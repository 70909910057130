import {animate, state, style, transition, trigger} from "@angular/animations";
import {Component, OnInit, ChangeDetectionStrategy, Optional, Inject} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LinkPreview} from "@shared/activity/attachments/link-preview/link-preview.component";
import {PanelOverlay} from "@shared/overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@shared/overlay-panel/panel-overlay.tokens";
import {Link} from "@shared/publisher/content.interface";
import {UserMediaService} from "@shared/user/user-media.service";
import {UploadEvent, UploadFile} from "@shared/utils/file-upload/file-upload.directive";
import {Observable} from "rxjs";
import {LinkTrackingComponent} from "../link-tracking/link-tracking.component";
import {getDomain, getMetaTagValue} from "@utils/link.function";

export interface IEditLinkMetadata {
  link?: Link;
}

@Component({
  selector: "app-edit-metadata",
  templateUrl: "./edit-metadata.component.html",
  styleUrls: ["./edit-metadata.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-520px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-520px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class EditMetadataComponent extends PanelOverlay implements OnInit {
  form: FormGroup;
  submitted = false;
  loading = false;
  isUpdatingImage = false;

  link: Link;
  linkPreview: LinkPreview = {};

  initialTitle: string;
  initialImgUrl: string;

  public uploadHandler = this.upload.bind(this);

  constructor(
    dialogRef: PanelOverlayRef<LinkTrackingComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IEditLinkMetadata,
    formBuilder: FormBuilder,
    private userMediaService: UserMediaService,
  ) {
    super(dialogRef, data);
    this.form = formBuilder.group({
      title: [data.link.title, Validators.required],
      description: [data.link.description],
      imageUrl: [data.link.imageUrl],
    });

    this.initialTitle = data.link.title;
    this.initialImgUrl = data.link.imageUrl;

    this.linkPreview = this.buildLinkPreview(data.link);

    this.linkPreview.url = getMetaTagValue("url", data.link.Metadata);
    this.linkPreview.domain = getDomain(this.linkPreview.url);

    this.link = data.link;
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((formValue) => {
      const domain = this.linkPreview.domain;
      const url = this.linkPreview.url;
      this.linkPreview = this.buildLinkPreview(formValue);
      this.linkPreview.domain = domain;
      this.linkPreview.url = url;

      this.link = {
        ...this.link,
        title: formValue.title,
        description: formValue.description,
        imageUrl: formValue.imageUrl,
        isTitleEdited: this.link.isTitleEdited || formValue.title != this.initialTitle,
        isImageEdited: this.link.isImageEdited || formValue.imageUrl != this.initialImgUrl,
      };
    });
  }

  buildLinkPreview(formValue: any): LinkPreview {
    return {
      title: formValue.title,
      description: formValue.description,
      image: formValue.imageUrl,
    };
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      this.dialogRef.close({
        link: this.link,
      });
    }
  }

  public onUploadEvent(event: UploadEvent): void {
    switch (event.status) {
      case "selected":
        break;
      case "uploading":
        this.isUpdatingImage = true;
        break;
      case "uploaded":
        this.form.patchValue({
          imageUrl: event.response.BlobUrl,
        });
        this.isUpdatingImage = false;
        break;
      case "error":
        console.error(event);
        this.isUpdatingImage = false;
        break;
    }
  }

  public upload(file: UploadFile): Observable<any> {
    if (file.file.type.startsWith("image")) {
      return this.userMediaService.uploadMediaFile(file.file, null, file.id);
    }
  }
}
