import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from "@angular/core";
import {Comment} from "@activity/comments/comment.model";
import {Activity} from "@activity/activity.model";
import {CommentService} from "@activity/comments/comment.service";
import {CommentResponse} from "@activity/comments/comment-response.interface";
import {IProfileResponse} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";
import Autolinker from "autolinker";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-instagram-comment",
  templateUrl: "./instagram-comment.component.html",
  styleUrls: ["./instagram-comment.component.scss"],
})
export class InstagramCommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() activity: Activity;
  @Output() delete: EventEmitter<any> = new EventEmitter();
  toggle: boolean;
  commentsPageSize = 10;
  defaultProfiles: IProfileResponse;
  nextPageCursorId: string;

  text: string;

  constructor(
    private profileService: ProfileService,
    private commentService: CommentService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });

    this.text = this.comment.ActivityCommentText || this.comment.Text;
    if (this.text) {
      this.text = Autolinker.link(this.text, {
        stripPrefix: false,
      });
    }
  }

  toggleComment() {
    this.toggle = !this.toggle;
  }

  loadMoreInstagramComments() {
    if (!this.defaultProfiles?.instagram?.ExternalId) return;
    this.commentService
      .forComment(
        "instagram",
        this.activity.Id,
        this.comment.Id,
        this.defaultProfiles.instagram.ExternalId,
        0,
        this.commentsPageSize,
        this.nextPageCursorId,
      )
      .pipe(untilDestroyed(this))
      .subscribe((data: CommentResponse) => {
        const newComments = data.ActivityComments;
        this.nextPageCursorId = data.NextPageCursorId;
        if (newComments.length > 0) {
          this.comment.appendMoreComments(newComments);
        }
        this.cdr.markForCheck();
      });
  }

  hideInstagramComment() {
    if (!this.defaultProfiles?.instagram?.ExternalId) return;
    const val = this.comment.Hidden;
    this.comment.Hidden = true;
    this.commentService
      .hide(this.defaultProfiles.instagram.ExternalId, this.comment.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => {
          this.comment.Hidden = val;
          // TODO: add error message
        },
      });
  }

  showInstagramComment() {
    if (!this.defaultProfiles?.instagram?.ExternalId) return;
    const val = this.comment.Hidden;
    this.comment.Hidden = false;
    this.commentService
      .show(this.defaultProfiles.instagram.ExternalId, this.comment.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => {
          this.comment.Hidden = val;
          // TODO: add error message
        },
      });
  }
}
