import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "app-iconable-button",
  template: "",
})
export class IconableButton implements OnInit {
  @Input() icon: string;
  public initialIcon = "";

  ngOnInit(): void {
    this.initialIcon = this.icon;
  }
}
