import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Comment} from "../comment.model";
import {CommentService} from "../comment.service";
import {Activity} from "@activity/activity.model";
import {IProfileResponse} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-comment-input",
  templateUrl: "./comment-input.component.html",
  styleUrls: ["./comment-input.component.scss"],
})
export class CommentInputComponent implements OnInit {
  @Input() provider: string;
  @Input() label: string;
  @Input() activity: Activity;
  @Input() parentComment: Comment;
  @Output() commented: EventEmitter<any> = new EventEmitter();

  defaultProfiles: IProfileResponse;
  commentText: string;

  constructor(
    private profileService: ProfileService,
    private commentService: CommentService,
  ) {}

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });
  }

  comment() {
    const profile = this.defaultProfiles[this.provider];
    const temporaryComment = new Comment(
      this.commentText,
      this.commentText,
      this.activity.Id,
      {
        AccountIdentifier: profile.ExternalId,
        ScreenName: profile.ScreenName,
        FriendlyName: profile.Name || profile.ScreenName,
        Picture: profile.Picture,
        Url: profile.Url,
      },
      this.parentComment ? this.parentComment.Id : null,
    );

    if (this.parentComment) {
      this.parentComment.addComment(temporaryComment);
    } else {
      this.activity.addComment(temporaryComment);
    }

    this.commentText = "";
    this.commented.emit();

    this.commentService
      .publish(
        this.activity.Id,
        profile.ExternalId,
        temporaryComment.Text,
        this.parentComment ? this.parentComment.Id : null,
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (comment: Comment) => {
          if (this.parentComment) {
            this.parentComment.Comments[0] = comment;
          } else {
            this.activity.Comments[0] = comment;
          }
        },
        error: () => {
          if (this.parentComment) {
            this.parentComment.removeCommentByIndex(0);
          } else {
            this.activity.removeCommentByIndex(0);
          }
          // TODO: add error handling
        },
      });
  }
}
