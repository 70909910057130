import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ThemeService} from "@theme/theme.service";
import {ChannelCardSelectedEvent, GroupSource} from "@shared/stream/stream.interface";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-channel-card",
  templateUrl: "./channel-card.component.html",
  styleUrls: ["./channel-card.component.scss"],
})
export class ChannelCardComponent implements OnInit {
  @Input() channelName: string;
  @Input() channelNameUrl: string;
  @Input() channelPhoto: string;
  @Input() channelProvider: number | string;
  @Input() channelSubtype: string;
  @Input() isSelected: boolean;
  @Input() showProvider: boolean;
  @Input() showHandle: boolean;
  @Input() showSubtype: boolean;
  @Input() showFollowers: boolean;
  @Input() showAlert: boolean;
  @Input() channelFollowers: number;
  @Input() channelHandle: string;
  @Input() selectButtonDisabled: boolean;
  @Input() removeButtonDisabled: boolean;
  @Input() isPrivateChannel: boolean;
  @Input() GroupSource: GroupSource;
  @Output() selectButton: EventEmitter<any> = new EventEmitter();
  @Output() removeButton: EventEmitter<any> = new EventEmitter();
  @Output() channelCardSelectedSingleValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() channelCardSelected: EventEmitter<ChannelCardSelectedEvent> = new EventEmitter<ChannelCardSelectedEvent>();
  @Input() channelMediaCount: number;
  @Input() showMediaCount: boolean;

  channelCardState: ChannelCardSelectedEvent = {};

  constructor(public themeService: ThemeService) {}

  ngOnInit() {
    this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
      if (isDarkTheme) {
        this.channelPhoto = this.channelPhoto ? this.channelPhoto : "/assets/icons/unknown-user-dark.svg";
      } else {
        this.channelPhoto = this.channelPhoto ? this.channelPhoto : "/assets/icons/unknown-user-light.svg";
      }
    });

    if (!this.GroupSource) {
      return;
    }

    this.GroupSource.Sources.forEach((source) => {
      if (source.selected == null) {
        this.channelCardState[source.Subtype] = false;
      } else {
        this.channelCardState[source.Subtype] = source.selected;
      }
    });
  }

  onSubTypeSelected(subtype: string) {
    const currentValue = this.channelCardState[subtype];

    if (currentValue) {
      this.channelCardState[subtype] = !currentValue;
    } else {
      this.channelCardState[subtype] = true;
    }

    this.channelCardSelected.emit(this.channelCardState);

    this.channelCardSelectedSingleValue.emit(subtype);
  }
}
