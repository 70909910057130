export function facebookUrl(ExternalId: string): string {
  return "https://facebook.com/" + ExternalId;
}

export function instagramUrl(ScreenName: string): string {
  return "https://instagram.com/" + ScreenName;
}

export function twitterUrl(ScreenName: string): string {
  return "https://twitter.com/" + ScreenName;
}
