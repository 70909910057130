import {BaseMediaValidation} from "./BaseMediaValidation";

export class FacebookMediaValidation extends BaseMediaValidation {
  PhotoSizeError: boolean;
  VideoSizeError: boolean;

  constructor() {
    super();
  }

  IsValid(): boolean {
    return !this.PhotoSizeError && !this.VideoSizeError;
  }
}
