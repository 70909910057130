import {Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject} from "@angular/core";
import {AdvocacyOrganicDataModel, IBaseTableDataModel} from "../../analytics.interface";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {Observable} from "rxjs";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {UserWidget} from "../../widget.interface";
import {BaseTableComponent} from "../base-table/base-table.component";

@Component({
  selector: "app-content-advocacy-organic-engagement",
  templateUrl: "./content-advocacy-organic-engagement.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentAdvocacyOrganicEngagementComponent extends BaseTableComponent<AdvocacyOrganicDataModel> {
  fileName = "Advocacy_Generated_Engagement";

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeListenerService: TimerangeListenerService,
    protected profilesListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeListenerService, profilesListener, cdr, widgetConfig);
  }

  getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesIds: string[],
  ): Observable<IBaseTableDataModel<AdvocacyOrganicDataModel>> {
    return this.analyticsService.getAdvocacyOrganicData(
      startDate,
      endDate,
      pageIndex,
      pageSize,
      groupsIds,
      profilesIds,
    );
  }

  getCsvHeaders() {
    return {
      Text: "Post Text",
      TotalEmployeeEngagement: "Total Employee Engagement",
      TotalPostEngagement: "Total Post Engagement",
      EmployeePostRelation: "Relation",
    };
  }
}
