import {Component, Input, OnInit, ViewChild, ElementRef} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {VideoModalComponent} from "./video-modal/video-modal.component";
import {Activity} from "@activity/activity.model";

export interface VideoAttachment {
  type: string;
  preview: string;
  source: string;
  width?: number;
  height?: number;
}

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-video-attachment",
  templateUrl: "./video-attachment.component.html",
  styleUrls: ["./video-attachment.component.scss"],
})
export class VideoAttachmentComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  private videoToOpenInDialog = ["youtube", "vimeo"];

  @Input() video: VideoAttachment;
  @Input() activity: Activity;
  @Input() autoPlay: boolean;
  @Input() muted = true;
  @Input() cssClass: string;
  @Input() showVolumeControl: boolean;

  openInDialog: boolean;
  videoPlayer: HTMLVideoElement;

  @ViewChild("videoPlayer")
  set mainVideoEl(el: ElementRef) {
    this.videoPlayer = el.nativeElement;
  }

  ngOnInit() {
    const regex = new RegExp("\\b(?:" + this.videoToOpenInDialog.join("|") + ")\\b", "gi");
    this.openInDialog = regex.test(this.video.source);
    if (!this.video.source) {
      this.video.source = this.getMetaTagValue();
    }
  }

  playVideo() {
    if (this.videoPlayer.paused) {
      this.videoPlayer.play().then(() => {
        this.videoPlayer.pause();
        this.videoPlayer.play();
      });
    } else {
      this.videoPlayer.pause();
    }
  }

  openDialog(): void {
    if (this.video.type === "share") {
      this.dialog.open(VideoModalComponent, {
        width: "800px",
        height: "521px",
        data: {
          source: this.video.source,
        },
      });
    }
  }

  private getMetaTagValue(): string {
    if (!this.activity) return "";

    if (this.activity.UrlMetadata && this.activity.UrlMetadata[0]) {
      const metaTags = this.activity.UrlMetadata[0].MetaTags;
      const metaTag = metaTags.find((meta) => meta.Key === "og:video" || meta.Key === "twitter:player");
      return metaTag ? metaTag.Value.replace(/&amp;/g, "&") : "";
    }
  }
}
