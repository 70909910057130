<!-- Facebook Label -->
<div
  *ngIf="showProviderLabel && providerLabel === 'Facebook'"
  class="inline-flex text-xs font-medium px-1.5 py-0.5 rounded-t-[4px]"
  [ngClass]="{
    '!text-light-base-0': focused && !composerValidation.FacebookValidation.hasErrors,
    'text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200': !focused,
    'bg-[#1877f2]': active && focused && !composerValidation.FacebookValidation.hasErrors,
    'bg-light-danger-100 dark:bg-dark-danger-100 text-light-base-0 dark:text-dark-base-0':
      composerValidation.FacebookValidation.hasErrors
  }">
  {{ providerLabel }}
</div>
<!-- Twitter Label -->
<div
  *ngIf="showProviderLabel && providerLabel === 'Twitter'"
  class="inline-flex text-xs font-medium px-1.5 py-0.5 rounded-t-[4px]"
  [ngClass]="{
    '!text-light-base-0': focused && !composerValidation.FacebookValidation.hasErrors,
    'text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200': !focused,
    'bg-[#1da1f2]': active && focused && !composerValidation.TwitterValidation.hasErrors,
    'bg-light-danger-100 dark:bg-dark-danger-100 text-light-base-0 dark:text-dark-base-0':
      composerValidation.TwitterValidation.hasErrors
  }">
  {{ providerLabel }}
</div>
<!-- LinkedIn Label -->
<div
  *ngIf="showProviderLabel && providerLabel === 'LinkedIn'"
  class="inline-flex text-xs font-medium px-1.5 py-0.5 rounded-t-[4px]"
  [ngClass]="{
    '!text-light-base-0': focused && !composerValidation.FacebookValidation.hasErrors,
    'text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200': !focused,
    'bg-[#0073b1]': active && focused && !composerValidation.LinkedInValidation.hasErrors,
    'bg-light-danger-100 dark:bg-dark-danger-100 text-light-base-0 dark:text-dark-base-0':
      composerValidation.LinkedInValidation.hasErrors
  }">
  {{ providerLabel }}
</div>
<!-- Instagram Label -->
<div
  *ngIf="showProviderLabel && providerLabel === 'Instagram'"
  class="inline-flex text-xs font-medium px-1.5 py-0.5 rounded-t-[4px]"
  [ngClass]="{
    '!text-light-base-0': focused && !composerValidation.FacebookValidation.hasErrors,
    'text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200': !focused,
    'bg-[#c13584]': active && focused && !composerValidation.InstagramValidation.hasErrors,
    'bg-light-danger-100 dark:bg-dark-danger-100 text-light-base-0 dark:text-dark-base-0':
      composerValidation.InstagramValidation.hasErrors
  }">
  {{ providerLabel }}
</div>
<!-- Editor -->
<div
  class="editor-wrapper rounded"
  [ngClass]="{
    'border-[#1877f2] dark:border-[#1877f2]': providerLabel === 'Facebook' && active && focused,
    'border-[#1da1f2] dark:border-[#1da1f2]': providerLabel === 'Twitter' && active && focused,
    'border-[#0073b1] dark:border-[#0073b1]': providerLabel === 'LinkedIn' && active && focused,
    'border-[#c13584] dark:border-[#c13584]': providerLabel === 'Instagram' && active && focused,
    '!border-2 rounded-tl-none': (active && showProviderLabel && focused) || (active && showProviderLabel && !focused),
    'rounded-tl-none': showProviderLabel,
    'border-light-base-300/90 dark:border-dark-base-300/90': !active,
    'border-2 border-light-brand-100 dark:border-dark-brand-100 hover:border-light-brand-100 dark:hover:border-dark-brand-100 -m-[1px]':
      active && focused && !showProviderLabel && !composerValidation.GeneralValidation.ComposerHasErrors
  }"
  [class.collapsed]="!active"
  [class.active]="active"
  [class.with-pics]="composerService.composerEditorsModel[providerName]?.MediaFiles?.length">
  <div
    class="editor rounded-[3px]"
    [ngClass]="{
      'rounded-tl-none': showProviderLabel,
      'editor-facebook': providerLabel === 'Facebook',
      'editor-twitter': providerLabel === 'Twitter',
      'editor-linkedin': providerLabel === 'LinkedIn',
      'editor-instagram': providerLabel === 'Instagram'
    }"
    [lang]="inputLanguage">
    <ng-content select="[share]"></ng-content>
    <quill-editor
      [placeholder]="placeholder"
      [modules]="modules"
      (onEditorCreated)="addBindingCreated($event)"
      (onContentChanged)="onContentChanged($event)"
      (onEditorChanged)="onEditorChanged($event)"
      data-test="composer-editor-quilleditor">
      <div style="display: none" quill-editor-toolbar></div>
    </quill-editor>
    <div
      class="images grid grid-cols-4 gap-x-2 gap-y-1.5 mt-5"
      data-test="composer-editor-attached-media-container-div"
      *ngIf="composerService.composerEditorsModel[providerName]?.MediaFiles?.length">
      <div
        [ngClass]="{
          'gallery-one': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 1,
          'gallery-two': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 2,
          'gallery-three': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 3,
          'gallery-four': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 4,
          'gallery-five': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 5,
          'gallery-six': composerService.composerEditorsModel[providerName]?.MediaFiles?.length === 6
        }"
        class="image"
        *ngFor="let file of composerService.composerEditorsModel[providerName]?.MediaFiles; let index = index">
        <img
          data-test="composer-editor-attached-media-item"
          *ngIf="file.preview"
          [src]="file.preview"
          [class.validation-error]="isFileValidationValid(file, provider)" />
        <div *ngIf="file.MediaType?.startsWith('video')" class="video-attachment"></div>
        <div *ngIf="!file.uploading" class="close" (click)="removeImage(index, provider)" title="remove">
          <app-icon icon="x" class="w-3.5 h-3.5 leading-3.5 text-light-base-0"></app-icon>
        </div>
        <div *ngIf="file.uploading" class="loading">
          <app-loader></app-loader>
        </div>
        <div class="edit" *ngIf="!file.MediaType?.startsWith('video')" (click)="mediaClicked.emit(file)">Edit</div>
        <div class="edit" *ngIf="file.MediaType?.startsWith('video')" (click)="videoClicked.emit(file)">Edit</div>
      </div>
    </div>

    <div
      data-test="composer-editor-attachedLink-container-div"
      *ngIf="composerService.composerEditorsModel[providerName]?.links?.length">
      <ng-container *ngFor="let link of composerService.getEditorLinks(providerName); let index = index">
        <app-attached-link
          [link]="link"
          (remove)="removeLink($event)"
          [index]="index"
          [dialogRef]="dialogRef"
          [showRemoveButton]="shouldShowRemoveButton(link)"
          [containsLinkedInProfile]="hasLinkedIn"
          data-test="composer-editor-app-attachedLink-metadata"></app-attached-link>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </div>

  <div *ngIf="enableActions" class="editor-actions">
    <div>
      <!-- Attach Media -->
      <div
        class="action-wrapper"
        [class.active]="composerService.composerEditorsModel[providerName]?.MediaFiles?.length"
        *ngIf="addPhoto">
        <label class="action attach-media" matTooltip="Attach Media" matTooltipPosition="above">
          <mat-icon svgIcon="photo"></mat-icon>
          <input
            type="file"
            multiple
            appFileUpload
            accept="image/*,video/*"
            (events)="onUploadEvent($event, provider)"
            [uploadHandler]="uploadHandler" />
        </label>
        <app-icon
          class="close"
          icon="x"
          (click)="removeImages($event, provider)"
          matTooltip="Remove Media"
          matTooltipPosition="above"></app-icon>
      </div>
      <div class="action-wrapper">
        <div class="action" (click)="openHashtagGroupPanel()" matTooltip="Hashtag Groups" matTooltipPosition="above">
          <mat-icon svgIcon="hashtag"></mat-icon>
        </div>
      </div>

      <!-- Employees -->
      <div
        class="action-wrapper"
        [class.active]="
          composerService.composerEditorsModel[providerName]?.ShareOnBehalfOfEmployees ||
          composerService.composerEditorsModel[providerName]?.LikeOnBehalfOfEmployees
        "
        *ngIf="(provider === null && hasLinkedIn) || isProviderLinkedIn">
        <div
          class="action"
          (click)="openLinkedInSharePanel()"
          matTooltip="Share to Employees"
          matTooltipPosition="above">
          <mat-icon svgIcon="users-3"></mat-icon>
        </div>
        <app-icon
          class="close"
          icon="x"
          (click)="removeEmployees()"
          matTooltip="Remove Share to Employees"
          matTooltipPosition="above"></app-icon>
      </div>
    </div>
    <div class="flex items-center">
      <!-- Character Counter -->
      <app-chars-counter
        *ngIf="limitChars"
        [max]="280"
        [value]="twitterMessageLength"
        class="mr-0.5"></app-chars-counter>
    </div>
  </div>
  <video style="display: none" *ngIf="videoUrl" width="320" height="240" controls [attr.src]="videoUrl"></video>
  <!-- Instagram First Comment -->
  <!--  <div *ngIf="isProviderInstagram && hasInstagram && !isProd">-->
  <!--    <mat-slide-toggle-->
  <!--      color="primary"-->
  <!--      [checked]="includeComment"-->
  <!--      [(ngModel)]="includeComment"-->
  <!--      (change)="onCommentEditorChange(null)"-->
  <!--    >Include a comment with this post-->
  <!--    </mat-slide-toggle>-->
  <!--    <div class="editor-comment" [hidden]="!includeComment">-->
  <!--      <quill-editor-->
  <!--        placeholder="Please type the first comment"-->
  <!--        (onEditorCreated)="addBindingCreatedForCommentEditor($event)"-->
  <!--        (onContentChanged)="onCommentEditorChange($event)">-->
  <!--        <div style="display: none" quill-editor-toolbar></div>-->
  <!--      </quill-editor>-->
  <!--    </div>-->
  <!--    <div class="editor-actions" *ngIf="includeComment">-->
  <!--      <div class="action-wrapper">-->
  <!--        <div-->
  <!--          class="action"-->
  <!--          (click)="openHashtagGroupPanel(true)"-->
  <!--          matTooltip="Hashtag groups"-->
  <!--          matTooltipPosition="above">-->
  <!--          <mat-icon svgIcon="share"></mat-icon>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
