import {Component, Input, OnInit} from "@angular/core";
import {ThemeService} from "@theme/theme.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-placeholder-image",
  templateUrl: "./placeholder-image.component.html",
  styleUrls: ["./placeholder-image.component.scss"],
})
export class PlaceholderImageComponent implements OnInit {
  @Input() lightThemeImageFileName: string;
  @Input() darkThemeImageFileName: string;
  isDark: boolean;

  constructor(public themeService: ThemeService) {}

  ngOnInit() {
    this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
      this.isDark = isDarkTheme;
    });
  }
}
