<div *ngIf="items.length > 0" class="carousel-wrapper" [ngClass]="{'cursor-zoom-in': clickable}">
  <!-- Carousel Controls -->
  <ng-container *ngIf="showControls">
    <div class="bg-light-base-200 cursor-pointer" (click)="previous()" *ngIf="slide !== 0">
      <app-icon icon="chevron-left" class="carousel-navigation previous text-white w-5 h-5 p-1 z-50">
        previous
      </app-icon>
    </div>
    <div class="bg-light-base-200 cursor-pointer" (click)="next()" *ngIf="slide !== items.length - 1">
      <app-icon icon="chevron-right" class="carousel-navigation next text-white w-5 h-5 p-1 z-50">next </app-icon>
    </div>
    <ul class="carousel-dots">
      <li class="carousel-dot" *ngFor="let item of items; let i = index" [class.active]="i === slide"></li>
    </ul>
  </ng-container>
  <div class="carousel-icon" *ngIf="showIcon">
    <mat-icon svgIcon="carousel"></mat-icon>
  </div>
  <ul class="carousel">
    <li style="transform: translateX(2px); width: 1px"></li>
    <li
      class="carousel-item"
      *ngFor="let item of items; let i = index"
      [class.hidden]="notInViewport(i)"
      [style.transform]="
        i === slide
          ? 'translateX(' + getCarouselAttachmentPosition(i) + '%)'
          : 'translateX(' + getCarouselAttachmentPosition(i) + '%)'
      ">
      <img
        class="media"
        (click)="open(i)"
        *ngIf="item.image"
        [src]="item.image"
        [imageWidth]="mediaWidth"
        [imageQuality]="mediaQuality" />
      <div class="media" *ngIf="item.video">
        <app-video-attachment
          [video]="item.video"
          autoPlay="true"
          muted="true"
          [showVolumeControl]="showVolumeControl"></app-video-attachment>
      </div>
    </li>
  </ul>
</div>
<div *ngIf="items.length === 0">Preview not available</div>
<div
  *ngIf="downloadUrl"
  class="relative grid grid-cols-2 items-center justify-between h-10 border-solid border-b border-t border-r-0 border-l-0 border-light-base-300 dark:border-dark-base-300 px-3 doc-title">
  <div class="text-sm truncate">{{ documentTitle }}</div>
  <a
    [href]="downloadUrl"
    target="_blank"
    class="inline-flex items-center ml-auto font-medium download-link space-x-1.5">
    <span class="text-sm">Download</span>
    <app-icon icon="download" class="w-5 h-5"></app-icon>
  </a>
</div>
