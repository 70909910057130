<mat-icon
  *ngIf="showMenuIcon"
  svgIcon="align-justified"
  (click)="menuClick.emit()"
  matTooltip="{{ menuClickTitle }}"
  matTooltipPosition="below"></mat-icon>
<div class="title" title="{{ pageTitle }}">{{ pageTitle }}</div>
<div class="toolbar" *ngIf="toolbar">
  <ng-content></ng-content>
</div>
