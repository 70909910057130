import {Component, EventEmitter, Input, Output} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";
import {IDisabledComponent} from "@shared/utils/base-role-validator";
import {IconableButton} from "../basic-button/IconableButton";

@UntilDestroy()
@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent extends IconableButton implements IDisabledComponent {
  @Input() color = "primary";
  @Input() type: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() customId: string;
  @Input() minWidth: number;
  @Input() buttonType: string;
  @Output() public buttonClick: EventEmitter<Event> = new EventEmitter();

  constructor() {
    super();
  }
}
