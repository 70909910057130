import {Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject} from "@angular/core";
import {BaseTableComponent} from "../base-table/base-table.component";
import {IBaseTableDataModel, IEmployeeSocialActionsModel} from "../../analytics.interface";
import {Observable} from "rxjs";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {UserWidget} from "../../widget.interface";

@Component({
  selector: "app-top-content-cards",
  templateUrl: "./top-content-cards.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopContentCardsComponent extends BaseTableComponent<IEmployeeSocialActionsModel> {
  fileName = "Top_Amplified_Content_Cards";
  pageSize = 10;

  constructor(
    protected timerangeListener: TimerangeListenerService,
    protected leaderboardAnalyticsService: LeaderboardAnalyticsService,
    protected cdr: ChangeDetectorRef,
    protected profilesListener: ProfileListenerService,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(leaderboardAnalyticsService, timerangeListener, profilesListener, cdr, widgetConfig);
  }

  getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesIds: string[],
  ): Observable<IBaseTableDataModel<IEmployeeSocialActionsModel>> {
    return this.leaderboardAnalyticsService.getAmplifiedPostsEx(
      startDate,
      endDate,
      pageIndex,
      pageSize,
      groupsIds,
      profilesIds,
    );
  }

  getCsvHeaders() {
    return {
      PostText: "PostText",
      MediaTitle: "MediaTitle",
      ShareMediaCategory: "ShareMediaCategory",
      ActivityPermalink: "ActivityPermalink",
      ActivityId: "ActivityInternalId",
      CommentCount: "CommentCount",
      LikeCount: "LikeCount",
      ShareCount: "ShareCount",
      TotalCount: "TotalCount",
    };
  }
}
