import {EmployeeInList, IProfile} from "@channel/profile.interface";
import {Activity} from "@activity/activity.model";
import {SafeResourceUrl} from "@angular/platform-browser";
import {UrlMetadata} from "@activity/activity.interface";
import {FbScrapeResponse} from "@utils/url/url-metadata.interface";
import {Moment} from "moment";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {TimezoneInfo} from "@shared/utils/timezone/timezone.interface";
import {PublishSchedule} from "./publish-schedule.interface";
import {InstagramMediaValidation} from "@shared/composer/composer-validation/media-validation/InstagramMediaValidation";
import {TwitterMediaValidation} from "@shared/composer/composer-validation/media-validation/TwitterMediaValidation";
import {FacebookMediaValidation} from "@shared/composer/composer-validation/media-validation/FacebookMediaValidation";
import {HashtagGroupModel} from "@shared/hashtag-groups/hashtag-group.interface";
import {LinkedInMediaValidation} from "@shared/composer/composer-validation/media-validation/LinkedInMediaValidation";
import {Comment} from "@shared/activity/comments/comment.model";
import {guid} from "@shared/utils/guid.function";
import {encodeUrl} from "@shared/utils/link.function";
import {IComposerToken} from "@shared/composer/composer-editor/token/token.interface";
import {EmployeeGroupConfig} from "@shared/composer/share-to-emplyee/share-to-employee.component";

export enum LinkEventEnum {
  None,
  Shorten,
  Unshorten,

  RemoveLink,
  SetEditorLink,
  UpdateLink,
  AddTracking,
  RemoveTracking,
  UpdateTracking,
  ReshortenUrl,
}

export interface IOnLinksModelChangeModel {
  links: Link[];
  Event: LinkEventEnum;
}

export interface ILinkUpdatedModel {
  link: Link;
  Event: LinkEventEnum;
  index: number;
}

export interface LinkChangesModel {
  caretPosition: number;

  changeLength: number;

  newLinks: NewLinkModel[];

  source: string;

  //Avoid reinitializing links when first opening the editor
  editModeFirstEdit: boolean;

  currentText: string;

  isDeleteOperation: boolean;
}

export interface NewLinkModel {
  Url: string;
  Index: number;
}

export class Link {
  Id: string;

  //The url provided by the user
  BaseUrl: string;
  ProfileType: ProfileTypes;

  IsTracking?: boolean;
  TrackingUrl?: string; // the url with UTM parameters

  UtmCampaign?: string;
  UtmSource?: string;
  UtmMedium?: string;
  UtmTerm?: string;
  UtmContent?: string;
  Metadata?: UrlMetadata[];

  IsShorten?: boolean;
  ShortenUrl?: string; // the shortened url, if it exists
  ShortenDomain?: string;
  ShortenGroupId?: string;

  title?: string;
  description?: string;
  imageUrl?: string;
  domain?: string;

  isTitleEdited?: boolean;
  isImageEdited?: boolean;

  startPosition?: number;

  lastTrackingUrlState?: string;

  FacebookMetadata: FbScrapeResponse;

  originalOrderTrackingParams = [];

  lastShortenUrl?: string;

  IsLinkOnText: boolean;

  IsMetadataLoading: boolean;

  constructor(baseUrl: string) {
    this.Id = guid();

    this.ShortenUrl = "";
    this.IsShorten = false;
    this.IsLinkOnText = true;
    this.IsMetadataLoading = true;

    if (baseUrl != null && baseUrl != "") {
      const linkData = new URL(baseUrl);

      const utmCampaign = linkData.searchParams.get("utm_campaign");
      const utmSource = linkData.searchParams.get("utm_source");
      const utmMedium = linkData.searchParams.get("utm_medium");
      const utmTerm = linkData.searchParams.get("utm_term");
      const utmContent = linkData.searchParams.get("utm_content");

      const paramNames = ["utm_campaign", "utm_source", "utm_medium", "utm_term", "utm_content"];

      linkData.searchParams.forEach((x, key) => {
        if (paramNames.some((x) => x == key)) this.originalOrderTrackingParams.push(key);
      });

      if (utmCampaign?.length > 0) {
        this.UtmCampaign = utmCampaign;
      }

      if (utmSource?.length > 0) {
        this.UtmSource = utmSource;
      }

      if (utmMedium?.length > 0) {
        this.UtmMedium = utmMedium;
      }

      if (utmTerm?.length > 0) {
        this.UtmTerm = utmTerm;
      }

      if (utmContent?.length > 0) {
        this.UtmContent = utmContent;
      }

      this.IsTracking =
        this.UtmCampaign?.length > 0 ||
        this.UtmSource?.length > 0 ||
        this.UtmMedium?.length > 0 ||
        this.UtmTerm?.length > 0 ||
        this.UtmContent?.length > 0;

      linkData.searchParams.delete("utm_campaign");
      linkData.searchParams.delete("utm_source");
      linkData.searchParams.delete("utm_medium");
      linkData.searchParams.delete("utm_term");
      linkData.searchParams.delete("utm_content");

      if (this.IsTracking) {
        //If the user inputed an url with /? we wont modify it.
        if (baseUrl.indexOf("/?") > -1) {
          this.BaseUrl = encodeUrl(linkData.toString());
        } else {
          //new URL() changes "%20" to '+';
          const regexObjectUrl = /\+/gi;
          let objectUrl = linkData.toString().replace(regexObjectUrl, "%20");

          if (objectUrl.endsWith("/")) {
            objectUrl = objectUrl.substring(0, objectUrl.length - 1);
          } else {
            // var regex = new RegExp("(/?)");
            const indexOfCharacter = objectUrl.indexOf("/?");
            if (indexOfCharacter > -1)
              objectUrl = objectUrl.substring(0, indexOfCharacter) + objectUrl.substring(indexOfCharacter + 1);
          }
          this.BaseUrl = encodeUrl(objectUrl);
        }
      } else {
        this.BaseUrl = encodeUrl(baseUrl);
      }
    } else {
      this.IsTracking = false;
      this.BaseUrl = encodeUrl(baseUrl);
    }
  }
}

export interface AddLinkDataResponse extends Link {
  shortenedUrl: string;
  metadata?: UrlMetadata[];
}

//SW-2796
export interface ContentModel {
  SkipPushNotification?: boolean;
  Id?: string;
  MediaFiles?: MediaFileUploaded[];
  MessageType?: string;
  Profiles?: IndexedObj[];
  Tags?: IndexedObj[];
  FacebookHideUrl?: boolean;
  FacebookHideUrlImage?: boolean;
  FacebookText?: string;
  FacebookUrl?: string;
  QuotedTweetUrl?: string;
  TwitterText?: string;
  InstagramText?: string;
  LinkedInText?: string;
  LinkedInArticleLink?: string;
  LinkedInArticleLinkDescription?: string;
  LinkedInArticleLinkCaption?: string;
  LinkedInArticleLinkPicture?: string;
  LinkedInArticleLinkDomain?: string;
  AvailableForPublish?: boolean;
  Approved?: boolean;
  InReplyToTweet?: string;
  TwitterUrlMetadata?: UrlMetadata[];
  TwitterUrlMetadataJson?: string;
  InstagramComment?: string;
  FacebookUrlPicture?: string;
  FacebookUrlTitle?: string;
  FacebookUrlDescription?: string;
  PublishEventsResolved?: string[];

  PublishMessageMentions?: ContentMention[];
  TimezoneId?: string;
  ScheduleModel?: PublishSchedule;
  ExpirationModel?: PublishSchedule;
  language?: string;

  ShareOnBehalfOfEmployees?: boolean;
  LikeOnBehalfOfEmployees?: boolean;
  ExcludeEmployees?: string;
  IncludedEmployees?: string;
  EmployeeGroupNames?: string;
  EmployeeGroupsConfig?: EmployeeGroupConfig;
  LinkedInShareCommentary?: string;
  Employees?: EmployeeInList[];
  Links?: Link[];

  FacebookEditorText?: string;
  TwitterEditorText?: string;
  LinkedInEditorText?: string;
  InstagramEditorText?: string;

  Tokens?: IComposerToken[];
  //Its null when its a legacy content or a stream content. If it was saved with tokens this will have data.
  EditorStatesJson?: string;

  LinkedInArticleCaptionEdited?: boolean;
  LinkedInArticlePictureEdited?: boolean;
}

export interface IndexedObj {
  Id: string;
}

export interface MediaFileUploaded extends MediaFile {
  uploading?: boolean;
  uploaded?: boolean;
  preview?: SafeResourceUrl;
  id?: string;
  videoDuration?: number;

  instagramValidation?: InstagramMediaValidation;
  facebookValidation?: FacebookMediaValidation;
  twitterValidation?: TwitterMediaValidation;
  linkedInValidation?: LinkedInMediaValidation;
}

export interface PublishEvent {
  Text: string;
  FacebookText: string;
  FacebookHideUrl: boolean;
  FacebookHideUrlImage: boolean;
  FacebookUrlTitle: string;
  FacebookUrlDescription: string;
  FacebookUrlMetadata: FbScrapeResponse;
  FacebookUrlPicture: string;
  FacebookUrl: string;
  TwitterText: string;
  YoutubeText: string;
  InstagramText: string;
  LinkedInText: string;
  CreatedAt: string;
  TwitterUrlMetadata: UrlMetadata[];
  PublishedTimes: number;
  MediaFiles: MediaFile[];
  Id: string;
  Activities: {
    Facebook?: Activity;
    TwitterAccount?: Activity;
    InstagramAccount?: Activity;
    LinkedIn?: Activity;
  };
  Providers?: string[];
  Profiles: IProfile[];
  IsDemo: boolean;
  ErrorMessage?: any;
  UserIds: string[];
  //Its null when its a legacy content or a stream content. If it was saved with tokens this will have data.
  EditorStatesJson?: string;
  IsAScheduledShare: boolean;
}

//Content is the equivalent of librarycontentdto on the backend.
export interface Content extends PublishEvent {
  PublishAt: string;
  ScheduleId: string;
  QueueItemId: string;
  HasNoContent: boolean;
  TextRaw: string;
  FacebookTextRaw: string;
  TwitterTextRaw: string;
  QuotedTweetUrl: any;
  YoutubeTextRaw: string;
  InstagramTextRaw: string;
  LinkedInTextRaw: string;
  ScheduledAt: string;
  IsPublished: boolean;
  Tags: Tag[];
  MessageType: string;
  Approved: boolean;
  LastPubshAt: any;
  Priority: number;
  AvailableForPublish: boolean;
  InReplyToTweet: any;
  FacebookUrlMetadataJson: string;
  PublishAtMoment?: Moment;
  PublishMessageMentions?: ContentMention[];
  ScheduleModel?: PublishSchedule;
  ExpirationModel?: PublishSchedule;

  InstagramComment?: string;

  ShareOnBehalfOfEmployees?: boolean;
  LikeOnBehalfOfEmployees?: boolean;
  ExcludeEmployees?: string;
  IncludedEmployees?: string;
  EmployeeGroupNames?: string;
  LinkedInShareCommentary?: string;
  Employees?: EmployeeInList[];
  Links?: Link[];
  LinkedInArticleCaptionEdited?: boolean;
  LinkedInArticlePictureEdited?: boolean;

  EmployeeGroupsConfig: EmployeeGroupConfig;
}

// export interface ContentCategory {
//   Id: string;
//   Name: string;
//   RecycleContent: boolean;
//   Account: any;
//   Messages: any;
//   ColorHex: string;
//   Schedules: any;
//   QueueItems: any;
// }

export interface MediaFile {
  BlobName?: string;
  BlobUrl?: string;
  EnabledForFacebook?: boolean;
  EnabledForTwitter?: boolean;
  EnabledForYoutube?: boolean;
  EnabledForInstagram?: boolean;
  EnabledForLinkedIn?: boolean;
  FileName?: string;
  Id?: string;
  MediaType?: string;
  PreviewUrl?: string;
  PublishMessageMediaFiles?: any;
  SizeBytes?: number;
  Width?: number;
  Height?: number;
}

//EditorModel is used for managing the state of a composer editor.
export interface EditorModel {
  Employees?: EmployeeInList[];
  sharing?: boolean;
  ShareOnBehalfOfEmployees?: boolean;
  LikeOnBehalfOfEmployees?: boolean;
  ExcludeEmployees?: string;
  IncludedEmployees?: string;
  EmployeeGroupNames?: string;
  EmployeeGroupsConfig?: EmployeeGroupConfig;
  LinkedInShareCommentary?: string;
  rawText?: any;
  MediaFiles?: MediaFileUploaded[];
  text?: string;
  link?: string;
  editorContent?: any[];
  commentEditorContent?: any[];
  TwitterUrlMetadata?: UrlMetadata[];
  commentText?: string;
  Mentions?: ContentMention[];
  inputLanguage?: string;
  hashtagGroups?: HashtagGroupModel[];
  links?: Link[];
  //This is null if you have no content on the composer. It will get filled with tokens when you have made changes in the composer.
  tokens?: IComposerToken[];
}

export interface ContentMention {
  Id?: string;
  Reference: string;
  Name: string;
  SourceType: ProfileTypes;
  //This are relative to the preview text.
  IndexStart: number;
  IndexEnd: number;
  //--
  EditorIndexStart: number;

  PublishMessage?: any;
}

export interface MentionsOnMessage {
  text: string;

  mentions: ContentMention[];
}

export enum ComposerActionTypeEnum {
  AddToLibrary,
  ScheduleOneTime,
  PublishNow,
}

export interface ComposerActionSchedule {
  time: string;
  startAt: Date;
  timezoneId?: TimezoneInfo;
}

export interface PublisherFilterData {
  Tags: Tag[];
  Profiles: IProfile[];
}

export enum CrudEventEnum {
  Add,
  Delete,
  Edit,
}

export interface ActivityCommentEventModel {
  ActivityComment: Comment;
  EventType: CrudEventEnum;
}

export interface Tag {
  Id?: string;
  Name?: string;
  ColorHex?: string;
  IsSystem?: boolean;
  FgColorHex?: string;
  UsedCount?: number;
  ColorName?: string;
  Selected?: boolean;
}

export interface Timezone {
  IanaId: string;
  Cities: string;
  Names: TimezoneName;
  Abbrs: TimezoneName;
  Offset: string;
  ShortName: string;
  LongName: string;
  WindowsId?: string;
}

export interface TimezoneName {
  Generic: string;
  Standard: string;
  Daylight: string;
}
