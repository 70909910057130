import {UrlMetadata} from "@activity/activity.interface";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Component, Inject, OnDestroy, OnInit, Optional, ViewChild, ViewContainerRef} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DomSanitizer} from "@angular/platform-browser";
import {AddChannelDialogComponent} from "@channel/add-channel-dialog/add-channel-dialog.component";
import {ProfileLabelsFromId, ProfileTypes} from "@channel/profile-types.enum";
import {IProfile} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {
  Content,
  ContentMention,
  ContentModel,
  EditorModel,
  IOnLinksModelChangeModel,
  Link,
  LinkEventEnum,
  MediaFileUploaded,
} from "@publisher/content.interface";
import {OauthService} from "@shared/channel/oauth.service";
import {GroupedPublishedContent} from "@shared/notifications/notification.interface";
import {
  PhotoEditorComponent,
  PhotoEditorExportModel,
  PhotoEditorImportModel,
} from "@shared/photo-editor/photo-editor.component";
import {UserMediaService} from "@shared/user/user-media.service";
import {ChannelPermissionsEnum} from "@shared/user/user.interface";
import {UserService} from "@shared/user/user.service";
import {getMentionsFromQuillText} from "@shared/utils/getMentionsFromQuillText.function";
import {guid} from "@shared/utils/guid.function";
import {PromptDialogComponent} from "@shared/utils/prompt-dialog/prompt-dialog.component";
import {setMediaFileMetadata} from "@shared/utils/setMediaFileMetadata.function";
import {stripHtmlForQuillText} from "@shared/utils/strip-html.function";
import {getTextFromTokens, getTokensFromHtml, getTokensFromLegacyText} from "@shared/utils/token.function";
import {unique} from "@shared/utils/unique.function";
import {FbScrapeResponse} from "@shared/utils/url/url-metadata.interface";
import {FromContentLink, getTrackingUrl, htmlEncode} from "@utils/link.function";
import {take} from "rxjs/operators";
import {ComposerActionsComponent} from "./composer-actions/composer-actions.component";
import {HashtagTokenBehaviour} from "./composer-editor/token/HashtagTokenBehaviour";
import {LinkTokenBehaviour} from "./composer-editor/token/LinkTokenBehaviour";
import {MentionTokenBehaviour} from "./composer-editor/token/MentionTokenBehaviour";
import {NewLineTokenBehaviour} from "./composer-editor/token/NewLineTokenBehaviour";
import {TextTokenBehaviour} from "./composer-editor/token/TextTokenBehaviour";
import {ComposerTokenTypeEnum, EditorsState, IComposerToken} from "./composer-editor/token/token.interface";
import {ComposerValidationService} from "./composer-validation/composer-validation.service";
import {FacebookMediaValidation} from "./composer-validation/media-validation/FacebookMediaValidation";
import {InstagramMediaValidation} from "./composer-validation/media-validation/InstagramMediaValidation";
import {LinkedInMediaValidation} from "./composer-validation/media-validation/LinkedInMediaValidation";
import {TwitterMediaValidation} from "./composer-validation/media-validation/TwitterMediaValidation";
import {ComposerService} from "./composer.service";
import {ShareLinkedInOverlayData} from "./share-to-emplyee/share-to-employee.component";
import {EditVideoComponent} from "./edit-video/edit-video.component";
import {PanelOverlayService} from "@shared/overlay-panel/panel-overlay.service";

export interface ISelectedProfile {
  profile: IProfile;

  // for FB Groups you can 'publish as' another profile.
  // If the user wants to do this, publishProfile is that profile to publish as.
  // For all other types of profiles, this will be null.
  // If the profile is a FB Group, publishProfile can also be null, it means we'll publish as the FB Group
  publishAsProfileForFbGroups?: IProfile;

  // TODO: Profile also has a 'selected' attribute. What is the difference? Can we get rid of one?
  // Unclear if they were intended to be different.
  // Suggestion is to remove Selected from the backend (EF Profile) and the Typescript Profile in profile.interface.ts.
  selected: boolean;

  // For FB Groups, this is the owner's personal page + any children of the profile.LinkedProfiles
  // Suggestion rename to linkedPublishAsProfilesFBGroup
  linkedPublishAsProfilesFbGroup?: IProfile[];
}

export interface IZapierProcessedProfile {
  profile: IProfile;
  processed: boolean; // If the user has already worked through it, either if he configured it or skipped it.
  configured: boolean; // If the user was able to create a zap successfully.
  selected: boolean; // If the user selected profile to configure on Zapier.
  confirmed: boolean; // Confirmed means we got a subscription from Zapier to our web hooks.
}

// TODO: The name of this interface is not social network specific, but the only non-null attribute is specific to FB?
export interface IComposerData {
  content?: Content;
  publishNow?: boolean;
  urlMetadata?: UrlMetadata[];
  text?: string;
  facebookLink: string;
}

@UntilDestroy()
@Component({
  selector: "app-composer",
  templateUrl: "./composer.component.html",
  styleUrls: ["./composer.component.scss"],
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-920px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-920px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class ComposerComponent extends PanelOverlay implements OnInit, OnDestroy {
  changesMade = false;

  editorModel: EditorModel = {
    rawText: null,
    text: null,
  };
  model: ContentModel = {};

  // Represents the editors shown. see setProviders() below.
  activeEditorsProfileTypes = [ProfileTypes.Unknown];

  // Array of all the visible editors. Only one will be true, the selected, active editor.
  activeEditors: boolean[] = [true];

  loading = true;
  bootingComposer = true;
  includeComment = false;
  facebookMetadataData: FbScrapeResponse;
  onlySchedule = false;
  haveSubmitted = false;
  providerBeingEdited: number;
  importModel: PhotoEditorImportModel;
  editSchedule: boolean;
  hardRefreshComposerUi = false;

  initialMentions: ContentMention[];
  mediaFiles: MediaFileUploaded[] = [];

  @ViewChild("composerAction", {static: false})
  composerAction: ComposerActionsComponent;
  @ViewChild("container", {read: ViewContainerRef})
  container: ViewContainerRef;

  public errorMessage: string;
  firstEditEvents: boolean;

  public get customize(): boolean {
    return this.composerService.customizeMode;
  }
  public set customize(value: boolean) {
    this.composerService.customizeMode = value;
  }

  public get editMode(): boolean {
    return this.composerService.editMode;
  }

  private windowPoll: any;
  public windowReference: Window;

  waitingForAuthenticationProfile: IProfile;

  public get profileLabels(): string[] {
    return ProfileLabelsFromId;
  }

  constructor(
    dialogRef: PanelOverlayRef<ComposerComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IComposerData,
    private readonly sanitizer: DomSanitizer,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly profileService: ProfileService,
    public composerValidation: ComposerValidationService,
    private readonly mediaService: UserMediaService,
    public composerService: ComposerService,
    public oauthService: OauthService,
    private userService: UserService,
    private editVideoPanelOverlayService: PanelOverlayService<EditVideoComponent>,
  ) {
    super(dialogRef, data);
    if (data?.content) {
      this.composerService.editMode = true;
    }
  }

  ngOnDestroy(): void {
    this.composerValidation.ResetValidation();
    this.haveSubmitted = false;
  }

  ngOnInit() {
    this.profileService.profileModifiedEvent
      .pipe(untilDestroyed(this))
      .subscribe((profile) => this.OnProfileModified(profile));

    this.composerService.onMediaModelChange.pipe(untilDestroyed(this)).subscribe((mediaFiles) => {
      if (!this.firstEditEvents) {
        this.changesMade = true;
      }

      this.mediaFiles = [...mediaFiles];
      this.model.MediaFiles = [...mediaFiles];
      this.editorModel.MediaFiles = [...mediaFiles];
      if (this.haveSubmitted) {
        this.composerValidation.ValidateComposer(
          null,
          this.profileService.allProfilesObservable.value,
          this.composerService.selectedProfiles,
          this.model,
          null,
        );
      }
      this.composerService.triggerUpdateComposerUi();
    });

    this.composerService.onLinksModelChange.pipe(untilDestroyed(this)).subscribe((model: IOnLinksModelChangeModel) => {
      this.addLinksToText(this.model, this.providerBeingEdited, model.Event);
    });

    this.profileService
      .list()
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe((profiles: IProfile[]) => {
        this.bootingComposer = false;

        this.loading = false;

        this.composerService.initializeSelectedProfiles(profiles);

        if (!this.data || !this.data.content) {
          if (
            this.profileService.allProfilesObservable.value.length === 1 &&
            this.composerService.selectedProfiles.length == 0
          ) {
            const profile = this.composerService.mapProfileToSelectedProfile(
              this.profileService.allProfilesObservable.value[0],
              false,
            );
            this.toggleSelectProfile(profile);
            this.composerService.triggerUpdateComposerUi();
          }
        }

        if (this.data) {
          this.composerService.selectedProfiles = [];
          if (this.data.content && !this.data.content.HasNoContent) {
            const groupPublishContent = this.data.content as unknown as GroupedPublishedContent;
            if (
              groupPublishContent &&
              groupPublishContent.PublishEvents &&
              groupPublishContent.PublishEvents.length > 0 &&
              groupPublishContent.PublishEvents.some((x) => x.ErrorMessage)
            ) {
              this.onlySchedule = true;

              this.setContentForFailedPublishedEvents(groupPublishContent);
            } else {
              if (this.data.content?.ScheduleModel?.FrequencyType == "OneTime") {
                this.editSchedule = true;
              }
              this.setContent(this.data.content);
            }
            this.composerService.editMode = true;
          }
          if (this.data.text && this.data.urlMetadata) {
            this.addLink(this.data.urlMetadata[0].Url);
            this.hardRefreshComposerUi = true;
          }
        }
      });

    this.composerService.toggleChannelSelection$.pipe(untilDestroyed(this)).subscribe((selectedProfile) => {
      this.changesMade = true;
      this.toggleSelectProfile(selectedProfile);
      if (this.composerService.selectedProfiles.length == 1) this.composerService.triggerUpdateComposerUi();
    });
  }

  // onSelectedProfile(profile: ISelectedProfile, linkedProfile: IProfile, matMenuTrigger: MatMenuTrigger) {
  //   if (profile.profile.Type == ProfileTypes.Facebook && profile.profile.Subtype == "FacebookGroup") {
  //     if (linkedProfile == null && profile.publishAsProfileForFbGroups == null) {
  //       matMenuTrigger.openMenu();
  //       return;
  //     }
  //     profile.publishAsProfileForFbGroups = linkedProfile;
  //     this.toggleSelectProfile(profile);
  //   } else {
  //     this.toggleSelectProfile(profile);
  //   }
  // }

  onLinkedChannelSelected(selectedProfile: ISelectedProfile, event) {
    selectedProfile.publishAsProfileForFbGroups = event.option.value;
  }

  hasPermission(selectedProfile: ISelectedProfile) {
    const roles = this.userService.userObservable.value?.ChannelsRoles;

    if (roles == null) return false;

    return roles.some(
      (x) =>
        x.ChannelId == selectedProfile.profile.AssociatedSource.Id &&
        x.Permissions.includes(ChannelPermissionsEnum.Publish),
    );
  }

  // when you click on a profile this method is called to either select or unselect it
  toggleSelectProfile(profileToSelect: ISelectedProfile) {
    const sameProfiles = this.composerService.selectedProfiles.find(
      (p) => p.profile.Type === profileToSelect.profile.Type,
    );
    const isNewNetwork = sameProfiles === undefined;

    const isProfileSelected = this.composerService.selectedProfiles.some(
      (x) => x.profile.Id == profileToSelect.profile.Id,
    );

    if (!isProfileSelected) {
      const selectedProfiles = this.composerService.selectedProfiles;

      if (profileToSelect.profile.Type == ProfileTypes.TwitterAccount) {
        const currentlySelectedTwitterProfileIndex = selectedProfiles.findIndex(
          (p) => p.profile.Type === ProfileTypes.TwitterAccount,
        );

        if (currentlySelectedTwitterProfileIndex > -1) {
          selectedProfiles.splice(currentlySelectedTwitterProfileIndex, 1);
          this.snackBar.open("You can select only one Twitter channel at a time");
        }
      }

      profileToSelect.selected = true;
      this.composerService.selectedProfiles = [...selectedProfiles, profileToSelect];
    } else {
      this.composerService.selectedProfiles = this.composerService.selectedProfiles.filter(
        (x) => x.profile.Id != profileToSelect.profile.Id,
      );
    }

    const selectedNetworks = unique(
      this.composerService.selectedProfiles.map((x) => x.profile),
      "Type",
    ).map((x) => x.Type);

    if (!this.editMode && selectedNetworks.length <= 1) {
      //When the composer only has 1 selected network it defaults to non customize mode.
      this.customize = false;
    }
    this.setActiveEditorsProfileTypes();

    this.updateMentions();

    if (!this.customize && isNewNetwork) this.composerService.onClearMentions.emit();

    // when you select a new profile, if it is the first instance of this Type, copy the media from the original post.
    if (this.editMode && isNewNetwork) {
      if (this.activeEditorsProfileTypes) {
        const copyFrom = this.activeEditorsProfileTypes.filter((x) => x != profileToSelect.profile.Type)[0];

        this.composerService.copyMediaFromToProvider(copyFrom, profileToSelect.profile.Type);
      }
    }

    if (this.haveSubmitted) {
      this.composerValidation.ValidateProfiles(
        this.profileService.allProfilesObservable.value,
        this.composerService.selectedProfiles,
      );

      if (profileToSelect.selected) {
        this.composerValidation.TriggerSocialMediaValidationByType(
          profileToSelect.profile.Type,
          this.model,
          this.composerService.selectedProfiles,
        );
      }
    }

    // if (this.editMode == false) {
    //   //with tokens this might not be needed
    //   // this.onEditorChange(this.editorModel, profileToSelect.profile.Type);
    // }

    if (isNewNetwork) {
      this.composerService.reevaluateDynamicParameters();
    }

    if (this.haveSubmitted && this.composerValidation.GeneralValidation.ComposerHasErrors)
      this.composerValidation.ValidateComposer(
        null,
        this.profileService.allProfilesObservable.value,
        this.composerService.selectedProfiles,
        this.model,
        null,
      );
  }

  get profilesTypes(): number[] {
    return this.composerService.selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();
  }

  private setActiveEditorsProfileTypes() {
    if (this.customize && this.profilesTypes.length > 0) {
      this.activeEditorsProfileTypes = [...this.profilesTypes];
      this.activeEditors = [true];
      if (this.profilesTypes.length > 0) this.providerBeingEdited = this.profilesTypes[0];
    } else {
      this.activeEditorsProfileTypes = [ProfileTypes.Unknown];
      this.setActive(0, 0);
    }
  }

  onFacebookMetadataChange(response: FbScrapeResponse) {
    if (response == null) {
      this.facebookMetadataData = null;
      this.model.FacebookUrlDescription = null;
      this.model.FacebookUrlTitle = null;
      this.model.FacebookUrlPicture = null;
      this.model.FacebookUrl = null;
    } else {
      this.facebookMetadataData = response;
      this.model.FacebookUrlDescription = response.description;
      this.model.FacebookUrlTitle = response.title;
      this.model.FacebookUrlPicture = response.image && response.image.length > 0 ? response.image[0].url : "";
    }

    this.composerService.triggerUpdateComposerUi();
  }

  close(response?: any) {
    if (this.changesMade && !response) {
      this.dialog
        .open(PromptDialogComponent, {
          width: "504px",
          autoFocus: false,
          panelClass: ["app-dialog", "discard-changes-composer-dialog"],
          data: {
            title: "Discard Changes",
            subtitle: `You've made changes that haven't been published or saved yet. If you discard changes, your unpublished changes will be deleted. You can't undo this action.`,
            buttons: {
              cancel: "Cancel",
              confirm: "Discard Changes",
            },
          },
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((result: boolean) => {
          if (result) {
            this.closeComposer(response);
          }
        });
    } else this.closeComposer(response);
  }

  closeComposer(response: any) {
    for (let index = 0; index < this.composerService.quillMentionsModules.length; index++) {
      const element = this.composerService.quillMentionsModules[index];
      if (element != null) {
        try {
          element.openMenu("");
        } catch {
          // When switching between customize and non-customize mode, quillMentionsModules array may have references to a module
          // that throws "TypeError: Cannot read properties of null (reading 'index')" when openMenu() is called.
        }
      }
    }

    this.dialogRef.close(response);

    //For some reason if you run this function while calling x.openMenu reset doesnt work even though the calls
    //seem to be sync. If you remove this timeout metadata will stay in the composer next time you open it.
    setTimeout(() => {
      this.composerService.reset();
    }, 400);
  }

  onEditorChange(model: EditorModel, provider: number) {
    const tokens = getTokensFromHtml(model.text);
    model.text = getTextFromTokens(tokens, provider, {links: this.composerService.getLinksToSendToServer()});
    if (!this.firstEditEvents) this.changesMade = true;
    if (this.activeEditorsProfileTypes.length === 1) {
      this.model = {
        Id: this.model.Id,
        FacebookText: model.text,
        TwitterText: model.text,
        InstagramText: model.text,
        LinkedInText: model.text,
        FacebookEditorText: model.text,
        TwitterEditorText: model.text,
        InstagramEditorText: model.text,
        LinkedInEditorText: model.text,
        LinkedInArticleLink: this.model.LinkedInArticleLink,
        LinkedInArticleLinkCaption: this.model.LinkedInArticleLinkCaption,
        LinkedInArticleLinkDescription: this.model.LinkedInArticleLinkDescription,
        LinkedInArticleLinkPicture: this.model.LinkedInArticleLinkPicture,
        LinkedInArticleLinkDomain: this.model.LinkedInArticleLinkDomain,
        TwitterUrlMetadata: this.model.TwitterUrlMetadata,
        MediaFiles: this.mediaFiles,
        language: model.inputLanguage,
        InstagramComment: model.commentText,
        PublishMessageMentions: this.model.PublishMessageMentions,
        Links: this.composerService.getLinksToSendToServer(),
        Tokens: tokens,
        LinkedInArticleCaptionEdited: this.model.LinkedInArticleCaptionEdited,
        LinkedInArticlePictureEdited: this.model.LinkedInArticlePictureEdited,
      };

      this.editorModel = model;
      this.editorModel.text = model.text;
      this.editorModel.rawText = model.rawText;
      this.editorModel.TwitterUrlMetadata = this.model.TwitterUrlMetadata;
      this.editorModel.inputLanguage = model.inputLanguage;
      this.editorModel.commentText = model.commentText;

      const currentActiveNetwork = this.activeEditorsProfileTypes[0];

      this.composerService.setTokensForProvider(tokens, currentActiveNetwork);

      this.addLinksToText(this.model, currentActiveNetwork);

      if (this.haveSubmitted) {
        this.composerValidation.ValidateComposer(
          null,
          this.profileService.allProfilesObservable.value,
          this.composerService.selectedProfiles,
          this.model,
          null,
        );
      }
    } else {
      if (provider === ProfileTypes.Facebook) {
        this.model.FacebookText = model.text;
        this.model.FacebookEditorText = model.text;
        this.TryTriggerValidationForProfileType(
          ProfileTypes.Facebook,
          this.model,
          this.composerService.selectedProfiles,
        );
      } else if (provider === ProfileTypes.TwitterAccount) {
        this.model.TwitterText = model.text;
        this.model.TwitterEditorText = model.text;
        this.TryTriggerValidationForProfileType(
          ProfileTypes.TwitterAccount,
          this.model,
          this.composerService.selectedProfiles,
        );
      } else if (provider === ProfileTypes.InstagramAccount) {
        this.model.InstagramText = model.text;
        this.model.InstagramEditorText = model.text;
        this.model.InstagramComment = model.commentText;
        this.TryTriggerValidationForProfileType(
          ProfileTypes.InstagramAccount,
          this.model,
          this.composerService.selectedProfiles,
        );
      } else if (provider == ProfileTypes.LinkedIn) {
        this.model.LinkedInText = model.text;
        this.model.LinkedInEditorText = model.text;
        this.model.language = model.inputLanguage;
        this.editorModel.inputLanguage = model.inputLanguage;

        this.TryTriggerValidationForProfileType(
          ProfileTypes.LinkedIn,
          this.model,
          this.composerService.selectedProfiles,
        );
      }

      this.addLinksToText(this.model, provider);

      this.composerService.setTokensForProvider(tokens, provider);
    }

    this.updateMentions();

    if (this.hardRefreshComposerUi) {
      this.composerService.triggerUpdateComposerUi();
      this.hardRefreshComposerUi = false;
    }
  }

  updateMentions() {
    if (this.customize) {
      this.updateMentionsForCustomizeMode();
    } else {
      this.updateMentionsForNonCustomizeMode();
    }
  }

  updateMentionsForCustomizeMode() {
    const facebookEditor = this.composerService.getEditorToModify(ProfileTypes.Facebook);
    const twitterEditor = this.composerService.getEditorToModify(ProfileTypes.TwitterAccount);
    const linkedInEditor = this.composerService.getEditorToModify(ProfileTypes.LinkedIn);
    const instagramEditor = this.composerService.getEditorToModify(ProfileTypes.InstagramAccount);

    facebookEditor.Mentions = getMentionsFromQuillText(this.model.FacebookEditorText, ProfileTypes.Facebook).mentions;
    twitterEditor.Mentions = getMentionsFromQuillText(
      this.model.TwitterEditorText,
      ProfileTypes.TwitterAccount,
    ).mentions;
    linkedInEditor.Mentions = getMentionsFromQuillText(this.model.LinkedInEditorText, ProfileTypes.LinkedIn).mentions;
    instagramEditor.Mentions = getMentionsFromQuillText(
      this.model.InstagramEditorText,
      ProfileTypes.InstagramAccount,
    ).mentions;
  }

  updateMentionsForNonCustomizeMode() {
    const selectedNetworks = unique(
      this.composerService.selectedProfiles.filter((x) => x.selected).map((x) => x.profile),
      "Type",
    ).map((x) => x.Type);

    if (selectedNetworks.length == 1) {
      this.updateMentionsForCustomizeMode();
    } else {
      //When we are on non customize mode and we have more than 1 network selected
      //mentions should get removed. We use getMentionsFromQuillText just to get the plain text without
      //the html element of the mention.

      const facebookEditor = this.composerService.getEditorToModify(ProfileTypes.Facebook);
      const twitterEditor = this.composerService.getEditorToModify(ProfileTypes.TwitterAccount);
      const linkedInEditor = this.composerService.getEditorToModify(ProfileTypes.LinkedIn);
      const instagramEditor = this.composerService.getEditorToModify(ProfileTypes.InstagramAccount);

      facebookEditor.Mentions = [];
      twitterEditor.Mentions = [];
      linkedInEditor.Mentions = [];
      instagramEditor.Mentions = [];

      this.model.FacebookEditorText = this.model.FacebookText = getMentionsFromQuillText(
        this.model.FacebookText,
        ProfileTypes.Facebook,
      ).text;
      this.model.TwitterEditorText = this.model.TwitterText = getMentionsFromQuillText(
        this.model.TwitterText,
        ProfileTypes.TwitterAccount,
      ).text;
      this.model.LinkedInEditorText = this.model.LinkedInText = getMentionsFromQuillText(
        this.model.LinkedInText,
        ProfileTypes.LinkedIn,
      ).text;
      this.model.InstagramEditorText = this.model.InstagramText = getMentionsFromQuillText(
        this.model.LinkedInText,
        ProfileTypes.InstagramAccount,
      ).text;

      this.composerService.triggerUpdateComposerUi();
    }
  }

  // This controls how urls are displayed in the composer preview.
  transformUrlsInNetworkText(editorLinks: Link[], modelNetworkText: string) {
    if (editorLinks === undefined || editorLinks.length === 0) {
      return modelNetworkText;
    }

    editorLinks.forEach((link) => {
      if (link.IsShorten) {
        modelNetworkText = modelNetworkText?.replace(htmlEncode(getTrackingUrl(link)), link.ShortenUrl);
      } else {
        if (link.ShortenUrl.length > 0) {
          modelNetworkText = modelNetworkText?.replace(link.ShortenUrl, htmlEncode(getTrackingUrl(link)));
        }
      }
    });
    return modelNetworkText;
  }

  addLinksToText(model: ContentModel, provider: ProfileTypes, event: LinkEventEnum = LinkEventEnum.None) {
    if (provider == ProfileTypes.Facebook && model.FacebookText) {
      const editorLinks = this.composerService.composerEditorsModel.Facebook.links;

      model.FacebookText = this.transformUrlsInNetworkText(editorLinks, model.FacebookEditorText);
    } else if (provider == ProfileTypes.InstagramAccount && model.InstagramText) {
      const editorLinks = this.composerService.composerEditorsModel.InstagramAccount.links;

      model.InstagramText = this.transformUrlsInNetworkText(editorLinks, model.InstagramEditorText);
    } else if (provider == ProfileTypes.LinkedIn && model.LinkedInText) {
      const editorLinks = this.composerService.composerEditorsModel.LinkedIn.links;
      model.LinkedInText = this.transformUrlsInNetworkText(editorLinks, model.LinkedInEditorText);
    } else if (provider == ProfileTypes.TwitterAccount && model.TwitterText) {
      const editorLinks = this.composerService.composerEditorsModel.TwitterAccount.links;
      model.TwitterText = this.transformUrlsInNetworkText(editorLinks, model.TwitterEditorText);
    } else if (provider == ProfileTypes.Unknown) {
      const editorLinks = this.composerService.composerEditorsModel.All.links;
      if (editorLinks === undefined || editorLinks.length === 0) {
        return;
      }

      let twitterEditorText = model.TwitterEditorText;
      let facebookEditorText = model.FacebookEditorText;
      let instagramEditorText = model.InstagramEditorText;
      let linkedInEditorText = model.LinkedInEditorText;

      // let idGuid = guid();

      editorLinks.forEach((link) => {
        const linkTrackingUrl = htmlEncode(getTrackingUrl(link));

        if (link.IsShorten) {
          // console.log(`${linkTrackingUrl} -> ${link.ShortenUrl}`);

          twitterEditorText = twitterEditorText?.replace(linkTrackingUrl, link.ShortenUrl);

          facebookEditorText = facebookEditorText?.replace(linkTrackingUrl, link.ShortenUrl);

          instagramEditorText = instagramEditorText?.replace(linkTrackingUrl, link.ShortenUrl);

          linkedInEditorText = linkedInEditorText?.replace(linkTrackingUrl, link.ShortenUrl);
        } else if (event == LinkEventEnum.Unshorten) {
          if (link.ShortenUrl != "") {
            // console.log(`${link.ShortenUrl} -> ${linkTrackingUrl}`);

            twitterEditorText = twitterEditorText?.replace(link.ShortenUrl, linkTrackingUrl);

            facebookEditorText = facebookEditorText?.replace(link.ShortenUrl, linkTrackingUrl);

            instagramEditorText = instagramEditorText?.replace(link.ShortenUrl, linkTrackingUrl);

            linkedInEditorText = linkedInEditorText?.replace(link.ShortenUrl, linkTrackingUrl);
          }
        }
      });

      // console.log({second : twitterEditorText});
      model.TwitterText = twitterEditorText;
      model.FacebookText = facebookEditorText;
      model.InstagramText = instagramEditorText;
      model.LinkedInText = linkedInEditorText;
    }
  }

  public onCommentEditorChange(model: EditorModel, provider: number) {
    if (provider == ProfileTypes.InstagramAccount) {
      this.model.InstagramComment = stripHtmlForQuillText(model.commentText);
    }
  }

  toggleCustomization() {
    this.composerService.quillMentionsModules = [];
    this.customize = !this.customize;
    this.setActiveEditorsProfileTypes();
    const hasFacebook = this.composerService.selectedProfiles.some((x) => x.profile.Type === ProfileTypes.Facebook);
    const hasTwitter = this.composerService.selectedProfiles.some(
      (x) => x.profile.Type === ProfileTypes.TwitterAccount,
    );
    const hasInstagram = this.composerService.selectedProfiles.some(
      (x) => x.profile.Type === ProfileTypes.InstagramAccount,
    );
    const hasLinkedIn = this.composerService.selectedProfiles.some((x) => x.profile.Type === ProfileTypes.LinkedIn);

    if (!this.customize) {
      this.providerBeingEdited = hasFacebook
        ? ProfileTypes.Facebook
        : hasTwitter
          ? ProfileTypes.TwitterAccount
          : hasInstagram
            ? ProfileTypes.InstagramAccount
            : hasLinkedIn
              ? ProfileTypes.LinkedIn
              : ProfileTypes.Unknown;

      const nonCustomizeModeText = this.composerService.getEditorToModify(ProfileTypes.Unknown).rawText;

      this.model.FacebookText = nonCustomizeModeText;
      this.model.TwitterText = nonCustomizeModeText;
      this.model.LinkedInText = nonCustomizeModeText;
      this.model.InstagramText = nonCustomizeModeText;

      this.composerService.deactivateCustomizeMode();
    } else {
      this.composerService.activateCustomizeMode({
        hasFacebook: hasFacebook,
        hasInstagram: hasInstagram,
        hasTwitter: hasTwitter,
        hasLinkedIn: hasLinkedIn,
      });
    }
  }

  limitChars(provider: number): boolean {
    if (!this.customize) {
      return this.composerService.selectedProfiles.some((x) => x.profile.Type == ProfileTypes.TwitterAccount);
    }
    return provider === ProfileTypes.TwitterAccount;
  }

  setContentForFailedPublishedEvents(content: GroupedPublishedContent) {
    let failedPublishedEvents = [];
    let publishEventsIds = [];

    this.composerService.reset();
    this.customize = true;

    const defaultText = content.FacebookText ?? content.TwitterText ?? content.InstagramText ?? content.LinkedInText;
    this.composerService.setTokensForProvider(
      getTokensFromLegacyText(defaultText, content.PublishMessageMentions, ProfileTypes.Unknown),
      ProfileTypes.Unknown,
    );

    const mediaFiles: MediaFileUploaded[] = content.MediaFiles.map((file) => {
      return {
        ...file,
        uploaded: true,
        preview: this.sanitizer.bypassSecurityTrustResourceUrl(file.PreviewUrl),
      };
    });

    const promises = [];

    mediaFiles.forEach((x) => promises.push(setMediaFileMetadata(x)));

    Promise.all(promises).then(() => {
      this.composerService.setMediaFiles(content.Profiles, mediaFiles);
    });

    if (content && content.PublishEvents && content.PublishEvents.length > 0) {
      failedPublishedEvents = content.PublishEvents.filter((x) => x.ErrorMessage);
      publishEventsIds = failedPublishedEvents.map((x) => x.Id);
      failedPublishedEvents
        .map((x) => x.Profile)
        .forEach((profile) => {
          const localProfile = this.profileService.allProfilesObservable.value.find((p) => p.Id === profile.Id);
          const localSelectedProfile = this.composerService.mapProfileToSelectedProfile(localProfile, true);
          this.toggleSelectProfile(localSelectedProfile);
        });

      failedPublishedEvents
        .map((x) => x.Type)
        .forEach((provider) => {
          if (provider === "Facebook") {
            if (content.FacebookText && content.FacebookText.length > 0) {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(content.FacebookText, content.PublishMessageMentions, ProfileTypes.Facebook),
                ProfileTypes.Facebook,
              );
            } else {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(defaultText, content.PublishMessageMentions, ProfileTypes.Facebook),
                ProfileTypes.Facebook,
              );
            }
          }

          if (provider === "TwitterAccount") {
            if (content.TwitterText && content.TwitterText.length > 0) {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(
                  content.TwitterText,
                  content.PublishMessageMentions,
                  ProfileTypes.TwitterAccount,
                ),
                ProfileTypes.TwitterAccount,
              );
            } else {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(defaultText, content.PublishMessageMentions, ProfileTypes.TwitterAccount),
                ProfileTypes.TwitterAccount,
              );
            }
          }

          if (provider === "InstagramAccount") {
            if (content.InstagramText && content.InstagramText.length > 0) {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(
                  content.InstagramText,
                  content.PublishMessageMentions,
                  ProfileTypes.InstagramAccount,
                ),
                ProfileTypes.InstagramAccount,
              );
            } else {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(defaultText, content.PublishMessageMentions, ProfileTypes.InstagramAccount),
                ProfileTypes.InstagramAccount,
              );
            }
          }

          if (provider === "LinkedIn") {
            if (content.LinkedInText && content.LinkedInText.length > 0) {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(content.LinkedInText, content.PublishMessageMentions, ProfileTypes.LinkedIn),
                ProfileTypes.LinkedIn,
              );
            } else {
              this.composerService.setTokensForProvider(
                getTokensFromLegacyText(defaultText, content.PublishMessageMentions, ProfileTypes.LinkedIn),
                ProfileTypes.LinkedIn,
              );
            }
          }
        });
    }

    this.model = {
      FacebookText: content.FacebookText,
      TwitterText: content.TwitterText,
      InstagramText: content.InstagramText,
      FacebookUrl: content.FacebookUrl,
      TwitterUrlMetadata: content.TwitterUrlMetadata,
      PublishEventsResolved: publishEventsIds,
      LinkedInText: content.LinkedInText,
      Id: content.Id,
      PublishMessageMentions: content.PublishMessageMentions,
    };

    this.editorModel = {
      ...this.editorModel,
      TwitterUrlMetadata: content.TwitterUrlMetadata,
      Mentions: content.PublishMessageMentions,
    };

    const linkedInEditor = this.composerService.getEditorToModify(ProfileTypes.LinkedIn);

    linkedInEditor.LikeOnBehalfOfEmployees = content.LikeOnBehalfOfEmployees;
    linkedInEditor.ShareOnBehalfOfEmployees = content.ShareOnBehalfOfEmployees;
    linkedInEditor.LinkedInShareCommentary = content.LinkedInShareCommentary;
    linkedInEditor.EmployeeGroupNames = content.EmployeeGroupNames;
    linkedInEditor.Employees = content.Employees;
    linkedInEditor.ExcludeEmployees = content.ExcludeEmployees;
    linkedInEditor.IncludedEmployees = content.IncludedEmployees;
  }

  getInitialText(provider) {
    if (!this.composerService.customizeMode) provider = ProfileTypes.Unknown;

    const editor = this.composerService.getEditorToModify(provider);

    const text = getTextFromTokens(editor.tokens, provider, {links: editor.links});

    return text;
  }

  setContent(content: Content) {
    this.composerService.reset();

    this.firstEditEvents = true;
    this.composerService.editMode = true;
    this.customize = true;

    this.initialMentions = [...content.PublishMessageMentions];

    // content.Profiles.forEach((profile) => {
    //   const index = this.profiles.findIndex((p) => p.profile.Id === profile.Id);
    //   const localProfile = this.profiles[index];
    //   localProfile.publishAsProfileForFbGroups = profile.PublishProfile;
    //   this.toggleSelectProfile(localProfile);
    // });

    if (!content.Profiles || content.Profiles.length == 0) {
      this.composerService.selectedProfiles = [];
    }

    content.Profiles.forEach((profile) => {
      const profileFound = this.profileService.allProfilesObservable.value.find((p) => p.Id === profile.Id);
      const localProfile = this.composerService.mapProfileToSelectedProfile(profileFound, true);
      this.toggleSelectProfile(localProfile);
    });

    const links = content.Links.map((x) => FromContentLink(x));

    this.setTokensFromContentToService(content);

    this.composerService.setEditorLinks(content.Profiles, links);

    const mediaFiles: MediaFileUploaded[] = content.MediaFiles.map((file) => {
      return {
        ...file,
        uploaded: true,
        preview: this.sanitizer.bypassSecurityTrustResourceUrl(file.PreviewUrl),
      };
    });

    const promises = [];

    mediaFiles.forEach((x) => promises.push(setMediaFileMetadata(x)));

    Promise.all(promises).then(() => {
      this.composerService.setMediaFiles(content.Profiles, mediaFiles);

      this.model = {
        ...this.model,
        FacebookUrl: content.FacebookUrl,
        TwitterUrlMetadata: content.TwitterUrlMetadata,
        Id: content.Id,
        InstagramComment: content.InstagramComment,
        PublishMessageMentions: content.PublishMessageMentions,
        Links: content.Links,
        FacebookEditorText: content.FacebookText,
        TwitterEditorText: content.TwitterText,
        InstagramEditorText: content.InstagramText,
        LinkedInEditorText: content.LinkedInText,
        LinkedInArticleCaptionEdited: content.LinkedInArticleCaptionEdited,
        LinkedInArticlePictureEdited: content.LinkedInArticlePictureEdited,
      };

      this.editorModel = {
        ...this.editorModel,
        TwitterUrlMetadata: content.TwitterUrlMetadata,
        text: "",
        rawText: "",
        Mentions: content.PublishMessageMentions,
        commentText: content.InstagramComment,
        ShareOnBehalfOfEmployees: content.ShareOnBehalfOfEmployees,
        LikeOnBehalfOfEmployees: content.LikeOnBehalfOfEmployees,
        LinkedInShareCommentary: content.LinkedInShareCommentary,
        ExcludeEmployees: content.ExcludeEmployees,
        IncludedEmployees: content.IncludedEmployees,
        EmployeeGroupNames: content.EmployeeGroupNames,
        sharing:
          content?.ExcludeEmployees?.length > 0 ||
          content?.IncludedEmployees?.length > 0 ||
          content.LikeOnBehalfOfEmployees ||
          content.ShareOnBehalfOfEmployees ||
          (content.EmployeeGroupNames && content.EmployeeGroupNames != "none") ||
          content.LinkedInShareCommentary?.length > 0,
        Employees: content.Employees,
        EmployeeGroupsConfig: content.EmployeeGroupsConfig,
      };

      const linkedInEditor = this.composerService.getEditorToModify(ProfileTypes.LinkedIn);

      linkedInEditor.LikeOnBehalfOfEmployees = content.LikeOnBehalfOfEmployees;
      linkedInEditor.ShareOnBehalfOfEmployees = content.ShareOnBehalfOfEmployees;
      linkedInEditor.LinkedInShareCommentary = content.LinkedInShareCommentary;
      linkedInEditor.EmployeeGroupNames = content.EmployeeGroupNames;
      linkedInEditor.Employees = content.Employees;
      linkedInEditor.ExcludeEmployees = content.ExcludeEmployees;
      linkedInEditor.IncludedEmployees = content.IncludedEmployees;
      linkedInEditor.EmployeeGroupsConfig = content.EmployeeGroupsConfig;

      if (linkedInEditor.links.length > 0) {
        linkedInEditor.links.forEach((x) => {
          x.isTitleEdited = content.LinkedInArticleCaptionEdited;
          x.isImageEdited = content.LinkedInArticlePictureEdited;
        });
      }

      this.addLinksToText(this.model, ProfileTypes.Facebook);
      this.addLinksToText(this.model, ProfileTypes.TwitterAccount);
      this.addLinksToText(this.model, ProfileTypes.LinkedIn);
      this.addLinksToText(this.model, ProfileTypes.InstagramAccount);
      this.addLinksToText(this.model, ProfileTypes.Unknown);

      if (content.InstagramComment) {
        this.includeComment = true;
      }

      setTimeout(() => {
        this.firstEditEvents = false;
      }, 700);
    });
  }

  setTokensFromContentToService(content: Content) {
    let editorsState: EditorsState = {
      editors: [],
    };

    if (content.EditorStatesJson != null && content.EditorStatesJson.length > 0) {
      editorsState = JSON.parse(content.EditorStatesJson) as EditorsState;
    }

    for (let index = 0; index < editorsState.editors.length; index++) {
      const editorState = editorsState.editors[index];

      if (editorState.tokens === undefined) {
        continue;
      }

      for (let tokenIndex = 0; tokenIndex < editorState.tokens.length; tokenIndex++) {
        const token = editorState.tokens[tokenIndex];

        if (token.tokenType == ComposerTokenTypeEnum.Hashtag) {
          token.behaviour = new HashtagTokenBehaviour(token.behaviour.originalValue);
        } else if (token.tokenType == ComposerTokenTypeEnum.Link) {
          token.behaviour = new LinkTokenBehaviour(token.behaviour.originalValue);
        } else if (token.tokenType == ComposerTokenTypeEnum.Mention) {
          const mentionStoredBehavior = token.behaviour as MentionTokenBehaviour;
          token.behaviour = new MentionTokenBehaviour(
            mentionStoredBehavior.id,
            mentionStoredBehavior.displayName,
            mentionStoredBehavior.sourceType,
          );
        } else if (token.tokenType == ComposerTokenTypeEnum.NewLine) {
          token.behaviour = new NewLineTokenBehaviour();
        } else if (token.tokenType == ComposerTokenTypeEnum.Text) {
          token.behaviour = new TextTokenBehaviour(token.behaviour.originalValue);
        }
      }
    }

    let defaultTokens: IComposerToken[] = [];

    if (content.InstagramText) {
      defaultTokens = setAndExtractTokens(ProfileTypes.InstagramAccount, content.InstagramText, this.composerService);
    }

    if (content.LinkedInText) {
      defaultTokens = setAndExtractTokens(ProfileTypes.LinkedIn, content.LinkedInText, this.composerService);
    }

    if (content.TwitterText) {
      defaultTokens = setAndExtractTokens(ProfileTypes.TwitterAccount, content.TwitterText, this.composerService);
    }

    if (content.FacebookText) {
      defaultTokens = setAndExtractTokens(ProfileTypes.Facebook, content.FacebookText, this.composerService);
    }

    //This code runs after the last block so that we have the default tokens set;

    if (!content.InstagramText) {
      this.composerService.setTokensForProvider(defaultTokens, ProfileTypes.InstagramAccount);
    }

    if (!content.TwitterText) {
      this.composerService.setTokensForProvider(defaultTokens, ProfileTypes.TwitterAccount);
    }

    if (!content.LinkedInText) {
      this.composerService.setTokensForProvider(defaultTokens, ProfileTypes.LinkedIn);
    }

    if (!content.FacebookText) {
      this.composerService.setTokensForProvider(defaultTokens, ProfileTypes.Facebook);
    }

    function setAndExtractTokens(profileType: ProfileTypes, text: string, composerService: ComposerService) {
      let tokens = editorsState.editors.find((x) => x.sourceType == profileType)?.tokens;

      if (!tokens) {
        tokens = getTokensFromLegacyText(text, content.PublishMessageMentions, profileType);
      }
      composerService.setTokensForProvider(tokens, profileType);
      return tokens;
    }
  }

  setActive(index: number, provider: number) {
    this.providerBeingEdited = provider;
    if (!this.activeEditors[index]) {
      this.activeEditors = this.activeEditors.map(() => false);
      this.activeEditors[index] = true;
    }
  }

  addLink(url: string) {
    //this.composerService.setInitialText(ProfileTypes.Unknown, stripHtmlForQuillText(this.data.text));
    this.composerService.setTokensForProvider(getTokensFromHtml(this.data.text), ProfileTypes.Unknown);

    this.model = {
      // FacebookText: this.data.text,
      // TwitterText: this.data.text,
      FacebookUrl: url,
      TwitterUrlMetadata: this.data.urlMetadata,
    };
    this.editorModel = {
      ...this.editorModel,
      text: this.data.text,
      rawText: this.data.text,
      TwitterUrlMetadata: this.data.urlMetadata,
    };
  }

  get enableMentions(): boolean {
    if (this.customize) {
      return true;
    }
    if (!this.composerService.selectedProfiles.length) {
      return false;
    }
    const hasOnlyFacebook = this.composerService.selectedProfiles.every(
      (p) => p.profile.Type === ProfileTypes.Facebook,
    );
    const hasOnlyTwitter = this.composerService.selectedProfiles.every(
      (p) => p.profile.Type === ProfileTypes.TwitterAccount,
    );
    const hasOnlyLinkedIn = this.composerService.selectedProfiles.every(
      (p) => p.profile.Type === ProfileTypes.LinkedIn,
    );

    return hasOnlyFacebook || hasOnlyTwitter || hasOnlyLinkedIn;
  }

  public addChannel() {
    this.dialog.open(AddChannelDialogComponent, {
      maxWidth: "804px",
      maxHeight: "90vh",
      panelClass: ["app-dialog", "add-channel"],
      autoFocus: false,
    });
  }

  // isProviderInstagram(provider): boolean {
  //   return provider === ProfileTypes.InstagramAccount;
  // }
  //
  // isProviderFacebook(provider): boolean {
  //   return provider === ProfileTypes.Facebook;
  // }
  //
  // isProviderTwitter(provider): boolean {
  //   return provider === ProfileTypes.TwitterAccount;
  // }

  isProviderLinkedIn(provider): boolean {
    return provider === ProfileTypes.LinkedIn;
  }

  onComposerSubmitFailed() {
    this.haveSubmitted = true;
  }

  TryTriggerValidationForProfileType(
    profileType: number,
    contentModel: ContentModel,
    selectedProfiles: ISelectedProfile[],
  ) {
    if (this.haveSubmitted) {
      this.composerValidation.TriggerSocialMediaValidationByType(profileType, contentModel, selectedProfiles);
    }
  }

  async onVideoClicked(media: MediaFileUploaded, provider: number) {
    this.editVideoPanelOverlayService
      .open(EditVideoComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        data: {
          media: media,
        },
      })
      .afterClosed.subscribe((result) => {
        media.PreviewUrl = result.thumbnailUrl;
        media.preview = this.sanitizer.bypassSecurityTrustResourceUrl(result.thumbnailUrl);
        this.composerService.replaceImage(provider, media.Id, media);
      });
  }

  async onMediaClicked(media: MediaFileUploaded, provider: number) {
    this.importModel = {
      Media: {
        Id: media.Id,
        FileName: media.FileName,
        BlobUrl: media.BlobUrl,
        MediaType: media.MediaType,
      },
      data: provider,
    };

    this.dialog
      .open(PhotoEditorComponent, {
        maxWidth: "90vw",
        maxHeight: "90vh",
        minWidth: "90vw",
        minHeight: "90vh",
        panelClass: ["app-dialog", "photo-editor-dialog"],
        autoFocus: false,
        data: this.importModel,
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((exportedModel) => {
        if (!exportedModel) {
          return;
        }
        this.fileExportedFromEditor(exportedModel);
      });
  }

  onShareEmployeesChange(shareModel: ShareLinkedInOverlayData, provider: ProfileTypes) {
    if (shareModel && (provider == ProfileTypes.LinkedIn || this.activeEditorsProfileTypes.length === 1)) {
      this.composerService.setEmployeeDataToProvider(shareModel, provider);
    }
  }

  onErrorMessage(event: string) {
    this.errorMessage = event;
  }

  OnProfileModified(profile: IProfile) {
    if (this.waitingForAuthenticationProfile != null) {
      if (this.waitingForAuthenticationProfile.Id == profile.Id) {
        this.waitingForAuthenticationProfile = null;
        this.windowReference = null;
      }
    }

    this.evaluateProfileResponse(profile);

    this.TryTriggerValidationForProfileType(profile.Type, this.model, this.composerService.selectedProfiles);
  }

  ReauthorizeProfile(profile: IProfile) {
    this.oauthService
      .reauthorize(profile.Type, true)
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.waitingForAuthenticationProfile = profile;
        this.windowReference = window.open(
          x,
          "_blank",
          "width=900,height=700,resizable=yes,top=0,left=0,status=1,popup=1",
        );
        this.windowPoll = setInterval(() => {
          const dialogClosedStatus = this.windowReference.closed;
          if (dialogClosedStatus === true) {
            clearInterval(this.windowPoll);
            this.updateReauthProfileStatus();
          }
        }, 1000);
      });
  }

  updateReauthProfileStatus() {
    this.profileService
      .get(this.waitingForAuthenticationProfile.Id)
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe((profile: IProfile) => {
        this.evaluateProfileResponse(profile);
      });
  }

  evaluateProfileResponse(profile: IProfile) {
    const displayedProfile = this.profileService.allProfilesObservable.value.find((x) => x.Id == profile.Id);
    displayedProfile.Credential.RequiresAttention = profile.Credential.RequiresAttention;
    displayedProfile.Credential.IsZapierWebhookEnabled = profile.Credential.IsZapierWebhookEnabled;

    const selectedProfiles = this.composerService.selectedProfiles;

    const index = selectedProfiles.findIndex((x) => x.profile.Id == profile.Id);

    if (index > -1) {
      selectedProfiles[index].profile.Credential.RequiresAttention = profile.Credential.RequiresAttention;

      selectedProfiles[index].profile.Credential.IsZapierWebhookEnabled = profile.Credential.IsZapierWebhookEnabled;

      this.composerService.selectedProfiles = selectedProfiles;
    }

    if (this.haveSubmitted) {
      this.composerValidation.ValidateComposer(
        null,
        this.profileService.allProfilesObservable.value,
        this.composerService.selectedProfiles,
        this.model,
        null,
      );
    }
  }

  public fileExportedFromEditor(editorExportedModel: PhotoEditorExportModel) {
    this.importModel = null;

    if (editorExportedModel.File.type.startsWith("image")) {
      const providerToUse = this.customize ? editorExportedModel.data : ProfileTypes.Unknown;

      this.composerService.markImageAsUploading(providerToUse, editorExportedModel.MediaFile.Id);

      this.mediaService
        .uploadMediaFile(editorExportedModel.File, null, guid())
        .pipe(untilDestroyed(this))
        .subscribe((mediaFile: MediaFileUploaded) => {
          setMediaFileMetadata(mediaFile).then((mediaFileUploaded) => {
            mediaFileUploaded.PreviewUrl = mediaFile.PreviewUrl;

            mediaFileUploaded.preview = this.sanitizer.bypassSecurityTrustResourceUrl(
              URL.createObjectURL(editorExportedModel.File),
            );

            mediaFileUploaded.instagramValidation = new InstagramMediaValidation();
            mediaFileUploaded.facebookValidation = new FacebookMediaValidation();
            mediaFileUploaded.twitterValidation = new TwitterMediaValidation();
            mediaFileUploaded.linkedInValidation = new LinkedInMediaValidation();
            this.composerService.replaceImage(providerToUse, editorExportedModel.MediaFile.Id, mediaFileUploaded);
          });
        });
    }
  }

  // public onEditorClosed() {
  //   this.importModel = null;
  // }

  public getProviderToSendToEditor(provider) {
    //When we are on edit mode the composer service is on customize mode on, so when the function
    //getMediaFilesToSendToServer is called it needs a provider to be able to return accurate results.
    if (this.editMode) return provider;

    return this.activeEditorsProfileTypes.length > 1 ? provider : null;
  }
}
