import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {SidenavService} from "@layout/sidenav/sidenav.service";
import {SafeHtml} from "@angular/platform-browser";

@UntilDestroy()
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(public sidenavService: SidenavService) {}

  @Input() pageTitle: SafeHtml;
  @Input() showMenuToggle = true;
  @Input() showCollapseToggle = true;
  @Output() menu: EventEmitter<void> = new EventEmitter();
  @Output() collapseSidenav: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {}
}
