import {Component, Input, OnChanges} from "@angular/core";
import {User} from "../user.interface";
import {ColorService} from "@utils/color-service/color.service";
import {ThemeService} from "@theme/theme.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.scss"],
})
export class UserAvatarComponent implements OnChanges {
  @Input() picture: string;
  @Input() user: User;
  @Input() size: string;
  initials: string;
  image: string;
  bgColor: string;
  fgColor: string;

  constructor(
    private colorService: ColorService,
    private themeService: ThemeService,
  ) {}

  ngOnChanges() {
    if (this.user) {
      this.image = this.user.Image ? this.user.Image : null;
      this.initials = this.getInitials();
      this.bgColor = this.user.HexColor;
      this.fgColor = this.colorService.generateFgColor(this.bgColor);
    } else {
      this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
        if (isDarkTheme) {
          this.image = this.picture ? this.picture : "/assets/icons/unknown-user-dark.svg";
        } else {
          this.image = this.picture ? this.picture : "/assets/icons/unknown-user-light.svg";
        }
      });
    }
  }

  getInitials(): string {
    if (!this.user) {
      return "";
    }
    const firstNameInitial = this.user.FirstName ? this.user.FirstName.substring(0, 1) : "";

    const lastNameInitial = this.user.LastName ? this.user.LastName.substring(0, 1) : "";

    let combinedNames = firstNameInitial + lastNameInitial;

    if (combinedNames == "") combinedNames = this.user.email.substring(0, 1);

    return combinedNames;
  }
}
