import {EventEmitter, Injectable, Output} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ActivityList} from "./activity.interface";
import {map, tap} from "rxjs/operators";
import {Activity} from "./activity.model";
import {CommentService} from "./comments/comment.service";
import {FacebookPost} from "./facebook-activity/facebook-post.model";
import {Tweet} from "./twitter-activity/tweet.model";
import {InstagramMedia} from "./instagram-activity/instagram-media.model";
import {environment} from "@environments/environment";
import {Stream} from "@stream/stream.interface";
import {Content, PublishEvent} from "@publisher/content.interface";
import {SyndicationItem} from "./syndication-activity/syndication-item";
import {LanguageService} from "@shared/utils/language-service/language.service";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {getTokensFromContent} from "@shared/utils/token.function";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  private baseUrl = environment.api + "/Activities";
  private cache = {};

  constructor(
    private httpClient: HttpClient,
    private commentService: CommentService,
    private languageService: LanguageService,
  ) {}

  @Output() retweetResponseReceived = new EventEmitter<ActivityOverlayResponse>();
  @Output() facebookShareResponseReceived = new EventEmitter<ActivityOverlayResponse>();
  @Output() syndicationShareResponseReceived = new EventEmitter<ActivityOverlayResponse>();
  @Output() activityUpdated = new EventEmitter<Activity>();

  list(stream: Stream, cache = true): Observable<ActivityList> {
    // console.log("listing the streams", stream, cache);

    if (cache && this.cache[stream.Id] && this.cache[stream.Id][stream.Page]) {
      return of(this.cache[stream.Id][stream.Page]);
    }

    if (cache == false) this.cache = {};

    // console.log("current cache", this.cache);
    return this.httpClient.post<ActivityList>(this.baseUrl, stream).pipe(
      map((activityList: ActivityList) => {
        activityList.Items = activityList.Items.map((activity) => {
          return this.mapActivity(activity);
        });
        return activityList;
      }),
      tap((activityList: ActivityList) => {
        this.cache[stream.Id] = this.cache[stream.Id] || {};
        this.cache[stream.Id][stream.Page] = activityList;
      }),
    );
  }

  updateActivity(activityId: string) {
    return this.httpClient.post<Activity>(this.baseUrl + "/updateActivity", {id: activityId}).pipe(
      map((activity: Activity) => this.mapActivity(activity)),
      tap((updatedActivity: Activity) => {
        for (const property in this.cache) {
          const propertyValue = this.cache[property];
          for (const propertyInStreamCache in propertyValue) {
            const activityList = propertyValue[propertyInStreamCache].Items;
            for (let index = 0; index < activityList.length; index++) {
              const activity = activityList[index];
              if (activity.Id == updatedActivity.Id) {
                propertyValue[propertyInStreamCache].Items[index] = updatedActivity;
              }
            }
          }
        }
        this.activityUpdated.emit(updatedActivity);
      }),
    );
  }

  public mapActivity(activity): Activity {
    let language = "english";

    if (activity.Text && activity.Text.length > 0) {
      language = this.languageService.detectLanguageOnText(activity.Text[0]);
    }

    return new Activity(
      activity.Tweet ? this.mapTweet(activity.Tweet) : null,
      activity.GooglePlusPost,
      activity.YouTubeVideo,
      activity.FacebookPost ? ActivityService.mapFacebookPost(activity.FacebookPost) : null,
      activity.InstagramMedia ? ActivityService.mapInstagramMedia(activity.InstagramMedia) : null,
      activity.SyndicationItem ? ActivityService.mapSyndicationItem(activity.SyndicationItem) : null,
      activity.JoinFieldParentId,
      activity.Type,
      activity.AccountId,
      activity.BrandId,
      activity.CreatedTime,
      activity.UpdatedTime,
      activity.Engagement,
      activity.SourceType,
      activity.CommentsCount,
      activity.Text,
      activity.MD5Hash,
      activity.Urls,
      activity.Language,
      activity.Source,
      activity.Author,
      activity.Comments ? this.commentService.mapComments(activity.Comments) : null,
      activity.StoredCommentsCount,
      activity.Attachment,
      activity.LikeCount,
      activity.ParentActivityId,
      activity.ParentCommentId,
      activity.UrlMetadata,
      activity.Reactions,
      activity.Hidden,
      activity.Anonymous,
      activity.Id,
      activity.LinkedInPost,
      activity.Mentions,
      language,
      activity.Links,
      null,
    );
  }

  private static mapFacebookPost(facebookPost): FacebookPost {
    return new FacebookPost(
      facebookPost.SharesCount,
      facebookPost.ReactionCount,
      facebookPost.LikesCount,
      facebookPost.AngryCount,
      facebookPost.WowCount,
      facebookPost.SadCount,
      facebookPost.HahaCount,
      facebookPost.LoveCount,
      facebookPost.Story,
      facebookPost.Permalink,
      facebookPost.Attachments,
      facebookPost.IsSharing,
    );
  }

  private static mapInstagramMedia(instagramMedia): InstagramMedia {
    return new InstagramMedia(
      instagramMedia.Url,
      instagramMedia.LikesCount,
      instagramMedia.Tags,
      instagramMedia.CarouselMedia,
      instagramMedia.Image,
      instagramMedia.Video,
      instagramMedia.MoreCommentsAvailable,
    );
  }

  private mapTweet(tweet): Tweet {
    return new Tweet(
      tweet.Id,
      tweet.Prefix,
      tweet.Content,
      tweet.RetweetsCount,
      tweet.LikesCount,
      tweet.InReplyToStatusId,
      tweet.InReplyToUserId,
      tweet.QuotedStatusId,
      tweet.InReplyToScreenName,
      tweet.Hashtags,
      tweet.Photos,
      tweet.Urls,
      tweet.Mentions,
      tweet.Videos,
      tweet.IsRetweeted,
      tweet.Original ? this.mapActivity(tweet.Original) : null,
      tweet.Permalink,
    );
  }

  private static mapSyndicationItem(item): SyndicationItem {
    return new SyndicationItem(
      item.ContentType,
      item.Summary,
      item.Links,
      item.Categories,
      item.GUID,
      item.ImageSrc,
      item.ImageAlt,
    );
  }

  mapActivitiesInContents<T extends PublishEvent>(contents: T[]): T[] {
    return contents.map((content: T) => {
      content.Providers = [];
      if (content.Activities) {
        // console.log("[mapActivitiesInContents]", content);
        const tokenMap = getTokensFromContent(content as unknown as Content);
        if (content.Activities.TwitterAccount) {
          if (
            (!content.Activities.TwitterAccount.UrlMetadata || !content.Activities.TwitterAccount.UrlMetadata.length) &&
            content.TwitterUrlMetadata
          ) {
            content.Activities.TwitterAccount.UrlMetadata = content.TwitterUrlMetadata;
          }
          if (content.TwitterUrlMetadata && content.TwitterUrlMetadata[0] && content.TwitterUrlMetadata[0].Tweet) {
            content.Activities.TwitterAccount.Tweet.QuotedStatusId = content.TwitterUrlMetadata[0].Tweet.Tweet.Id;
            content.Activities.TwitterAccount.Tweet.Original = content.TwitterUrlMetadata[0].Tweet;
            content.Activities.TwitterAccount.Tweet.Urls = [
              {
                BareUrl: content.TwitterUrlMetadata[0].Tweet.Tweet.Permalink,
                ExpandedUrl: content.TwitterUrlMetadata[0].Tweet.Tweet.Permalink,
              },
            ];
          }
          content.Activities.TwitterAccount = this.mapActivity(content.Activities.TwitterAccount);
          content.Activities.TwitterAccount.tokens = tokenMap.get(ProfileTypes.TwitterAccount);
          content.Text = content.Activities.TwitterAccount.Text;
          content.Providers.push("TwitterAccount");
        }
        if (content.Activities.Facebook) {
          if (
            (!content.Activities.Facebook.UrlMetadata || !content.Activities.Facebook.UrlMetadata.length) &&
            content.TwitterUrlMetadata
          ) {
            content.Activities.Facebook.UrlMetadata = content.TwitterUrlMetadata;
          }
          content.Activities.Facebook = this.mapActivity(content.Activities.Facebook);
          content.Activities.Facebook.tokens = tokenMap.get(ProfileTypes.Facebook);
          content.Text = content.Activities.Facebook.Text;
          content.Providers.push("Facebook");
        }
        if (content.Activities.InstagramAccount) {
          content.Activities.InstagramAccount = this.mapActivity(content.Activities.InstagramAccount);
          content.Activities.InstagramAccount.tokens = tokenMap.get(ProfileTypes.InstagramAccount);
          content.Text = content.Activities.InstagramAccount.Text;
          content.Providers.push("InstagramAccount");
        }

        if (content.Activities.LinkedIn) {
          const linkedInLink = content.Activities.LinkedIn.Links.filter(
            (link) => link.ProfileType == ProfileTypes.LinkedIn,
          );

          if (linkedInLink && linkedInLink.length > 0) {
            content.Activities.LinkedIn.UrlMetadata = linkedInLink[0].Metadata;
          }

          content.Activities.LinkedIn = this.mapActivity(content.Activities.LinkedIn);
          if (!content.IsAScheduledShare) content.Activities.LinkedIn.tokens = tokenMap.get(ProfileTypes.LinkedIn);
          else content.Activities.LinkedIn.tokens = null;

          content.Text = content.Activities.LinkedIn.Text;
          content.Providers.push("LinkedIn");
        }
      }
      if (content.Providers) {
        content.Providers = content.Providers.sort();
      }
      if (content.Profiles) {
        content.Profiles = content.Profiles.sort((a, b) => a.Type - b.Type);
      }
      return content;
    });
  }

  clearCache() {
    this.cache = {};
  }

  clearCacheForStream(streamId: string) {
    for (const stream in this.cache) {
      if (stream == streamId) {
        this.cache[stream] = null;
      }
    }
  }
}

export class ActivityOverlayResponse {
  Activity: Activity;
  Response: any;
}
