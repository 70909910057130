import {EventEmitter, HostBinding, HostListener, Directive} from "@angular/core";
import {ESCAPE} from "@angular/cdk/keycodes";
import {PanelOverlayRef} from "./panel-overlay-ref";
import {AnimationEvent} from "@angular/animations";

@Directive()
export abstract class PanelOverlay {
  @HostBinding("@slideContent")
  public animationState: "void" | "enter" | "leave" = "enter";

  animationStateChanged = new EventEmitter<AnimationEvent>();

  abstract close(result: any);

  constructor(
    public dialogRef: PanelOverlayRef<PanelOverlay>,
    public data?: any,
  ) {}

  @HostListener("document:keydown", ["$event"])
  private handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE && this.dialogRef.childOverlayRef == null) {
      this.close(event);
    }
  }

  @HostListener("@slideContent.start", ["$event"])
  public onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  @HostListener("@slideContent.done", ["$event"])
  public onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = "leave";
  }
}
