<button
  mat-flat-button
  id="{{ customId }}"
  color="{{ color }}"
  [type]="buttonType"
  [ngClass]="type ? '' + type : null"
  [class.icon]="icon"
  [disabled]="disabled || loading"
  (click)="buttonClick.emit()"
  [ngStyle]="{'min-width.px': minWidth}">
  <mat-icon *ngIf="icon && !loading" svgIcon="{{ icon }}"></mat-icon>
  <ng-content *ngIf="!loading"></ng-content>
  <span class="loader" *ngIf="loading">
    <span class="rect1"></span>
    <span class="rect2"></span>
    <span class="rect3"></span>
    <span class="rect4"></span>
    <span class="rect5"></span>
  </span>
</button>
