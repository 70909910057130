<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 ml-2">Hashtag Manager</div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- New Hashtag Group -->
  <section>
    <div
      class="flex items-center justify-between px-5 border-0 border-b border-solid box-border border-light-base-300 dark:border-dark-base-300 !min-h-[50px] !h-[50px]">
      <div>Groups</div>
      <button
        matRipple
        type="button"
        (click)="createHashtagGroup()"
        appGlobalroleValidator
        permission="ManageAccount"
        [disableElement]="true"
        [showLock]="true"
        [toastOnClick]="true"
        data-test="composer-hashtag-manager-new-button"
        class="table-header-button">
        <app-icon class="w-5 h-5" icon="plus"></app-icon>
        <span class="font-medium">New Hashtag Group</span>
      </button>
    </div>
  </section>
  <!-- Hashtag Groups Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-100px)] min-w-[510px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div *ngIf="loading" class="flex items-center justify-center h-24">
        <app-loader></app-loader>
      </div>
      <!-- Groups -->
      <div *ngIf="hashtagGroups?.length && !loading" class="flex-1 p-5">
        <div
          class="relative inline-flex flex-col h-full w-full mb-5 box-border bg-light-base-0 dark:bg-dark-base-50 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md"
          *ngFor="let hashtagGroup of hashtagGroups">
          <div
            class="px-5 py-2 bg-light-base-200 dark:bg-dark-base-200 border-b border-light-base-300 dark:border-dark-base-300">
            <div class="-ml-4 -mt-2 flex items-center justify-between flex-nowrap">
              <div class="ml-4 mt-2">
                <div class="font-medium text-light-base-600 dark:text-dark-base-400 capitalize">
                  {{ hashtagGroup.Name }}
                </div>
              </div>
              <div class="ml-4 mt-2 flex-shrink-0">
                <a (click)="selectHashtagGroup(hashtagGroup)" class="flex items-center font-medium"
                  >Insert <app-icon icon="arrow-right" class="h-4 w-4 ml-1"></app-icon
                ></a>
              </div>
            </div>
          </div>
          <!-- Group Content -->
          <div class="p-5" [innerHTML]="hashtagGroup.Text | hashtag"></div>
        </div>
      </div>
      <!-- If No Channels -->
      <app-placeholder *ngIf="!hashtagGroups?.length && !loading" class="p-5">
        <div class="flex-1 flex flex-col items-center justify-center">
          <app-placeholder-image
            lightThemeImageFileName="airballons.png"
            darkThemeImageFileName="camping.png"
            class="w-44 h-44"></app-placeholder-image>
          <div class="empty-state-text mt-5">No Hashtag Groups</div>
          <p class="text-center mt-2">Once you create a hashtag group or two, we'll show them here.</p>
          <div class="mt-6 mb-2">
            <app-button type="inline" icon="hashtag" (buttonClick)="createHashtagGroup()" class="mt-4"
              >New Hashtag Group
            </app-button>
          </div>
        </div>
      </app-placeholder>
    </ng-scrollbar>
  </div>
</div>
