<div class="relative flex flex-col">
  <div
    class="h-[100px] relative flex flex-row border border-solid border-light-base-300 dark:border-dark-base-300 rounded mt-4 overflow-hidden bg-light-base-0 dark:bg-dark-base-0"
    data-test="composer-editor-attachedLink-details-container-div">
    <!-- Loader -->
    <div
      *ngIf="link.IsMetadataLoading"
      class="absolute flex items-center justify-center inset-0 rounded bg-black-alpha-200 pointer-events-none">
      <app-loader></app-loader>
    </div>
    <!-- Link Thumbnail -->
    <img
      *ngIf="link.imageUrl"
      [alt]="link.title"
      [src]="link.imageUrl"
      class="h-[100px] w-[100px] object-cover rounded-l" />
    <!-- Link Details -->
    <div class="relative flex-1 flex flex-col justify-between p-4 overflow-hidden">
      <div>
        <!-- Title -->
        <div
          *ngIf="link.title"
          class="line-clamp-1 text-light-base-600 dark:text-dark-base-400 font-medium"
          [title]="link.title">
          {{ link.title }}
        </div>
        <!-- Description -->
        <p
          *ngIf="link.description"
          class="line-clamp-1 text-sm text-light-base-400 dark:text-dark-base-400 mt-0.5"
          [title]="link.description"
          data-test="composer-editor-link-description-p">
          {{ link.description }}
        </p>
      </div>
      <div>
        <!-- Source -->
        <div
          *ngIf="link.BaseUrl && !link.IsMetadataLoading"
          class="line-clamp-1 text-xs text-light-base-400 dark:text-dark-base-400 mt-auto"
          data-test="composer-editor-link-base-url-div">
          {{ nakedDomain(link.BaseUrl) }}
        </div>
      </div>
    </div>
    <!-- Remove -->
    <app-icon
      icon="x"
      class="absolute top-[4px] right-[4px] text-light-base-400 dark:text-dark-base-400 hover:text-light-base-400 dark:hover:text-white-alpha-600 h-4 w-4 cursor-pointer"
      title="Remove"
      (click)="removeLink(link)"
      *ngIf="showRemoveButton"
      data-test="composer-editor-attachedLink-remove-icon">
    </app-icon>
  </div>
  <div class="relative flex px-2" data-test="composer-editor-attachedLink-controls-container-div">
    <div class="flex-1 flex flex-row justify-end">
      <!-- Edit Metadata -->
      <div
        *ngIf="containsLinkedInProfile"
        class="inline-flex items-center border border-solid border-t-0 border-light-base-300 dark:border-dark-base-300 rounded-b -mt-px ml-px">
        <button
          type="button"
          class="inline-flex items-center rounded-b px-2 py-1 text-xs font-medium border-0 m-0"
          data-test="composer-editor-attachedLink-editMetadata-icon"
          [ngClass]="{
            'bg-light-base-200 dark:bg-transparent text-light-base-400 dark:text-dark-base-400 cursor-not-allowed':
              link.IsMetadataLoading,
            'text-light-base-400 dark:text-dark-base-400 bg-transparent hover:bg-light-base-200 dark:hover:bg-dark-base-25 active:bg-light-base-200 dark:active:bg-dark-base-25 cursor-pointer':
              !link.IsMetadataLoading
          }"
          (click)="editMetadata()"
          [disabled]="link.IsMetadataLoading">
          Edit Metadata
        </button>
      </div>
      <!-- Shortening -->
      <div
        *ngIf="isUrlShortenerConfigured"
        class="inline-flex items-center border border-solid border-t-0 border-light-base-300 dark:border-dark-base-300 rounded-b -mt-px ml-px">
        <!-- Shorten / Unshorten -->
        <button
          type="button"
          class="inline-flex items-center text-light-base-400 dark:text-dark-base-400 bg-transparent hover:bg-light-base-200 dark:hover:bg-dark-base-25 active:bg-light-base-200 dark:active:bg-dark-base-25 cursor-pointer rounded-b px-2 py-1 text-xs font-medium border-0 m-0"
          data-test="composer-editor-attachedLink-controls-shorten-button"
          (click)="shortenUnshorten()">
          <ng-container *ngIf="!link.IsShorten">Shorten</ng-container>
          <ng-container *ngIf="link.IsShorten">Unshorten</ng-container>
        </button>
        <!-- Shorten with Menu Toggle -->
        <div *ngIf="!link.IsShorten" class="w-px h-4 bg-light-base-200 dark:bg-dark-base-200"></div>
        <button
          *ngIf="!link.IsShorten"
          type="button"
          class="inline-flex items-center text-light-base-400 dark:text-dark-base-400 bg-transparent hover:bg-light-base-200 dark:hover:bg-dark-base-25 active:bg-light-base-200 dark:active:bg-dark-base-25 cursor-pointer rounded-br px-1 py-1 text-xs font-medium border-0 m-0"
          [matMenuTriggerFor]="dropdownMenuOne">
          <app-icon icon="chevron-down" class="w-4 h-4"></app-icon>
        </button>
      </div>
      <!-- Shorten With Menu -->
      <mat-menu #dropdownMenuOne="matMenu" class="selector-menu !w-44">
        <ng-container *ngFor="let group of groups">
          <mat-optgroup>
            <div class="uppercase text-[11px] !h-10">{{ group.name }}</div>
            <mat-option
              class="rounded !h-10 !px-6 !pl-6"
              *ngFor="let domain of group.bsds"
              (click)="shortenUrl(domain, group.guid)">
              {{ domain }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-menu>
      <!-- Tracking -->
      <div
        class="inline-flex items-center border border-solid border-t-0 border-light-base-300 dark:border-dark-base-300 rounded-b -mt-px ml-px">
        <button
          type="button"
          class="inline-flex items-center text-light-base-400 dark:text-dark-base-400 bg-transparent hover:bg-light-base-200 dark:hover:bg-dark-base-25 active:bg-light-base-200 dark:active:bg-dark-base-25 cursor-pointer rounded-b px-2 py-1 text-xs font-medium border-0 m-0"
          data-test="composer-editor-attachedLink-controls-tracking-button"
          (click)="addEditTracking()">
          <ng-container *ngIf="link.IsTracking">Edit Tracking</ng-container>
          <ng-container *ngIf="!link.IsTracking">Add Tracking</ng-container>
        </button>
        <div *ngIf="utmPresets.length" class="w-px h-4 bg-light-base-200 dark:bg-dark-base-200"></div>
        <button
          *ngIf="utmPresets.length"
          type="button"
          class="inline-flex items-center text-light-base-400 dark:text-dark-base-400 bg-transparent hover:bg-light-base-200 dark:hover:bg-dark-base-25 active:bg-light-base-200 dark:active:bg-dark-base-25 cursor-pointer rounded-br px-1 py-1 text-xs font-medium border-0 m-0"
          [matMenuTriggerFor]="dropdownMenuTwo">
          <app-icon icon="chevron-down" class="w-4 h-4"></app-icon>
        </button>
      </div>
      <!-- Presets Menu -->
      <mat-menu #dropdownMenuTwo="matMenu" class="selector-menu !w-44">
        <ng-container>
          <mat-option
            class="rounded !h-10 !px-6 !pl-6"
            *ngFor="let preset of utmPresets"
            (click)="applyUtmPreset(preset)">
            {{ preset.Name }}
          </mat-option>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</div>
