import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IZapierProcessedProfile} from "@shared/composer/composer.component";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-confirmation-zapier",
  templateUrl: "./confirmation-zapier.component.html",
  styleUrls: ["./confirmation-zapier.component.scss"],
})
export class ConfirmationZapierComponent {
  @Input() processedProfiles: IZapierProcessedProfile[];

  @Output() Finished = new EventEmitter<any>();

  OnFinishedClicked() {
    this.Finished.emit();
  }
}
