<div
  class="flex text-sm p-4 rounded-md border border-solid border-light-danger-100 dark:border-dark-danger-100 bg-light-danger-100/5 dark:bg-dark-danger-100/5">
  <div class="flex-shrink-0 leading-zero">
    <app-icon icon="error" class="w-5 h-5 leading-zero text-light-danger-100 dark:text-dark-danger-100"></app-icon>
  </div>
  <div class="ml-3">
    <div class="text-light-danger-100 dark:text-dark-danger-100 -mt-px break-words">
      <ng-content></ng-content>
    </div>
  </div>
</div>
