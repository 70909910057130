import {BaseHub} from "./basehub";
import {EventEmitter} from "@angular/core";
import {
  InboxMessage,
  InboxConversationMessageModel,
  InboxConversationStatusModel,
  InboxConversationMessagingChangeStatusModel,
} from "@shared/inbox/inbox.interface";
import {UserNotificationModel, TagsUpdatedModel} from "./hubs.interface";
import {NotificationGroup} from "@shared/notifications/notification.interface";
import {GroupedPublishEventAdded} from "@shared/publisher/queue.interface";
import {ProcessZapierAuthenticationModel} from "../zapier/zapier.interface";
import {ActivityCommentEventModel} from "@shared/publisher/content.interface";
import {IntegrationAuthCompletedModel} from "src/app/pages/settings/integrations/integrations.interface";
import {UserTaskCompletedModel, UserTaskModel, UserTaskStartedModel} from "../user-tasks/user-tasks.model";

export class AccountHub extends BaseHub {
  public OnInboxConversationReceived = new EventEmitter<InboxMessage>();

  public OnInboxConversationMessageReceived = new EventEmitter<InboxConversationMessageModel>();

  public OnInboxConversationStatusChange = new EventEmitter<InboxConversationStatusModel>();

  public OnUserNotificationReceived = new EventEmitter<UserNotificationModel>();

  public OnUserNotificationRaise = new EventEmitter<UserNotificationModel>();

  public OnTagsUpdated = new EventEmitter<TagsUpdatedModel>();

  public OnPublishedGroupEventPosted = new EventEmitter<GroupedPublishEventAdded>();

  public OnPublishedGroupForActivityShare = new EventEmitter<GroupedPublishEventAdded>();

  public OnInboxConversationMessagingChangeStatusChange =
    new EventEmitter<InboxConversationMessagingChangeStatusModel>();

  public OnProfileModified = new EventEmitter<string>();

  public OnStopSupportSession = new EventEmitter<string>();

  public OnZapierLoginFinished = new EventEmitter<ProcessZapierAuthenticationModel>();

  public OnQueueRebuilt = new EventEmitter<any>();

  public OnIntegrationAuthenticationCompleted = new EventEmitter<IntegrationAuthCompletedModel>();

  public OnActivityCommentEvent = new EventEmitter<ActivityCommentEventModel>();

  public OnUserUserTaskStarted = new EventEmitter<UserTaskStartedModel>();

  public OnUserUserTaskCompleted = new EventEmitter<UserTaskCompletedModel>();

  start(parameters: any = null) {
    return this.connect("account", parameters)
      .then()
      .catch((error) => {
        if (!error.Authorization) {
          console.warn("[SignalR]: failed to connect to accountHub. No token or might be expired. Please login.");
        } else {
          console.error("[SignalR]: failed to connect to accountHub", error);
        }
      });
  }

  protected subscribeToEvents() {
    this.hubConnection.on("inboxConversationReceived", (accountId: string, model: InboxMessage) => {
      return this.OnInboxConversationReceived.emit(model);
    });

    this.hubConnection.on(
      "inboxConversationMessageReceived",
      (accountId: string, model: InboxConversationMessageModel) => {
        return this.OnInboxConversationMessageReceived.emit(model);
      },
    );

    this.hubConnection.on("inboxConversationStatusChange", (accountId: string, model: InboxConversationStatusModel) => {
      return this.OnInboxConversationStatusChange.emit(model);
    });

    this.hubConnection.on(
      "inboxConversationMessagingChangeStatus",
      (accountId: string, model: InboxConversationMessagingChangeStatusModel) => {
        return this.OnInboxConversationMessagingChangeStatusChange.emit(model);
      },
    );

    this.hubConnection.on("userNotificationRaise", (userId: string, model: NotificationGroup) => {
      return this.OnUserNotificationRaise.emit({
        UserId: userId,
        NotificationGroup: model,
      });
    });

    this.hubConnection.on("userNotificationReceived", (userId: string, model: NotificationGroup) => {
      return this.OnUserNotificationReceived.emit({
        UserId: userId,
        NotificationGroup: model,
      });
    });

    this.hubConnection.on("publishedGroupEvent", (accountId: string, occurrenceId: string, messageId: string) => {
      this.OnPublishedGroupEventPosted.emit({
        MessageId: messageId,
        OccurrenceId: occurrenceId,
      });
    });

    this.hubConnection.on(
      "publishedGroupForActivityShare",
      (accountId: string, occurrenceId: string, activityShareId: string) => {
        this.OnPublishedGroupForActivityShare.emit({
          ActivityShareId: activityShareId,
          OccurrenceId: occurrenceId,
        });
      },
    );

    this.hubConnection.on("profileModified", (accountId: string, profileId: string) => {
      this.OnProfileModified.emit(profileId);
    });

    this.hubConnection.on("stopSupportSession", (supportSession: string) => {
      this.OnStopSupportSession.emit(supportSession);
    });

    this.hubConnection.on("onZapierLoginFinished", (zapierAuthModel: ProcessZapierAuthenticationModel) => {
      this.OnZapierLoginFinished.emit(zapierAuthModel);
    });

    this.hubConnection.on("onQueueRebuilt", () => {
      this.OnQueueRebuilt.emit();
    });

    this.hubConnection.on("onActivityCommentEvent", (model: ActivityCommentEventModel) => {
      this.OnActivityCommentEvent.emit(model);
    });

    this.hubConnection.on("onIntegrationAuthenticationCompleted", (model: IntegrationAuthCompletedModel) => {
      this.OnIntegrationAuthenticationCompleted.emit(model);
    });

    this.hubConnection.on("userTaskStarted", (userId: string, model: UserTaskModel) => {
      return this.OnUserUserTaskStarted.emit({
        UserId: userId,
        UserTask: model,
      });
    });

    this.hubConnection.on("userTaskCompleted", (userId: string, model: UserTaskModel) => {
      return this.OnUserUserTaskCompleted.emit({
        UserId: userId,
        UserTask: model,
      });
    });
  }
}
