<div class="flex items-center px-4">
  <div class="flex flex-col">
    <div class="flex-1 flex">
      <button
        *ngIf="ShowLike"
        mat-button
        data-test="shared-likebutton-button"
        class="action facebook like"
        [class.active]="Liked"
        (click)="LikeClicked.emit()">
        <mat-icon *ngIf="!Liked" svgIcon="like"></mat-icon>
        <mat-icon *ngIf="Liked" svgIcon="like-solid"></mat-icon>
        <span [class.active]="Liked">Like</span>
      </button>
      <button *ngIf="ShowComment" mat-button class="action linkedin comment" (click)="CommentClicked.emit()">
        <mat-icon svgIcon="message-square"></mat-icon>
        <span>Comment</span>
      </button>
      <button *ngIf="ShowShare" mat-button class="action linkedin share" (click)="openShareDialog()">
        <!-- <mat-icon svgIcon="share-arrow"></mat-icon> <span>Share</span> -->
        <mat-icon *ngIf="!Shared" svgIcon="share-arrow"></mat-icon>
        <mat-icon *ngIf="Shared" svgIcon="check"></mat-icon>
        <span [class.active]="Shared">
          <span *ngIf="!Shared">Share</span>
          <span *ngIf="Shared">Shared</span>
        </span>
      </button>
    </div>
    <!--    <div class="flex items-center space-x-3 mt-2">-->
    <!--      <input-->
    <!--        *ngIf="commentsOpen"-->
    <!--        class="comment-input"-->
    <!--        placeholder="Comment"-->
    <!--        (keyup.enter)="CommentSubmit.emit(commentText)"-->
    <!--        [(ngModel)]="commentText"-->
    <!--        autofocus />-->
    <!--      <input *ngIf="sharesOpen" class="share-input" placeholder="Share comment" [(ngModel)]="ShareText" autofocus />-->
    <!--      <button *ngIf="sharesOpen" (click)="ShareSubmit.emit(ShareText)">Share</button>-->
    <!--      &lt;!&ndash; Share button for input &ndash;&gt;-->
    <!--      <app-button (buttonClick)="openShareDialog()" type="inline">Share</app-button>-->
    <!--    </div>-->
  </div>
</div>
