import {HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel, HubConnectionState} from "@microsoft/signalr";
import {environment} from "@environments/environment";
import {AuthenticationService} from "@shared/auth/authentication.service";
import {from} from "rxjs";
import {switchMap} from "rxjs/operators";

export abstract class BaseHub {
  protected hubConnection: HubConnection;

  protected abstract subscribeToEvents();

  constructor(private authenticationService: AuthenticationService) {}

  connect(hub: string, parameters: any = null): Promise<any> {
    if (this.authenticationService.CheckIfTokenIsExpired())
      return Promise.reject({
        Authorization: false,
        Message: "Expired token. Not connecting to SignalR.",
      });

    const url = environment.baseUrl + "/signalr-endpoint/" + hub;
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => this.authenticationService.token,
      })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          if (this.authenticationService.CheckIfTokenIsExpired()) {
            return null;
          }

          return 10000;
        },
      })
      .build();

    this.subscribeToEvents();

    if (!parameters) {
      return from(this.hubConnection.start())
        .pipe(switchMap(() => from(this.hubConnection.invoke("Start"))))
        .toPromise();
    } else {
      return from(this.hubConnection.start())
        .pipe(switchMap(() => from(this.hubConnection.invoke("Start", parameters))))
        .toPromise();
    }
  }

  dispose(): Promise<any> {
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
      return this.hubConnection.stop();
    }

    return Promise.resolve();
  }
}
