<div
  class="publisher-activity"
  data-test="publisher-activity-containing-div"
  (click)="openPreviewDialog()"
  [ngClass]="status">
  <app-activity
    [activity]="activity"
    [showFooter]="false"
    [showSource]="false"
    [showDescription]="showDescription"
    [showDomain]="showDomain"
    [class.not-clickable]="!clickable"
    [showCarouselControls]="showCarouselControls"
    [showCarouselIcon]="showCarouselIcon"
    [showVolumeControl]="showVolumeControl"
    [class.error]="status === 'error'">
    <app-publisher-activity-header
      [activity]="activity"
      [profiles]="content.Profiles"
      [provider]="provider"
      [showTime]="showTime"
      [showChannels]="showChannels"
      [showStatus]="showStatus"
      [time]="content.PublishAt"
      [status]="status"
      [showBadge]="showBadge"
      [profileQuality]="profileQuality"
      [profileWidth]="profileWidth"
      [pictureSize]="pictureSize"
      role="header">
    </app-publisher-activity-header>
    <app-publisher-activity-footer
      [content]="content"
      role="footer"
      [tagSize]="tagSize"
      [showTags]="showTags"
      [showFooter]="showFooter"></app-publisher-activity-footer>
    <div
      *ngIf="
        (showAdvocates && employeeGroups?.length && (likeToEmployees || shareToEmployees)) ||
        (showAdvocates && employees?.length && (likeToEmployees || shareToEmployees))
      "
      class="flex items-center bg-yellow-200/75 px-2 text-sm font-medium min-h-[40px] h-[40px] text-light-base-400 overflow-hidden rounded-b-[4px] sharing-note">
      <app-icon icon="users-3" class="w-5 h-5 mr-1"></app-icon>
      <span class="px-0.5" *ngIf="status === 'scheduledShare' || status === 'scheduled'">share</span>
      <span class="px-0.5" *ngIf="status === 'shared' || status === 'published'">shared</span>
      <span class="px-0.5 whitespace-pre">to employees</span>
    </div>
  </app-activity>
  <!-- multipost indicator -->
  <ng-container *ngIf="showStack && content.Providers.length > 1">
    <div class="multipost"></div>
    <div class="sheet"></div>
  </ng-container>
</div>
