import {Pipe, PipeTransform} from "@angular/core";
import {Activity} from "../activity.model";
import {IComposerToken} from "@shared/composer/composer-editor/token/token.interface";

@Pipe({
  name: "linkifytokens",
})
export class LinkifyFromTokensPipe implements PipeTransform {
  transform(swTokens: IComposerToken[], activity: Activity) {
    const tokensEvaluated = swTokens?.map((x) =>
      x.behaviour.getPreviewHtmlValue({links: activity.Links}, activity.SourceType),
    );
    if (!tokensEvaluated || tokensEvaluated.length == 0) return "";
    return tokensEvaluated.reduce((a, b) => `${a}${b}`) ?? "";
  }
}
