import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Observable, of} from "rxjs";
import {TimezoneInfo} from "../../timezone.interface";
import {TimezoneService} from "../../timezone.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-timezone-selector",
  templateUrl: "./timezone-selector.component.html",
})
export class TimezoneSelectorComponent implements OnInit, OnChanges {
  constructor(private timezoneService: TimezoneService) {}

  @Input() InitialTimezoneSelected: TimezoneInfo;
  @Input() type: string;

  @Output() TimezoneSelected = new EventEmitter<TimezoneInfo>();

  timezones: TimezoneInfo[];
  timezone = new FormControl();
  currentTimezoneSelected: TimezoneInfo;
  filteredOptions: Observable<TimezoneInfo[]>;

  ngOnInit(): void {
    this.timezoneService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((timezones: TimezoneInfo[]) => {
        this.timezones = timezones;
        this.timezone.valueChanges.pipe(untilDestroyed(this)).subscribe((x) => {
          if (x == null) {
            this.filteredOptions = of(this.timezones.slice());
            return;
          }
          if (typeof x == "object") {
            this.filteredOptions = of(this.timezones.slice());
            return;
          }
          this.filteredOptions = of(this.filter(x));
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.InitialTimezoneSelected.currentValue) {
      this.InitialTimezoneSelected = changes.InitialTimezoneSelected.currentValue;
      this.setTimezone(this.InitialTimezoneSelected);
    }
  }

  displayFn(timezone?: TimezoneInfo): string | undefined {
    return timezone ? timezone.LongName + " " + timezone.ShortName : undefined;
  }

  private filter(name: string): TimezoneInfo[] {
    const filterValue = name.toLowerCase();
    return this.timezones.filter((option) => option.LongName.toLowerCase().indexOf(filterValue) === 0);
  }

  setTimezone(selectedTimezone?: TimezoneInfo) {
    this.currentTimezoneSelected = selectedTimezone;
    this.timezone.setValue(selectedTimezone);
  }

  onFocus() {
    this.timezone.setValue(null);
  }

  OnBlurTimezone() {
    setTimeout(() => {
      if (this.timezone.value?.IanaId != null) return;

      const timezoneExists = this.timezones.some((x) => x.LongName == this.timezone.value);

      if (!timezoneExists) {
        this.timezone.setValue(this.currentTimezoneSelected);
      }
    }, 250);
  }

  onTimezoneChange() {
    this.currentTimezoneSelected = this.timezone.value;
    this.TimezoneSelected.emit(this.currentTimezoneSelected);
  }
}
