import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import * as Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";

exporting(Highcharts);
exportData(Highcharts);
NoDataToDisplay(Highcharts);

@Component({
  selector: "app-employee-group-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupGraphComponent extends BaseGraphComponent {
  elementId = "employeeGroupGraphContainer";
  public options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      styledMode: true,
      type: "pie",
      events: {
        render: function () {
          const chart = this;
          const centerX = chart.plotLeft + chart.plotWidth * 0.5;
          const centerY = chart.plotTop + this.plotHeight / 2 + this.subtitle.getBBox().height / 2 - 40;
          console.log("y", centerY);
          console.log("x", centerX);
          if (chart.subtitle) {
            chart.subtitle
              .attr({
                x: centerX,
                y: centerY,
              })
              .css({
                textAlign: "center",
                verticalAlign: "middle",
              });
          }
        },
      },
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Employee_Groups",
    },
    title: {
      text: "Employee Groups",
      align: "left",
    },
    subtitle: {
      text: "",
      verticalAlign: "middle",
      floating: true,
      useHTML: true,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y} </b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        innerSize: "60%",
        dataLabels: {
          enabled: true,
          format: "{point.name}: <b>{point.y} </b>",
          style: {
            fontWeight: "normal",
          },
        },
        showInLegend: false,
      },
      series: {},
    },
    series: [
      {
        name: "Number of Employees",
        colorByPoint: true,
        data: [],
        type: null,
      },
    ],
    noData: {},
    lang: {
      noData: "No data to display",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(): Observable<void> {
    return this.analyticsService.getEmployeesGroupsGraphData().pipe(
      tap((data) => {
        this.options.series[0]["data"] = data.Models.filter(
          (x) => this.widgetConfig.showNoGroup || x.GroupName != "No Group",
        ).map((x) => {
          return {
            name: x.GroupName,
            y: x.EmployeeCount,
          };
        });

        this.options.subtitle.text = `<div class="text-center"><div class='text-lg text-light-base-600 dark:text-dark-base-600'><strong>${data.TotalEmployeeCount}</strong></div><div class='text-sm text-light-base-400 dark:text-dark-base-400'>Employees</div></div>`;
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
