import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {ProfileService} from "@channel/profile.service";
import {IProfile, IProfileResponse} from "@channel/profile.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export interface ProfileSelectorOutput {
  provider: string;
  profile: IProfile;
}

@UntilDestroy()
@Component({
  selector: "app-profile-selector",
  templateUrl: "./profile-selector.component.html",
  styleUrls: ["./profile-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSelectorComponent implements OnInit {
  @Input() provider: string;
  @Output() selectProfile: EventEmitter<ProfileSelectorOutput> = new EventEmitter();

  defaultProfiles: IProfileResponse;
  profiles: IProfile[];

  constructor(
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });
    this.profileService
      .list(this.provider)
      .pipe(untilDestroyed(this))
      .subscribe((profiles) => {
        this.profiles = profiles;
      });
  }

  setDefaultProfile(profile: IProfile) {
    this.defaultProfiles[this.provider] = profile;
    this.selectProfile.emit({
      provider: this.provider,
      profile,
    });
    this.cdr.markForCheck();
  }
}
