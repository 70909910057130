<div *ngIf="showFooter" class="activity-footer-wrapper relative flex flex-row flex-wrap items-center justify-start">
  <ng-container *ngIf="showTags">
    <app-tag
      *ngFor="let tag of getTags()"
      [tagName]="tag.Name"
      [tagColor]="tag.ColorHex"
      [tagFontColor]="tag.FgColorHex"
      [tagSize]="tagSize"></app-tag>
  </ng-container>
</div>
