import {ITokenBehaviour, TokenPreviewData} from "./ITokenBehaviour";

export class TextTokenBehaviour implements ITokenBehaviour {
  originalValue: string;

  constructor(text: string) {
    this.originalValue = text;
  }

  getEditorLength(): number {
    return this.originalValue.length;
  }

  getPreviewLength(): number {
    return this.originalValue.length;
  }

  getEditorValue() {
    return this.originalValue;
  }

  getPreviewTextValue(): string {
    return this.originalValue;
  }

  getPreviewHtmlValue() {
    return this.originalValue;
  }
}
