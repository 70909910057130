import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import Highcharts from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";
NoDataToDisplay(Highcharts);

@Component({
  selector: "app-employee-group-client-engagement-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupClientEngagementGraphComponent extends BaseGraphComponent {
  elementId = "employeeGroupClientEngagementGraph";

  options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      styledMode: true,
    },
    title: {
      text: "Engagement by Client",
      align: "left",
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Engagement",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.0f}",
          style: {
            fontWeight: "medium",
          },
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.0f}</b> Engagements',
    },
    series: [
      {
        name: "Engagement Client",
        colorByPoint: true,
        data: [],
        type: null,
      },
    ],
    noData: {},
    lang: {
      noData: "No data to display",
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Employee_Engagement_by_Client",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void> {
    return this.analyticsService.getEmployeeGroupsClientEngagementData(startDate, endDate, profileIds).pipe(
      tap((models) => {
        this.options.series[0]["data"] = models.map((x) => {
          return {
            name: x.ClientName,
            y: x.CommentCount + x.LikesCount + x.ShareCount,
          };
        });
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
