import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ComposerService} from "@shared/composer/composer.service";
import {LinkTrackingComponent} from "@shared/composer/link-tracking/link-tracking.component";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PanelOverlayService} from "@shared/overlay-panel/panel-overlay.service";
import {AddLinkDataResponse, Link, LinkEventEnum} from "@shared/publisher/content.interface";
import {IntegrationService} from "@shared/utils/integration-service/integration-service.service";
import {getTrackingUrl} from "@shared/utils/link.function";
import {IUtmPreset, UtmDynamicParams} from "@shared/utils/utm-service/utm.interface";
import {UtmService} from "@shared/utils/utm-service/utm.service";
import {
  DefaultGroupDomain,
  IBitlyGroup,
  IntegrationTypeEnum,
} from "src/app/pages/settings/integrations/integrations.interface";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {EditMetadataComponent, IEditLinkMetadata} from "@shared/composer/edit-metadata/edit-metadata.component";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {UrlMetadata} from "@shared/activity/activity.interface";

@UntilDestroy()
@Component({
  selector: "app-attached-link",
  templateUrl: "./attached-link.component.html",
  styleUrls: ["./attached-link.component.scss"],
})
export class AttachedLinkComponent implements OnInit {
  @Input() link: Link;

  @Input() dialogRef: PanelOverlayRef<PanelOverlay>;

  @Output() remove: EventEmitter<Link> = new EventEmitter<Link>();

  @Input() showRemoveButton = true;

  @Input() index: number;

  @Input() presetParams: UtmDynamicParams;

  @Input() containsLinkedInProfile = true;

  isUrlShortenerConfigured: boolean;
  groups: IBitlyGroup[] = [];
  hasOneDomain: boolean;
  selectedDomain: DefaultGroupDomain;

  utmPresets: IUtmPreset[] = [];

  constructor(
    private integrationService: IntegrationService,
    private overlayService: PanelOverlayService<LinkTrackingComponent>,
    private composerService: ComposerService,
    private utmService: UtmService,
    private editMetadataService: PanelOverlayService<EditMetadataComponent>,
  ) {}

  ngOnInit(): void {
    this.utmService.getAll().subscribe((presets) => {
      this.utmPresets = presets;
    });

    this.integrationService.getIntegrationsConfigured().subscribe((configuredIntegrations: IntegrationTypeEnum[]) => {
      this.isUrlShortenerConfigured = configuredIntegrations.some((x) => x == IntegrationTypeEnum.Bitly);

      if (!this.isUrlShortenerConfigured) {
        return;
      }

      if (this.selectedDomain == null) {
        this.integrationService.getDefaultDomain().subscribe((defaultData) => {
          this.selectedDomain = defaultData;
        });
      }

      this.integrationService.getBitlyGroups().subscribe((groups: IBitlyGroup[]) => {
        this.groups = groups;
        const listOfDomains = groups.map((x) => x.bsds).reduce((acc, val) => acc.concat(val), []);
        const setOfValues = new Set(listOfDomains);
        this.hasOneDomain = setOfValues.size == 1;
      });
    });
  }

  shortenUrl(domain: string, groupId: string) {
    const currentDisplay = getTrackingUrl(this.link);
    this.integrationService.shortenUrl(currentDisplay, domain, groupId).subscribe((shortenedUrl: string) => {
      this.link.IsShorten = true;
      this.link.ShortenUrl = shortenedUrl;
      this.link.ShortenDomain = domain;
      this.link.ShortenGroupId = groupId;
      this.composerService.linkUpdated.emit({
        link: this.link,
        Event: LinkEventEnum.Shorten,
        index: this.index,
      });
    });
  }

  reshortenUrl(domain: string, groupId: string) {
    const currentDisplay = getTrackingUrl(this.link);
    this.integrationService.shortenUrl(currentDisplay, domain, groupId).subscribe((shortenedUrl: string) => {
      this.link.lastShortenUrl = this.link.ShortenUrl;
      this.link.IsShorten = true;
      this.link.ShortenUrl = shortenedUrl;
      this.link.ShortenDomain = domain;
      this.link.ShortenGroupId = groupId;
      this.composerService.linkUpdated.emit({
        link: this.link,
        Event: LinkEventEnum.ReshortenUrl,
        index: this.index,
      });
    });
  }

  shortenWithDefault() {
    if (this.selectedDomain) this.shortenUrl(this.selectedDomain.Domain, this.selectedDomain.GroupId);
  }

  unshortenUrl() {
    this.link.IsShorten = false;
    this.link.ShortenDomain = null;
    this.link.ShortenGroupId = null;
    // this.link.ShortenUrl = null;
    this.composerService.linkUpdated.emit({
      link: this.link,
      Event: LinkEventEnum.Unshorten,
      index: this.index,
    });
  }

  shortenUnshorten() {
    if (!this.link.IsShorten) {
      this.shortenWithDefault();
    } else {
      this.unshortenUrl();
    }
  }

  addEditTracking() {
    this.overlayService
      .open(LinkTrackingComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        data: {
          link: this.link,
        },
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((link: AddLinkDataResponse) => {
        if (link) {
          this.link = link;

          let event: LinkEventEnum;

          if (link.IsTracking && link.lastTrackingUrlState) {
            event = LinkEventEnum.UpdateTracking;
          } else if (link.IsTracking && !link.lastTrackingUrlState) {
            event = LinkEventEnum.AddTracking;
          } else if (!link.IsTracking) {
            link.TrackingUrl = link.BaseUrl;
            event = LinkEventEnum.RemoveTracking;
          }

          this.composerService.linkUpdated.emit({
            link: this.link,
            Event: event,
            index: this.index,
          });

          if (link.IsShorten) {
            this.reshortenUrl(link.ShortenDomain, link.ShortenGroupId);
          }
        }
      });
  }

  applyUtmPreset(utmPreset: IUtmPreset) {
    if (this.link.IsTracking) this.link.lastTrackingUrlState = getTrackingUrl(this.link);

    this.link.IsTracking = true;
    this.link.UtmCampaign = utmPreset.UtmCampaign;
    this.link.UtmContent = utmPreset.UtmContent;
    this.link.UtmMedium = utmPreset.UtmMedium;
    this.link.UtmSource = utmPreset.UtmSource;
    this.link.UtmTerm = utmPreset.UtmTerm;
    this.link.TrackingUrl = getTrackingUrl(this.link);

    let event: LinkEventEnum;

    if (this.link.lastTrackingUrlState) {
      event = LinkEventEnum.UpdateTracking;
    } else if (!this.link.lastTrackingUrlState) {
      event = LinkEventEnum.AddTracking;
    }

    this.composerService.linkUpdated.emit({
      link: this.link,
      Event: event,
      index: this.index,
    });

    if (this.link.IsShorten) {
      this.shortenUrl(this.link.ShortenDomain, this.link.ShortenGroupId);
    }
  }

  removeLink(link: Link) {
    this.remove.emit(link);
  }

  editMetadata() {
    if (this.link.IsMetadataLoading) return;

    const linkToEdit = Object.assign({}, this.link);

    this.editMetadataService
      .open(EditMetadataComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        data: {
          link: linkToEdit,
        },
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((link: IEditLinkMetadata) => {
        if (link == null) return;

        const originalMetadataUrl = this.link.Metadata[0].Url;

        const urlMetadata: UrlMetadata = {
          Url: originalMetadataUrl,
          MetaTags: [
            {
              Key: "og:title",
              Value: link.link.title,
            },
            {
              Key: "og:image",
              Value: link.link.imageUrl,
            },
            {
              Key: "og:description",
              Value: link.link.description,
            },
            {
              Key: "og:url",
              Value: originalMetadataUrl,
            },
          ],
        };

        this.link.Metadata = [urlMetadata];

        this.link.title = link.link.title;

        this.link.imageUrl = link.link.imageUrl;

        this.link.description = link.link.description;

        this.link.isImageEdited = link.link.isImageEdited;

        this.link.isTitleEdited = link.link.isTitleEdited;

        const linkedInLink = this.composerService
          .getEditorToModify(ProfileTypes.LinkedIn)
          ?.links.find((x) => x.BaseUrl == link.link.BaseUrl);

        linkedInLink.Metadata = [urlMetadata];

        linkedInLink.title = link.link.title;

        linkedInLink.imageUrl = link.link.imageUrl;

        linkedInLink.description = link.link.description;

        linkedInLink.isImageEdited = link.link.isImageEdited;

        linkedInLink.isTitleEdited = link.link.isTitleEdited;

        this.composerService.onLinksMetadataModelChange.emit(this.composerService.getLinksToSendToServer());
      });
  }

  public nakedDomain(url: string): string {
    const regex = /:\/\/(.[^/:]+)/;
    if (url.match(regex)) {
      return url.match(regex)[1].replace("www.", "");
    }
  }
}
