import {APP_INITIALIZER, PLATFORM_ID, NgModule, Inject, Optional, forwardRef} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import {StripeConfig, StripeConfigToken, loadStripeJS, stripeFactory} from "./stripe-factory";
import {Elements} from "./stripe-definitions/element";
import {Stripe} from "./stripe-definitions";
import {StripeControlDirective} from "./stripe-control/stripe-control.directive";
import {StripeMaterialDirective} from "./stripe-material/stripe-material.directive";
import {StripeCardComponent} from "./stripe-card/stripe-card.component";
import {environment} from "@environments/environment";

@NgModule({
  imports: [
    /*CommonModule*/
  ],
  declarations: [StripeControlDirective, StripeMaterialDirective, StripeCardComponent],
  exports: [StripeControlDirective, StripeMaterialDirective, StripeCardComponent],
  providers: [
    {provide: APP_INITIALIZER, useValue: loadStripeJS, multi: true},
    {
      provide: StripeConfigToken,
      useValue: {publicKey: environment.stripeKey},
    },
    {
      provide: Stripe,
      useFactory: stripeFactory,
      deps: [[new Optional(), new Inject(StripeConfigToken)]],
    },
    {
      provide: Elements,
      useFactory: (stripe: Stripe, config: StripeConfig) => stripe.elements(config && config.elementsOptions),
      deps: [forwardRef(() => Stripe), [new Optional(), new Inject(StripeConfigToken)]],
    },
  ],
})
export class StripeModule {
  constructor(@Inject(PLATFORM_ID) platformId: Record<string, unknown>) {
    if (!isPlatformBrowser(platformId)) {
      throw new Error("StripeModule package supports Browsers only");
    }
  }
}
