import {Component, OnDestroy, OnInit} from "@angular/core";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NotificationService} from "../notification.service";
import {NotificationGroup, NotificationsList} from "../notification.interface";
import {unique} from "@utils/unique.function";
import moment from "moment-timezone";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-notification-panel",
  templateUrl: "./notification-panel.component.html",
  styleUrls: ["./notification-panel.component.scss"],
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-520px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-520px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class NotificationPanelComponent extends PanelOverlay implements OnInit, OnDestroy {
  notifications: NotificationGroup[] = [];
  end: boolean;
  loading = true;
  private page = 1;
  private subscription: Subscription;

  constructor(
    dialogRef: PanelOverlayRef<NotificationPanelComponent>,
    private notificationService: NotificationService,
    private snackbar: MatSnackBar,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.notificationService
      .check()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getNotifications();
      });
  }

  ngOnDestroy() {
    this.notificationService.check().pipe(untilDestroyed(this)).subscribe();
    this.subscription.unsubscribe();
  }

  loadMore() {
    if (!this.end) {
      this.page++;
      this.loading = true;
      this.getNotifications(true);
    }
  }

  isNotificationGroupImplemented(notificationGroup: NotificationGroup): boolean {
    const implementedNotificationTypes = [6, 5];

    return (
      notificationGroup.Notifications.some((x) => x.PublishedEvents) ||
      notificationGroup.Notifications.some((x) => implementedNotificationTypes.some((t) => t == x.NotificationType))
    );
  }

  isNotificationGroupAPublishEvent(notificationGroup: NotificationGroup): boolean {
    return notificationGroup.Notifications.some((x) => x.PublishedEvents);
  }

  isNotificationGroupASystemError(notificationGroup: NotificationGroup): boolean {
    return notificationGroup.Notifications.some((x) => x.NotificationType == 6);
  }

  isNotificationGroupANewInboxConversation(notificationGroup: NotificationGroup): boolean {
    return notificationGroup.Notifications.some((x) => x.NotificationType == 4);
  }

  isNotificationGroupAInboxConversationAssignedToMe(notificationGroup: NotificationGroup): boolean {
    return notificationGroup.Notifications.some((x) => x.NotificationType == 3);
  }

  private getNotifications(concat?: boolean) {
    this.notificationService
      .list(this.page)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (notificationsList: NotificationsList) => {
          if (concat) {
            this.notifications = unique(this.notifications.concat(notificationsList.Result.Items), "Id");
          } else {
            this.notifications = notificationsList.Result.Items;
          }
          if (this.notifications.length >= notificationsList.Result.Total) {
            this.end = true;
          }
          this.subscribeToNotifications();
        },
        complete: () => (this.loading = false),
      });
  }

  private subscribeToNotifications() {
    if (!this.subscription) {
      this.subscription = this.notificationService
        .onNotification()
        .pipe(untilDestroyed(this))
        .subscribe((notificationGroup: NotificationGroup) => {
          const isDuplicated = this.notifications.find(
            (n) => n.NotificationGroupId === notificationGroup.NotificationGroupId,
          );
          if (!isDuplicated) {
            this.page = 1;
            this.getNotifications();
          }
        });
    }
  }

  clearAll() {
    this.notificationService
      .clearAll()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notifications = [];
      });
    this.snackbar.open("All notifications were cleared.");
  }

  readAll() {
    this.notificationService
      .readAll()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notifications.forEach((notification) => {
          notification.Pending = false;
        });
      });
    this.snackbar.open("All notifications were marked as read.");
  }

  getDate(date: string, index: number): string {
    const calendarDate = moment(date).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "LL",
    });
    if (index === 0) {
      return calendarDate;
    }
    const prevDate = this.notifications[index - 1].NotificationDateTime;
    if (!moment(date).isSame(prevDate, "day")) {
      return calendarDate;
    }
  }

  delete(index: number) {
    this.notifications.splice(index, 1);
  }

  close() {
    this.dialogRef.close();
  }
}
