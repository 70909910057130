import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Comment} from "./comment.model";
import {CommentResponse} from "./comment-response.interface";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  private baseUrl = environment.api + "/comments";

  constructor(private http: HttpClient) {}

  forActivity(
    provider: string,
    activityId: string,
    profileId: string,
    skip: number,
    take: number,
    cursorId?: string,
  ): Observable<CommentResponse> {
    const params: any = {
      profileId,
    };
    if (skip) {
      params.skip = skip.toString();
    }
    if (take) {
      params.take = take.toString();
    }
    if (cursorId) {
      params.cursorId = cursorId;
    }
    return this.http.get<CommentResponse>(this.baseUrl + `/${provider}/for-activity/${activityId}`, {params}).pipe(
      map((response: CommentResponse) => {
        response.ActivityComments = this.mapComments(response.ActivityComments);
        return response;
      }),
    );
  }

  forComment(
    provider: string,
    activityId: string,
    commentId: string,
    profileId: string,
    skip: number,
    take: number,
    cursorId?: string,
  ): Observable<CommentResponse> {
    const params: any = {
      profileId,
    };
    if (skip) {
      params.skip = skip.toString();
    }
    if (take) {
      params.take = take.toString();
    }
    if (cursorId) {
      params.cursorId = cursorId;
    }
    return this.http
      .get<CommentResponse>(this.baseUrl + `/${provider}/for-comment/${activityId}/${commentId}`, {params})
      .pipe(
        map((response: CommentResponse) => {
          response.ActivityComments = this.mapComments(response.ActivityComments);
          return response;
        }),
      );
  }

  publish(activityId: string, profileId: string, text: string, parentId?: string): Observable<Comment> {
    return this.http
      .post(this.baseUrl, {
        ActivityId: activityId,
        ExternalProfileId: profileId,
        ParentId: parentId,
        Text: text,
      })
      .pipe(map((comment: any) => this.mapComment(comment)));
  }

  delete(activityId: string, profileId: string, commentId: string): Observable<any> {
    return this.http.post(this.baseUrl + "/delete", {
      ActivityId: activityId,
      ExternalProfileId: profileId,
      CommentId: commentId,
    });
  }

  hide(profileId: string, commentId: string): Observable<any> {
    return this.http.post(this.baseUrl + "/hide", {
      ExternalProfileId: profileId,
      CommentId: commentId,
    });
  }

  show(profileId: string, commentId: string): Observable<any> {
    return this.http.post(this.baseUrl + "/show", {
      ExternalProfileId: profileId,
      CommentId: commentId,
    });
  }

  private mapComment(comment): Comment {
    return new Comment(
      comment.ActivityCommentText,
      comment.Text,
      comment.ParentActivityId,
      comment.Author,
      comment.ParentCommentId,
      comment.IsPriority,
      comment.AngryCount,
      comment.WowCount,
      comment.SadCount,
      comment.HahaCount,
      comment.LoveCount,
      comment.ReactionsCount,
      comment.JoinFieldParentId,
      comment.Type,
      comment.AccountId,
      comment.BrandId,
      comment.CreatedTime,
      comment.UpdatedTime,
      comment.Engagement,
      comment.SourceType,
      comment.CommentsCount,
      comment.MD5Hash,
      comment.Urls,
      comment.Language,
      comment.Source,
      comment.Comments ? this.mapComments(comment.Comments) : null,
      comment.StoredCommentsCount,
      comment.Attachment,
      comment.LikeCount,
      comment.UrlMetadata,
      comment.Reactions,
      comment.Hidden,
      comment.Anonymous,
      comment.Id,
    );
  }

  public mapComments(comments): Comment[] {
    return comments.map((comment) => {
      return this.mapComment(comment);
    });
  }
}
