import {OverlayRef} from "@angular/cdk/overlay";
import {Observable, Subject} from "rxjs";
import {filter, take} from "rxjs/operators";
import {PanelOverlay} from "./panel-overlay";

export class PanelOverlayRef<T extends PanelOverlay> {
  componentInstance: T;
  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<any>();
  afterClosed: Observable<any>;
  beforeClose: Observable<void> = this._beforeClose.asObservable();
  shiftOffset: number;
  childOverlayRef: PanelOverlayRef<T>;

  constructor(
    public overlayRef: OverlayRef,
    public parentOverlayRef: PanelOverlayRef<T>,
  ) {
    this.afterClosed = this._afterClosed.asObservable();
  }

  close(result?: any): void {
    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === "start"),
        take(1),
      )
      .subscribe(() => {
        this._beforeClose.next();
        this._beforeClose.complete();
        this.overlayRef.detachBackdrop();
        let parent = this.parentOverlayRef;
        if (parent) {
          parent.childOverlayRef = null;
        }
        while (parent) {
          parent.unshiftPanel();
          parent = parent.parentOverlayRef;
        }
      });

    this.componentInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === "done" && event.toState === "leave"),
        take(1),
      )
      .subscribe(() => {
        this.overlayRef.dispose();
        this._afterClosed.next(result);
        this._afterClosed.complete();

        this.componentInstance = null;
      });

    this.componentInstance.startExitAnimation();
  }

  shiftPanel(childOverlayRef: PanelOverlayRef<T>) {
    this.childOverlayRef = childOverlayRef;
    this.overlayRef.overlayElement.style.transition = "500ms cubic-bezier(.15,1,.3,1)";
    this.setShiftOffset(childOverlayRef);
  }

  unshiftPanel() {
    this.setShiftOffset(this.childOverlayRef);
  }

  private setShiftOffset(childOverlayRef: PanelOverlayRef<T>) {
    if (childOverlayRef && childOverlayRef.overlayRef) {
      const childWidth = childOverlayRef.overlayRef.overlayElement.offsetWidth;
      const currentWidth = this.overlayRef.overlayElement.offsetWidth;
      const childOffset = childOverlayRef.shiftOffset || 0;
      this.shiftOffset = childWidth - currentWidth / 2 + 100 + childOffset;
      if (this.shiftOffset >= 0) {
        var isPositionRight = this.overlayRef.getConfig().positionStrategy["_justifyContent"] == "flex-end";

        if (isPositionRight)
          this.overlayRef.overlayElement.style.transform = "translateX(" + -1 * this.shiftOffset + "px)";
        else this.overlayRef.overlayElement.style.transform = "translateX(" + this.shiftOffset + "px)";
      }
    } else {
      this.shiftOffset = 0;
      this.overlayRef.overlayElement.style.transform = "translateX(0)";
    }
  }
}
