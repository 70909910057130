import {Activity} from "../activity.model";
import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {Hashtag, Mention, TwitterPhoto, Url, Video} from "../activity.interface";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {youtubeParse} from "@utils/youtube-parse.function";
import {MediaFileUploaded} from "@publisher/content.interface";

export class Tweet {
  constructor(
    public Id?: number,
    public Prefix?: string,
    public Content?: string,
    public RetweetsCount?: number,
    public LikesCount?: number,
    public InReplyToStatusId?: number,
    public InReplyToUserId?: number,
    public QuotedStatusId?: number,
    public InReplyToScreenName?: string,
    public Hashtags?: Hashtag[],
    public Photos?: TwitterPhoto[],
    public Urls?: Url[],
    public Mentions?: Mention[],
    public Videos?: Video[],
    public IsRetweeted?: boolean,
    public Original?: Activity,
    public Permalink?: string,
  ) {}

  get photosAttachment(): ImageAttachment[] {
    if (this.Photos && this.Photos.length) {
      return this.Photos.map((photo) => {
        const attachment = {
          src: photo.MediaUrlHttps,
          thumb: photo.MediaUrlHttps + ":thumb",
        };
        if (photo.Sizes) {
          const largeSize = photo.Sizes.sort((a, b) => a.Width * a.Height - b.Width * b.Height)[0];
          return {
            ...attachment,
            height: largeSize.Height,
            width: largeSize.Width,
          };
        }
        return attachment;
      });
    }
  }

  public setPhotosAttachment(images: MediaFileUploaded[]) {
    this.Photos = images
      .filter((image: MediaFileUploaded) => image.EnabledForTwitter)
      .map((image) => {
        return {
          MediaUrlHttps: image.PreviewUrl,
        };
      });
  }

  public setVideoAttachment(video: MediaFileUploaded) {
    this.Videos = [
      {
        MediaType: video.MediaType,
        Thumbnail: video.PreviewUrl,
        Url: video.BlobUrl,
      },
    ];
  }

  get videoAttachment(): VideoAttachment {
    if (this.Videos && this.Videos.length) {
      return {
        type: this.Videos[0].MediaType,
        preview: this.Videos[0].Thumbnail,
        source: this.Videos[0].Url,
        width: this.Videos[0].AspectRatio ? this.Videos[0].AspectRatio[0] : null,
        height: this.Videos[0].AspectRatio ? this.Videos[0].AspectRatio[1] : null,
      };
    }
    if (this.Urls && this.Urls[0]) {
      if (this.Urls[0].ExpandedUrl.indexOf("youtube.com") !== -1) {
        const id = youtubeParse(this.Urls[0].ExpandedUrl);
        if (id) {
          return {
            type: "share",
            preview: "https://img.youtube.com/vi/" + id + "/hqdefault.jpg",
            source: "https://www.youtube.com/embed/" + id,
            width: null,
            height: null,
          };
        }
      }
    }
  }
}
