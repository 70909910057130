import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {UserWidget} from "../../widget.interface";
import {Observable} from "rxjs";
import Highcharts from "highcharts";
import {finalize, map, tap} from "rxjs/operators";

@Component({
  selector: "app-followers-count-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowersCountGraphComponent extends BaseGraphComponent {
  elementId = "followerscountgraph";

  public options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      styledMode: true,
    },
    title: {
      text: "Follower Counts",
      align: "left",
    },
    xAxis: {
      categories: [],
      tickmarkPlacement: "on",
      title: {},
    },
    yAxis: {
      title: {
        text: "Followers",
      },
      labels: {
        formatter: function () {
          return this.value.toString();
        },
      },
    },
    tooltip: {
      split: true,
      valueSuffix: "",
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
    },
    legend: {
      itemStyle: {
        fontWeight: "normal",
      },
    },
    series: [],
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "contrast",
      },
    },
    lang: {
      noData: "No data to display",
    },
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            //"printChart",
            //"separator",
            //"downloadPNG",
            //"downloadJPEG",
            //"downloadPDF",
            //"downloadSVG",
            //"separator",
            "downloadCSV",
            //"downloadXLS",
            //"viewData",
            //"openInCloud",
          ],
        },
      },
      filename: "Follower_Counts",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date): Observable<void> {
    return this.analyticsService.getFollowersCountData(startDate, endDate).pipe(
      tap((data) => {
        // console.log("[followers-count-graph] updateChartData() pipe continuing");

        (this.options.xAxis as Highcharts.XAxisOptions).categories = data.map((x1) => x1.ColumnName);

        const seriesValue = [];

        if (data.length > 0) {
          seriesValue.push({
            name: "Organic Followers",
            data: data.map((val) => val.OrganicFollowersCount),
          });

          seriesValue.push({
            name: "Paid Followers",
            data: data.map((val) => val.PaidFollowersCount),
          });

          seriesValue.push({
            name: "Total Followers",
            data: data.map((val) => val.PaidFollowersCount + val.OrganicFollowersCount),
          });
        }

        this.options.series = seriesValue;
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
