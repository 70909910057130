import {Component, ElementRef, forwardRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {TimeStruct} from "./time-picker/time-struct.interface";
import {padNumber} from "../utils.functions";

interface Hour {
  value: number;
  text: number;
}

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-time-selector",
  templateUrl: "./time-selector.component.html",
  styleUrls: ["./time-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeSelectorComponent),
      multi: true,
    },
  ],
})
export class TimeSelectorComponent implements OnInit, ControlValueAccessor {
  constructor(protected overlay: Overlay) {}

  get value(): string {
    if (
      this.model == null ||
      this.model.hour == null ||
      typeof this.model.hour === "undefined" ||
      this.model.minute == null ||
      typeof this.model.minute === "undefined"
    ) {
      return;
    }
    const h = this.model.hour === 0 ? 12 : this.model.hour > 12 ? this.model.hour - 12 : this.model.hour;
    const meridian = this.model.hour >= 12 ? "PM" : "AM";
    return padNumber(h) + ":" + padNumber(this.model.minute) + " " + meridian;
  }

  @ViewChild("hoursPanel", {static: true}) hoursPanel: TemplatePortal;
  @ViewChild("minutesPanel", {static: true})
  minutesPanel: TemplatePortal;
  @ViewChild("reference", {static: true}) reference: ElementRef;

  hoursList: any[];
  minutesList: number[];
  hoveringMinutes: number;
  hoveringHours: Hour;

  protected overlayRef: OverlayRef;

  model: TimeStruct = {
    hour: 0,
    minute: 0,
    second: 0,
  };

  private static formatHours(hours: number[]): Hour[] {
    const result = [];
    for (let index = 0; index < hours.length; index++) {
      const n = hours[index];
      result.push({value: n, text: n === 0 ? 12 : n});
    }
    return result;
  }

  private static getRange(interval: number, limit: number): number[] {
    const range = [];
    for (let i = 0; i < limit; i = i + interval) {
      range.push(i);
    }
    return range;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  ngOnInit() {
    this.hoursList = TimeSelectorComponent.formatHours(TimeSelectorComponent.getRange(1, 12));
    this.minutesList = TimeSelectorComponent.getRange(5, 60);
  }

  public showOverlay(portal: TemplatePortal) {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(portal);
    this.syncWidth();
    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.hideOverlay());
  }

  public hideOverlay() {
    this.overlayRef.detach();
  }

  @HostListener("window:resize")
  public onWinResize() {
    this.syncWidth();
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }
    const refRect = this.reference.nativeElement.getBoundingClientRect();
    this.overlayRef.updateSize({width: refRect.width});
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([
        {
          originX: "start",
          originY: "bottom",
          overlayX: "start",
          overlayY: "top",
        },
        {
          originX: "start",
          originY: "top",
          overlayX: "start",
          overlayY: "bottom",
        },
      ]);

    return new OverlayConfig({
      positionStrategy: positionStrategy,
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
    });
  }

  onTimepickerClick(event) {
    const field = event.target.getAttribute("aria-label");
    if (field === "Hours") {
      this.showOverlay(this.hoursPanel);
    } else if (field === "Minutes") {
      this.showOverlay(this.minutesPanel);
    }
  }

  onTimeChange() {
    this.onChange(this.value);
  }

  setHours() {
    const hour24format = this.model?.hour > 11 ? this.hoveringHours.value + 12 : this.hoveringHours.value;
    this.model = {
      ...this.model,
      hour: hour24format === 0 ? 12 : hour24format,
    } as TimeStruct;

    if (!this.model?.minute) {
      this.model.minute = 0;
    }

    this.onChange(this.value);
    this.hideOverlay();
  }

  setMinutes() {
    this.model = {
      ...this.model,
      minute: this.hoveringMinutes,
    } as TimeStruct;

    if (!this.model?.hour) {
      this.model.hour = 12;
    }

    this.onChange(this.value);
    this.hideOverlay();
  }

  public registerOnChange(fn: (_: any) => Record<string, never>): void {
    this.onChange = fn;
  }

  public writeValue(value: string): void {
    if (value) {
      const matches = value.match(/^(\d{1,2}):(\d{1,2}) ([AP]M)$/);
      if (!matches) return;
      this.model = {
        hour: +matches[1] + (matches[3] === "PM" && matches[1] != "12" ? 12 : 0),
        minute: +matches[2],
        second: 0,
      };

      if (this.model.hour == 12 && matches[3] == "AM") {
        this.model.hour = 0;
      }

      this.onChange(this.value);
    }
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
