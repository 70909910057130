<div
  class="gallery"
  [class.single]="image.length === 1"
  [class.double]="image.length === 2"
  [class.triple]="image.length === 3"
  [class.quad]="image.length === 4"
  [class.quint]="image.length >= 5">
  <div
    [ngClass]="{
      first: i === 0,
      second: i === 1,
      third: i === 2,
      fourth: i === 3,
      fifth: i === 4
    }"
    *ngFor="let photo of gallery; let i = index">
    <img
      class="photo"
      [src]="photo.src"
      *ngIf="photo.src"
      (click)="open(i)"
      [imageWidth]="imageWidth"
      [imageQuality]="imageQuality"
      [alt]="photo.caption" />
    <div class="more" *ngIf="i === 4 && image.length > 5">+{{ image.length - 5 }}</div>
  </div>
  <a *ngIf="album" class="album" [href]="album.url" target="_blank">
    <mat-icon svgIcon="external-link"></mat-icon>
    <h3>{{ album.title }}</h3>
  </a>
</div>
