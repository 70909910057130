import {Component, Input, OnInit, SimpleChanges} from "@angular/core";
import {Activity} from "../activity.model";
import {LinkPreview} from "../attachments/link-preview/link-preview.component";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {OnChanges} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";
import {LinkedInPoll, LinkedInPollOption} from "./linkedin-post.model";
import {CarouselItem} from "../attachments/carousel-attachment/carousel-attachment.component";
import moment from "moment";
import {getDomain} from "@utils/link.function";

@UntilDestroy()
@Component({
  selector: "app-linkedin-activity",
  styleUrls: ["./linkedin-activity.component.scss"],
  templateUrl: "./linkedin-activity.component.html",
})
export class LinkedInActivityComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showVolumeControl: boolean;
  @Input() showSource: boolean;
  @Input() showBadge = true;
  @Input() showDescription: boolean;
  @Input() showDomain: boolean;
  @Input() isRepost: boolean;

  @Input() showEmployeeSharing: boolean;

  linkPreview: LinkPreview;
  photos: ImageAttachment[];
  video: VideoAttachment;
  carousel: CarouselItem[];

  public get selectedOption(): LinkedInPollOption {
    return this.activity.LinkedInPost.Poll.Options.find((x) => x.Voted);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activity?.currentValue !== null) {
      this.activity = changes.activity.currentValue;
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    if (this.activity.LinkedInPost.ShareMediaCategory == "IMAGE") {
      this.photos = this.activity.LinkedInPost.Media.map((x) => {
        return {
          src: x.OriginalUrl,
          thumb: x?.Thumbnails?.Url,
          height: x?.Thumbnails?.Height,
          width: x?.Thumbnails?.Width,
        };
      });
    } else if (this.activity.LinkedInPost.ShareMediaCategory == "VIDEO") {
      const video = this.activity.LinkedInPost.Media[0];
      this.video = {
        preview: video.Thumbnails.Url,
        height: video.Thumbnails.Height,
        width: video.Thumbnails.Width,
        type: "video",
        source: video.OriginalUrl,
      };
    } else if (
      this.activity.LinkedInPost.ShareMediaCategory == "ARTICLE" &&
      this.activity.LinkedInPost.Media?.length > 0
    ) {
      const articleInfo = this.activity.LinkedInPost.Media[0];
      this.linkPreview = {
        url: articleInfo.OriginalUrl,
        title: articleInfo.Title,
        description: articleInfo.Description,
        image: articleInfo.Thumbnails.Url,
        domain: getDomain(articleInfo.OriginalUrl),
      };

      if (
        articleInfo.Thumbnails.Url == null &&
        this.activity.UrlMetadata != null &&
        this.activity.UrlMetadata.length > 0 &&
        this.activity.UrlMetadata[0].MetaTags.length > 0
      ) {
        articleInfo.Thumbnails.Url = this.activity.UrlMetadata[0].MetaTags.find((x) => x.Key == "og:image")?.Value;
      }
    } else if (this.activity.LinkedInPost.ShareMediaCategory == "ARTICLE" && this.activity.LinkedInPost.Link != null) {
      const link = this.activity.LinkedInPost.Link;
      this.linkPreview = {
        url: link.Url,
        title: link.Caption,
        description: link.Description,
        image: link.Picture,
        domain: link.Domain,
      };
    } else if (
      this.activity.LinkedInPost.ShareMediaCategory == "NATIVE_DOCUMENT" ||
      this.activity.LinkedInPost.ShareMediaCategory == "CAROUSEL"
    ) {
      this.carousel = this.carouselItems;
    } else {
      this.video = null;
      this.photos = [];
      this.linkPreview = null;
    }
  }

  getMissingTime(linkedInPoll: LinkedInPoll) {
    const expirationDate = moment(linkedInPoll.ExpirationDate);
    const diffHours = expirationDate.diff(moment(), "hours");
    const diffMinutes = expirationDate.diff(moment(), "minutes");
    if (diffHours > 24) return `${expirationDate.diff(moment(), "days")} days left`;
    else if (diffHours >= 1 && diffHours < 24) return `${diffHours} hours left`;
    else if (diffMinutes >= 0 && diffMinutes < 60) return `${diffMinutes} minutes left`;

    return "Poll closed";
  }

  get carouselItems(): CarouselItem[] {
    if (
      this.activity.LinkedInPost.ShareMediaCategory == "NATIVE_DOCUMENT" &&
      this.activity.LinkedInPost.Document?.Pages &&
      this.activity.LinkedInPost.Document?.Pages.length > 0
    ) {
      return this.activity.LinkedInPost.Document.Pages.map((page) => {
        return {
          image: page.Url,
        };
      });
    } else if (this.activity.LinkedInPost.ShareMediaCategory == "CAROUSEL") {
      return this.activity.LinkedInPost.Media.map((media) => {
        return {
          image: media.OriginalUrl,
        };
      });
    }

    return [];
  }

  getPollVoteCount(linkedInPoll: LinkedInPoll): number {
    if (!linkedInPoll.Options) return 0;
    return linkedInPoll.Options.map((x) => x.VoteCount).reduce((a, b) => a + b, 0);
  }
}
