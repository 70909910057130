<div class="card">
  <div class="graphics">
    <app-profile-picture
      *ngIf="channelPhoto"
      [picture]="channelPhoto"
      [provider]="channelProvider"
      [showBadge]="showProvider"
      [pictureSize]="80"
      [badgeSize]="22"
      [mediaWidth]="160"
      [mediaQuality]="80"
      [hasChannel]="true"
      class="photo">
    </app-profile-picture>
  </div>
  <div class="info">
    <a
      *ngIf="channelName && channelNameUrl && channelSubtype !== 'PublicHashtag' && channelProvider !== 8"
      class="name"
      [title]="channelName"
      [href]="channelNameUrl"
      target="_blank"
      >{{ channelName }}</a
    >
    <a
      *ngIf="channelName && channelSubtype === 'PublicHashtag'"
      class="name"
      [title]="channelName"
      [href]="'https://twitter.com/search?q=%23' + (channelName | slice: 1)"
      target="_blank"
      >{{ channelName }}</a
    >
    <a
      *ngIf="channelName && channelSubtype === 'LinkedInPage'"
      class="name"
      [title]="channelName"
      [href]="channelNameUrl"
      target="_blank"
      >{{ channelName }}</a
    >
    <a
      *ngIf="channelName && channelSubtype === 'LinkedInUser'"
      class="name"
      [title]="channelName"
      [href]="'https://linkedin.com/in/' + channelHandle"
      target="_blank"
      >{{ channelName }}</a
    >
    <div
      *ngIf="
        channelName &&
        !channelNameUrl &&
        channelSubtype !== 'PublicHashtag' &&
        channelSubtype !== 'LinkedInPage' &&
        channelSubtype !== 'LinkedInUser'
      "
      class="name text-light-base-600 dark:text-dark-base-600"
      [title]="channelName">
      {{ channelName }}
    </div>
    <!-- Handle -->
    <div
      *ngIf="showHandle"
      class="text-light-base-400 dark:text-dark-base-400 text-xs -mb-1 line-clamp-1"
      [title]="'@' + channelHandle">
      &#64;{{ channelHandle }}
    </div>
    <!-- Alert -->
    <div *ngIf="showAlert" class="alert -mb-1 line-clamp-1">
      <ng-content></ng-content>
    </div>
    <!-- Subtype -->
    <div *ngIf="showSubtype" class="text-light-base-400 dark:text-dark-base-400 text-xs -mb-1 line-clamp-1">
      <span *ngIf="channelSubtype === 'PublicHashtag'">Hashtag</span>
      <span *ngIf="channelSubtype === 'FacebookInstagramPage'">Page</span>
      <span *ngIf="channelSubtype === 'FacebookInstagraBusinessDiscoveryPage'">Page</span>
      <span *ngIf="channelSubtype === 'FacebookInstagramPageMentions'">Mentions</span>
      <span *ngIf="channelSubtype === 'FacebookInstagramPageTags'">Tags</span>
    </div>
    <!-- Followers -->
    <div *ngIf="showFollowers" class="text-light-base-400 dark:text-dark-base-400 text-xs -mb-1 line-clamp-1">
      <span
        ><strong>{{ channelFollowers | shortNumber }}</strong> Followers</span
      >
    </div>
    <!-- MediaCount -->
    <div *ngIf="showMediaCount" class="text-light-base-400 dark:text-dark-base-400 text-xs -mb-1 line-clamp-1">
      <span
        ><strong>{{ channelMediaCount | shortNumber }}</strong> Media
      </span>
    </div>
    <div class="action">
      <div *ngIf="isSelected">
        <app-button type="block" (buttonClick)="removeButton.emit()" [disabled]="removeButtonDisabled"
          >Remove
        </app-button>
      </div>
      <div *ngIf="!isSelected">
        <app-button-stroked
          type="block"
          (buttonClick)="selectButton.emit()"
          [disabled]="selectButtonDisabled"
          data-test="channel-card-component-sharedSelect-button"
          >Select
        </app-button-stroked>
      </div>
    </div>
  </div>
</div>
