import {Directive} from "@angular/core";
import {GlobalPermissionEnum, User} from "@shared/user/user.interface";
import {BaseRoleValidator} from "../base-role-validator";

@Directive({
  selector: "[appGlobalroleValidator]",
})
export class GlobalRoleValidatorDirective extends BaseRoleValidator {
  public UserHasPermission(user: User, permission: string) {
    const globalPermissionRequested = GlobalPermissionEnum[permission];
    return user.GlobalPermissions.some((x) => x == globalPermissionRequested);
  }
}
