import {
  ComposerActionSchedule,
  ComposerActionTypeEnum,
  ContentModel,
  MediaFileUploaded,
} from "@shared/publisher/content.interface";
import {ISelectedProfile} from "../composer.component";
import {Injectable} from "@angular/core";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {InstagramComposerValidation} from "./InstagramComposerValidation";
import {TwitterComposerValidation} from "./TwitterComposerValidation";
import {FacebookComposerValidation} from "./FacebookComposerValidation";
import {LinkedInComposerValidation} from "./LinkedInComposerValidation";
import moment from "moment";
import {GeneralComposerValidation} from "./GeneralComposerValidation";
import {IProfile} from "@shared/channel/profile.interface";
import {UserService} from "@shared/user/user.service";
import {ChannelPermissionsEnum} from "@shared/user/user.interface";

@Injectable({
  providedIn: "root",
})
export class ComposerValidationService {
  GeneralValidation: GeneralComposerValidation = new GeneralComposerValidation();
  FacebookValidation: FacebookComposerValidation = new FacebookComposerValidation();
  TwitterValidation: TwitterComposerValidation = new TwitterComposerValidation();
  InstagramValidation: InstagramComposerValidation = new InstagramComposerValidation();
  LinkedInValidation: LinkedInComposerValidation = new LinkedInComposerValidation();

  constructor(private userService: UserService) {}

  ValidateComposer(
    schedule: ComposerActionSchedule,
    profiles: IProfile[],
    selectedProfiles: ISelectedProfile[],
    contentModel: ContentModel,
    composerActionType: ComposerActionTypeEnum,
    expiration: ComposerActionSchedule = null,
  ): boolean {
    this.ResetValidation();

    this.ValidateProfiles(profiles, selectedProfiles, composerActionType);

    this.ValidateExpiration(expiration);

    if (composerActionType == ComposerActionTypeEnum.ScheduleOneTime) this.ValidateSchedule(schedule);

    this.ValidateUploadingMediaFiles(contentModel.MediaFiles);

    this.GeneralValidation.ComposerHasErrors =
      this.GeneralValidation.ComposerHasErrors ||
      this.GeneralValidation.NoChannel ||
      this.GeneralValidation.NoProfileSelected ||
      this.GeneralValidation.PostInThePast ||
      this.GeneralValidation.TimezoneEmpty ||
      this.GeneralValidation.MediaFilesUploading ||
      this.GeneralValidation.ProfileRequiresAttention ||
      this.GeneralValidation.ExpirationInThePast ||
      this.GeneralValidation.ProfileRequiresPermission ||
      this.GeneralValidation.IncompleteExpirationDate ||
      this.GeneralValidation.IncompleteScheduleDate;

    if (contentModel == null) {
      console.warn("end composer-validation. With contentModel null");
      return;
    }

    if (this.ShouldValidateFacebook(selectedProfiles)) {
      const isFacebookValid = this.FacebookValidation.Validate(contentModel, selectedProfiles);
      if (!this.GeneralValidation.ComposerHasErrors && !isFacebookValid) {
        this.GeneralValidation.ComposerHasErrors = true;
      }
    }

    if (this.ShouldValidateTwitter(selectedProfiles, contentModel)) {
      const isTwitterValid = this.TwitterValidation.Validate(contentModel, selectedProfiles);
      if (!this.GeneralValidation.ComposerHasErrors && !isTwitterValid) {
        this.GeneralValidation.ComposerHasErrors = true;
      }
    }

    if (this.ShouldValidateInstagram(selectedProfiles)) {
      const isInstagramValid = this.InstagramValidation.Validate(contentModel, selectedProfiles);
      if (!this.GeneralValidation.ComposerHasErrors && !isInstagramValid) {
        this.GeneralValidation.ComposerHasErrors = true;
      }
    }

    if (this.ShouldValidateLinkedIn(selectedProfiles)) {
      const isLinkedInValid = this.LinkedInValidation.Validate(contentModel, selectedProfiles);
      if (!this.GeneralValidation.ComposerHasErrors && !isLinkedInValid) {
        this.GeneralValidation.ComposerHasErrors = true;
      }
    }

    return !this.GeneralValidation.ComposerHasErrors;
  }

  ValidateUploadingMediaFiles(mediaFiles: MediaFileUploaded[]) {
    this.GeneralValidation.MediaFilesUploading = !mediaFiles.every((x) => x.uploaded);
  }

  ShouldValidateFacebook(selectedProfiles: ISelectedProfile[]): boolean {
    const profileTypes = selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();

    return profileTypes.indexOf(ProfileTypes.Facebook) !== -1;
  }

  ShouldValidateTwitter(selectedProfiles: ISelectedProfile[], contentModel: ContentModel): boolean {
    const profileTypes = selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();

    return profileTypes.indexOf(ProfileTypes.TwitterAccount) !== -1;
  }

  ShouldValidateInstagram(selectedProfiles: ISelectedProfile[]) {
    const profileTypes = selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();

    return profileTypes.indexOf(ProfileTypes.InstagramAccount) !== -1;
  }

  ShouldValidateLinkedIn(selectedProfiles: ISelectedProfile[]) {
    const profileTypes = selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();

    return profileTypes.indexOf(ProfileTypes.LinkedIn) !== -1;
  }

  ValidateSchedule(schedule: ComposerActionSchedule) {
    if (schedule == null) {
      return;
    }

    if (schedule.timezoneId === undefined) {
      console.log("[ValidateSchedule] missing timezone, failing validation");
      this.GeneralValidation.TimezoneEmpty = true;
    }

    if (!schedule.startAt || !schedule.time) {
      this.GeneralValidation.IncompleteScheduleDate = true;
      return;
    } else {
      this.GeneralValidation.IncompleteScheduleDate = false;
    }

    const format = "DD-MM-YYYY hh:mm a";

    const startAtMoment = moment(this.dateToYMD(schedule.startAt), "YYYY-MM-DD");

    const scheduledMomentWithTimeAsString = startAtMoment.format("DD-MM-YYYY") + " " + schedule.time;

    const timezoneToUse = schedule.timezoneId["IanaId"];

    //Doing this so that both times are in the same timezone so that isAfter does the comparison on the same timezone.
    const nowMoment = moment(moment().tz(timezoneToUse).format(format), format);
    const scheduledMoment = moment(scheduledMomentWithTimeAsString, format);

    this.GeneralValidation.PostInThePast = nowMoment.isAfter(scheduledMoment, "second");

    this.GeneralValidation.TimezoneEmpty = !(schedule.timezoneId["IanaId"]?.length > 0);
  }

  ValidateScheduleForShareDialog(schedule: ComposerActionSchedule): GeneralComposerValidation {
    if (schedule == null) {
      return;
    }

    const generalValidation = new GeneralComposerValidation();

    if (schedule.timezoneId === undefined) {
      generalValidation.TimezoneEmpty = true;
    }

    if (!schedule.startAt || !schedule.time) {
      this.GeneralValidation.IncompleteScheduleDate = true;
      return;
    } else {
      this.GeneralValidation.IncompleteScheduleDate = false;
    }

    const format = "DD-MM-YYYY hh:mm a";

    const startAtMoment = moment(this.dateToYMD(schedule.startAt), "YYYY-MM-DD");

    const scheduledMomentWithTimeAsString = startAtMoment.format("DD-MM-YYYY") + " " + schedule.time;

    const timezoneToUse = schedule.timezoneId["IanaId"];

    //Doing this so that both times are in the same timezone so that isAfter does the comparison on the same timezone.
    const nowMoment = moment(moment().tz(timezoneToUse).format(format), format);
    const scheduledMoment = moment(scheduledMomentWithTimeAsString, format);

    generalValidation.PostInThePast = nowMoment.isAfter(scheduledMoment, "second");

    generalValidation.TimezoneEmpty = !(schedule.timezoneId["IanaId"]?.length > 0);

    return generalValidation;
  }

  ValidateExpiration(expiration: ComposerActionSchedule) {
    if (expiration == null) {
      return;
    }

    this.GeneralValidation.TimezoneEmpty = !(expiration.timezoneId["IanaId"]?.length > 0);

    if (!expiration.startAt || !expiration.time) {
      this.GeneralValidation.IncompleteExpirationDate = true;
      return;
    } else {
      this.GeneralValidation.IncompleteExpirationDate = false;
    }

    const format = "DD-MM-YYYY hh:mm a";

    const startAtMoment = moment(this.dateToYMD(expiration.startAt), "YYYY-MM-DD");

    const scheduledMomentWithTimeAsString = startAtMoment.format("DD-MM-YYYY") + " " + expiration.time;

    const timezoneToUse = expiration.timezoneId["IanaId"];

    //Doing this so that both times are in the same timezone so that isAfter does the comparison on the same timezone.
    const nowMoment = moment(moment().tz(timezoneToUse).format(format), format);
    const scheduledMoment = moment(scheduledMomentWithTimeAsString, format);

    this.GeneralValidation.ExpirationInThePast = nowMoment.isAfter(scheduledMoment, "second");
  }

  ValidateProfiles(
    profiles: IProfile[],
    selectedProfiles: ISelectedProfile[],
    composerActionType: ComposerActionTypeEnum = null,
  ) {
    this.GeneralValidation.NoChannel = !profiles || profiles.length === 0;
    this.GeneralValidation.NoProfileSelected = !selectedProfiles || selectedProfiles.length === 0;
    this.GeneralValidation.ProfileRequiresAttention = selectedProfiles.some(
      (x) => x.profile.Credential.RequiresAttention,
    );

    if (composerActionType != null) {
      const profileIds = selectedProfiles.map((x) => x.profile.AssociatedSource.Id);

      const channelRoles = this.userService.userObservable.value?.ChannelsRoles;

      for (const profileId of profileIds) {
        if (this.GeneralValidation.ProfileRequiresPermission) break;

        const channelPermissions = channelRoles.find((x) => x.ChannelId == profileId);

        this.GeneralValidation.ProfileRequiresPermission =
          channelPermissions == null || !channelPermissions.Permissions.includes(ChannelPermissionsEnum.Publish);
      }
    } else console.log("Composeractionstype is null");

    const hasFacebookSelected = selectedProfiles.some((x) => x.profile.Type === ProfileTypes.Facebook);
    const hasTwitterSelected = selectedProfiles.some((x) => x.profile.Type === ProfileTypes.TwitterAccount);
    const hasInstagramSelected = selectedProfiles.some((x) => x.profile.Type === ProfileTypes.InstagramAccount);
    const hasLinkedInSelected = selectedProfiles.some((x) => x.profile.Type === ProfileTypes.LinkedIn);
    if (!hasFacebookSelected) {
      this.FacebookValidation.Reset();
    }

    if (!hasTwitterSelected) {
      this.TwitterValidation.Reset();
    }

    if (!hasInstagramSelected) {
      this.InstagramValidation.Reset();
    }

    if (!hasLinkedInSelected) {
      this.LinkedInValidation.Reset();
    }
  }

  ResetValidation() {
    this.GeneralValidation.Reset();
    this.FacebookValidation.Reset();
    this.TwitterValidation.Reset();
    this.InstagramValidation.Reset();
    this.LinkedInValidation.Reset();
  }

  TriggerSocialMediaValidationByType(Type: number, model: ContentModel, selectedProfiles: ISelectedProfile[]) {
    if (Type === ProfileTypes.Facebook) {
      this.FacebookValidation.Validate(model, selectedProfiles);
    } else if (Type === ProfileTypes.TwitterAccount) {
      this.TwitterValidation.Validate(model, selectedProfiles);
    } else if (Type === ProfileTypes.InstagramAccount) {
      this.InstagramValidation.Validate(model, selectedProfiles);
    } else if (Type === ProfileTypes.LinkedIn) {
      this.LinkedInValidation.Validate(model, selectedProfiles);
    }
  }

  dateToYMD(date) {
    console.log({date});
    const d = date.getDate();
    const m = date.getMonth() + 1; // Month from 0 to 11
    const y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }
}
