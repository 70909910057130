import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {FacebookLink} from "../activity.interface";
import {LinkPreview} from "../attachments/link-preview/link-preview.component";
import {Activity} from "../activity.model";

export class LinkedInPost {
  Link?: FacebookLink;

  constructor(
    public Media?: LinkedInMedia[],
    public Visibility?: string,
    public FirstPublishedAt?: Date,
    public VersionTag?: string,
    public LastModified?: string,
    public FeedDistribution?: string,
    public Hashtags?: string[],
    public ShareMediaCategory?: string,
    public Poll?: LinkedInPoll,
    public Document?: LinkedInDocument,
    public Repost?: Activity,
    public IsAPersonPost?: boolean,
    public OriginalPostUrn?: string,
    public IsSponsoredContent?: boolean,
  ) {}

  public get photosAttachment(): ImageAttachment[] {
    if (this.Media.length) {
      this.Media.map((x) => {
        return {
          src: x.OriginalUrl,
          thumb: x.Thumbnails.Url,
          height: x.Thumbnails.Height,
          width: x.Thumbnails.Width,
        };
      });
    }

    return null;
  }

  get linkPreview(): LinkPreview {
    if (this.Link) {
      return {
        url: this.Link.Url,
        title: this.Link.Caption,
        description: this.Link.Description ? this.Link.Description.trim() : "",
        image: this.Link.Picture,
        domain: this.Link.Domain,
      };
    }
  }
}

export interface LinkedInMedia {
  Id: string;
  OriginalUrl: string;
  Thumbnails: LinkedInThumbnail;
  Title: string;
  Description: string;
}

export interface LinkedInThumbnail {
  Width: number;
  Url: string;
  Height: number;
}

export interface LinkedInPoll {
  Question: string;
  Options: LinkedInPollOption[];
  ExpirationDate: Date;
}

export interface LinkedInPollOption {
  Voted: boolean;
  Text: string;
  VoteCount: number;
}

export interface LinkedInDocument {
  Title: string;
  Pages: LinkedInDocumentPage[];
  DownloadUrl: string;
}

export interface LinkedInDocumentPage {
  Url: string;
}
