import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PanelOverlay} from "@shared/overlay-panel/panel-overlay";
import {IProfile} from "@shared/channel/profile.interface";
import {ContentModel, EditorModel} from "@shared/publisher/content.interface";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@shared/overlay-panel/panel-overlay.tokens";
import {IComposerData, ISelectedProfile} from "../composer.component";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ProfileService} from "@shared/channel/profile.service";
import {Activity} from "@shared/activity/activity.model";
import {ActivitySource} from "@shared/activity/activity.interface";
import {ReactionService} from "@shared/activity/reactions/reaction.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-composer-share",
  templateUrl: "./composer-share.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComposerComponent extends PanelOverlay implements OnInit {
  profile: IProfile;
  postSource: ActivitySource;
  model: ContentModel = {};
  rawText = "";
  selectedProfiles: ISelectedProfile[] = [];
  loading = false;
  activity: Activity;

  editorModel: EditorModel = {
    MediaFiles: [],
  };

  constructor(
    dialogRef: PanelOverlayRef<ShareComposerComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IComposerData,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private reactionService: ReactionService,
  ) {
    super(dialogRef, data);
  }

  ngOnInit() {
    if (this.data) {
      if (!this.data.profile && this.data.profileId) {
        this.profileService
          .list()
          .pipe(untilDestroyed(this))
          .subscribe((profiles: IProfile[]) => {
            this.profile = profiles.filter((x) => x.Id === this.data.profileId)[0];
          });
      }

      if (this.data.profile) {
        this.selectedProfiles.push({
          profile: this.data.profile,
          selected: true,
        });
        this.profile = this.data.profile;
      }

      if (this.data.activity) {
        this.activity = this.data.activity as Activity;
        this.model = this.activity.toContentModel();

        this.postSource = this.activity.Source;

        this.editorModel = {
          ...this.editorModel,
          link: this.model.FacebookUrl || this.model.LinkedInArticleLink,
        };
      }
    }
  }

  onEditorChange(model: EditorModel) {
    if (model.rawText.length > 0) {
      this.rawText = model.rawText;
    } else {
      this.rawText = model.text;
    }
  }

  close(response?: any) {
    this.dialogRef.close(response);
  }

  share() {
    this.loading = true;
    this.reactionService
      .shareWithText(this.profile.ExternalId, this.activity.Id, this.rawText)
      .pipe(untilDestroyed(this))
      .subscribe((reaction) => {
        this.close({
          Successful: true,
          Reaction: reaction,
        });
      });
  }
}
