<!-- Loading -->
<div *ngIf="loading" class="absolute inset-0 flex items-center justify-center">
  <div class="h-1 top-0 left-0 right-0 absolute overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25">
    <div
      class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
  </div>
  <div>Loading Zapier...</div>
</div>
<!-- Zapier iFrame -->
<iframe *ngIf="embeddedZapierUrl" [src]="embeddedZapierUrl" id="zapierIframe"></iframe>
