<div class="time" #reference>
  <app-time-picker
    [(ngModel)]="model"
    (ngModelChange)="onTimeChange()"
    [meridian]="true"
    [spinners]="false"
    [minuteStep]="5"
    (timeClick)="onTimepickerClick($event)">
  </app-time-picker>
  <ng-content></ng-content>
</div>

<ng-template cdk-portal #hoursPanel="cdkPortal">
  <div class="clock-hours">
    <div class="clock-container">
      <span class="clock-dot"></span>
      <span class="hands hover-{{ hoveringHours?.text }}"></span>
      <ul (click)="setHours()">
        <li
          (mouseenter)="hoveringHours = hour"
          *ngFor="let hour of hoursList"
          id="hour-{{ hour.text }}"
          class="hour-{{ hour.text }}"
          data-value="{{ hour.value }}">
          {{ hour.text }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>
<ng-template cdk-portal #minutesPanel="cdkPortal">
  <div class="clock-minutes">
    <div class="clock-container">
      <span class="clock-dot"></span>
      <span class="hands hover-m-{{ hoveringMinutes }}"></span>
      <ul (click)="setMinutes()">
        <li
          (mouseenter)="hoveringMinutes = minute"
          *ngFor="let minute of minutesList"
          id="minute-{{ minute }}"
          class="minute-{{ minute }}"
          data-value="{{ minute }}">
          <span *ngIf="minute !== 0">{{ minute }}</span
          ><span *ngIf="minute === 0">00</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
