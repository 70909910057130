import {ContentModel, MediaFileUploaded} from "@shared/publisher/content.interface";
import {stripHtmlForQuillText} from "@shared/utils/strip-html.function";
import {ISelectedProfile} from "../composer.component";
import {ComposerValidation} from "./ComposerValidation";
import {InstagramMediaValidation} from "./media-validation/InstagramMediaValidation";
import XRegExp from "xregexp";

export class InstagramComposerValidation extends ComposerValidation {
  minimumDecimalAspectRatio = 0.8;
  maximumDecimalAspectRatio = 1.91;
  maximumVideoWidthDimension = 1920;
  maximumNumberOfCharactersOnPost = 2200;

  minimumDecimalVideoAspectRatio = 0.8;
  //too many decimals
  maximumDecimalVideoAspectRatio = 16 / 9;

  maximumNumberOfHashtags = 30;

  maximumNumberOfMedia = 10;

  CharLimitError: boolean;
  DevicesRequiredError: boolean;
  NoContent: boolean;
  NoPublishingTypeSelectedError: boolean;
  tooManyMediaForDirectPublishing: boolean;

  tooManyHashtagsError: boolean;

  invalidAspectRatio: boolean;
  invalidVideoAspectRatio: boolean;
  invalidPhotoSizeError: boolean;
  invalidVideoSizeError: boolean;
  ZapierEnabledError: boolean;

  invalidPhotoDimension: boolean;
  invalidVideoDimension: boolean;

  invalidPhotoExtension: boolean;
  invalidVideoExtension: boolean;

  invalidVideoDuration: boolean;

  constructor() {
    super();
    this.Reset();
  }

  IsValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    const media = contentModel.MediaFiles?.filter((file) => file.EnabledForInstagram);
    this.NoContent = !media || media.length === 0;

    const instagramText = stripHtmlForQuillText(contentModel.InstagramText);

    this.CharLimitError = instagramText.length > this.maximumNumberOfCharactersOnPost;

    const regexForHashtags = XRegExp("(#[a-zA-Z\\d-]+)", "gi");

    const result = XRegExp.match(instagramText, regexForHashtags) ?? [];

    console.log("[IsValid]", {result});

    this.tooManyHashtagsError = result.length > this.maximumNumberOfHashtags;

    media.forEach((x) => (x.instagramValidation = new InstagramMediaValidation()));

    const isValid = this.IsDirectPublishingValid(contentModel, selectedProfiles);

    return !(this.NoContent || this.CharLimitError || this.tooManyHashtagsError || !isValid);
  }

  LocalReset() {
    this.NoPublishingTypeSelectedError = false;
    this.DevicesRequiredError = false;
    this.NoContent = false;
    this.tooManyMediaForDirectPublishing = false;
    this.invalidAspectRatio = false;
    this.ZapierEnabledError = false;
    this.invalidPhotoSizeError = false;
    this.invalidVideoSizeError = false;
    this.invalidPhotoDimension = false;
    this.invalidVideoDimension = false;
    this.invalidPhotoExtension = false;
    this.invalidVideoExtension = false;
    this.invalidVideoDuration = false;
    this.CharLimitError = false;
    this.tooManyHashtagsError = false;
    this.invalidVideoAspectRatio = false;
  }

  IsDirectPublishingValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    let isValid = true;

    contentModel.MediaFiles.forEach((element) => {
      if (element.EnabledForInstagram) {
        const instagramValidation = new InstagramMediaValidation();

        if (element.MediaType.indexOf("image") > -1) {
          instagramValidation.AspectRatioError = !this.IsImageAspectRatioCorrect(element);
          instagramValidation.SizeError = element.SizeBytes > this.MbToBytes(8);
          instagramValidation.InvalidExtension = !this.IsImageExtensionCorrect(element);

          if (!this.invalidPhotoSizeError) {
            this.invalidPhotoSizeError = instagramValidation.SizeError;
          }

          if (!this.invalidPhotoExtension) {
            this.invalidPhotoExtension = instagramValidation.InvalidExtension;
          }
        }

        if (element.MediaType.indexOf("video") > -1) {
          instagramValidation.VideoAspectRatioError = !this.IsVideoAspectRatioCorrect(element);
          instagramValidation.VideoDimensionError = !this.IsVideoDimensionCorrect(element);
          instagramValidation.SizeError = element.SizeBytes > this.MbToBytes(100);
          instagramValidation.InvalidExtension = !this.IsVideoExtensionCorrect(element);
          instagramValidation.InvalidVideoDuration = !this.IsVideoDurationCorrect(element);

          if (!this.invalidVideoSizeError) {
            this.invalidVideoSizeError = instagramValidation.SizeError;
          }

          if (!this.invalidVideoExtension) {
            this.invalidVideoExtension = instagramValidation.InvalidExtension;
          }

          if (!this.invalidVideoDuration) {
            this.invalidVideoDuration = instagramValidation.InvalidVideoDuration;
          }
        }

        if (!this.invalidAspectRatio) {
          this.invalidAspectRatio = instagramValidation.AspectRatioError;
        }

        if (!this.invalidVideoAspectRatio) {
          this.invalidVideoAspectRatio = instagramValidation.VideoAspectRatioError;
        }

        if (!this.invalidVideoDimension) {
          this.invalidVideoDimension = instagramValidation.VideoDimensionError;
        }

        if (isValid) {
          isValid = instagramValidation.IsValid();
        }

        element.instagramValidation = instagramValidation;
      }
    });

    this.tooManyMediaForDirectPublishing =
      contentModel.MediaFiles.filter((x) => x.EnabledForInstagram).length > this.maximumNumberOfMedia;
    if (isValid) {
      isValid = !this.tooManyMediaForDirectPublishing;
    }
    return isValid;
  }
  IsVideoAspectRatioCorrect(media: MediaFileUploaded) {
    const aspectRatioDecimal = media.Width / media.Height;

    return (
      aspectRatioDecimal >= this.minimumDecimalVideoAspectRatio &&
      aspectRatioDecimal <= this.maximumDecimalVideoAspectRatio
    );
  }

  IsVideoDurationCorrect(element: MediaFileUploaded) {
    return element.videoDuration >= 3 && element.videoDuration <= 60;
  }

  private IsImageAspectRatioCorrect(media: MediaFileUploaded): boolean {
    const aspectRatioDecimal = media.Width / media.Height;

    return aspectRatioDecimal >= this.minimumDecimalAspectRatio && aspectRatioDecimal < this.maximumDecimalAspectRatio;
  }

  private IsVideoDimensionCorrect(media: MediaFileUploaded): boolean {
    if (media.Width < 1) return false;
    return media.Width <= this.maximumVideoWidthDimension;
  }

  IsImageExtensionCorrect(element: MediaFileUploaded) {
    if (!element.BlobUrl) return false;

    const extension = element.BlobUrl.split(".").pop();

    const validImageExtensions = ["bmp", "jpg", "png", "jpeg"];

    return validImageExtensions.some((x) => x == extension);
  }

  IsVideoExtensionCorrect(element: MediaFileUploaded) {
    const extension = element.BlobUrl.split(".").pop();

    const validImageExtensions = ["mp4", "mov"];

    return validImageExtensions.some((x) => x == extension);
  }
}
