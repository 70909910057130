<div class="group absolute inset-0 overflow-hidden touch-none z-[1000] outline-none flex flex-col">
  <!-- backdrop -->
  <div class="absolute inset-0 bg-black"></div>
  <!-- top bar -->
  <div class="w-full h-12 flex items-center justify-between bg-black/25 z-[1020]">
    <div class="flex w-full justify-between items-center space-x-2 px-2 py-0.5">
      <div class="ml-2">
        <div *ngIf="images.length > 1" class="text-base text-white/80">
          {{ selectedIndex + 1 }} of {{ images.length }}
        </div>
      </div>
      <div>
        <!-- show caption -->
        <app-icon-button
          *ngIf="!showCaption"
          iconSize="24"
          icon="text-caption"
          matTooltip="Show Caption"
          matTooltipPosition="below"
          type="inline"
          class="text-white/80"
          (buttonClick)="toggleCaption()"></app-icon-button>
        <!-- hide caption -->
        <app-icon-button
          *ngIf="showCaption"
          iconSize="24"
          icon="text-caption"
          matTooltip="Hide Caption"
          matTooltipPosition="below"
          type="inline"
          class="text-white/80"
          (buttonClick)="toggleCaption()"></app-icon-button>
        <!-- close button -->
        <app-icon-button
          iconSize="24"
          icon="x"
          type="inline"
          matTooltip="Close"
          matTooltipPosition="below"
          class="text-white/80"
          (buttonClick)="close()"></app-icon-button>
      </div>
    </div>
  </div>
  <!-- media -->
  <div
    class="flex items-center justify-center relative overflow-visible mx-4"
    [style.height]="'calc(100% - 4rem - 4rem)'">
    <app-icon-button
      iconSize="24"
      icon="arrow-left"
      type="inline"
      *ngIf="this.selectedIndex > 0"
      class="absolute top-1/2 left-0 text-white/80 bg-black/25 rounded-full z-[1020]"
      (buttonClick)="prevImage()"></app-icon-button>
    <img
      [src]="currentImage.src"
      [alt]="currentImage.title"
      class="max-w-full object-contain transform transition-transform duration-300 ease-in-out max-h-[calc(100%-4rem-5rem)]"
      [style.transform]="'scale(' + zoomLevel + ')'"
      [ngClass]="zoomLevel === 1 ? 'cursor-zoom-in' : 'cursor-zoom-out'"
      (click)="toggleZoom($event)" />
    <app-icon-button
      iconSize="24"
      icon="arrow-right"
      type="inline"
      *ngIf="this.selectedIndex < this.images.length - 1"
      class="absolute top-1/2 right-0 text-white/80 bg-black/25 rounded-full z-[1020]"
      (buttonClick)="nextImage()"></app-icon-button>
  </div>
  <!-- caption -->
  <div
    *ngIf="currentImage.title && showCaption"
    class="absolute bottom-0 w-full flex justify-center items-center text-white/80 bg-black/25 z-[1020]">
    <div class="text-left max-w-sm p-4 mx-auto">{{ currentImage.title }}</div>
  </div>
</div>
