<div class="nav">
  <div class="upper">
    <app-icon-button
      type="button"
      class="compose"
      iconSize="20"
      icon="compose"
      (click)="openComposer()"
      matTooltip="Composer"
      matTooltipPosition="after"
      data-test="composer-opencomposer-button">
      <app-icon class="icon" icon="compose"></app-icon>
    </app-icon-button>
    <span
      class="item"
      routerLink="/streams"
      routerLinkActive="active"
      matRipple
      [hidden]="!hasGroupRole('ViewStream')"
      matTooltip="Streams"
      matTooltipPosition="after">
      <app-icon class="icon" icon="streams"></app-icon>
    </span>
    <span
      class="item"
      routerLink="/publisher"
      routerLinkActive="active"
      matRipple
      matTooltip="Publisher"
      matTooltipPosition="after">
      <app-icon class="icon publisher" icon="publisher"></app-icon>
    </span>
    <span
      class="item"
      routerLink="/analytics"
      routerLinkActive="active"
      matRipple
      matTooltip="Analytics"
      matTooltipPosition="after"
      data-test="composer-openanalytics-button">
      <app-icon class="icon" icon="analytics"></app-icon>
    </span>
  </div>
  <div class="lower">
    <span
      data-test="nav-add-channel"
      class="item"
      (click)="addChannel$.next(null)"
      matRipple
      [hidden]="!hasGroupRole('AddChannel')"
      matTooltip="Connect Channel"
      matTooltipPosition="after">
      <app-icon class="icon" icon="channels"></app-icon>
    </span>
    <span
      data-test="nav-notifications"
      class="item"
      (click)="openNotifications()"
      matRipple
      matTooltip="Notifications"
      matTooltipPosition="after">
      <app-icon class="icon" icon="notifications"></app-icon>
      <!-- New Notifications Indicator -->
      <span class="new" *ngIf="notificationsCount">{{ notificationsCount }}</span>
    </span>
    <span
      data-test="nav-toggle-theme"
      class="item"
      matRipple
      (click)="toggleTheme()"
      [matTooltip]="isDark ? 'Light Mode' : 'Dark Mode'"
      matTooltipPosition="after">
      <app-icon class="icon" *ngIf="isDark" icon="light"></app-icon>
      <app-icon class="icon" *ngIf="!isDark" icon="dark"></app-icon>
    </span>
    <span
      data-test="navcomponent-settings-span"
      class="item"
      matRipple
      routerLink="/settings"
      routerLinkActive="active"
      matTooltip="Settings"
      matTooltipPosition="after">
      <app-icon class="icon" icon="settings"></app-icon>
    </span>
    <span data-test="navcomponent-user-thumbnail-span" class="thumbnail">
      <button
        type="button"
        data-test="navcomponent-user-thumbnail-button"
        class="thumbnail-button"
        [matMenuTriggerFor]="userMenu"
        matRipple>
        <app-user-avatar *ngIf="user" [user]="user" size="medium"></app-user-avatar>
      </button>
      <mat-menu #userMenu="matMenu" overlapTrigger="false" class="user-menu">
        <a mat-menu-item routerLink="/user" data-test="userMenu-account-menuItem">
          <app-icon class="h-6 w-6 leading-zero text-none mr-3" icon="user"></app-icon>
          <span>Profile</span></a
        >
        <mat-divider></mat-divider>
        <a
          mat-menu-item
          href="https://www.socialweaver.com/help/"
          data-test="userMenu-help-center-menuItem"
          target="_blank">
          <app-icon class="h-6 w-6 leading-zero text-none mr-3" icon="help"></app-icon>
          <span>Help Center</span></a
        >
        <!--        <a mat-menu-item (click)="liveChat()" data-test="userMenu-live-chat-menuItem">-->
        <!--          <app-icon class="h-6 w-6 leading-zero text-none mr-3" icon="lifebuoy"></app-icon>-->
        <!--          <span>Contact Support</span></a>-->
        <mat-divider></mat-divider>
        <a
          mat-menu-item
          data-test="userMenu-get-privacy-menuItem"
          href="https://www.socialweaver.com/privacy"
          target="_blank"
          >Privacy Policy</a
        >
        <a mat-menu-item href="https://www.socialweaver.com/terms" data-test="userMenu-terms-menuItem" target="_blank"
          >Terms of Use</a
        >
        <mat-divider *ngIf="isAdmin"></mat-divider>
        <a *ngIf="isAdmin" mat-menu-item routerLink="/admin" data-test="userMenu-admin-menuItem">
          <app-icon class="h-6 w-6 leading-zero text-none mr-3" icon="radioactive"></app-icon>
          <span>Admin</span>
        </a>
        <mat-divider></mat-divider>
        <a mat-menu-item (click)="logout()" data-test="userMenu-sign-out-menuItem">
          <app-icon class="h-6 w-6 leading-zero text-none mr-3" icon="logout"></app-icon>
          <span>Sign Out</span>
        </a>
      </mat-menu>
    </span>
  </div>
</div>
