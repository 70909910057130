import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-dismissible-snackbar",
  template: `
    {{ Message }}
    <app-loader *ngIf="enableLoading"></app-loader>
    <button mat-button color="primary" (click)="dismiss()" data-test="shared-dismiss-button">Dismiss</button>
  `,
})
export class DismissibleSnackbarComponent {
  Message = "";
  enableLoading = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {
    this.Message = data.Message;
    this.enableLoading = data.enableLoading;
  }

  dismiss() {
    this.data.closeSnackbar();
  }
}
