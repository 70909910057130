export enum IntegrationTypeEnum {
  Zapier,
  Bitly,
  Slack,
  Teams,
}

export interface IntegrationDataModel {
  UserReference: string;
  GroupId: string;
  Domain: string;
  ImageUrl: string;
  DisplayName: string;
}

export interface SlackIntegrationModel {
  WorkspaceName: string;
  WorkspaceImage: string;
  Domain: string;
}

export interface TeamsIntegrationModel {
  Domain: string;
}

export interface IntegrationAuthCompletedModel {
  SWIntegration: IntegrationTypeEnum;
  Authenticated: boolean;
}

export interface IBitlyGroup {
  name: string;
  bsds: string[];
  guid: string;
}

export interface IBitlyOrganization {
  name: string;
  bsds: string[];
  guid: string;
}

export interface DefaultGroupDomain {
  GroupId?: string;
  Domain: string;
}
