import {EventEmitter, Injectable} from "@angular/core";
import {ICalendarSettings} from "./calendar-settings.interface";
import {StateService} from "@user/state.service";
import {Observable, Subscriber} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CalendarSettingsService {
  private change$: EventEmitter<ICalendarSettings> = new EventEmitter();

  change: Observable<ICalendarSettings> = this.change$.asObservable();

  private cache: ICalendarSettings;

  public calendarTodayEvent = new EventEmitter<any>();

  public calendarPreviousEvent = new EventEmitter<any>();

  public calendarNextEvent = new EventEmitter<any>();

  public calendarOpenScheduleDialogEvent = new EventEmitter<any>();

  public calendarOpenSettingsDialogEvent = new EventEmitter<any>();

  public timezoneChangedEvent = new EventEmitter<any>();

  public calendarMomentDate = new Observable<any>((observable) => {
    this.calendarMomentDateSubscriber = observable;
  });

  public calendarMomentDateSubscriber = new Subscriber<any>();

  public DATE_FORMAT = "MM/DD/YYYY";

  private defaultSettings: ICalendarSettings = {
    startOfWeek: 0,
    workDays: false,
    startOfWorkDay: "9am",
    endOfWorkDay: "5pm",
    defaultProvider: "Facebook",
    view: "compact",
    showSchedule: true,
    showEmptySlots: true,
    showChannels: true,
    showTags: true,
  };
  private key = "calendarSettings";

  constructor(private stateService: StateService) {}

  get(): ICalendarSettings {
    if (this.cache) {
      return this.cache;
    }
    return this.stateService.get(this.key) || this.defaultSettings;
  }

  set(settings: ICalendarSettings) {
    this.cache = settings;
    this.stateService.set(this.key, settings);
    this.change$.emit(settings);
  }

  setCalendarDateToday() {
    this.calendarTodayEvent.emit();
  }

  setCalendarDateNext() {
    this.calendarNextEvent.emit();
  }

  setCalendarDatePrevious() {
    this.calendarPreviousEvent.emit();
  }

  openScheduleDialog() {
    this.calendarOpenScheduleDialogEvent.emit();
  }

  openSettingsDialog() {
    this.calendarOpenSettingsDialogEvent.emit();
  }
}
