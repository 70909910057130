import {ContentModel} from "@shared/publisher/content.interface";
import {ISelectedProfile} from "../composer.component";

export abstract class ComposerValidation {
  public hasErrors: boolean;

  abstract IsValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean;

  abstract LocalReset();

  public Reset() {
    this.hasErrors = false;
    this.LocalReset();
  }

  Validate(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    this.Reset();
    const isValid = this.IsValid(contentModel, selectedProfiles);
    this.hasErrors = !isValid;
    return isValid;
  }

  MbToBytes(mb: number): number {
    return mb * 1000000;
  }
}
