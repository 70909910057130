<div class="h-full bg-light-base-100 dark:bg-dark-base-0 shadow-overlay-panel" data-test="preview-dialog-container-div">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <!-- Reschedule -->
      <app-button-stroked
        (buttonClick)="rescheduleActivity()"
        *ngIf="rescheduleEnabled"
        icon="calendar"
        type="inline"
        class="mr-2.5 ml-2"
        minWidth="72">
        Reschedule
      </app-button-stroked>
      <!-- Edit -->
      <app-button-stroked
        (buttonClick)="editActivity()"
        [disabled]="!editingEnabled || isAPublishedEvent"
        *ngIf="!rescheduleEnabled && !isAPublishedEvent"
        icon="compose"
        type="inline"
        class="mr-2.5 ml-2"
        minWidth="72"
        [matTooltip]="!editingEnabled ? 'Can\'t Edit Published Content' : ''"
        >Edit
      </app-button-stroked>
      <!-- Publish -->
      <app-button-stroked
        (buttonClick)="publishActivity()"
        [disabled]="!publishEnabled || isAPublishedEvent"
        *ngIf="!rescheduleEnabled && !isAPublishedEvent"
        type="inline"
        icon="publisher"
        class="mr-2.5"
        minWidth="98"
        >Publish
      </app-button-stroked>
      <!-- Delete -->
      <app-button-stroked (buttonClick)="deleteActivity()" [loading]="deleting" type="inline" icon="trash" minWidth="92"
        >Delete
      </app-button-stroked>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button icon="x" iconSize="20" (buttonClick)="close()" class="-mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Content Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-44px)] min-w-[500px]">
    <!-- Scrollbar -->
    <ng-scrollbar class="app-scrollbar flex-1">
      <!-- Content Type Tabs -->
      <mat-tab-group
        disablePagination="true"
        animationDuration="0ms"
        mat-stretch-tabs="false"
        disableRipple
        class="preview-dialog-tabs"
        [class.flat-corner]="activeTab === 0 && content.Providers.length > 1"
        [(selectedIndex)]="activeTab"
        [class.hide-tab-header]="content.Providers?.length <= 1">
        <mat-tab *ngFor="let provider of content.Providers">
          <ng-template mat-tab-label>
            <app-icon
              *ngIf="providerHasErrors(provider)"
              icon="exclamation-circle-solid"
              class="w-4 h-4 leading-4 mr-1.25 text-red dark:text-red-300"></app-icon>
            {{ getProviderLabel(provider) }}
          </ng-template>
          <div class="p-3">
            <!-- Content Preview -->
            <app-publisher-activity
              [content]="content"
              [provider]="provider"
              [openDetail]="false"
              [clickable]="true"
              [showTime]="false"
              [showStatus]="false"
              [showChannels]="true"
              [showBadge]="true"
              [showFooter]="true"
              [showTags]="true"
              [showDescription]="false"
              [showDomain]="true"
              [pictureSize]="40"
              [showCarouselControls]="true"
              [showVolumeControl]="true"
              tagSize="medium"
              [class.no-tags]="!content.Tags?.length">
            </app-publisher-activity>
            <!-- Content Errors -->
            <ng-container *ngFor="let errorEvent of errors[provider]">
              <div class="inline-flex items-start mx-1 mt-3">
                <div class="mr-1.25" style="margin-top: 2px">
                  <app-icon
                    icon="exclamation-circle-solid"
                    class="inline-flex w-3.5 h-3.5 text-red dark:text-red-300"></app-icon>
                </div>
                <div class="text-sm text-red dark:text-red-300" style="word-break: break-word">
                  <span class="font-medium">{{ errorEvent.profile.Name }}: </span>{{ errorEvent.error }}
                </div>
              </div>
            </ng-container>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div
        *ngIf="getErrorMessages()['Unknown']"
        class="text-sm text-red dark:text-red-300"
        style="word-break: break-word">
        {{ getErrorMessages()["Unknown"] }}
      </div>
    </ng-scrollbar>
  </div>
</div>
