import {IProfile} from "@channel/profile.interface";
import {PublishEvent, Content, ContentModel} from "@publisher/content.interface";

export interface NotificationsList {
  Result: {
    Items: NotificationGroup[];
    Total: number;
  };
  NotificationPending: boolean;
}

export interface NotificationGroup {
  Id: string;
  NotificationDateTime: string;
  NotificationGroupId: string;
  Pending: boolean;
  UserId: string;
  Notifications: Notification[];
}

export interface PublishedContent extends PublishEvent {
  PublishedAt: string;
  ProfileId: string;
  Profile: IProfile;
  Type: string;
  MessageId: string;
  ExternalIds: string[];
  Skipped: boolean;
  AccountId: string;
  OccurrenceId: string;
  ErrorStackTrace: any;
  ErrorMessage: any;
  PublishedEventCategory: any;
  PublishAt?: string;
}

export interface GroupedPublishedContent extends Content {
  failedToPublish: boolean;
  PublishEvents: PublishedContent[];
}

export interface LibraryMessageFilterModel {
  TextSearch?: string;

  TagsIds?: string[];

  ProfileIds?: string[];

  ProfileTypes?: string[];
}

export interface Notification {
  NotificationDateTime: string;
  NotificationType: number;
  PublishedEvents: PublishedContent[];
  Text: string;
  Title: string;
  Url: string;
  Profile?: IProfile;
  SystemError?: SystemError;
  Message?: ContentModel;
}

export interface NotificationStatusResponse {
  NotificationPendingCount: number;
  UserNotificationPending: boolean;
}

export enum SystemError {
  AuthenticationError,
}
