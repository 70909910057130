import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional} from "@angular/core";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {environment} from "@environments/environment";
import {UntilDestroy} from "@ngneat/until-destroy";
import {DefaultGroupDomain} from "src/app/pages/settings/integrations/integrations.interface";
import {Link} from "@shared/publisher/content.interface";
import {FromContentLink, getTrackingUrl} from "@utils/link.function";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UtmService} from "@shared/utils/utm-service/utm.service";
import {AutoCompleteInfo} from "@shared/utils/autocompleteinput/autocompleteinput.component";
import {ComposerService} from "@composer/composer.service";
import {ProfileLabelsFromId} from "@channel/profile-types.enum";

export interface IAddLinkData {
  link?: Link;
}

@UntilDestroy()
@Component({
  selector: "app-add-link",
  templateUrl: "./link-tracking.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkTrackingComponent extends PanelOverlay implements OnInit {
  link: Link = new Link("");
  title = "Track Link";
  isProd = environment.production;
  loading = false;
  isLinkValid: boolean;
  submitted: boolean;
  form: FormGroup;

  constructor(
    dialogRef: PanelOverlayRef<LinkTrackingComponent>,
    private cdr: ChangeDetectorRef,
    formBuilder: FormBuilder,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IAddLinkData,
    public utmPresetService: UtmService,
    private composerService: ComposerService,
  ) {
    super(dialogRef, data);

    if (data && data.link) {
      this.title = "Update Tracking";

      this.link = FromContentLink(data.link);

      if (this.link.IsTracking) {
        this.link.lastTrackingUrlState = this.link.TrackingUrl;
      }

      this.form = formBuilder.group({
        utmSource: this.getAutoCompleteInfo(this.utmPresetService.options.Source, data.link.UtmSource),
        utmMedium: this.getAutoCompleteInfo(this.utmPresetService.options.Medium, data.link.UtmMedium),
        utmCampaign: this.getAutoCompleteInfo(this.utmPresetService.options.Campaign, data.link.UtmCampaign),
        utmTerm: this.getAutoCompleteInfo(this.utmPresetService.options.Term, data.link.UtmTerm),
        utmContent: this.getAutoCompleteInfo(this.utmPresetService.options.Content, data.link.UtmContent),
      });
    } else {
      this.form = formBuilder.group({
        name: "",
        utmSource: {},
        utmMedium: {},
        utmCampaign: {},
        utmTerm: {},
        utmContent: {},
      });
    }
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((value) => {
      this.link.UtmMedium = this.resolveDynParameter(value.utmMedium?.Value);
      this.link.UtmCampaign = this.resolveDynParameter(value.utmCampaign?.Value);
      this.link.UtmTerm = this.resolveDynParameter(value.utmTerm?.Value);
      this.link.UtmContent = this.resolveDynParameter(value.utmContent?.Value);
      this.link.UtmSource = this.resolveDynParameter(value.utmSource?.Value);
    });
  }

  private resolveDynParameter(parameterValue: string): string {
    if (parameterValue === "{channelType}" && this.composerService.selectedProfiles.length > 0) {
      return ProfileLabelsFromId[this.composerService.selectedProfiles[0].profile.Type];
    }
    return parameterValue;
  }

  public getTrackingUrl() {
    return getTrackingUrl(this.link);
  }

  public saveTracking() {
    this.loading = true;
    this.submitted = true;

    const fullUrl = getTrackingUrl(this.link);

    this.link.IsTracking = fullUrl != this.link.BaseUrl;

    this.link.TrackingUrl = fullUrl;

    this.dialogRef.close(this.link);
  }

  close() {
    this.dialogRef.close();
  }

  compareObjects(o1: DefaultGroupDomain, o2: DefaultGroupDomain): boolean {
    if (o1.GroupId == null || o2.GroupId == null) return o1.Domain == o2.Domain;
    else return o1?.Domain == o2?.Domain && o1?.GroupId == o2?.GroupId;
  }

  getAutoCompleteInfo(options: AutoCompleteInfo[], value: string): AutoCompleteInfo {
    if (value == null || value == undefined)
      return {
        Custom: true,
        DisplayName: "",
        Value: "",
      };

    const isCustom = !value.startsWith("{");

    if (isCustom) {
      return {
        Custom: true,
        Value: value,
        DisplayName: value,
      };
    }

    const optionSelectedName = options.find((x) => x.Value == value)?.DisplayName;
    return {
      Custom: false,
      Value: value,
      DisplayName: optionSelectedName,
    };
  }
}
