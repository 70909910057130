<div class="reactions-wrapper" *ngIf="activity.FacebookPost.ReactionCount">
  <app-facebook-reactions-list [post]="activity.FacebookPost"></app-facebook-reactions-list>
  <span class="counter">
    {{ activity.FacebookPost.ReactionCount | shortNumber }}
    <span>{{ activity.FacebookPost.ReactionCount === 1 ? "Reaction" : "Reactions" }}</span>
  </span>
</div>

<div class="counters">
  <span class="counter" *ngIf="activity.CommentsCount">
    {{ activity.CommentsCount | shortNumber }}
    <span>{{ activity.CommentsCount === 1 ? "Comment" : "Comments" }}</span>
  </span>
  <span class="counter" *ngIf="activity.FacebookPost.SharesCount">
    {{ activity.FacebookPost.SharesCount | shortNumber }}
    <span>{{ activity.FacebookPost.SharesCount === 1 ? "Share" : "Shares" }}</span>
  </span>
</div>
