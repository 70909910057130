import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EventEmitter, Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {IUsage} from "@shared/subscription/subscription.interface";
import {guid} from "@shared/utils/guid.function";
import {TimezoneService} from "@shared/utils/timezone/timezone.service";
import {TimezoneInfo} from "@utils/timezone/timezone.interface";
import moment from "moment";
import {BehaviorSubject, Observable, of, pipe} from "rxjs";
import {finalize, map, share, switchMap, tap} from "rxjs/operators";
import {AnalyticsWidgets, UserWidget, defaultNoGroupSettingForWidget} from "src/app/pages/analytics/widget.interface";
import {NotificationSettings, PrivacySettings, Theme, User, UserSettings} from "./user.interface";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private baseUrl = environment.api + "/UserData";
  private baseUrlSettings = environment.api + "/UserSettings";
  private meObservable: Observable<User>;
  public user: User;

  public userObservable: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public widgetAdded = new EventEmitter<UserWidget>();

  public widgetEdited = new EventEmitter<UserWidget>();

  public widgetRemoved = new EventEmitter<UserWidget>();

  protected userChanged = pipe(
    tap((user: User) => {
      this.userObservable.next(user);
    }),
  );

  constructor(
    private http: HttpClient,
    private timezoneService: TimezoneService,
  ) {}

  me(cache = true): Observable<User> {
    let userObservable: Observable<User>;

    if (this.user && cache) {
      userObservable = of(this.user);
    } else if (this.meObservable && cache) {
      userObservable = this.meObservable;
    } else {
      this.meObservable = this.http.get<User>(this.baseUrl).pipe(
        tap((user: User) => {
          this.user = user;
          this.userObservable.next(user);
        }),
        share(),
        finalize(() => {
          this.meObservable = null;
        }),
      );
      userObservable = this.meObservable;
    }
    return userObservable;
  }

  getSettings(): Observable<UserSettings> {
    return this.http.get<UserSettings>(this.baseUrlSettings + "/Settings").pipe(
      switchMap((settings) => {
        const observable = this.timezoneService.getTimezoneByIanaId(settings.TimezoneInfo.IanaId);
        return observable.pipe(
          map((timezone) => {
            settings.TimezoneInfo = timezone;
            return settings;
          }),
        );
      }),
    );
  }

  updateContacts(contacts: UserSettings): Observable<void> {
    return this.http.put<void>(this.baseUrlSettings + "/Contacts", contacts);
  }

  updateMetadataBeforePublishingSettings(settings: UserSettings): Observable<void> {
    return this.http.put<void>(this.baseUrlSettings + "/IsMetadataBeforePublishingEnabled", settings);
  }

  updateContentRandomizationSettings(settings: UserSettings): Observable<void> {
    return this.http.put<void>(this.baseUrlSettings + "/IsContentRandomizationEnabled", settings);
  }

  updateOrganizationContacts(contacts: UserSettings): Observable<void> {
    return this.http.put<void>(this.baseUrlSettings + "/OrganizationContacts", contacts);
  }

  updateEmail(settings: UserSettings): Observable<User> {
    return this.http
      .put<User>(this.baseUrlSettings + "/Email", settings)
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  updateEmailSubscriptions(subscriptions: any) {
    return this.http
      .put<User>(this.baseUrlSettings + "/EmailSubscriptions", subscriptions)
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  updateImage(image: string): Observable<User> {
    return this.http
      .put<User>(this.baseUrlSettings + "/Image", {Image: image})
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  updateTimezone(timezone: TimezoneInfo): Observable<User> {
    return this.http
      .put<User>(this.baseUrlSettings + "/TimeZones", timezone)
      .pipe(
        tap((user: User) => {
          this.user = user;
          //Need to update moment before the subscriptions get the event
          moment.tz.setDefault(user.TimezoneId);
        }),
      )
      .pipe(this.userChanged);
  }

  updatePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.http.put<void>(environment.api + "/Password", {
      Current: currentPassword,
      New: newPassword,
    });
  }

  updateTheme(theme: Theme): Observable<User> {
    return this.http
      .post<User>(this.baseUrlSettings + "/SetTheme", theme)
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  deleteCurrentUser(currentPassword: string): Observable<void> {
    return this.http.put<void>(this.baseUrlSettings + "/Delete", {
      CurrentPassword: currentPassword,
    });
  }

  getNotificationSettings(): Observable<NotificationSettings> {
    return this.http.get<NotificationSettings>(this.baseUrlSettings + "/NotificationSettings");
  }

  updateNotificationSettings(settings: NotificationSettings): Observable<any> {
    return this.http.post<any>(this.baseUrlSettings + "/NotificationSettings", settings);
  }

  getPlanUsage(): Observable<IUsage> {
    return this.http.get<IUsage>(this.baseUrl + "/GetUsage");
  }

  clearCache() {
    this.user = null;
  }

  setDemoAdmin(emailAddress: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    const options = {headers: headers};

    return this.http.post(this.baseUrlSettings + "/SetDemoAdmin", JSON.stringify(emailAddress), options);
  }

  getCurrentDemoAdmin(): Observable<string> {
    return this.http.get<string>(this.baseUrlSettings + "/GetDemoAdminEmail");
  }

  updatePrivacySettings(privacySettings: any): Observable<PrivacySettings> {
    return this.http
      .put<PrivacySettings>(this.baseUrlSettings + "/PrivacySettings", privacySettings)
      .pipe(
        tap((privacy) => {
          this.user.RecordSession = privacy.RecordSession;
        }),
        map(() => this.user),
      )
      .pipe(this.userChanged);
  }

  resumeScheduledPublishing(): Observable<User> {
    return this.http
      .post<User>(this.baseUrl + "/ResumeScheduledPublishing", null)
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  pauseScheduledPublishing(): Observable<User> {
    return this.http
      .post<User>(this.baseUrl + "/PauseScheduledPublishing", null)
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }

  addAnalyticsWidget(widget: UserWidget) {
    let widgetsState = this.getUserUserWidgetsState(this.user);

    widget.order = widgetsState.length - 1;

    widgetsState = [...widgetsState, widget];

    return this.updateAnalyticsWidgetState(widgetsState).pipe(
      tap(() => {
        this.widgetAdded.emit(widget);
      }),
    );
  }

  editAnalyticWidget(editingWidget: UserWidget) {
    let widgetsState = JSON.parse(this.user.AnalyticsWidgetsState) as UserWidget[];

    if (widgetsState == null) {
      const userWidgets: UserWidget[] = AnalyticsWidgets.map((widgets, index) => {
        return {
          displayName: widgets.name,
          typeIdentifier: widgets.typeIdentifier,
          order: index,
          id: guid(),
          showNoGroup: defaultNoGroupSettingForWidget,
        };
      });

      const widgetIndex = userWidgets.findIndex((x) => x.typeIdentifier == editingWidget.typeIdentifier);

      userWidgets[widgetIndex] = editingWidget;

      widgetsState = userWidgets;
    } else {
      const index = widgetsState.findIndex((x) => x.id == editingWidget.id);

      widgetsState[index] = editingWidget;
    }

    return this.updateAnalyticsWidgetState(widgetsState).pipe(
      tap(() => {
        this.widgetEdited.emit(editingWidget);
      }),
    );
  }

  removeAnalyticWidget(widget: UserWidget) {
    let widgetsState = JSON.parse(this.user.AnalyticsWidgetsState) as UserWidget[];

    if (widgetsState == null)
      widgetsState = AnalyticsWidgets.filter((x) => x.typeIdentifier != widget.typeIdentifier).map((widgets, index) => {
        return {
          displayName: widgets.name,
          typeIdentifier: widgets.typeIdentifier,
          order: index,
          id: guid(),
          showNoGroup: defaultNoGroupSettingForWidget,
        };
      });
    else {
      widgetsState = widgetsState.filter((x) => x.id != widget.id);
    }

    return this.updateAnalyticsWidgetState(widgetsState).pipe(
      tap(() => {
        this.widgetRemoved.emit(widget);
      }),
    );
  }

  private getUserUserWidgetsState(user: User) {
    const widgetsState = JSON.parse(user.AnalyticsWidgetsState) as UserWidget[];

    if (widgetsState == null)
      return AnalyticsWidgets.map((widgets, index) => {
        return {
          displayName: widgets.name,
          typeIdentifier: widgets.typeIdentifier,
          order: index,
          id: guid(),
          showNoGroup: defaultNoGroupSettingForWidget,
        };
      });

    return widgetsState;
  }

  updateAnalyticsWidgetState(widgetState: UserWidget[]) {
    return this.http
      .put(this.baseUrl + "/analyticsWidgetState", {State: JSON.stringify(widgetState)})
      .pipe(
        tap((user: User) => {
          this.user = user;
        }),
      )
      .pipe(this.userChanged);
  }
}
