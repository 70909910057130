import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {ChannelLimitComponent} from "./upgrade-dialogs/channel-limit/channel-limit.component";

@Injectable({
  providedIn: "root",
})
export class UpgradeSubscriptionService {
  constructor(private dialog: MatDialog) {}

  /// If you want to use a full component without a base on the dialog use this.
  showUpgradeSubscriptionDialog(): Observable<any> {
    const dialogRef = this.dialog.open(ChannelLimitComponent, {
      width: "400px",
      panelClass: ["app-dialog", "single-action", "channel-limit"],
    });

    return dialogRef.afterClosed();
  }
}
