import {EventEmitter, Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Activity} from "@shared/activity/activity.model";
import {getVideoThumbFromUrl} from "@shared/utils/getVideoThumbFromUrl";
import {combineLatest} from "rxjs";
import {INTERCEPTOR_SKIP_AUTH} from "@shared/auth/auth.interceptor";
import {filter, map, switchMap} from "rxjs/operators";
import {ActivityService} from "@shared/activity/activity.service";
import {PanelOverlayService} from "@shared/overlay-panel/panel-overlay.service";
import {ShareLinkedInOverlayData, ShareToEmployeeComponent} from "./share-to-employee.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ReactionService} from "@shared/activity/reactions/reaction.service";
import {PublishSchedule} from "@shared/publisher/publish-schedule.interface";
import {Content} from "@shared/publisher/content.interface";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PreviewDialogComponent} from "@shared/preview-dialog/preview-dialog.component";
import {MediaUploadModel} from "@user/user.interface";

@Injectable({
  providedIn: "root",
})
export class ShareToEmployeeControllerService {
  updatingActivity = false;
  onActivityUpdated = new EventEmitter<void>();

  constructor(
    private httpClient: HttpClient,
    private activityService: ActivityService,
    private sharePanelOverlay: PanelOverlayService<ShareToEmployeeComponent>,
    private snackbar: MatSnackBar,
    private reactionService: ReactionService,
  ) {}

  editScheduledShare(content: Content, parentDialogRef: PanelOverlayRef<PreviewDialogComponent> = null) {
    return this.sharePanelOverlay
      .open(ShareToEmployeeComponent, {
        position: "left",
        disableClose: false,
        parentOverlayRef: parentDialogRef,
        data: {
          sharing: content.ShareOnBehalfOfEmployees,
          like: content.LikeOnBehalfOfEmployees,
          shareText: content.LinkedInShareCommentary,
          addText: false,
          shouldShowShareButton: false,
          scheduleEnabled: true,
          scheduleModel: content.ScheduleModel,
          includedEmployees: content.IncludedEmployees,
          employeeGroupNames: content.EmployeeGroupNames,
          editingSchedule: true,
        },
      })
      .afterClosed.pipe(
        filter((shareModel) => shareModel != null),
        map((shareModel: ShareLinkedInOverlayData) => {
          const startDateString = `${
            shareModel.schedule.startAt.getMonth() + 1
          }/${shareModel.schedule.startAt.getDate()}/${shareModel.schedule.startAt.getFullYear()}`;

          const schedule: PublishSchedule = {
            Name: "OneTime",
            FrequencyType: "OneTime",
            Times: [shareModel.schedule.time],
            StartAt: startDateString,
            TimezoneId: shareModel.schedule.timezoneId["IanaId"],
          };

          shareModel.scheduleModel = schedule;

          return shareModel;
        }),
        switchMap((shareModel) =>
          this.reactionService
            .editScheduleShareWithEmployees(content.Id, shareModel, shareModel.scheduleModel)
            .pipe(map(() => shareModel)),
        ),
      );
  }

  shareLinkedInPost(activity: Activity) {
    this.updatingActivity = true;
    this.sharePanelOverlay
      .open(ShareToEmployeeComponent, {
        position: "left",
        disableClose: false,
        data: {
          sharing: false,
          like: false,
          shareText: "",
          addText: false,
          shouldShowShareButton: true,
          scheduleEnabled: true,
        },
      })
      .afterClosed.subscribe((shareModel: ShareLinkedInOverlayData) => {
        if (!shareModel) {
          return;
        }

        if (!shareModel.scheduleEnabled) {
          this.reactionService.shareWithEmployees(activity.Id, shareModel).subscribe(
            () => {
              this.snackbar.open("Shared successfully");
            },
            () => {
              this.snackbar.open("Failed to share post with employees");
            },
          );
        } else {
          const startDateString = `${
            shareModel.schedule.startAt.getMonth() + 1
          }/${shareModel.schedule.startAt.getDate()}/${shareModel.schedule.startAt.getFullYear()}`;

          const schedule: PublishSchedule = {
            Name: "OneTime",
            FrequencyType: "OneTime",
            Times: [shareModel.schedule.time],
            StartAt: startDateString,
            TimezoneId: shareModel.schedule.timezoneId["IanaId"],
          };

          this.reactionService.scheduleShareWithEmployees(activity.Id, shareModel, schedule).subscribe(
            () => {
              this.snackbar.open("Scheduled successfully");
            },
            () => {
              this.snackbar.open("Failed to schedule post with employees");
            },
          );
        }
      });

    this.activityService.updateActivity(activity.Id).subscribe((updatedActivity: Activity) => {
      const activityToEvaluate =
        updatedActivity.LinkedInPost.Repost != null ? updatedActivity.LinkedInPost.Repost : updatedActivity;

      if (activityToEvaluate.LinkedInPost.Media != null) {
        if (activityToEvaluate.LinkedInPost.ShareMediaCategory == "VIDEO") {
          if (
            activityToEvaluate.LinkedInPost.Media.length > 0 &&
            (activityToEvaluate.LinkedInPost.Media[0].Thumbnails == null ||
              activityToEvaluate.LinkedInPost.Media[0].Thumbnails.Url == null)
          ) {
            // Need to upload video
            console.log("original url", activityToEvaluate.LinkedInPost);
            const thumb$ = getVideoThumbFromUrl(activityToEvaluate.LinkedInPost.Media[0].OriginalUrl);
            const uploadUrl$ = this.httpClient
              .get<MediaUploadModel>(environment.api + "/user-media/upload-token")
              .toPromise();

            combineLatest([thumb$, uploadUrl$])
              .pipe(
                switchMap(([thumb, uploadUrl]) => {
                  return this.httpClient
                    .put(uploadUrl.PutUrl, thumb, {
                      headers: {
                        "Content-Type": uploadUrl.ContentType,
                        [INTERCEPTOR_SKIP_AUTH]: "true",
                      },
                    })
                    .pipe(map(() => uploadUrl.GetUrl.split("?")[0]));
                }),
                switchMap((previewUrl) => {
                  const newActivity = {
                    Id: updatedActivity.Id,
                    LinkedInPost: {
                      Media: [
                        {
                          Thumbnails: {
                            Url: previewUrl,
                          },
                        },
                      ],
                    },
                  };

                  return this.httpClient.post<any>(
                    environment.api + "/activities/update-linkedin-video-preview",
                    newActivity,
                  );
                }),
              )
              .subscribe(() => {
                this.updatingActivity = false;
                this.onActivityUpdated.emit();
              });

            return;
          }
        }
      }

      this.updatingActivity = false;
      this.onActivityUpdated.emit();
    });
  }
}
