<ng-container *ngIf="hasChannel">
  <div
    class="inline-flex relative"
    [ngClass]="{
      'filter-grayscale opacity-40': selectable,
      'filter-none !opacity-100': selected
    }">
    <img
      class="rounded-full ring-1 ring-light-base-300 dark:ring-dark-base-300 box-border object-cover z-0"
      *ngIf="picture"
      [src]="picture"
      [imageWidth]="mediaWidth"
      [imageQuality]="mediaQuality"
      [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}" />
    <img
      class="rounded-full ring-1 ring-light-base-300 dark:ring-dark-base-300 box-border object-cover z-0"
      *ngIf="!picture && !themeService.isDarkTheme"
      src="/assets/icons/unknown-user-dark.svg"
      [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}" />
    <img
      class="rounded-full ring-1 ring-light-base-300 dark:ring-dark-base-300 box-border object-cover z-0"
      *ngIf="!picture && themeService.isDarkTheme"
      src="/assets/icons/unknown-user-light.svg"
      [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}" />

    <app-icon
      (click)="onRemoveClicked()"
      class="absolute top-0 right-0 block rounded-full ring-2 ring-white dark:ring-dark-base-100 box-border z-10"
      *ngIf="removable"
      icon="x"
      [ngStyle]="{'height.px': badgeSize, 'width.px': badgeSize}"></app-icon>
    <app-icon
      class="absolute bottom-0 right-0 block rounded-full ring-2 ring-white dark:ring-dark-base-100 box-border z-10"
      *ngIf="showBadge && provider"
      [icon]="badge"
      [ngStyle]="{'height.px': badgeSize, 'width.px': badgeSize}"></app-icon>
    <app-icon
      class="absolute bottom-0 right-0 block rounded-full ring-2 ring-white dark:ring-dark-base-100 box-border z-10"
      *ngIf="icon"
      [icon]="icon"
      [ngStyle]="{
        'height.px': pictureSize,
        'width.px': pictureSize
      }"></app-icon>
  </div>
  <!-- Selection Indicator for Composer -->
  <div *ngIf="activeInComposer">
    <span
      class="absolute top-1/2 -left-4.5 translate-y-1/2 -mt-1 block h-2 w-2 rounded-full bg-light-brand-100 dark:bg-dark-brand-100"></span>
  </div>
</ng-container>
<div
  *ngIf="hasError"
  class="absolute top-0 left-0 rounded-full border-2 border-solid border-light-danger-100 dark:border-dark-danger-100 box-border z-0"
  [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}"></div>
<!--<div *ngIf="selected" class="absolute top-0 left-0 rounded-full ring-2 ring-weaver-600 box-border z-0" [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}"></div>-->
<ng-container *ngIf="!hasChannel">
  <div
    class="flex items-center justify-center h-100 w-100 rounded-full box-border border-2 border-dashed border-light-base-300 dark:border-dark-base-300"
    [ngStyle]="{'height.px': pictureSize, 'width.px': pictureSize}">
    <app-icon icon="plus" class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
  </div>
</ng-container>
