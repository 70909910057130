import {Component, OnInit} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-footer-left",
  templateUrl: "./footer-left.component.html",
  styleUrls: ["./footer-left.component.scss"],
})
export class FooterLeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
