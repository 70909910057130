import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {IProfile} from "@shared/channel/profile.interface";
import {AsyncMessageService} from "@shared/utils/async-message.service";
import {ProcessZapierAuthenticationModel} from "@shared/utils/zapier/zapier.interface";
import {ZapierService} from "@shared/utils/zapier/zapier.service";
import {MatDialogRef} from "@angular/material/dialog";
import {AddChannelDialogComponent} from "@channel/add-channel-dialog/add-channel-dialog.component";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BaseIntegrationTypeComponent} from "src/app/pages/settings/integrations/integration-type/base-integration";
import {Observable} from "rxjs";
import {IntegrationTypeEnum} from "src/app/pages/settings/integrations/integrations.interface";
import {IntegrationService} from "@shared/utils/integration-service/integration-service.service";

@UntilDestroy()
@Component({
  selector: "app-connect-zapier",
  styleUrls: ["./connect-zapier.component.scss"],
  templateUrl: "./connect-zapier.component.html",
})
export class ConnectZapierComponent extends BaseIntegrationTypeComponent implements OnInit {
  integrationTypeEnum: IntegrationTypeEnum = IntegrationTypeEnum.Zapier;

  @Input() IdProfile: string;

  @Output() Authenticated: EventEmitter<any> = new EventEmitter();

  @Output() ErrorReceived: EventEmitter<string> = new EventEmitter<string>();

  public profile: IProfile;

  public isAuthenticated = false;

  public isWaitingAuth = false;

  public error: string;

  constructor(
    private zapierService: ZapierService,
    protected asyncMessageService: AsyncMessageService,
    private dialogRef: MatDialogRef<AddChannelDialogComponent>,
    protected integrationService: IntegrationService,
  ) {
    super(asyncMessageService, integrationService);
  }

  ngOnInit(): void {
    this.zapierService
      .IsAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.Authenticated.emit();
        }
      });

    this.zapierService.zapierAuthenticationCanceled.pipe(untilDestroyed(this)).subscribe((_) => {
      if (this.isWaitingAuth) {
        this.isWaitingAuth = false;
      }
    });

    this.asyncMessageService.accountHub.OnZapierLoginFinished.pipe(untilDestroyed(this)).subscribe(
      (response: ProcessZapierAuthenticationModel) => {
        if (this.isWaitingAuth) {
          this.isWaitingAuth = false;

          if (response.Successful) {
            this.zapierService
              .refreshAuthentication()
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.error = "";
                this.isAuthenticated = true;
              });
            this.Authenticated.emit();
          } else {
            this.isAuthenticated = false;
            this.error = response.Error;
            this.ErrorReceived.emit(this.error);
          }
        }
      },
    );
  }

  AuthorizeApp() {
    this.isWaitingAuth = true;
    this.error = "";
    this.connect();
  }

  localConnect() {}

  localLogout() {}

  getAuthenticationStartUrl(): Observable<string> {
    return this.zapierService.getAuthenticationStartUrl();
  }

  close() {
    this.dialogRef.close();
  }
}
