<div class="activity-header" [class.empty]="!activity.Text">
  <div class="channel flex flex-row items-center" *ngIf="showSource">
    <app-profile-picture
      class="text-none mr-2.5"
      [picture]="(activity.Author || activity.Source)?.Picture"
      [provider]="activity.SourceType"
      [showBadge]="showBadge"
      [hasChannel]="true"
      [pictureSize]="40">
    </app-profile-picture>
    <div *ngIf="!isRepost || (isRepost && activity.Text)" class="channel-info font-sans font-normal text-sm">
      <div class="channel-name-and-handle">
        <a
          class="channel-name font-medium"
          [href]="activity.Author?.DirectUrlToProfile || activity.Source?.Url"
          target="_blank"
          *ngIf="activity.Author?.FriendlyName || activity.Source?.Name"
          [title]="activity.Author?.FriendlyName || activity.Source?.Name">
          {{ activity.Author?.FriendlyName || activity.Source?.Name }}
        </a>
        <a
          class="channel-handle font-medium"
          [href]="activity.Author?.DirectUrlToProfile || activity.Source?.Url"
          target="_blank"
          *ngIf="activity.Source?.Type === 2"
          title="@{{ activity.Author?.ScreenName || activity.Source?.ScreenName }}">
          &#64;{{ activity.Author?.ScreenName || activity.Source?.ScreenName }}
        </a>
      </div>
      <a class="activity-date text-sm" [href]="permalink" target="_blank">{{ activity.CreatedTime | amTimeAgo }}</a>
    </div>
    <div *ngIf="isRepost && !activity.Text" class="flex font-sans font-normal text-sm">
      <a
        class="channel-name font-medium"
        [href]="activity.Author?.DirectUrlToProfile || activity.Source?.Url"
        target="_blank"
        *ngIf="activity.Author?.FriendlyName || activity.Source?.Name">
        {{ activity.Author?.FriendlyName || activity.Source?.Name }}
      </a>
      <a class="activity-date text-sm ml-1" [href]="permalink" target="_blank">reposted this</a>
    </div>
  </div>
  <p class="replying-to mt-4" *ngIf="activity.Tweet && activity.Tweet.InReplyToScreenName">
    <span>Replying to</span>
    <a class="font-medium" [href]="'https://twitter.com/' + activity.Tweet.InReplyToScreenName" target="_blank"
      >&#64;{{ activity.Tweet.InReplyToScreenName }}</a
    >
  </p>
  <pre
    class="activity-text"
    *ngIf="!activity.SyndicationItem && !activity.tokens"
    data-test="shared-activityText-p"
    [innerHTML]="activity.Text | linkify: activity"></pre>

  <pre
    class="activity-text"
    *ngIf="!activity.SyndicationItem && activity.Text && activity.tokens"
    data-test="shared-activityText-p"
    [innerHTML]="activity.tokens | linkifytokens: activity"></pre>
</div>
