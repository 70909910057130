<header class="pane-header" data-test="composer-empty-header">
  <div class="title">Composer</div>
  <app-icon-button icon="x" iconSize="20" (buttonClick)="close()"></app-icon-button>
</header>
<div class="pane-body" data-test="composer-empty-body-div">
  <div class="empty-state-wrapper composer p-10">
    <div class="empty-state-image composer"></div>
    <div class="empty-state-text mt-5">No Private Channels Connected</div>
    <p class="text-center mt-2">
      Once you connect your private channels, you'll be able to compose and schedule content from here.
    </p>
    <app-button
      appGlobalroleValidator
      permission="ManageChannels"
      [hideElement]="false"
      [disableElement]="true"
      [showLock]="true"
      [toastOnClick]="true"
      type="inline"
      icon="channels"
      (buttonClick)="addChannel(); close()"
      class="mt-4"
      >Add Channel</app-button
    >
  </div>
</div>
