import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Color} from "@shared/utils/color-service/color.interface";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  private numbers: number[];
  private maxColors = 128;

  private arrayOfTagColors: Color[] = [];

  constructor(private httpClient: HttpClient) {
    this.numbers = Array.from({length: this.maxColors}, (v, k) => k + 1);
    this.shuffleArray(this.numbers);
  }

  generateBgColor(): string {
    const number = this.numbers.shift();
    this.numbers.push(number);
    return this.selectColor(number);
  }

  async getColorsList(): Promise<Color[]> {
    if (this.arrayOfTagColors.length == 0) {
      await this.getColorsFromServer();
    }

    return this.arrayOfTagColors;
  }

  async getRandomColorFromDefinedList(): Promise<Color> {
    if (this.arrayOfTagColors.length == 0) {
      await this.getColorsFromServer();
    }

    const randomIndex = Math.round(Math.random() * (this.arrayOfTagColors.length - 1));
    return this.arrayOfTagColors[randomIndex];
  }

  generateFgColor(bgColor: string): string {
    const {r, g, b} = this.hexToRgb(bgColor);
    const sum = Math.round((parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000);
    return sum > 128 ? "black" : "white";
  }

  private hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  selectColor(colorNum: number, colors = this.maxColors): string {
    if (colors < 1) {
      colors = 1;
    }
    const h = (colorNum * (360 / colors)) % 360;
    const s = 75;
    const l = 50;
    return this.hslToHex(h, s, l);
  }

  hslToHex(h, s, l): string {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

  private getColorsFromServer() {
    return this.httpClient
      .get<Color[]>(environment.api + "/tags/colors")
      .pipe(
        tap((colors) => {
          this.arrayOfTagColors = colors;
        }),
      )
      .toPromise();
  }

  private shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
