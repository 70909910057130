import {Component, forwardRef, AfterContentInit, Output, EventEmitter} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DateRange} from "@angular/material/datepicker";
import {UntilDestroy} from "@ngneat/until-destroy";
import {AutoCompleteInfo} from "@shared/utils/autocompleteinput/autocompleteinput.component";
import {DateRangePreset, TimeFrameRange} from "@shared/utils/time-selector/time-picker/time-struct.interface";
import moment from "moment";
import {TimerangeListenerService} from "../../pages/analytics/timerange-listener.service";
@UntilDestroy()
@Component({
  selector: "app-date-range",
  templateUrl: "./date-range.component.html",
  styleUrls: ["./date-range.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    },
  ],
})
export class DateRangeComponent implements ControlValueAccessor, AfterContentInit {
  @Output() closeDateRange = new EventEmitter<void>();

  selectedRangeValue: DateRange<Date> | undefined;

  selectedPreset: AutoCompleteInfo[];

  datePresets: AutoCompleteInfo[] = [
    {
      DisplayName: "Today",
      Value: DateRangePreset.Today.toString(),
      Custom: false,
    },
    {
      DisplayName: "Last 7 days",
      Value: DateRangePreset.Last7Days.toString(),
      Custom: false,
    },
    {
      DisplayName: "Last 4 weeks",
      Value: DateRangePreset.Last4Weeks.toString(),
      Custom: false,
    },
    {
      DisplayName: "Last 3 months",
      Value: DateRangePreset.Last3Months.toString(),
      Custom: false,
    },
    {
      DisplayName: "Last 12 months",
      Value: DateRangePreset.Last12Months.toString(),
      Custom: false,
    },
    {
      DisplayName: "Month to date",
      Value: DateRangePreset.MonthToDate.toString(),
      Custom: false,
    },
    {
      DisplayName: "Quarter to date",
      Value: DateRangePreset.QuarterToDate.toString(),
      Custom: false,
    },
    {
      DisplayName: "Year to date",
      Value: DateRangePreset.YearToDate.toString(),
      Custom: false,
    },
    {
      DisplayName: "All time",
      Value: DateRangePreset.AllTime.toString(),
      Custom: false,
    },
    {
      DisplayName: "Custom",
      Value: DateRangePreset.Custom.toString(),
      Custom: false,
    },
  ];
  disabled: boolean;
  value: TimeFrameRange;

  private onChange: (_: TimeFrameRange) => void = (t) => {
    this.value = t;
    this.initializeTimeFrameRange();
  };
  private onTouched: () => void = () => {};
  initialValue: TimeFrameRange;

  public get startDate(): Date {
    return this.selectedRangeValue?.start;
  }
  public set startDate(value: Date) {
    this.selectedRangeValue = new DateRange(value, this.selectedRangeValue?.end);
    this.changeToCustomDatePreset();
    // this.dateRangeChanged();
  }

  public get endDate(): Date {
    return this.selectedRangeValue?.end;
  }
  public set endDate(value: Date) {
    this.selectedRangeValue = new DateRange(this.selectedRangeValue?.start, value);
    this.changeToCustomDatePreset();
    // this.dateRangeChanged();
  }

  constructor(public timerangeListener: TimerangeListenerService) {}

  ngAfterContentInit(): void {
    this.initialValue = {...this.value};

    if (this.value != null && this.value.SelectedDatePreset != DateRangePreset.Custom) {
      const dateRangeValue = this.value.SelectedDatePreset;
      const startDate = this.getDisplayDateFromRangeEnum(dateRangeValue).toDate();
      const endDate = moment().toDate();

      this.selectedRangeValue = new DateRange<Date>(startDate, endDate);
      this.applyChanges();
    }
  }

  writeValue(obj: TimeFrameRange): void {
    this.value = obj;
    this.onChange(obj);
  }
  registerOnChange(fn: (_: TimeFrameRange) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  initializeTimeFrameRange() {
    const preset = this.datePresets.find((x) => x.Value == this.value?.SelectedDatePreset?.toString());

    this.selectedPreset = [preset];

    this.selectedRangeValue = new DateRange<Date>(this.value?.StartDate, this.value?.EndDate);
  }

  selectedChange(m: Date) {
    this.changeToCustomDatePreset();
    if (!this.selectedRangeValue?.start || this.selectedRangeValue?.end) {
      this.selectedRangeValue = new DateRange<Date>(m, null);
    } else {
      const start = this.selectedRangeValue.start;
      const end = m;
      if (end < start) {
        this.selectedRangeValue = new DateRange<Date>(end, start);
      } else {
        this.selectedRangeValue = new DateRange<Date>(start, end);
      }
    }
    // this.dateRangeChanged();
  }

  onPresetClicked(event: AutoCompleteInfo) {
    const dateRangeValue = parseInt(event.Value);
    const startDate = this.getDisplayDateFromRangeEnum(dateRangeValue).toDate();
    const endDate = moment().toDate();

    this.selectedRangeValue = new DateRange<Date>(startDate, endDate);
    // this.dateRangeChanged();
  }

  changeToCustomDatePreset() {
    const customPreset = this.datePresets.find((x) => x.Value == DateRangePreset.Custom.toString());

    this.selectedPreset = [customPreset];
  }

  getDisplayDateFromRangeEnum(dateRangeEnum: DateRangePreset) {
    let currentMoment = moment();

    switch (dateRangeEnum) {
      case DateRangePreset.Today:
        break;
      case DateRangePreset.Last7Days:
        currentMoment = currentMoment.add(-7, "day");
        break;
      case DateRangePreset.Last4Weeks:
        currentMoment = currentMoment.add(-4, "weeks");
        break;
      case DateRangePreset.Last3Months:
        currentMoment = currentMoment.add(-3, "months");
        break;
      case DateRangePreset.Last12Months:
        currentMoment = currentMoment.add(-12, "months");
        break;
      case DateRangePreset.MonthToDate:
        currentMoment = currentMoment.startOf("month");
        break;
      case DateRangePreset.QuarterToDate:
        currentMoment = currentMoment.startOf("quarter");
        break;
      case DateRangePreset.YearToDate:
        currentMoment = currentMoment.startOf("year");
        break;
      case DateRangePreset.AllTime:
        currentMoment = currentMoment.add(-3, "year");
        break;
      case DateRangePreset.Custom:
        break;
    }

    return currentMoment;
  }

  applyChanges() {
    const selectedDatePresetInt = parseInt(this.selectedPreset[0].Value);

    const selectedRange: TimeFrameRange = {
      StartDate: this.startDate,
      EndDate: this.endDate ?? this.startDate,
      SelectedDatePreset: selectedDatePresetInt,
    };
    this.writeValue(selectedRange);

    this.initialValue = {...this.value};

    this.closeDateRange.emit();
  }

  cancelChanges() {
    console.log("cancelling changes", {value: this.value}, {initialValue: this.initialValue});
    this.value = this.initialValue;
    this.initializeTimeFrameRange();
    this.closeDateRange.emit();
  }
}
