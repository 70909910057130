import {Component, EventEmitter, Input, Output} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";
import {IconableButton} from "./IconableButton";

@UntilDestroy()
@Component({
  selector: "app-basic-button",
  templateUrl: "./basic-button.component.html",
  styleUrls: ["./basic-button.component.scss"],
})
export class BasicButtonComponent extends IconableButton {
  @Input() type: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;

  @Input() customId: string;
  @Input() minWidth: number;
  @Input() buttonType: string;
  @Output() public buttonClick: EventEmitter<Event> = new EventEmitter();

  constructor() {
    super();
  }
}
