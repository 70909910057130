import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-logo-text",
  templateUrl: "./logo-text.component.html",
  styleUrls: ["./logo-text.component.scss"],
})
export class LogoTextComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
