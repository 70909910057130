import {ContentModel} from "@shared/publisher/content.interface";
import {ComposerValidation} from "./ComposerValidation";
import {ISelectedProfile} from "../composer.component";
import {TwitterMediaValidation} from "./media-validation/TwitterMediaValidation";
import {getTweetCharactersCountBalance} from "@shared/utils/twitterCharactersLeft.function";

export class TwitterComposerValidation extends ComposerValidation {
  FewVideos: boolean;
  VideoAndImages: boolean;
  ManyImages: boolean;
  ManyGifs: boolean;
  CharLimit: boolean;
  NoContentTwitter: boolean;
  ExceedingImageSize: boolean;
  ExceedingVideoSize: boolean;
  ExceedingGifSize: boolean;
  ExceedingVideoDuration: boolean;
  MixesGifAndImages: boolean;

  constructor() {
    super();
    this.Reset();
  }

  IsValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    contentModel.MediaFiles = contentModel.MediaFiles || [];
    const videos = contentModel.MediaFiles.filter(
      (file) => file.MediaType.startsWith("video") && file.EnabledForTwitter,
    );
    const images = contentModel.MediaFiles.filter(
      (file) => !file.MediaType.startsWith("video") && file.EnabledForTwitter,
    );
    const gifs = contentModel.MediaFiles.filter((file) => file.MediaType === "image/gif" && file.EnabledForTwitter);
    const hasMoreThen1Video = videos.length > 1;
    const hasAnyVideos = videos.length > 0;
    const hasAnyImages = images.length > 0;
    const hasManyImages = images.length > 4;
    const hasManyGifs = gifs.length > 1;
    const mixesGifAndImages = gifs.length > 0 && images.length > 0 && images.length !== gifs.length;
    const charactersLeftCount = getTweetCharactersCountBalance(contentModel.TwitterText);
    const exceedingCharlimit = charactersLeftCount < 0;

    let mediaFilesValid = true;

    if (contentModel.MediaFiles) {
      contentModel.MediaFiles.filter((file) => file.EnabledForTwitter).forEach((element) => {
        if (element.EnabledForTwitter) {
          const twitterValidation = new TwitterMediaValidation();

          if (element.MediaType.startsWith("image") && element.MediaType !== "image/gif") {
            twitterValidation.PhotoSizeError = element.SizeBytes > this.MbToBytes(5);
          } else if (element.MediaType.startsWith("video")) {
            const twitterVideoLimit = this.MbToBytes(512);
            twitterValidation.VideoSizeError = element.SizeBytes > twitterVideoLimit;

            const twitterVideoDurationMinSecs = 0.5;
            const twitterVideoDurationMaxSecs = 140;

            twitterValidation.VideoDurationError = !(
              element.videoDuration > twitterVideoDurationMinSecs && element.videoDuration < twitterVideoDurationMaxSecs
            );
          } else if (element.MediaType == "image/gif") {
            twitterValidation.GifSizeError = element.SizeBytes > this.MbToBytes(15);
          }

          if (mediaFilesValid) {
            mediaFilesValid = twitterValidation.IsValid();
          }

          if (!this.ExceedingImageSize) {
            this.ExceedingImageSize = twitterValidation.PhotoSizeError;
          }

          if (!this.ExceedingVideoSize) {
            this.ExceedingVideoSize = twitterValidation.VideoSizeError;
          }

          if (!this.ExceedingGifSize) {
            this.ExceedingGifSize = twitterValidation.GifSizeError;
          }

          if (!this.ExceedingVideoDuration) {
            this.ExceedingVideoDuration = twitterValidation.VideoDurationError;
          }

          element.twitterValidation = twitterValidation;
        }
      });
    }

    this.FewVideos = hasMoreThen1Video;
    this.VideoAndImages = hasAnyVideos && hasAnyImages;
    this.ManyImages = hasManyImages;
    this.ManyGifs = hasManyGifs;
    this.CharLimit = exceedingCharlimit;
    this.NoContentTwitter = !contentModel.TwitterText;
    this.MixesGifAndImages = mixesGifAndImages;
    return (
      !(
        this.ExceedingGifSize ||
        this.ExceedingVideoSize ||
        this.ExceedingVideoDuration ||
        this.ExceedingImageSize ||
        this.NoContentTwitter ||
        this.CharLimit ||
        this.ManyGifs ||
        this.ManyImages ||
        this.VideoAndImages ||
        this.FewVideos ||
        this.MixesGifAndImages
      ) && mediaFilesValid
    );
  }

  LocalReset() {
    this.FewVideos = false;
    this.VideoAndImages = false;
    this.ManyImages = false;
    this.ManyGifs = false;
    this.CharLimit = false;
    this.NoContentTwitter = false;
    this.ExceedingImageSize = false;
    this.ExceedingVideoSize = false;
    this.ExceedingGifSize = false;
    this.ExceedingVideoDuration = false;
    this.MixesGifAndImages = false;
  }
}
