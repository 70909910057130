///But what this function does is, it scans the quill text to see if there is a HTML element that is actually a mention added by the quill-mention plugin.
//If it is we extract the id of the socialmedia profile, and the name, to add it to a mentions object that has the index where the mention start and ends.

import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {MentionsOnMessage} from "@shared/publisher/content.interface";
import {stripHtmlForQuillText} from "./strip-html.function";

export function getMentionsFromQuillText(text: string, sourceType: ProfileTypes): MentionsOnMessage {
  if (!text) {
    return {
      text: text,
      mentions: [],
    };
  }

  const mentions = [];
  if (
    sourceType == ProfileTypes.Facebook ||
    sourceType == ProfileTypes.LinkedIn ||
    sourceType == ProfileTypes.TwitterAccount
  ) {
    const mentionRegex =
      /<span class="mention" data-index="(\d+)" data-denotation-char="" data-id="(\d+)" data-value="([^<>"]+?)" data-sourcetype="(\d+)" data-newmention="([A-z]+)" data-editorindex="(\d+)">?([\s+])<span contenteditable="false"><span class="ql-mention-denotation-char"><\/span>([^<>"]+?)<\/span>?(\s)<\/span>/g;
    let textToEvaluate = text.replace(mentionRegex, "@[$1_$2_$3_$4_$5_$6]");
    textToEvaluate = stripHtmlForQuillText(textToEvaluate);

    const matches =
      textToEvaluate.match(/@\[([^<>"]+?)_([^<>"]+?)_([^<>"]+?)_([^<>"]+?)_([^<>"]+?)_([^<>"]+?)\]/g) ?? [];
    const customRegex = /(.+)_(.+)_(.+)_(.+)_(.+)_(.+)/g;

    for (const match of matches) {
      const previewIndex = match.replace("@[", "").replace("]", "").replace(customRegex, "$1");

      const matchId = match.replace("@[", "").replace("]", "").replace(customRegex, "$2");
      const matchText = match.replace("@[", "").replace("]", "").replace(customRegex, "$3");

      const matchSourceType = match.replace("@[", "").replace("]", "").replace(customRegex, "$4");

      const editorIndex = match.replace("@[", "").replace("]", "").replace(customRegex, "$6");

      const startIndex = parseInt(previewIndex);
      const endIndex = startIndex + matchText.length - 1;

      textToEvaluate = textToEvaluate.replace(match, matchText);

      if (parseInt(matchSourceType) != sourceType) continue;

      mentions.push({
        Reference: matchId,
        Name: matchText,
        SourceType: matchSourceType,
        EditorIndexStart: editorIndex,
        IndexStart: startIndex,
        IndexEnd: endIndex,
      });
    }

    //Remove the BOM from quill, the ASCII code is 65279 BOM
    const bomRegex = new RegExp("﻿", "gi");
    textToEvaluate = textToEvaluate.replace(bomRegex, "");

    return {
      text: textToEvaluate,
      mentions: mentions.sort((a, b) => b.IndexStart - a.IndexStart),
    };
  }

  return {
    text: text,
    mentions: [],
  };
}
