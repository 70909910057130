import {Component, Input, OnChanges} from "@angular/core";
import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {Activity} from "../activity.model";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-twitter-activity",
  templateUrl: "./twitter-activity.component.html",
  styleUrls: ["./twitter-activity.component.scss"],
})
export class TwitterActivityComponent implements OnChanges {
  @Input() activity: Activity;
  @Input() showSource = true;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showVolumeControl: boolean;
  @Input() showBadge = true;
  @Input() showDescription: boolean;
  @Input() showDomain: boolean;

  photos: ImageAttachment[];
  video: VideoAttachment;
  retweetedActivity: Activity;

  constructor() {}

  ngOnChanges() {
    // console.log("TwitterActivityComponent.ngOnChanges", this.activity.Links);
    if (this.activity.Tweet.IsRetweeted) {
      this.retweetedActivity = {...this.activity} as Activity;
      this.activity = this.activity.Tweet.Original;
    }
    const video = this.activity.Tweet.videoAttachment;
    const hasVideoUrl = this.activity && this.activity.getMetaTagValue && !!this.activity.getMetaTagValue("video:url");
    if (hasVideoUrl && video && video.type === "share") {
      this.photos = null;
      this.video = null;
    } else {
      this.photos = this.activity.Tweet.photosAttachment;
      this.video = video;
    }
  }
}
