import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {Source} from "@stream/stream.interface";
import {HttpClient} from "@angular/common/http";
import {AccountSubType, ProfileTypes} from "./profile-types.enum";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SuggestionService {
  private baseUrl = environment.api + "/Suggestions";

  constructor(private http: HttpClient) {}

  searchPublicSources(
    query: string,
    sourceType: ProfileTypes,
    count: number,
    subtype: AccountSubType = null,
  ): Observable<Source[]> {
    return this.http
      .get<
        Source[]
      >(this.baseUrl + `/Sources?query=${query}&sourceType=${sourceType.valueOf()}&count=${count}&subtype=${subtype}`)
      .pipe(
        tap((sources) => {
          sources.forEach((x) => {
            if (x.Picture == null && x.Type === ProfileTypes.SyndicationFeed) {
              x.Picture = "assets/images/rss.png";
            }
            if (x.Picture == null && x.Subtype !== "PublicHashtag") {
              x.Picture = "assets/images/no_company_logo.png";
            }
            if (x.Picture == null && x.Subtype === "PublicHashtag") {
              x.Picture = "assets/images/hashtag.png";
            }
            if (x.Subtype == "TwitterHashtag") {
              x.KeywordTwitterEnabled = true;
            }
          });
        }),
      );
  }
}
