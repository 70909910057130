<div *ngIf="retweetedActivity" class="retweeted">
  <mat-icon svgIcon="repeat"></mat-icon>
  <a
    class="retweet-text"
    [href]="retweetedActivity.Author.DirectUrlToProfile"
    target="_blank"
    [title]="retweetedActivity.Author.FriendlyName"
    >{{ retweetedActivity.Author.FriendlyName }}</a
  >
  <a class="retweet-text" [href]="retweetedActivity.Tweet.Permalink" target="_blank"
    ><span>Retweeted</span> {{ retweetedActivity.CreatedTime | amTimeAgo }}</a
  >
</div>

<app-activity-header
  *ngIf="activity"
  [activity]="activity"
  [showBadge]="showBadge"
  [permalink]="activity.Tweet.Permalink"
  [showSource]="showSource"></app-activity-header>

<app-link-preview
  *ngIf="
    activity &&
    activity.UrlMetadata?.length &&
    !activity.Tweet.Photos?.length &&
    !activity.Tweet.Videos?.length &&
    !activity.Tweet.QuotedStatusId
  "
  [activity]="activity"
  [mediaQuality]="mediaQuality"
  [showDescription]="showDescription"
  [showDomain]="showDomain"
  [mediaWidth]="mediaWidth"></app-link-preview>

<app-image-attachment
  *ngIf="photos && !video"
  [image]="photos"
  [activity]="activity"
  [imageQuality]="mediaQuality"
  [imageWidth]="mediaWidth"></app-image-attachment>

<app-video-attachment
  *ngIf="video"
  [video]="video"
  [activity]="activity"
  [showVolumeControl]="showVolumeControl"></app-video-attachment>

<app-twitter-activity
  class="quoted-tweet"
  *ngIf="activity && activity.Tweet.QuotedStatusId && !photos"
  [activity]="activity.Tweet.Original"></app-twitter-activity>
