import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {EmployeeInList} from "@shared/channel/profile.interface";
import {Pagination} from "@shared/utils/pagination/pagination.model";
import {Observable, tap} from "rxjs";
import {GetEmployeesRequestModel, TriggerEmployeeClientEngagementModel} from "./employee.model";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  baseUrl = `${environment.api}/Employees`;

  constructor(private http: HttpClient) {}

  getEmployees(request: GetEmployeesRequestModel): Observable<Pagination<EmployeeInList>> {
    return this.http.post<Pagination<EmployeeInList>>(`${this.baseUrl}/GetEmployees`, request).pipe(
      tap((result) => {
        result.Data.forEach((x) => (x.IsALinkedInEmployee = true));
      }),
    );
  }

  triggerCustomAction(request: TriggerEmployeeClientEngagementModel) {
    return this.http.post(`${this.baseUrl}/TriggerEmployeeEngagementClientAction`, request);
  }
}
