import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from "@angular/core";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HashtagGroupModel} from "@shared/hashtag-groups/hashtag-group.interface";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {ComposerComponent} from "@composer/composer.component";
import {HashtagGroupService} from "@shared/hashtag-groups/hashtag-group.service";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import XRegExp from "xregexp";
import {Observable} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@UntilDestroy()
@Component({
  selector: "app-composer-create-hashtag-group",
  templateUrl: "./composer-create-hashtag-group.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-510px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-510px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerCreateHashtagGroupComponent extends PanelOverlay implements OnInit {
  form: FormGroup;
  loading: boolean;
  submitted = false;
  error: string;
  title = "Create Hashtag Group";
  countOfHashtags = 0;
  saveButtonText = "Create Group";
  hashtagGroup: HashtagGroupModel[];

  constructor(
    dialogRef: PanelOverlayRef<ComposerComponent>,
    formBuilder: FormBuilder,
    private hashtagGroupService: HashtagGroupService,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: HashtagGroupModel,
  ) {
    super(dialogRef, data);

    this.form = formBuilder.group({
      HashtagGroupId: "",
      Name: ["", Validators.required],
      Text: ["", Validators.required],
      Private: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.data?.hashtagGroup) {
      this.form.patchValue(this.data.hashtagGroup);
      this.title = "Update hashtag group";
      this.saveButtonText = "Update Group";
    }

    this.hashtagGroupService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((x) => (this.hashtagGroup = x));
  }

  onHashtagGroupNameInput() {
    const value = this.form.get("Name").value;
    this.form.get("Name").setValue(value);
  }

  onHashtagGroupTextInput() {
    const value = this.form.get("Text").value;
    this.form.get("Text").setValue(value);

    const regex = XRegExp("(#?[a-zA-Z\\d-]+)", "gi");

    const result = XRegExp.match(value, regex) ?? [];
    this.countOfHashtags = result.length;
  }

  save() {
    this.error = "";
    this.submitted = true;
    this.loading = true;

    if (this.form.valid) {
      const exists =
        !this.data?.hashtagGroup &&
        this.hashtagGroup.find(
          (hashtagGroup) => hashtagGroup.Name.toLowerCase() === this.form.value.Name.toLowerCase(),
        );
      const existsAndNotCurrent =
        this.data?.hashtagGroup &&
        this.hashtagGroup.find(
          (hashtagGroup) =>
            hashtagGroup.Name.toLowerCase() === this.form.value.Name.toLowerCase() &&
            hashtagGroup.HashtagGroupId !== this.data.hashtagGroup.HashtagGroupId,
        );
      if (exists || existsAndNotCurrent) {
        this.form.get("Name").setErrors({nameExists: true});
        this.error = "A hashtag group with this name already exists.";
        this.loading = false;
        return;
      } else {
        this.loading = true;

        if (this.countOfHashtags > 25) {
          this.error = "The maximum number of allowed hashtags per group is 25.";
          this.loading = false;
          return;
        }

        let observable: Observable<HashtagGroupModel>;

        if (!this.data?.hashtagGroup) {
          const data = this.form.value;
          delete data.HashtagGroupId;
          observable = this.hashtagGroupService.createHashTagGroup(data);
        } else {
          observable = this.hashtagGroupService.editHashTagGroup(this.form.value);
        }
        observable.pipe(untilDestroyed(this)).subscribe({
          next: (hashtagGroup: HashtagGroupModel) => {
            this.loading = false;
            this.dialogRef.close(hashtagGroup);
          },
          error: (err) => {
            this.error = "An error occurred while saving the hashtag group.";
            this.loading = false;
            console.error("Error saving hashtag group:", err);
          },
        });
      }
    } else {
      this.loading = false;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
