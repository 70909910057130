<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0" xmlns="http://www.w3.org/1999/html">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 ml-2">
        <ng-container>{{ title }}</ng-container>
      </div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        (buttonClick)="close()"
        buttonType="button"
        iconSize="20"
        class="block -mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-50px)] min-w-[510px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div class="flex-1 p-5">
        <section
          class="relative inline-flex flex-col h-full w-full p-5 box-border bg-light-base-0 dark:bg-dark-base-50 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md">
          <form [formGroup]="form" class="flex flex-col">
            <!-- Hashtag group name -->
            <mat-form-field
              subscriptSizing="fixed"
              appearance="outline"
              floatLabel="always"
              [ngClass]="{'mb-2.5': submitted && form.get('Name').errors}">
              <mat-label>Group Name</mat-label>
              <input
                matInput
                (keydown)="onHashtagGroupNameInput()"
                data-test="settings-hashtag-manager-group-name-input"
                formControlName="Name"
                autocomplete="off" />
              <mat-error
                *ngIf="submitted && form.get('Name').errors"
                data-test="settings-hashtag-manager-group-name-input-error">
                <span *ngIf="form.get('Name').errors.required">Group name is required.</span>
                <span *ngIf="form.get('Name').errors.nameExists">A group with this name already exists.</span>
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              [ngClass]="{'mb-2.5': submitted && form.get('Text').errors}">
              <mat-label>Hashtags</mat-label>
              <textarea
                matInput
                [rows]="6"
                (keydown)="onHashtagGroupTextInput()"
                formControlName="Text"
                data-test="settings-hashtag-manager-group-textarea"
                autocomplete="off"></textarea>
              <mat-error
                *ngIf="submitted && form.get('Text').errors"
                data-test="settings-hashtag-manager-group-textarea-error">
                <span *ngIf="form.get('Text').errors.required">Hashtag group can't be empty.</span>
              </mat-error>
              <mat-hint align="end" class="text-light-base-500 dark:text-dark-base-500"
                >Hashtags {{ countOfHashtags }}/25</mat-hint
              >
              <small></small>
            </mat-form-field>
            <footer class="dialog-footer mt-4">
              <app-button
                type="block"
                (buttonClick)="save()"
                [loading]="loading"
                data-test="settings-hashtag-manager-save-button"
                >{{ saveButtonText }}</app-button
              >
            </footer>
          </form>
        </section>
      </div>
    </ng-scrollbar>
  </div>
</div>
