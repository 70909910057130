import {Comment} from "../comments/comment.model";
import {Reaction} from "../activity.interface";
import {EventEmitter, Output, Directive} from "@angular/core";
import {unique} from "@utils/unique.function";

@Directive()
export class Reactable {
  @Output() ReactionChanged = new EventEmitter<any>();

  constructor(
    public Reactions: Reaction[],
    public Comments: Comment[],
    public CommentsCount: number,
  ) {}

  addReaction(reaction: Reaction) {
    this.Reactions = this.Reactions || [];
    this.Reactions.push(reaction);

    this.ReactionChanged.emit();
  }

  removeReaction(reaction: Reaction) {
    this.Reactions = this.Reactions.filter((r) => {
      return r !== reaction;
    });

    this.ReactionChanged.emit();
  }

  findReaction(reaction: Reaction): Reaction {
    return this.Reactions.find((r) => {
      return r.ReactionType === reaction.ReactionType && r.ProfileExternalId === reaction.ProfileExternalId;
    });
  }

  hasReaction(reactionType: string, profileExternalId: string): boolean {
    if (!this.Reactions || !this.Reactions.length) {
      return false;
    }
    const reaction = {
      ReactionType: reactionType,
      ProfileExternalId: profileExternalId,
    };
    return !!this.findReaction(reaction);
  }

  addComment(comment: Comment) {
    this.Comments = this.Comments || [];
    this.Comments.unshift(comment);
    this.CommentsCount++;
  }

  addCommentAtIndex(comment: Comment, index: number) {
    this.Comments = this.Comments || [];
    this.Comments.splice(index, 0, comment);
    this.CommentsCount++;
  }

  removeCommentByIndex(index: number) {
    this.Comments.splice(index, 1);
    if (this.CommentsCount > 0) {
      this.CommentsCount--;
    }
  }

  appendMoreComments(comments: Comment[]) {
    this.Comments = this.Comments || [];
    this.Comments = unique(this.Comments.concat(comments), "Id");
  }
}
