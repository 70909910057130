<header class="dialog-header">
  <!-- Step 0 - Channel type selection -->
  <ng-container *ngIf="step === 0">
    <a
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Connect Channels</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
      What type of channels would you like to connect?
    </div>
  </ng-container>
  <!-- Step 1 & 2 - Private compatible and incompatible channels -->
  <ng-container *ngIf="step >= 1 && step < 3">
    <a
      (click)="goTo(step - 1)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      data-test="channel-addchannel-a"
      >Cancel</a
    >
    <ng-container *ngIf="step === 1">
      <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Private Channels</div>
      <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
        Private channels are channels you own or manage with full admin access.
      </div>
    </ng-container>
    <ng-container *ngIf="step === 2 && hasChannels()">
      <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Select Channels</div>
      <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
        Select the channels you would like to connect to SocialWeaver.
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="step === 2 && !hasChannels()">
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">
      No Compatible Channels
    </div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
      We couldn't find any compatible channels.
    </div>
  </ng-container>
  <!-- Step 3 - Public channels -->
  <ng-container *ngIf="step === 3">
    <a
      *ngIf="step === 3"
      (click)="goTo(0)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Public Channels</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
      Any public brand or profile. Log in credentials are not required.
    </div>
  </ng-container>
  <!-- Step 4 - Searching Facebook -->
  <ng-container *ngIf="step === 4">
    <a
      (click)="goTo(3)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Facebook</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">Search Public Pages</div>
  </ng-container>
  <!-- Step 5 - Searching Twitter -->
  <ng-container *ngIf="step === 5">
    <a
      (click)="goTo(3)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Twitter</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">Search Public Profiles</div>
  </ng-container>
  <!-- Step 6 - Searching Instagram -->
  <ng-container *ngIf="step === 6">
    <a
      (click)="goTo(3)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">Instagram</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">Search Public Profiles</div>
  </ng-container>
  <!-- Step 7 - Searching LinkedIn -->
  <ng-container *ngIf="step === 7">
    <a
      (click)="goTo(3)"
      class="absolute top-0 left-0 group inline-flex items-center text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha">
      <app-icon
        icon="arrow-left"
        class="h-4 w-4 mr-1 leading-5 text-light-base-400 dark:text-dark-base-400 group-hover:text-light-base-600 dark:group-hover:text-white-alpha"></app-icon>
      Back
    </a>
    <a
      *ngIf="!cancelDisabled"
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="dialogRef.close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">LinkedIn</div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">Search public pages</div>
  </ng-container>
</header>
<!-- Dialog Content -->
<mat-tab-group [(selectedIndex)]="step" dynamicHeight>
  <mat-tab [tabIndex]="0">
    <!-- Select Private or Public Channels to Add -->
    <div class="grid grid-cols-2 gap-4 w-[464px] min-w-[464px] max-w-[464px]" data-test="channeldialog-dialog-div">
      <app-dialog-card
        class="h-52 md:w-52 m-2"
        cardTitle="Private Channels"
        description="Channels you own or manage with full admin access."
        [showSupportedChannels]="true"
        [supportsFacebook]="true"
        [supportsInstagram]="true"
        [supportsTwitter]="true"
        [supportsLinkedin]="true"
        (connect)="goTo(1)"
        appGlobalroleValidator
        permission="ManageChannels"
        [disableElement]="true"
        [toastOnClick]="true"
        data-test="channels-addchannelprivate-card"></app-dialog-card>
      <app-dialog-card
        class="h-52 md:w-52 m-2"
        cardTitle="Public Channels"
        buttonLabel="Search"
        [description]="
          !facebookPublicSearchEnabled && !instagramPublicSearchEnabled && !linkedInPublicSearchEnabled
            ? 'Connect a private channel first to enable this option.'
            : 'Any public brand or profile.'
        "
        [showSupportedChannels]="true"
        [supportsFacebook]="true"
        [supportsInstagram]="true"
        [supportsTwitter]="false"
        [supportsLinkedin]="true"
        [isDisabled]="!facebookPublicSearchEnabled && !instagramPublicSearchEnabled && !linkedInPublicSearchEnabled"
        appGlobalroleValidator
        permission="ManageChannels"
        [disableElement]="true"
        [toastOnClick]="true"
        (connect)="goTo(3)"
        data-test="channel-addchannelpublic-card"></app-dialog-card>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="1">
    <!-- Adding Private Channel Options -->
    <div class="grid grid-cols-3 gap-1 w-[632px] min-w-[632px] max-w-[632px]">
      <app-dialog-card
        class="h-48 md:w-48 m-2"
        cardTitle="Facebook"
        description="Manage pages and groups. Publish, comment, react, and share."
        [showSupportedChannels]="false"
        [facebookLogo]="true"
        (connect)="connect('facebook')"
        data-test="channels-addfacebook-card"></app-dialog-card>
      <app-dialog-card
        class="h-48 md:w-48 m-2"
        cardTitle="Instagram"
        description="Manage professional accounts. Publish, comment, and react."
        [showSupportedChannels]="false"
        [instagramLogo]="true"
        (connect)="connect('instagram')"
        data-test="channels-addinstagram-card"></app-dialog-card>
      <app-dialog-card
        class="h-48 md:w-48 m-2"
        cardTitle="Twitter"
        description="Manage profiles. Tweet, reply, retweet, and react."
        [showSupportedChannels]="false"
        [twitterLogo]="true"
        (connect)="connect('twitter')"
        data-test="channels-addtwitter-card"></app-dialog-card>
      <app-dialog-card
        class="h-48 md:w-48 m-2"
        cardTitle="LinkedIn"
        description="Manage company and product pages. Publish, comment, and react."
        [showSupportedChannels]="false"
        [linkedinLogo]="true"
        (connect)="connect('linkedin')"
        data-test="channels-linkedin-card"></app-dialog-card>
      <app-dialog-card
        class="h-48 md:w-48 m-2"
        requestNew="true"
        cardTitle="Request Channel"
        description="Looking for something else? Request support for other networks."
        (connect)="request()"></app-dialog-card>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="2">
    <!-- Private Channel List After Authentication -->
    <ng-container *ngIf="step === 2 && hasChannels()">
      <div *ngIf="loading" class="private-channel-loader" data-test="channels-privatechannels-loader">
        <app-loader></app-loader>
      </div>
      <!-- Channel Limited Exceeded -->
      <app-alert *ngIf="isChannelsExceeded" class="mb-5">
        <app-alert-error
          >The number of channels selected exceeds the number of channels permitted on current plan.
        </app-alert-error>
      </app-alert>
      <div class="select-channels" *ngIf="!loading">
        <ng-scrollbar class="app-scrollbar" style="height: 280px; min-height: 280px">
          <div class="grid grid-cols-4 gap-4 p-4 box-content" [@channelAnimation]="profiles?.length">
            <div *ngFor="let p of profiles">
              <app-channel-card
                [channelName]="p.profile.Name"
                [channelPhoto]="p.profile.Picture"
                [channelProvider]="p.profile.Type"
                [showSubtype]="!p.profile.HasAppInstalled && p.profile.Subtype !== 'FacebookGroup'"
                [showAlert]="!p.profile.HasAppInstalled && p.profile.Subtype === 'FacebookGroup'"
                [channelSubtype]="p.profile.Subtype"
                [isSelected]="p.selected"
                (selectButton)="channelSelected(p)"
                (removeButton)="channelSelected(p)"
                [selectButtonDisabled]="
                  (!p.profile.HasAppInstalled && p.profile.Subtype === 'FacebookGroup') || p.profile.Selected
                "
                class="mx-auto"
                data-test="shared-privateprofile-channelcard">
                <a href="https://www.socialweaver.com/help/" target="_blank">How to Connect</a>
              </app-channel-card>
            </div>
          </div>
        </ng-scrollbar>
      </div>
      <!-- Private Channel List After Auth Footer -->
      <footer *ngIf="!loading" class="flex items-center justify-between mt-5">
        <!-- Channel Limit -->
        <div class="w-1/2">
          <app-channel-gauge [minValue]="NumberOfChannelsSelected" [maxValue]="MaxLicensedChannels">
          </app-channel-gauge>
        </div>
        <!-- Save Selected Channels -->
        <div class="save-selected">
          <app-button
            [loading]="savingFacebook"
            [disabled]="selectedProfilesNumber === 0 || isChannelsExceeded"
            color="primary"
            type="inline"
            minWidth="120"
            (buttonClick)="saveFacebookProfiles()"
            data-test="channels-save-btn">
            Save
          </app-button>
        </div>
      </footer>
    </ng-container>
    <!-- No Compatible Private Channels After Authentication -->
    <ng-container *ngIf="step === 2 && !hasChannels()">
      <div class="max-w-xl m-1 text-light-base-600 dark:text-dark-base-400">
        <!-- Instagram -->
        <div class="shadow rounded-md p-6 bg-white dark:bg-dark-base-100 p-4">
          <div *ngIf="data.provider === 'instagram'">
            <div
              class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-300 dark:bg-red-200 text-red dark:text-red-300 bg-opacity-25 dark:bg-opacity-10">
              404
            </div>
            <p class="mt-4">
              We didn't find any Instagram business profiles associated with the credentials you entered. Your Instagram
              channel should match the requirements below before it can be connected:
            </p>
            <div class="flow-root mt-4">
              <ul>
                <li>
                  <div class="relative pb-8">
                    <span
                      class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-light-base-100 dark:bg-dark-base-0"
                      aria-hidden="true"></span>
                    <div class="relative flex">
                      <div>
                        <div
                          class="h-8 w-8 rounded-full bg-light-base-100 dark:bg-dark-base-0 flex items-center justify-center ring-8 ring-white dark:ring-dark-base-600">
                          1
                        </div>
                      </div>
                      <div class="min-w-0 flex-1 ml-4">
                        <span class="font-medium">An Instagram business account</span>. See<a
                          href="https://www.socialweaver.com/help/"
                          target="_blank"
                          class="m-0">
                          how to convert your personal Instagram account to a business account</a
                        >
                        if you want to convert your existing personal profile.
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="relative">
                    <div class="relative flex">
                      <div>
                        <div
                          class="h-8 w-8 rounded-full bg-light-base-100 dark:bg-dark-base-0 flex items-center justify-center ring-8 ring-white dark:ring-dark-base-600">
                          2
                        </div>
                      </div>
                      <div class="min-w-0 flex-1 ml-4">
                        <span class="font-medium">Connected to a Facebook page that you are an admin of</span>. See
                        <a href="https://www.socialweaver.com/help/" target="_blank" class="m-0"
                          >how to connect your Instagram account to a Facebook page</a
                        >
                        for instructions.
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- Facebook -->
          <div *ngIf="data.provider === 'facebook'">
            <div
              class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-300 dark:bg-red-200 text-red dark:text-red-300 bg-opacity-25 dark:bg-opacity-10">
              404
            </div>
            <p class="mt-4">We didn't find any Facebook pages or groups associated with the credentials you entered.</p>
          </div>
          <!-- Linkedin -->
          <div
            *ngIf="data.provider === 'linkedin'"
            class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
            We didn't find any Linkedin company pages associated with the credentials you entered.
          </div>
          <!-- Other Providers -->
          <div
            *ngIf="data.provider !== 'facebook' && data.provider !== 'instagram'"
            class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
            No matching channels
          </div>
        </div>
        <div class="mt-4">
          <app-button (buttonClick)="goTo(1)" type="inline"> Back</app-button>
        </div>
      </div>
    </ng-container>
  </mat-tab>
  <!-- Public Channel Selection -->
  <mat-tab [tabIndex]="3">
    <ng-container>
      <div class="grid grid-cols-3 gap-1 w-[632px] min-w-[632px] max-w-[632px]">
        <app-dialog-card
          class="h-48 md:w-48 m-2"
          cardTitle="Facebook"
          [description]="
            facebookPublicSearchEnabled
              ? 'Search public pages.'
              : 'Connect a private Facebook channel to enable this option.'
          "
          buttonLabel="Search"
          [showSupportedChannels]="false"
          [facebookLogo]="true"
          [isDisabled]="!facebookPublicSearchEnabled"
          (connect)="searchFacebook()"></app-dialog-card>
        <app-dialog-card
          class="h-48 md:w-48 m-2"
          cardTitle="Instagram"
          [description]="
            instagramPublicSearchEnabled
              ? 'Search public profiles.'
              : 'Connect a private Instagram channel to enable this option.'
          "
          buttonLabel="Search"
          [showSupportedChannels]="false"
          [instagramLogo]="true"
          [isDisabled]="!instagramPublicSearchEnabled"
          (connect)="searchInstagram()"></app-dialog-card>
        <app-dialog-card
          class="h-48 md:w-48 m-2"
          cardTitle="LinkedIn"
          [description]="
            linkedInPublicSearchEnabled
              ? 'Search public pages.'
              : 'Connect a private LinkedIn channel to enable this option.'
          "
          buttonLabel="Search"
          [showSupportedChannels]="true"
          [linkedinLogo]="true"
          [isDisabled]="!linkedInPublicSearchEnabled"
          (connect)="searchLinkedIn()"></app-dialog-card>

        <app-dialog-card
          class="h-48 md:w-48 m-2"
          requestNew="true"
          cardTitle="Request Channel"
          description="Looking for something else? Request support for other networks."
          (connect)="request()"></app-dialog-card>
      </div>
    </ng-container>
  </mat-tab>
  <!-- Facebook Public Channels -->
  <mat-tab [tabIndex]="4">
    <app-alert *ngIf="isChannelsExceeded || isChannelCountExceeded(NumberOfChannelsSelected)" class="mb-5">
      <app-alert-error
        >The number of channels selected exceeds the number of channels permitted on current plan.
      </app-alert-error>
    </app-alert>
    <app-alert *ngIf="facebookError || generalError">
      <!-- Facebook -->
      <app-alert-error *ngIf="facebookError">{{ facebookError }}</app-alert-error>
      <!-- General -->
      <app-alert-error *ngIf="generalError">{{ generalError }}</app-alert-error>
    </app-alert>
    <mat-form-field appearance="outline" class="channel-search-field">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="publicSearchQuery" (keydown.enter)="searchPublicProfiles()" autocomplete="off" />
      <app-icon-button
        aria-label="Search"
        iconSize="24"
        class="leading-5 text-light-base-400 dark:text-dark-base-400"
        (buttonClick)="searchPublicProfiles()"></app-icon-button>
      <mat-hint *ngIf="activeChannel === 0" align="start"
        >Facebook search only returns pages. Use the page name or handle to search, i.e.
        <a (click)="publicSearchQuery = 'unhcr'; searchPublicProfiles()">unhcr</a>.
      </mat-hint>
    </mat-form-field>
    <div class="searching-public">
      <ng-scrollbar class="app-scrollbar" style="height: 280px; min-height: 280px">
        <!-- Placeholder -->
        <div class="placeholder-wrapper" *ngIf="!facebookPublicSearchLoading && !sources?.length">
          <div class="placeholder">
            <mat-icon class="mt-5" svgIcon="facebook-logo"></mat-icon>
            <div class="mb-5">Facebook</div>
          </div>
          <div class="pattern dot-scatter channel-dialog"></div>
        </div>
        <div
          class="relative"
          style="height: 280px; min-height: 280px"
          *ngIf="facebookPublicSearchLoading"
          data-test="channels-publicchannels-facebook-loader">
          <app-loader></app-loader>
        </div>
        <div
          class="grid grid-cols-4 gap-4 p-4 box-content"
          *ngIf="sources?.length"
          [@channelAnimation]="sources?.length">
          <div *ngFor="let source of sources">
            <app-channel-card
              *ngIf="source.source?.Type === 1"
              [channelName]="source.source.ScreenName"
              [channelNameUrl]="source.source.Url"
              [channelPhoto]="source.source.Picture"
              [channelProvider]="source.source.Type"
              [showProvider]="true"
              [isSelected]="source.selected && !source.source.AlreadyTracked"
              (selectButton)="sourceSelected(source)"
              (removeButton)="sourceSelected(source)"
              [selectButtonDisabled]="source.source.AlreadyTracked"
              class="mx-auto">
            </app-channel-card>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <footer class="flex items-center justify-between mt-5">
      <!-- Channel Limit -->
      <div class="w-1/2">
        <app-channel-gauge [minValue]="NumberOfChannelsSelected" [maxValue]="MaxLicensedChannels"></app-channel-gauge>
      </div>
      <!-- Save Selected Channels -->
      <div class="save-selected">
        <app-button
          [loading]="loading"
          [disabled]="!CanSaveSources()"
          color="primary"
          type="inline"
          minWidth="120"
          (buttonClick)="addSelectedSources()"
          data-test="channel-addchannel-button">
          Save
        </app-button>
      </div>
    </footer>
  </mat-tab>
  <!-- Twitter Public Channels -->
  <mat-tab [tabIndex]="5">
    <app-alert *ngIf="isChannelsExceeded || isChannelCountExceeded(NumberOfChannelsSelected)" class="mb-5">
      <app-alert-error
        >The number of channels selected exceeds the number of channels permitted on current plan.
      </app-alert-error>
    </app-alert>
    <app-alert *ngIf="twitterError || generalError">
      <!-- Twitter -->
      <app-alert-error *ngIf="twitterError">{{ twitterError }}</app-alert-error>
      <!-- General -->
      <app-alert-error *ngIf="generalError">{{ generalError }}</app-alert-error>
    </app-alert>
    <mat-form-field appearance="outline" class="channel-search-field">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="publicSearchQuery" (keydown.enter)="searchPublicProfiles()" autocomplete="off" />
      <app-icon-button
        aria-label="Search"
        iconSize="24"
        class="leading-5 text-light-base-400 dark:text-dark-base-400"
        (buttonClick)="searchPublicProfiles()"></app-icon-button>
      <mat-hint *ngIf="activeChannel === 1" align="start"
        >Use profile name or handle to search, i.e.
        <a (click)="publicSearchQuery = 'socialweaver'; searchPublicProfiles()">socialweaver</a>. Use
        <span class="font-mono">#</span> to search for hashtags, e.g.
        <a (click)="publicSearchQuery = '#marketing'; searchPublicProfiles()">#marketing</a>.
      </mat-hint>
    </mat-form-field>
    <div class="searching-public">
      <ng-scrollbar class="app-scrollbar" style="height: 280px; min-height: 280px">
        <!-- Placeholder -->
        <div class="placeholder-wrapper" *ngIf="!twitterPublicSearchLoading && !sources?.length">
          <div class="placeholder">
            <mat-icon class="mt-5" svgIcon="twitter-logo"></mat-icon>
            <div class="mb-5">Twitter</div>
          </div>
          <div class="pattern dot-scatter tw channel-dialog"></div>
        </div>
        <div
          class="relative"
          style="height: 280px; min-height: 280px"
          *ngIf="twitterPublicSearchLoading"
          data-test="channels-publicchannels-twitter-loader">
          <app-loader></app-loader>
        </div>
        <div
          class="grid grid-cols-4 gap-4 p-4 box-content"
          *ngIf="sources?.length"
          [@channelAnimation]="sources?.length">
          <div *ngFor="let source of sources">
            <app-channel-card
              *ngIf="source.source?.Type === 2 || source.source?.Subtype === 'PublicHashtag'"
              [channelName]="source.source.FriendlyName"
              [channelNameUrl]="source.source.ScreenName"
              [channelPhoto]="source.source.Picture"
              [channelProvider]="source.source.Type"
              [showProvider]="true"
              [isSelected]="source.selected && !source.source.AlreadyTracked"
              [showFollowers]="source.source?.Type === 2 && source.source?.Subtype !== 'PublicHashtag'"
              [showSubtype]="source.source?.Subtype === 'PublicHashtag'"
              [channelSubtype]="source.source?.Subtype"
              [channelFollowers]="source.source.FollowersCount"
              (selectButton)="sourceSelected(source)"
              (removeButton)="sourceSelected(source)"
              [selectButtonDisabled]="source.source.AlreadyTracked"
              class="mx-auto">
            </app-channel-card>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <footer class="flex items-center justify-between mt-5">
      <!-- Channel Limit -->
      <div class="w-1/2">
        <app-channel-gauge [minValue]="NumberOfChannelsSelected" [maxValue]="MaxLicensedChannels"></app-channel-gauge>
      </div>
      <!-- Save Selected Channels -->
      <div class="save-selected">
        <app-button
          [loading]="loading"
          [disabled]="!CanSaveSources()"
          color="primary"
          type="inline"
          minWidth="120"
          (buttonClick)="addSelectedSources()"
          data-test="channel-addchannel-button">
          Save
        </app-button>
      </div>
    </footer>
  </mat-tab>
  <!-- Instagram Public Channels -->
  <mat-tab [tabIndex]="6">
    <app-alert *ngIf="isChannelsExceeded || isChannelCountExceeded(NumberOfChannelsSelected)" class="mb-5">
      <app-alert-error
        >The number of channels selected exceeds the number of channels permitted on current plan.
      </app-alert-error>
    </app-alert>
    <app-alert *ngIf="instagramError || generalError">
      <!-- Instagram -->
      <app-alert-error *ngIf="instagramError">{{ instagramError }}</app-alert-error>
      <!-- General -->
      <app-alert-error *ngIf="generalError">{{ generalError }}</app-alert-error>
    </app-alert>
    <mat-form-field appearance="outline" class="channel-search-field">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="publicSearchQuery" (keydown.enter)="searchPublicProfiles()" autocomplete="off" />
      <app-icon-button
        aria-label="Search"
        iconSize="24"
        class="leading-5 text-light-base-400 dark:text-dark-base-400"
        (buttonClick)="searchPublicProfiles()"></app-icon-button>
      <mat-hint *ngIf="activeChannel === 2" align="start"
        >Instagram search only accepts usernames and returns a single exact match i.e.
        <a (click)="publicSearchQuery = 'doctorswithoutborders'; searchPublicProfiles()">doctorswithoutborders</a>.
      </mat-hint>
    </mat-form-field>
    <div class="searching-public">
      <ng-scrollbar class="app-scrollbar" style="height: 280px; min-height: 280px">
        <!-- Placeholder -->
        <div class="placeholder-wrapper" *ngIf="!instagramPublicSearchLoading && !sources?.length">
          <div class="placeholder">
            <mat-icon class="mt-5" svgIcon="instagram-logo"></mat-icon>
            <div class="mb-5">Instagram</div>
          </div>
          <div class="pattern dot-scatter ig channel-dialog"></div>
        </div>
        <div
          class="relative"
          style="height: 280px; min-height: 280px"
          *ngIf="instagramPublicSearchLoading"
          data-test="channels-publicchannels-instagram-loader">
          <app-loader></app-loader>
        </div>
        <div
          class="grid grid-cols-4 gap-4 p-4 box-content"
          *ngIf="sources?.length"
          [@channelAnimation]="sources?.length">
          <div *ngFor="let source of sources">
            <app-channel-card
              *ngIf="source.source?.Type === 6"
              [channelName]="source.source.ScreenName"
              [channelNameUrl]="source.source.Url"
              [channelPhoto]="source.source.Picture"
              [channelProvider]="source.source.Type"
              [showProvider]="true"
              [showFollowers]="true"
              [channelFollowers]="source.source.FollowersCount"
              [isSelected]="source.selected && !source.source.AlreadyTracked"
              (selectButton)="sourceSelected(source)"
              (removeButton)="sourceSelected(source)"
              [selectButtonDisabled]="source.source.AlreadyTracked"
              [showMediaCount]="false"
              [channelMediaCount]="source.source.MediaCount"
              class="mx-auto">
            </app-channel-card>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <footer class="flex items-center justify-between mt-5">
      <!-- Channel Limit -->
      <div class="w-1/2">
        <app-channel-gauge [minValue]="NumberOfChannelsSelected" [maxValue]="MaxLicensedChannels"></app-channel-gauge>
      </div>
      <!-- Save Selected Channels -->
      <div class="save-selected">
        <app-button
          [loading]="loading"
          [disabled]="!CanSaveSources()"
          color="primary"
          type="inline"
          minWidth="120"
          (buttonClick)="addSelectedSources()"
          data-test="channel-addchannel-button">
          Save
        </app-button>
      </div>
    </footer>
  </mat-tab>
  <mat-tab [tabIndex]="8">
    <app-alert *ngIf="isChannelsExceeded || isChannelCountExceeded(NumberOfChannelsSelected)" class="mb-5">
      <app-alert-error
        >The number of channels selected exceeds the number of channels permitted on current plan.
      </app-alert-error>
    </app-alert>
    <app-alert *ngIf="linkedInError || generalError">
      <!-- LinkedIn -->
      <app-alert-error *ngIf="linkedInError">{{ linkedInError }}</app-alert-error>
      <!-- General -->
      <app-alert-error *ngIf="generalError">{{ generalError }}</app-alert-error>
    </app-alert>
    <mat-form-field appearance="outline" subscriptSizing="fixed" class="channel-search-field">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="publicSearchQuery" (keydown.enter)="searchPublicProfiles()" autocomplete="off" />
      <app-icon-button
        aria-label="Search"
        iconSize="24"
        class="leading-5 text-light-base-400 dark:text-dark-base-400"
        (buttonClick)="searchPublicProfiles()"></app-icon-button>
      <mat-hint *ngIf="activeChannel === 3" align="start"
        >Use page or vanity name to search, i.e.
        <a (click)="publicSearchQuery = 'socialweaver'; searchPublicProfiles()">socialweaver</a>.
      </mat-hint>
    </mat-form-field>
    <div class="searching-public">
      <ng-scrollbar class="app-scrollbar" style="height: 280px; min-height: 280px">
        <!-- Placeholder -->
        <div class="placeholder-wrapper" *ngIf="!linkedInPublicSearchLoading && !sources?.length">
          <div class="placeholder">
            <mat-icon class="mt-5" svgIcon="linkedin-logo"></mat-icon>
            <div class="mb-5">LinkedIn</div>
          </div>
          <div class="pattern dot-scatter tw channel-dialog"></div>
        </div>
        <div
          class="relative"
          style="height: 280px; min-height: 280px"
          *ngIf="linkedInPublicSearchLoading"
          data-test="channels-publicchannels-linkedin-loader">
          <app-loader></app-loader>
        </div>
        <div
          class="grid grid-cols-4 gap-4 p-4 box-content"
          *ngIf="sources?.length"
          [@channelAnimation]="sources?.length">
          <div *ngFor="let source of sources">
            <app-channel-card
              *ngIf="source.source?.Type === 8"
              [channelName]="source.source.FriendlyName"
              [channelNameUrl]="source.source.Url"
              [channelPhoto]="source.source.Picture"
              [channelProvider]="source.source.Type"
              [showProvider]="true"
              [isSelected]="source.selected && !source.source.AlreadyTracked"
              [showFollowers]="source.source?.Type === 8"
              [showSubtype]="source.source?.Subtype === 'PublicHashtag'"
              [channelSubtype]="source.source?.Subtype"
              [channelFollowers]="source.source.FollowersCount"
              (selectButton)="sourceSelected(source)"
              (removeButton)="sourceSelected(source)"
              [selectButtonDisabled]="source.source.AlreadyTracked"
              class="mx-auto">
            </app-channel-card>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <footer class="flex items-center justify-between mt-5">
      <!-- Channel Limit -->
      <div class="w-1/2">
        <app-channel-gauge [minValue]="NumberOfChannelsSelected" [maxValue]="MaxLicensedChannels"></app-channel-gauge>
      </div>
      <!-- Save Selected Channels -->
      <div class="save-selected">
        <app-button
          [loading]="loading"
          [disabled]="!CanSaveSources()"
          color="primary"
          type="inline"
          minWidth="120"
          (buttonClick)="addSelectedSources()"
          data-test="channel-addchannel-button">
          Save
        </app-button>
      </div>
    </footer>
  </mat-tab>
</mat-tab-group>
