import {Component, EventEmitter, Output} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {AsyncMessageService} from "@shared/utils/async-message.service";
import {IntegrationService} from "@shared/utils/integration-service/integration-service.service";
import {Observable} from "rxjs";
import {IntegrationTypeEnum} from "../integrations.interface";

@UntilDestroy()
@Component({
  selector: "app-base-integration-type",
  template: "",
})
export abstract class BaseIntegrationTypeComponent {
  private windowPoll: any;
  public authenticationCancelled = new EventEmitter<any>();
  public windowReference: Window;

  @Output() connectedEvent: EventEmitter<IntegrationTypeEnum> = new EventEmitter<IntegrationTypeEnum>();
  @Output() disconnectEvent: EventEmitter<IntegrationTypeEnum> = new EventEmitter<IntegrationTypeEnum>();
  isAuthenticated: boolean;
  connecting: boolean;
  loading = true;
  error: string;

  abstract localConnect();

  abstract localLogout();

  abstract integrationTypeEnum: IntegrationTypeEnum;

  abstract getAuthenticationStartUrl(): Observable<string>;

  protected constructor(
    protected asyncMessageService: AsyncMessageService,
    protected integrationService: IntegrationService,
  ) {
    this.checkStatus();
  }

  checkStatus() {
    this.integrationService.updateObservables();
    this.integrationService.getIntegrationsConfigured().subscribe((x) => {
      if (this.integrationTypeEnum == IntegrationTypeEnum.Zapier) {
        return;
      }
      this.isAuthenticated = x.some((x) => x == this.integrationTypeEnum);
      this.loading = false;
      if (this.isAuthenticated) {
        this.localConnect();
      }
    });
  }

  connect() {
    this.connecting = true;

    this.getAuthenticationStartUrl().subscribe((authenticationStartUrl) => {
      this.windowReference = window.open(authenticationStartUrl, "", "width=600,height=700");
      this.windowPoll = setInterval(() => {
        if (this.windowReference.closed === true) {
          this.integrationService.updateObservables();
          this.loading = false;
          this.connecting = false;
          clearInterval(this.windowPoll);
          this.checkStatus();
        }
      }, 500);
    });
  }

  logout() {
    this.loading = true;
    this.connecting = false;

    this.integrationService.logout(this.integrationTypeEnum).subscribe(() => {
      this.isAuthenticated = false;
      this.localLogout();
      this.integrationService.updateObservables();
      this.disconnectEvent.emit(this.integrationTypeEnum);
      this.loading = false;
    });
  }
}
