import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {UploadEvent, UploadFile} from "@utils/file-upload/file-upload.directive";
import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {UserMediaService} from "@user/user-media.service";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {
  ContentMention,
  EditorModel,
  ILinkUpdatedModel,
  Link,
  LinkChangesModel,
  LinkEventEnum,
  MediaFile,
  MediaFileUploaded,
} from "@publisher/content.interface";
import {debounceTime, switchMap, take, tap} from "rxjs/operators";
import {ProfileLabelsFromId, ProfileTypes} from "@channel/profile-types.enum";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {ISelectedProfile} from "../composer.component";
import {Activity} from "@activity/activity.model";
import "quill-mention";
import {SuggestionsService} from "./suggestions.service";
import {ComposerEditor} from "./composer-editor";
import {User} from "@shared/user/user.interface";
import {ThemeService} from "@shared/theme/theme.service";
import {ComposerValidationService} from "../composer-validation/composer-validation.service";
import {environment} from "@environments/environment";
import {ComposerService, MediaFinishedUploading} from "../composer.service";
import {HashtagGroupModel} from "@shared/hashtag-groups/hashtag-group.interface";
import {AddHashtagGroupComponent} from "../add-hashtag-group/add-hashtag-group.component";
import {LanguageService} from "@utils/language-service/language.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  ShareToEmployeeComponent,
  ShareLinkedInOverlayData,
} from "@composer/share-to-emplyee/share-to-employee.component";
import {UrlMetadata} from "@shared/activity/activity.interface";
import {getTweetCharactersCount} from "@shared/utils/twitterCharactersLeft.function";
import {getTrackingUrl} from "@shared/utils/link.function";
import {PanelOverlay} from "@overlay-panel/panel-overlay";

@UntilDestroy()
@Component({
  selector: "app-composer-editor",
  templateUrl: "./composer-editor.component.html",
  styleUrls: ["./composer-editor.component.scss"],
})
export class ComposerEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() editorModel: EditorModel;
  @Input() initialText: string;
  @Input() initialMentions: ContentMention[];
  @Input() limitChars: boolean;
  @Input() provider: number;
  @Input() active = true;
  @Input() dialogRef: PanelOverlayRef<PanelOverlay>;
  @Input() placeholder = "What would you like to share?";
  @Input() activity: Activity;
  @Input() addPhoto = true;
  @Input() enableMentions: boolean;
  @Input() selectedProfiles: ISelectedProfile[];
  @Input() enableActions = true;
  @Input() showProviderLabel: boolean;
  @Input() includeComment = false;

  @Output() editorChange: EventEmitter<EditorModel> = new EventEmitter();
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() commentEditorChange: EventEmitter<EditorModel> = new EventEmitter();
  @Output() shareEmployeesChange: EventEmitter<ShareLinkedInOverlayData> = new EventEmitter();

  @Output() mediaClicked: EventEmitter<MediaFileUploaded> = new EventEmitter();
  @Output() videoClicked: EventEmitter<MediaFileUploaded> = new EventEmitter();

  private providersWithSpaceMentions = [ProfileTypes.Facebook, ProfileTypes.LinkedIn];
  public providerName: string;
  public focused = false;

  currentMentionListResults: any[] = [];
  public inputLanguage: string;
  model: EditorModel = {
    hashtagGroups: [],
  };

  uploadHandler = this.upload.bind(this);
  editor: ComposerEditor;
  commentEditor: ComposerEditor;

  modules = {
    mention: this.editorMentionsModule(),
  };

  videoUrl: string = null;

  isProd = environment.production;

  private editorReady: ReplaySubject<any> = new ReplaySubject(1);
  private editorReadySubscription: Subscription;

  private commentEditorReady: ReplaySubject<any> = new ReplaySubject(1);
  // private commentEditorReadySubscription: Subscription;
  private mentionSearchSubscription: Subscription;
  private mentionSearch$: Subject<any> = new Subject();
  public accountUsers: User[];

  constructor(
    private userMediaService: UserMediaService,
    private elRef: ElementRef,
    private suggestionsService: SuggestionsService,
    private overlayServiceHashtagGroup: PanelOverlayService<AddHashtagGroupComponent>,
    private themeService: ThemeService,
    public composerValidation: ComposerValidationService,
    public composerService: ComposerService,
    public languageService: LanguageService,
    private overlayServiceLinkedinShare: PanelOverlayService<ShareToEmployeeComponent>,
  ) {}

  ngOnInit() {
    if (this.provider == null) {
      this.providerName = "All";
    } else {
      this.providerName = ProfileTypes[this.provider].toString();
    }

    this.editorReadySubscription = this.editorReady
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.editor.onUrlsFoundOnText
          .pipe(debounceTime(300)) // don't call APIs for every (fast) keystroke
          .pipe(untilDestroyed(this))
          .subscribe((changeModel: LinkChangesModel) => {
            const providerToSend = this.provider == null ? ProfileTypes.Unknown : this.provider;
            this.composerService.newEditorLinks(providerToSend, changeModel, this.selectedProfiles);
          });

        this.editor.quillEditorUpdated.subscribe(() => {
          this.composerService.editorModelDataReady.emit();
        });
        if (this.active) {
          this.editor.quill.focus();
          this.active = true;
          this.focused = true;
        }
        this.composerService.quillMentionsModules.push(this.editor.quill.getModule("mention"));

        if (this.initialText) {
          this.editor.editModeFirstEdit = true;

          this.editor.setInitialText(this.initialText).then(() => {
            if (this.initialMentions) {
              const providerMentions = this.initialMentions.filter((x) => x.SourceType == this.provider);
              this.editor.addMentionsToEditor(providerMentions);
            }
          });
        }
      });

    // this.commentEditorReadySubscription = this.commentEditorReady
    //   .pipe(take(1))
    //   .pipe(untilDestroyed(this))
    //   .subscribe(() => {
    //     if (this.model.commentText) {
    //       this.commentEditor.setInitialText(this.model.commentText);
    //     }
    //   });

    this.handleMentionsSource();

    this.composerService.onMediaIsUploading.pipe(untilDestroyed(this)).subscribe((provider: number) => {
      if (provider == null || provider == ProfileTypes.Unknown) {
        this.loading.emit(true);
      }

      if (this.provider == provider) {
        this.loading.emit(true);
      }
    });

    this.composerService.onMediaFinishedUploading
      .pipe(untilDestroyed(this))
      .subscribe((mediaUploading: MediaFinishedUploading) => {
        if (mediaUploading?.provider == null || mediaUploading?.provider == ProfileTypes.Unknown) {
          this.loading.emit(false);
        }

        if (this.provider == mediaUploading?.provider) {
          this.loading.emit(false);
        }
      });

    this.composerService.linkUpdated.pipe(untilDestroyed(this)).subscribe((model) => {
      this.linkUpdated(model);
    });

    this.composerService.onClearMentions.pipe(untilDestroyed(this)).subscribe(() => {
      this.editor.removeMentionsFromEditor();
      this.composerService.removeMentionsFromEditor(ProfileTypes.Unknown);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.editorModel && changes.editorModel.currentValue) {
      this.setEditorModel(changes.editorModel.currentValue);
      if (this.model.commentText && this.commentEditor) {
        this.commentEditor.setInitialText(this.model.commentText);
      }
    }

    if (changes && changes.provider && changes.provider.currentValue) {
      this.provider = changes.provider.currentValue;
      if (this.provider == null) this.providerName = "All";
      else {
        this.providerName = ProfileTypes[this.provider].toString();
      }
    }

    if (changes && changes.selectedProfiles && changes.selectedProfiles.currentValue) {
      this.SetMentionBehaviourOnEditor();
    }

    if (this.editor && changes.rawText && changes.rawText.currentValue) {
      this.editor.editModeFirstEdit = true;
      this.editor.setInitialText(this.initialText);
    }
  }

  ngOnDestroy() {
    // const quillModule = this.editor.quill.getModule("mention");
    // quillModule.openMenu("");
    this.editor.unsubscribeFromSubscriptions();
    this.editorReadySubscription.unsubscribe();
    this.mentionSearchSubscription.unsubscribe();
  }

  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement) {
    if (this.elRef.nativeElement.contains(targetElement)) {
      this.clicked.emit();
    }

    if (targetElement.parentElement == null) {
      return;
    }

    const allEditors = [
      this.setActiveEditor(targetElement, ProfileTypes.TwitterAccount),
      this.setActiveEditor(targetElement, ProfileTypes.InstagramAccount),
      this.setActiveEditor(targetElement, ProfileTypes.LinkedIn),
      this.setActiveEditor(targetElement, ProfileTypes.Facebook),
    ];

    if (targetElement.closest(".editor") && this.provider == null) {
      this.editor.quill.focus();
      this.active = true;
      this.focused = true;
    } else if (allEditors.every((x) => x == false)) {
      this.focused = false;
    } else if (!allEditors.some((x) => x == true)) {
      this.active = false;
      this.focused = false;
    }
  }

  private setActiveEditor(element, provider: ProfileTypes): boolean {
    const channelName = ProfileLabelsFromId[provider].toLowerCase();
    const editor = element.closest(".editor-" + channelName);
    if (editor != null && this.provider == provider) {
      this.editor.quill.focus();
      this.active = true;
      this.focused = true;
      return true;
    }
    return false;
  }

  private setEditorModel(value: EditorModel) {
    this.model.TwitterUrlMetadata = value.TwitterUrlMetadata;
    this.model.link = value.link;
    this.model.commentText = value.commentText;
    this.model.Mentions = value.Mentions;
  }

  get providerLabel(): string {
    return ProfileLabelsFromId[this.provider];
  }

  public onUploadEvent(event: UploadEvent, originProvider: number): void {
    switch (event.status) {
      case "selected":
        {
          const files = event.files.map((file) => {
            return {
              preview: file.preview,
              MediaType: file.file.type,
              uploading: true,
              id: file.id,
              SizeBytes: file.file.size,
            };
          });

          this.composerService.handleUploadingImages(originProvider, files);
        }
        break;
      case "uploading":
        break;
      case "uploaded":
        this.composerService.handleUploadedEvent(originProvider, event);
        break;
      case "error":
        console.error(event);
        break;
    }
  }

  public upload(file: UploadFile): Observable<any> {
    if (file.file.type.startsWith("image")) {
      return this.userMediaService.uploadMediaFile(file.file, null, file.id);
    }
    return this.userMediaService.getVideoThumb(file.file).pipe(
      tap((thumb) => {
        this.composerService.setMediaFilePreview(this.provider, file, thumb);
      }),
      switchMap((thumb: Blob) => {
        return this.userMediaService.uploadMediaFile(file.file, thumb, file.id);
      }),
    );
  }

  public removeImage(index: number, originProvider: number) {
    this.composerService.removeImage(originProvider, index);
  }

  public removeImages(event: Event, originProvider: number) {
    this.composerService.removeImages(originProvider);
  }

  public addBindingCreated(editor: any) {
    this.editor = new ComposerEditor(
      editor,
      this.elRef.nativeElement,
      this.themeService,
      this.composerService,
      this.provider,
    );
    this.editorReady.next(true);
    this.editor.setInitialContent(this.model.editorContent);
    this.SetMentionBehaviourOnEditor();
  }

  public addBindingCreatedForCommentEditor(editor: any) {
    this.commentEditor = new ComposerEditor(
      editor,
      this.elRef.nativeElement,
      this.themeService,
      this.composerService,
      this.provider,
    );
    this.commentEditorReady.next(null);
    this.commentEditor.setInitialContent(this.model.commentEditorContent);
  }

  SetMentionBehaviourOnEditor() {
    if (this.editor == null) return;

    let allowMentionsWithSpace = false;

    if (this.provider == null) {
      allowMentionsWithSpace = this.selectedProfiles.every((x) =>
        this.providersWithSpaceMentions.some((providerWithSpace) => x.profile.Type == providerWithSpace),
      );
    } else {
      allowMentionsWithSpace = this.providersWithSpaceMentions.some((x) => x == this.provider);
    }

    this.editor.allowMentionsWithSpace = allowMentionsWithSpace;
  }

  public onContentChanged(response) {
    // console.log("OnContentChanged", {response});
    this.model.text = response.html;
    this.model.rawText = response.text;
    this.model.editorContent = response.content;
    const isTextEmpty = !response.text || response.text.length == 0;
    if (!isTextEmpty) this.inputLanguage = this.languageService.detectLanguageOnText(response.text[0]);
    else if (isTextEmpty) this.inputLanguage = "english";
    this.model.inputLanguage = this.inputLanguage;
    this.composerService.setEditorLanguage(this.provider, this.inputLanguage);
    this.model.links = this.composerService.getEditorToModify(this.provider)?.links;

    this.editorChange.emit(this.model);
  }

  public onEditorChanged(response) {
    // console.log({response});
    if (response.event == "text-change") {
      // console.log("OnTextChanged", response);
      this.editor.linkify(response.delta, response.oldDelta, response.source);

      // console.log("HTML IS" , response.html);
    }
  }

  public onCommentEditorChange(response) {
    if (this.includeComment && response != null) {
      this.model.commentText = this.stripDataFromHtml(response.html);
      this.model.commentEditorContent = response.content;
    } else {
      this.model.commentText = null;
    }

    this.commentEditorChange.emit(this.model);
  }

  shouldShowRemoveButton(link: Link) {
    if (this.selectedProfiles.length == 0) return true;

    if (
      this.provider == ProfileTypes.TwitterAccount ||
      ((this.provider == null || this.provider == ProfileTypes.Unknown) &&
        this.selectedProfiles.filter((x) => x.selected).every((x) => x.profile.Type == ProfileTypes.TwitterAccount))
    )
      return false;

    return true;
  }

  get isProviderInstagram(): boolean {
    return this.provider === ProfileTypes.InstagramAccount;
  }

  get isProviderLinkedIn(): boolean {
    return this.provider === ProfileTypes.LinkedIn;
  }

  get isProviderFacebook(): boolean {
    return this.provider === ProfileTypes.Facebook;
  }

  get isProviderTwitter(): boolean {
    return this.provider === ProfileTypes.TwitterAccount;
  }

  private stripDataFromHtml(text: string) {
    if (text == null) {
      return "";
    }
    return text.replace(/(data-.+?=".*?")/g, "");
  }

  removeEmployees() {
    this.composerService.clearEmployees(this.provider);
    if (this.provider == ProfileTypes.LinkedIn) this.composerService.clearEmployees(ProfileTypes.Unknown);
  }

  openHashtagGroupPanel(commentEditorTrigger = false) {
    this.overlayServiceHashtagGroup
      .open(AddHashtagGroupComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((hashtagGroup: HashtagGroupModel) => {
        if (hashtagGroup) {
          this.model.hashtagGroups.push(hashtagGroup);
          this.editorChange.emit(this.model);

          if (!commentEditorTrigger) this.editor.insertTextAtEnd(" " + hashtagGroup.Text);
          else {
            this.model.commentText = this.model.commentText + " " + hashtagGroup.Text;
            this.commentEditor.insertTextAtEnd(" " + hashtagGroup.Text);
          }
        }
      });
  }

  openLinkedInSharePanel() {
    const editor = this.composerService.getEditorToModify(this.provider);

    this.overlayServiceLinkedinShare
      .open(ShareToEmployeeComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        data: {
          sharing: editor.ShareOnBehalfOfEmployees,
          like: editor.LikeOnBehalfOfEmployees,
          excludedEmployees: editor.ExcludeEmployees,
          shareText: editor.LinkedInShareCommentary,
          includedEmployees: editor.IncludedEmployees,
          employeeGroupNames: editor.EmployeeGroupNames,
          employeeGroupsConfig: editor.EmployeeGroupsConfig,
        },
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((shareModel: ShareLinkedInOverlayData) => {
        this.shareEmployeesChange.emit(shareModel);
      });
  }

  linkUpdated(model: ILinkUpdatedModel) {
    console.log("linkUpdated callback: ", model.link.ProfileType, model.link.ProfileType);
    if (model.link.ProfileType != ProfileTypes.Unknown && model.link.ProfileType != this.provider) return;

    let lengthChange = 0;

    // console.log({Event: model.Event});
    // console.log(model.link);

    if (model.Event == LinkEventEnum.AddTracking) {
      if (!model.link.IsShorten) {
        lengthChange = getTrackingUrl(model.link).length - model.link.BaseUrl.length;
      }

      this.composerService.modifyTrackingUrlForAllEditors(model.link);
      this.editor.addTrackingToUrl(model.link);
    } else if (model.Event == LinkEventEnum.RemoveTracking) {
      // console.log("Removing tracking", model.link);

      if (!model.link.IsShorten) {
        lengthChange = model.link.BaseUrl.length - model.link.lastTrackingUrlState.length;
      }

      this.composerService.modifyTrackingUrlForAllEditors(model.link);
      this.editor.removeTrackingForUrl(model.link);
    } else if (model.Event == LinkEventEnum.UpdateTracking) {
      if (!model.link.IsShorten) {
        lengthChange = getTrackingUrl(model.link).length - model.link.lastTrackingUrlState.length;
      }

      this.composerService.modifyTrackingUrlForAllEditors(model.link);
      this.editor.updateTrackingUrl(model.link);
    } else if (model.Event == LinkEventEnum.Shorten) {
      this.composerService.updateShortenParametersForAllEditors(model.link);

      lengthChange = model.link.ShortenUrl.length - getTrackingUrl(model.link).length;

      // console.log(`Shortening ${model.link.ShortenUrl.length} - ${getTrackingUrl(model.link).length} = ${lengthChange}`);
    } else if (model.Event == LinkEventEnum.Unshorten) {
      this.composerService.updateShortenParametersForAllEditors(model.link);
      lengthChange = getTrackingUrl(model.link).length - model.link.ShortenUrl.length;
    } else if (model.Event == LinkEventEnum.ReshortenUrl) {
      lengthChange = model.link.ShortenUrl.length - model.link.lastShortenUrl.length;
      this.composerService.updateShortenParametersForAllEditors(model.link);
    }

    // console.log({editorModel : this.editorModel});
    // console.log({startPosition: model.link.startPosition});
    // console.log({lengthChange});
    // console.log(this.composerService.composerEditorsModel);
    // console.log({LinkProfileType : model.link.ProfileType});
    // console.log({Provider : this.provider});

    const shouldEditEditorIndex = [
      LinkEventEnum.AddTracking,
      LinkEventEnum.UpdateTracking,
      LinkEventEnum.RemoveTracking,
    ].some((x) => x == model.Event);

    this.composerService.updateMentionsIndex(
      this.provider,
      model.link.startPosition,
      lengthChange,
      shouldEditEditorIndex,
    );

    this.composerService.updateLinkFromEditor(this.provider, model.link, model.index, model.Event);

    this.composerService.onLinksMetadataModelChange.emit();
  }

  metadataUpdated(urlMetadata: UrlMetadata[], index: number) {
    this.composerService.updateLinkMetadataFromEditor(this.provider, urlMetadata, index);
  }

  removeLink(link: Link) {
    this.composerService.removeLinkFromEditor(this.provider, link, this.editorModel.text);
  }

  get twitterMessageLength(): number {
    if (this.model.text) {
      const result = getTweetCharactersCount(this.model.text);
      return result;
    }
    return 0;
  }

  private editorMentionsModule() {
    return {
      allowedChars: /^[A-Za-z\sÅÄÖåäö._!%&,'áéíú|+/()*-]*$/,
      positioningStrategy: "fixed",
      spaceAfterInsert: true,
      maxChars: 45,
      onSelect: (item, insertItem) => {
        const shouldDenotationCharacterBeEmpty = [ProfileTypes.Facebook, ProfileTypes.LinkedIn].some(
          (x) => x == this.currentProvider,
        );

        if (shouldDenotationCharacterBeEmpty) {
          item.denotationChar = "";
        }

        const text = this.editor.getEditorTextWithMentionValue();

        const textBetweenCursorAndBeginning = text.substring(0, this.editor.getCursorPosition());

        // console.log({textBetweenCursorAndBeginning});

        if (this.currentProvider === ProfileTypes.TwitterAccount) {
          const result = this.currentMentionListResults.filter((x) => x.id == item.id);

          if (result.length > 0) {
            item.value = result[0].screenName;
          }
        }

        item.sourcetype = this.currentProvider;

        item.newmention = true;

        let indexToBalance = 0;

        const shortenedLinks = this.composerService
          .getEditorToModify(this.currentProvider)
          .links.filter((x) => x.IsShorten);

        //verify if the link is currently in the text
        shortenedLinks.forEach((shortenLink) => {
          const trackingUrl = getTrackingUrl(shortenLink);
          if (textBetweenCursorAndBeginning.indexOf(trackingUrl) > -1) {
            indexToBalance -= trackingUrl.length;
            indexToBalance += shortenLink.ShortenUrl.length;
          }
        });

        const matchIndex = textBetweenCursorAndBeginning.lastIndexOf("@");
        // There are 2 potential outcomes:
        // 1. mention sign is somewhere in the middle of text
        // This is indicative of a user typing a mention dynamically
        // 2. mention sign is at the end of text
        // This is indicative of a user adding mention sign in front of existing word
        //
        // These 2 outcomes should be handled differently.

        if (textBetweenCursorAndBeginning.length - 1 == matchIndex) {
          // Case #2
          const textAfterMentionSign = text.substring(matchIndex);

          const matches = textAfterMentionSign.match(new RegExp("@(\\w+)"));
          if (matches && matches.length > 1) {
            const nextWord = matches[1];
            this.editor.quill.deleteText(matchIndex, nextWord.length + 1);
            item.editorindex = matchIndex;
            item.index = matchIndex + indexToBalance;
            insertItem(item);
          }
        } else {
          // Case #1
          item.editorindex = matchIndex;
          item.index = matchIndex + indexToBalance;

          insertItem(item);
        }

        this.editor.addMentionToBeObserved(matchIndex);

        this.currentMentionListResults = [];
      },
      renderItem: (item, searchTerm: string) => {
        // Create the outer div
        const div = document.createElement("div");
        div.className = "cql-list-item-inner overflow-hidden";
        div.setAttribute("data-test", "shared-mentions-dialog-item");

        // Create the image
        const img = document.createElement("img");
        img.src = item.picture ? item.picture : "/assets/images/no_company_logo.png";
        img.alt = item.value;

        // Create the text div
        const textDiv = document.createElement("div");
        textDiv.className = "whitespace-nowrap overflow-ellipsis overflow-hidden";
        textDiv.textContent = item.value;

        // Append the image and text div to the outer div
        div.appendChild(img);
        div.appendChild(textDiv);

        return div;
      },
      source: (searchTerm: string, renderList) => {
        // const spaces = searchTerm.split(" ").length;

        const text = this.editor.getEditorTextWithMentionAsSpace();

        const cursorPosition = this.editor.getCursorPosition();

        const textBetweenCursorAndBeginning = text.substring(0, cursorPosition);

        const matchIndex = textBetweenCursorAndBeginning.lastIndexOf("@");
        // There are 2 potential outcomes:
        // 1. mention sign is somewhere in the middle of text
        // This is indicative of a user typing a mention dynamically
        // 2. mention sign is at the end of text
        // This is indicative of a user adding mention sign in front of existing word
        //
        // These 2 outcomes should be handled differently.

        if (textBetweenCursorAndBeginning.length - 1 == matchIndex) {
          const textAfterMentionSign = text.substring(matchIndex);

          const matches = textAfterMentionSign.match(new RegExp("@(\\w+)"));
          if (matches && matches.length > 1) {
            const nextWord = matches[1];
            if (nextWord && this.enableMentions && this.active) {
              searchTerm = nextWord;
              this.mentionSearch$.next({searchTerm, renderList});
            }
          }
        } else {
          // Case #1
          let isValidCharacterBeforeMention = ["", " ", "\n", "\t"].some((x) => {
            if (matchIndex == 0) return true;

            return x == text[matchIndex - 1];
          });

          if (!isValidCharacterBeforeMention) {
            //When going into customize mode for some reason this character gets added before mentions and the last
            //validation is not able to catch it;
            isValidCharacterBeforeMention = text.charCodeAt(matchIndex - 1) == 65279;
          }

          if (this.enableMentions && this.active && isValidCharacterBeforeMention) {
            this.mentionSearch$.next({searchTerm, renderList});
          }
        }
      },
    };
  }

  isFileValidationValid(file: MediaFileUploaded, provider): boolean {
    if ((provider == null && this.hasInstagram) || this.isProviderInstagram) {
      return !file.uploading && file?.instagramValidation?.IsValid() === false;
    }

    if ((provider == null && this.hasFacebook) || this.isProviderFacebook) {
      return !file.uploading && file?.facebookValidation?.IsValid() === false;
    }

    if ((provider == null && this.hasTwitter) || this.isProviderTwitter) {
      return !file.uploading && file?.twitterValidation?.IsValid() === false;
    }
  }

  private handleMentionsSource() {
    this.mentionSearchSubscription = this.mentionSearch$
      .pipe(debounceTime(300))
      .pipe(untilDestroyed(this))
      .subscribe(({searchTerm, renderList}) => {
        if (!searchTerm) {
          renderList([], searchTerm);
        } else {
          this.suggestionsService
            .getMentions(searchTerm, this.currentProvider)
            .pipe(untilDestroyed(this))
            .subscribe((sources) => {
              if (this.active) {
                this.currentMentionListResults = sources.map((source) => ({
                  id: source.Identifier,
                  value: source.Name ?? source.ScreenName,
                  picture: source.Picture,
                  screenName: source.ScreenName,
                }));

                renderList(this.currentMentionListResults, searchTerm);
              }
            });
        }
      });
  }

  get currentProvider(): number {
    if (!this.provider) {
      const hasOnlyFacebook = this.selectedProfiles.every((p) => p.profile.Type === ProfileTypes.Facebook);
      const hasOnlyTwitter = this.selectedProfiles.every((p) => p.profile.Type === ProfileTypes.TwitterAccount);
      const hasOnlyInstagram = this.selectedProfiles.every((p) => p.profile.Type === ProfileTypes.InstagramAccount);
      const hasOnlyLinkedIn = this.selectedProfiles.every((p) => p.profile.Type === ProfileTypes.LinkedIn);

      if (hasOnlyFacebook) {
        return ProfileTypes.Facebook;
      }
      if (hasOnlyTwitter) {
        return ProfileTypes.TwitterAccount;
      }
      if (hasOnlyInstagram) {
        return ProfileTypes.InstagramAccount;
      }
      if (hasOnlyLinkedIn) {
        return ProfileTypes.LinkedIn;
      }
    }
    return this.provider;
  }

  get hasInstagram(): boolean {
    return (
      this.selectedProfiles &&
      this.selectedProfiles.some((p) => p.profile.Type === ProfileTypes.InstagramAccount && p.selected)
    );
  }

  get hasLinkedIn(): boolean {
    if (this.composerService.customizeMode) {
      return this.selectedProfiles && this.provider === ProfileTypes.LinkedIn;
    }
    return (
      this.selectedProfiles &&
      this.selectedProfiles.some((profile) => profile.profile.Type == ProfileTypes.LinkedIn && profile.selected)
    );
  }

  get hasFacebook(): boolean {
    return (
      this.selectedProfiles && this.selectedProfiles.some((p) => p.profile.Type === ProfileTypes.Facebook && p.selected)
    );
  }

  get hasTwitter(): boolean {
    return (
      this.selectedProfiles &&
      this.selectedProfiles.some((p) => p.profile.Type === ProfileTypes.TwitterAccount && p.selected)
    );
  }
}

export interface ComposerEditorImageChanged {
  MediaFiles: MediaFile[];
  provider: ProfileTypes;
}
