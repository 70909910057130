import {Component, Input, OnInit} from "@angular/core";
import {Comment} from "@activity/comments/comment.model";
import {FacebookPost} from "@activity/facebook-activity/facebook-post.model";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-facebook-reactions-list",
  templateUrl: "./facebook-reactions-list.component.html",
  styleUrls: ["./facebook-reactions-list.component.scss"],
})
export class FacebookReactionsListComponent implements OnInit {
  @Input() post: FacebookPost | Comment;
  @Input() size = "normal";

  constructor() {}

  ngOnInit() {}
}
