import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {HashtagGroupModel} from "@shared/hashtag-groups/hashtag-group.interface";
import {HashtagGroupService} from "@shared/hashtag-groups/hashtag-group.service";
import {PanelOverlay} from "@shared/overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PanelOverlayService} from "@shared/overlay-panel/panel-overlay.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ComposerCreateHashtagGroupComponent} from "@composer/add-hashtag-group/composer-create-hashtag-group/composer-create-hashtag-group.component";

@UntilDestroy()
@Component({
  selector: "app-add-hashtag-group",
  templateUrl: "./add-hashtag-group.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-510px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-510px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddHashtagGroupComponent extends PanelOverlay implements OnInit {
  hashtagGroups: HashtagGroupModel[];
  loading = true;

  constructor(
    dialogRef: PanelOverlayRef<AddHashtagGroupComponent>,
    private hashtagGroupService: HashtagGroupService,
    private cdr: ChangeDetectorRef,
    private panelOverlayService: PanelOverlayService<ComposerCreateHashtagGroupComponent>,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.hashtagGroupService
      .getAll()
      .pipe(untilDestroyed(this))
      .subscribe((hashtagGroups: HashtagGroupModel[]) => {
        this.hashtagGroups = hashtagGroups;
        this.loading = false;
      });
  }

  createHashtagGroup() {
    this.panelOverlayService
      .open(ComposerCreateHashtagGroupComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        panelClass: "composer-create-hashtag-group",
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((hashtagGroup: HashtagGroupModel) => {
        if (hashtagGroup != null) {
          this.hashtagGroups.push(hashtagGroup);
          this.cdr.markForCheck();
        }
      });
  }

  selectHashtagGroup(hashtagGroup: HashtagGroupModel) {
    this.dialogRef.close(hashtagGroup);
  }

  close() {
    this.dialogRef.close();
  }
}
