<header
  class="flex items-center h-full px-3 border-0 border-b border-solid box-border bg-light-base-100 dark:bg-dark-base-0 border-light-base-300 dark:border-dark-base-300">
  <div class="flex lg:hidden h-full items-center -ml-2 mr-2" *ngIf="showMenuToggle">
    <app-icon-button
      icon="align-justified"
      class="text-light-base-500 dark:text-dark-base-500 cursor-pointer"
      buttonType="button"
      iconSize="20"
      (buttonClick)="menu.emit()"></app-icon-button>
  </div>
  <div class="hidden lg:flex h-full items-center mr-2" *ngIf="showCollapseToggle">
    <app-icon-button
      icon="sidenav"
      iconSize="20"
      class="text-light-base-500 dark:text-dark-base-500 hover:text-light-base-600 dark:hover:text-dark-base-600 cursor-pointer"
      buttonType="button"
      [matTooltip]="sidenavService.isSidenavCollapsed ? 'Show Sidenav' : 'Collapse Sidenav'"
      matTooltipPosition="below"
      (buttonClick)="collapseSidenav.emit()"></app-icon-button>
  </div>
  <div class="flex h-full items-center" *ngIf="pageTitle">
    <!-- Title -->
    <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400" [innerHTML]="pageTitle"></div>
  </div>
  <div class="flex-1 flex justify-between h-full items-center">
    <ng-content></ng-content>
  </div>
</header>
