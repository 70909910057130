// helper methods for CSV export
//
// based on https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
// with these changes:
//  - split the test into a spec file,
//  - add filtering by header attributes (only export the attributes in the header)
//  - follow https://datatracker.ietf.org/doc/html/rfc4180 and escape double quotes
//

export class CsvExporter {
  csvEscape(src: string): string {
    if (src !== null) {
      return '"' + src.toString().replace(/"/g, '""') + '"';
    }
    return '"n/a"';
  }

  // given an array of objects, return a CSV string
  // the first object is treated as a header, only attributes listed in the header will
  // be included in the output string.
  convertObjectArrayToCSV(header, objArray): string {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    // output header
    for (const index in header) {
      if (str != "") str += ",";
      str += this.csvEscape(header[index]);
    }
    str += "\r\n";

    // output data
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in header) {
        if (line != "") line += ",";
        line += this.csvEscape(array[i][index]);
      }
      str += line + "\r\n";
    }

    return str;
  }

  public exportToCsvFile(headers, items, fileTitle, download) {
    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);

    const csv = this.convertObjectArrayToCSV(headers, jsonObject);

    if (!download) return csv;

    const exportFilename = fileTitle + ".csv" || "export.csv";

    const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});

    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
