import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {IZapierProcessedProfile} from "@shared/composer/composer.component";
import {MatSnackBar} from "@angular/material/snack-bar";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-preview-zapier",
  templateUrl: "./preview-zapier.component.html",
  styleUrls: ["./preview-zapier.component.scss"],
})
export class PreviewZapierComponent implements OnInit {
  @Input() processedProfiles: IZapierProcessedProfile[];

  @Output() Next = new EventEmitter<any>();
  @Output() Finished = new EventEmitter<any>();

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  OnProfileSelected(profile: IZapierProcessedProfile) {
    profile.selected = !profile.selected;
  }

  OnNextClick() {
    if (this.getSelectedChannels()) {
      this.Next.emit();
    } else {
      this.snackBar.open("Please make a channel selection first.", null, {
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }

  OnFinished() {
    this.Finished.emit();
  }

  getSelectedChannels() {
    return this.processedProfiles.filter((x) => x.selected).length;
  }
}
