<app-activity-header
  [activity]="activity"
  [showBadge]="showBadge"
  [permalink]="activity.FacebookPost.Permalink"
  [showSource]="showSource"></app-activity-header>

<app-link-preview
  *ngIf="!activity.FacebookPost.IsSharing && linkPreview && (photos?.length === 0 || !photos)"
  [linkPreview]="linkPreview"
  [activity]="activity"
  [mediaWidth]="mediaWidth"
  [showDescription]="showDescription"
  [showDomain]="true"
  [mediaQuality]="mediaQuality"></app-link-preview>

<app-image-attachment
  *ngIf="
    (activity.FacebookPost.IsSharing && sharing && photos?.length) ||
    (!activity.FacebookPost.IsSharing && photos?.length)
  "
  [image]="photos"
  [album]="activity.FacebookPost.album"
  [activity]="activity"
  [imageQuality]="mediaQuality"
  [imageWidth]="mediaWidth"></app-image-attachment>

<app-video-attachment
  *ngIf="!activity.FacebookPost.IsSharing && video"
  [video]="video"
  [activity]="activity"
  [showVolumeControl]="showVolumeControl"></app-video-attachment>

<app-facebook-reactions
  *ngIf="
    (activity.FacebookPost.ReactionCount || activity.CommentsCount || activity.FacebookPost.SharesCount) &&
    showReactions
  "
  [activity]="activity"></app-facebook-reactions>

<app-facebook-activity
  *ngIf="activity.FacebookPost.IsSharing && !sharing && activity.FacebookPost.Original"
  [activity]="activity.FacebookPost.Original"
  [sharing]="true"></app-facebook-activity>
