import {Pipe, PipeTransform} from "@angular/core";
import {DateRangePreset} from "./time-selector/time-picker/time-struct.interface";

@Pipe({
  name: "toPresetName",
})
export class ToPresetNamePipe implements PipeTransform {
  DateRangePresetLabels = [
    "Today",
    "Last 7 days",
    "Last 4 weeks",
    "Last 3 months",
    "Last 12 months",
    "Month to date",
    "Quarter to date",
    "Year to date",
    "All time",
    "Custom",
  ];

  transform(preset: DateRangePreset): string {
    return this.DateRangePresetLabels[preset];
  }
}
