import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {LinkPreview} from "../attachments/link-preview/link-preview.component";
import {Attachment, FacebookLink} from "../activity.interface";
import {Album, ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {FbScrapeResponse} from "@utils/url/url-metadata.interface";
import {MediaFileUploaded} from "@publisher/content.interface";
import {Activity} from "../activity.model";
import {getDomain} from "@utils/link.function";

export class FacebookPost {
  Link?: FacebookLink;

  constructor(
    public SharesCount?: number,
    public ReactionCount?: number,
    public LikesCount?: number,
    public AngryCount?: number,
    public WowCount?: number,
    public SadCount?: number,
    public HahaCount?: number,
    public LoveCount?: number,
    public Story?: any,
    public Permalink?: string,
    public Attachments: Attachment[] = [],
    public IsSharing?: boolean,
    public Original?: Activity,
  ) {
    const linkAttachment = Attachments?.find((x) => x.Type == "share");

    if (linkAttachment)
      this.Link = {
        Url: linkAttachment.UnshimmedUrl,
        Caption: linkAttachment.Title,
        Description: linkAttachment.Description,
        Picture: linkAttachment.Media && linkAttachment.Media.Image ? linkAttachment.Media.Image.Src : null,
        Domain: getDomain(linkAttachment.UnshimmedUrl),
      };
  }

  get videoAttachment(): VideoAttachment {
    if (this.Attachments.length) {
      return {
        type: this.Attachments[0].Type,
        preview: this.Attachments[0].Media.Image.Src,
        source: this.Attachments[0].Media.Source,
        height: this.Attachments[0].Media.Image.Height,
        width: this.Attachments[0].Media.Image.Width,
      };
    }
  }

  get photosAttachment(): ImageAttachment[] {
    if (this.Attachments.length) {
      const type = this.Attachments[0].Type;
      if (type === "album" || type === "new_album") {
        return this.getPhotosfromAttachment(this.Attachments[0].SubAttachments);
      } else if (type === "photo") {
        return this.getPhotosfromAttachment(this.Attachments);
      }
    }
  }

  get album(): Album {
    if (this.Attachments.length) {
      const type = this.Attachments[0].Type;
      if ((type === "album" || type === "new_album") && !this.Attachments[0].Target) {
        return {
          title: this.Attachments[0].Title,
          url: this.Attachments[0].Url,
        };
      }
    }
  }

  get linkPreview(): LinkPreview {
    if (this.Link) {
      return {
        url: this.Link.Url,
        title: this.Link.Caption,
        description: this.Link.Description ? this.Link.Description.trim() : "",
        image: this.Link.Picture,
        domain: this.Link.Domain,
      };
    }
  }

  private getPhotosfromAttachment(attachments: Attachment[]): ImageAttachment[] {
    if (attachments.length) {
      return attachments
        .filter((attachment) => {
          return attachment.Media;
        })
        .map((attachment) => {
          return {
            src: attachment.Media.Image.Src,
            thumb: attachment.Media.Image.Src,
            height: attachment.Media.Image.Height,
            width: attachment.Media.Image.Width,
          };
        });
    }
  }

  public setPhotosAttachment(images: MediaFileUploaded[]) {
    if (images.every((image) => image.uploaded)) {
      this.Attachments = images
        .filter((image: MediaFileUploaded) => image.EnabledForFacebook)
        .map((image) => {
          return {
            Type: "photo",
            Media: {
              Image: {
                Height: 0,
                Width: 0,
                Src: image.PreviewUrl,
              },
            },
          };
        });
    }
  }

  public setVideoAttachment(video: MediaFileUploaded) {
    this.Attachments = [
      {
        Type: "video",
        Media: {
          Image: {
            Height: 0,
            Width: 0,
            Src: video.PreviewUrl,
          },
          Source: video.BlobUrl,
        },
      },
    ];
  }

  public setLink(link: FbScrapeResponse) {
    if (link == null) return;

    this.Link = {
      Url: link.url,
      Caption: link.title,
      Description: link.description,
      Picture: link.image && link.image.length ? link.image[0].url : null,
      Domain: getDomain(link.url),
    };
  }
}
