"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es6.object.define-property");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
var _extractCashtagsWithIndices = _interopRequireDefault(require("./extractCashtagsWithIndices"));

// Copyright 2018 Twitter, Inc.
// Licensed under the Apache License, Version 2.0
// http://www.apache.org/licenses/LICENSE-2.0
function _default(text) {
  var cashtagsOnly = [],
    cashtagsWithIndices = (0, _extractCashtagsWithIndices["default"])(text);
  for (var i = 0; i < cashtagsWithIndices.length; i++) {
    cashtagsOnly.push(cashtagsWithIndices[i].cashtag);
  }
  return cashtagsOnly;
}
module.exports = exports.default;