import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  @Input() source: string;

  constructor() {}

  ngOnInit() {}
}
