<div
  *ngFor="let profile of profiles"
  style="display: flex"
  [style.border]="profile.Selected ? 'solid red' : ''"
  (click)="selectChannel(profile)"
  data-test="stream-channelselect-button">
  <div style="flex-grow: 1">
    <app-profile-picture
      [picture]="profile.Picture"
      [hasChannel]="true"
      pictureSize="25"
      style="flex-grow: 1"></app-profile-picture>
  </div>
  <div style="flex-grow: 1">
    <span *ngIf="profile.Name" class="item-name" target="_blank"
      >{{ profile.Name }}
      <mat-icon class="verified w-5 h-5 leading-5" *ngIf="profile.IsVerified" svgIcon="twitter-verified"></mat-icon>
    </span>
  </div>
</div>

<app-button type="inline" (buttonClick)="saveSelectedChannels()">Save changes</app-button>
