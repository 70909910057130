import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class FaviconService {
  private alertFavicon = "/assets/favicons/notification.png";
  private defaultFavicon = "/assets/favicons/favicon.png";

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setAlertFavicon() {
    this.setCustomFavicon(this.alertFavicon);
  }

  setDefaultFavicon() {
    this.setCustomFavicon(this.defaultFavicon);
  }

  private setCustomFavicon(faviconUrl: string) {
    const linkElement: HTMLLinkElement = this.document.querySelector("#favicon") as HTMLLinkElement;
    linkElement.href = faviconUrl;
  }
}
