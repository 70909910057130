<mat-form-field
  (click)="onFocus()"
  appearance="outline"
  [ngClass]="{'w-full': type === 'block', 'w-auto': type === 'inline'}">
  <mat-label>Time Zone</mat-label>
  <input type="text" matInput [formControl]="timezone" [matAutocomplete]="auto" (blur)="OnBlurTimezone()" />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onTimezoneChange()"
    (blur)="OnBlurTimezone()"
    (click)="onFocus()"
    class="timezone-menu">
    <mat-option
      *ngFor="let timezone of filteredOptions | async"
      [value]="timezone"
      class="text-sm text-light-base-600 dark:text-dark-base-600"
      title="{{ timezone.LongName }} {{ timezone.ShortName }}">
      {{ timezone.LongName }} ({{ timezone.ShortName }})
    </mat-option>
  </mat-autocomplete>
  <button
    matSuffix
    mat-icon-button
    type="button"
    title="Select Timezone"
    class="select-timezone !flex !items-center !justify-center">
    <app-icon icon="selector" class="text-light-base-400 dark:text-dark-base-400 w-5 h-5 leading-5"></app-icon>
  </button>
</mat-form-field>
