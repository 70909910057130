<div
  data-test="prompt-dialog-title"
  class="text-lg font-semibold text-light-base-600 dark:text-dark-base-600 px-6 pt-6">
  {{ options.title }}
</div>
<mat-dialog-content>
  <p class="leading-5 text-light-base-500/90 dark:text-dark-base-500/90 pt-0">{{ options.subtitle }}</p>
  <app-alert class="mt-4">
    <app-alert-error *ngIf="options.warning">{{ options.warningMessage }} </app-alert-error>
  </app-alert>
</mat-dialog-content>
<div class="flex items-center mt-6 px-6 pb-6">
  <div class="ml-auto space-x-4">
    <app-basic-button type="inline" [mat-dialog-close]="false" data-test="prompt-dialog-discard-changes-button">
      {{ options.buttons.cancel }}
    </app-basic-button>
    <app-button
      type="inline"
      class="ml-2"
      autofocus
      [mat-dialog-close]="true"
      data-test="prompt-dialog-apply-changes-button">
      {{ options.buttons.confirm }}
    </app-button>
  </div>
</div>
