import {Component, Input, OnInit} from "@angular/core";
import {Content, Tag} from "@publisher/content.interface";

import {UntilDestroy} from "@ngneat/until-destroy";
import {PublishedContent} from "@notifications/notification.interface";

@UntilDestroy()
@Component({
  selector: "app-publisher-activity-footer",
  templateUrl: "./publisher-activity-footer.component.html",
  styleUrls: ["./publisher-activity-footer.component.scss"],
})
export class PublisherActivityFooterComponent implements OnInit {
  @Input() tagSize: string;
  @Input() showTags: boolean;
  @Input() showFooter: boolean;
  @Input() content: Content | PublishedContent;

  constructor() {}

  ngOnInit() {}

  // PublishedContent has no Tags array
  getTags(): Tag[] {
    if ("Tags" in this.content) return this.content.Tags;
    else return [];
  }
}
