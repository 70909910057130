<div
  class="relative flex flex-col bg-white dark:bg-dark-base-50 overflow-hidden border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md box-border min-h-[400px] max-h-[400px] h-[400px]">
  <!-- Loading -->
  <div
    *ngIf="loading"
    class="h-1 top-0 left-0 right-0 absolute overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25">
    <div
      class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
  </div>
  <div
    *ngIf="loading"
    class="absolute inset-0 flex flex-col items-center justify-center h-[400px] text-light-base-400 dark:text-dark-base-400">
    loading...
  </div>
  <div [id]="elementId"></div>
</div>
