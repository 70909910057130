import {Directive, Input} from "@angular/core";
import {EmployeeGroupPermissionsEnum, User} from "@shared/user/user.interface";
import {BaseRoleValidator} from "../base-role-validator";

@Directive({
  selector: "[appEmployeeGroupRoleValidator]",
})
export class EmployeeGroupRoleValidatorDirective extends BaseRoleValidator {
  @Input() employeeGroupId: string;

  public UserHasPermission(user: User, permission: string) {
    const employeeGroupPermission = EmployeeGroupPermissionsEnum[permission];
    return user.EmployeeGroupRoles.some(
      (x) => x.EmployeeGroupId == this.employeeGroupId && x.Permissions.includes(employeeGroupPermission),
    );
  }
}
