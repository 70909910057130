import {ExternalProfileInfo} from "@auth/auth.interface";
import {AccountSubType, ProfileTypes} from "@shared/channel/profile-types.enum";
import {Timezone} from "@shared/publisher/content.interface";
import {SortingEnum, SwFieldSorting} from "@utils/filter/filter.model";

export interface User {
  AcceptingInvitation: boolean;
  AcceptTerms: boolean;
  AcceptTermsAt: string;
  AccountId: string;
  EmailConfirmed: boolean;
  FirstName: string;
  GDPR_AcceptTerms: boolean;
  GDPR_AcceptTermsAt: number;
  HasTrialed: boolean;
  Id: string;
  Image: any;
  IsAdmin: boolean;
  IsSoleOwner: boolean;
  LastName: string;
  PlanType: string;
  SubscriptionState: string;
  TimezoneId: string;
  TimezoneName: string;
  Token: string;
  RefreshToken: string;
  UserGroupRoles: any; // TODO: expose an array of nested types...
  email: string;
  name: string;
  user_hash: string;
  user_id: string;
  Theme: Theme;
  IsDemoAdmin: boolean;
  HexColor: string;
  Devices?: UserDevice[];
  TokenExpirationDate?: string;
  Selected: boolean;
  HasDevices: boolean;
  HasEnabledDevices: boolean;
  code: string;
  state: string;
  RecordSession: boolean;
  SupportSessionId?: string;
  SupportSessionExpirationDate?: Date;
  ZapierUserToken?: string;
  IsPublishingPaused: boolean;
  PublishingPausedBy: string;
  PublishingPausedStartTime: string;
  AnalyticsWidgetsState: string;
  IsEngagementChannelNew: boolean;
  EngagementChannelInstallError: string;
  ChannelsRoles: ChannelRoleDto[];
  EmployeeGroupRoles: EmployeeGroupRoleDto[];
  GlobalRole: string;
  GlobalPermissions: GlobalPermissionEnum[];
  LastLoggedInAt?: Date;

  IsTfaEnabled?: boolean;
  IsPasswordCorrect?: boolean;
  TfaAccessKey?: string;
  TwoFactorRecoveryCode?: string;
}

export enum EmployeeGroupPermissionsEnum {
  ShareContent,
  ViewGroup,
  AssignEmployeesToGroups,
}

export enum ChannelPermissionsEnum {
  Publish,
  ViewChannel,
  ViewAnalytics,
}

export enum GlobalPermissionEnum {
  ManageAccount,
  ManageBilling,
  ManageChannels,
  ManageUsers,
  ManageGroups,
  ManageEmployees,
}

export interface EmployeeGroupRoleDto {
  EmployeeGroupId: string;
  EmployeeGroupName: string;
  EmployeeGroupRoleName: string;
  EmployeesCount: number;
  Permissions: EmployeeGroupPermissionsEnum[];
}

export interface ChannelRoleDto {
  ChannelId: string;
  ChannelName: string;
  ChannelType: ProfileTypes;
  ChannelImage: string;
  ChannelRoleName: string;
  ChannelLocation: string;
  ChannelScreenName: string;
  ChannelSubtype: AccountSubType;

  Permissions: ChannelPermissionsEnum[];
}

export interface UserDevice {
  Id: string;
  Name: string;
  Selected?: boolean;
  NotificationsEnabled: boolean;
}

export interface Team {
  TeamId: string;
  TeamName: string;
  Users: User[];
}

export interface UserSettings {
  AccountName?: string;
  FirstName?: string;
  Image?: string;
  InformSubprocessorChanges?: boolean;
  LastName?: string;
  PhoneNumber?: string;
  ReceivePromotions?: boolean;
  RecordSession?: boolean;
  TimezoneInfo?: Timezone;
  ExternalLogins?: ExternalProfileInfo[];
  IsContentRandomizationEnabled?: boolean;
  IsUpdateMetadataBeforePublishingEnabled?: boolean;
}

export class NotificationSettings {
  PublishSuccessfulTypeNotificationEnabled?: any;
  PublishFailedTypeNotificationEnabled?: any;
  InboxConversationAssignedToMeNotificationEnabled?: any;
  InboxConversationMentionNotificationEnabled?: any;
  InboxConversationNewConversationNotificationEnabled: any;
}

export class MediaUploadModel {
  PutUrl: string;
  GetUrl: string;
  ContentType: string;
}

export enum NotificationBehaviourEnum {
  Web,
  Mobile,
  Email,
}

export enum Theme {
  Light = 0,
  Dark = 1,
}

export interface PrivacySettings {
  RecordSession: boolean;
}

export interface AssignEmployeeGroupRoleToUserModel {
  EmployeeGroupId: string;
  UserId: string;
  EmployeeGroupRoleName: string;
}

export interface GetUsersRequestModel {
  UserFieldSorting: SwFieldSorting;
  PageIndex: number;
  PageSize: number;
}
