<div class="flex flex-col">
  <!-- Linked Pages -->
  <div class="flex flex-row items-center justify-between mt-4 first:mt-0" *ngFor="let profile of linkedChannels">
    <div class="flex-1 flex flex-row items-center">
      <app-profile-picture [picture]="profile.Picture" [hasChannel]="true" pictureSize="32"></app-profile-picture>
      <div>
        <div class="ml-2 font-medium text-light-base-600 dark:text-dark-base-400">
          {{ profile.Name }}
        </div>
        <div class="ml-2 text-xs text-light-success-100">Linked</div>
      </div>
    </div>
    <!-- Remove -->
    <div>
      <a>Remove</a>
    </div>
  </div>
  <!-- Failed Pages -->
  <div class="flex flex-row items-center justify-between pt-4" *ngFor="let profile of profilesWithError">
    <div class="flex-1 flex flex-row items-center">
      <app-profile-picture [picture]="profile.Picture" [hasChannel]="true" pictureSize="32"></app-profile-picture>
      <div>
        <div class="ml-2 font-medium text-light-base-600 dark:text-dark-base-400">
          {{ profile.Name }}
        </div>
        <div class="ml-2 text-xs text-red dark:text-red-300">Not Linked Yet. Check Facebook.</div>
      </div>
    </div>
  </div>
  <!-- Add Linked Pages -->
  <div class="flex flex-row items-center justify-between pt-4">
    <div class="flex flex-row items-center text-light-base-400 dark:text-dark-base-400 cursor-pointer">
      <a (click)="addLinkedPage()">Add Linked Page</a>
    </div>
  </div>
</div>
