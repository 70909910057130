export interface TimeStruct {
  hour: number;
  minute: number;
  second: number;
}

export interface TimeFrameRange {
  StartDate: Date;
  EndDate: Date;
  SelectedDatePreset?: DateRangePreset;
}

export enum DateRangePreset {
  Today,
  Last7Days,
  Last4Weeks,
  Last3Months,
  Last12Months,
  MonthToDate,
  QuarterToDate,
  YearToDate,
  AllTime,
  Custom,
}
