import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {VideoAttachment} from "../video-attachment/video-attachment.component";
import {Activity} from "@activity/activity.model";
import {UserMediaService} from "@shared/user/user-media.service";
import {ImageAttachment} from "../image-attachment/image-attachment.interface";
import {stripHtml} from "@shared/utils/strip-html.function";

export interface CarouselItem {
  image?: string;
  video?: VideoAttachment;
}

import {UntilDestroy} from "@ngneat/until-destroy";
import {ImagePreviewService} from "@activity/attachments/image-preview/image-preview.service";

@UntilDestroy()
@Component({
  selector: "app-carousel-attachment",
  templateUrl: "./carousel-attachment.component.html",
  styleUrls: ["./carousel-attachment.component.scss"],
})
export class CarouselAttachmentComponent implements OnChanges {
  @Input() items: CarouselItem[];
  @Input() activity: Activity;
  @Input() showIcon: boolean;
  @Input() showControls: boolean;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showVolumeControl: boolean;
  @Input() downloadUrl: string = null;
  @Input() documentTitle: string = null;
  @Input() clickable: boolean;

  slide = 0;

  constructor(
    private imagePreviewService: ImagePreviewService,
    private userMediaService: UserMediaService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.items = changes.items.currentValue;
    }
  }

  next() {
    if (this.slide < this.items.length) {
      this.slide = this.slide + 1;
    }
  }

  previous() {
    if (this.slide != 0) {
      this.slide = this.slide - 1;
    }
  }

  notInViewport(index: number) {
    return !(index >= this.slide - 1 && index <= this.slide + 1);
  }

  getCarouselAttachmentPosition(index) {
    if (index == this.slide) return 0;
    if (index > this.slide) return (index - this.slide) * 100;
    return (this.slide - index) * -100;
  }

  open(index: number): void {
    const images: ImageAttachment[] = this.items
      .filter((x) => x.image)
      .map((x) => {
        return {
          src: x.image,
          thumb: x.image,
          caption: this.activity.Text,
        };
      });

    if (images.some((x) => !(x.height > 0) || !(x.width > 0))) {
      const promises = [];

      for (const photo of images) {
        promises.push(this.userMediaService.getMediaDimensions(photo.src));
      }

      Promise.all(promises).then((x) => {
        for (const photo of images) {
          const result = x.find((x) => x.url == photo.src);

          photo.height = result.height;

          photo.width = result.width;
        }

        this.imagePreviewService.open(images, index, stripHtml(this.activity.Text), this.downloadUrl);
      });
    } else {
      this.imagePreviewService.open(images, index, stripHtml(this.activity.Text), this.downloadUrl);
    }
  }
}
