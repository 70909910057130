import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {Activity} from "../activity.model";
import {Reaction, UrlMetadata} from "../activity.interface";
import {ReactionService} from "../reactions/reaction.service";
import {IProfile, IProfileResponse} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";
import {ProfileSelectorOutput} from "../profile-selector/profile-selector.component";
import {ActivityOverlayResponse, ActivityService} from "../activity.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Tag} from "@shared/publisher/content.interface";
import {ShareToEmployeeControllerService} from "@shared/composer/share-to-emplyee/share-to-employee-controller.service";

@UntilDestroy()
@Component({
  selector: "app-activity-footer",
  templateUrl: "./activity-footer.component.html",
  styleUrls: ["./activity-footer.component.scss"],
})
export class ActivityFooterComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() tags: Tag[];
  @Output() retweetShare = new EventEmitter<IRetweetEvent>();
  @Output() openFacebookShareComposer = new EventEmitter<IFacebookShareEvent>();
  @Output() openSyndicationShareComposer = new EventEmitter<ISyndicationShareEvent>();

  commentsOpen = false;
  defaultProfiles: IProfileResponse;
  hasReactions: any;

  constructor(
    private profileService: ProfileService,
    private reactionService: ReactionService,
    private changeDetector: ChangeDetectorRef,
    private activityService: ActivityService,
    private shareToEmployeeController: ShareToEmployeeControllerService,
  ) {}
  toggleComments() {
    this.commentsOpen = !this.commentsOpen;
  }

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
        this.hasReactions = this.buildReactionsForProviderObject();
      });

    this.activityService.facebookShareResponseReceived
      .pipe(untilDestroyed(this))
      .subscribe((model: ActivityOverlayResponse) => {
        if (model.Activity.Id !== model.Activity.Id) {
          return;
        }

        if (model.Response && model.Response.Successful) {
          this.activity.incrementShareCount();
        }
      });

    this.activityService.retweetResponseReceived
      .pipe(untilDestroyed(this))
      .subscribe((model: ActivityOverlayResponse) => {
        if (model.Activity.Id !== this.activity.Id) {
          return;
        }
        if (model.Response?.Successful) {
          this.hasReactions["twitter"]["share"] = model.Response.Successful;
          this.activity.incrementShareCount();
          this.activity.addReaction(model.Response.Reaction);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activity?.currentValue) {
      this.activity = changes.activity.currentValue;
      this.activity.ReactionChanged.pipe(untilDestroyed(this)).subscribe(() => {
        if (this.hasReactions[this.activity.provider]) {
          this.hasReactions[this.activity.provider]["like"] = this.hasReaction("Like", this.activity.provider);
          this.changeDetector.markForCheck();
        }
      });
    }
  }

  likeToggle(provider: string) {
    if (!this.hasReaction("Like", provider)) {
      this.like(provider);
    } else {
      this.unlike(provider);
    }
  }

  like(provider: string) {
    const reaction = this.buildLikeReaction(provider);
    this.activity.addReaction(reaction);
    this.activity.incrementLikeCount();
    this.reactionService
      .like(reaction.ProfileExternalId, reaction.ActivityId)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: () => {
          this.activity.removeReaction(reaction);
          this.activity.decrementLikeCount();
          // TODO: show error message
        },
      });
  }

  unlike(provider: string) {
    const reaction = this.buildLikeReaction(provider);
    const hasReaction = this.activity.findReaction(reaction);
    this.activity.removeReaction(hasReaction);
    this.activity.decrementLikeCount();

    this.reactionService
      .unlike(reaction.ProfileExternalId, reaction.ActivityId)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: () => {
          if (hasReaction) {
            this.activity.addReaction(reaction);
            this.activity.incrementLikeCount();
          }
          // TODO: show error message
        },
      });
  }

  private hasReaction(reactionType, provider): boolean {
    if (this.defaultProfiles[provider]) {
      const profileExternalId = this.defaultProfiles[provider].ExternalId;
      return this.activity.hasReaction(reactionType, profileExternalId);
    }
  }

  private buildLikeReaction(provider: string): Reaction {
    const profileExternalId = this.defaultProfiles[provider].ExternalId;
    return {
      ReactionType: "Like",
      ProfileExternalId: profileExternalId,
      ActivityId: this.activity.Id,
    };
  }

  replyOrRetweet(action: string) {
    if (action === "retweet") {
      if (this.hasReaction("Share", "twitter")) {
        this.hasReactions["twitter"]["share"] = false;
        this.undoRetweet();
        return;
      }
    }

    this.retweetShare.emit({
      action,
      activity: this.activity,
      profile: this.defaultProfiles.twitter,
    });
  }

  onProfileSelected(profileSelected: ProfileSelectorOutput) {
    this.defaultProfiles[profileSelected.provider] = profileSelected.profile;

    this.hasReactions = this.buildReactionsForProviderObject();
  }

  buildReactionsForProviderObject() {
    return {
      facebook: {
        like: this.hasReaction("Like", "facebook"),
      },
      twitter: {
        like: this.hasReaction("Like", "twitter"),
        share: this.hasReaction("Share", "twitter"),
      },
    };
  }

  undoRetweet() {
    if (this.defaultProfiles === undefined) {
      return;
    }
    const profileExternalId = this.defaultProfiles.twitter.ExternalId;
    const reaction = {
      ReactionType: "Share",
      ProfileExternalId: profileExternalId,
      ActivityId: this.activity.Id,
    };
    const hasReaction = this.activity.findReaction(reaction);
    this.activity.removeReaction(hasReaction);
    this.activity.decrementShareCount();
    this.reactionService
      .unshare(reaction.ProfileExternalId, reaction.ActivityId)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: () => {
          if (hasReaction) {
            this.activity.addReaction(reaction);
            this.activity.incrementShareCount();
          }
          // TODO: show error message
        },
      });
  }

  shareToEmployees() {
    this.shareToEmployeeController.shareLinkedInPost(this.activity);
  }

  share(type: string) {
    if (type === "facebook") {
      const profile = this.defaultProfiles[type];
      this.openFacebookShareComposer.emit({
        profile: profile,
        activity: this.activity,
      });
    } else if (type === "syndication") {
      const text = this.activity.SyndicationItem.Summary + " " + this.activity.SyndicationItem.Links[0].Uri;
      this.openSyndicationShareComposer.emit({
        text: text,
        urlMetadata: this.activity.UrlMetadata,
      });
    }
  }
}

export interface IRetweetEvent {
  action: string;
  activity: Activity;
  profile: IProfile;
}

export interface IFacebookShareEvent {
  activity: Activity;
  profile: IProfile;
}

export interface ISyndicationShareEvent {
  text: string;
  urlMetadata: UrlMetadata[];
}
