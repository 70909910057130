import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {FacebookComposerValidation} from "../FacebookComposerValidation";
import {GeneralComposerValidation} from "../GeneralComposerValidation";
import {InstagramComposerValidation} from "../InstagramComposerValidation";
import {TwitterComposerValidation} from "../TwitterComposerValidation";
import {LinkedInComposerValidation} from "../LinkedInComposerValidation";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-validation-message",
  templateUrl: "./validation-message.component.html",
  styleUrls: ["./validation-message.component.scss"],
})
export class ValidationMessageComponent implements OnChanges {
  @Input() customizationMode: boolean;
  @Input() regularMode: boolean;
  @Input() provider: number;

  @Input() GeneralValidation?: GeneralComposerValidation;
  @Input() FacebookValidation?: FacebookComposerValidation;
  @Input() TwitterValidation?: TwitterComposerValidation;
  @Input() InstagramValidation?: InstagramComposerValidation;
  @Input() LinkedInValidation?: LinkedInComposerValidation;

  constructor() {
    this.GeneralValidation = new GeneralComposerValidation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.GeneralValidation?.currentValue) {
      this.GeneralValidation = changes.GeneralValidation.currentValue;
    }

    if (changes.FacebookValidation?.currentValue) {
      this.FacebookValidation = changes.FacebookValidation.currentValue;
    }

    if (changes.TwitterValidation?.currentValue) {
      this.TwitterValidation = changes.TwitterValidation.currentValue;
    }

    if (changes.InstagramValidation?.currentValue) {
      this.InstagramValidation = changes.InstagramValidation.currentValue;
    }

    if (changes.LinkedInValidation?.currentValue) {
      this.LinkedInValidation = changes.LinkedInValidation.currentValue;
    }
  }
}
