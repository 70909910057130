<div
  class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0"
  data-test="composer-edit-metadata-container-div">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400">Edit Video</div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-2.5"></app-icon-button>
    </app-header-right>
  </app-header>
  <div class="flex flex-row min-h-[calc(100%-60px)] w-[520px]">
    <div class="flex w-full h-full">
      <div class="flex-1 p-3">
        <section
          class="relative flex flex-col h-full w-[480px] w-max-[480px] shadow rounded-md p-5 bg-white dark:bg-dark-base-100 box-border">
          <div class="flex w-full h-full">
            <!--  -->
          </div>

          <form [formGroup]="form" class="flex flex-col">
            <!-- URL Image Upload -->
            <div class="py-4">
              <label>
                <div
                  class="flex items-center justify-center border-2 border-dashed border-light-base-300 dark:border-dark-base-300 text-light-base-400 dark:text-dark-base-400 bg-transparent dark:bg-transparent p-4 rounded hover:bg-light-base-200 dark:hover:bg-white-alpha-50 cursor-pointer">
                  <app-icon
                    icon="photo"
                    class="inline-flex w-5 h-5 text-light-base-400 dark:text-dark-base-400 mr-1"></app-icon>
                  Upload Image
                </div>
                <input
                  class="hidden"
                  type="file"
                  appFileUpload
                  accept="image/jpeg, image/png"
                  (events)="onUploadEvent($event)"
                  [uploadHandler]="uploadHandler" />
              </label>

              <div>
                <img
                  style="max-width: 400px"
                  data-test="composer-editor-attached-media-item"
                  *ngIf="form.value.thumbnailUrl"
                  [src]="form.value.thumbnailUrl"
                  alt="something went wrong with the image" />
              </div>
            </div>
            <app-button [loading]="loading" type="block" buttonType="submit" (click)="submit()">Save</app-button>
          </form>
        </section>
      </div>
    </div>
  </div>
</div>
