import {Component, EventEmitter, Input, Output} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";
import {IDisabledComponent} from "@utils/base-role-validator";
import {IconableButton} from "../basic-button/IconableButton";

@UntilDestroy()
@Component({
  selector: "app-icon-button",
  templateUrl: "./icon-button.component.html",
  styleUrls: ["./icon-button.component.scss"],
})
export class IconButtonComponent extends IconableButton implements IDisabledComponent {
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() iconSize = 24;
  @Input() customId: string;
  @Input() buttonType: string;
  @Output() public buttonClick: EventEmitter<Event> = new EventEmitter();

  constructor() {
    super();
  }
}
