import {Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {SubscriptionService} from "@shared/subscription/subscription.service";
import {IUsage} from "@shared/subscription/subscription.interface";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-channel-limit",
  templateUrl: "./channel-limit.component.html",
  styleUrls: ["./channel-limit.component.scss"],
})
export class ChannelLimitComponent implements OnInit {
  currentUsage: IUsage;

  constructor(
    public dialogRef: MatDialogRef<ChannelLimitComponent>,
    private subscriptionService: SubscriptionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptionService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.currentUsage = x.Usage;
      });
  }

  UpgradePlan() {
    this.router.navigate(["/settings/plans"]);
    this.dialogRef.close({
      upgradeClicked: true,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
