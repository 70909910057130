export function getVideoThumbFromUrl(url: string) {
  return new Promise<Blob>(function (resolve) {
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.preload = "metadata";
    video.src = url;
    video.muted = true;
    video["playsInline"] = true;

    if (url == null) resolve(new Blob());

    video.ontimeupdate = function () {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const circle = new Path2D();
      const radius = 48;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      // Circle
      circle.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      context.fillStyle = "rgba(0, 0, 0, 0.75)";
      context.fill(circle);
      context.lineWidth = 2;
      context.strokeStyle = "#bbbbbb";
      context.stroke(circle);
      context.fillStyle = "#ffffff";
      // Triangle
      context.beginPath();
      context.moveTo(centerX - 12, centerY - 24);
      context.lineTo(centerX - 12, centerY + 24);
      context.lineTo(centerX + 24, centerY);
      context.fill();
      context.save();
      canvas.toBlob((blob) => {
        if (blob && blob.size > 1024) {
          resolve(blob);
        }
      });
      video.pause();
    };

    video.play();
  });
}
