export enum ProfileTypes {
  Unknown = 0,
  Facebook = 1,
  TwitterAccount = 2,
  // GooglePlusPerson = 3,
  // KeywordSource = 4,
  // YouTubeChannel = 5,
  InstagramAccount = 6,
  SyndicationFeed = 7,
  LinkedIn = 8,
}

export const ProfileLabels = {
  Facebook: {
    label: "Facebook",
    id: 1,
  },
  TwitterAccount: {
    label: "Twitter",
    id: 2,
  },
  InstagramAccount: {
    label: "Instagram",
    id: 6,
  },
  SyndicationFeed: {
    label: "Feed",
    id: 7,
  },
  LinkedIn: {
    label: "LinkedIn",
    id: 8,
  },
};

export const ProfileLabelsFromId = [
  "Unknown",
  "Facebook",
  "Twitter",
  "*Google NOT USED",
  "*Keyword NOT USED",
  "*YouTube NOT USED",
  "Instagram",
  "Feed",
  "LinkedIn",
];

export enum AccountSubType {
  Undefined = 0,

  FacebookPage = 1,

  FacebookUser = 2,

  FacebookGroup = 3,

  TwitterHomeTimeline = 4,

  TwitterUserTimeline = 5,

  TwitterMentionsTimeline = 6,

  FacebookInstagramPage = 7,

  FacebookInstagramBusinessDiscoveryPage = 8,

  FacebookInstagramPageMentions = 9,

  FacebookInstagramPageTags = 10,

  LinkedInUser = 11,

  LinkedInPage = 12,

  TwitterAll = 13,

  InstagramAll = 14,

  PublicHashtag = 15,
}
