<div
  class="relative rounded-md bg-light-base-0 dark:bg-dark-base-50 text-white-base-600 dark:text-dark-base-600 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90">
  <!-- Loading -->
  <div
    *ngIf="loading"
    data-test="activity-loader"
    class="h-1 top-0 left-0 right-0 absolute overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25 z-50 rounded-t-md">
    <div
      class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
  </div>
  <!-- Activity Header -->
  <ng-content select="[role=header]"></ng-content>
  <!-- Activity Body -->
  <app-facebook-activity
    *ngIf="activity?.FacebookPost"
    [activity]="activity"
    [showSource]="showSource"
    [showReactions]="showReactions"
    [mediaWidth]="mediaWidth"
    [showBadge]="showBadge"
    [showDomain]="showDomain"
    [showDescription]="showDescription"
    [mediaQuality]="mediaQuality"
    [showVolumeControl]="showVolumeControl">
  </app-facebook-activity>
  <app-twitter-activity
    *ngIf="activity?.Tweet"
    [activity]="activity"
    [showSource]="showSource"
    [showBadge]="showBadge"
    [mediaWidth]="mediaWidth"
    [showDomain]="showDomain"
    [showDescription]="showDescription"
    [mediaQuality]="mediaQuality"
    [showVolumeControl]="showVolumeControl">
  </app-twitter-activity>
  <app-instagram-activity
    *ngIf="activity?.InstagramMedia"
    [activity]="activity"
    [showSource]="showSource"
    [showBadge]="showBadge"
    [showCarouselControls]="showCarouselControls"
    [showCarouselIcon]="showCarouselIcon"
    [mediaWidth]="mediaWidth"
    [mediaQuality]="mediaQuality"
    [showVolumeControl]="showVolumeControl">
  </app-instagram-activity>
  <app-syndication-activity
    *ngIf="activity?.SyndicationItem"
    [activity]="activity"
    [mediaWidth]="mediaWidth"
    [mediaQuality]="mediaQuality">
  </app-syndication-activity>

  <app-linkedin-activity
    *ngIf="activity?.LinkedInPost"
    [activity]="activity"
    [showBadge]="showBadge"
    [mediaWidth]="mediaWidth"
    [mediaQuality]="mediaQuality"
    [showDomain]="showDomain"
    [showDescription]="showDescription"
    [showVolumeControl]="showVolumeControl"
    [isRepost]="isRepost"
    [showSource]="showSource">
  </app-linkedin-activity>

  <!-- Activity Footer -->
  <ng-container *ngIf="showFooter">
    <app-activity-footer
      *ngIf="activity"
      [activity]="activity"
      (retweetShare)="onRetweetShare($event)"
      (openFacebookShareComposer)="onOpenFacebookShareComposer($event)"
      (openSyndicationShareComposer)="OnOpenSyndicationShareComposer($event)"></app-activity-footer>
    <!-- Activity Comments -->
    <app-facebook-comments
      *ngIf="activity?.FacebookPost && activity.Comments.length"
      [comments]="activity.Comments"
      [activity]="activity"></app-facebook-comments>
    <app-instagram-comments
      *ngIf="activity?.InstagramMedia && activity.Comments.length"
      [comments]="activity.Comments"
      [activity]="activity"></app-instagram-comments>
  </ng-container>
  <ng-content select="[role=footer]"></ng-content>
  <ng-content></ng-content>
</div>
