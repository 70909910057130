<div class="bg-white dark:bg-dark-base-100 overflow-hidden shadow rounded-md mt-5 mb-10">
  <div class="px-6 pt-5 pb-6">
    <header class="settings-card-header py-5">
      <h2 class="text-lg leading-6 font-medium text-light-base-600 dark:text-dark-base-400">Add-on Plans</h2>
      <p class="mt-1 text-light-base-400 dark:text-dark-base-400">
        Expand the capacity of the base plan above with additional Users, Channels, or Employee Advocates.
      </p>
    </header>
    <form [formGroup]="form" (submit)="changeAddOnPlans()">
      <div class="grid grid-cols-4 py-5">
        <div class="col-start-2">Included</div>
        <div class="col">Add-On</div>
        <div class="col">Total</div>
      </div>
      <div class="grid grid-cols-4" *ngIf="showAddOnUsers()">
        <div>Users</div>
        <div>{{ subscription.BaseLicensedUsers }}</div>
        <div>
          <mat-form-field appearance="outline" class="w-1/2">
            <input
              matInput
              type="number"
              formControlName="AddOnLicensedUsers"
              autocomplete="off"
              min="0"
              minlength="0"
              maxlength="3"
              required
              [(ngModel)]="subscription.Usage.AddOnLicensedUsers" />
          </mat-form-field>
        </div>
        <div>{{ subscription.MaxLicensedUsers }}</div>
      </div>
      <div class="grid grid-cols-4" *ngIf="showAddOnChannels()">
        <div class="col">Channels</div>
        <div class="col">{{ subscription.BaseLicensedChannels }}</div>
        <div class="col">
          <mat-form-field appearance="outline" class="w-1/2">
            <input
              matInput
              type="number"
              formControlName="AddOnLicensedChannels"
              autocomplete="off"
              min="0"
              minlength="0"
              maxlength="3"
              required
              [(ngModel)]="subscription.Usage.AddOnLicensedChannels" />
          </mat-form-field>
        </div>
        <div class="col detail">{{ subscription.MaxLicensedChannels }}</div>
      </div>
      <div class="grid grid-cols-4" *ngIf="showAddOnAdvocates()">
        <div class="col">Employees</div>
        <div class="col">{{ subscription.BaseLicensedAdvocates }}</div>
        <div class="col">
          <mat-form-field appearance="outline" class="w-1/2">
            <input
              matInput
              type="number"
              formControlName="AddOnLicensedAdvocates"
              autocomplete="off"
              min="0"
              minlength="0"
              maxlength="4"
              required
              [(ngModel)]="subscription.Usage.AddOnLicensedAdvocates" />
          </mat-form-field>
        </div>
        <div class="col">{{ subscription.MaxLicensedAdvocates }}</div>
      </div>
      <div>
        <button [disabled]="!subscription.hasChangedAddOnQuantity()" type="submit">Save Changed Addons</button>
        <!-- TODO: couldn't get the app-button to submit this form, so the layout is crappy.
        <app-button-stroked minWidth="122"
          buttonType="button" type="submit">Save Changed Addons</app-button-stroked>
        -->
      </div>
    </form>
  </div>
</div>
