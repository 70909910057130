import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Optional} from "@angular/core";
import {User} from "@shared/user/user.interface";
import {UserService} from "@shared/user/user.service";
import {fromEvent} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ButtonComponent} from "@shared/layout/button/button.component";
import {StrokedButtonComponent} from "@layout/stroked-button/stroked-button.component";
import {MatSelect} from "@angular/material/select";
import {IconButtonComponent} from "@layout/icon-button/icon-button.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatOption} from "@angular/material/core";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {BasicButtonComponent} from "@layout/basic-button/basic-button.component";
import {MatInput} from "@angular/material/input";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";

@UntilDestroy()
@Directive()
export abstract class BaseRoleValidator implements AfterViewInit, OnInit {
  @Input() permission: string;

  @Input() hideElement: boolean;

  @Input() permissionIndicator: boolean;

  @Input() hideSelectArrow: boolean;

  @Input() showLock: boolean;

  @Input() disableElement: boolean;

  @Input() toastOnClick: boolean;

  @Input() tooltipTitle: string;

  private disabledComponents: IDisabledComponent[] = [];

  constructor(
    public userService: UserService,
    public el: ElementRef,
    public snackbar: MatSnackBar,
    public cdr: ChangeDetectorRef,
    @Optional() buttonComponent: ButtonComponent,
    @Optional() strokedButtonComponent: StrokedButtonComponent,
    @Optional() basicButtonComponent: BasicButtonComponent,
    @Optional() matSelect: MatSelect,
    @Optional() iconButtonComponent: IconButtonComponent,
    @Optional() matCheckbox: MatCheckbox,
    @Optional() matOption: MatOption,
    @Optional() matInput: MatInput,
    @Optional() matSlideToggle: MatSlideToggle,
    @Optional() matButtonToggle: MatButtonToggle,
    @Optional() matButtonToggleGroup: MatButtonToggleGroup,
  ) {
    this.disabledComponents = [
      buttonComponent,
      strokedButtonComponent,
      iconButtonComponent,
      basicButtonComponent,
      matSelect,
      matCheckbox,
      matOption,
      matSlideToggle,
      matInput,
      matButtonToggle,
      matButtonToggleGroup,
    ];
  }
  ngOnInit(): void {
    if (this.disableElement) {
      this.disableComponent();
    }
  }

  public abstract UserHasPermission(user: User, permission: string);

  ngAfterViewInit(): void {
    if (this.toastOnClick) {
      const el = this.el.nativeElement;
      const elementToUse = el.parentNode ?? el;
      fromEvent(elementToUse, "click", {capture: true})
        .pipe(untilDestroyed(this))
        .subscribe((ev: any) => {
          if (this.toastOnClick && el == ev.target.closest("[permission]")) {
            const user = this.userService.userObservable.value;
            if (!this.UserHasPermission(user, this.permission)) {
              ev.stopImmediatePropagation();
              this.snackbar.open("You don't have sufficient permissions to perform this operation.");
            }
          }
        });
    }

    if (this.disableElement) {
      this.el.nativeElement.disabled = true;
      this.el.nativeElement.classList.add("permission");
    }

    if (this.showLock) {
      this.el.nativeElement.classList.add("locked");
    }

    if (this.hideElement) {
      this.el.nativeElement.style.display = "none";
    }

    if (this.permissionIndicator) {
      this.el.nativeElement.style.display = "none";
    }

    if (this.hideSelectArrow) {
      const elementSearched = this.el.nativeElement.querySelector(".mat-select-arrow-wrapper");
      if (elementSearched) elementSearched.style.display = "none";
    }

    this.userService.userObservable.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user == null) return;

      const userHasPermission = this.UserHasPermission(user, this.permission);

      if (userHasPermission) {
        this.el.nativeElement.disabled = false;
        setTimeout(() => this.enableComponent(), 400);
        const elementSearched = this.el.nativeElement.querySelector(".mat-select-arrow-wrapper");
        if (elementSearched) elementSearched.style.display = "";
        this.el.nativeElement.classList.remove("locked");
      } else if (this.permissionIndicator) {
        this.el.nativeElement.style.display = "";
      }
    });
  }

  disableComponent() {
    const component = this.disabledComponents.find((x) => x != null);
    if (component) {
      component.disabled = true;
      const type = component as ButtonComponent;
      if (this.showLock && type) {
        type.icon = "lock";
      }
    }
  }

  enableComponent() {
    const component = this.disabledComponents.find((x) => x != null);
    if (component) {
      component.disabled = false;
      const type = component as ButtonComponent;
      if (this.showLock && type) {
        type.icon = type.initialIcon;
      }
      this.cdr.markForCheck();
    }
  }
}

export interface IDisabledComponent {
  disabled: boolean;
}
