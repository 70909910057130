import {stripHtmlForQuillText} from "./strip-html.function";
import {getTweetLength} from "twitter-text/dist/index";

//function returns number of characters left for a tweet.
//The max number of characters in a tweet is 280 characters
//If you have 281 characters in the text it will return -1
//If you have 280 characters in the text it will return 0
//If you have 279 characters in the text it will return 1
export function getTweetCharactersCountBalance(tweetText: string): number {
  const result = getTweetCharactersCount(tweetText);
  return 280 - result;
}

//Returns the total number of characters on a tweet.
export function getTweetCharactersCount(tweetText: string): number {
  let strippedText = stripHtmlForQuillText(tweetText);
  const regex = new RegExp("\\n\\r", "g");
  strippedText = strippedText.replace(regex, "\n");
  //SW-2195 getTweetLength incorrectly accounts for BOM characters.
  strippedText = strippedText.replace(/\uFEFF/gm, "");
  const result = getTweetLength(strippedText);
  return result;
}
