import {Component, Input, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges} from "@angular/core";
import Autolinker from "autolinker";
import {CommentService} from "../../comment.service";
import {Comment} from "../../comment.model";
import {Activity} from "../../../activity.model";
import {ReactionService} from "../../../reactions/reaction.service";
import {Reaction} from "../../../activity.interface";
import {CommentResponse} from "../../comment-response.interface";
import {IProfileResponse} from "../../../../channel/profile.interface";
import {ProfileService} from "../../../../channel/profile.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-facebook-comment",
  templateUrl: "./facebook-comment.component.html",
  styleUrls: ["./facebook-comment.component.scss"],
})
export class FacebookCommentComponent implements OnInit, OnChanges {
  @Input() comment: Comment;
  @Input() parentComment: Comment;
  @Input() activity: Activity;
  @Input() isLast: boolean;
  @Input() level = 1;
  toggle: boolean;
  defaultProfiles: IProfileResponse;
  commentsPageSize = 10;
  hideLoadMore: boolean;
  text: string;
  hasLike: boolean;
  isAttachmentInViewPort = true;

  constructor(
    private profileService: ProfileService,
    private commentService: CommentService,
    private reactionService: ReactionService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comment) {
      this.comment = changes.comment.currentValue;
      this.cdr.markForCheck();
    }

    if (changes.parentComment) {
      this.parentComment = changes.parentComment.currentValue;
      this.cdr.markForCheck();
    }
  }

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });
    this.text = this.comment.ActivityCommentText || this.comment.Text;
    if (this.text) {
      this.text = Autolinker.link(this.text, {
        stripPrefix: false,
      });
    }
    this.hasLike = this.hasReaction("LikeComment");
  }

  toggleComment() {
    this.toggle = !this.toggle;
  }

  like() {
    const reaction = this.buildLikeReaction();
    this.comment.addReaction(reaction);
    this.comment.incrementLikeCount();
    this.reactionService
      .likeComment(reaction.ProfileExternalId, reaction.ActivityId, this.comment.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: () => {
          this.comment.removeReaction(reaction);
          this.comment.decrementLikeCount();
          // TODO: show error message
        },
      });
  }

  unlike() {
    const reaction = this.buildLikeReaction();
    const hasReaction = this.comment.findReaction(reaction);
    this.comment.removeReaction(hasReaction);
    this.comment.decrementLikeCount();
    this.reactionService
      .unlikeComment(reaction.ProfileExternalId, reaction.ActivityId, this.comment.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: () => {
          if (hasReaction) {
            this.comment.addReaction(reaction);
            this.comment.incrementLikeCount();
          }
          // TODO: show error message
        },
      });
  }

  private hasReaction(reactionType): boolean {
    if (this.defaultProfiles) {
      const profileExternalId = this.defaultProfiles?.facebook?.ExternalId;
      if (!profileExternalId) return false;
      return this.comment.hasReaction(reactionType, profileExternalId);
    }
  }

  private buildLikeReaction(): Reaction {
    return {
      ReactionType: "LikeComment",
      ProfileExternalId: this.defaultProfiles.facebook.ExternalId,
      ActivityId: this.activity.Id,
      CommentId: this.comment.Id,
    };
  }

  loadMoreFacebookComments() {
    const count = this.comment.CommentsCount;
    const skip = this.comment.Comments.length;
    const pageSize = this.commentsPageSize;
    let take = count >= skip + pageSize ? pageSize : count - skip;
    if (take <= 0) {
      take = 1;
    }
    const profileId = this.defaultProfiles?.facebook?.ExternalId;
    if (!profileId) return;
    this.commentService
      .forComment("facebook", this.comment.ParentActivityId, this.comment.Id, profileId, skip, take)
      .pipe(untilDestroyed(this))
      .subscribe((data: CommentResponse) => {
        const newComments = data.ActivityComments;
        if (newComments.length > 0) {
          this.comment.appendMoreComments(newComments);
        } else {
          this.hideLoadMore = true;
        }
        this.cdr.markForCheck();
      });
  }
}
