import {Component, Input, OnInit} from "@angular/core";
import {Activity} from "../../activity.model";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-facebook-reactions",
  templateUrl: "./facebook-reactions.component.html",
  styleUrls: ["./facebook-reactions.component.scss"],
})
export class FacebookReactionsComponent implements OnInit {
  @Input() activity: Activity;

  constructor() {}

  ngOnInit() {}
}
