import {HashtagTokenBehaviour} from "@shared/composer/composer-editor/token/HashtagTokenBehaviour";
import {LinkTokenBehaviour} from "@shared/composer/composer-editor/token/LinkTokenBehaviour";
import {MentionTokenBehaviour} from "@shared/composer/composer-editor/token/MentionTokenBehaviour";
import {NewLineTokenBehaviour} from "@shared/composer/composer-editor/token/NewLineTokenBehaviour";
import {TextTokenBehaviour} from "@shared/composer/composer-editor/token/TextTokenBehaviour";
import {
  ComposerTokenTypeEnum,
  EditorsState,
  IComposerToken,
} from "@shared/composer/composer-editor/token/token.interface";
import XRegExp from "xregexp";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {Content, ContentMention} from "@shared/publisher/content.interface";
import {TokenPreviewData} from "@shared/composer/composer-editor/token/ITokenBehaviour";

export function getTextFromTokens(tokens: IComposerToken[], sourceType: ProfileTypes, previewData: TokenPreviewData) {
  if (!tokens) return "";

  const tokens2 = tokens
    .map((x) => x.behaviour.getEditorValue(previewData, sourceType.toString()))
    .filter((x) => x != null);

  if (tokens2.length == 0) return "";

  return tokens2.reduce((a, b) => `${a}${b}`);
}

export function getTextToSendToServerFromTokens(
  tokens: IComposerToken[],
  sourceType: ProfileTypes,
  previewData: TokenPreviewData,
) {
  if (!tokens) return "";

  const tokens2 = tokens
    .map((x) => x.behaviour.getPreviewTextValue(previewData, sourceType.toString()))
    .filter((x) => x != null);

  if (tokens2.length == 0) return "";

  return tokens2.reduce((a, b) => `${a}${b}`);
}

export function getTokensFromHtml(html) {
  if (html == null) return [];

  html = htmlHandler(html);

  const temporalDivElement = document.createElement("div");

  temporalDivElement.innerHTML = html;

  const tokens: IComposerToken[] = [];

  let wasLastNodeAP = false;

  for (let i = 0; i < temporalDivElement.childNodes.length; i++) {
    const element = temporalDivElement.childNodes[i];

    if (wasLastNodeAP && element?.firstChild?.nodeName != "BR") {
      tokens.push(buildNewlineBehaviourToken());
    }

    wasLastNodeAP = element.nodeName == "P";

    for (let j = 0; j < element.childNodes?.length; j++) {
      const child = element.childNodes[j];

      if (["SPAN", "STRONG", "EM"].includes(child.nodeName)) {
        for (let t = 0; t < child.childNodes?.length; t++) {
          const span = child.childNodes[t] as HTMLElement;
          if (span.nodeName == "#text") {
            //Need to evaluate the text to see if there are hashtags or links in between.
            const tokensFromTextNode = getTokensFromTextNode(span);
            tokens.push(...tokensFromTextNode);
          } else if (span.className == "mention") {
            const mentionId = span.getAttribute("data-id");
            const mentionValue = span.getAttribute("data-value");
            const mentionSourceType = span.getAttribute("data-sourcetype");
            if (span.textContent.includes(mentionValue))
              tokens.push(buildMentionBehaviourToken(mentionId, mentionValue, mentionSourceType));
          }
        }
      }
    }

    if (element?.firstChild?.nodeName == "BR") {
      tokens.push(buildNewlineBehaviourToken());
    }
  }

  return tokens ?? [];
}

export function getTokensFromLegacyText(text: string, mentions: ContentMention[], profileType: ProfileTypes) {
  const tokens: IComposerToken[] = [];

  if (mentions == null || mentions.length == 0) {
    const node = document.createTextNode(text);
    tokens.push(...getTokensFromTextNode(node));
    return tokens;
  }

  let accumulatedString = "";

  for (let index = 0; index < text.length; index++) {
    const mention = mentions.find((x) => x.EditorIndexStart == index && x.SourceType == profileType);

    if (mention) {
      const node2 = document.createTextNode(accumulatedString);
      tokens.push(...getTokensFromTextNode(node2));
      tokens.push(buildMentionBehaviourToken(mention.Id, mention.Name, mention.SourceType.toString()));
      index = index + mention.Name.length - 1;
      accumulatedString = "";
    } else {
      accumulatedString = accumulatedString + text[index];
    }
  }

  if (accumulatedString.length > 0) {
    const node3 = document.createTextNode(accumulatedString);
    tokens.push(...getTokensFromTextNode(node3));
  }

  return tokens;
}

function htmlHandler(html: string) {
  const temporalDivElement = document.createElement("div");

  const nbspRegex = new RegExp("&nbsp;", "gi");

  html = html.replace(nbspRegex, " ");

  temporalDivElement.innerHTML = html;

  temporalDivElement.querySelectorAll(".ql-cursor").forEach((x) => x.remove());
  return temporalDivElement.innerHTML;
}

function getTokensFromTextNode(node: Node): IComposerToken[] {
  const text = node.textContent;

  const regexForHashtagOrUrl = XRegExp(
    "(?<url>(http|ftp|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-?]*[(\\(|\\))]*[\\w.,@?^=%&:/~+#-]))|(?<tag>#[a-zA-Z\\d-_]+)|(?<textnode>[^\\s]+)|(?<newline>[\n])|(?<space>[ ])",
    "gi",
  );

  const listOfTokens: IComposerToken[] = [];

  let accumulatedString = "";

  XRegExp.forEach(text, regexForHashtagOrUrl, (m) => {
    const matchValue = m[0];
    if (m.groups.textnode || m.groups.space) {
      accumulatedString = accumulatedString + matchValue;
    } else if (m.groups.url) {
      if (accumulatedString.length > 0) listOfTokens.push(buildTextBehaviourToken(accumulatedString));
      listOfTokens.push(buildLinkBehaviourToken(m.groups.url));
      accumulatedString = "";
    } else if (m.groups.tag) {
      if (accumulatedString.length > 0) listOfTokens.push(buildTextBehaviourToken(accumulatedString));
      const matchStartsWithNewLine = matchValue.startsWith("\n");
      if (matchStartsWithNewLine) {
        listOfTokens.push(buildNewlineBehaviourToken());
      }
      listOfTokens.push(buildHashtagBehaviourToken(m.groups.tag));
      accumulatedString = "";
    } else if (m.groups.newline) {
      if (accumulatedString.length > 0) listOfTokens.push(buildTextBehaviourToken(accumulatedString));
      listOfTokens.push(buildNewlineBehaviourToken());
      accumulatedString = "";
    }
  });

  if (accumulatedString.length > 0) {
    const textToken = buildTextBehaviourToken(accumulatedString);
    listOfTokens.push(textToken);
    accumulatedString = "";
  }
  return listOfTokens ?? [];
}

export function getTokensFromContent(content: Content): Map<ProfileTypes, IComposerToken[]> {
  let editorsState: EditorsState = {
    editors: [],
  };

  if (content.EditorStatesJson != null && content.EditorStatesJson.length > 0) {
    editorsState = JSON.parse(content.EditorStatesJson) as EditorsState;
  }

  for (let index = 0; index < editorsState.editors.length; index++) {
    const editorState = editorsState.editors[index];

    if (editorState.tokens === undefined) {
      continue;
    }

    for (let tokenIndex = 0; tokenIndex < editorState.tokens.length; tokenIndex++) {
      const token = editorState.tokens[tokenIndex];

      if (token.tokenType == ComposerTokenTypeEnum.Hashtag) {
        token.behaviour = new HashtagTokenBehaviour(token.behaviour.originalValue);
      } else if (token.tokenType == ComposerTokenTypeEnum.Link) {
        token.behaviour = new LinkTokenBehaviour(token.behaviour.originalValue);
      } else if (token.tokenType == ComposerTokenTypeEnum.Mention) {
        const mentionStoredBehavior = token.behaviour as MentionTokenBehaviour;
        token.behaviour = new MentionTokenBehaviour(
          mentionStoredBehavior.id,
          mentionStoredBehavior.displayName,
          mentionStoredBehavior.sourceType,
        );
      } else if (token.tokenType == ComposerTokenTypeEnum.NewLine) {
        token.behaviour = new NewLineTokenBehaviour();
      } else if (token.tokenType == ComposerTokenTypeEnum.Text) {
        token.behaviour = new TextTokenBehaviour(token.behaviour.originalValue);
      }
    }
  }

  const dictionaryOfTokens = new Map<ProfileTypes, IComposerToken[]>();

  let defaultTokens: IComposerToken[] = [];

  if (content.InstagramText) {
    const instagramTokens = extractTokens(ProfileTypes.InstagramAccount, content.InstagramText);

    dictionaryOfTokens.set(ProfileTypes.InstagramAccount, instagramTokens);

    defaultTokens = instagramTokens;
  }

  if (content.LinkedInText) {
    const linkedInTokens = extractTokens(ProfileTypes.LinkedIn, content.LinkedInText);

    dictionaryOfTokens.set(ProfileTypes.LinkedIn, linkedInTokens);

    defaultTokens = linkedInTokens;
  }

  if (content.TwitterText) {
    const twitterTokens = extractTokens(ProfileTypes.TwitterAccount, content.TwitterText);

    dictionaryOfTokens.set(ProfileTypes.TwitterAccount, twitterTokens);

    defaultTokens = twitterTokens;
  }

  if (content.FacebookText) {
    const facebookTokens = extractTokens(ProfileTypes.Facebook, content.FacebookText);

    dictionaryOfTokens.set(ProfileTypes.Facebook, facebookTokens);

    defaultTokens = facebookTokens;
  }

  //This code runs after the last block so that we have the default tokens set;

  if (!content.InstagramText) {
    dictionaryOfTokens.set(ProfileTypes.InstagramAccount, defaultTokens);
  }

  if (!content.TwitterText) {
    dictionaryOfTokens.set(ProfileTypes.TwitterAccount, defaultTokens);
  }

  if (!content.LinkedInText) {
    dictionaryOfTokens.set(ProfileTypes.LinkedIn, defaultTokens);
  }

  if (!content.FacebookText) {
    dictionaryOfTokens.set(ProfileTypes.Facebook, defaultTokens);
  }

  return dictionaryOfTokens;

  function extractTokens(profileType: ProfileTypes, text: string) {
    let tokens = editorsState.editors.find((x) => x.sourceType == profileType)?.tokens;

    if (!tokens) {
      tokens = getTokensFromLegacyText(text, content.PublishMessageMentions, profileType);
    }
    return tokens;
  }
}

export function buildTextBehaviourToken(text: string): IComposerToken {
  const behaviour = new TextTokenBehaviour(text);
  return {
    behaviour: behaviour,
    tokenType: ComposerTokenTypeEnum.Text,
  };
}

export function buildHashtagBehaviourToken(hashtag: string): IComposerToken {
  const behaviour = new HashtagTokenBehaviour(hashtag);
  return {
    behaviour: behaviour,
    tokenType: ComposerTokenTypeEnum.Hashtag,
  };
}

export function buildLinkBehaviourToken(url: string): IComposerToken {
  const behaviour = new LinkTokenBehaviour(url);
  return {
    behaviour: behaviour,
    tokenType: ComposerTokenTypeEnum.Link,
  };
}

export function buildMentionBehaviourToken(id: string, displayName: string, sourceType: string): IComposerToken {
  const behaviour = new MentionTokenBehaviour(id, displayName, sourceType);
  return {
    behaviour: behaviour,
    tokenType: ComposerTokenTypeEnum.Mention,
  };
}

export function buildNewlineBehaviourToken() {
  const behaviour = new NewLineTokenBehaviour();
  return {
    behaviour: behaviour,
    tokenType: ComposerTokenTypeEnum.NewLine,
  };
}

export function getMentionsFromTokens(
  tokens: IComposerToken[],
  sourceType: ProfileTypes,
  tokenPreviewData: TokenPreviewData,
): ContentMention[] {
  if (!tokens || tokens.length == 0) {
    return [];
  }

  const mentions = [];
  if (
    sourceType == ProfileTypes.Facebook ||
    sourceType == ProfileTypes.LinkedIn ||
    sourceType == ProfileTypes.TwitterAccount
  ) {
    let indexStart = 0;
    let editorIndexStart = 0;
    for (let index = 0; index < tokens.length; index++) {
      const token = tokens[index];

      if (token.tokenType == ComposerTokenTypeEnum.Mention) {
        const behaviour = token.behaviour as MentionTokenBehaviour;
        mentions.push({
          Reference: behaviour.id,
          Name: behaviour.getEditorValue(),
          SourceType: behaviour.profileType,
          EditorIndexStart: editorIndexStart,
          IndexStart: indexStart,
          IndexEnd: indexStart + behaviour.getPreviewLength(null, behaviour.profileType.toString()) - 1,
        });
      }

      editorIndexStart = editorIndexStart + token.behaviour.getEditorLength(tokenPreviewData, sourceType.toString());
      indexStart = indexStart + token.behaviour.getPreviewLength(tokenPreviewData, sourceType.toString());
    }
  }

  return mentions;
}
