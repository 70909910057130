import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Time} from "./time";
import {TimeStruct} from "./time-struct.interface";
import {isInteger, isNumber, padNumber, toInteger} from "../../utils.functions";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ],
})
export class TimePickerComponent implements ControlValueAccessor, OnChanges {
  @Input()
  set hourStep(step: number) {
    this._hourStep = isInteger(step) ? step : 1;
  }

  get hourStep(): number {
    return this._hourStep;
  }

  @Input()
  set minuteStep(step: number) {
    this._minuteStep = isInteger(step) ? step : 1;
  }

  get minuteStep(): number {
    return this._minuteStep;
  }

  @Input()
  set secondStep(step: number) {
    this._secondStep = isInteger(step) ? step : 1;
  }

  get secondStep(): number {
    return this._secondStep;
  }

  constructor(private _cd: ChangeDetectorRef) {
    this.meridian = false;
    this.spinners = true;
    this.seconds = false;
    this.hourStep = 1;
    this.minuteStep = 1;
    this.secondStep = 1;
    this.disabled = false;
    this.readonlyInputs = false;
    this.size = "medium";
  }

  get isSmallSize(): boolean {
    return this.size === "small";
  }

  get isLargeSize(): boolean {
    return this.size === "large";
  }

  disabled: boolean;
  model: Time;

  private _hourStep: number;
  private _minuteStep: number;
  private _secondStep: number;

  @Output() timeClick = new EventEmitter<any>();
  @Input() meridian: boolean;
  @Input() spinners: boolean;
  @Input() seconds: boolean;
  @Input() readonlyInputs: boolean;
  @Input() size: "small" | "medium" | "large";

  private static fromModel(time: TimeStruct | null): TimeStruct | null {
    return time && isInteger(time.hour) && isInteger(time.minute)
      ? {
          hour: time.hour,
          minute: time.minute,
          second: isInteger(time.second) ? time.second : <any>null,
        }
      : null;
  }

  private static toModel(time: TimeStruct | null): TimeStruct | null {
    return time && isInteger(time.hour) && isInteger(time.minute)
      ? {
          hour: time.hour,
          minute: time.minute,
          second: isInteger(time.second) ? time.second : <any>null,
        }
      : null;
  }

  private onChange: (_: TimeStruct) => void = () => {};
  private onTouched: () => void = () => {};

  public onTimeInputClick = (e: Event): void => {
    e.stopPropagation();
    this.timeClick.emit(e);
  };

  writeValue(value: TimeStruct) {
    const structValue = TimePickerComponent.fromModel(value);
    this.model = structValue ? new Time(structValue.hour, structValue.minute, structValue.second) : new Time();
    if (!this.seconds && (!structValue || !isNumber(structValue.second))) {
      this.model.second = 0;
    }
    this._cd.markForCheck();
  }

  registerOnChange(fn: (_: TimeStruct) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  changeHour(step: number) {
    this.model.changeHour(step);
    this.propagateModelChange();
  }

  changeMinute(step: number) {
    this.model.changeMinute(step);
    this.propagateModelChange();
  }

  changeSecond(step: number) {
    this.model.changeSecond(step);
    this.propagateModelChange();
  }

  updateHour(newVal: string) {
    const isPM = this.model.hour >= 12;
    const enteredHour = toInteger(newVal);
    if (this.meridian && ((isPM && enteredHour < 12) || (!isPM && enteredHour === 12))) {
      this.model.updateHour(enteredHour + 12);
    } else {
      this.model.updateHour(enteredHour);
    }
    this.propagateModelChange();
  }

  updateMinute(newVal: string) {
    this.model.updateMinute(toInteger(newVal));
    this.propagateModelChange();
  }

  updateSecond(newVal: string) {
    this.model.updateSecond(toInteger(newVal));
    this.propagateModelChange();
  }

  toggleMeridian() {
    if (this.meridian) {
      this.changeHour(12);
    }
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(/[^0-9]/g, "");
  }

  formatHour(value?: number) {
    if (isNumber(value)) {
      if (this.meridian) {
        return padNumber(value % 12 === 0 ? 12 : value % 12);
      } else {
        return padNumber(value % 24);
      }
    } else {
      return padNumber(NaN);
    }
  }

  formatMinSec(value?: number) {
    return padNumber(isNumber(value) ? value : NaN);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["seconds"] && !this.seconds && this.model && !isNumber(this.model.second)) {
      this.model.second = 0;
      this.propagateModelChange(false);
    }
  }

  private propagateModelChange(touched = true) {
    if (touched) {
      this.onTouched();
    }
    if (this.model.isValid(this.seconds)) {
      this.onChange(
        TimePickerComponent.toModel({
          hour: this.model.hour,
          minute: this.model.minute,
          second: this.model.second,
        }),
      );
    } else {
      this.onChange(TimePickerComponent.toModel(null));
    }
  }
}
