import {Component, Input, OnInit} from "@angular/core";
import {Activity} from "../activity.model";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-activity-header",
  templateUrl: "./activity-header.component.html",
  styleUrls: ["./activity-header.component.scss"],
})
export class ActivityHeaderComponent implements OnInit {
  @Input() activity: Activity;
  @Input() permalink: string;
  @Input() showSource = true;
  @Input() showBadge = true;
  @Input() isRepost = false;

  constructor() {}

  ngOnInit() {}
}
