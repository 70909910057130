<div class="composer-channel-selector">
  <mat-form-field data-test="composer-profile-selector-button" appearance="outline" [ngClass]="{'has-error': hasError}">
    <!-- Selected Channel Chip -->
    <div
      *ngFor="let channel of selectedProfiles"
      class="inline-flex items-center text-xs flex-nowrap py-px px-0.5 m-0.5 rounded-full border border-solid border-light-base-300/90 dark:border-dark-base-400/30">
      <app-icon [icon]="iconMap[channel.Type]" class="w-5 h-5"></app-icon>
      <div class="whitespace-nowrap -mt-px ml-1 text-light-base-600 dark:text-dark-base-600">
        {{ channel.Name | lowercase }}
      </div>
      <app-icon-button
        icon="x"
        iconSize="16"
        type="inline"
        class="ml-1 p-px"
        (buttonClick)="remove(channel.Id)"></app-icon-button>
    </div>
    <!-- Selected Channel Input -->
    <input
      [formControl]="inputControl"
      (click)="openPanel()"
      type="text"
      matInput
      (focus)="onInputFocus()"
      (keydown.escape)="closePanelEscape()"
      [placeholder]="selectedProfiles?.length === 0 ? 'Where would you like to post?' : ''" />
    <!-- Select Channel Dropdown Icon -->
    <button
      *ngIf="selectedProfiles?.length === 0"
      matSuffix
      mat-icon-button
      (click)="openPanel()"
      title="List channels"
      class="list-channels !flex !items-center !justify-center">
      <app-icon icon="selector" class="text-light-base-400 dark:text-dark-base-400 w-5 h-5 leading-5"></app-icon>
    </button>
    <button
      *ngIf="selectedProfiles?.length > 0"
      matSuffix
      mat-icon-button
      (click)="clearSelectedChannels()"
      title="Remove all"
      data-test="remove-all-selected-channels"
      class="remove-channels !flex !items-center !justify-center">
      <app-icon icon="x" class="text-light-base-400 dark:text-dark-base-400 w-5 h-5 leading-5"></app-icon>
    </button>
  </mat-form-field>
  <app-message
    *ngIf="composerValidation.GeneralValidation.NoProfileSelected"
    class="block mt-1"
    data-test="validationmessage-generalvalidation-nochannelselected-appmessage">
    You must select at least one channel.
  </app-message>
  <ul
    *ngIf="isPanelOpen"
    class="block absolute top-full left-0 w-full overflow-y-auto max-h-52 box-border border border-solid border-light-base-300/90 dark:border-dark-base-300/90 bg-light-base-0 dark:bg-dark-base-100 z-[100] mt-2 rounded-md shadow">
    <li
      *ngFor="let channel of filteredChannels$ | async; trackBy: trackById"
      (click)="onOptionSelect(channel)"
      data-test="composer-channel-selector-option"
      class="composer-channel-selector-option cursor-pointer hover:bg-light-base-100/50 dark:hover:bg-dark-base-400/10">
      <div class="divide divide-y divide-light-base-100 dark:divide-dark-base-100">
        <div
          class="flex relative items-center pl-3 pr-4 py-2"
          [ngClass]="{'cursor-not-allowed': !userHasPermissionForChannel(channel)}">
          <app-profile-picture
            [picture]="channel.Picture"
            [provider]="channel.Type"
            [hasChannel]="true"
            [showBadge]="true"
            [badgeSize]="12"
            [pictureSize]="32">
          </app-profile-picture>
          <div class="flex-1 flex items-center ml-2">
            <div class="font-medium text-light-base-600 dark:text-dark-base-400 text-sm">
              {{ channel.Name }}
            </div>
            <div
              *ngIf="channel.ScreenName"
              class="text-light-base-400 dark:text-dark-base-400 text-[12px] ml-2 truncate">
              &#64;{{ channel.ScreenName }}
            </div>
          </div>
          <div class="flex-shrink-0 leading-none">
            <app-icon
              *ngIf="!userHasPermissionForChannel(channel)"
              icon="lock"
              class="text-light-base-400 dark:text-dark-base-400 channel-permission-indicator w-5 h-5"
              matTooltip="You don't have permission to publish to this channel."
              matTooltipPosition="above"></app-icon>
          </div>
        </div>
      </div>
    </li>
    <li *ngIf="noMatch$ | async">No matches found.</li>
  </ul>
</div>
