"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
require("core-js/modules/es6.object.define-property");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
require("core-js/modules/es6.array.sort");
var _clone = _interopRequireDefault(require("./lib/clone"));
var _extractHtmlAttrsFromOptions = _interopRequireDefault(require("./extractHtmlAttrsFromOptions"));
var _htmlEscape = _interopRequireDefault(require("./htmlEscape"));
var _linkToCashtag = _interopRequireDefault(require("./linkToCashtag"));
var _linkToHashtag = _interopRequireDefault(require("./linkToHashtag"));
var _linkToUrl = _interopRequireDefault(require("./linkToUrl"));
var _linkToMentionAndList = _interopRequireDefault(require("./linkToMentionAndList"));

// Copyright 2018 Twitter, Inc.
// Licensed under the Apache License, Version 2.0
// http://www.apache.org/licenses/LICENSE-2.0
// Default CSS class for auto-linked lists (along with the url class)
var DEFAULT_LIST_CLASS = 'tweet-url list-slug'; // Default CSS class for auto-linked usernames (along with the url class)

var DEFAULT_USERNAME_CLASS = 'tweet-url username'; // Default CSS class for auto-linked hashtags (along with the url class)

var DEFAULT_HASHTAG_CLASS = 'tweet-url hashtag'; // Default CSS class for auto-linked cashtags (along with the url class)

var DEFAULT_CASHTAG_CLASS = 'tweet-url cashtag';
function _default(text, entities, options) {
  var options = (0, _clone["default"])(options || {});
  options.hashtagClass = options.hashtagClass || DEFAULT_HASHTAG_CLASS;
  options.hashtagUrlBase = options.hashtagUrlBase || 'https://twitter.com/search?q=%23';
  options.cashtagClass = options.cashtagClass || DEFAULT_CASHTAG_CLASS;
  options.cashtagUrlBase = options.cashtagUrlBase || 'https://twitter.com/search?q=%24';
  options.listClass = options.listClass || DEFAULT_LIST_CLASS;
  options.usernameClass = options.usernameClass || DEFAULT_USERNAME_CLASS;
  options.usernameUrlBase = options.usernameUrlBase || 'https://twitter.com/';
  options.listUrlBase = options.listUrlBase || 'https://twitter.com/';
  options.htmlAttrs = (0, _extractHtmlAttrsFromOptions["default"])(options);
  options.invisibleTagAttrs = options.invisibleTagAttrs || "style='position:absolute;left:-9999px;'"; // remap url entities to hash

  var urlEntities, i, len;
  if (options.urlEntities) {
    urlEntities = {};
    for (i = 0, len = options.urlEntities.length; i < len; i++) {
      urlEntities[options.urlEntities[i].url] = options.urlEntities[i];
    }
    options.urlEntities = urlEntities;
  }
  var result = '';
  var beginIndex = 0; // sort entities by start index

  entities.sort(function (a, b) {
    return a.indices[0] - b.indices[0];
  });
  var nonEntity = options.htmlEscapeNonEntities ? _htmlEscape["default"] : function (text) {
    return text;
  };
  for (var i = 0; i < entities.length; i++) {
    var entity = entities[i];
    result += nonEntity(text.substring(beginIndex, entity.indices[0]));
    if (entity.url) {
      result += (0, _linkToUrl["default"])(entity, text, options);
    } else if (entity.hashtag) {
      result += (0, _linkToHashtag["default"])(entity, text, options);
    } else if (entity.screenName) {
      result += (0, _linkToMentionAndList["default"])(entity, text, options);
    } else if (entity.cashtag) {
      result += (0, _linkToCashtag["default"])(entity, text, options);
    }
    beginIndex = entity.indices[1];
  }
  result += nonEntity(text.substring(beginIndex, text.length));
  return result;
}
module.exports = exports.default;