import {Injectable, EventEmitter} from "@angular/core";
import {environment} from "@environments/environment";
import {GroupSource, Source} from "@stream/stream.interface";
import {Observable, of} from "rxjs";
import {finalize, map, share, tap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ProfileTypes} from "./profile-types.enum";

@Injectable({
  providedIn: "root",
})
export class SourceService {
  private baseUrl = environment.api + "/Sources";
  private allSources: Source[];
  private listObservable: Observable<Source[]>;

  public sourceRemovedEvent = new EventEmitter<any>();

  constructor(private http: HttpClient) {}

  list(cache = true): Observable<Source[]> {
    let observable: Observable<Source[]>;
    if (this.allSources && cache) {
      observable = of(this.allSources);
    } else if (this.listObservable && cache) {
      observable = this.listObservable;
    } else {
      this.listObservable = this.http.get<Source[]>(this.baseUrl).pipe(
        tap((sources) => {
          sources.forEach((x) => {
            if (x.Picture == null && x.Type === ProfileTypes.SyndicationFeed) {
              x.Picture = "assets/images/rss.png";
            }
          });
          this.allSources = sources;
        }),
        share(),
        finalize(() => {
          this.listObservable = null;
        }),
      );
      observable = this.listObservable;
    }
    return observable;
  }

  getAll(): Observable<Source[]> {
    return this.http.get<Source[]>(`${this.baseUrl}/GetAll`).pipe(
      tap((sources) => {
        sources.forEach((x) => {
          if (x.Picture == null && x.Type === ProfileTypes.SyndicationFeed) {
            x.Picture = "assets/images/rss.png";
          }
        });
      }),
    );
  }

  addSource(source: Source) {
    return this.http.post<Source>(this.baseUrl, source).pipe(
      tap((source) => {
        if (this.allSources) {
          this.allSources.push(source);
          this.listObservable = of(this.allSources);
        }
      }),
      share(),
    );
  }

  clearCache() {
    this.allSources = null;
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.baseUrl + "/" + id).pipe(
      tap(() => {
        const all = this.allSources;
        if (all) {
          this.sourceRemovedEvent.emit({
            sourcesToRemove: all.filter((x) => x.Id === id),
          });
          this.allSources = all.filter((x) => x.Id !== id);
        }
        this.listObservable = of(this.allSources);
      }),
      share(),
    );
  }

  getGroupedSources(cache = true): Observable<GroupSource[]> {
    return this.list(cache).pipe(
      map((sources) => {
        const reducedSources = sources.reduce((r, a) => {
          r[a.Identifier] = [...(r[a.Identifier] || []), a];
          return r;
        }, {});
        const listOfGroupsToReturn: GroupSource[] = [];
        for (const groupObjectName of Object.keys(reducedSources)) {
          if (reducedSources.hasOwnProperty(groupObjectName)) {
            const firstSource = reducedSources[groupObjectName][0];
            const isPrivateChannel = reducedSources[groupObjectName].some((x) => x.HasProfile);
            listOfGroupsToReturn.push({
              Picture: firstSource.Picture,
              Sources: reducedSources[groupObjectName],
              FriendlyName: firstSource.FriendlyName,
              ScreenName: firstSource.ScreenName,
              Type: firstSource.Type,
              Subtype: firstSource.Subtype,
              Url: firstSource.Url,
              showHome: true,
              showMentions: true,
              showTags: true,
              selected: false,
              isPrivateChannel: isPrivateChannel,
            });
          }
        }
        return listOfGroupsToReturn;
      }),
    );
  }
}
