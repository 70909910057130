import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {ITokenBehaviour} from "./ITokenBehaviour";

export interface IComposerToken {
  behaviour: ITokenBehaviour;

  tokenType: ComposerTokenTypeEnum;
}

export enum ComposerTokenTypeEnum {
  NewLine,
  Mention,
  Link,
  Hashtag,
  Text,
}

export interface EditorsState {
  editors: EditorState[];
}

export interface EditorState {
  sourceType: ProfileTypes;
  tokens: IComposerToken[];
}
