import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {Observable} from "rxjs";
import {HashtagGroupModel} from "./hashtag-group.interface";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HashtagGroupService {
  private baseUrl = environment.api + "/HashtagGroup";

  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<HashtagGroupModel[]> {
    return this.httpClient.get<HashtagGroupModel[]>(this.baseUrl + "/getAll");
  }

  createHashTagGroup(model: HashtagGroupModel): Observable<HashtagGroupModel> {
    return this.httpClient.post<HashtagGroupModel>(this.baseUrl + "/createHashtagGroup", model);
  }

  editHashTagGroup(model: HashtagGroupModel): Observable<HashtagGroupModel> {
    return this.httpClient.put<HashtagGroupModel>(this.baseUrl + "/editHashtagGroup", model);
  }

  deleteHashtagGroup(id: string): Observable<void> {
    return this.httpClient.delete<void>(this.baseUrl + "/deletehashtagGroup/" + id);
  }
}
