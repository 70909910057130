import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {UrlMetadata} from "@activity/activity.interface";
import {FbScrapeResponse} from "./url-metadata.interface";

@Injectable({
  providedIn: "root",
})
// consider a wrapper "behavior subject" and cache that with some timeout
export class UrlMetadataService {
  private baseUrl = environment.api + "/UrlMetadata";

  constructor(private http: HttpClient) {
    // console.log("UrlMetadataService constructor");
  }

  resolve(urls: string[]): Observable<UrlMetadata[]> {
    return this.http.post<UrlMetadata[]>(this.baseUrl + "/Resolve", urls);
  }

  fbScrape(url: string): Observable<FbScrapeResponse> {
    return this.http.post<FbScrapeResponse>(this.baseUrl + "/fb-scrape", "'" + url + "'", {
      headers: {"Content-Type": "application/json; charset=utf-8"},
    });
  }
}
