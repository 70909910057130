<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 pl-2">Share to LinkedIn</div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-0.5"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Settings Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-50px)] min-w-[500px] max-w-[500px]">
    <div class="app-scrollbar flex-1 flex">
      <div class="flex-1 p-5">
        <section
          class="relative inline-flex flex-col w-full p-5 box-border bg-light-base-0 dark:bg-dark-base-50 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md">
          <mat-form-field appearance="outline">
            <mat-label>Share Caption</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="6"
              cdkAutosizeMaxRows="10"
              [(ngModel)]="data"></textarea>
          </mat-form-field>
          <app-button type="block" class="mt-5" (buttonClick)="share()">Share</app-button>
        </section>
      </div>
    </div>
  </div>
</div>
