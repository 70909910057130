import {getDisplayUrl, getTrackingUrl} from "@shared/utils/link.function";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import Autolinker from "autolinker";
import {ITokenBehaviour, TokenPreviewData} from "./ITokenBehaviour";

export class LinkTokenBehaviour implements ITokenBehaviour {
  originalValue: string;

  constructor(url: string) {
    this.originalValue = url;
  }

  getEditorLength(): number {
    return this.originalValue.length;
  }

  getPreviewLength(previewData: TokenPreviewData, sourceType: string): number {
    const profileType = parseInt(sourceType);

    const url = this.getCurrentLinkState(previewData, profileType);

    return url.length;
  }

  getEditorValue() {
    return this.originalValue;
  }

  getPreviewTextValue(previewData: TokenPreviewData, sourceType: string): string {
    const profileType = parseInt(sourceType);
    const url = this.getCurrentLinkState(previewData, profileType);
    return url;
  }

  getPreviewHtmlValue(previewData: TokenPreviewData, sourceType: string) {
    const profileType: ProfileTypes = ProfileTypes[sourceType];
    const url = this.getCurrentLinkState(previewData, profileType);

    return new Autolinker.HtmlTag({
      tagName: "a",
      attrs: {
        href: url,
        target: "_blank",
        class: "shared-activityurl-a",
      },
      innerHtml: url,
    }).toAnchorString();
  }

  getCurrentLinkState(previewData: TokenPreviewData, sourceType: ProfileTypes) {
    const link = previewData?.links?.find((x) => x.TrackingUrl == this.originalValue && x.ProfileType == sourceType);

    if (link) {
      const displayUrl = getDisplayUrl(link);
      return displayUrl;
    }

    return this.originalValue;
  }
}
