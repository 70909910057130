import {Component, Inject, OnInit, Optional} from "@angular/core";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {IComposerData} from "@composer/composer.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AddChannelDialogComponent} from "@channel/add-channel-dialog/add-channel-dialog.component";
import {MatDialog} from "@angular/material/dialog";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-composer-empty",
  templateUrl: "./composer-empty.component.html",
  styleUrls: ["./composer-empty.component.scss"],
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-480px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-480px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class ComposerEmptyComponent extends PanelOverlay implements OnInit {
  constructor(
    dialogRef: PanelOverlayRef<ComposerEmptyComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IComposerData,
  ) {
    super(dialogRef, data);
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  public addChannel() {
    this.dialog.open(AddChannelDialogComponent, {
      maxWidth: "804px",
      maxHeight: "90vh",
      panelClass: ["app-dialog", "add-channel"],
      autoFocus: false,
    });
  }
}
