<div *ngIf="!tags?.length && loading" style="width: 100%">
  <app-loader class="loader"></app-loader>
</div>
<div *ngIf="!tags?.length && !loading" class="text-light-base-400 dark:text-dark-base-400 text-xs py-2">
  No Tags to Show.
</div>
<app-tag
  *ngFor="let tag of tags"
  [tagName]="tag.Name"
  [tagColor]="tag.ColorHex"
  [tagFontColor]="tag.FgColorHex"
  [tagCounter]="tag.UsedCount"
  [showCounter]="showCounter"
  [active]="tag.Id === selectedTag?.Id"
  [class.selected]="tag.Selected"
  [class.not-selected]="notSelected()"
  (click)="select(tag)"
  tagSize="large">
</app-tag>
