<div class="details" *ngIf="showStatus || showTime">
  <div class="font-medium text-light-base-400 dark:text-dark-base-400" *ngIf="showTime">
    {{ time || activity.CreatedTime | momentFormat: "hh:mm a" }}
  </div>
  <!-- Schedule Posts -->
  <app-icon
    *ngIf="status === 'scheduled'"
    icon="clock"
    class="h-4 w-4 leading-4 text-light-base-400 dark:text-dark-base-400"
    matTooltip="Scheduled"
    matTooltipPosition="above"></app-icon>
  <app-icon
    *ngIf="status === 'published'"
    icon="check-circle-solid"
    class="h-3.5 w-3.5 leading-3 text-light-success-100"
    matTooltip="Published"
    matTooltipPosition="above"></app-icon>

  <!-- Schedule Employee Advocacy Notifications -->
  <app-icon
    *ngIf="status === 'scheduledShare'"
    icon="clock"
    class="h-4 w-4 leading-4 text-light-base-400 dark:text-dark-base-400"
    matTooltip="Scheduled Share"
    matTooltipPosition="above"></app-icon>
  <app-icon
    *ngIf="status === 'shared'"
    icon="check-circle-solid"
    class="h-3.5 w-3.5 leading-3 text-light-success-100"
    matTooltip="Published"
    matTooltipPosition="above"></app-icon>

  <!-- Error -->
  <app-icon
    *ngIf="status === 'error'"
    icon="exclamation-circle-solid"
    class="h-3.5 w-3.5 leading-3 text-red dark:text-red-300"
    matTooltip="Failed"
    matTooltipPosition="above"></app-icon>
</div>
<div class="source items-center" *ngIf="showChannels && filteredProfiles.length > 0">
  <ng-container *ngFor="let profile of filteredProfiles | slice: 0 : 4; let i = index">
    <app-profile-picture
      [picture]="profile.Picture"
      [pictureSize]="pictureSize"
      [mediaQuality]="profileQuality"
      [mediaWidth]="profileWidth"
      [provider]="profile.Type"
      [showBadge]="showBadge"
      [hasError]="profile.hasError"
      [hasChannel]="true"></app-profile-picture>
    <div *ngIf="filteredProfiles.length === 1 && i === 0" class="single-channel">
      <div class="single-channel-name">
        {{ profile.Name || profile.ScreenName }}
      </div>
      <div class="single-channel-handle line-clamp-1" *ngIf="profile.ScreenName">&#64;{{ profile.ScreenName }}</div>
    </div>
  </ng-container>
  <div class="channel-counter" *ngIf="filteredProfiles.length > 4">+ {{ filteredProfiles.length - 4 }}</div>
</div>
<div class="source" *ngIf="showChannels && filteredProfiles.length === 0">
  <app-profile-picture [provider]="0" [showBadge]="false" [hasChannel]="false" [pictureSize]="pictureSize">
  </app-profile-picture>
</div>
