<ng-container *ngFor="let notification of notificationGroup.Notifications">
  <div class="relative min-w-full">
    <div class="relative flex items-start">
      <div>
        <div class="relative px-1">
          <div class="h-8 w-8 bg-light-base-100 dark:bg-dark-base-0 rounded-full flex items-center justify-center">
            <app-icon
              [icon]="notification.NotificationType === 1 ? 'exclamation-circle-solid' : 'check-circle-solid'"
              class="h-4 w-4 text-gray-500"
              [ngClass]="{
                '!text-red !dark:text-red-300': notification.NotificationType === 1,
                '!text-light-success-100': notification.NotificationType === 0
              }"></app-icon>
          </div>
        </div>
      </div>
      <div class="min-w-0 flex-1 flex justify-between py-1.5 mr-5">
        <div class="font-medium text-light-base-400 dark:text-dark-base-400">
          {{ getNotificationTitle(notification) }}
        </div>
        <div class="text-light-base-400 dark:text-dark-base-400">
          <app-icon
            class="w-4 h-4 leading-4 cursor-pointer mr-2"
            icon="compose"
            *ngIf="notification.NotificationType === 1"
            (click)="sendToComposer()"
            matTooltip="Send to composer"
            matTooltipPosition="above"></app-icon>
          <app-icon
            class="read w-4 h-4 leading-4 cursor-pointer mr-2"
            icon="check"
            *ngIf="notificationGroup.Pending"
            (click)="toggleRead()"
            matTooltip="Mark as Read"
            matTooltipPosition="above"></app-icon>
          <app-icon
            class="delete w-4 h-4 leading-4 cursor-pointer"
            icon="trash"
            (click)="deleteNotification()"
            matTooltip="Delete Notification"
            matTooltipPosition="above"></app-icon>
        </div>
      </div>
    </div>
    <div class="relative flex items-start">
      <div class="flex-1 ml-10 mr-5">
        <div class="relative">
          <ng-container *ngFor="let profileType of profileTypesOnNotification">
            <!-- Content Pulled from Template Below -->
            <ng-template
              *ngTemplateOutlet="
                content;
                context: {
                  publishEvent: publishedEventsPerNetwork[profileType][0]
                }
              "></ng-template>
            <div *ngFor="let publishEvent of publishedEventsPerNetwork[profileType]">
              <!-- Error Message -->
              <div
                *ngIf="publishEvent && publishEvent.ErrorMessage"
                class="mt-2 text-xs font-normal text-red dark:text-red-300 break-all">
                <span class="font-medium">{{ publishEvent.Profile.ScreenName || publishEvent.Profile.Name }}</span
                >: {{ publishEvent.ErrorMessage }}
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Notification Date -->
        <div
          *ngIf="!notificationGotAnyError(notification)"
          class="mt-2 text-xs font-normal text-light-base-400 dark:text-dark-base-400">
          Published on {{ getNotificationDate() | date: "medium" }}
        </div>
        <div
          *ngIf="notificationGotAnyError(notification)"
          class="mt-2 text-xs font-normal text-light-base-400 dark:text-dark-base-400">
          Failed on {{ getNotificationDate() | date: "medium" }}
        </div>
      </div>
    </div>
  </div>
  <!-- Content Template -->
  <ng-template #content let-publishEvent="publishEvent">
    <div
      *ngIf="publishEvent"
      [class.border]="!notificationGotErrorByType(notification, publishEvent?.Profile?.Type)"
      [ngClass]="{
        'border-2 border-light-danger-100 dark:border-dark-danger-100': notificationGotErrorByType(
          notification,
          publishEvent?.Profile?.Type
        )
      }"
      class="block bg-white dark:bg-dark-base-100 border-solid border-light-base-300 dark:border-dark-base-300 rounded p-4 mt-2.5 first:mt-0">
      <!-- Notification Channels -->
      <div class="flex flex-wrap text-none leading-zero">
        <app-profile-picture
          *ngFor="let profile of getProfiles(publishEvent.Type)"
          [picture]="profile.Picture"
          [provider]="profile.Type"
          [hasChannel]="true"
          [showBadge]="true"
          [badgeSize]="12"
          [pictureSize]="30"
          [hasError]="channelHasError(profile)"
          [matTooltip]="profile.Name || profile.ScreenName"
          matTooltipPosition="above"
          class="mr-2 mb-2"></app-profile-picture>
      </div>
      <!-- Notification Text -->
      <div
        class="min-w-full text-xs pt-1 text-light-base-600 dark:text-dark-base-400"
        style="word-break: break-word"
        [innerHtml]="getPublishEventText(publishEvent)"></div>
      <!-- Notification Media -->
      <div class="flex flex-wrap text-none leading-zero" *ngIf="publishEvent.MediaFiles.length">
        <img
          class="object-cover w-7.5 h-7.5 rounded mt-3 ml-2 first:ml-0"
          *ngFor="let media of publishEvent.MediaFiles"
          [src]="media.BlobUrl" />
      </div>
    </div>
  </ng-template>
</ng-container>
