import {Component, Inject, OnInit} from "@angular/core";
import {Team, User} from "../user.interface";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TeamService} from "../team.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-select-team-user",
  templateUrl: "./select-team-user.component.html",
  styleUrls: ["./select-team-user.component.scss"],
})
export class SelectTeamUserDialogComponent implements OnInit {
  AccountTeams: Team[];

  constructor(
    public dialogRef: MatDialogRef<SelectTeamUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private teamService: TeamService,
  ) {}

  ngOnInit() {
    this.teamService
      .getAccountTeams()
      .pipe(untilDestroyed(this))
      .subscribe((teams) => {
        this.AccountTeams = teams;
      });
  }

  UserClicked(user: User) {
    this.dialogRef.close(user);
  }
}
