<!-- Direct Publishing via Zapier -->
<ng-container>
  <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">
    Instagram Direct Publishing
  </div>
  <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
    Configure direct publishing for your Instagram channels.
  </div>
</ng-container>
<div class="relative flex flex-col">
  <div class="flex-1 flex flex-row items-center">
    SocialWeaver + Zapier + Instagram
    {{ getSelectedChannels() }}
  </div>
  <div class="relative border border-solid border-light-base-300 dark:border-dark-base-300 rounded">
    <ng-scrollbar class="app-scrollbar" style="width: 670px; min-height: 240px">
      <div class="grid grid-cols-4 gap-4 p-4 box-content">
        <ng-container *ngFor="let processedProfile of processedProfiles">
          <app-channel-card
            [channelPhoto]="processedProfile.profile.Picture"
            [channelProvider]="processedProfile.profile.Type"
            [channelName]="processedProfile.profile.Name"
            [showProvider]="true"
            [isSelected]="processedProfile.selected"
            (selectButton)="OnProfileSelected(processedProfile)"
            (removeButton)="OnProfileSelected(processedProfile)">
          </app-channel-card>
        </ng-container>
      </div>
    </ng-scrollbar>
  </div>
  <div class="flex-1 flex justify-between mt-4">
    <app-basic-button (buttonClick)="OnFinished()" minWidth="116">Configure Later</app-basic-button>
    <app-button (buttonClick)="OnNextClick()" minWidth="116">Configure Selected</app-button>
  </div>
</div>
