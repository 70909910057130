export function stripHtml(html) {
  if (!html || html == "") return "";
  const temporalDivElement = document.createElement("div");
  temporalDivElement.innerHTML = html;
  const result = (temporalDivElement.textContent || temporalDivElement.innerText || "").trim();
  return result || "";
}

export function stripHtmlForQuillText(html) {
  if (html == null || html == undefined) return "";

  const temporalDivElement = document.createElement("div");

  temporalDivElement.innerHTML = html;

  let textToReturn = "";

  let wasLastNodeAP = false;

  for (let i = 0; i < temporalDivElement.childNodes.length; i++) {
    const element = temporalDivElement.childNodes[i];
    if (element?.firstChild?.nodeName == "BR") {
      textToReturn += "\n";
    } else {
      if (wasLastNodeAP) {
        // console.log("was last node a p executed");
        textToReturn += "\n";
      }

      textToReturn += element.textContent || "";

      wasLastNodeAP = element.nodeName == "P" && element?.firstChild?.nodeName != "BR";
    }
  }

  return textToReturn || "";
}
