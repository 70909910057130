import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Reaction} from "../activity.interface";
import {ShareLinkedInOverlayData} from "@shared/composer/share-to-emplyee/share-to-employee.component";
import {PublishSchedule} from "@shared/publisher/publish-schedule.interface";

@Injectable({
  providedIn: "root",
})
export class ReactionService {
  private baseUrl = environment.api + "/Reaction";

  constructor(private http: HttpClient) {}

  like(profileExternaId: string, activityId: string): Observable<any> {
    return this.http.post(this.baseUrl + `/Like/${profileExternaId}/${activityId}`, {});
  }

  unlike(profileExternaId: string, activityId: string): Observable<any> {
    return this.http.delete(this.baseUrl + `/Like/${profileExternaId}/${activityId}`, {});
  }

  likeComment(profileExternaId: string, activityId: string, commentId: string): Observable<any> {
    return this.http.post(this.baseUrl + `/LikeComment/${profileExternaId}/${activityId}/${commentId}`, {});
  }

  unlikeComment(profileExternaId: string, activityId: string, commentId: string): Observable<any> {
    return this.http.delete(this.baseUrl + `/LikeComment/${profileExternaId}/${activityId}/${commentId}`, {});
  }

  share(profileExternaId: string, activityId: string): Observable<any> {
    return this.http.post(this.baseUrl + `/Share/${profileExternaId}/${activityId}?ReactionType=Share`, {});
  }

  shareWithText(profileExternaId: string, activityId: string, sharingText: string): Observable<Reaction> {
    return this.http.post<Reaction>(this.baseUrl + `/Share/${profileExternaId}/${activityId}`, {
      text: sharingText,
    });
  }

  unshare(profileExternaId: string, activityId: string): Observable<any> {
    return this.http.delete(this.baseUrl + `/Share/${profileExternaId}/${activityId}`, {});
  }

  shareWithEmployees(activityId: string, employeeData: ShareLinkedInOverlayData) {
    return this.http.post(`${environment.api}/Employees/content/share/`, {
      ActivityId: activityId,
      LikeOnBehalfOfEmployees: employeeData.like,
      ShareOnBehalfOfEmployees: employeeData.sharing,
      IncludedEmployees: employeeData.employees
        .filter((x) => x.Selected)
        .map((x) => x.Id)
        .join(","),
      LinkedInShareCommentary: employeeData.shareText,
      EmployeeGroupNames: employeeData.employeeGroupsConfig.EmployeeGroups.map((x) => x.Name).join(";"),
      EmployeeGroupsConfig: employeeData.employeeGroupsConfig,
    });
  }

  scheduleShareWithEmployees(activityId: string, employeeData: ShareLinkedInOverlayData, schedule: PublishSchedule) {
    const shareToEmployeesModel = {
      ActivityId: activityId,
      LikeOnBehalfOfEmployees: employeeData.like,
      ShareOnBehalfOfEmployees: employeeData.sharing,
      IncludedEmployees: employeeData.employees
        .filter((x) => x.Selected)
        .map((x) => x.Id)
        .join(","),
      LinkedInShareCommentary: employeeData.shareText,
      EmployeeGroupNames: employeeData.employeeGroupsConfig.EmployeeGroups.map((x) => x.Name).join(";"),
      EmployeeGroupsConfig: employeeData.employeeGroupsConfig,
    };

    return this.http.post(`${environment.api}/Employees/content/scheduleshare/`, {
      ShareToEmployeesModel: shareToEmployeesModel,
      ScheduleModel: schedule,
    });
  }

  editScheduleShareWithEmployees(
    activityShareId: string,
    employeeData: ShareLinkedInOverlayData,
    schedule: PublishSchedule,
  ) {
    const shareToEmployeesModel = {
      LikeOnBehalfOfEmployees: employeeData.like,
      ShareOnBehalfOfEmployees: employeeData.sharing,
      IncludedEmployees: employeeData.employees
        .filter((x) => x.Selected)
        .map((x) => x.Id)
        .join(","),
      LinkedInShareCommentary: employeeData.shareText,
      EmployeeGroupNames: employeeData.employeeGroupsConfig.EmployeeGroups.map((x) => x.Name).join(";"),
      EmployeeGroupsConfig: employeeData.employeeGroupsConfig,
    };

    return this.http.post(`${environment.api}/Employees/content/scheduleshare/`, {
      ActivityShareId: activityShareId,
      ShareToEmployeesModel: shareToEmployeesModel,
      ScheduleModel: schedule,
    });
  }

  deleteScheduleShareWithEmployees(activityShareId: string) {
    return this.http.delete(`${environment.api}/Employees/content/removescheduleshare/` + activityShareId);
  }
}
