import {EventEmitter, Injectable} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {IProfile} from "@shared/channel/profile.interface";
import {
  ContentMention,
  EditorModel,
  ILinkUpdatedModel,
  IOnLinksModelChangeModel,
  Link,
  LinkChangesModel,
  LinkEventEnum,
  MediaFileUploaded,
  NewLinkModel,
} from "@shared/publisher/content.interface";
import {UploadEvent, UploadFile} from "@shared/utils/file-upload/file-upload.directive";
import {setMediaFileMetadata} from "@shared/utils/setMediaFileMetadata.function";
import {unique} from "@shared/utils/unique.function";
import {FacebookMediaValidation} from "./composer-validation/media-validation/FacebookMediaValidation";
import {InstagramMediaValidation} from "./composer-validation/media-validation/InstagramMediaValidation";
import {TwitterMediaValidation} from "./composer-validation/media-validation/TwitterMediaValidation";
import {LinkedInMediaValidation} from "./composer-validation/media-validation/LinkedInMediaValidation";
import {UrlMetadata} from "@shared/activity/activity.interface";
import {UrlMetadataService} from "@shared/utils/url/url-metadata.service";
import {FbScrapeResponse} from "@shared/utils/url/url-metadata.interface";
import {ISelectedProfile} from "./composer.component";
import {
  encodeUrl,
  FromContentLink,
  getDisplayUrl,
  getTrackingUrl,
  hasDynamicParameters,
  htmlEncode,
  setLinkFacebookMetadata,
  setLinkMetadata,
} from "@utils/link.function";
import {getMentionsFromQuillText} from "@shared/utils/getMentionsFromQuillText.function";
import {IntegrationService} from "@shared/utils/integration-service/integration-service.service";
import {StateService} from "@shared/user/state.service";
import {BehaviorSubject} from "rxjs";
import {ComposerTokenTypeEnum, IComposerToken} from "./composer-editor/token/token.interface";
import {EmployeeGroupSelectionTypeEnum, ShareLinkedInOverlayData} from "./share-to-emplyee/share-to-employee.component";
import {buildTextBehaviourToken} from "@shared/utils/token.function";

@Injectable({
  providedIn: "root",
})
export class ComposerService {
  onMediaIsUploading = new EventEmitter<any>();
  onMediaFinishedUploading = new EventEmitter<MediaFinishedUploading>();
  onMediaModelChange = new EventEmitter<MediaFileUploaded[]>();
  onUpdateComposerUi = new EventEmitter<any>();
  onLinksModelChange = new EventEmitter<IOnLinksModelChangeModel>();
  onLinksMetadataModelChange = new EventEmitter<Link[]>();

  onClearMentions = new EventEmitter<any>();

  onContentCreated = new EventEmitter<any>();

  editorModelDataReady = new EventEmitter<any>();

  public linkUpdated: EventEmitter<ILinkUpdatedModel> = new EventEmitter<ILinkUpdatedModel>();

  // // TODO: What is the difference between the 'selectedProfiles' array and 'profiles.filter(p => p.selected)'?
  // // Typically yes, but it could be maintaining front end selections in case we reload the profiles from the backend
  // // interacts with comments above on Selected vs selected attributes. Remove backend first.
  private _selectedProfiles: ISelectedProfile[] = [];

  selectedChannels$ = new BehaviorSubject(this.selectedProfiles);

  toggleChannelSelection$ = new EventEmitter<ISelectedProfile>();

  private urlsLoadingFbMetadata = [];
  private urlsLoadingMetadata = [];

  public quillMentionsModules = [];

  public customizeMode = false;
  public editMode = false;

  public actionsActiveTab = 0;

  public composerEditorsModel: ComposerModel = {
    All: {links: []},
    Facebook: {links: []},
    InstagramAccount: {links: []},
    TwitterAccount: {links: []},
    LinkedIn: {links: []},
  };

  constructor(
    private sanitizer: DomSanitizer,
    private urlMetadataService: UrlMetadataService,
    private integrationService: IntegrationService,
    private stateService: StateService,
  ) {}

  addSelectedChannel(selectedProfile: ISelectedProfile) {
    this.toggleChannelSelection$.emit(selectedProfile);
  }

  removeSelectedChannel(selectedProfile: ISelectedProfile) {
    this.toggleChannelSelection$.emit(selectedProfile);
  }

  clearSelectedChannels() {
    this.selectedProfiles.forEach((x) => this.toggleChannelSelection$.emit(x));
  }
  initializeSelectedProfiles(profiles: IProfile[]) {
    if (!this.editMode) {
      const listOfIds = this.stateService.getWithType<string[]>(this.stateService.selectedProfilesKey) ?? [];

      this.selectedProfiles = profiles
        .filter((x) => listOfIds.some((t) => x.Id == t))
        .map((profile) => this.mapProfileToSelectedProfile(profile, this.editMode));
    } else {
      this._selectedProfiles = [];
    }
  }
  setTokensForProvider(tokens: IComposerToken[], provider: ProfileTypes) {
    const editor = this.getEditorToModify(provider);

    editor.tokens = tokens;
  }

  setMediaFiles(profiles: IProfile[], mediaFiles: MediaFileUploaded[]) {
    const hasFacebook = profiles.some((x) => x.Type == ProfileTypes.Facebook);

    const hasTwitter = profiles.some((x) => x.Type == ProfileTypes.TwitterAccount);

    const hasInstagram = profiles.some((x) => x.Type == ProfileTypes.InstagramAccount);

    const hasLinkedIn = profiles.some((x) => x.Type == ProfileTypes.LinkedIn);

    const profilesTypeCount =
      (hasFacebook ? 1 : 0) + (hasTwitter ? 1 : 0) + (hasInstagram ? 1 : 0) + (hasLinkedIn ? 1 : 0);

    const hasManyNetworks = profilesTypeCount > 1;

    if (hasFacebook) {
      this.composerEditorsModel.Facebook.MediaFiles = mediaFiles.filter((x) => x.EnabledForFacebook == true);
    }

    if (hasTwitter) {
      this.composerEditorsModel.TwitterAccount.MediaFiles = mediaFiles.filter((x) => x.EnabledForTwitter == true);
    }

    if (hasInstagram) {
      this.composerEditorsModel.InstagramAccount.MediaFiles = mediaFiles.filter((x) => x.EnabledForInstagram == true);
    }

    if (hasLinkedIn) {
      this.composerEditorsModel.LinkedIn.MediaFiles = mediaFiles.filter((x) => x.EnabledForLinkedIn == true);
    }

    if (!hasManyNetworks) {
      this.composerEditorsModel.All.MediaFiles = mediaFiles;
    } else {
      this.composerEditorsModel.All.MediaFiles = [];
    }

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  getEditorLinks(provider: string): Link[] {
    const foo = this.getEditorLinksInternal(provider);
    if (typeof foo === "undefined") {
      return [];
    } else {
      return foo;
    }
  }

  private getEditorLinksInternal(provider: string): Link[] | undefined {
    switch (provider) {
      case "All":
        return this.composerEditorsModel.All.links;
      case "Facebook":
        return this.composerEditorsModel.Facebook.links;
      case "InstagramAccount":
        return this.composerEditorsModel.InstagramAccount.links;
      case "TwitterAccount":
        return this.composerEditorsModel.TwitterAccount.links;
      case "LinkedIn":
        return this.composerEditorsModel.LinkedIn.links;
    }

    return [];
  }

  setEditorLinks(profiles: IProfile[], links: Link[]) {
    this.composerEditorsModel.All.links = links
      .filter((x) => x.ProfileType == ProfileTypes.Unknown)
      .map((x) => FromContentLink(x));
    this.composerEditorsModel.Facebook.links = links
      .filter((x) => x.ProfileType == ProfileTypes.Facebook)
      .map((x) => FromContentLink(x));
    this.composerEditorsModel.TwitterAccount.links = links
      .filter((x) => x.ProfileType == ProfileTypes.TwitterAccount)
      .map((x) => FromContentLink(x));
    this.composerEditorsModel.InstagramAccount.links = links
      .filter((x) => x.ProfileType == ProfileTypes.InstagramAccount)
      .map((x) => FromContentLink(x));
    this.composerEditorsModel.LinkedIn.links = links
      .filter((x) => x.ProfileType == ProfileTypes.LinkedIn)
      .map((x) => FromContentLink(x));

    this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
  }

  async handleUploadingImages(originProvider: ProfileTypes, files: MediaFileUploaded[]) {
    const editor = this.getEditorToModify(originProvider);

    this.onMediaIsUploading.emit();

    if (!editor.MediaFiles) {
      editor.MediaFiles = [];
    }

    editor.MediaFiles.push(...files);
  }

  async handleUploadedEvent(originProvider: ProfileTypes, event: UploadEvent) {
    const editor = this.getEditorToModify(originProvider);
    const index = editor.MediaFiles.findIndex((m) => m.id === event.response.Id);

    const preview = editor.MediaFiles[index].preview;
    editor.MediaFiles[index] = event.response;
    editor.MediaFiles[index].preview = preview;
    editor.MediaFiles[index].uploading = false;
    editor.MediaFiles[index].uploaded = true;
    editor.MediaFiles[index].instagramValidation = new InstagramMediaValidation();
    editor.MediaFiles[index].twitterValidation = new TwitterMediaValidation();
    editor.MediaFiles[index].facebookValidation = new FacebookMediaValidation();
    editor.MediaFiles[index].linkedInValidation = new LinkedInMediaValidation();

    editor.MediaFiles[index] = await setMediaFileMetadata(editor.MediaFiles[index]);

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());

    if (!editor.MediaFiles.some((file) => file.uploading)) {
      this.onMediaFinishedUploading.emit();
    }
  }

  removeImage(originProvider: number, index: number) {
    // console.log("removing mediafile");
    const editor = this.getEditorToModify(originProvider);

    const mediaToRemove = {...editor.MediaFiles[index]};

    editor.MediaFiles.splice(index, 1);

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());

    return mediaToRemove;
  }

  removeImages(originProvider: number) {
    const editor = this.getEditorToModify(originProvider);
    editor.MediaFiles = [];
    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  removeMentionsFromEditor(profileType: ProfileTypes) {
    const editor = this.getEditorToModify(profileType);

    if (!editor.tokens) return;

    for (let index = 0; index < editor.tokens.length; index++) {
      const token = editor.tokens[index];

      if (token.tokenType == ComposerTokenTypeEnum.Mention)
        editor.tokens[index] = buildTextBehaviourToken(
          token.behaviour.getEditorValue({links: []}, "").replace("@", ""),
        );
    }
  }

  replaceImage(originProvider: number, mediaFileId: string, mediaFile: MediaFileUploaded) {
    const editor = this.getEditorToModify(originProvider);
    const index = editor.MediaFiles.findIndex((x) => x.Id == mediaFileId);

    if (index == -1) return;

    const imageToCopy = editor.MediaFiles[index];

    if (imageToCopy == null) return;

    const mediaToReplace = Object.assign({}, imageToCopy);

    mediaToReplace.Id = mediaFile.Id;
    mediaToReplace.preview = mediaFile.preview;
    mediaToReplace.PreviewUrl = mediaFile.PreviewUrl;
    mediaToReplace.BlobUrl = mediaFile.BlobUrl;
    mediaToReplace.uploaded = true;
    mediaToReplace.uploading = false;
    mediaToReplace.MediaType = mediaFile.MediaType;
    mediaToReplace.Width = mediaFile.Width;
    mediaToReplace.Height = mediaFile.Height;
    mediaToReplace.SizeBytes = mediaFile.SizeBytes;

    editor.MediaFiles[index] = mediaToReplace;

    if (this.composerEditorsModel && this.composerEditorsModel.All && this.composerEditorsModel.All.MediaFiles) {
      const globalFileIndex = this.composerEditorsModel.All.MediaFiles.findIndex((x) => x.Id == mediaFileId);
      if (globalFileIndex > -1) {
        this.composerEditorsModel.All.MediaFiles[globalFileIndex] = mediaToReplace;
      }
    }

    this.onMediaFinishedUploading.emit();

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  activateCustomizeMode(model: CustomizeModeModel) {
    this.customizeMode = true;

    const generalMediaFiles = this.composerEditorsModel.All?.MediaFiles;
    const generalTokens = this.composerEditorsModel.All?.tokens;

    if (generalMediaFiles) {
      if (model.hasFacebook) this.composerEditorsModel.Facebook.MediaFiles = [...generalMediaFiles];

      if (model.hasTwitter) this.composerEditorsModel.TwitterAccount.MediaFiles = [...generalMediaFiles];

      if (model.hasInstagram) this.composerEditorsModel.InstagramAccount.MediaFiles = [...generalMediaFiles];

      if (model.hasLinkedIn) this.composerEditorsModel.LinkedIn.MediaFiles = [...generalMediaFiles];
    }

    if (generalTokens) {
      if (model.hasFacebook) this.composerEditorsModel.Facebook.tokens = [...generalTokens];

      if (model.hasTwitter) this.composerEditorsModel.TwitterAccount.tokens = [...generalTokens];

      if (model.hasInstagram) this.composerEditorsModel.InstagramAccount.tokens = [...generalTokens];

      if (model.hasLinkedIn) this.composerEditorsModel.LinkedIn.tokens = [...generalTokens];
    }

    if (model.hasLinkedIn) {
      this.CopyEmployeeSharingInfoIntoLinkedInEditor();
    }
    this.initializeRawText();

    this.initializeMentions();

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  deactivateCustomizeMode() {
    this.customizeMode = false;

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  getMentions(): ContentMention[] {
    const facebookMention = this.composerEditorsModel.Facebook.Mentions;
    const linkedInMention = this.composerEditorsModel.LinkedIn.Mentions;
    const twitterMention = this.composerEditorsModel.LinkedIn.Mentions;
    const instagramMention = this.composerEditorsModel.LinkedIn.Mentions;

    return facebookMention.concat(linkedInMention).concat(twitterMention).concat(instagramMention);
  }

  setMediaFilePreview(provider: number, file: UploadFile, thumb: Blob) {
    const editor = this.getEditorToModify(provider);

    if (editor == null) return;

    const index = editor.MediaFiles.findIndex((m) => m.id === file.id);

    const url = URL.createObjectURL(thumb);
    const encUrl = encodeUrl(url);
    // console.log("the media's url", url, encUrl);
    editor.MediaFiles[index].preview = this.sanitizer.bypassSecurityTrustResourceUrl(encUrl);
  }

  copyMediaFromToProvider(providerFrom: number, providerTo: number) {
    const editorToMoveFrom = this.getEditorToModify(providerFrom);

    const editorToMoveTo = this.getEditorToModify(providerTo);

    editorToMoveTo.MediaFiles = [...(editorToMoveFrom?.MediaFiles ?? [])];

    this.onMediaModelChange.emit(this.getMediaFilesToSendToServer());
  }

  triggerUpdateComposerUi() {
    this.onUpdateComposerUi.emit(this.getMediaFilesToSendToServer());
  }

  ///When sending the request to the server use this function to get the correct list of mediafiles ready to be sent to the controller.
  getMediaFilesToSendToServer(): MediaFileUploaded[] {
    let file;
    if (!this.customizeMode) {
      if (!this.composerEditorsModel.All?.MediaFiles) this.composerEditorsModel.All.MediaFiles = [];

      for (file of this.composerEditorsModel.All.MediaFiles) {
        file.EnabledForFacebook = true;
        file.EnabledForTwitter = true;
        file.EnabledForInstagram = true;
        file.EnabledForLinkedIn = true;
      }

      return [...this.composerEditorsModel.All.MediaFiles];
    }

    const facebookFiles = [...(this.composerEditorsModel.Facebook?.MediaFiles?.filter((x) => x != null && true) ?? [])];

    const twitterFiles = [
      ...(this.composerEditorsModel.TwitterAccount?.MediaFiles?.filter((x) => x != null && true) ?? []),
    ];

    const instagramFiles = [
      ...(this.composerEditorsModel.InstagramAccount?.MediaFiles?.filter((x) => x != null && true) ?? []),
    ];

    const linkedInFiles = [...(this.composerEditorsModel.LinkedIn?.MediaFiles?.filter((x) => x != null && true) ?? [])];

    const allFiles: MediaFileUploaded[] = [
      ...unique(facebookFiles.concat(twitterFiles).concat(instagramFiles).concat(linkedInFiles), "Id"),
    ];

    for (file of allFiles) {
      file.EnabledForFacebook = facebookFiles.some((x) => x.Id == file.Id);
      file.EnabledForTwitter = twitterFiles.some((x) => x.Id == file.Id);
      file.EnabledForInstagram = instagramFiles.some((x) => x.Id == file.Id);
      file.EnabledForLinkedIn = linkedInFiles.some((x) => x.Id == file.Id);
    }

    return allFiles;
  }

  markImageAsUploading(provider: number, mediaFileId: string) {
    const editor = this.getEditorToModify(provider);

    const index = editor.MediaFiles.findIndex((x) => x.Id == mediaFileId);
    if (index == -1) return;

    if (index > editor.MediaFiles.length - 1) return;

    const mediaFile = Object.assign({}, editor.MediaFiles[index]);
    mediaFile.uploaded = false;
    mediaFile.uploading = true;
    editor.MediaFiles[index] = mediaFile;
    this.onMediaIsUploading.emit();
  }

  newEditorLinks(provider: number, changesModel: LinkChangesModel, selectedProfiles: ISelectedProfile[]) {
    const editor = this.getEditorToModify(provider);

    // console.log("newEditorLinks", {newLinks: changesModel.newLinks});

    const urlsFoundOnText = changesModel.newLinks;

    if (editor.links == null) editor.links = [];

    //Set the starting position of the oldUrls
    if (changesModel.editModeFirstEdit) {
      editor.links.forEach((oldUrl) => {
        const url = urlsFoundOnText.find((x) => x.Url == getTrackingUrl(oldUrl));

        if (url != null) {
          oldUrl.startPosition = url.Index;
        }
      });
    }

    const oldUrls = [...editor.links].map((x) => FromContentLink(x));
    let linkNotLocatedInTwitterText = false;

    //Remove metadata for twitter that isnt in the text of the post.
    if (provider == null || provider == ProfileTypes.Unknown) {
      const twitterEditor = this.getEditorToModify(ProfileTypes.TwitterAccount);

      const indexesToRemove = [];

      // console.log({TwitterLinks: twitterEditor.links});

      twitterEditor.links.forEach((x, index) => {
        // console.log("Searching for " + getTrackingUrl(x));
        // console.log({text: changesModel.currentText});
        if (changesModel.currentText.indexOf(getTrackingUrl(x)) === -1) {
          // console.log("Couldnt find the url on the text");
          indexesToRemove.push(index);
        }
      });

      indexesToRemove.reverse().forEach((x) => {
        twitterEditor.links.splice(x);
      });

      const onlyTwitterSelected =
        selectedProfiles.length > 0 && selectedProfiles.every((x) => x.profile.Type == ProfileTypes.TwitterAccount);

      // console.log({onlyTwitterSelected})

      if (indexesToRemove.length > 0 && onlyTwitterSelected) {
        // console.log("removing link");
        indexesToRemove.reverse().forEach((x) => {
          editor.links.splice(x);
        });

        linkNotLocatedInTwitterText = true;
      }

      if (indexesToRemove.length > 0) {
        this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
      }
    }

    // console.log({EditorLinks : editor.links});
    // console.log({oldUrls});
    // console.log({linkNotLocatedInTwitterText});

    const isOldUrlModified =
      linkNotLocatedInTwitterText ||
      this.checkIfUrlIsModified(
        changesModel,
        oldUrls.map((x) => {
          return {
            Url: getTrackingUrl(x),
            Index: x.startPosition,
          };
        }),
      );

    // console.log({isOldUrlModified});
    //SW-2523
    this.updateOldUrlsStartPosition(editor.links, changesModel.newLinks);

    if (!isOldUrlModified && urlsFoundOnText.length == oldUrls.length) {
      // console.log("No link changed.", Object.assign(this.composerEditorsModel));
      return;
    }

    if (provider != ProfileTypes.Unknown && provider != ProfileTypes.TwitterAccount && editor.links.length > 0) {
      // console.log("If we are in customize mode and its not twitter we dont want to reinitialize the links for the editor.");
      return;
    }

    if (provider != ProfileTypes.Unknown && changesModel.editModeFirstEdit) {
      // console.log("Provider was twitter and it was the edit raised by the api inserted the edited text.");
      return;
    }

    const newLinks = urlsFoundOnText.map((newLinkModel) => {
      const link = new Link(newLinkModel.Url);

      link.TrackingUrl = newLinkModel.Url;

      link.startPosition = newLinkModel.Index;

      link.ProfileType = provider;

      return link;
    });

    // console.log("newEditorLinks", {newLinks});

    this.clearLinksDependingOnScenario(editor, provider, selectedProfiles, newLinks, changesModel.currentText);

    for (const link of newLinks) {
      // SW-2236 - oldUrlIndex was -1 because the tracking url had changed, leading to duplicate links.
      // Match on BaseUrl instead, so we only have one link per BaseUrl.
      const oldUrlIndex = oldUrls.findIndex((oldUrl) => oldUrl.BaseUrl === link.BaseUrl);

      //description: When typing a url this piece of code removes matching links that are subsets of the current url.
      const oldUrl = oldUrls[oldUrlIndex];

      // console.log({oldUrl});
      // if (oldUrl === undefined) {
      //   console.warn(
      //     "newEditorLinks: undefined oldUrl! ",
      //     oldUrlIndex,
      //     oldUrls.length,
      //     link.BaseUrl
      //   );
      // }

      const indexOldUrl = editor.links.findIndex(
        (x) => getTrackingUrl(link).startsWith(getTrackingUrl(x)) && getTrackingUrl(link) != getTrackingUrl(x),
      );

      if (indexOldUrl >= 0 && provider == ProfileTypes.Unknown) {
        editor.links.splice(indexOldUrl);
      }
      //---

      if (
        (oldUrl === undefined || oldUrl == null || !oldUrl.FacebookMetadata) &&
        !this.urlsLoadingFbMetadata.some((x) => x == link.BaseUrl)
      ) {
        this.urlsLoadingFbMetadata.push(link.BaseUrl);

        this.urlMetadataService.fbScrape(link.BaseUrl).subscribe(
          (fbScrapeResponse) => {
            this.urlsLoadingFbMetadata.splice(this.urlsLoadingFbMetadata.findIndex((url) => url == link.BaseUrl));
            this.updateFacebookMetadataForAllEditors(link.BaseUrl, fbScrapeResponse);
            this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
          },
          (error) => {
            this.urlsLoadingFbMetadata.splice(this.urlsLoadingFbMetadata.findIndex((url) => url == link.BaseUrl));
          },
        );
      } else {
        if (oldUrl !== undefined) {
          setLinkFacebookMetadata(link, oldUrl.FacebookMetadata);
          this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
        }
      }

      if (
        (oldUrl === undefined || oldUrl == null || !oldUrl.Metadata) &&
        !this.urlsLoadingMetadata.some((x) => x == link.BaseUrl)
      ) {
        this.urlsLoadingMetadata.push(link.BaseUrl);
        this.urlMetadataService.resolve([link.BaseUrl]).subscribe(
          (metadata) => {
            this.urlsLoadingMetadata.splice(this.urlsLoadingMetadata.findIndex((url) => url == link.BaseUrl));

            this.updateUrlMetadataForAllEditors(link.BaseUrl, metadata);

            this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
          },
          (error) => {
            this.urlsLoadingMetadata.splice(this.urlsLoadingMetadata.findIndex((url) => url == link.BaseUrl));
          },
        );
      } else {
        if (oldUrl !== undefined) {
          setLinkMetadata(link, oldUrl.Metadata);
          this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
        }
      }

      if (oldUrl === undefined || oldUrl == null || provider == ProfileTypes.TwitterAccount) {
        //We clear the editor links if the provider is twitteraccount, so we need to readd them if they are found in the text.
        //When not in customize mode oldUrl == null prevents us from adding the same link to the editor over and over again.
        editor.links.push(link);
      } else if (
        provider == ProfileTypes.Unknown &&
        selectedProfiles.filter((x) => x.selected).every((x) => x.profile.Type == ProfileTypes.TwitterAccount)
      ) {
        if (oldUrl.IsShorten) {
          link.IsShorten = oldUrl.IsShorten;
          link.ShortenDomain = oldUrl.ShortenDomain;
          link.ShortenGroupId = oldUrl.ShortenGroupId;
          link.ShortenUrl = oldUrl.ShortenUrl;
          link.lastShortenUrl = oldUrl.lastShortenUrl;
          link.IsLinkOnText = oldUrl.IsLinkOnText;
          link.IsMetadataLoading = oldUrl.IsMetadataLoading;
        }

        editor.links.push(link);
      }
    }

    this.setLinksEnabledFlags(newLinks, provider, selectedProfiles, changesModel.source);

    this.onLinksModelChange.emit({
      Event: LinkEventEnum.SetEditorLink,
      links: this.getLinksToSendToServer(),
    });

    this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
  }

  checkIfUrlIsModified(changesModel: LinkChangesModel, urls: NewLinkModel[]): boolean {
    let spChange = changesModel.caretPosition;
    const isDelete = changesModel.isDeleteOperation;
    const lenChange = changesModel.changeLength;
    let epChange = spChange + lenChange - 1;

    // console.log("----[CheckIfUrlIsModified] Start---");
    // console.log({lenChange});
    // console.log({spChange});
    // console.log({epChange});
    // console.log({urls});
    // console.log({newUrls: changesModel.newLinks});

    // console.log(changesModel.currentText);

    // console.log("----===--------");
    if (lenChange == 0) return false;

    if (isDelete) {
      // console.log("IsDelete");
      return !urls.every((x) => {
        if (x.Index >= spChange) {
          // console.log(`adding ${lenChange} to the index.`);
          x.Index += lenChange;
        }
        return x.Index + x.Url.length <= spChange || x.Index >= epChange;
      });
    } else {
      if (changesModel.currentText.length > spChange && changesModel.currentText.length > epChange) {
        //todo handle all whitespaces
        const startsWithWhitespace = /\s/.test(changesModel.currentText[spChange]);
        const endsWithWhitespace = /\s/.test(changesModel.currentText[epChange]);

        // console.log(changesModel.currentText.charCodeAt(spChange),
        //             changesModel.currentText.charCodeAt(epChange));

        if (!startsWithWhitespace) {
          spChange -= 1;
        }

        if (!endsWithWhitespace) {
          epChange += 1;
        }
        // console.log({startsWithWhitespace});
        // console.log({endsWithWhitespace});

        return !urls.every((x) => x.Index + x.Url.length <= spChange || x.Index >= epChange);
      }
      //  console.log("Current text is smaller than the index we are trying to evaluate.");
    }

    return false;
  }

  clearLinksDependingOnScenario(
    editor: EditorModel,
    provider: number,
    selectedProfiles: ISelectedProfile[],
    newUrls: Link[],
    currentText: string,
  ) {
    if (
      ((provider == ProfileTypes.Unknown || provider == null) &&
        selectedProfiles.length > 0 &&
        selectedProfiles.filter((x) => x.selected).every((x) => x.profile.Type == ProfileTypes.TwitterAccount)) ||
      provider == ProfileTypes.TwitterAccount
    ) {
      editor.links = [];
    } else if (newUrls.length < editor.links.length && editor.links.length > 1) {
      //If the editor link is not found in the text, remove it.
      if (currentText.indexOf(getTrackingUrl(editor.links[editor.links.length - 1])) === -1) {
        editor.links.splice(editor.links.length - 1);
      }
    }
  }

  updateFacebookMetadataForAllEditors(url: string, fbScrapeResponse: FbScrapeResponse) {
    this.composerEditorsModel.All.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkFacebookMetadata(element, fbScrapeResponse);
    });
    this.composerEditorsModel.Facebook.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkFacebookMetadata(element, fbScrapeResponse);
    });
  }

  updateMentionsIndex(provider: number, startPosition: number, lengthChange: number, shouldEditEditorIndex: boolean) {
    // console.log("startPosition",{startPosition})
    if (provider == null || provider == ProfileTypes.Unknown) {
      for (const key in this.composerEditorsModel) {
        // console.log(key);
        const editor: EditorModel = this.composerEditorsModel[key];

        editor.Mentions?.forEach((x) => {
          // console.log(x.Name, {EditorIndexStart: x.EditorIndexStart});
          if (x.EditorIndexStart > startPosition) {
            // console.log("Mention is after the url, changing indexes", {lengthChange});
            // console.log({IndexStart:x.IndexStart});
            x.IndexStart += lengthChange;
            x.IndexEnd += lengthChange;

            // console.log(`Converted to: ${x.IndexStart}`);
            const element = document.querySelector(`[data-id="${x.Reference}"]`);

            element.setAttribute("data-index", x.IndexStart.toString());

            if (shouldEditEditorIndex) {
              x.EditorIndexStart = parseInt(x.EditorIndexStart.toString()) + lengthChange;
              element.setAttribute("data-editorindex", x.EditorIndexStart.toString());
            }
          }
        });
      }
    } else {
      const editor = this.getEditorToModify(provider);

      editor.Mentions?.forEach((x) => {
        if (x.EditorIndexStart > startPosition) {
          x.IndexStart += lengthChange;
          x.IndexEnd += lengthChange;

          // console.log(x.IndexStart);

          // TODO: Edwin, this has a TS-2339 error on it
          // let element = document.querySelector(`[data-id="${x.Reference}"][data-editorindex="${x.editorindexstart}"]`);
          const element = document.querySelector(`[data-id="${x.Reference}"]`);

          element.setAttribute("data-index", x.IndexStart.toString());
          if (shouldEditEditorIndex) {
            x.EditorIndexStart = parseInt(x.EditorIndexStart.toString()) + lengthChange;
            element.setAttribute("data-editorindex", x.EditorIndexStart.toString());
          }
        }
      });
    }
  }

  updateUrlMetadataForAllEditors(url: string, metadata: UrlMetadata[]) {
    // console.log("updateUrlMetadataForAllEditorsCalled");
    this.composerEditorsModel.All.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkMetadata(element, metadata);
    });

    this.composerEditorsModel.TwitterAccount.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkMetadata(element, metadata);
    });

    this.composerEditorsModel.LinkedIn.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkMetadata(element, metadata);
    });

    this.composerEditorsModel.InstagramAccount.links.forEach((element) => {
      if (element.BaseUrl == url) setLinkMetadata(element, metadata);
    });
  }

  updateShortenParametersForAllEditors(link: Link) {
    if (this.customizeMode) return;
    this.updateEditorModelWithShortenParameters(this.composerEditorsModel.All, link);
    this.updateEditorModelWithShortenParameters(this.composerEditorsModel.Facebook, link);
    this.updateEditorModelWithShortenParameters(this.composerEditorsModel.TwitterAccount, link);
    this.updateEditorModelWithShortenParameters(this.composerEditorsModel.LinkedIn, link);
    this.updateEditorModelWithShortenParameters(this.composerEditorsModel.InstagramAccount, link);
  }

  modifyTrackingUrlForAllEditors(link: Link) {
    if (this.customizeMode) return;
    this.updateEditorModelWithTrackingParameters(this.composerEditorsModel.All, link);
    this.updateEditorModelWithTrackingParameters(this.composerEditorsModel.Facebook, link);
    this.updateEditorModelWithTrackingParameters(this.composerEditorsModel.TwitterAccount, link);
    this.updateEditorModelWithTrackingParameters(this.composerEditorsModel.LinkedIn, link);
    this.updateEditorModelWithTrackingParameters(this.composerEditorsModel.InstagramAccount, link);
  }

  private updateEditorModelWithShortenParameters(editorModel: EditorModel, link: Link) {
    editorModel.links.forEach((element) => {
      if (getTrackingUrl(element) == getTrackingUrl(link)) {
        element.IsShorten = link.IsShorten;
        element.ShortenUrl = link.ShortenUrl;
        element.ShortenDomain = link.ShortenDomain;
        element.ShortenGroupId = link.ShortenGroupId;
      }
    });
  }

  private updateEditorModelWithTrackingParameters(editorModel: EditorModel, link: Link) {
    const changedLinkTrackingUrl = link.BaseUrl;

    editorModel.links.forEach((element) => {
      const elementLinkTrackingUrl = element.BaseUrl;
      if (elementLinkTrackingUrl == changedLinkTrackingUrl) {
        element.BaseUrl = link.BaseUrl;
        element.IsTracking = link.IsTracking;
        element.lastTrackingUrlState = link.lastTrackingUrlState;
        element.TrackingUrl = link.TrackingUrl;
        element.UtmCampaign = link.UtmCampaign;
        element.UtmContent = link.UtmContent;
        element.UtmMedium = link.UtmMedium;
        element.UtmSource = link.UtmSource;
        element.UtmTerm = link.UtmTerm;
      }
    });
  }

  setEmployeeDataToProvider(shareModel: ShareLinkedInOverlayData, provider: ProfileTypes) {
    const editor = this.getEditorToModify(provider);
    editor.ShareOnBehalfOfEmployees = shareModel.sharing;
    editor.LikeOnBehalfOfEmployees = shareModel.like;
    editor.LinkedInShareCommentary = shareModel.shareText;
    editor.ExcludeEmployees = "";
    editor.IncludedEmployees = shareModel.employees
      .filter((x) => x.Selected)
      .map((x) => x.Id)
      .join(",");
    // editor.EmployeeGroupNames = shareModel.employeeGroupsConfig.SelectionType;
    editor.EmployeeGroupsConfig = shareModel.employeeGroupsConfig;
    editor.Employees = shareModel.employees;
  }

  clearEmployees(provider: number) {
    const editor = this.getEditorToModify(provider);
    editor.ShareOnBehalfOfEmployees = false;
    editor.LikeOnBehalfOfEmployees = false;
    editor.LinkedInShareCommentary = "";
    editor.ExcludeEmployees = "";
    editor.IncludedEmployees = "";
    editor.EmployeeGroupNames = "none";

    editor.EmployeeGroupsConfig = {
      EmployeeGroups: [],
      SelectionType: EmployeeGroupSelectionTypeEnum.None,
    };

    editor.Employees.forEach((x) => {
      x.Selected = false;
    });
  }

  setLinksEnabledFlags(newLinks: Link[], provider: number, selectedProfiles: ISelectedProfile[], source: string) {
    if (provider == ProfileTypes.Unknown) {
      this.updateLinksIfAppropriate(ProfileTypes.Facebook, newLinks);
      this.updateLinksIfAppropriate(ProfileTypes.InstagramAccount, newLinks);
      this.updateLinksIfAppropriate(ProfileTypes.LinkedIn, newLinks);

      //To remove the link from the links property in the Twitter editor so that the metadata goes away when link is not present
      //on the text;

      if (source == "api") {
        this.updateLinksIfAppropriate(ProfileTypes.TwitterAccount, newLinks);
      } else if (source == "user") {
        const twitterEditor = this.getEditorToModify(ProfileTypes.TwitterAccount);

        twitterEditor.links = this.getCopyOfLinksDependingOnProfileType(newLinks, ProfileTypes.TwitterAccount);
      } else {
        console.warn("setLinksEnabledFlags UNEXPECTED SOURCE: " + source);
      }
    }

    return newLinks;
  }

  private updateLinksIfAppropriate(profileType: ProfileTypes, newLinks: Link[]) {
    const editor = this.getEditorToModify(profileType);

    if (editor.links.length == 0) {
      editor.links = this.getCopyOfLinksDependingOnProfileType(newLinks, profileType);
    } else if (editor.links.length == newLinks.length) {
      //See if a new link is the extension of an existing link.
      const linksToBeUpdated = [];
      editor.links.forEach((oldLink, index) => {
        const oldLinkClone = FromContentLink(oldLink);
        newLinks.forEach((newLink) => {
          const newLinkClone = FromContentLink(newLink);
          const newLinkCloneTrackingUrl = getTrackingUrl(newLinkClone);
          const oldLinkCloneTrackingUrl = getTrackingUrl(oldLinkClone);

          if (
            newLinkCloneTrackingUrl.startsWith(oldLinkCloneTrackingUrl) &&
            newLinkCloneTrackingUrl != oldLinkCloneTrackingUrl
          ) {
            linksToBeUpdated.push({
              oldLinkIndex: index,
              newLink: newLinkClone,
            });
          }
        });
      });

      linksToBeUpdated.forEach((updatedLink) => {
        updatedLink.newLink.ProfileType = profileType;
        editor.links[updatedLink.oldLinkIndex] = updatedLink.newLink;
      });
    }
  }

  private getCopyOfLinksDependingOnProfileType(newLinks: Link[], profileType: ProfileTypes) {
    return newLinks.map((x) => {
      const copyLink = Object.assign({}, x);

      copyLink.ProfileType = profileType;

      return copyLink;
    });
  }

  updateLinkFromEditor(provider: number, link: Link, index: number, customEvent: LinkEventEnum) {
    const editor = this.getEditorToModify(provider);

    editor.links[index] = link;

    this.onLinksModelChange.emit({
      Event: customEvent,
      links: this.getLinksToSendToServer(),
    });
  }

  updateLinkMetadataFromEditor(provider: number, urlMetadata: UrlMetadata[], index: number) {
    const editor = this.getEditorToModify(provider);

    setLinkMetadata(editor.links[index], urlMetadata);

    this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());
  }

  removeLinkFromEditor(provider: number, link: Link, editorText: string) {
    if (!this.customizeMode) {
      this.removeLinkInternal(ProfileTypes.Facebook, link);

      if (editorText?.indexOf(htmlEncode(getTrackingUrl(link))) === -1)
        this.removeLinkInternal(ProfileTypes.TwitterAccount, link);

      this.removeLinkInternal(ProfileTypes.InstagramAccount, link);
      this.removeLinkInternal(ProfileTypes.LinkedIn, link);
      this.removeLinkInternal(ProfileTypes.Unknown, link);
    } else {
      this.removeLinkInternal(provider, link);
    }

    this.onLinksMetadataModelChange.emit(this.getLinksToSendToServer());

    this.onLinksModelChange.emit({
      Event: LinkEventEnum.RemoveLink,
      links: this.getLinksToSendToServer(),
    });
  }

  private removeLinkInternal(profileType: ProfileTypes, link: Link) {
    const editor = this.getEditorToModify(profileType);

    if (!editor || !editor.links) return;

    editor.links = editor.links.map((x) => FromContentLink(x)).filter((x) => getDisplayUrl(x) != getDisplayUrl(link));
  }

  private initializeRawText() {
    const firstAvailableText =
      this.composerEditorsModel.Facebook?.rawText ??
      this.composerEditorsModel.TwitterAccount?.rawText ??
      this.composerEditorsModel.InstagramAccount?.rawText ??
      this.composerEditorsModel.LinkedIn?.rawText;

    if (!this.composerEditorsModel.Facebook?.rawText) {
      this.composerEditorsModel.Facebook.rawText = firstAvailableText;
    }

    if (!this.composerEditorsModel.TwitterAccount?.rawText) {
      this.composerEditorsModel.TwitterAccount.rawText = firstAvailableText;
    }

    if (!this.composerEditorsModel.InstagramAccount?.rawText) {
      this.composerEditorsModel.InstagramAccount.rawText = firstAvailableText;
    }

    if (!this.composerEditorsModel.LinkedIn?.rawText) {
      this.composerEditorsModel.LinkedIn.rawText = firstAvailableText;
    }
  }

  initializeMentions() {
    const facebookEditor = this.getEditorToModify(ProfileTypes.Facebook);
    const linkedInEditor = this.getEditorToModify(ProfileTypes.LinkedIn);
    const instagramEditor = this.getEditorToModify(ProfileTypes.InstagramAccount);
    const twitterEditor = this.getEditorToModify(ProfileTypes.TwitterAccount);

    const facebookMentions = getMentionsFromQuillText(facebookEditor.text, ProfileTypes.Facebook).mentions;

    const linkedInMentions = getMentionsFromQuillText(linkedInEditor.text, ProfileTypes.LinkedIn).mentions;

    const instagramMentions = getMentionsFromQuillText(instagramEditor.text, ProfileTypes.InstagramAccount).mentions;

    const twitterMentions = getMentionsFromQuillText(twitterEditor.text, ProfileTypes.TwitterAccount).mentions;

    this.composerEditorsModel.Facebook.Mentions = facebookMentions;

    this.composerEditorsModel.LinkedIn.Mentions = linkedInMentions;

    this.composerEditorsModel.InstagramAccount.Mentions = instagramMentions;

    this.composerEditorsModel.TwitterAccount.Mentions = twitterMentions;
  }

  public reset() {
    this.customizeMode = false;
    this.editMode = false;
    // console.log('composer.service reset');
    this.composerEditorsModel = {
      All: {links: []},
      Facebook: {links: []},
      InstagramAccount: {links: []},
      TwitterAccount: {links: []},
      LinkedIn: {links: []},
    };
    this.quillMentionsModules = [];
  }

  public getMediaFilesByProvider(provider: number | ProfileTypes) {
    return this.getEditorToModify(provider).MediaFiles;
  }

  public getEditorToModify(provider: number | ProfileTypes): EditorModel {
    let editorToModify: string;

    if (provider == null || provider == ProfileTypes.Unknown) {
      editorToModify = "All";
    } else {
      editorToModify = ProfileTypes[provider].toString();
    }

    return this.composerEditorsModel[editorToModify];
  }

  public setEditorLanguage(provider: number | ProfileTypes, language: string) {
    const editor = this.getEditorToModify(provider);

    editor.inputLanguage = language;
  }

  public getEditorLanguage(provider: number | ProfileTypes) {
    const editor = this.getEditorToModify(provider);
    return editor.inputLanguage ?? "english";
  }

  public getLinksToSendToServer() {
    // console.log("getLinksToSendToServer", this.composerEditorsModel.TwitterAccount);

    const facebookLinks = [...(this.composerEditorsModel.Facebook?.links?.filter((x) => x != null && true) ?? [])];

    const twitterLinks = [...(this.composerEditorsModel.TwitterAccount?.links?.filter((x) => x != null && true) ?? [])];

    const instagramLinks = [
      ...(this.composerEditorsModel.InstagramAccount?.links?.filter((x) => x != null && true) ?? []),
    ];

    const linkedInLinks = [...(this.composerEditorsModel.LinkedIn?.links?.filter((x) => x != null && true) ?? [])];

    return facebookLinks.concat(twitterLinks).concat(instagramLinks).concat(linkedInLinks);
  }

  public updateOldUrlsStartPosition(oldUrls: Link[], newLinkModels: NewLinkModel[]) {
    oldUrls.forEach((oldUrl) => {
      // console.log(`Searching for `+getTrackingUrl(oldUrl));
      const link = newLinkModels.find((x) => x.Url == getTrackingUrl(oldUrl));

      if (link != null) {
        // console.log("Found the url, setting the index to "+link.Index);
        // console.log({oldUrl});
        // console.log({newLinkModels});
        oldUrl.startPosition = link.Index;
      }
    });

    return oldUrls;
  }

  reevaluateDynamicParameters() {
    this.composerEditorsModel.All.links.forEach((link, index) => {
      // console.log(link);
      if (hasDynamicParameters(link)) {
        link.lastTrackingUrlState = link.TrackingUrl;

        link.TrackingUrl = getTrackingUrl(link);

        // console.log({TrackingUrl: getTrackingUrl(link)});

        this.linkUpdated.emit({
          link: link,
          Event: LinkEventEnum.UpdateTracking,
          index: index,
        });

        if (link.IsShorten) {
          this.reshortenUrl(link.ShortenDomain, link.ShortenGroupId, link, index);
        }
      }
    });
  }

  private CopyEmployeeSharingInfoIntoLinkedInEditor() {
    this.composerEditorsModel.LinkedIn.ShareOnBehalfOfEmployees =
      this.composerEditorsModel.All.ShareOnBehalfOfEmployees;
    this.composerEditorsModel.LinkedIn.LikeOnBehalfOfEmployees = this.composerEditorsModel.All.LikeOnBehalfOfEmployees;
    this.composerEditorsModel.LinkedIn.LinkedInShareCommentary = this.composerEditorsModel.All.LinkedInShareCommentary;
    this.composerEditorsModel.LinkedIn.ExcludeEmployees = this.composerEditorsModel.All.ExcludeEmployees;
    this.composerEditorsModel.LinkedIn.IncludedEmployees = this.composerEditorsModel.All.IncludedEmployees;
    this.composerEditorsModel.LinkedIn.EmployeeGroupNames = this.composerEditorsModel.All.EmployeeGroupNames;
    this.composerEditorsModel.LinkedIn.Employees = this.composerEditorsModel.All.Employees;
  }

  mapProfileToSelectedProfile(profile: IProfile, editing: boolean) {
    let linkedProfiles = [];
    const listOfIds = this.stateService.getWithType<string[]>(this.stateService.selectedProfilesKey) ?? [];

    if (profile.Type === ProfileTypes.Facebook && profile.Subtype === "FacebookGroup") {
      const personalProfile = {
        ...profile,
        Name: profile.AssociatedSource.ScreenName,
        Picture: profile.AssociatedSource.UserPicture,
      };

      linkedProfiles =
        profile.LinkedProfiles.map((x) => {
          return {...x.ChildProfile};
        }) ?? [];

      linkedProfiles.push(personalProfile);
    }

    return {
      profile,
      selected: listOfIds.some((x) => x == profile.Id) && !editing,
      linkedPublishAsProfilesFbGroup: linkedProfiles,
    };
  }

  public get selectedProfiles(): ISelectedProfile[] {
    return this._selectedProfiles ?? [];
  }

  public set selectedProfiles(value: ISelectedProfile[]) {
    this.stateService.set(
      this.stateService.selectedProfilesKey,
      value.map((x) => x.profile.Id),
    );
    this._selectedProfiles = value;

    this.selectedChannels$.next(this._selectedProfiles);
  }

  reshortenUrl(domain: string, groupId: string, link: Link, index: number) {
    const currentDisplay = getTrackingUrl(link);
    this.integrationService.shortenUrl(currentDisplay, domain, groupId).subscribe((shortenedUrl: string) => {
      link.lastShortenUrl = link.ShortenUrl;
      link.IsShorten = true;
      link.ShortenUrl = shortenedUrl;
      link.ShortenDomain = domain;
      link.ShortenGroupId = groupId;
      this.linkUpdated.emit({
        link: link,
        Event: LinkEventEnum.ReshortenUrl,
        index: index,
      });
    });
  }
}

export interface ComposerModel {
  All: EditorModel;
  Facebook: EditorModel;
  TwitterAccount: EditorModel;
  InstagramAccount: EditorModel;
  LinkedIn: EditorModel;
}

export interface MediaFinishedUploading {
  provider: ProfileTypes;
}

export interface CustomizeModeModel {
  hasInstagram: boolean;
  hasFacebook: boolean;
  hasTwitter: boolean;
  hasLinkedIn: boolean;
}
