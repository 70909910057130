import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {IZapierProcessedProfile} from "@shared/composer/composer.component";
import {ZapierService} from "@shared/utils/zapier/zapier.service";
import {IProfile} from "../profile.interface";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ProfileService} from "../profile.service";

@UntilDestroy()
@Component({
  selector: "app-add-zapier",
  styleUrls: ["./add-zapier.component.scss"],
  templateUrl: "./add-zapier.component.html",
})
export class AddZapierComponent implements OnInit, OnChanges {
  @Input() instagramProfiles: IProfile[];
  @Input() isBeingConnectedToZapier: boolean;
  @Output() zapierProcessDone = new EventEmitter<boolean>();

  step = 1;
  profiles: IZapierProcessedProfile[] = [];
  profileIndexToModify: number;
  error: string;

  constructor(
    private zapierService: ZapierService,
    private profileService: ProfileService,
  ) {
    this.zapierService
      .IsAuthenticated()
      .pipe(untilDestroyed(this))
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) this.step = 0;
      });
  }

  ngOnInit(): void {
    this.profileService.profileModifiedEvent.pipe(untilDestroyed(this)).subscribe((profile: IProfile) => {
      const profileToModify = this.profiles.find((x) => x.profile.Id == profile.Id);
      if (profileToModify == null) return;
      profileToModify.confirmed = profile.IsZapierWebhookEnabled;
    });
  }

  getNextProcessedProfileIndex(): number {
    return this.profiles.findIndex((x) => x.processed == false && x.selected);
  }

  onUserAuthenticatedWithZapier() {
    this.step = 1;
  }

  startConfiguringZapierProfile(): number {
    this.profileIndexToModify = this.getNextProcessedProfileIndex();
    return this.profileIndexToModify;
  }

  onNextPage() {
    let index;
    this.step += 1;
    if (this.step == 2) {
      index = this.startConfiguringZapierProfile();

      if (index == -1) {
        this.step = 4;
      }
    }

    if (this.step == 3) {
      index = this.startConfiguringZapierProfile();

      if (index == -1) {
        this.step = 4;
      }
    }

    if (this.step == 4) {
      index = this.startConfiguringZapierProfile();

      if (index > 0) {
        this.step = 2;
      }
    }
  }

  Finished() {
    this.zapierProcessDone.emit();
  }

  onSkipProfile() {
    this.profiles[this.profileIndexToModify].processed = true;
    this.profiles[this.profileIndexToModify].configured = false;
    this.profiles[this.profileIndexToModify].confirmed = false;
    this.onNextPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instagramProfiles && changes.instagramProfiles.currentValue) {
      this.instagramProfiles = changes.instagramProfiles.currentValue;
      this.profiles = this.instagramProfiles.map((x) => {
        return {
          processed: false,
          selected: true,
          configured: false,
          confirmed: false,
          profile: x,
        };
      });
      if (this.isBeingConnectedToZapier) {
        this.profileIndexToModify = 0;
        this.step = 2;
      }
    }
  }
}
