import {Component, OnInit} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-header-right",
  templateUrl: "./header-right.component.html",
  styleUrls: ["./header-right.component.scss"],
})
export class HeaderRightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
