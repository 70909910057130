import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {AuthenticationService} from "./authentication.service";
import {catchError, tap} from "rxjs/operators";

export const INTERCEPTOR_SKIP_AUTH = "X-Skip-Auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    const clonedReq = this.addHeaders(request, token);

    return next.handle(clonedReq).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (this.containsNewToken(event)) {
            this.authService.updateUserWithRefreshedToken(event.headers.get("refreshedToken"));
          }
        }
        return event;
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.url.search("/Account/Login") !== -1) {
            this.authService.logout();
          }
        }
        return throwError(err);
      }),
    );
  }

  private containsNewToken(response: HttpResponse<any>): boolean {
    return response.headers.has("refreshedToken");
  }

  private addHeaders(request: HttpRequest<any>, token: string): HttpRequest<any> {
    let headers = request.headers;
    if (token && !headers.has(INTERCEPTOR_SKIP_AUTH)) {
      headers = headers.append("Authorization", `Bearer ${token}`);
    } else {
      headers = headers.delete(INTERCEPTOR_SKIP_AUTH);
    }

    if (request.url.includes("content/share") && request.method == "POST") {
      headers = headers.append("share_username", this.authService.currentUserValue.email);
      headers = headers.append("share_userId", this.authService.currentUserValue.Id);
    }

    const clone = request.clone({headers});
    return clone;
  }
}
