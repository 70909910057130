import {ContentModel} from "@shared/publisher/content.interface";
import {ComposerValidation} from "./ComposerValidation";
import {ISelectedProfile} from "../composer.component";
import {LinkedInMediaValidation} from "./media-validation/LinkedInMediaValidation";
import {ProfileTypes} from "@shared/channel/profile-types.enum";

export class LinkedInComposerValidation extends ComposerValidation {
  NoContent: boolean;
  maximumImagePixels = 36152320;

  FewVideos: boolean;
  VideoAndImages: boolean;
  ExceedingVideoSize: boolean;
  HasImagesWithNotSupportedFormat: boolean;
  HasImagesWithExceedingPixels: boolean;
  TooManyMedia: boolean;

  constructor() {
    super();
    this.Reset();
  }

  IsValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    contentModel.MediaFiles = contentModel.MediaFiles || [];

    const linkedInMedia = contentModel.MediaFiles?.filter((file) => file.EnabledForLinkedIn);

    const anyLinkAttached = contentModel.Links?.some((x) => x.ProfileType == ProfileTypes.LinkedIn);

    this.NoContent = !contentModel.LinkedInText && !linkedInMedia.length && !anyLinkAttached;

    this.TooManyMedia = linkedInMedia.length > 9;

    const videos = contentModel.MediaFiles.filter(
      (file) => file.MediaType.startsWith("video") && file.EnabledForLinkedIn,
    );
    const images = contentModel.MediaFiles.filter(
      (file) => !file.MediaType.startsWith("video") && file.EnabledForLinkedIn,
    );
    const hasMoreThen1Video = videos.length > 1;
    const hasAnyVideos = videos.length > 0;
    const hasAnyImages = images.length > 0;

    let mediaFilesValid = true;

    if (contentModel.MediaFiles) {
      contentModel.MediaFiles.filter((file) => file.EnabledForLinkedIn).forEach((element) => {
        const linkedInMediaValidation = new LinkedInMediaValidation();

        if (element.MediaType.startsWith("image")) {
          const supportedImageTypes = ["image/gif", "image/png", "image/jpg", "image/jpeg"];

          linkedInMediaValidation.ImageFormatError = !supportedImageTypes.some((x) => x == element.MediaType);

          const pixelCount = element.Width * element.Height;

          linkedInMediaValidation.ExceedingPixelError = pixelCount > this.maximumImagePixels;
        } else if (element.MediaType.startsWith("video")) {
          linkedInMediaValidation.SizeError = element.SizeBytes > this.MbToBytes(200);
        }

        if (mediaFilesValid) {
          mediaFilesValid = linkedInMediaValidation.IsValid();
        }

        if (!this.ExceedingVideoSize) {
          this.ExceedingVideoSize = linkedInMediaValidation.SizeError ?? false;
        }

        if (!this.HasImagesWithNotSupportedFormat) {
          this.HasImagesWithNotSupportedFormat = linkedInMediaValidation.ImageFormatError;
        }

        if (!this.HasImagesWithExceedingPixels) {
          this.HasImagesWithExceedingPixels = linkedInMediaValidation.ExceedingPixelError;
        }

        element.linkedInValidation = linkedInMediaValidation;
      });
    }

    this.FewVideos = hasMoreThen1Video;
    this.VideoAndImages = hasAnyVideos && hasAnyImages;

    const negationErrors = [
      this.ExceedingVideoSize,
      this.VideoAndImages,
      this.HasImagesWithNotSupportedFormat,
      this.FewVideos,
      this.HasImagesWithExceedingPixels,
      this.NoContent,
      this.TooManyMedia,
    ];

    return negationErrors.every((x) => !x) && mediaFilesValid;
  }

  LocalReset() {
    this.FewVideos = false;
    this.VideoAndImages = false;
    this.ExceedingVideoSize = false;
    this.HasImagesWithNotSupportedFormat = false;
    this.HasImagesWithExceedingPixels = false;
    this.NoContent = false;
    this.TooManyMedia = false;
  }
}
