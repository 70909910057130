import XRegExp from "xregexp";
import {ThemeService} from "@shared/theme/theme.service";
import {ContentMention, Link, LinkChangesModel, NewLinkModel} from "@shared/publisher/content.interface";
import {EventEmitter} from "@angular/core";
import {getTrackingUrl} from "@shared/utils/link.function";
import {ComposerService} from "../composer.service";
import {ProfileTypes} from "@shared/channel/profile-types.enum";

export const REGEXP = {
  X_URL: XRegExp(
    "(^|\\s)(?<url>(http|ftp|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-?]*[(\\(|\\))]*[\\w.,@?^=%&:/~+#-]))|(^|\\s)(?<tag>#[a-zA-Z\\d-_]+)|(^|\\s)(?<mention>@[a-zA-Z\\d-_.]+)",
    "gi",
  ),
  X_URL2: XRegExp(
    "(^|\\s)(?<url>(http|ftp|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-?]*[(\\(|\\))]*[\\w.,@?^=%&:/~+#-]))|(^|\\s)(?<tag>#[a-zA-Z\\d-_]+)|(^|\\s)(?<mentionspace>@[a-zA-Z\\d-_.]+( )?([a-zA-Z\\d-_.]+))",
    "gi",
  ),
  X_URLWITHSPACE: XRegExp(
    "(^|\\s)(?<urlwithspace>(http|ftp|https)://([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-?]*[(\\(|\\))]*[\\w.,@?^=%&:/~+#-])[ ])",
    "gi",
  ),
};

export class ComposerEditor {
  allowMentionsWithSpace: boolean;

  public onUrlsFoundOnText = new EventEmitter<LinkChangesModel>();

  public editModeFirstEdit = false;

  quillEditorUpdated = new EventEmitter<any>();

  config = {attributes: false, childList: true, subtree: false};

  //Watches for changes inside the HTML of mentions so they can be destroyed if the HTML doesnt match the data-value.
  private mutationObserver = new MutationObserver((mutations, observer) =>
    this.onMentionMutated(mutations, observer, this.quill),
  );

  constructor(
    public quill: any,
    private editorElement: Element,
    private themeService: ThemeService,
    private composerService: ComposerService,
    private provider: ProfileTypes,
  ) {
    this.themeService.isDarkTheme.subscribe((isDarkTheme) => {
      this.linkStyle = {
        color: ComposerEditor.getLinkColorDependingOnTheme(isDarkTheme),
        underline: false,
      };
      this.textStyle = {
        color: ComposerEditor.getTextColorDependingOnTheme(isDarkTheme),
        underline: false,
      };
    });
    this.cleanClipboardFromStyle();
  }
  private debugStyle = {color: "#850000", underline: true};
  private linkStyle = {color: "#5d9cec", underline: false};
  private textStyle = {};

  static getTextColorDependingOnTheme(isDarkTheme: boolean) {
    if (isDarkTheme) {
      return "rgba(250, 250, 250, 0.9)";
    }

    return "rgba(61, 61, 61)";
  }

  static getLinkColorDependingOnTheme(isDarkTheme: boolean) {
    if (isDarkTheme) {
      return "#5d9cec";
    }

    return "#1772e8";
  }

  setInitialContent(content: any[]) {
    if (content) {
      this.quill.setContents(content);
    }
  }

  addMentionToBeObserved(editorIndex: number) {
    const nativeElement = this.editorElement;

    const mentionObject = nativeElement.querySelector(`span[data-editorindex='${editorIndex}']`);

    this.mutationObserver.observe(mentionObject, this.config);
  }

  addMentionsToEditor(mentions: ContentMention[]) {
    mentions = mentions.sort((a, b) => (b.EditorIndexStart ?? b.IndexStart) - (a.EditorIndexStart ?? a.IndexStart));

    for (const mention of mentions) {
      //Since EditorIndexStart is a new field, old post will have EditorIndexStart as null, so we are initializing it as the indexstart.
      //Newer posts will always have EditorIndexStart
      const indexToStart = mention.EditorIndexStart ?? mention.IndexStart; //- numberOfCharactersAddedByAnchor;

      const mentionLength = mention.Name.length;
      this.quill.deleteText(indexToStart, mentionLength, "api");

      this.quill.insertEmbed(
        indexToStart,
        "mention",
        {
          index: mention.IndexStart,
          denotationChar: mention.SourceType == ProfileTypes.TwitterAccount ? "@" : "",
          id: mention.Reference,
          value: mention.SourceType == ProfileTypes.TwitterAccount ? mention.Name.replace("@", "") : mention.Name,
          sourcetype: mention.SourceType,
          newmention: false,
          editorindex: indexToStart,
        },
        "api",
      );

      this.addMentionToBeObserved(indexToStart);
    }
  }

  removeMentionsFromEditor() {
    const contents = this.quill.getContents();

    const contentsToAdd = [];

    contents.forEach((content) => {
      const insert = content["insert"];
      if (insert != null) {
        if (insert?.mention != null) {
          content["insert"] = insert.mention.value;
        }
        contentsToAdd.push(content);
      }
    });

    this.quill.setContents(contentsToAdd, "silent");

    this.quill.setSelection(this.quill.getLength() - 1);

    this.quill.focus();
  }

  addTrackingToUrl(link: Link) {
    const contents = this.quill.getContents();

    contents.ops = this.replaceInOps(contents.ops, link.BaseUrl, link.TrackingUrl);

    this.quill.updateContents(contents);
  }

  removeTrackingForUrl(link: Link) {
    const contents = this.quill.getContents();

    contents.ops = this.replaceInOps(contents.ops, link.lastTrackingUrlState, link.BaseUrl);

    this.quill.updateContents(contents);

    link.lastTrackingUrlState = null;
  }

  updateTrackingUrl(link: Link) {
    const contents = this.quill.getContents();

    contents.ops = this.replaceInOps(contents.ops, link.lastTrackingUrlState, link.TrackingUrl);

    this.quill.updateContents(contents);
  }

  replaceInOps(ops: any[], src: string, target: string): any[] {
    const updateOps = [];

    ops.forEach((op) => {
      const insert = op["insert"];
      if (typeof insert === "string" && insert.indexOf(src) > -1) {
        const replacedValue = insert.replace(src, target);

        updateOps.push({delete: insert.length});
        updateOps.push({
          insert: replacedValue,
          attributes: op.attributes,
        });
      } else {
        const retainLength = insert?.mention != null ? 1 : insert.length;

        if (op.attributes)
          updateOps.push({
            retain: retainLength,
            attributes: op.attributes,
          });
        else updateOps.push({retain: retainLength});
      }
    });

    return updateOps;
  }

  setInitialText(text: string): Promise<any> {
    if (text == null || text == undefined) text = "";

    const promise = new Promise((resolve, reject) => {
      if (this.quill.getLength() === 1) {
        setTimeout(() => {
          this.quill.setText(text);
          resolve(null);
        });
      }
    });

    return promise;
  }

  insertTextAtCursorPosition(text: string) {
    const selection = this.quill.getSelection(true);
    this.quill.insertText(selection.index, text);
  }

  insertTextAtEnd(text: string) {
    this.quill.insertText(this.quill.getLength() - 1, text, "user");
  }

  getCursorPosition(): number {
    //Seems that mentions are treated as one space
    const range = this.quill.getSelection(true);
    if (range) {
      if (range.length == 0) {
        let cursorPosition = range.index;

        this.getEditorMentions().forEach((element) => {
          if (element.index < cursorPosition) {
            //-1 because the mention is already represented as a space in quill operations.
            cursorPosition = cursorPosition + element.value.length - 1;
          }
        });
        return cursorPosition;
      }
    }

    return this.quill.getLength() - 1;
  }

  getEditorText(): string {
    const text: string = this.quill.getText();

    return text;
  }

  getEditorMentions() {
    return this.quill
      .getContents()
      .filter((op) => op?.insert?.mention)
      .map((op) => op?.insert.mention);
  }

  getEditorTextWithMentionAsSpace(): string {
    return this.quill
      .getContents()
      .filter((op) => typeof op.insert === "string" || op.insert.mention)
      .map((op) => (op.insert.mention ? " " : op.insert))
      .join("");
  }

  getEditorTextWithMentionValue(): string {
    return this.quill
      .getContents()
      .filter((op) => typeof op.insert === "string" || op.insert.mention)
      .map((op) => (op.insert.mention ? op.insert.mention.value : op.insert))
      .join("");
  }

  public linkify(delta, oldDelta, source) {
    const text = this.getEditorTextWithMentionAsSpace();
    // console.log({delta});
    const matches = [];
    const urlsFoundOnText: NewLinkModel[] = [];

    const mentions = this.getEditorMentions().sort((a, b) => parseInt(b.index) - parseInt(a.index));

    const mentionsSortedAsc = mentions.sort((a, b) => parseInt(a.index) - parseInt(b.index));

    const retainValueObj = delta.ops.find((x) => x["retain"])?.retain;
    let retainValue = retainValueObj == undefined ? 0 : retainValueObj;
    const insertValue = delta.ops.find((x) => x["insert"])?.insert;
    const deleteValue = delta.ops.find((x) => x["delete"])?.delete;
    //If there is a newMention in the list of mentions;
    let isThereNewMention = false;

    //Since Quill takes every mention like 1 space the retainValue is not accurate so in this function we sum
    //the length of the mentions.
    retainValue = this.updateRetainValueWithMentionsLength(mentions, retainValue);

    if (source == "user") {
      const linksOnText: Link[] = [];
      mentions.forEach((mention) => {
        let mentionIndex = parseInt(mention["index"]);
        let editorIndex = parseInt(mention["editorindex"]);
        //isNewMention is only true when the mention was just added to the editor and this is the first time it gets proccessed by this linkify function.
        let isNewMention = mention["newmention"] == "true" || mention["newmention"] == true;
        const denotationChar = mention["denotationChar"];
        //
        const isChangeAfterMention = retainValue + denotationChar.length > editorIndex;

        // console.log({editorIndex});
        // console.log({isChangeAfterMention});
        // console.log({retainValue});
        // console.log({editorIndex});
        // console.log({isNewMention});

        if (isThereNewMention) {
          mention["newmention"] = false;
          isNewMention = false;
        }

        if (!isChangeAfterMention) {
          if (insertValue?.length > 0) {
            if (isNewMention) {
              //This happens because when we add a new mention, the caret where we do the change is right before where the new mention index will be, so
              //we dont need to sum the length of the change to the mention.
              //
              mention["newmention"] = false;
              isThereNewMention = true;
            } else {
              mentionIndex = mentionIndex + insertValue.length;
              editorIndex = editorIndex + insertValue.length;
            }
          }

          if (insertValue?.mention && !isNewMention) {
            const newMention = insertValue.mention;
            mentionIndex = mentionIndex + newMention.value.length + newMention.denotationChar.length;
            editorIndex = editorIndex + newMention.value.length + newMention.denotationChar.length;
          }

          if (deleteValue) {
            const deletedCharacters = parseInt(deleteValue);

            //This function scans for mentions that were deleted on the last change and if it detects one it returns the size of it so that we can substract the index properly.
            let deletedMentionsLength = this.getLengthOfDeletedMentionBeforePosition(oldDelta, mentions);

            if (deletedMentionsLength > 0) {
              //-1 because quill is already deleting 1 space.
              deletedMentionsLength -= 1;
            }

            mentionIndex = mentionIndex - deletedCharacters - deletedMentionsLength;
            editorIndex = editorIndex - deletedCharacters - deletedMentionsLength;

            //This code handles issue SW-2282 Mentions disappear when deleting a shortened URL before them (Facebook and LinkedIn)
            const editorLinks = this.composerService.getEditorToModify(this.provider)?.links ?? [];

            for (let index = 0; index < editorLinks.length; index++) {
              const link = editorLinks[index];

              const currentLinkPosition = text.indexOf(getTrackingUrl(link));

              if (link.IsLinkOnText && currentLinkPosition == -1) {
                linksOnText.push(link);

                if (mention.index > link.startPosition) {
                  mentionIndex = editorIndex;
                }
              }
            }
          }

          mention.index = mentionIndex;
          mention.editorindex = editorIndex;
        }

        if (isNewMention) {
          mention["newmention"] = false;
        }
      });

      linksOnText.forEach((link) => {
        link.IsLinkOnText = false;
      });
    }

    if (source === "api-copy") {
      return matches;
    }

    const regexToUse = this.allowMentionsWithSpace ? REGEXP.X_URL2 : REGEXP.X_URL;

    XRegExp.forEach(text, regexToUse, (m) => {
      m.text = m.groups.tag || m.groups.url || m.groups.mention;
      if (m.text != null) {
        m.length = m.text.length;
        matches.push(m);
      }
    });

    XRegExp.forEach(text, REGEXP.X_URL, (m) => {
      if (m.groups.url) {
        const indexToAdd = m["0"].startsWith(" ") ? 1 : 0;

        const obj = {
          Url: m.groups.url,
          Index: m.index + indexToAdd,
        };

        mentionsSortedAsc.forEach((element) => {
          if (element.index < obj.Index) {
            obj.Index = obj.Index + element.value.length - 1;
          }
        });

        urlsFoundOnText.push(obj);
      }
    });

    const caretPosition = retainValue != null && retainValue != undefined ? retainValue : 0;

    this.onUrlsFoundOnText.emit({
      newLinks: urlsFoundOnText,
      caretPosition: caretPosition,
      changeLength: insertValue?.length > 0 ? insertValue.length : deleteValue != null ? deleteValue : 0,
      source: source,
      //If it is inserted by the api and the
      editModeFirstEdit: this.editModeFirstEdit && source == "api",
      currentText: this.getEditorTextWithMentionValue(),
      isDeleteOperation: deleteValue != null && deleteValue > 0,
    });

    if (this.editModeFirstEdit) this.editModeFirstEdit = false;

    matches.forEach((match) => {
      const trailingSpacesCount = match[0].length - match.text.length;
      match.index = match.index + trailingSpacesCount;
    });

    if (matches.length) {
      const ops = [];
      let currentPosition = 0;
      matches.forEach((match) => {
        if (match.index > currentPosition) {
          ops.push({
            retain: match.index - currentPosition,
            attributes: this.textStyle,
          });
        }

        ops.push({
          retain: match.text.length,
          attributes: this.linkStyle,
        });

        currentPosition = match.index + match.text.length;
      });

      if (currentPosition < text.length) {
        ops.push({
          retain: text.length - currentPosition,
          attributes: this.textStyle,
        });
      }

      this.quill.updateContents({ops: ops}, "user");
    } else {
      this.quill.updateContents(
        {
          ops: [
            {
              retain: text.length,
              attributes: this.textStyle,
            },
          ],
        },
        "user",
      );
    }
  }
  updateRetainValueWithMentionsLength(mentions: any, retainValue: number): any {
    let localRetainValue = retainValue;
    mentions.forEach((element) => {
      if (element.editorindex < localRetainValue) {
        localRetainValue = localRetainValue + element.value.length - 1;
      }
    });

    return localRetainValue;
  }
  getLengthOfDeletedMentionBeforePosition(oldDelta: any, mentions: any) {
    const deltaOldMentions = oldDelta.filter((x) => x.insert?.mention).map((x) => x.insert.mention);

    const mentionsNotFound = deltaOldMentions.filter(
      (oldMention) =>
        !mentions.some((localMention) => localMention.id == oldMention.id && localMention.index == oldMention.index),
    );

    if (mentionsNotFound.length == 0) return 0;

    return mentionsNotFound[0].value.length + mentionsNotFound[0].denotationChar.length;
  }

  onMentionMutated(mutationList: MutationRecord[], observer: MutationObserver, quill) {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        if (mutation.removedNodes.length > 0) {
          const targetElement = mutation.target as Element;
          const selection = quill.getSelection(true);
          targetElement.remove();
          quill.setSelection(selection.index);
        }
      }
    }
  }

  private cleanClipboardFromStyle() {
    this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const ops = [];
      delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === "string") {
          ops.push({
            insert: op.insert,
          });
        }
      });
      delta.ops = ops;
      return delta;
    });
  }
  unsubscribeFromSubscriptions() {
    this.mutationObserver.disconnect();
  }
}
