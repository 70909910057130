import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {Activity} from "@activity/activity.model";
import {IProfile} from "@channel/profile.interface";
import {LibraryService} from "@publisher/library.service";
import {ContentModel, EditorModel} from "@publisher/content.interface";
import {ReactionService} from "@activity/reactions/reaction.service";
import {ActivitySource} from "@shared/activity/activity.interface";
import {ISelectedProfile} from "@shared/composer/composer.component";
import {ComposerService} from "@shared/composer/composer.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export interface RetweetReplyData {
  action: "reply" | "retweet";
  activity: Activity;
  profile: IProfile;
}

@UntilDestroy()
@Component({
  selector: "app-retweet-reply",
  templateUrl: "./retweet-reply.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetweetReplyComponent extends PanelOverlay implements OnInit {
  profile: IProfile;
  activity: Activity;
  model: ContentModel = {};
  modelToSendOnSubmit: ContentModel = {};
  postSource: ActivitySource;

  selectedProfiles: ISelectedProfile[] = [];
  rawText = "";

  editorModel: EditorModel = {
    MediaFiles: [],
  };

  loading: boolean;
  action: "reply" | "retweet";
  error: string;

  constructor(
    dialogRef: PanelOverlayRef<RetweetReplyComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: RetweetReplyData,
    private reactionService: ReactionService,
    private libraryService: LibraryService,
    private cdr: ChangeDetectorRef,
    private composerService: ComposerService,
  ) {
    super(dialogRef, data);
    this.profile = data.profile;
    this.activity = data.activity;
    this.action = data.action;
  }

  ngOnInit() {
    if (this.activity) {
      this.postSource = this.getTwitterPostSource();

      this.model = this.activity.toTwitterContentModel();
    }

    this.selectedProfiles = [{profile: this.profile, selected: true}];
  }

  close(response?: any) {
    if (response == null) {
      response = {
        Successful: false,
        Reaction: null,
      };
    }
    this.composerService.reset();
    this.dialogRef.close(response);
  }

  onEditorChange(model: EditorModel) {
    this.rawText = model.rawText;
    this.modelToSendOnSubmit = {
      TwitterText: model.rawText,
    };
  }

  publish() {
    this.error = "";
    this.loading = true;

    if (this.action === "retweet" && !this.modelToSendOnSubmit.TwitterText) {
      this.share();
      return;
    }
    this.modelToSendOnSubmit.MessageType = "OneTime";
    this.modelToSendOnSubmit.AvailableForPublish = true;
    this.modelToSendOnSubmit.Approved = true;
    this.modelToSendOnSubmit.MediaFiles = this.composerService.composerEditorsModel?.TwitterAccount?.MediaFiles ?? [];

    this.modelToSendOnSubmit.MediaFiles.forEach((mediaFile) => {
      mediaFile.EnabledForTwitter = true;
    });

    if (this.action === "reply") {
      if (!this.modelToSendOnSubmit.TwitterText || !this.modelToSendOnSubmit.TwitterText.trim().length) {
        this.error = "A message is required to reply.";
        this.loading = false;
        return;
      }
      this.modelToSendOnSubmit.InReplyToTweet = this.activity.Id;
    } else if (this.action === "retweet") {
      this.modelToSendOnSubmit.QuotedTweetUrl = this.activity.Tweet.Permalink;
    }

    this.modelToSendOnSubmit.Profiles = [{Id: this.profile.Id}];

    this.libraryService
      .publish(this.modelToSendOnSubmit)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          const response = {
            Successful: true,
            Reaction: null,
          };
          this.close(response);
        },
        error: (response) => {
          console.error(response); // TODO: handle error
        },
        complete: () => (this.loading = false),
      });
  }

  share() {
    this.reactionService
      .share(this.profile.ExternalId, this.activity.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (reaction) => {
          const response = {
            Successful: true,
            Reaction: reaction,
          };
          this.close(response);
        },
        error: (response) => {
          console.error(response); // TODO: handle error
        },
        complete: () => (this.loading = false),
      });
  }

  getTwitterPostSource() {
    if (this.activity.Tweet && this.activity.Tweet.Original && this.activity.Tweet.IsRetweeted) {
      return {
        Id: "",
        Name: this.activity.Tweet.Original.Author.ScreenName,
        Picture: this.activity.Tweet.Original.Author.Picture,
        ScreenName: this.activity.Tweet.Original.Author.ScreenName,
        Type: 2,
        Url: this.activity.Tweet.Original.Author.Url,
        Subtype: "",
      };
    }
    return {
      Id: "",
      Name: this.activity.Author.ScreenName,
      Picture: this.activity.Author.Picture,
      ScreenName: this.activity.Author.ScreenName,
      Type: 2,
      Url: this.activity.Author.Url,
      Subtype: "",
    };
  }
}
