import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild, Output, EventEmitter} from "@angular/core";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {ColorService} from "@utils/color-service/color.service";
import {TagService} from "../tag.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Tag} from "@shared/publisher/content.interface";

@UntilDestroy()
@Component({
  selector: "app-tags-input",
  templateUrl: "./tags-input.component.html",
  styleUrls: ["./tags-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsInputComponent),
      multi: true,
    },
  ],
})
export class TagsInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  @Input() disabled: boolean;
  @Input() inputLabel: boolean;
  @Input() enableCreationOfTags = true;
  @Input() showAllContentTag = false;
  @Input() hasError: boolean;

  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  filteredTags: Observable<Tag[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  tags: Tag[];
  selectedTags: Tag[] = [];

  inputCtrl = new FormControl();

  selectingFromAutoComplete = true;

  latestSelectedEvent: MatAutocompleteSelectedEvent;

  private onChange: any = (_: Tag[]) => {};
  private onTouched: any = () => {};

  constructor(
    public tagService: TagService,
    private colorService: ColorService,
  ) {}

  ngOnInit() {
    this.tagService.allcontentTag.Selected = true;
    this.tagService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((tags: Tag[]) => {
        this.tags = tags;
        this.filteredTags = this.inputCtrl.valueChanges.pipe(
          startWith(null),
          map((tag: string | null) => (tag ? this._filter(tag) : this.unselectedTags)),
        );

        if (this.selectedTags.length) {
          this.tagService.allcontentTag.Selected = false;
          setTimeout(() => {
            this.selectedTags.forEach((tag) => {
              tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
              const updatedTag = this.tags.find((t) => t.Id === tag.Id);
              if (updatedTag) {
                tag = updatedTag;
              }
            });
          });
        }
      });
  }

  async add(event: MatChipInputEvent): Promise<void> {
    if (!this.enableCreationOfTags) return;

    if (this.selectingFromAutoComplete) {
      return;
    }

    const input = event.input;
    const value: string = event.value.toLowerCase();
    if (value == this.tagService.allcontentTag.Name) {
      this.tagService.allcontentTag.Selected = true;
      this.selectedTags = [];
      this.onChangeEvent(this.selectedTags);
      this.valueChanged.emit(this.tagService.allcontentTag.Selected);
    } else {
      if ((value || "").trim()) {
        this.tagService.allcontentTag.Selected = false;
        const alreadyAdded = this.selectedTags.find((tag) => tag.Name.toLowerCase() === value.toLowerCase());
        if (!alreadyAdded) {
          const existingInList = this.tags.find((tag) => tag.Name.toLowerCase() === value.toLowerCase());
          if (existingInList) {
            this.selectedTags.push(existingInList);
          } else {
            const color = await this.colorService.getRandomColorFromDefinedList();
            this.selectedTags.push({
              Name: value.trim(),
              ColorHex: color.Hex,
              UsedCount: 0,
              FgColorHex: this.colorService.generateFgColor(color.Hex),
              ColorName: color.Name,
            });
          }

          this.onChangeEvent(this.selectedTags);
          if (input) {
            input.value = "";
          }
          this.inputCtrl.setValue(null);
        }
      }
    }
  }

  remove(tag: Tag): void {
    if (tag.Name == this.tagService.allcontentTag.Name) {
      if (this.tagService.allcontentTag.Selected) {
        this.tagService.allcontentTag.Selected = false;
        this.onChangeEvent(this.selectedTags);
        this.inputCtrl.setValue(null);
        this.valueChanged.emit(this.tagService.allcontentTag.Selected);
      }
    } else {
      const index = this.selectedTags.indexOf(tag);
      if (index >= 0) {
        this.selectedTags.splice(index, 1);
        this.onChangeEvent(this.selectedTags);
        this.inputCtrl.setValue(null);
        this.valueChanged.emit(this.tagService.allcontentTag.Selected);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.enableCreationOfTags && !event.option.value.Id) return;

    this.latestSelectedEvent = event;
    if (!this.selectingFromAutoComplete) {
      return;
    }
    const selected: Tag = event.option.value;
    if (selected.Name == this.tagService.allcontentTag.Name) {
      this.tagService.allcontentTag.Selected = true;
      this.selectedTags = [];
      this.onChangeEvent(this.selectedTags);
      this.valueChanged.emit(this.tagService.allcontentTag.Selected);
    } else {
      this.tagService.allcontentTag.Selected = false;
      const alreadyAdded = this.selectedTags.find((tag) => tag.Name.toLowerCase() === selected.Name.toLowerCase());
      if (!alreadyAdded) {
        selected.FgColorHex = this.colorService.generateFgColor(selected.ColorHex);
        this.selectedTags.push(selected);
        this.onChangeEvent(this.selectedTags);
        this.valueChanged.emit(this.tagService.allcontentTag.Selected);
      }
      this.tagInput.nativeElement.value = "";
      this.inputCtrl.setValue(null);
    }
  }

  private _filter(value: string): Tag[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase();
      const filteredTags = this.unselectedTags.filter((tag) => tag.Name.toLowerCase().indexOf(filterValue) === 0);
      if (filteredTags && filteredTags.length) {
        return filteredTags;
      }
      if (this.selectedTags.find((tag) => tag.Name.toLowerCase() === filterValue)) {
        return null;
      }
      const bgColor = this.colorService.generateBgColor();
      return [
        {
          Name: value.trim(),
          ColorHex: bgColor,
          FgColorHex: this.colorService.generateFgColor(bgColor),
          UsedCount: 0,
          ColorName: "",
        },
      ];
    }
    return this.tags;
  }

  get unselectedTags(): Tag[] {
    return this.tags.filter(
      (x) => !this.selectedTags.some((t) => t.Name && x.Name && t.Name.toLowerCase() == x.Name.toLowerCase()),
    );
  }

  public registerOnChange(fn: (value: Tag[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: Tag[]): void {
    if (value) {
      this.selectedTags = value;
      this.onChangeEvent(this.selectedTags);
      this.valueChanged.emit(this.tagService.allcontentTag.Selected);
    } else {
      this.selectedTags = [];
      this.onChangeEvent(this.selectedTags);
    }
  }

  onTagNameInput(event: KeyboardEvent) {
    if (event.key.startsWith("Arrow")) {
      return;
    }
    const input = event.target as HTMLInputElement;
    input.value = input.value.toLowerCase();
    this.selectingFromAutoComplete = false;
  }

  optionActivated() {
    this.selectingFromAutoComplete = true;
  }

  optionClicked() {
    this.selectingFromAutoComplete = true;
    this.selected(this.latestSelectedEvent);
  }

  onChangeEvent(tags: Tag[]) {
    this.onChange(tags);
  }
}
