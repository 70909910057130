import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Content} from "./content.interface";
import {ActivityService} from "@activity/activity.service";
import {map} from "rxjs/operators";
import {
  PublishedContent,
  GroupedPublishedContent,
  LibraryMessageFilterModel,
} from "@notifications/notification.interface";
import {PublishedEventsResponse, GroupedPublishedEventsResponse} from "./queue.interface";
import {TagService} from "@tags/tag.service";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class QueueService {
  private baseUrl = environment.api + "/queue";

  constructor(
    private httpClient: HttpClient,
    private activityService: ActivityService,
    private tagService: TagService,
  ) {}

  list(start: string, end: string): Observable<Content[]> {
    return this.httpClient.get<Content[]>(this.baseUrl + "?start=" + start + "&end=" + end).pipe(
      map((queue: Content[]) => this.activityService.mapActivitiesInContents(queue)),
      map((queue: Content[]) => this.tagService.mapTagsInContent(queue)),
    );
  }

  pagedList(filters: LibraryMessageFilterModel, pageIndex: number, pageSize = 20): Observable<Content[]> {
    return this.httpClient.post<Content[]>(`${this.baseUrl}/paged/${pageIndex}/${pageSize}`, filters).pipe(
      map((queue: Content[]) => this.activityService.mapActivitiesInContents(queue)),
      map((queue: Content[]) => this.tagService.mapTagsInContent(queue)),
      map((queue: Content[]) => this.mapContent(queue)),
    );
  }

  publishedEvents(start: string, end: string): Observable<PublishedContent[]> {
    return this.httpClient
      .get<PublishedEventsResponse[]>(environment.api + "/PublishEvents?start=" + start + "&end=" + end)
      .pipe(
        map((events: PublishedEventsResponse[]) => this.mapPublishEvents(events)),
        map((queue: PublishedContent[]) => this.activityService.mapActivitiesInContents(queue)),
      );
  }

  pagedPublishedEvents(pageIndex: number, pageSize = 20): Observable<PublishedContent[]> {
    return this.httpClient
      .get<
        PublishedEventsResponse[]
      >(environment.api + "/PublishEvents/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize)
      .pipe(
        map((events: PublishedEventsResponse[]) => this.mapPublishEvents(events)),
        map((queue: PublishedContent[]) => this.activityService.mapActivitiesInContents(queue)),
      );
  }

  groupedPublishedEvents(start: string, end: string): Observable<GroupedPublishedContent[]> {
    return this.httpClient
      .get<GroupedPublishedEventsResponse[]>(environment.api + "/PublishEvents/grouped?start=" + start + "&end=" + end)
      .pipe(map((events: GroupedPublishedEventsResponse[]) => this.mapGroupPublishEvents(events)));
  }

  pagedGroupedPublishedEvents(
    filters: LibraryMessageFilterModel,
    pageIndex: number,
    pageSize = 20,
  ): Observable<GroupedPublishedContent[]> {
    return this.httpClient
      .post<
        GroupedPublishedEventsResponse[]
      >(`${environment.api}/PublishEvents/grouped/paged/${pageIndex}/${pageSize}`, filters)
      .pipe(map((events: GroupedPublishedEventsResponse[]) => this.mapGroupPublishEvents(events)));
  }

  hidePublishEvent(publishEventId: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.api}/PublishEvents/hide/${publishEventId}`);
  }

  randomizeQueue(): Observable<any> {
    return this.httpClient.post(`${environment.api}/queue/randomize`, {});
  }

  getGroupedPublishedEventsForMessage(occurrenceId: string, messageId: string): Observable<GroupedPublishedContent> {
    return this.httpClient
      .get<GroupedPublishedEventsResponse>(environment.api + `/PublishEvents/grouped/${occurrenceId}/${messageId}`)
      .pipe(
        map((event: GroupedPublishedEventsResponse) => {
          return this.mapGroupPublishEvent(event);
        }),
      );
  }

  getGroupedPublishedEventsForActivityShare(
    occurrenceId: string,
    activityShareId: string,
  ): Observable<GroupedPublishedContent> {
    return this.httpClient
      .get<GroupedPublishedEventsResponse>(
        environment.api + `/PublishEvents/grouped/activityshare/${occurrenceId}/${activityShareId}`,
      )
      .pipe(
        map((event: GroupedPublishedEventsResponse) => {
          return this.mapGroupPublishEvent(event);
        }),
      );
  }

  private mapPublishEvents(events: PublishedEventsResponse[]): PublishedContent[] {
    return events.map((event: PublishedEventsResponse) => {
      if (event.Message) {
        event.PublishEvent.Activities = event.Message.Activities;
        event.PublishEvent.Profiles = event.Message.Profiles;
        event.PublishEvent.PublishAt = event.PublishEvent.PublishedAt;
        if (event.Message.Tags) {
          event.Message.Tags = this.tagService.mapTags(event.Message.Tags);
        }
      }
      return event.PublishEvent;
    });
  }

  private mapGroupPublishEvents(groupedEvents: GroupedPublishedEventsResponse[]): GroupedPublishedContent[] {
    const groupedPublishedContentList = [];

    groupedEvents.forEach((groupEvent) => {
      groupedPublishedContentList.push(this.mapGroupPublishEvent(groupEvent));
    });
    return groupedPublishedContentList;
  }

  public mapGroupPublishEvent(groupedEvent: GroupedPublishedEventsResponse) {
    const groupedPublishedContent = this.activityService.mapActivitiesInContents([
      groupedEvent.Message,
    ])[0] as GroupedPublishedContent;
    groupedPublishedContent.PublishEvents = this.activityService.mapActivitiesInContents(groupedEvent.PublishEvents);

    if (groupedPublishedContent.Activities.Facebook) {
      const publishEvent = groupedPublishedContent.PublishEvents.filter((x) => x.Type == "Facebook");

      if (publishEvent && publishEvent.length > 0) {
        groupedPublishedContent.Activities.Facebook.CreatedTime = publishEvent[0].PublishedAt;
        groupedPublishedContent.PublishAt = publishEvent[0].PublishedAt;
      } else {
        groupedPublishedContent.Activities.Facebook = null;
        groupedPublishedContent.FacebookText = null;
        groupedPublishedContent.FacebookTextRaw = null;
        groupedPublishedContent.Providers = groupedPublishedContent.Providers.filter((x) => x != "Facebook");
      }
    }

    if (groupedPublishedContent.Activities.TwitterAccount) {
      const publishEvent = groupedPublishedContent.PublishEvents.filter((x) => x.Type == "TwitterAccount");

      if (publishEvent && publishEvent.length > 0) {
        groupedPublishedContent.Activities.TwitterAccount.CreatedTime = publishEvent[0].PublishedAt;
        groupedPublishedContent.PublishAt = publishEvent[0].PublishedAt;
      } else {
        groupedPublishedContent.Activities.TwitterAccount = null;
        groupedPublishedContent.TwitterText = null;
        groupedPublishedContent.TwitterTextRaw = null;
        groupedPublishedContent.Providers = groupedPublishedContent.Providers.filter((x) => x != "TwitterAccount");
      }
    }

    if (groupedPublishedContent.Activities.InstagramAccount) {
      const publishEvent = groupedPublishedContent.PublishEvents.filter((x) => x.Type == "InstagramAccount");

      if (publishEvent && publishEvent.length > 0) {
        groupedPublishedContent.Activities.InstagramAccount.CreatedTime = publishEvent[0].PublishedAt;
        groupedPublishedContent.PublishAt = publishEvent[0].PublishedAt;
      } else {
        groupedPublishedContent.Activities.InstagramAccount = null;
        groupedPublishedContent.InstagramText = null;
        groupedPublishedContent.InstagramTextRaw = null;
        groupedPublishedContent.Providers = groupedPublishedContent.Providers.filter((x) => x != "InstagramAccount");
      }
    }

    if (groupedPublishedContent.Activities.LinkedIn) {
      const publishEvent = groupedPublishedContent.PublishEvents.filter((x) => x.Type == "LinkedIn");

      if (publishEvent && publishEvent.length > 0) {
        groupedPublishedContent.Activities.LinkedIn.CreatedTime = publishEvent[0].PublishedAt;
        groupedPublishedContent.PublishAt = publishEvent[0].PublishedAt;
      } else {
        groupedPublishedContent.Activities.LinkedIn = null;
        groupedPublishedContent.LinkedInText = null;
        groupedPublishedContent.LinkedInTextRaw = null;
        groupedPublishedContent.Providers = groupedPublishedContent.Providers.filter((x) => x != "LinkedIn");
      }

      if (groupedPublishedContent.IsAScheduledShare) {
        groupedPublishedContent.EditorStatesJson = null;
        groupedPublishedContent.Activities.LinkedIn.tokens = null;
      }
    }

    groupedPublishedContent.ShareOnBehalfOfEmployees = groupedEvent.Message.ShareOnBehalfOfEmployees;
    groupedPublishedContent.ShareOnBehalfOfEmployees = groupedEvent.Message.ShareOnBehalfOfEmployees;
    groupedPublishedContent.LikeOnBehalfOfEmployees = groupedEvent.Message.LikeOnBehalfOfEmployees;
    groupedPublishedContent.ExcludeEmployees = groupedEvent.Message.ExcludeEmployees;
    groupedPublishedContent.LinkedInShareCommentary = groupedEvent.Message.LinkedInShareCommentary;
    groupedPublishedContent.EmployeeGroupNames = groupedEvent.Message.EmployeeGroupNames;
    groupedPublishedContent.Employees = groupedEvent.Message.Employees;

    if (groupedEvent.Message.Tags) {
      groupedPublishedContent.Tags = this.tagService.mapTags(groupedEvent.Message.Tags);
    }

    const profilesUsed = groupedEvent.PublishEvents.map((x) => x.ProfileId);

    groupedPublishedContent.Profiles = groupedPublishedContent.Profiles.filter((x) =>
      profilesUsed.some((id) => id == x.Id),
    );

    groupedPublishedContent.PublishAtMoment = moment(groupedPublishedContent.PublishAt);
    groupedPublishedContent.failedToPublish = groupedEvent.PublishEvents.some((x) => x.ErrorMessage);
    return groupedPublishedContent;
  }

  private mapContent(queue: Content[]): any {
    queue.forEach((content) => {
      content.PublishAtMoment = moment(content.PublishAt);
    });
    return queue;
  }
}
