<!-- Facebook -->
<ul *ngIf="provider === 'Facebook'" class="facebook">
  <li>
    <app-icon icon="like" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Like
  </li>
  <li>
    <app-icon icon="message-square" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Comment
  </li>
  <li>
    <app-icon icon="share-arrow" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Share
  </li>
</ul>
<!-- Twitter -->
<ul *ngIf="provider === 'TwitterAccount'" class="twitter">
  <li>
    <app-icon icon="twitter-reply" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="twitter-retweet" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="twitter-like" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="twitter-share" class="w-5 h-5 leading-5"></app-icon>
  </li>
</ul>
<!-- Instagram -->
<ul *ngIf="provider === 'InstagramAccount'" class="instagram">
  <li>
    <app-icon icon="instagram-like" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="instagram-comment" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="instagram-share" class="w-5 h-5 leading-5"></app-icon>
  </li>
  <li>
    <app-icon icon="instagram-bookmark" class="w-5 h-5 leading-5"></app-icon>
  </li>
</ul>
<!-- Linkedin -->
<ul *ngIf="provider === 'LinkedIn'" class="linkedin">
  <li>
    <app-icon icon="linkedin-like" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Like
  </li>
  <li class="!ml-4">
    <app-icon icon="linkedin-comment" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Comment
  </li>
  <li class="!ml-4">
    <app-icon icon="linkedin-share" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Share
  </li>
  <li class="!ml-4">
    <app-icon icon="linkedin-send" class="w-5 h-5 leading-5 mr-1.25"></app-icon>
    Send
  </li>
</ul>
