import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from "@angular/core";
import moment from "moment-timezone";
import {
  ComposerActionSchedule,
  ComposerActionTypeEnum,
  Content,
  ContentModel,
  Link,
  Tag,
} from "@publisher/content.interface";
import {LibraryService} from "@publisher/library.service";
import {PublishSchedule} from "@publisher/publish-schedule.interface";
import {PublishScheduleService} from "@publisher/publish-schedule.service";
import {UserService} from "@user/user.service";
import {map, take} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {TagService} from "@tags/tag.service";
import {ProfileTypes} from "@channel/profile-types.enum";
import {stripHtmlForQuillText} from "@utils/strip-html.function";
import {CalendarSettingsService} from "src/app/pages/publisher/calendar/calendar-widget/calendar-settings/calendar-settings.service";
import {GroupedPublishedContent} from "@shared/notifications/notification.interface";
import {ComposerValidationService} from "../composer-validation/composer-validation.service";
import {ColorService} from "@shared/utils/color-service/color.service";
import {ComposerService} from "../composer.service";
import {TimezoneInfo} from "@shared/utils/timezone/timezone.interface";
import {TimezoneService} from "@shared/utils/timezone/timezone.service";
import {AsyncMessageService} from "@shared/utils/async-message.service";
import {momentToDate} from "@utils/momentToDate.function";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getTrackingUrl, setLinkMetadata} from "@utils/link.function";
import {AnalyticsService} from "@utils/analytics-service/analytics.service";
import {ProfileService} from "@shared/channel/profile.service";
import {environment} from "@environments/environment";
import {getMentionsFromTokens, getTextToSendToServerFromTokens} from "@shared/utils/token.function";
import {EditorsState} from "../composer-editor/token/token.interface";

@UntilDestroy()
@Component({
  selector: "app-composer-actions",
  templateUrl: "./composer-actions.component.html",
})
export class ComposerActionsComponent implements OnInit, OnChanges {
  @Input() model: ContentModel;
  @Input() content: Content;
  @Input() publishNow: boolean;
  @Input() onlySchedule = false;
  @Input() editSchedule = false;

  @Output() submitFailed: EventEmitter<any> = new EventEmitter();
  @Output() errorMessage: EventEmitter<string> = new EventEmitter();
  @Output() closeComposerEvent: EventEmitter<any> = new EventEmitter();
  @Output() handleScrollbar: EventEmitter<any> = new EventEmitter();

  submitted: boolean;
  loading: boolean;
  addToLibrary = false;
  today = this.dateConverter();
  timezone: string;

  expires: boolean;

  schedule: ComposerActionSchedule = {
    time: "09:00 AM",
    startAt: momentToDate(moment().add(1, "days")),
  };

  expiration: ComposerActionSchedule = {
    time: "09:00 AM",
    startAt: momentToDate(moment().add(1, "days")),
  };

  selectedTags: Tag[] = [];

  isProd = environment.production;

  constructor(
    private libraryService: LibraryService,
    private userService: UserService,
    private tagService: TagService,
    private scheduleService: PublishScheduleService,
    private calendarSettings: CalendarSettingsService,
    public composerValidation: ComposerValidationService,
    private colorService: ColorService,
    public composerService: ComposerService,
    private timezoneService: TimezoneService,
    private asyncMessageService: AsyncMessageService,
    private analyticsService: AnalyticsService,
    private profileService: ProfileService,
  ) {}

  ngOnInit() {
    if (this.content) {
      this.selectedTags = [...this.content.Tags];
    }

    if (!this.editSchedule) {
      this.userService
        .getSettings()
        .pipe(untilDestroyed(this))
        .subscribe((settings) => {
          this.schedule.timezoneId = settings.TimezoneInfo;
          this.expiration.timezoneId = settings.TimezoneInfo;
        });
    }
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.onlySchedule !== undefined && changes.onlySchedule.currentValue === true) {
      this.composerService.actionsActiveTab = 1;
    }

    if (changes.editSchedule !== undefined && changes.editSchedule.currentValue === true) {
      this.editSchedule = true;
      this.schedule.startAt = new Date(this.content.ScheduleModel.StartAt);
      this.schedule.time = this.content.ScheduleModel.Times[0];

      this.timezoneService
        .getTimezoneByIanaId(this.content.ScheduleModel.TimezoneId)
        .pipe(untilDestroyed(this))
        .subscribe((timezoneInfo: TimezoneInfo) => {
          this.schedule.timezoneId = timezoneInfo;
          this.expiration.timezoneId = timezoneInfo;
        });
    }

    if (changes.content !== undefined) {
      this.content = changes.content.currentValue;
      this.expires = this.content?.ExpirationModel != null;

      if (this.expires) {
        const moment2 = moment(this.content.ExpirationModel.StartAt);
        this.expiration.startAt = new Date(moment2.toISOString());
        this.expiration.time = this.content.ExpirationModel.Times[0];
        this.timezoneService
          .getTimezoneByIanaId(this.content.ExpirationModel.TimezoneId)
          .pipe(untilDestroyed(this))
          .subscribe((timezoneInfo: TimezoneInfo) => {
            this.expiration.timezoneId = timezoneInfo;
          });
      }
    }

    if (changes.model !== undefined) {
      this.model = changes.model.currentValue;
    }
  }

  private static getDisplayLinkForLinkedIn(link: Link): string {
    if (link.IsShorten) return link.ShortenUrl;
    return getTrackingUrl(link);
  }

  private prepareModel(type: ComposerActionTypeEnum): Observable<boolean> {
    this.submitted = true;
    this.model.PublishMessageMentions = [];

    this.model.MediaFiles = this.composerService.getMediaFilesToSendToServer();

    this.model.Links = this.composerService.getLinksToSendToServer();

    this.errorMessage.emit();
    const hasErrors = !this.composerValidation.ValidateComposer(
      this.schedule,
      this.profileService.allProfilesObservable.value,
      this.composerService.selectedProfiles,
      this.model,
      type,
      this.expires ? this.expiration : null,
    );

    if (
      this.composerValidation.GeneralValidation.PostInThePast ||
      this.composerValidation.GeneralValidation.TimezoneEmpty ||
      this.composerValidation.GeneralValidation.MediaFilesUploading ||
      this.composerValidation.GeneralValidation.ProfileRequiresAttention ||
      this.composerValidation.GeneralValidation.ExpirationInThePast ||
      this.composerValidation.GeneralValidation.ProfileRequiresPermission ||
      this.composerValidation.GeneralValidation.IncompleteExpirationDate ||
      this.composerValidation.GeneralValidation.IncompleteScheduleDate
    ) {
      return of(false);
    }

    if (hasErrors) {
      return of(false);
    }

    if (!this.isProd && this.expires) {
      this.model.ExpirationModel = {
        Times: [this.expiration.time],
        StartAt: moment(this.expiration.startAt, this.calendarSettings.DATE_FORMAT).format(
          this.calendarSettings.DATE_FORMAT,
        ),
        TimezoneId: this.expiration.timezoneId["IanaId"],
        FrequencyType: "None",
        Name: "",
      };
    } else this.model.ExpirationModel = null;

    if (this.model.Links && this.model.Links.length > 0) {
      this.model.TwitterUrlMetadata = [];

      const linksWithoutFacebookEnabled = this.model.Links.filter((x) => x.ProfileType != ProfileTypes.Facebook);

      for (let i = 0; i < linksWithoutFacebookEnabled.length; i++) {
        if (
          linksWithoutFacebookEnabled[i].Metadata &&
          linksWithoutFacebookEnabled[i].ProfileType == ProfileTypes.TwitterAccount
        ) {
          this.model.TwitterUrlMetadata.push(...linksWithoutFacebookEnabled[i].Metadata);

          this.model.TwitterUrlMetadataJson = JSON.stringify(this.model.TwitterUrlMetadata);
        }
      }
    }

    const editorStates: EditorsState = {
      editors: [],
    };

    this.loading = true;
    if (this.addToLibrary || this.publishNow) {
      this.model.MessageType = "Library";
      this.model.Tags = this.selectedTags.map((tag) => ({
        ...tag,
        Id: tag.Id,
      }));
    } else {
      this.model.MessageType = "OneTime";
    }
    this.model.Profiles = this.composerService.selectedProfiles.map((p) => {
      const publishProfile = p.profile.Id == p.publishAsProfileForFbGroups?.Id ? null : p.publishAsProfileForFbGroups;
      return {Id: p.profile.Id, PublishProfile: publishProfile};
    });
    if (this.model.TwitterUrlMetadata && this.model.TwitterUrlMetadata[0] && this.model.TwitterUrlMetadata[0].Tweet) {
      this.model.QuotedTweetUrl = this.model.TwitterUrlMetadata[0].Tweet.Tweet.Permalink;
    }

    if (this.composerService.selectedProfiles.some((x) => x.selected && x.profile.Type === ProfileTypes.Facebook)) {
      const facebookLinks = this.model.Links.filter((x) => x.ProfileType == ProfileTypes.Facebook);

      const facebookTokens = this.getTokensDependingIfCustomizeModeIsOn(ProfileTypes.Facebook);

      const facebookMentions = getMentionsFromTokens(facebookTokens, ProfileTypes.Facebook, {links: facebookLinks});

      const facebookText = getTextToSendToServerFromTokens(facebookTokens, ProfileTypes.Facebook, {
        links: facebookLinks,
      });

      editorStates.editors.push({
        sourceType: ProfileTypes.Facebook,
        tokens: facebookTokens,
      });

      this.model.FacebookText = facebookText;
      if (facebookMentions) {
        this.model.PublishMessageMentions.push(...facebookMentions);
      }

      if (facebookLinks && facebookLinks.length > 0) {
        const facebookMetadata = facebookLinks[0];

        if (facebookMetadata) {
          this.model.FacebookUrl = facebookMetadata.FacebookMetadata.url;
          this.model.FacebookUrlDescription = facebookMetadata.FacebookMetadata.description;
          this.model.FacebookUrlTitle = facebookMetadata.FacebookMetadata.title;
          this.model.FacebookUrlPicture =
            facebookMetadata.FacebookMetadata.image && facebookMetadata.FacebookMetadata.image.length > 0
              ? facebookMetadata.FacebookMetadata.image[0].url
              : "";
        }
      }
    } else {
      this.model.FacebookText = null;
      this.model.FacebookUrlDescription = null;
      this.model.FacebookUrlTitle = null;
      this.model.FacebookUrlPicture = null;
      this.model.FacebookUrl = null;
    }

    if (
      this.composerService.selectedProfiles.some((x) => x.selected && x.profile.Type === ProfileTypes.TwitterAccount)
    ) {
      const twitterLinks = this.model.Links.filter((x) => x.ProfileType == ProfileTypes.TwitterAccount);
      const twitterTokens = this.getTokensDependingIfCustomizeModeIsOn(ProfileTypes.TwitterAccount);
      const twitterMentions = getMentionsFromTokens(twitterTokens, ProfileTypes.TwitterAccount, {links: twitterLinks});

      this.model.TwitterText = getTextToSendToServerFromTokens(twitterTokens, ProfileTypes.TwitterAccount, {
        links: twitterLinks,
      });
      editorStates.editors.push({
        sourceType: ProfileTypes.TwitterAccount,
        tokens: twitterTokens,
      });

      if (twitterMentions && twitterMentions.length > 0) {
        this.model.PublishMessageMentions.push(...twitterMentions);
      }
    } else {
      this.model.TwitterText = null;
    }

    if (this.composerService.selectedProfiles.some((x) => x.selected && x.profile.Type === ProfileTypes.LinkedIn)) {
      const linkedInLinks = this.model.Links.filter((x) => x.ProfileType == ProfileTypes.LinkedIn);

      if (linkedInLinks && linkedInLinks.length > 0) {
        const linkToBeSentToLinkedIn = linkedInLinks[0];

        setLinkMetadata(linkToBeSentToLinkedIn, linkToBeSentToLinkedIn.Metadata);

        this.model.LinkedInArticleLink = ComposerActionsComponent.getDisplayLinkForLinkedIn(linkToBeSentToLinkedIn);

        this.model.LinkedInArticleLinkCaption = linkToBeSentToLinkedIn.title;

        this.model.LinkedInArticleLinkDescription = linkToBeSentToLinkedIn.description;

        this.model.LinkedInArticleLinkDomain = linkToBeSentToLinkedIn.domain;

        this.model.LinkedInArticleLinkPicture = linkToBeSentToLinkedIn.imageUrl;

        this.model.LinkedInArticleCaptionEdited = linkToBeSentToLinkedIn.isTitleEdited;

        this.model.LinkedInArticlePictureEdited = linkToBeSentToLinkedIn.isImageEdited;
      }

      const linkedInTokens = this.getTokensDependingIfCustomizeModeIsOn(ProfileTypes.LinkedIn);

      let shareEmployeesEditor = this.composerService.getEditorToModify(ProfileTypes.LinkedIn);

      if (!this.composerService.customizeMode)
        shareEmployeesEditor = this.composerService.getEditorToModify(ProfileTypes.Unknown);

      this.model.LinkedInShareCommentary = shareEmployeesEditor.LinkedInShareCommentary;
      this.model.ShareOnBehalfOfEmployees = shareEmployeesEditor.ShareOnBehalfOfEmployees;
      this.model.Employees = shareEmployeesEditor.Employees;
      this.model.ExcludeEmployees = shareEmployeesEditor.ExcludeEmployees;
      this.model.LikeOnBehalfOfEmployees = shareEmployeesEditor.LikeOnBehalfOfEmployees;
      this.model.IncludedEmployees = shareEmployeesEditor.IncludedEmployees;
      this.model.EmployeeGroupNames = shareEmployeesEditor?.EmployeeGroupsConfig?.EmployeeGroups?.map(
        (x) => x.Name,
      ).join(";");

      this.model.EmployeeGroupsConfig = shareEmployeesEditor?.EmployeeGroupsConfig;

      const linkedInMentions = getMentionsFromTokens(linkedInTokens, ProfileTypes.LinkedIn, {links: linkedInLinks});

      const linkedInText = getTextToSendToServerFromTokens(linkedInTokens, ProfileTypes.LinkedIn, {
        links: linkedInLinks,
      });

      editorStates.editors.push({
        sourceType: ProfileTypes.LinkedIn,
        tokens: linkedInTokens,
      });

      this.model.LinkedInText = linkedInText;
      if (linkedInMentions) {
        this.model.PublishMessageMentions.push(...linkedInMentions);
      }
    } else {
      this.model.LinkedInText = null;
      this.model.ExcludeEmployees = null;
      this.model.ShareOnBehalfOfEmployees = false;
      this.model.LikeOnBehalfOfEmployees = false;
      this.model.LinkedInShareCommentary = null;
    }

    if (
      this.composerService.selectedProfiles.some((x) => x.selected && x.profile.Type === ProfileTypes.InstagramAccount)
    ) {
      const instagramLinks = this.model.Links.filter((x) => x.ProfileType == ProfileTypes.InstagramAccount);
      const instagramTokens = this.getTokensDependingIfCustomizeModeIsOn(ProfileTypes.InstagramAccount);
      this.model.InstagramText = getTextToSendToServerFromTokens(instagramTokens, ProfileTypes.InstagramAccount, {
        links: instagramLinks,
      });

      editorStates.editors.push({
        sourceType: ProfileTypes.InstagramAccount,
        tokens: instagramTokens,
      });

      if (this.model?.InstagramComment?.length > 0)
        this.model.InstagramComment = stripHtmlForQuillText(this.model.InstagramComment);
      else this.model.InstagramComment = null;
    } else {
      this.model.InstagramText = null;
      this.model.InstagramComment = null;
    }

    if (!this.composerService.customizeMode) {
      editorStates.editors.push({
        sourceType: ProfileTypes.Unknown,
        tokens: this.getTokensDependingIfCustomizeModeIsOn(ProfileTypes.Unknown),
      });
    }

    this.model.EditorStatesJson = JSON.stringify(editorStates);

    if (this.addToLibrary) {
      return this.tagService.batchCreate(this.selectedTags).pipe(
        map((tags) => {
          const tagsWithId = (this.selectedTags || []).filter((tag) => !!tag.Id);
          const selectedTags = tagsWithId.concat(tags);
          this.model.Tags = selectedTags.map((tag) => ({...tag, Id: tag.Id}));
          return true;
        }),
      );
    } else {
      return of(true);
    }
  }
  getTokensDependingIfCustomizeModeIsOn(profileType: ProfileTypes) {
    if (!this.composerService.customizeMode) profileType = ProfileTypes.Unknown;

    const editor = this.composerService.getEditorToModify(profileType);

    return editor.tokens;
  }

  saveContent() {
    this.addToLibrary = true;
    this.prepareModel(ComposerActionTypeEnum.AddToLibrary)
      .pipe(untilDestroyed(this))
      .subscribe((valid) => {
        if (valid) {
          this.libraryService
            .create(this.model)
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(this.handleCallbacks);
          this.analyticsService.track("Add to Library");
        } else {
          this.submitFailingComposer(true, false, false);
        }
      });
  }

  async publish() {
    this.prepareModel(ComposerActionTypeEnum.PublishNow)
      .pipe(untilDestroyed(this))
      .subscribe(async (valid) => {
        if (valid) {
          this.libraryService.publish(this.model).pipe(untilDestroyed(this)).subscribe(this.handleCallbacks);
          this.analyticsService.track("Publish Now");
        } else {
          this.submitFailingComposer(false, false, true);
        }
      });
  }

  createSchedule() {
    this.prepareModel(ComposerActionTypeEnum.ScheduleOneTime)
      .pipe(untilDestroyed(this))
      .subscribe((valid) => {
        if (valid) {
          const groupContent = this.content as unknown as GroupedPublishedContent;

          let publishEventsResolved = [];

          if (groupContent && groupContent.PublishEvents && groupContent.PublishEvents.length > 0) {
            publishEventsResolved = groupContent.PublishEvents.filter((x) => x.ErrorMessage).map((x) => x.Id);
          }

          // we pass the time and the timezone to the server in separate fields
          // the year/month/day should match the displayed date
          const startDateString = `${
            this.schedule.startAt.getMonth() + 1
          }/${this.schedule.startAt.getDate()}/${this.schedule.startAt.getFullYear()}`;

          const schedule: PublishSchedule = {
            Name: "OneTime",
            FrequencyType: "OneTime",
            Times: [this.schedule.time],
            StartAt: startDateString,
            Message: this.model,
            Tags: this.model.Tags,
            PublishEventsResolved: publishEventsResolved,
            TimezoneId: this.schedule.timezoneId["IanaId"],
          };

          this.scheduleService.create(schedule).pipe(untilDestroyed(this)).subscribe(this.handleCallbacks);
          this.analyticsService.track("Schedule");
        } else {
          this.submitFailingComposer(false, true, false);
        }
      });
  }

  editContent() {
    this.addToLibrary = true;
    this.prepareModel(ComposerActionTypeEnum.AddToLibrary)
      .pipe(untilDestroyed(this))
      .subscribe((valid) => {
        if (valid) {
          const content = {
            ...this.content,
            ...this.model,
            MessageType: this.content.MessageType,
          } as Content;
          content.MediaFiles = this.composerService.getMediaFilesToSendToServer();

          if (this.editSchedule) {
            content.ScheduleModel = {
              Name: "OneTime",
              FrequencyType: "OneTime",
              Times: [this.schedule.time],
              StartAt: moment(this.schedule.startAt, this.calendarSettings.DATE_FORMAT).format(
                this.calendarSettings.DATE_FORMAT,
              ),
              Message: this.model,
              Tags: this.model.Tags,
              TimezoneId: this.schedule.timezoneId["IanaId"],
            };
          }

          this.libraryService
            .edit(content)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: (response: Content) => {
                response.Tags.forEach((x) => (x.FgColorHex = this.colorService.generateFgColor(x.ColorHex)));
                this.closeComposerEvent.emit(response);
              },
              error: (response) => {
                this.errorMessage.emit(response.error.Exception.Message);
                this.loading = false;
              },
              complete: () => (this.loading = false),
            });
        } else {
          this.submitFailingComposer(true, false, false);
        }
      });
  }

  submitFailingComposer(library: boolean, schedule: boolean, publishNow: boolean) {
    this.submitFailed.emit({
      library: library,
      schedule: schedule,
      publishNow: publishNow,
    });
  }

  onDatepickerChange() {
    if (this.submitted) {
      this.composerValidation.ValidateSchedule(this.schedule);
    }
  }

  public OnTimezoneSelected(timezoneInfo: TimezoneInfo) {
    this.schedule.timezoneId = timezoneInfo;
    if (this.submitted) {
      this.model.MediaFiles = this.model.MediaFiles || [];
      this.composerValidation.ValidateSchedule(this.schedule);
    }
  }

  expirationDatepickerChange() {
    if (this.submitted) {
      this.composerValidation.ValidateExpiration(this.expiration);
    }
  }

  public OnExpirationTimezoneSelected(timezoneInfo: TimezoneInfo) {
    if (!this.expires) return;

    this.expiration.timezoneId = timezoneInfo;
    if (this.submitted) {
      this.composerValidation.ValidateExpiration(this.expiration);
    }
  }

  get profilesTypes(): number[] {
    return this.composerService.selectedProfiles
      .map((profile) => profile.profile.Type)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .sort();
  }

  private handleCallbacks = {
    next: () => {
      this.composerService.onContentCreated.emit();
      this.closeComposerEvent.emit(true);
    },
    error: (response) => {
      const profileIds = this.model.Profiles.map((x) => x.Id);

      const selectedProfiles = this.profileService.allProfilesObservable.value.filter((x) =>
        profileIds.some((t) => t == x.Id),
      );

      selectedProfiles.forEach((profile) => {
        const shouldEmit =
          //If its a linkedin profile and it returns a code 401
          (profile.Type == ProfileTypes.LinkedIn && response?.error?.Exception?.Code == 401) ||
          //If its facebook profile, it doesnt contain a code but contains a message like this ' "(OAuthException - #190) Error validating access token: The user has not authorized application."'
          ((profile.Type == ProfileTypes.Facebook || profile.Type == ProfileTypes.InstagramAccount) &&
            response?.error?.ExceptionMessage?.includes("Error validating access token")) ||
          //If its twitter 89 - Invalid or expired token.
          (profile.Type == ProfileTypes.TwitterAccount &&
            response?.error?.Exception?.TwitterFailureReason?.includes("89 - Invalid or expired token."));
        if (shouldEmit) this.asyncMessageService.accountHub.OnProfileModified.emit(profile.Id);
      });

      this.errorMessage.emit(response.error.ExceptionMessage);
      this.loading = false;
    },
    complete: () => (this.loading = false),
  };

  dateConverter() {
    const date = moment();
    return momentToDate(date);
  }

  scrollToBottom() {
    this.handleScrollbar.emit();
  }
}
