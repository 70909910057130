import {BaseMediaValidation} from "./BaseMediaValidation";

export class LinkedInMediaValidation extends BaseMediaValidation {
  SizeError: boolean;
  ImageFormatError: boolean;
  ExceedingPixelError: boolean;

  constructor() {
    super();
  }

  IsValid(): boolean {
    const arrayOfFlags = [this.SizeError, this.ImageFormatError, this.ExceedingPixelError];

    return arrayOfFlags.every((x) => !x);
  }
}
