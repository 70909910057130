<mat-tab-group [(selectedIndex)]="step" dynamicHeight>
  <mat-tab [tabIndex]="0">
    <div *ngIf="step === 0">
      <app-connect-zapier (Authenticated)="onUserAuthenticatedWithZapier()"> </app-connect-zapier>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="1">
    <div *ngIf="step === 1 && profiles">
      <app-preview-zapier (Next)="onNextPage()" (Finished)="Finished()" [processedProfiles]="profiles">
      </app-preview-zapier>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="2">
    <div *ngIf="step === 2 && profiles[profileIndexToModify]">
      <app-configure-zapier
        (Skip)="onSkipProfile()"
        (Next)="onNextPage()"
        [processedProfile]="profiles[profileIndexToModify]">
      </app-configure-zapier>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="3">
    <div *ngIf="step === 3">
      <app-progress-preview-zapier (Next)="onNextPage()" (Finished)="Finished()" [processedProfiles]="profiles">
      </app-progress-preview-zapier>
    </div>
  </mat-tab>
  <mat-tab [tabIndex]="4">
    <div *ngIf="step === 4">
      <app-confirmation-zapier (Finished)="Finished()" [processedProfiles]="profiles"> </app-confirmation-zapier>
    </div>
  </mat-tab>
</mat-tab-group>
