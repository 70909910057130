import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-generic-snackbar",
  template: `
    <div data-test="shared-snackbar">
      {{ Message }}
    </div>
  `,
})
export class GenericSnackbarComponent {
  Message = "";

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {
    this.Message = data.Message;
  }
}
