<div class="logo-text">
  <svg width="100%" height="100%" viewBox="0 0 270 35" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m1.00479,9.90717c0,-5.09141 4.07332,-8.38908 10.13546,-8.38908a14.11813,14.11813 0 0 1 7.51589,2.08543c1.11564,0.72734 1.64884,1.30884 1.64884,1.98788a2.13772,2.13772 0 0 1 -1.79374,2.08543c-0.4366,0 -0.87319,-0.33952 -1.79468,-0.87319a10.57692,10.57692 0 0 0 -5.5763,-1.64836c-3.78257,0 -6.0129,1.89081 -6.0129,4.50944c0,6.69194 15.80837,5.09188 15.80837,15.46979c0,5.72215 -4.70406,9.06812 -10.81356,9.06812a13.68025,13.68025 0 0 1 -8.14758,-2.522c-1.0181,-0.72736 -1.59963,-1.40641 -1.59963,-2.13371a2.13035,2.13035 0 0 1 1.79374,-2.03618c0.58244,0 0.96979,0.339 1.98883,1.01809a10.08207,10.08207 0 0 0 6.0129,2.03618c3.83087,0 6.64364,-2.03618 6.64364,-5.188c0.00004,-7.37151 -15.80928,-5.6256 -15.80928,-15.46984z"></path>
      <path
        d="m35.7241,11.70138c6.4495,0 10.57111,4.41284 10.57111,11.15355c0,6.83779 -4.16992,11.25015 -10.66866,11.25015c-6.4495,0 -10.52281,-4.41236 -10.52281,-11.15261c0,-6.83825 4.17087,-11.25109 10.62036,-11.25109zm0,19.05774c3.87917,0 6.30365,-3.05522 6.30365,-7.80664c0,-4.89821 -2.47278,-7.95343 -6.35195,-7.95343c-3.88011,0 -6.35289,3.05522 -6.35289,7.85588c0,4.84899 2.52205,7.90419 6.40119,7.90419z"></path>
      <path
        d="m60.8402,30.51667a6.77478,6.77478 0 0 0 3.83087,-1.21224c0.63074,-0.4366 1.019,-0.77564 1.55224,-0.77564a1.83019,1.83019 0 0 1 1.79374,1.843a2.40491,2.40491 0 0 1 -1.21224,1.79374a10.3102,10.3102 0 0 1 -6.0612,1.843c-6.0129,0 -10.37791,-4.41331 -10.37791,-11.10525c0,-6.64317 4.26746,-11.10478 10.32961,-11.10478a10.44917,10.44917 0 0 1 6.1095,1.74591a2.29515,2.29515 0 0 1 1.21224,1.74544a1.8843,1.8843 0 0 1 -1.74544,1.98836c-0.4849,0 -0.82394,-0.33952 -1.60054,-0.77564a6.66625,6.66625 0 0 0 -3.83087,-1.21271c-3.92747,0 -6.25535,2.95815 -6.25535,7.51683c0,4.50986 2.27958,7.70998 6.25535,7.70998z"></path>
      <path
        d="m75.09538,7.48269a2.57242,2.57242 0 0 1 -2.66693,-2.66693a2.66737,2.66737 0 0 1 5.33386,0a2.57189,2.57189 0 0 1 -2.66693,2.66693zm2.03713,24.68281c0,1.11564 -0.63074,1.69714 -1.98883,1.69714c-1.40639,0 -2.08448,-0.5815 -2.08448,-1.69714l0,-18.52406c0,-1.11564 0.6298,-1.69761 1.98788,-1.69761c1.40639,0 2.08543,0.582 2.08543,1.69761l0,18.52406z"></path>
      <path
        d="m91.24279,15.2415a8.44316,8.44316 0 0 0 -4.36406,1.21224c-0.77564,0.4366 -1.16394,0.77612 -1.69714,0.77612a1.85688,1.85688 0 0 1 -1.64884,-1.94006c0,-0.5815 0.4366,-1.16347 1.45469,-1.79421a12.76832,12.76832 0 0 1 6.64364,-1.69714c3.10352,0 5.4314,0.87319 6.69194,2.61863c0.92149,1.16394 1.30979,2.90938 1.30979,5.28556l0,12.41457c0,1.16394 -0.4849,1.74544 -1.93958,1.74544c-1.21319,0 -1.79468,-0.38735 -1.79468,-1.30884l0,-1.16395a8.54454,8.54454 0 0 1 -6.4978,2.57033c-4.365,0 -6.88609,-2.42448 -6.88609,-6.06215c0,-4.41236 3.83087,-6.74024 13.14144,-7.46759l0,-0.63074c0.00001,-3.00645 -1.21223,-4.55821 -4.41331,-4.55821zm-4.41236,12.3658c0,1.69714 1.16394,3.00692 3.87917,3.00692a7.624,7.624 0 0 0 4.94651,-1.89128l0,-5.57725c-6.0612,0.67904 -8.82568,1.79468 -8.82568,4.46161z"></path>
      <path
        d="m110.44449,32.16551c0,1.11564 -0.63074,1.69714 -1.98883,1.69714c-1.40639,0 -2.08448,-0.5815 -2.08448,-1.69714l0,-29.96837c0,-1.11564 0.6298,-1.69714 1.98788,-1.69714c1.40639,0 2.08543,0.5815 2.08543,1.69714l0,29.96837z"></path>
      <path
        d="m131.34426,30.42007c-0.63074,2.27863 -1.60054,3.44257 -4.94651,3.44257c-3.24842,0 -4.26746,-1.11469 -4.80066,-3.29767l-6.0129,-24.39159a10.47576,10.47576 0 0 1 -0.34,-2.03666c0,-1.21224 0.77659,-2.27911 3.686,-2.27911c2.66693,0 3.49182,0.92149 3.92747,3.00645l4.12256,20.22215l4.80066,-18.525c0.6298,-2.42448 1.74544,-3.63672 4.75236,-3.63672c2.95768,0 4.07332,1.21224 4.65481,3.63672l4.70406,18.525l4.21916,-20.22215c0.43565,-2.085 1.26054,-3.00645 3.78162,-3.00645c2.81278,0 3.54012,1.11517 3.54012,2.27911a10.54386,10.54386 0 0 1 -0.339,2.03666l-6.11045,24.39159c-0.5332,2.183 -1.55129,3.29767 -4.75236,3.29767c-3.24842,0 -4.41236,-1.16394 -4.99481,-3.44257l-4.89726,-18.42747l-4.99487,18.42747z"></path>
      <path
        d="m178.42747,29.88692c0,1.01809 -0.5815,1.74544 -1.79374,2.47278a13.98971,13.98971 0 0 1 -7.08024,1.64884c-7.51589,0 -11.68675,-4.17086 -11.68675,-11.3477c0,-7.07977 4.07332,-11.58968 10.81356,-11.58968c6.88609,0 10.32961,4.65529 10.32961,9.50519c0,3.58842 -1.843,4.07332 -4.025,4.07332l-10.13548,0c0.58244,2.32693 2.08543,3.54012 4.80066,3.54012a8.84855,8.84855 0 0 0 3.92841,-0.97074a4.87669,4.87669 0 0 1 1.98788,-0.6298c1.55225,-0.00005 2.86109,1.74539 2.86109,3.29767zm-13.72293,-9.74764l7.51589,0a3.356,3.356 0 0 0 -3.58847,-3.68554c-2.23029,0 -3.49083,1.26102 -3.92743,3.6855l0.00001,0.00004z"></path>
      <path
        d="m191.42211,17.03571a10.436,10.436 0 0 0 -4.07332,0.96979a5.94733,5.94733 0 0 1 -2.08543,0.582c-1.64884,0 -2.71523,-1.98836 -2.71523,-3.39427c0,-1.01857 0.5815,-1.69761 1.842,-2.37618a16.31931,16.31931 0 0 1 7.61343,-1.64884c3.686,0 6.35289,0.87272 7.85588,2.56985a8.0683,8.0683 0 0 1 1.60054,5.4314l0,11.78381c0,2.03713 -1.11564,2.90938 -3.58842,2.90938c-2.182,0 -3.00692,-0.679 -3.00692,-2.27863l0,-0.0483a7.28983,7.28983 0 0 1 -5.86705,2.32693c-4.55916,0 -7.371,-2.47278 -7.371,-6.40119c0,-4.36406 3.39427,-6.78854 12.80144,-7.66173l0,-0.24245c0.00006,-1.55178 -0.62974,-2.52157 -3.00592,-2.52157zm-2.522,9.74764c0,1.01809 0.63074,1.74544 2.182,1.74544a5.13515,5.13515 0 0 0 3.346,-1.26054l0,-3.44352c-3.73334,0.48489 -5.52803,1.30979 -5.52803,2.95862l0.00003,0z"></path>
      <path
        d="m219.93532,14.22292c0.679,-2.03666 1.45469,-2.95768 4.07332,-2.95768c2.52108,0 3.346,0.87272 3.346,2.03666a7.43754,7.43754 0 0 1 -0.5332,2.27911l-5.72215,14.839c-1.019,2.66693 -2.03713,3.44257 -5.189,3.44257c-3.24937,0 -4.31576,-0.82394 -5.28556,-3.44257l-5.57725,-14.839a7.43755,7.43755 0 0 1 -0.5332,-2.27911c0,-1.16394 0.82489,-2.03666 3.49182,-2.03666c2.52108,0 3.39427,0.77564 4.12162,2.95768l3.92841,11.92967l3.87919,-11.92967z"></path>
      <path
        d="m249.85302,29.88692c0,1.01809 -0.5815,1.74544 -1.79374,2.47278a13.98971,13.98971 0 0 1 -7.08024,1.64884c-7.51589,0 -11.68675,-4.17086 -11.68675,-11.3477c0,-7.07977 4.07332,-11.58968 10.81356,-11.58968c6.88609,0 10.32961,4.65529 10.32961,9.50519c0,3.58842 -1.843,4.07332 -4.025,4.07332l-10.1355,0c0.58244,2.32693 2.08543,3.54012 4.80066,3.54012a8.84855,8.84855 0 0 0 3.92841,-0.97074a4.87669,4.87669 0 0 1 1.98788,-0.6298c1.55227,-0.00005 2.86111,1.74539 2.86111,3.29767zm-13.72293,-9.74764l7.51587,0a3.356,3.356 0 0 0 -3.58842,-3.68549c-2.23032,-0.00005 -3.49086,1.26097 -3.92745,3.68545l0,0.00004z"></path>
      <path
        d="m261.82862,30.95327q0,2.90938 -3.63767,2.90938q-3.63672,0 -3.63672,-2.90938l0,-16.77863q0,-2.90938 3.49182,-2.90938c2.52108,0 3.44257,1.21224 3.39427,2.90938l0,0.4849a5.73031,5.73031 0 0 1 5.23726,-3.443c1.98788,0 2.90938,0.96979 2.90938,3.24937c0,2.52155 -1.11564,3.2489 -3.20012,3.346c-2.522,0.0966 -3.686,0.4849 -4.55821,1.64836l-0.00001,11.493z"></path>
    </g>
  </svg>
</div>
