import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (value < 1000) {
      return value.toString();
    } else if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(2) + "K";
    }
    return (value / 1000000).toFixed(2) + "M";
  }
}
