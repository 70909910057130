<!-- Regular Mode -->
<ng-container *ngIf="regularMode">
  <app-message class="mt-1" *ngIf="GeneralValidation.PostInThePast"
    >You cannot schedule messages in the past. Update your schedule date and time.
  </app-message>
  <app-message class="mt-1" *ngIf="GeneralValidation.ExpirationInThePast"
    >You cannot set your post to expire in the past. Update your expiration date and time.
  </app-message>
  <app-message
    class="mt-1"
    data-test="validationmessage-facebookvalidation-nocontent-appmessage"
    *ngIf="FacebookValidation.NoContent"
    ><span class="font-medium">Facebook:</span> write a message or attach some media to post.
  </app-message>
  <app-message class="mt-1" *ngIf="FacebookValidation.invalidPhotoSizeError"
    ><span class="font-medium">Facebook:</span> photos must be less than 4 MBs.
  </app-message>
  <app-message class="mt-1" *ngIf="FacebookValidation.invalidVideoSizeError"
    ><span class="font-medium">Facebook:</span> videos must be less than a GB.
  </app-message>
  <app-message class="mt-1" *ngIf="FacebookValidation.onlyOneGifAllowedError"
    ><span class="font-medium">Facebook:</span> you can attach up to 1 animated GIF.
  </app-message>

  <app-message
    class="mt-1"
    data-test="validationmessage-instagramvalidation-nocontent-appmessage"
    *ngIf="InstagramValidation.NoContent"
    ><span class="font-medium">Instagram:</span> media is required for Instagram posts.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.NoPublishingTypeSelectedError"
    ><span class="font-medium">Instagram:</span> no publishing type selected.
  </app-message>

  <app-message class="mt-1" *ngIf="InstagramValidation.CharLimitError" i118n>
    <span class="font-medium">Instagram:</span> character limit exceeded.
  </app-message>

  <app-message class="mt-1" *ngIf="InstagramValidation.tooManyHashtagsError">
    <span class="font-medium">Instagram:</span> you’re exceeding the limit of 30 hashtags per post
  </app-message>

  <app-message class="mt-1" *ngIf="InstagramValidation.DevicesRequiredError"
    ><span class="font-medium">Instagram:</span> select the user you would like to send the Instagram reminder to.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidAspectRatio"
    ><span class="font-medium">Instagram:</span> your attached image has an aspect ratio outside of the supported range
    of 4:5 to 1.91:1 for direct publishing.
  </app-message>

  <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoAspectRatio"
    ><span class="font-medium">Instagram:</span> your attached video has an aspect ratio outside of the supported range
    of 4:5 to 16:9 for direct publishing.
  </app-message>

  <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoSizeError"
    ><span class="font-medium">Instagram:</span> your attached image is too large. Image should be less than 8 MB.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoSizeError"
    ><span class="font-medium">Instagram:</span> your attached video is too large. Image should be less than 100 MB.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.tooManyMediaForDirectPublishing"
    ><span class="font-medium">Instagram:</span> you can attach up to 10 media files.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.ZapierEnabledError"
    ><span class="font-medium">Instagram:</span> the SocialWeaver Zapier integration for Instagram is not configured or
    not enabled. Read
    <a href="https://www.socialweaver.com/help/" target="_blank">how to configure Instagram publishing</a>
    for more details.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoDimension"
    ><span class="font-medium">Instagram:</span> Images must have a min width of 320 and a max of 1440.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoDimension"
    ><span class="font-medium">Instagram:</span> Videos must have a max width of 1920.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoExtension"
    ><span class="font-medium">Instagram:</span> Photo extension is not valid. It must be a png, jpg, bmp.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoExtension"
    ><span class="font-medium">Instagram:</span> Video extension is not valid. It must be a mov or mp4.
  </app-message>
  <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoDuration"
    ><span class="font-medium">Instagram:</span> Video must be between 3 and 60 seconds long.
  </app-message>

  <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingVideoSize"
    ><span class="font-medium">Twitter:</span> your video file is too large. Videos should be less than 512 MB.
  </app-message>

  <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingVideoDuration"
    ><span class="font-medium">Twitter:</span> your video file duration is invalid. Videos duration should be between
    0.5 and 140 seconds.
  </app-message>

  <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingGifSize"
    ><span class="font-medium">Twitter:</span> your GIF file is too large. GIFs should be less than 15 MB.
  </app-message>
  <app-message
    class="mt-1"
    *ngIf="
      TwitterValidation.FewVideos ||
      TwitterValidation.VideoAndImages ||
      TwitterValidation.ManyImages ||
      TwitterValidation.MixesGifAndImages
    "
    ><span class="font-medium">Twitter:</span> you can attach up to 4 photos, 1 animated GIF, or 1 video.
  </app-message>
  <app-message class="mt-1" *ngIf="TwitterValidation.ManyGifs"
    ><span class="font-medium">Twitter:</span> you can attach up to 4 photos, 1 animated GIF, or 1 video.
  </app-message>
  <app-message class="mt-1" *ngIf="TwitterValidation.CharLimit"
    ><span class="font-medium">Twitter:</span> character limit exceeded.
  </app-message>
  <app-message
    class="mt-1"
    data-test="validationmessage-twittervalidation-nocontent-appmessage"
    *ngIf="TwitterValidation.NoContentTwitter"
    ><span class="font-medium">Twitter:</span> a message is required to tweet.
  </app-message>
  <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingImageSize"
    ><span class="font-medium">Twitter:</span> your image file is too large. Images should be less than 5 MB.
  </app-message>

  <app-message
    class="mt-1"
    data-test="validationmessage-linkedinvalidation-nocontent-appmessage"
    *ngIf="LinkedInValidation.NoContent"
    ><span class="font-medium">LinkedIn:</span> write a message or attach some media to post.
  </app-message>

  <app-message
    class="mt-1"
    data-test="validationmessage-linkedinvalidation-toomanymedia-appmessage"
    *ngIf="LinkedInValidation.TooManyMedia"
    ><span class="font-medium">LinkedIn:</span> you can attach up to 9 files.
  </app-message>

  <app-message class="mt-1" *ngIf="LinkedInValidation.FewVideos"
    ><span class="font-medium">LinkedIn:</span> you can attach up to 1 video.
  </app-message>
  <app-message class="mt-1" *ngIf="LinkedInValidation.VideoAndImages"
    ><span class="font-medium">LinkedIn:</span> you cannot publish images and videos in the same post.
  </app-message>
  <app-message class="mt-1" *ngIf="LinkedInValidation.ExceedingVideoSize"
    ><span class="font-medium">LinkedIn:</span> your video file is too large. Videos should be less than 200 MB.
  </app-message>
  <app-message class="mt-1" *ngIf="LinkedInValidation.HasImagesWithNotSupportedFormat"
    ><span class="font-medium">LinkedIn:</span> only *.png, *.jpeg, *.jpg and *.gif images are supported.
  </app-message>
  <app-message class="mt-1" *ngIf="LinkedInValidation.HasImagesWithExceedingPixels"
    ><span class="font-medium">LinkedIn:</span>Images can't exceed 36152320 pixels.
  </app-message>

  <app-message class="mt-1" *ngIf="GeneralValidation.TimezoneEmpty">Timezone is required.</app-message>
  <app-message class="mt-1" *ngIf="GeneralValidation.IncompleteExpirationDate"
    >Please complete the expiration date by filling in both the time and date fields.</app-message
  >
  <app-message class="mt-1" *ngIf="GeneralValidation.IncompleteScheduleDate"
    >Please complete the schedule by filling in both the time and date fields.</app-message
  >

  <app-message class="mt-1" *ngIf="GeneralValidation.MediaFilesUploading"
    >Please wait for all mediafiles to be uploaded.
  </app-message>
</ng-container>
<!-- Customization Mode -->
<ng-container *ngIf="customizationMode">
  <app-message class="mt-1" *ngIf="GeneralValidation.MediaFilesUploading"
    >Please wait for all mediafiles to be uploaded.
  </app-message>

  <app-message class="mt-1" *ngIf="GeneralValidation.PostInThePast"
    >You cannot schedule messages in the past. Update your schedule date and time.
  </app-message>
  <app-message class="mt-1" *ngIf="GeneralValidation.ExpirationInThePast"
    >You cannot set your post to expire in the past. Update your expiration date and time
  </app-message>
  <app-message class="mt-1" *ngIf="GeneralValidation.TimezoneEmpty">Timezone is required.</app-message>
  <app-message class="mt-1" *ngIf="GeneralValidation.IncompleteExpirationDate"
    >Please complete the expiration date by filling in both the time and date fields.</app-message
  >
  <app-message class="mt-1" *ngIf="GeneralValidation.IncompleteScheduleDate"
    >Please complete the schedule by filling in both the time and date fields.</app-message
  >
  <!-- Facebook -->
  <ng-container *ngIf="provider === 1">
    <app-message
      class="mt-1"
      data-test="validationmessage-facebookvalidation-nocontent-appmessage"
      *ngIf="FacebookValidation.NoContent"
      >Write a message or attach some media to post.
    </app-message>
    <app-message class="mt-1" *ngIf="FacebookValidation.invalidPhotoSizeError"
      >Your photos must be less than 4 MBs.
    </app-message>
    <app-message class="mt-1" *ngIf="FacebookValidation.invalidVideoSizeError"
      >Your videos must be less than a GB.
    </app-message>
    <app-message class="mt-1" *ngIf="FacebookValidation.onlyOneGifAllowedError"
      ><span class="font-medium">Facebook:</span> you can attach up to 1 animated GIF.
    </app-message>
  </ng-container>
  <!-- Instagram -->
  <ng-container *ngIf="provider === 6">
    <app-message
      class="mt-1"
      data-test="validationmessage-instagramvalidation-nocontent-appmessage"
      *ngIf="InstagramValidation.NoContent"
      >Media is required for Instagram posts.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.DevicesRequiredError"
      >Select the user you would like to send the Instagram reminder to.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.NoPublishingTypeSelectedError"
      >No publishing type selected.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidAspectRatio"
      >Your attached image has an aspect ratio outside of the supported range of 4:5 to 1.91:1 for direct publishing.
    </app-message>

    <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoAspectRatio"
      >Your attached video has an aspect ratio outside of the supported range of 4:5 to 16:9 for direct publishing.
    </app-message>

    <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoSizeError"
      >Your attached image is too large. Media should be less than 8 MB.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoSizeError"
      >Your attached video is too large. Media should be less than 100 MB.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.tooManyMediaForDirectPublishing"
      >You can attach up to 10 media files.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.ZapierEnabledError"
      >The SocialWeaver Zapier integration for Instagram is not configured or not enabled. Read
      <a href="https://www.socialweaver.com/help/" target="_blank">how to configure Instagram publishing</a>
      for more details.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoDimension"
      >Images must have a min width of 320 and a max of 1440.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoDimension"
      >Videos must have a max width of 1920.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidPhotoExtension"
      >Photo extension is not valid. It must be a png, jpg, bmp.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoExtension"
      >Video extension is not valid. It must be a mov, mp4.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.invalidVideoDuration"
      >Video must be between 3 and 60 seconds long.
    </app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.CharLimitError"> Character limit exceeded.</app-message>
    <app-message class="mt-1" *ngIf="InstagramValidation.tooManyHashtagsError">
      You’re exceeding the limit of 30 hashtags per post.</app-message
    >
  </ng-container>
  <!-- Twitter -->
  <ng-container *ngIf="provider === 2">
    <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingVideoSize"
      >Your video file is too large. Videos should be less than 512 MB.
    </app-message>

    <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingVideoDuration"
      >Your video file duration is invalid. Videos duration should be between 0.5 and 140 seconds.
    </app-message>

    <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingGifSize"
      >Your GIF file is too large. GIFs should be less than 15 MB.
    </app-message>
    <app-message
      class="mt-1"
      *ngIf="
        TwitterValidation.FewVideos ||
        TwitterValidation.VideoAndImages ||
        TwitterValidation.ManyImages ||
        TwitterValidation.MixesGifAndImages
      "
      >You can attach up to 4 photos, 1 animated GIF, or 1 video.
    </app-message>
    <app-message class="mt-1" *ngIf="TwitterValidation.ManyGifs"
      >You can attach up to 4 photos, 1 animated GIF, or 1 video.
    </app-message>
    <app-message class="mt-1" *ngIf="TwitterValidation.CharLimit">Character limit exceeded.</app-message>

    <app-message
      class="mt-1"
      data-test="validationmessage-twittervalidation-nocontent-appmessage"
      *ngIf="TwitterValidation.NoContentTwitter"
      >A message is required to tweet.
    </app-message>
    <app-message class="mt-1" *ngIf="TwitterValidation.ExceedingImageSize"
      >Your image file is too large. Images should be less than 5 MB.
    </app-message>
  </ng-container>
  <!-- LinkedIn -->
  <ng-container *ngIf="provider === 8">
    <app-message
      class="mt-1"
      data-test="validationmessage-linkedinvalidation-nocontent-appmessage"
      *ngIf="LinkedInValidation.NoContent"
      >Write a message or attach some media to post.
    </app-message>

    <app-message
      class="mt-1"
      data-test="validationmessage-linkedinvalidation-toomanymedia-appmessage"
      *ngIf="LinkedInValidation.TooManyMedia"
      >You can attach up to 9 files.
    </app-message>

    <app-message class="mt-1" *ngIf="LinkedInValidation.FewVideos">You can attach up to 1 video.</app-message>
    <app-message class="mt-1" *ngIf="LinkedInValidation.VideoAndImages"
      >You cannot publish images and videos in the same post.
    </app-message>
    <app-message class="mt-1" *ngIf="LinkedInValidation.ExceedingVideoSize"
      >Your video file is too large. Videos should be less than 200 MB.
    </app-message>
    <app-message class="mt-1" *ngIf="LinkedInValidation.HasImagesWithNotSupportedFormat"
      >Only *.png, *.jpeg, *.jpg and *.gif images are supported.
    </app-message>
    <app-message class="mt-1" *ngIf="LinkedInValidation.HasImagesWithExceedingPixels"
      >Images can't exceed 36152320 pixels.
    </app-message>
  </ng-container>
</ng-container>
