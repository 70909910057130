import {Injectable} from "@angular/core";
import {GetUsersRequestModel, Team, User} from "./user.interface";
import {Observable, of, Subject, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, map, share, tap} from "rxjs/operators";
import {environment} from "@environments/environment";
import {SwFieldSorting} from "@shared/utils/filter/filter.model";
import {Pagination} from "@utils/pagination/pagination.model";

@Injectable({
  providedIn: "root",
})
export class TeamService {
  private baseUrl = environment.api + "/UserData";

  private emailDuplicateCache: Map<string, boolean> = new Map();

  public usersInAccount: User[];

  public usersInAccountObservable: Subject<User[]> = new Subject<User[]>();

  constructor(private http: HttpClient) {}

  getAccountTeams(): Observable<Team[]> {
    return this.http.get<Team[]>(this.baseUrl + "/Teams");
  }

  getTeamUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + "/TeamUsers");
  }

  initEmailDuplicateCache(): void {
    this.emailDuplicateCache.clear();
  }

  isUserEmailTaken(emailAddress: string): Observable<string> {
    if (emailAddress) {
      // console.log("checking for duplicate", emailAddress);
      const cacheDup = this.emailDuplicateCache.get(emailAddress);
      if (cacheDup !== undefined) {
        if (cacheDup === true) {
          return of("true");
        } else {
          return of("false");
        }
      }

      const params = new HttpParams().set("email", encodeURIComponent(emailAddress));

      return this.http.get<string>(this.baseUrl + "/CheckForUser", {
        params: params,
      });
    }

    return of("false");
  }

  getAllUsers(cache = true): Observable<User[]> {
    if (cache && this.usersInAccount) {
      this.usersInAccountObservable.next(this.usersInAccount);
      return of(this.usersInAccount);
    } else {
      return this.refreshUsersInAccount();
    }
  }

  getAllUsersWithParameters(model: GetUsersRequestModel): Observable<Pagination<User>> {
    return this.http.post<Pagination<User>>(`${this.baseUrl}/GetAllUsersInAccount`, model).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error fetching users:", error);
        return throwError(error);
      }),
    );
  }

  invite(invites: any): Observable<User[]> {
    return this.http.put<User[]>(this.baseUrl + "/Users/", invites).pipe(
      tap((users: User[]) => {
        this.usersInAccount.push(...users);
        this.usersInAccountObservable.next(this.usersInAccount);
      }),
    );
  }

  resendInvitation(userId: string): Observable<any> {
    return this.http.put<any>(this.baseUrl + "/ResendInvitation/" + userId, {});
  }

  delete(userId: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + "/User/" + userId).pipe(
      tap(() => {
        this.usersInAccount = this.usersInAccount.filter((x) => x.Id !== userId);
        this.usersInAccountObservable.next(this.usersInAccount);
      }),
    );
  }

  clearCache() {
    this.usersInAccount = null;
    this.usersInAccountObservable.next([]);
  }

  private refreshUsersInAccount(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + "/AllUsers").pipe(
      map((users) => users.sort((a, b) => +b.EmailConfirmed - +a.EmailConfirmed)),
      tap((users: User[]) => {
        this.usersInAccount = users;
        this.usersInAccountObservable.next(this.usersInAccount);
      }),
      share(),
    );
  }
}
