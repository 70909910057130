import {BaseHub} from "./basehub";
import {EventEmitter} from "@angular/core";
import {ActivitiesAcknowledged, ActivitiesUpdated, ActivitiesAugmented} from "./hubs.interface";
import {Activity} from "@shared/activity/activity.model";

export class StreamHub extends BaseHub {
  public OnStreamState = new EventEmitter<boolean>();

  public OnActivitiesAcknowledged = new EventEmitter<ActivitiesAcknowledged>();

  public OnActivitiesUpdated = new EventEmitter<ActivitiesUpdated>();

  public OnActivitiesAugmented = new EventEmitter<ActivitiesAugmented>();

  start(parameters: any) {
    this.connect("stream", parameters)
      .then()
      .catch((error) => {
        if (!error.Authorization) {
          console.warn("[SignalR]: failed to connect to streamHub. No token or might be expired. Please login.");
        } else {
          console.error("[SignalR]: failed to connect to streamHub", error);
        }
      });
  }

  protected subscribeToEvents() {
    this.hubConnection.on("activitiesAcknowledged", (streamId: string, count: number) => {
      this.OnActivitiesAcknowledged.emit({
        StreamId: streamId,
        Count: count,
      });
    });

    this.hubConnection.on("activitiesUpdated", (streamId: string, activities: Activity[]) => {
      this.OnActivitiesUpdated.emit({
        StreamId: streamId,
        Activities: activities,
      });
    });

    this.hubConnection.on("activitiesAugmented", (streamId: string, activities: Activity[]) => {
      this.OnActivitiesAugmented.emit({
        StreamId: streamId,
        Activities: activities,
      });
    });

    this.hubConnection.on("isStreamLoaded", (isStreamLoaded: boolean) => {
      this.OnStreamState.emit(isStreamLoaded);
    });
  }
}
