import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {PublishSchedule} from "./publish-schedule.interface";
import {map, tap} from "rxjs/operators";
import moment from "moment-timezone";
import {Content} from "./content.interface";
import {CalendarSettingsService} from "../../pages/publisher/calendar/calendar-widget/calendar-settings/calendar-settings.service";

@Injectable({
  providedIn: "root",
})
export class PublishScheduleService {
  private baseUrl = environment.api + "/publishschedule";
  private change$: Subject<PublishSchedule> = new Subject();
  change: Observable<PublishSchedule> = this.change$.asObservable();

  constructor(
    private http: HttpClient,
    public calendarSettings: CalendarSettingsService,
  ) {}

  list(): Observable<PublishSchedule[]> {
    return this.http.get<PublishSchedule[]>(this.baseUrl).pipe(
      map((schedules) => {
        return this.sort(schedules);
      }),
    );
  }

  sort(schedules: PublishSchedule[]): PublishSchedule[] {
    return schedules.sort((a: PublishSchedule, b: PublishSchedule) => {
      const DATE_FORMAT = this.calendarSettings.DATE_FORMAT;
      const AStart = moment(a.StartAt, DATE_FORMAT);
      const AEnd = a.EndAt ? moment(a.EndAt, DATE_FORMAT) : null;
      const BStart = moment(b.StartAt, DATE_FORMAT);
      const BEnd = b.EndAt ? moment(b.EndAt, DATE_FORMAT) : null;
      const Alength = AEnd ? AEnd.diff(AStart, "days") : 1000000;
      const Blength = BEnd ? BEnd.diff(BStart, "days") : 1000000;
      return Blength - Alength;
    });
  }

  update(schedule: PublishSchedule): Observable<PublishSchedule> {
    return this.http
      .put<PublishSchedule>(this.baseUrl, schedule)
      .pipe(tap((editedSchedule) => this.change$.next(editedSchedule)));
  }

  create(schedule: PublishSchedule): Observable<PublishSchedule> {
    return this.http
      .post<PublishSchedule>(this.baseUrl, schedule)
      .pipe(tap((newSchedule) => this.change$.next(newSchedule)));
  }

  preview(schedule: PublishSchedule): Observable<Content[]> {
    return this.http.post<Content[]>(this.baseUrl + "/queue-preview", schedule);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<any>(this.baseUrl + "/" + id);
  }

  reschedule(queueItemId: string, date: string): Observable<void> {
    return this.http.post<void>(environment.api + "/queue/reschedule/" + queueItemId, {
      NewDateTime: date,
    });
  }
}
