<div
  class="flex text-sm p-4 rounded-md border border-solid border-light-brand-100 dark:border-dark-brand-100 bg-light-brand-100/5 dark:bg-dark-brand-100/5">
  <div class="flex-shrink-0 leading-zero">
    <app-icon icon="info" class="w-5 h-5 leading-zero text-light-brand-100 dark:text-dark-brand-100"></app-icon>
  </div>
  <div class="ml-3">
    <div class="text-light-brand-100 dark:text-dark-brand-100 -mt-px">
      <ng-content></ng-content>
    </div>
  </div>
</div>
