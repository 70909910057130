import {Component, OnInit} from "@angular/core";
import {Notification, SystemError} from "@shared/notifications/notification.interface";
import {BaseNotificationTypeComponent} from "../base-notification-type/base-notification-type.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-notification-systemerror",
  templateUrl: "./systemerror-type.component.html",
  styleUrls: ["./systemerror-type.component.scss"],
})
export class SystemErrorNotificationTypeComponent extends BaseNotificationTypeComponent implements OnInit {
  notification: Notification;

  ngOnInit(): void {
    if (this.notificationGroup.Notifications && this.notificationGroup.Notifications.length > 0) {
      this.notification = this.notificationGroup.Notifications[0];
    }
  }

  goToChannels() {
    if (this.notification.SystemError == null) return;

    let url = "";

    switch (this.notification.SystemError) {
      case SystemError.AuthenticationError:
        url = "/settings/channels";
    }

    this.notificationService
      .read(this.notificationGroup.NotificationGroupId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
        this.router.navigate([url]);
      });
  }
}
