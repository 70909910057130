<div class="link" *ngIf="linkPreview.title || linkPreview.image || linkPreview.description">
  <div *ngIf="isUpdatingImage" class="flex items-center justify-center h-[229px]">
    <app-loader></app-loader>
  </div>
  <ng-container *ngIf="!isUpdatingImage">
    <a [href]="linkPreview.url" target="_blank" (click)="onClick($event)">
      <div class="link-image" *ngIf="linkPreview.image" [class.video]="videoUrl">
        <img *ngIf="!showVideo" [src]="linkPreview.image" [imageWidth]="mediaWidth" [imageQuality]="mediaQuality" />
        <div *ngIf="videoUrl && !showVideo" class="play-image"></div>
        <!-- Play Icon -->
        <div
          *ngIf="videoUrl && !showVideo"
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center pointer-events-none z-40">
          <app-icon icon="play-video" class="text-white-alpha text-center opacity-95 w-8 h-auto"></app-icon>
        </div>
        <div class="player z-50" *ngIf="showVideo" [style.paddingTop]="videoRatio + '%'">
          <app-video-player [source]="videoUrl"></app-video-player>
        </div>
      </div>
    </a>
  </ng-container>
  <a [href]="linkPreview.url" target="_blank">
    <div class="link-metadata">
      <span
        *ngIf="linkPreview?.title && !isUpdatingTitle"
        class="link-title"
        [innerHTML]="linkPreview.title"
        [title]="linkPreview.title"
        [lang]="linkPreview.textLanguage"></span>
      <span *ngIf="isUpdatingTitle" class="link-title">...</span>
      <span
        *ngIf="linkPreview?.description && showDescription"
        class="link-description"
        data-test="link-metadata-preview-description"
        [innerHTML]="linkPreview.description"
        [title]="linkPreview.description"
        [lang]="linkPreview.textLanguage"></span>
      <span
        *ngIf="linkPreview?.domain && showDomain"
        [ngClass]="{'!mt-0': !linkPreview?.title}"
        class="link-domain"
        [innerHTML]="linkPreview.domain"></span>
    </div>
  </a>
</div>
