import {Component, Inject, OnInit} from "@angular/core";
import {OauthService} from "../oauth.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {OauthDescriptor} from "../oauth.interface";
import {ISelectedProfile} from "@composer/composer.component";
import {SuggestionService} from "../suggestions.service";
import {AccountSubType, ProfileTypes} from "../profile-types.enum";
import {SelectedSource} from "../suggestions.interface";
import {Source} from "@stream/stream.interface";
import {SourceService} from "../source.service";
import {forkJoin, switchMap} from "rxjs";
import {ProfileService} from "../profile.service";
import {UserService} from "@user/user.service";
import {UpgradeSubscriptionService} from "@shared/subscription/upgrade-subscription/upgrade-subscription.service";
import {SubscriptionService} from "@shared/subscription/subscription.service";
import {environment} from "@environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {animate, keyframes, query, stagger, style, transition, trigger} from "@angular/animations";
import {AnalyticsService} from "@utils/analytics-service/analytics.service";
import {IChatBox} from "@shared/utils/chat/chatbox.interface";

@UntilDestroy()
@Component({
  selector: "app-add-channel-dialog",
  templateUrl: "./add-channel-dialog.component.html",
  styleUrls: ["./add-channel-dialog.component.scss"],
  animations: [
    trigger("channelAnimation", [
      transition("* => *", [
        query(":enter", style({opacity: 0}), {optional: true}),
        query(
          ":enter",
          stagger("50ms", [
            animate(
              ".1s ease-in",
              keyframes([
                style({opacity: 0, transform: "translateY(10%)"}),
                style({opacity: 0.5, transform: "translateY(5%)"}),
                style({opacity: 1, transform: "translateY(0)"}),
              ]),
            ),
          ]),
          {optional: true},
        ),
      ]),
    ]),
  ],
})
export class AddChannelDialogComponent implements OnInit {
  step = 0;
  activeChannel = 0;
  profiles: ISelectedProfile[];
  descriptor: OauthDescriptor;
  savingFacebook: boolean;
  loading: boolean;
  sources: SelectedSource[];
  publicSearchQuery: string;
  facebookPublicSearchEnabled = false;
  twitterPublicSearchEnabled = false;
  instagramPublicSearchEnabled = false;
  linkedInPublicSearchEnabled = false;
  facebookPublicSearchLoading = false;
  twitterPublicSearchLoading = false;
  instagramPublicSearchLoading = false;
  linkedInPublicSearchLoading = false;
  MaxLicensedChannels: number;
  ChannelsOnAccountCount: number;
  CurrentSourcesOnAccount: Source[] = [];
  facebookError = "";
  twitterError = "";
  instagramError = "";
  linkedInError = "";
  generalError = "";

  cancelDisabled: boolean;

  selectedSources: SelectedSource[] = [];

  isProd = environment.production;

  constructor(
    private oauthService: OauthService,
    private suggestionsService: SuggestionService,
    private sourceService: SourceService,
    public dialogRef: MatDialogRef<AddChannelDialogComponent>,
    private profileService: ProfileService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public chatbox: IChatBox,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private upgradeSubscriptionService: UpgradeSubscriptionService,
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    if (this.data && this.data.provider) {
      switch (this.data.provider) {
        case "facebook":
        case "instagram":
        case "linkedin":
          this.step = 2;
          this.getDescriptorData(this.data.provider, this.data.descriptorId);
          break;
      }
    }

    this.profileService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.userService
          .me()
          .pipe(untilDestroyed(this))
          .subscribe((user) => {
            this.facebookPublicSearchEnabled = this.profileService.AnyFacebookProfileAdded() || user.IsDemoAdmin;
            this.twitterPublicSearchEnabled = this.profileService.AnyTwitterProfileAdded();
            this.instagramPublicSearchEnabled = this.profileService.AnyInstagramProfileAdded() || user.IsDemoAdmin;
            this.linkedInPublicSearchEnabled = this.profileService.AnyLinkedInProfileAdded();
          });
      });

    this.userService
      .getPlanUsage()
      .pipe(untilDestroyed(this))
      .subscribe((usageInfo) => {
        this.ChannelsOnAccountCount = usageInfo.BilledChannelsCount;
        this.MaxLicensedChannels = usageInfo.MaxLicensedChannels;
      });

    this.sourceService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((sources) => {
        this.CurrentSourcesOnAccount = sources;
      });

    this.goToActiveChannel();
  }

  goTo(step) {
    this.resetDialog();
    this.step = step;
  }

  goToActiveChannel() {
    if (this.facebookPublicSearchEnabled) {
      this.activeChannel = 0;
    } else if (!this.facebookPublicSearchEnabled && this.linkedInPublicSearchEnabled) {
      this.activeChannel = 1;
    } else if (
      !this.facebookPublicSearchEnabled &&
      !this.linkedInPublicSearchEnabled &&
      this.instagramPublicSearchEnabled
    ) {
      this.activeChannel = 2;
    } else if (
      !this.facebookPublicSearchEnabled &&
      !this.linkedInPublicSearchEnabled &&
      !this.instagramPublicSearchEnabled
    ) {
      this.activeChannel = 3;
    }
  }

  connect(provider: string) {
    this.oauthService
      .getUrl(provider, false)
      .pipe(untilDestroyed(this))
      .subscribe((url: string) => {
        window.location.href = url;
      });
  }

  request() {
    this.chatbox.requestChannel();
  }

  getDescriptorData(provider: string, descriptorId: string) {
    this.loading = true;
    this.oauthService
      .getDescriptor(provider, descriptorId)
      .pipe(untilDestroyed(this))
      .subscribe((descriptor: OauthDescriptor) => {
        this.profiles = descriptor.Profiles.map((profile) => {
          return {
            profile,
            selected: false,
          };
        });
        this.loading = false;
      });
  }

  get selectedProfilesNumber(): number {
    return this.profiles ? this.profiles.filter((profile) => profile.selected).length : 0;
  }

  // return true if the User can't add a channel
  get isChannelsExceeded(): boolean {
    if (this.MaxLicensedChannels === -1) return false;
    return this.selectedProfilesNumber + this.ChannelsOnAccountCount > this.MaxLicensedChannels;
  }

  isChannelCountExceeded(channelCount: number): boolean {
    if (this.MaxLicensedChannels === -1) return false;
    return channelCount > this.MaxLicensedChannels;
  }

  saveFacebookProfiles() {
    this.savingFacebook = true;
    const descriptor: OauthDescriptor = {
      ...this.descriptor,
    } as OauthDescriptor;
    descriptor.Profiles = this.profiles.filter((p) => p.selected).map((p) => p.profile);
    this.oauthService
      .saveByDescriptor(this.data.provider, descriptor)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.subscriptionService.get(false).pipe(untilDestroyed(this))),
        switchMap(() => this.userService.me(false).pipe(untilDestroyed(this))),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
          this.analyticsService.track("Add Channel", {
            type: "Private",
            network: this.data.provider.charAt(0).toUpperCase() + this.data.provider.slice(1),
          });
        },
        error: () => (this.savingFacebook = false),
      });
  }

  searchPublicProfiles() {
    if (this.publicSearchQuery.length > 0) {
      this.instagramError = "";
      this.twitterError = "";
      this.facebookError = "";
      this.linkedInError = "";
      this.generalError = "";
      this.sources = [];

      if (this.instagramPublicSearchEnabled && this.activeChannel === 2) {
        this.instagramPublicSearchLoading = true;

        this.suggestionsService
          .searchPublicSources(this.publicSearchQuery, ProfileTypes.InstagramAccount, 12)
          .pipe(untilDestroyed(this))
          .subscribe(
            (sources) => {
              const selectedSources = sources.map((source) => this.mapSourceSelectedFromSource(source));

              selectedSources.forEach((source) => {
                this.sources.push(source);
              });
            },
            (error) => {
              this.instagramError = error.error.Exception.Message;
              this.instagramPublicSearchLoading = false;
            },
            () => {
              this.instagramPublicSearchLoading = false;
            },
          );
      }

      if (this.facebookPublicSearchEnabled && this.activeChannel === 0) {
        this.facebookPublicSearchLoading = true;

        this.suggestionsService
          .searchPublicSources(this.publicSearchQuery, ProfileTypes.Facebook, 12)
          .pipe(untilDestroyed(this))
          .subscribe(
            (sources) => {
              const selectedSources = sources.map((source) => this.mapSourceSelectedFromSource(source));

              selectedSources.forEach((source) => {
                this.sources.push(source);
              });
            },
            (error) => {
              this.facebookError = error.error.Exception.Message;
              this.facebookPublicSearchLoading = false;
            },
            () => {
              this.facebookPublicSearchLoading = false;
            },
          );
      }

      if (this.twitterPublicSearchEnabled && this.activeChannel === 1) {
        this.twitterPublicSearchLoading = true;

        let subtype = null;
        let searchQuery = this.publicSearchQuery;

        if (this.publicSearchQuery.startsWith("#")) {
          subtype = AccountSubType.PublicHashtag;
          searchQuery = searchQuery.replace("#", "");
        }

        if (this.publicSearchQuery.startsWith("@")) {
          searchQuery = searchQuery.replace("@", "");
        }

        this.suggestionsService
          .searchPublicSources(searchQuery, ProfileTypes.TwitterAccount, 12, subtype)
          .pipe(untilDestroyed(this))
          .subscribe(
            (sources) => {
              console.log({sources});
              const selectedSources = sources.map((source) => this.mapSourceSelectedFromSource(source));
              selectedSources.forEach((source) => {
                this.sources.push(source);
              });
            },
            (error) => {
              this.twitterError = error.error.Exception.Message;
              this.twitterPublicSearchLoading = false;
            },
            () => {
              this.twitterPublicSearchLoading = false;
            },
          );
      }

      if (this.linkedInPublicSearchEnabled && this.activeChannel == 3) {
        this.linkedInPublicSearchLoading = true;
        this.suggestionsService
          .searchPublicSources(this.publicSearchQuery, ProfileTypes.LinkedIn, 12)
          .pipe(untilDestroyed(this))
          .subscribe(
            (sources) => {
              console.log({sources});
              const selectedSources = sources.map((source) => this.mapSourceSelectedFromSource(source));
              selectedSources.forEach((source) => {
                this.sources.push(source);
              });
            },
            (error) => {
              this.linkedInError = error.error.Exception.Message;
              this.linkedInPublicSearchLoading = false;
            },
            () => {
              this.linkedInPublicSearchLoading = false;
            },
          );
      }
    } else {
      this.snackBar.open("Please enter the channel name you would like to search for.", null, {
        verticalPosition: "top",
        duration: 3000,
      });
    }
  }

  getNumberOfSourceSelected(): number {
    if (!this.selectedSources) {
      return 0;
    }
    return this.selectedSources.length;
  }

  sourceSelected(selectedSource: SelectedSource) {
    if (!selectedSource.affectsCount) {
      return;
    }
    //If the user is selecting a source, we check if adding that source as selected would exceed the plan max channels if it does we will just show the upgrade dialog without
    //selecting the source
    if (
      this.MaxLicensedChannels > -1 && // there is a limited on the number of Channels
      !selectedSource.selected &&
      this.getNumberOfSourceSelected() > 0 &&
      this.NumberOfChannelsSelected + 1 > this.MaxLicensedChannels
    ) {
      this.upgradeSubscriptionService
        .showUpgradeSubscriptionDialog()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result?.upgradeClicked) {
            this.dialogRef.close();
          }
        });
      return;
    }

    selectedSource.selected = !selectedSource.selected;

    if (selectedSource.selected) {
      this.selectedSources.push(selectedSource);
    } else {
      this.selectedSources = this.selectedSources.filter(
        (x) => x.source.Identifier !== selectedSource.source.Identifier,
      );
    }
  }

  mapSourceSelectedFromSource(source: Source): SelectedSource {
    const alreadyExists = this.CurrentSourcesOnAccount.some((x) => x.Id === source.Id);
    const isNewSelection = this.selectedSources.some((x) => x.source.Identifier === source.Identifier);

    return {
      selected: alreadyExists || isNewSelection,
      affectsCount: !alreadyExists,
      source: source,
    };
  }

  addSelectedSources() {
    if (this.MaxLicensedChannels > -1 && this.NumberOfChannelsSelected > this.MaxLicensedChannels) {
      return;
    }

    const tasks = [];
    this.loading = true;
    this.generalError = "";

    this.selectedSources.forEach((source) => {
      tasks.push(this.sourceService.addSource(source.source));
    });

    forkJoin(tasks)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.subscriptionService.get(false).pipe(untilDestroyed(this))),
        switchMap(() => this.userService.me(false).pipe(untilDestroyed(this))),
      )
      .subscribe(
        () => {
          this.dialogRef.close(true);
          this.loading = false;
          if (this.activeChannel === 0) {
            this.analyticsService.track("Add Channel", {
              type: "Public",
              network: "Facebook",
            });
          } else if (this.activeChannel === 1) {
            this.analyticsService.track("Add Channel", {
              type: "Public",
              network: "LinkedIn",
            });
          } else if (this.activeChannel === 2) {
            this.analyticsService.track("Add Channel", {
              type: "Public",
              network: "Instagram",
            });
          }
        },
        (error) => {
          this.generalError = this.generalError + " " + error.error.Exception.Message;
          this.loading = false;
        },
      );
  }

  get NumberOfChannelsSelected(): number {
    if (this.selectedSources && !this.profiles) {
      return this.selectedSources.length + this.ChannelsOnAccountCount;
    }

    if (this.profiles) {
      return this.profiles.filter((x) => x.selected).length + this.ChannelsOnAccountCount;
    }

    return this.ChannelsOnAccountCount;
  }

  public CanSaveSources(): boolean {
    return (
      this.getNumberOfSourceSelected() > 0 &&
      (this.NumberOfChannelsSelected <= this.MaxLicensedChannels || this.MaxLicensedChannels === -1)
    );
  }

  public channelSelected(profile) {
    profile.selected = !profile.selected;

    if (this.isChannelsExceeded) {
      this.upgradeSubscriptionService
        .showUpgradeSubscriptionDialog()
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          if (result?.upgradeClicked) {
            this.dialogRef.close();
          }
        });
    }
  }

  public resetDialog() {
    this.profiles = null;
    this.sources = null;
    this.publicSearchQuery = "";
  }

  public hasChannels() {
    return !this.profiles || this.profiles.length > 0;
  }

  public searchFacebook() {
    this.step = 4;
    this.activeChannel = 0;
  }

  public searchTwitter() {
    this.step = 5;
    this.activeChannel = 1;
  }

  public searchInstagram() {
    this.step = 6;
    this.activeChannel = 2;
  }

  public searchLinkedIn() {
    this.step = 8;
    this.activeChannel = 3;
  }
}
