import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {CarouselItem} from "../attachments/carousel-attachment/carousel-attachment.component";
import {CarouselMedia, InstagramImage, InstagramVideo} from "../activity.interface";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {MediaFileUploaded} from "@publisher/content.interface";

export class InstagramMedia {
  constructor(
    public Url?: string,
    public LikesCount?: number,
    public Tags?: any,
    public CarouselMedia?: CarouselMedia[],
    public Image?: InstagramImage,
    public Video?: InstagramVideo,
    public MoreCommentsAvailable?: boolean,
  ) {}

  get photosAttachment(): ImageAttachment[] {
    if (this.Image && this.Image.StandardResolution) {
      return [
        {
          src: this.Image.StandardResolution.Url,
          thumb: this.Image.StandardResolution.Url,
          height: this.Image.StandardResolution.Height,
          width: this.Image.StandardResolution.Width,
        },
      ];
    }
  }

  get videoAttachment(): VideoAttachment {
    if (this.Video && this.Video.StandardResolution) {
      return {
        type: "video",
        preview: this.Video.StandardResolution.ThumbnailUrl,
        source: this.Video.StandardResolution.Url,
        height: this.Video.StandardResolution.Height,
        width: this.Video.StandardResolution.Width,
      };
    }
  }

  public setVideoAttachment(video: MediaFileUploaded) {
    this.Video = {
      StandardResolution: {
        Url: video.BlobUrl,
        ThumbnailUrl: video.PreviewUrl,
        Height: 0,
        Width: 0,
      },
    };
  }

  public setPhotosAttachment(images: MediaFileUploaded[]) {
    this.Image = {
      StandardResolution: {
        Url: images[0].BlobUrl,
        ThumbnailUrl: images[0].BlobUrl,
        Height: 0,
        Width: 0,
      },
    };
  }

  public setCarouselItems(images: MediaFileUploaded[]) {
    this.CarouselMedia = images.map((image) => {
      if (image.MediaType.startsWith("video")) {
        return {
          Video: {
            StandardResolution: {
              Url: image.BlobUrl,
              ThumbnailUrl: image.PreviewUrl,
              Height: 0,
              Width: 0,
            },
          },
        };
      }
      return {
        Image: {
          StandardResolution: {
            Url: image.BlobUrl,
            ThumbnailUrl: image.BlobUrl,
            Height: 0,
            Width: 0,
          },
        },
      };
    });
  }

  get carouselItems(): CarouselItem[] {
    if (this.CarouselMedia && this.CarouselMedia.length) {
      return this.CarouselMedia.map((carouselMedia) => {
        if (carouselMedia.Image) {
          return {
            image: carouselMedia.Image.StandardResolution.Url,
          };
        }
        if (carouselMedia.Video) {
          return {
            video: {
              type: "video",
              preview: carouselMedia.Video.StandardResolution.ThumbnailUrl,
              source: carouselMedia.Video.StandardResolution.Url,
            },
          };
        }
      });
    }
  }
}
