import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-sidenav-divider",
  templateUrl: "./sidenav-divider.component.html",
  styleUrls: ["./sidenav-divider.component.scss"],
})
export class SidenavDividerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
