<!-- Direct Publishing via Zapier -->
<ng-container>
  <header class="dialog-header">
    <a
      class="absolute top-0 right-0 text-xs text-light-base-400 dark:text-dark-base-400 hover:text-light-base-600 dark:hover:text-white-alpha"
      (click)="close()"
      >Cancel</a
    >
    <div class="text-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-1">
      SocialWeaver & Zapier Integration
    </div>
    <div class="text-center text-xs text-light-base-400 dark:text-dark-base-400">
      Enable hundreds of productivity apps with Zapier.
    </div>
  </header>
</ng-container>
<!-- Errors -->
<app-alert *ngIf="error">
  <app-alert-error>
    {{ error }}
  </app-alert-error>
</app-alert>
<div class="relative flex flex-col">
  <div class="relative flex-1 px-0.5">
    <div class="relative inline-flex flex-col h-full w-full shadow rounded-md p-5 bg-white dark:bg-dark-base-100">
      <div class="grid grid-cols-2 gap-4 h-52 max-w-xl">
        <div class="flex flex-col justify-center items-start">
          <div class="text-xl leading-7 text-light-base-600 dark:text-dark-base-400 font-bold mt-2.5">
            Automate your Instagram publishing
          </div>
          <div class="text-xs text-light-base-400 dark:text-dark-base-400 mt-2.5">
            Connect your Zapier account and enable Instagram direct publishing and hundreds of other productivity apps
            at no additional cost to your existing plan.
          </div>
          <div class="text-xs text-light-base-400 dark:text-dark-base-400 font-medium mt-2.5">
            No Zapier account?
            <a
              href="https://zapier.com/sign-up/?utm_source=partner_socialweaver_signup&utm_medium=embed_custom_zapier&utm_campaign=socialweaver_zapier_integration"
              target="_blank"
              >get one for free</a
            >.
          </div>
        </div>
        <div class="relative flex flex-col justify-center items-center">
          <div class="flex items-center justify-center h-10 w-10 rounded-full text-white">
            <app-icon icon="instagram-logo"></app-icon>
          </div>
          <div class="flex items-center justify-center h-10 w-10 rounded-full text-white">
            <app-icon icon="zapier-logo"></app-icon>
          </div>
          <div class="flex items-center justify-center h-10 w-10 rounded-full text-white">
            <app-icon icon="socialweaver" class="text-light-brand-100"></app-icon>
          </div>
          <div class="flex items-center justify-center h-9 w-9 rounded-full text-white">
            <app-icon icon="slack-logo"></app-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex-1 flex justify-between mt-4">
  <app-basic-button (buttonClick)="close()" minWidth="116">Skip for Now</app-basic-button>
  <app-button (buttonClick)="AuthorizeApp()" [loading]="isWaitingAuth" minWidth="128" type="inline"
    >Connect Zapier
  </app-button>
</div>
