import {Injectable} from "@angular/core";
import {Theme} from "@user/user.interface";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private darkTheme = new BehaviorSubject<boolean>(false);

  isDarkTheme = this.darkTheme.asObservable();

  private storageKey = "currentTheme";

  constructor() {
    this.refreshTheme();
  }

  setTheme(theme: Theme): void {
    const isDarkTheme = theme === Theme.Dark;

    this.darkTheme.next(isDarkTheme);

    this.localSaveTheme(theme);
  }

  getTheme(): Theme {
    const isDark = this.darkTheme.getValue();
    return isDark ? Theme.Dark : Theme.Light;
  }

  refreshTheme() {
    const selectedTheme = JSON.parse(localStorage.getItem(this.storageKey)) as Theme;

    this.darkTheme.next(selectedTheme === Theme.Dark);
  }

  protected localSaveTheme(theme: Theme) {
    localStorage.setItem(this.storageKey, JSON.stringify(theme));
  }
}
