import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  constructor() {}

  detectLanguageOnText(text: string): string {
    return /[\u0621-\u064A]+/.test(text) ? "arabic" : "english";
  }
}
