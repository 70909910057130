<span class="comment">
  <strong
    ><a class="comment-author" [href]="comment.Author.Url">{{
      comment.Author.FriendlyName || comment.Author.ScreenName
    }}</a></strong
  >
  <span [innerHTML]="text"></span>
  <div class="comment-actions">
    <span class="reply" (click)="toggleComment()">Reply</span>
    <span class="dot" role="presentation" aria-hidden="true"> &middot; </span>
    <span class="delete" (click)="delete.emit()">Delete</span>
    <span class="dot" role="presentation" aria-hidden="true"> &middot; </span>
    <span class="hide-show" *ngIf="!comment.Hidden" (click)="hideInstagramComment()">Hide</span>
    <span class="hide-show" *ngIf="comment.Hidden" (click)="showInstagramComment()">Show</span>
    <span
      class="dot"
      role="presentation"
      *ngIf="comment.CommentsCount > 0 && (comment.Comments === null || comment.Comments.length === 0)"
      aria-hidden="true">
      &middot;
    </span>
    <span
      class="reply"
      *ngIf="comment.CommentsCount > 0 && (comment.Comments === null || comment.Comments.length === 0)"
      (click)="loadMoreInstagramComments()"
      >Get Replies
    </span>
    <span class="dot" role="presentation" aria-hidden="true"> &middot; </span>
    <span class="time-stamp">{{ comment.CreatedTime | amTimeAgo }}</span>
  </div>

  <div *ngFor="let comment2 of comment.Comments">
    <p class="insta-cmt" style="padding-left: 5vh">
      <strong
        ><a class="comment-author" [href]="comment2.Author.Url">{{
          comment2.Author.FriendlyName || comment2.Author.ScreenName
        }}</a></strong
      >
      {{ comment2.ActivityCommentText || comment2.Text }} <br />
      <span class="action-btn source-created"
        ><small><span>Followers</span>: {{ comment.Author.FollowersCount }} </small></span
      >
    </p>
  </div>

  <div class="comment more-comments-link" *ngIf="nextPageCursorId" style="padding-left: 5vh">
    <a (click)="loadMoreInstagramComments()">Load more replies</a>
  </div>
  <app-comment-input
    *ngIf="toggle"
    label="Reply to @{{ comment.Author.FriendlyName || comment.Author.ScreenName }}"
    provider="instagram"
    [activity]="activity"
    [parentComment]="comment"
    (commented)="toggleComment()"></app-comment-input>
</span>
