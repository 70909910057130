import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {Observable} from "rxjs";
import {IUtmPreset, UtmDynamicParams} from "./utm.interface";
import {shareReplay} from "rxjs/operators";
import {AutoCompleteInfo} from "../autocompleteinput/autocompleteinput.component";

export interface DynamicParameterOptions {
  Campaign: AutoCompleteInfo[];
  Source: AutoCompleteInfo[];
  Medium: AutoCompleteInfo[];
  Term: AutoCompleteInfo[];
  Content: AutoCompleteInfo[];
}

@Injectable({
  providedIn: "root",
})
export class UtmService {
  private baseUrl = environment.api + "/UtmPreset";
  private presetsObservable: Observable<IUtmPreset[]>;

  //Data structure for dyn paramter
  //[
  //   {
  //     DisplayName: "Channel Type",
  //     Value: "{channelType}",
  //     Custom: false,
  //   },
  // ]
  options: DynamicParameterOptions = {
    Campaign: [],
    Source: [],
    Medium: [],
    Term: [],
    Content: [],
  };

  constructor(private httpClient: HttpClient) {
    // console.log("UtmService.constructor()");
    this.updatePresetList();
  }

  private updatePresetList() {
    this.presetsObservable = this.httpClient.get<IUtmPreset[]>(this.baseUrl + "/getAll").pipe(shareReplay(1));
  }

  getAll(): Observable<IUtmPreset[]> {
    return this.presetsObservable;
  }

  createUtmPreset(model: IUtmPreset): Observable<IUtmPreset> {
    const preset = this.httpClient.post<IUtmPreset>(this.baseUrl + "/createUtmPreset", model);

    this.updatePresetList();
    return preset;
  }

  editUtmPreset(model: IUtmPreset): Observable<IUtmPreset> {
    const preset = this.httpClient.put<IUtmPreset>(this.baseUrl + "/editUtmPreset", model);

    this.updatePresetList();
    return preset;
  }

  deleteUtmPreset(id: string): Observable<any> {
    const preset = this.httpClient.delete(this.baseUrl + "/removeUtmPreset/" + id);

    this.updatePresetList();
    return preset;
  }

  transformPreset(presetParams: UtmDynamicParams, value: string): string {
    if (value == "{channelType}") {
      return ProfileTypes[presetParams.networkType];
    }

    this.updatePresetList();
    return value;
  }
}
