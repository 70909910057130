<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400">
        <div *ngIf="action === 'reply'">Reply</div>
        <div *ngIf="action === 'retweet'">Retweet</div>
      </div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-2.5"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Retweet Reply Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-44px)] min-w-[500px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div class="flex-1">
        <section class="relative inline-flex flex-col h-full w-full rounded-md p-5 box-border">
          <div class="mb-4 text-light-base-400 dark:text-dark-base-400">
            <span *ngIf="action === 'reply'"
              >Replying to
              <a [href]="activity.Author?.DirectUrlToProfile || activity.Source?.Url" target="_blank"
                >&#64;{{ postSource.ScreenName }}</a
              >
            </span>
          </div>
          <app-alert *ngIf="error" class="mt-5">
            <app-alert-error>{{ error }}</app-alert-error>
          </app-alert>
          <!-- Editor -->
          <app-composer-editor
            data-test="retweet-reply-editor-component"
            (editorChange)="onEditorChange($event)"
            [limitChars]="true"
            [showProviderLabel]="false"
            [dialogRef]="dialogRef"
            [activity]="activity"
            [placeholder]="action === 'reply' ? 'Tweet your reply...' : 'Add a comment'"
            [addPhoto]="true"
            [provider]="2"
            class="mb-5 !mt-0"
            [enableMentions]="true">
            <div class="flex items-center mb-4" share>
              <app-profile-picture
                [picture]="profile.Picture"
                provider="TwitterAccount"
                [hasChannel]="true"
                [showBadge]="true"
                pictureSize="40"
                class="mr-2.5">
              </app-profile-picture>
              <strong>{{ profile.Name }}</strong>
            </div>
            <!-- Embedded Activity -->
            <div class="embedded-activity">
              <app-activity
                [activity]="activity"
                [showFooter]="false"
                class="border border-solid border-light-base-300 dark:border-dark-base-300 rounded-md"></app-activity>
            </div>
          </app-composer-editor>
          <!-- Action -->
          <app-button
            (buttonClick)="publish()"
            [loading]="loading"
            type="block"
            [disabled]="action === 'reply' && !model.TwitterText?.length">
            <ng-container *ngIf="action === 'reply'">Reply</ng-container>
            <ng-container *ngIf="action === 'retweet'">Retweet</ng-container>
          </app-button>
        </section>
      </div>
    </ng-scrollbar>
  </div>
</div>
