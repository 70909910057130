import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {map, tap} from "rxjs/operators";
import {ColorService} from "@utils/color-service/color.service";
import {EventEmitter} from "@angular/core";
import {AsyncMessageService} from "@utils/async-message.service";
import {Content, Tag} from "@publisher/content.interface";
import {TagsUpdatedModel} from "@shared/utils/signalRHubs/hubs.interface";

@Injectable({
  providedIn: "root",
})
export class TagService {
  private baseUrl = environment.api + "/tags";

  private tagsUpdated$: EventEmitter<Tag[]> = new EventEmitter(true);

  private tagCreatedEvent: EventEmitter<Tag> = new EventEmitter(true);

  public allcontentTag: Tag = {
    Id: "-",
    Name: "all content",
    IsSystem: true,
    ColorHex: "#2f80e7",
    FgColorHex: "#ffffff",
  };

  constructor(
    private httpClient: HttpClient,
    private colorService: ColorService,
    private asyncMessageService: AsyncMessageService,
  ) {}

  init() {
    this.asyncMessageService.accountHub.OnTagsUpdated.subscribe((model: TagsUpdatedModel) => {
      model.Tags.forEach((tag: Tag) => {
        tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
      });

      this.tagsUpdated$.emit(model.Tags);
    });
  }

  list(): Observable<Tag[]> {
    return this.httpClient.get<Tag[]>(this.baseUrl).pipe(
      map((tags: Tag[]) => {
        tags.forEach((tag: Tag) => {
          tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
        });
        return tags;
      }),
    );
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(this.baseUrl + "/" + id);
  }

  create(tag: Tag): Observable<Tag> {
    return this.httpClient.post<Tag>(this.baseUrl, tag).pipe(
      tap((tag) => {
        tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
        this.tagCreatedEvent.emit(tag);
      }),
    );
  }

  edit(tag: Tag): Observable<Tag> {
    return this.httpClient.put<Tag>(this.baseUrl, tag);
  }

  batchCreate(tags: Tag[]): Observable<Tag[]> {
    tags = (tags || []).filter((tag) => !tag.Id);
    if (tags.length) {
      return this.httpClient.post<Tag[]>(this.baseUrl + "/createTags", tags).pipe(
        tap((tags) =>
          tags.forEach((tag) => {
            tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
            this.tagCreatedEvent.emit(tag);
          }),
        ),
      );
    } else {
      return of([]);
    }
  }

  mapTagsInContent(contents: Content[]): Content[] {
    for (const content of contents) {
      content.Tags = this.mapTags(content.Tags);
    }
    return contents;
  }

  mapTags(tags: Tag[]): Tag[] {
    if (tags) {
      tags.forEach((tag) => {
        if (tag.ColorHex) tag.FgColorHex = this.colorService.generateFgColor(tag.ColorHex);
      });
    }
    return tags.filter((x) => x.FgColorHex != null);
  }

  onTagsUpdated(): EventEmitter<Tag[]> {
    return this.tagsUpdated$;
  }

  onTagCreatedEvent(): EventEmitter<Tag> {
    return this.tagCreatedEvent;
  }
}
