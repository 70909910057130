import {Component, OnInit} from "@angular/core";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {IProfile} from "@shared/channel/profile.interface";
import {Notification, PublishedContent} from "@shared/notifications/notification.interface";
import Autolinker from "autolinker";
import {BaseNotificationTypeComponent} from "../base-notification-type/base-notification-type.component";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-notification-publishevent",
  templateUrl: "./publishevent-type.component.html",
  styleUrls: ["./publishevent-type.component.scss"],
})
export class PublishEventNotificationTypeComponent extends BaseNotificationTypeComponent implements OnInit {
  profileTypesOnNotification: Set<string> = new Set();

  publishedEventsPerNetwork = {
    Facebook: [],
    TwitterAccount: [],
    InstagramAccount: [],
    LinkedIn: [],
  };

  facebookProfiles: IProfile[];
  twitterProfiles: IProfile[];
  instagramProfiles: IProfile[];
  linkedInProfiles: IProfile[];
  notifications: Notification[];

  ngOnInit() {
    this.groupNotifications();
  }

  private groupNotifications() {
    const notificationWithPublishedEvents = this.notificationGroup.Notifications[0];

    const profiles = notificationWithPublishedEvents.PublishedEvents.map((p) => p.Profile);
    this.facebookProfiles = profiles.filter((profile) => profile.Type === ProfileTypes.Facebook);
    this.twitterProfiles = profiles.filter((profile) => profile.Type === ProfileTypes.TwitterAccount);
    this.instagramProfiles = profiles.filter((profile) => profile.Type === ProfileTypes.InstagramAccount);
    this.linkedInProfiles = profiles.filter((profile) => profile.Type === ProfileTypes.LinkedIn);

    this.profileTypesOnNotification = new Set(notificationWithPublishedEvents.PublishedEvents.map((x) => x.Type));

    this.publishedEventsPerNetwork.Facebook = notificationWithPublishedEvents.PublishedEvents.filter(
      (x) => x.Type === "Facebook",
    );
    this.publishedEventsPerNetwork.Facebook?.forEach(
      (x) => (x.MediaFiles = x.MediaFiles.filter((x) => x.EnabledForFacebook)),
    );

    this.publishedEventsPerNetwork.TwitterAccount = notificationWithPublishedEvents.PublishedEvents.filter(
      (x) => x.Type === "TwitterAccount",
    );
    this.publishedEventsPerNetwork.TwitterAccount?.forEach(
      (x) => (x.MediaFiles = x.MediaFiles.filter((x) => x.EnabledForTwitter)),
    );

    this.publishedEventsPerNetwork.InstagramAccount = notificationWithPublishedEvents.PublishedEvents.filter(
      (x) => x.Type === "InstagramAccount",
    );
    this.publishedEventsPerNetwork.InstagramAccount?.forEach(
      (x) => (x.MediaFiles = x.MediaFiles.filter((x) => x.EnabledForInstagram)),
    );

    this.publishedEventsPerNetwork.LinkedIn = notificationWithPublishedEvents.PublishedEvents.filter(
      (x) => x.Type === "LinkedIn",
    );
    this.publishedEventsPerNetwork.InstagramAccount?.forEach(
      (x) => (x.MediaFiles = x.MediaFiles.filter((x) => x.EnabledForLinkedIn)),
    );
  }

  getProfiles(provider: string): IProfile[] {
    if (provider === "Facebook") {
      return this.facebookProfiles;
    }
    if (provider === "InstagramAccount") {
      return this.instagramProfiles;
    }
    if (provider === "TwitterAccount") {
      return this.twitterProfiles;
    }
    if (provider == "LinkedIn") {
      return this.linkedInProfiles;
    }
  }

  channelHasError(profile: IProfile) {
    const notificationWithPublishedEvents = this.notificationGroup.Notifications[0];
    return notificationWithPublishedEvents.PublishedEvents.filter((x) => x.ProfileId === profile.Id).some(
      (x) => x.ErrorMessage,
    );
  }

  getNotificationDate(): string {
    const notificationWithPublishedEvents = this.notificationGroup.Notifications[0];
    return notificationWithPublishedEvents.PublishedEvents[0].PublishedAt;
  }

  getNotificationTitle(notification: Notification): string {
    if (notification.NotificationType === 0 || notification.NotificationType === 1) {
      return notification.Text;
    }

    return notification.Title;
  }

  notificationGotAnyError(notification: Notification) {
    return notification.PublishedEvents.some((x) => x.ErrorMessage);
  }

  notificationGotErrorByType(notification: Notification, type: ProfileTypes) {
    return notification.PublishedEvents.some((x) => x.ErrorMessage && x.Profile.Type == type);
  }

  getPublishEventText(publishEvent: PublishedContent) {
    let text;

    if (publishEvent) {
      if (publishEvent.Type === "Facebook") {
        text = publishEvent.FacebookText;
      }
      if (publishEvent.Type === "InstagramAccount") {
        text = publishEvent.InstagramText;
      }
      if (publishEvent.Type === "TwitterAccount") {
        text = publishEvent.TwitterText;
      }
      if (publishEvent.Type === "LinkedIn") {
        text = publishEvent.LinkedInText;
      }
    } else {
      text = publishEvent.Text;
    }

    if (text == null) {
      return "";
    }

    return Autolinker.link(text, {
      stripPrefix: false,
      replaceFn: (match) => {
        const originalTag = match.buildTag();
        return new Autolinker.HtmlTag({
          tagName: originalTag.getTagName(),
          innerHtml: originalTag.getInnerHTML(),
        });
      },
      hashtag: "twitter",
      mention: "twitter",
    });
  }
}
