<div
  data-test="employee-advocacy-top-content-cards"
  class="base-table-widget employee-advocacy-top-content-cards relative flex flex-col text-light-base-600 dark:text-dark-base-600 bg-white dark:bg-dark-base-50 overflow-hidden border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md box-border min-h-[400px] max-h-[400px] h-[400px]">
  <!-- No Data Available -->
  <div
    *ngIf="!model?.Items?.length && !loadingResults && !loading"
    class="absolute text-[14px] inset-0 flex flex-col items-center justify-center h-[400px] text-light-base-400 dark:text-dark-base-400">
    No data to display
  </div>
  <!-- Loading -->
  <div
    *ngIf="loading"
    class="h-1 top-0 left-0 right-0 absolute overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25">
    <div
      class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
  </div>
  <div
    *ngIf="loading"
    class="absolute inset-0 flex flex-col items-center justify-center h-[400px] text-light-base-400 dark:text-dark-base-400">
    loading...
  </div>
  <!-- Paginator -->
  <mat-paginator #thePaginator [pageSize]="pageSize" [hidden]="loading" hidePageSize="true"></mat-paginator>
  <ng-container *ngIf="!loading">
    <div class="flex justify-between mx-3 mt-2">
      <!-- Title -->
      <div class="flex items-center">
        <div class="ml-4 text-sm font-normal text-light-base-600 dark:text-dark-base-400">{{ widgetName }}</div>
        <!-- Loading Data -->
        <div *ngIf="loading || loadingResults" class="ml-2">
          <app-loader class="loading-data"></app-loader>
        </div>
      </div>
      <div class="flex shrink-0">
        <!-- Has Groups -->
        <div class="flex mr-[4.5rem]">
          <app-employee-groups-filter
            [allGroups]="true"
            (selectedEmployees)="onFilterSelectedEmployeesGroup($event)"
            [showCounter]="false">
          </app-employee-groups-filter>
        </div>
        <!-- Menu -->
        <app-icon-button
          [matMenuTriggerFor]="menu"
          icon="menu-triple"
          iconSize="20"
          type="inline"
          title="Chart context menu"
          class="btn-menu -mt-1 -mr-1 cursor-not-allowed"></app-icon-button>
        <mat-menu #menu="matMenu" xPosition="before" class="selector-menu analytics-widget">
          <button (click)="exportToCsv()" mat-menu-item>Download CSV</button>
          <button (click)="editWidget()" mat-menu-item>Widget Settings</button>
          <button (click)="removeWidget()" mat-menu-item>Remove Widget</button>
        </mat-menu>
      </div>
    </div>
    <!-- Header -->
    <div
      *ngIf="!loading && model?.Items?.length"
      class="grid grid-cols-[60px_repeat(8,_1fr)] text-light-base-400 dark:text-dark-base-400 font-normal text-xs"></div>
    <!-- Post List -->
    <ng-scrollbar class="app-scrollbar flex mt-1 mx-3" visibility="always" track="horizontal">
      <a
        *ngFor="let post of model?.Items; let rank = index"
        [href]="post.ActivityPermalink"
        target="_blank"
        class="relative flex flex-col ml-2 first:ml-0 shrink-0 rounded border border-solid border-light-base-300 dark:border-dark-base-300 w-[200px] h-[296px] min-h-[296px] max-h-[296px] overflow-hidden hover:after:absolute hover:after:inset-0 hover:after:bg-light-base-200/10 dark:hover:after:bg-light-base-200/5 hover:after:content-['']">
        <app-activity
          class="graph-activity"
          [showCarouselControls]="false"
          [showCarouselIcon]="false"
          [showReactions]="false"
          [showVolumeControl]="false"
          [activity]="post.Activity"
          [showFooter]="false"
          [showBadge]="false"
          [showDomain]="true"
          [mediaQuality]="50"
          [mediaWidth]="400"
          [showDescription]="false">
        </app-activity>
        <div
          class="flex items-center mt-auto mx-1.5 mb-1.5 pt-1 text-xs text-light-base-400 dark:text-dark-base-400 border border-solid border-t !border-b-0 !border-r-0 !border-l-0 border-light-base-300 dark:border-dark-base-300">
          <div class="font-bold text-light-base-600 dark:text-dark-base-400">{{ post.TotalCount }}</div>
          <div class="ml-1 text-light-base-400 dark:text-dark-base-400">
            <span *ngIf="post.TotalCount === 1">Engagement</span>
            <span *ngIf="post.TotalCount > 1">Engagements</span>
          </div>
        </div>
      </a>
      <div class="snap-start shrink-0 scroll-mx-[12px]">
        <div class="w-0 h-[300px]"></div>
      </div>
    </ng-scrollbar>
  </ng-container>
</div>
