import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Coupon} from "./subscription.interface";
import {map, tap} from "rxjs/operators";
import {Subscription} from "./subscription";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private baseUrl = environment.api + "/Subscription";
  private subscription: Subscription;

  constructor(private httpClient: HttpClient) {}

  get(cache = true): Observable<Subscription> {
    if (cache && this.subscription) {
      return of(this.subscription);
    }

    return this.httpClient.post<Subscription>(this.baseUrl, {IncludeCurrentUsage: true}).pipe(
      map((subscription) => SubscriptionService.mapSubscription(subscription)),
      tap((subscription: Subscription) => {
        this.subscription = subscription;
      }),
    );
  }

  startTrial(planType: string, couponCode: string): Observable<Subscription> {
    return this.httpClient.post<Subscription>(this.baseUrl + "/trial/" + planType + "/" + couponCode, {}).pipe(
      map((subscription) => SubscriptionService.mapSubscription(subscription)),
      tap((subscription: Subscription) => {
        this.subscription = subscription;
      }),
    );
  }

  startPaid(planType: string, cardToken: string, couponCode: string): Observable<Subscription> {
    return this.httpClient
      .post<Subscription>(this.baseUrl + "/paid/" + planType + "/" + cardToken + "/" + couponCode, {})
      .pipe(
        map((subscription) => SubscriptionService.mapSubscription(subscription)),
        tap((subscription: Subscription) => {
          this.subscription = subscription;
        }),
      );
  }

  startFree() {
    return this.httpClient.post<Subscription>(this.baseUrl + "/free", {}).pipe(
      map((subscription) => SubscriptionService.mapSubscription(subscription)),
      tap((subscription: Subscription) => {
        this.subscription = subscription;
      }),
    );
  }

  validateCoupon(couponCode: string): Observable<Coupon> {
    return this.httpClient.post<Coupon>(this.baseUrl + "/validate-coupon", {
      couponCode,
    });
  }

  changePaymentMethod(cardToken: string): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + "/payment-method/" + cardToken, {}).pipe(
      map((subscription) => SubscriptionService.mapSubscription(subscription)),
      tap((subscription: Subscription) => {
        this.subscription = subscription;
      }),
    );
  }

  removePaymentMethod() {
    return this.httpClient.delete<any>(this.baseUrl + "/payment-method/" + this.subscription.CreditCardId).pipe(
      map((subscription) => SubscriptionService.mapSubscription(subscription)),
      tap((subscription: Subscription) => {
        this.subscription = subscription;
      }),
    );
  }

  getFutureInvoice(plan: string, coupon: string, users: number, channels: number, employees: number) {
    const invoiceRequest = {
      PlanType: plan,
      CouponCode: coupon,
      Users: users,
      Channels: channels,
      Advocates: employees,
    };

    return this.httpClient.post<any>(this.baseUrl + "/invoice/", invoiceRequest);
  }

  saveAddOnPlans(users, channels, advocates) {
    return this.httpClient.post<any>(this.baseUrl + "/addonplans", {
      Users: users,
      Channels: channels,
      Advocates: advocates,
    });
  }

  private static mapSubscription(response): Subscription {
    const sub = new Subscription(
      response.AmountDue,
      response.Currency,
      response.PeriodEnd,
      response.PeriodStart,
      response.SubscriptionId,
      response.PlanType,
      response.State,
      response.HasTrialed,
      response.CreditCardLast4,
      response.CreditCardType,
      response.ExpirationMonth,
      response.ExpirationYear,
      response.CreditCardId,
      response.Usage,
      response.AddOnSubscriptions,
      response.CancelAtPeriodEnd,
    );

    sub.MaxLicensedChannels = sub.getTotalQuantityByName(sub.Addon_Channel_Name);
    sub.MaxLicensedUsers = sub.getTotalQuantityByName(sub.Addon_User_Name);
    sub.MaxLicensedAdvocates = sub.getTotalQuantityByName(sub.Addon_Advocate_Name);

    sub.BaseLicensedChannels = sub.getBaseQuantityByName(sub.Addon_Channel_Name);
    sub.BaseLicensedUsers = sub.getBaseQuantityByName(sub.Addon_User_Name);
    sub.BaseLicensedAdvocates = sub.getBaseQuantityByName(sub.Addon_Advocate_Name);

    return sub;
  }

  clearCache() {
    this.subscription = null;
  }
}
