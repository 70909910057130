import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Source} from "@stream/stream.interface";
import {ProfileTypes} from "@channel/profile-types.enum";

@Injectable({
  providedIn: "root",
})
export class SuggestionsService {
  private baseUrl = environment.api + "/Suggestions";

  constructor(private http: HttpClient) {}

  getPublicProfiles(searchName: string, count = 10): Observable<Source[]> {
    return this.http.get<Source[]>(this.baseUrl + "/PublicProfile", {
      params: {searchName, count: count.toString()},
    });
  }

  getMentions(query: string, sourceType: number, subtype?: string, count = 10): Observable<Source[]> {
    if (sourceType == ProfileTypes.Facebook && !environment.production) return this.getPublicProfiles(query, count);

    return this.http.get<Source[]>(this.baseUrl + "/Sources", {
      params: {
        query,
        sourceType: sourceType.toString(),
        subtype,
        count: count.toString(),
      },
    });
  }
}
