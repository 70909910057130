import {ITokenBehaviour, TokenPreviewData} from "./ITokenBehaviour";

export class NewLineTokenBehaviour implements ITokenBehaviour {
  originalValue: string;

  getPreviewLength(): number {
    return 1;
  }

  getEditorLength(): number {
    return 1;
  }

  getEditorValue() {
    return "\n";
  }
  getPreviewHtmlValue() {
    return "\n"; //document.createElement("br").outerHTML;
  }
  getPreviewTextValue(): string {
    return "\n";
  }
}
