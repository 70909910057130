import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from "@angular/core";
import {Observable} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {IBaseTableDataModel, LeaderboardEmployeeModel} from "../../analytics.interface";
// for testing -
// import {MockLeaderboardAnalyticsService} from "@shared/analytics/analytics.service.mock";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {BaseTableComponent} from "../base-table/base-table.component";
import {UserWidget} from "../../widget.interface";

@UntilDestroy()
@Component({
  selector: "app-employee-leaderboard",
  templateUrl: "./employee-leaderboard.component.html",
  // for testing
  // providers: [{provide: LeaderboardAnalyticsService, useClass: MockLeaderboardAnalyticsService}],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeLeaderboardComponent
  extends BaseTableComponent<LeaderboardEmployeeModel>
  implements AfterViewInit
{
  fileName = "Employee_Engagement_Leaderboard";

  // for testing
  // constructor(private service: MockLeaderboardAnalyticsService) {}
  constructor(
    protected service: LeaderboardAnalyticsService,
    protected cdr: ChangeDetectorRef,
    protected timerangeListener: TimerangeListenerService,
    protected profilesListener: ProfileListenerService,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(service, timerangeListener, profilesListener, cdr, widgetConfig);
  }

  getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesIds: string[],
  ): Observable<IBaseTableDataModel<LeaderboardEmployeeModel>> {
    return this.service.getEmployeeLeaders(startDate, endDate, pageIndex, pageSize, groupsIds, profilesIds);
  }

  getCsvHeaders() {
    return {
      EmployeeId: "EmployeeInternalId",
      EmployeeExternalId: "EmployeeExternalId",
      EmployeeFirstName: "FirstName",
      EmployeeLastName: "LastName",
      EmployeePicture: "PictureUrl",
      LikeCount: "LikeCount",
      ShareCount: "ShareCount",
      CommentCount: "CommentCount",
      TotalCount: "TotalCount",
    };
  }

  EmployeeNameEx(emp: LeaderboardEmployeeModel) {
    return emp.EmployeeFirstName + " " + emp.EmployeeLastName;
  }
}
