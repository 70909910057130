Configuring Zapier for instagram Profile

<app-profile-picture
  *ngIf="processedProfile"
  [picture]="processedProfile.profile.Picture"
  [provider]="processedProfile.profile.Type"
  [hasChannel]="true"
  [showBadge]="true"
  [badgeSize]="8"
  [pictureSize]="24"
  class="mr-1.25"></app-profile-picture>

<iframe *ngIf="embeddedZapierUrl" [src]="embeddedZapierUrl" width="100%" height="600px"> </iframe>

<app-button (buttonClick)="OnSkipClick()" minWidth="116">Skip</app-button>
