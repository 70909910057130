import {Component, OnInit, Output, EventEmitter} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-text-filter",
  templateUrl: "./text-filter.component.html",
  styleUrls: ["./text-filter.component.scss"],
})
export class TextFilterComponent implements OnInit {
  @Output() OnTextFilterChanged = new EventEmitter<string>();

  text: string;
  submitted: boolean;

  ngOnInit(): void {}

  search() {
    this.OnTextFilterChanged.emit(this.text);
    this.submitted = true;
  }

  clearFilterWithoutRaisingEvent() {
    this.text = "";
    this.submitted = false;
  }

  clear() {
    this.clearFilterWithoutRaisingEvent();
    this.search();
    this.submitted = false;
  }
}
