import {Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {ComposerTeamsShareComponent} from "@composer/composer-teams-share/composer-teams-share.component";

@UntilDestroy()
@Component({
  selector: "app-teams-activity-footer",
  templateUrl: "./teams-activity-footer.component.html",
  styleUrls: ["./teams-activity-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamsActivityFooterComponent implements OnInit {
  @Input() ShowLike = true;

  @Input() ShowShare = true;

  @Input() ShowComment = true;

  @Input() Liked = false;

  @Input() Shared = false;

  @Input() Commented = false;

  @Output() LikeClicked = new EventEmitter<void>();

  @Output() CommentClicked = new EventEmitter<void>();

  @Output() CommentSubmit = new EventEmitter<string>();

  @Output() ShareClicked = new EventEmitter<void>();

  @Output() ShareSubmit = new EventEmitter<string>();

  @Input() ShareText: string;

  commentsOpen = false;

  commentText: string;

  sharesOpen = false;

  constructor(private composerTeamsShareOverlay: PanelOverlayService<ComposerTeamsShareComponent>) {}

  ngOnInit(): void {
    this.CommentClicked.pipe(
      untilDestroyed(this),
      tap(() => {
        this.commentsOpen = true;
      }),
    ).subscribe();

    this.CommentSubmit.pipe(
      untilDestroyed(this),
      tap(() => {
        this.commentsOpen = false;
      }),
    ).subscribe();

    this.ShareClicked.pipe(
      untilDestroyed(this),
      tap(() => {
        if (!this.Shared) {
          this.sharesOpen = true;
        } else {
          this.ShareSubmit.emit();
        }
      }),
    ).subscribe();

    this.ShareSubmit.pipe(
      untilDestroyed(this),
      tap(() => {
        this.sharesOpen = false;
        this.ShareText = "";
      }),
    ).subscribe();
  }

  openShareDialog() {
    const dialogRef = this.composerTeamsShareOverlay.open(ComposerTeamsShareComponent, {
      position: "left",
      data: this.ShareText,
    });

    dialogRef.componentInstance.shareTextUpdated.subscribe((updatedText: string) => {
      this.ShareText = updatedText;
      this.ShareSubmit.emit(this.ShareText);
    });
  }
}
