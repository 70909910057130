import {Directive, Input, OnInit} from "@angular/core";
import {environment} from "@environments/environment";

@Directive({
  selector: "img",
  host: {
    "[src]": "src",
  },
})
export class ImageCacheDirective implements OnInit {
  @Input() src: string;
  @Input() imageWidth = 0;
  @Input() imageHeight = 0;
  @Input() imageQuality = 0;

  whiteListedMediaUrlDomain: string[] = [];

  ngOnInit(): void {
    if (typeof this.src === undefined) {
      console.warn("media-cache.directive ngOnInit() called without a src, ignoring.");
      return;
    }

    if (this.src == undefined) return;
    if (this.src && this.src["changingThisBreaksApplicationSecurity"]) return;
    if (this.whiteListedMediaUrlDomain.some((x) => this.src.indexOf(x) >= 0)) return;
    if (this.src.includes("/assets/")) return;
    if (this.src.includes("assets/images")) return;
    if (this.src.indexOf(".gif") > 0) return;

    let sizeParameters = "";

    if (this.imageWidth > 0) {
      sizeParameters = this.addQueryParam(sizeParameters, "width", this.imageWidth);
    }

    if (this.imageHeight > 0) {
      sizeParameters = this.addQueryParam(sizeParameters, "height", this.imageHeight);
    }

    if (this.imageQuality > 0) {
      sizeParameters = this.addQueryParam(sizeParameters, "quality", this.imageQuality);
    }

    if (sizeParameters.length > 0) {
      sizeParameters = sizeParameters + "&";
    }

    this.src = environment.mediaCache + `?${sizeParameters}src=${encodeURIComponent(this.src)}`;
  }

  addQueryParam(query: string, key: string, value) {
    if (query.length == 0) {
      query = `${key}=${value}`;
    } else {
      if (!query.endsWith("&")) {
        query = query + "&";
      }

      query = query + `${key}=${value}`;
    }
    return query;
  }
}
