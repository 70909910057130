import {ChangeDetectionStrategy, Component, ElementRef, HostListener, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {ComposerService} from "@composer/composer.service";
import {ProfileService} from "@channel/profile.service";
import {ISelectedProfile} from "@composer/composer.component";
import {IProfile} from "@channel/profile.interface";
import {ChannelPermissionsEnum} from "@user/user.interface";
import {UserService} from "@user/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ComposerValidationService} from "@composer/composer-validation/composer-validation.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-composer-channel-selector",
  templateUrl: "./composer-channel-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerChannelSelectorComponent {
  @Input() hasError: boolean;
  inputControl = new FormControl("");
  filteredChannels$: Observable<IProfile[]>;
  isPanelOpen$: Observable<boolean>;
  isPanelOpen: boolean;
  private _noMatchSubject = new BehaviorSubject<boolean>(false);
  public noMatch$ = this._noMatchSubject.asObservable();

  iconMap: {[key: number]: string} = {
    1: "facebook-badge",
    2: "twitter-badge",
    6: "instagram-badge",
    8: "linkedin-badge",
  };

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: Event) {
    if (!this.isPartOfComponent(event.target)) {
      this.isPanelOpen = false;
    }
  }

  private isPartOfComponent(target: any): boolean {
    const componentElement = this.elementRef.nativeElement;
    const classesToCheck = ["composer-channel-selector-option", "channel-selector-list"];

    if (componentElement.contains(target)) {
      return true;
    }

    while (target) {
      if (target.classList && classesToCheck.some((className) => target.classList.contains(className))) {
        return true;
      }
      target = target.parentElement;
    }

    return false;
  }

  constructor(
    private profileService: ProfileService,
    private composerService: ComposerService,
    private elementRef: ElementRef,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public composerValidation: ComposerValidationService,
  ) {
    this.inputControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe();
  }

  ngOnInit() {
    this.filteredChannels$ = combineLatest([
      this.inputControl.valueChanges.pipe(startWith("")),
      this.profileService.allProfilesObservable.pipe(
        map((profiles) => profiles.filter((x) => x.HasAppInstalled == null || x.HasAppInstalled)),
      ),
      this.composerService.selectedChannels$,
    ]).pipe(
      map(([value, profiles, selectedProfiles]) => {
        const filteredProfiles = profiles.filter(
          (channel) =>
            !selectedProfiles.some((s) => s.profile.Id === channel.Id) &&
            (!value || typeof value !== "string" || channel.Name?.toLowerCase().includes(value?.toLowerCase())),
        );

        this._noMatchSubject.next(typeof value === "string" && value.trim() !== "" && filteredProfiles.length === 0);

        return filteredProfiles;
      }),
    );

    this.isPanelOpen$ = this.filteredChannels$.pipe(
      map((filteredProfiles) => filteredProfiles && filteredProfiles.length > 0),
    );
  }

  onOptionSelect(channel: IProfile): void {
    if (!this.userHasPermissionForChannel(channel)) {
      this.snackBar.open("You don't have permission to select this channel.");
      return;
    }

    this.inputControl.setValue(channel.Name, {emitEvent: false});

    const profile = this.profileService.allProfilesObservable.value.find((x) => x.Id === channel.Id);

    const selectedProfile: ISelectedProfile = {
      profile: profile,
      selected: false,
      linkedPublishAsProfilesFbGroup: [],
      publishAsProfileForFbGroups: null,
    };

    this.composerService.addSelectedChannel(selectedProfile);
    this.inputControl.patchValue("");
    this.isPanelOpen = true;
  }

  trackById(_index: number, channel: IProfile) {
    return channel.Id;
  }

  public get selectedProfiles() {
    return this.composerService.selectedProfiles.map((x) => x.profile);
  }

  remove(id: string) {
    const removeSelectedChannel = this.composerService.selectedProfiles.find((x) => x.profile.Id == id);
    this.composerService.removeSelectedChannel(removeSelectedChannel);
  }

  openPanel(): void {
    this.isPanelOpen = true;
  }

  clearSelectedChannels() {
    this.composerService.clearSelectedChannels();
    this.isPanelOpen = false;
  }

  userHasPermissionForChannel(profile: IProfile) {
    const user = this.userService.userObservable.value;

    if (user == null || user.ChannelsRoles == null) {
      return false;
    }
    const channelsUserHasPermission = user.ChannelsRoles?.filter((egr) =>
      egr.Permissions.includes(ChannelPermissionsEnum.Publish),
    ).map((x) => x.ChannelId);

    return channelsUserHasPermission.includes(profile.AssociatedSource?.Id);
  }

  closePanelEscape() {
    this.isPanelOpen = false;
  }

  onInputFocus(): void {
    this.isPanelOpen = true;
  }
}
