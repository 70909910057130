import {Component, Input, ViewChild} from "@angular/core";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
  selector: "app-base-pagination",
  template: `<p>base works!</p>`,
  styles: [],
})
export abstract class AbstractPaginatedComponent {
  @Input() pageIndex: number;
  @ViewChild("thePaginator", {static: false}) thePaginator: MatPaginator;
  pageSize = 10; // how many records to show on the widget
  readonly exportLimit = 50; // how many records to export

  protected constructor() {}

  protected updatePagination(paginator: MatPaginator, pageIndex: number, hasMoreRows: boolean, currentCount: number) {
    this.pageIndex = pageIndex;

    if (hasMoreRows) {
      paginator.length = Math.max((this.pageIndex + 1) * this.pageSize + 1, paginator.length);
    } else {
      paginator.length = Math.max(this.pageIndex * this.pageSize + currentCount, paginator.length);
    }
  }

  protected resetPaginatorIfDateRangeChanges(
    paginator: MatPaginator,
    currStart: Date,
    prevStart: string,
    currEnd: Date,
    prevEnd: string,
  ): void {
    if (
      currStart.toISOString().substring(0, 10) !== prevStart.substring(0, 10) ||
      currEnd.toISOString().substring(0, 10) !== prevEnd.substring(0, 10)
    ) {
      paginator.firstPage();
      paginator.length = 0;
    }
  }

  protected defaultPageEvent(): PageEvent {
    return {pageIndex: 0, length: 0, pageSize: 10, previousPageIndex: undefined};
  }
}

export default AbstractPaginatedComponent;
