import {Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef} from "@angular/core";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {BaseGraphComponent} from "../base-graph/base-graph.component";
import Highcharts from "highcharts";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import {UserWidget} from "../../widget.interface";

NoDataToDisplay(Highcharts);

@Component({
  selector: "app-employee-group-engagement-ratio-graph",
  templateUrl: "../base-graph/base-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupEngagementRatioGraphComponent extends BaseGraphComponent {
  elementId = "employeeGroupEngagementRatioGraph";

  options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      styledMode: true,
      type: "column",
    },
    title: {
      text: "Engagement Percentage by Group",
      align: "left",
    },
    xAxis: {
      categories: [],
    },
    yAxis: [
      {
        min: 0,
        max: 100,
        title: {
          text: "Engagement Percentage",
        },
      },
      {
        title: {
          text: "Employee count",
        },
        opposite: true,
      },
    ],
    legend: {
      shadow: true,
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // formatter: function () {
          //   let suffix = this.series.userOptions?.tooltip?.valueSuffix;
          //
          //   if (suffix == undefined) suffix = "";
          //
          //   return `${this.point.y}${suffix}`;
          // },
        },
      },
    },
    series: [
      {
        name: "Engagement Percentage",
        data: [],
        tooltip: {
          valueSuffix: " %",
        },
        pointPadding: 0.3,
        pointPlacement: -0.2,
        type: "column",
      },
      {
        name: "Employee Count",
        data: [],
        pointPadding: 0.3,
        pointPlacement: 0.2,
        yAxis: 1,
        type: "column",
      },
    ],
    exporting: {
      csv: {
        columnHeaderFormatter: function (item, key) {
          if (!key) {
            return "Group Name";
          }
          return false;
        },
      },
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "EmployeeGroupsEngagementRatio",
    },
    noData: {},
    lang: {
      noData: "No data to display",
    },
  };

  constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profileListener: ProfileListenerService,
    protected cdr: ChangeDetectorRef,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(analyticsService, timeRangeListener, profileListener, widgetConfig);
    this.setElementId();
  }

  updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void> {
    return this.analyticsService.getEmployeeGroupsEngagementRatioData(startDate, endDate, profileIds).pipe(
      tap((incomingData) => {
        const models = incomingData.filter((x) => this.widgetConfig.showNoGroup || x.GroupName != "No Group");
        const percentages = models.map((x) => x.Percentage);
        const employeeCounts = models.map((x) => x.Value);
        (this.options.xAxis as Highcharts.XAxisOptions).categories = models.map((x) => x.GroupName);
        (this.options.series[0] as Highcharts.SeriesColumnOptions).data = percentages;
        (this.options.series[1] as Highcharts.SeriesColumnOptions).data = employeeCounts;
      }),
      map(() => {}),
      finalize(() => {
        this.setLoadingState(false);
      }),
    );
  }

  setLoadingState(loading: boolean) {
    super.setLoadingState(loading);
    this.cdr.detectChanges();
  }
}
