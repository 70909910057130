import {Injectable} from "@angular/core";
import {AuthenticationService} from "@shared/auth/authentication.service";
import {AccountHub} from "./signalRHubs/accountHub";
import {StreamHub} from "./signalRHubs/streamHub";

@Injectable({
  providedIn: "root",
})
export class AsyncMessageService {
  public accountHub: AccountHub;
  constructor(private authenticationService: AuthenticationService) {
    this.accountHub = new AccountHub(this.authenticationService);

    this.accountHub.OnStopSupportSession.subscribe((token) => {
      if (this.authenticationService.currentUserValue.SupportSessionId == token) this.authenticationService.logout();
    });
  }

  buildStreamHub(): StreamHub {
    return new StreamHub(this.authenticationService);
  }

  init() {
    this.accountHub.start();
  }

  disconnect() {
    this.accountHub.dispose().then();
  }
}
