import {Comment} from "./comments/comment.model";
import {ActivitySource, Author, Reaction, UrlMetadata} from "./activity.interface";
import {Tweet} from "./twitter-activity/tweet.model";
import {FacebookPost} from "./facebook-activity/facebook-post.model";
import {InstagramMedia} from "./instagram-activity/instagram-media.model";
import {Reactable} from "./reactions/reactable.model";
import {SyndicationItem} from "./syndication-activity/syndication-item";
import {ContentMention, ContentModel, Link, MediaFileUploaded} from "@shared/publisher/content.interface";
import {Directive} from "@angular/core";
import {LinkedInPost} from "./linkedin-activity/linkedin-post.model";
import {IComposerToken} from "@shared/composer/composer-editor/token/token.interface";

@Directive()
export class Activity extends Reactable {
  constructor(
    public Tweet?: Tweet,
    public GooglePlusPost?: any,
    public YouTubeVideo?: any,
    public FacebookPost?: FacebookPost,
    public InstagramMedia?: InstagramMedia,
    public SyndicationItem?: SyndicationItem,
    public JoinFieldParentId?: any,
    public Type?: string,
    public AccountId?: string,
    public BrandId?: string,
    public CreatedTime?: string,
    public UpdatedTime?: any,
    public Engagement?: number,
    public SourceType?: "Facebook" | "TwitterAccount" | "InstagramAccount" | "LinkedIn",
    CommentsCount?: number,
    public Text?: string,
    public MD5Hash?: string,
    public Urls?: string[],
    public Language?: string,
    public Source?: ActivitySource,
    public Author?: Author,
    Comments: Comment[] = [],
    public StoredCommentsCount?: number,
    public Attachment?: any,
    public LikesCount?: number,
    public ParentActivityId?: any,
    public ParentCommentId?: any,
    public UrlMetadata: UrlMetadata[] = [],
    Reactions?: Reaction[],
    public Hidden?: boolean,
    public Anonymous?: boolean,
    public Id?: string,
    public LinkedInPost?: LinkedInPost,
    public Mentions?: ContentMention[],
    public TextLanguage?: string,
    public Links?: Link[],
    public tokens: IComposerToken[] = [],
  ) {
    super(Reactions, Comments, CommentsCount);
    if (Author && this.Tweet) {
      Author.DirectUrlToProfile = this.twitterUrl(Author);
    }
  }

  get provider(): string {
    return this.FacebookPost
      ? "facebook"
      : this.Tweet
        ? "twitter"
        : this.InstagramMedia
          ? "instagram"
          : this.LinkedInPost
            ? "linkedin"
            : null;
  }

  incrementLikeCount() {
    if (this.FacebookPost) {
      this.FacebookPost.ReactionCount++;
      this.FacebookPost.LikesCount++;
    }
    if (this.InstagramMedia) {
      this.InstagramMedia.LikesCount++;
    }
    if (this.YouTubeVideo) {
      this.YouTubeVideo.LikeCount++;
    }
    if (this.GooglePlusPost) {
      this.GooglePlusPost.PlusesCount++;
    }
    if (this.Tweet) {
      if (!this.Tweet.IsRetweeted) {
        this.Tweet.LikesCount++;
      } else if (this.Tweet.Original) {
        this.Tweet.Original.Tweet.LikesCount++;
      }
    }
    this.ReactionChanged.emit();
  }

  twitterUrl(author: Author) {
    return "https://twitter.com/" + author.ScreenName;
  }

  decrementLikeCount() {
    if (this.FacebookPost && this.FacebookPost.LikesCount > 0) {
      this.FacebookPost.LikesCount--;
    }
    if (this.FacebookPost && this.FacebookPost.ReactionCount > 0) {
      this.FacebookPost.ReactionCount--;
    }
    if (this.InstagramMedia && this.InstagramMedia.LikesCount > 0) {
      this.InstagramMedia.LikesCount--;
    }
    if (this.YouTubeVideo && this.YouTubeVideo.LikeCount > 0) {
      this.YouTubeVideo.LikeCount--;
    }
    if (this.GooglePlusPost && this.GooglePlusPost.PlusesCount > 0) {
      this.GooglePlusPost.PlusesCount--;
    }
    if (
      this.Tweet &&
      (this.Tweet.LikesCount > 0 || (this.Tweet.Original && this.Tweet.Original.Tweet.LikesCount > 0))
    ) {
      if (!this.Tweet.IsRetweeted) {
        this.Tweet.LikesCount--;
      } else {
        this.Tweet.Original.Tweet.LikesCount--;
      }
    }
    this.ReactionChanged.emit();
  }

  decrementShareCount() {
    if (this.FacebookPost && this.FacebookPost.SharesCount > 0) {
      this.FacebookPost.SharesCount--;
    }
    if (this.Tweet && this.Tweet.RetweetsCount > 0) {
      this.Tweet.RetweetsCount--;
      if (this.Tweet.Original && this.Tweet.Original.Tweet.RetweetsCount > 0) {
        this.Tweet.Original.Tweet.RetweetsCount--;
      }
    }
    this.ReactionChanged.emit();
  }

  incrementShareCount() {
    if (this.FacebookPost) {
      this.FacebookPost.SharesCount++;
    }
    if (this.Tweet) {
      this.Tweet.RetweetsCount++;
      if (this.Tweet.Original) {
        this.Tweet.Original.Tweet.RetweetsCount++;
      }
    }

    this.ReactionChanged.emit();
  }

  getMetaTagValue(name: string): string {
    const metadata = this.selectUrlMetadataSet();
    if (metadata) {
      const metaTags = metadata.MetaTags;
      const metaTag = metaTags
        ? metaTags.find((meta) => meta.Key === "twitter:" + name || meta.Key === "og:" + name)
        : null;
      return metaTag ? metaTag.Value : "";
    }
  }

  private selectUrlMetadataSet(): UrlMetadata {
    if (this.UrlMetadata && this.UrlMetadata.length) {
      if (this.SourceType == "TwitterAccount") {
        return this.UrlMetadata[this.UrlMetadata.length - 1];
      } else {
        return this.UrlMetadata[0];
      }
    }

    return null;
  }

  toContentModel(): ContentModel {
    const mediaFiles = [];

    if (this.FacebookPost?.Attachments) {
      if (this.FacebookPost.photosAttachment) {
        const photos = this.FacebookPost.photosAttachment.map<MediaFileUploaded>((x) => {
          return {
            BlobUrl: x.src,
            MediaType: "image/jpeg",
            preview: x.thumb,
            EnabledForFacebook: true,
            uploaded: true,
            BlobName: "Test",
            PreviewUrl: x.thumb,
          };
        });

        mediaFiles.push(...photos);
      }

      if (this.FacebookPost?.videoAttachment) {
        const video = this.FacebookPost.videoAttachment;
        mediaFiles.push({
          BlobUrl: video.source,
          MediaType: "video/mp4",
          preview: video.preview,
          EnabledForFacebook: true,
          uploaded: true,
          BlobName: "Test",
          PreviewUrl: video.preview,
        });
      }
    }

    return {
      FacebookText: this.Text,
      FacebookUrl:
        this.FacebookPost && this.FacebookPost.Link && this.FacebookPost.Link.Url
          ? this.FacebookPost.Link.Url
          : this.FacebookPost?.Permalink,
      MediaFiles: mediaFiles,
    };
  }

  toTwitterContentModel(): ContentModel {
    const mediaFiles = [];
    if (this.Tweet?.photosAttachment) {
      const photos = this.Tweet.photosAttachment.map<MediaFileUploaded>((x) => {
        return {
          BlobUrl: x.src,
          MediaType: "image/jpeg",
          preview: x.thumb,
          EnabledForTwitter: true,
          uploaded: true,
          BlobName: "Test",
          PreviewUrl: x.thumb,
        };
      });

      mediaFiles.push(...photos);
    }

    if (this.Tweet?.videoAttachment) {
      const video = this.Tweet.videoAttachment;
      mediaFiles.push({
        BlobUrl: video.source,
        MediaType: "video/mp4",
        preview: video.preview,
        EnabledForTwitter: true,
        uploaded: true,
        BlobName: "Test",
        PreviewUrl: video.preview,
      });
    }
    return {
      TwitterText: this.Text,
      FacebookUrl:
        this.FacebookPost && this.FacebookPost.Link && this.FacebookPost.Link.Url
          ? this.FacebookPost.Link.Url
          : this.Tweet?.Permalink,
      MediaFiles: mediaFiles,
    };
  }
}
