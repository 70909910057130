import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Router} from "@angular/router";
import {ComposerComponent} from "@shared/composer/composer.component";
import {NotificationGroup} from "@shared/notifications/notification.interface";
import {NotificationService} from "@shared/notifications/notification.service";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PanelOverlayService} from "@shared/overlay-panel/panel-overlay.service";
import {LibraryService} from "@shared/publisher/library.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PanelOverlay} from "@overlay-panel/panel-overlay";

@UntilDestroy()
@Component({
  selector: "app-base-notification-type",
  template: "",
})
export class BaseNotificationTypeComponent {
  @Input() notificationGroup: NotificationGroup;
  @Input() dialogRef: PanelOverlayRef<PanelOverlay>;
  @Output() delete: EventEmitter<any> = new EventEmitter();

  constructor(
    protected router: Router,
    protected notificationService: NotificationService,
    private composerOverlay: PanelOverlayService<ComposerComponent>,
    private libraryService: LibraryService,
  ) {}

  goToEvent() {
    this.notificationService
      .read(this.notificationGroup.NotificationGroupId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogRef.close();
        this.router.navigate(["/publisher/calendar"]);
      });
  }

  toggleRead() {
    if (this.notificationGroup.Pending) {
      this.notificationService
        .read(this.notificationGroup.NotificationGroupId)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationGroup.Pending = false;
        });
    } else {
      this.notificationService
        .unread(this.notificationGroup.NotificationGroupId)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationGroup.Pending = true;
        });
    }
  }

  deleteNotification() {
    this.notificationService
      .delete(this.notificationGroup.NotificationGroupId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.delete.emit();
      });
  }

  sendToComposer() {
    const messageId = this.notificationGroup.Notifications[0]?.PublishedEvents[0]?.MessageId;

    if (messageId == null) return;
    this.dialogRef.close();

    this.libraryService
      .getContent(messageId)
      .pipe(untilDestroyed(this))
      .subscribe((messageContent) => {
        this.composerOverlay
          .open(ComposerComponent, {
            position: "left",
            disableClose: true,
            parentOverlayRef: this.dialogRef,
            data: {
              content: messageContent,
              publishNow: true,
            },
          })
          .afterClosed.pipe(untilDestroyed(this))
          .subscribe();
      });
  }
}
