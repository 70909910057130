import {animate, state, style, transition, trigger} from "@angular/animations";
import {AfterViewInit, Component, Inject, Input, OnInit, Optional, ViewChild} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Channel, EmployeeInList} from "@shared/channel/profile.interface";
import {ProfileService} from "@shared/channel/profile.service";
import {PanelOverlay} from "@shared/overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@shared/overlay-panel/panel-overlay.tokens";
import {Observable} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {AuthenticationService} from "@auth/authentication.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IEmployeeGroup} from "src/app/pages/auth/employee/employee";
import {ShareToEmployeeControllerService} from "./share-to-employee-controller.service";
import {ComposerActionSchedule} from "@shared/publisher/content.interface";
import {momentToDate} from "@shared/utils/momentToDate.function";
import moment from "moment";
import {TimezoneInfo} from "@shared/utils/timezone/timezone.interface";
import {PublishSchedule} from "@shared/publisher/publish-schedule.interface";
import {TimezoneService} from "@shared/utils/timezone/timezone.service";
import {UserService} from "@shared/user/user.service";
import {ComposerValidationService} from "../composer-validation/composer-validation.service";
import {EmployeeGroupPermissionsEnum, GlobalPermissionEnum} from "@shared/user/user.interface";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {EmployeeService} from "@shared/employee/employee.service";
import {EmployeeStatusFilterEnum, FilterModeEnum, SortingEnum} from "@shared/utils/filter/filter.model";
import {Router} from "@angular/router";
import {EmployeeGroupService} from "@shared/employee-group/employee-group.service";

export interface ShareLinkedInOverlayData {
  sharing: boolean;
  like: boolean;
  employees: EmployeeInList[];
  shareText: string;
  shouldShowShareButton?: boolean;

  scheduleEnabled?: boolean;
  schedule?: ComposerActionSchedule;
  editingSchedule?: boolean;
  scheduleModel?: PublishSchedule;
  employeeGroupsConfig: EmployeeGroupConfig;
}

export interface EmployeeGroupConfig {
  EmployeeGroups: EmployeeGroupModel[];

  SelectionType: EmployeeGroupSelectionTypeEnum;
}

export interface EmployeeGroupModel {
  Id: string;
  Name: string;
}

export enum EmployeeGroupSelectionTypeEnum {
  None,
  Some,
  Everyone,
}

@UntilDestroy()
@Component({
  selector: "app-share-to-employee",
  templateUrl: "./share-to-employee.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-510px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-510px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class ShareToEmployeeComponent extends PanelOverlay implements OnInit, AfterViewInit {
  @Input() mainChannel: Channel;

  employees: EmployeeInList[] = [];
  groups: IEmployeeGroup[] = [];
  employeeGroupNames: string;
  shareText: string;
  addText: boolean;
  like: boolean;
  sharing: boolean;
  inviteUrl: string;
  groupSelectorValue = [];
  initialLoad = true;

  shareButton = false;
  loadingEmployees = false;

  isScheduled = false;

  schedule: ComposerActionSchedule = {
    time: "09:00 AM",
    startAt: momentToDate(moment().add(1, "days")),
  };

  today = this.dateConverter();
  scheduleEnabled: boolean;
  editingSchedule: boolean;
  timezoneEmpty: boolean;
  scheduleInThePast: boolean;
  scheduleIncomplete: boolean;

  pageSize = 25;
  currentPage = 0;
  totalEmployees = 0;
  end: boolean;
  selectedCount = 0;

  selectedEmployeeIds = new Set<string>();
  selectedGroupIds: string[] = [];
  selectedGroupNames: string[] = [];
  selectedEmployees: EmployeeInList[] = [];

  dataSource = new MatTableDataSource<EmployeeInList>();
  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  displayedColumns = ["thumbnail", "name", "group", "options"];

  constructor(
    private panelOverlayRef: PanelOverlayRef<ShareToEmployeeComponent>,
    private authenticationService: AuthenticationService,
    private snackbar: MatSnackBar,
    private shareToEmployeeController: ShareToEmployeeControllerService,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: ShareLinkedInOverlayData,
    private timezoneService: TimezoneService,
    private userService: UserService,
    private validationService: ComposerValidationService,
    private employeeService: EmployeeService,
    private router: Router,
    private employeeGroupService: EmployeeGroupService,
  ) {
    super(panelOverlayRef, data);
    this.shareButton = data.shouldShowShareButton;
    this.scheduleEnabled = data.scheduleEnabled;
    this.editingSchedule = data.editingSchedule;
    this.isScheduled = data.editingSchedule;

    if (data.editingSchedule) {
      this.schedule.startAt = new Date(data.scheduleModel.StartAt);
      this.schedule.time = data.scheduleModel.Times[0];

      this.timezoneService
        .getTimezoneByIanaId(data.scheduleModel.TimezoneId)
        .pipe(untilDestroyed(this))
        .subscribe((timezoneInfo: TimezoneInfo) => {
          this.schedule.timezoneId = timezoneInfo;
        });
    } else {
      this.userService
        .getSettings()
        .pipe(untilDestroyed(this))
        .subscribe((settings) => {
          this.schedule.timezoneId = settings.TimezoneInfo;
        });
    }

    shareToEmployeeController.onActivityUpdated.subscribe(() => {});
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
      this.paginator.page.pipe(tap(() => this.fetchEmployees())).subscribe();
    }
  }

  ngOnInit(): void {
    this.employeeGroupNames = "some";

    if (this.data != null) {
      this.like = this.data.like;
      this.sharing = this.data.sharing;
      this.addText = this.data.addText;
      this.shareText = this.data.shareText;

      if (this.data?.employeeGroupsConfig) {
        this.selectedGroupNames = this.data.employeeGroupsConfig?.EmployeeGroups.map((x) => x.Name);
        this.onGroupSelectionChange(this.selectedGroupNames);
      } else if (this.data?.employeeGroupNames) {
        this.selectedGroupNames = this.data.employeeGroupNames.split(";");
        this.onGroupSelectionChange(this.selectedGroupNames);
      }
    }

    this.employeeGroupService
      .getEmployeeGroups()
      .pipe(untilDestroyed(this))
      .subscribe((groups) => {
        this.groups = groups.map((group) => {
          return {
            ...group,
            UserHasPermissionToShare: this.userService.userObservable.value.EmployeeGroupRoles.some(
              (egr) =>
                egr.EmployeeGroupId == group.Id && egr.Permissions.includes(EmployeeGroupPermissionsEnum.ShareContent),
            ),
          };
        });

        if (this.data?.employeeGroupNames) {
          this.onGroupSelectionChange(this.selectedGroupNames);
        }
      });

    this.GetLinkedInInviteUrl()
      .pipe(untilDestroyed(this))
      .subscribe((url) => {
        this.inviteUrl = url;
      });

    this.fetchEmployees().then(() => {
      if (this.data?.includedEmployees) {
        const includedEmployees = this.data.includedEmployees.split(",");

        this.employees.forEach((employee) => {
          if (includedEmployees.includes(employee.Id)) {
            employee.Selected = true;
            this.selectedEmployeeIds.add(employee.Id);
          }
        });
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.fetchEmployees().then();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackById(index: number, employee: EmployeeInList) {
    return employee.Id;
  }

  fetchEmployees() {
    this.loadingEmployees = true;
    const originalEmployeeGroupNames = this.employeeGroupNames;

    return new Promise((resolve, reject) => {
      this.employeeService
        .getEmployees({
          PageIndex: this.currentPage,
          PageSize: this.pageSize,
          TextFilter: "",
          EmployeeStatusFilter: {
            FilterMode: FilterModeEnum.Selection,
            SelectionValues: [EmployeeStatusFilterEnum.Active],
          },
          EmployeeFieldSorting: {
            SortingField: "",
            Sorting: SortingEnum.NoneSelected,
          },
        })
        .pipe(
          finalize(() => {
            this.loadingEmployees = false;
            if (!this.initialLoad) {
              this.employeeGroupNames = originalEmployeeGroupNames;
            }
          }),
          untilDestroyed(this),
        )
        .subscribe((result) => {
          console.log({employees: result.Data});
          this.employees = result.Data;
          this.employees.forEach((employee) => {
            employee.Selected = this.selectedGroupIds.some((id) =>
              employee.EmployeeGroups?.some((group) => group.Id === id),
            );

            if (this.data?.includedEmployees) {
              const includedEmployees = this.data.includedEmployees.split(",");
              if (includedEmployees.includes(employee.Id)) {
                this.selectedEmployeeIds.add(employee.Id);
                employee.Selected = true;
                // Add to selectedEmployees if not already there
                if (!this.selectedEmployees.some((e) => e.Id === employee.Id)) {
                  this.selectedEmployees.push(employee);
                }
              }
            }
          });
          this.dataSource = new MatTableDataSource(this.employees);
          this.dataSource.paginator = this.paginator;
          this.totalEmployees = result.TotalCount;

          this.updateSelectedCount();

          if (this.data != null) {
            if (this.data.employeeGroupNames) {
              this.employees.forEach((employee) => {
                if (this.data.employeeGroupNames == "all") {
                  employee.Selected = true;
                } else if (this.data.employeeGroupNames == "none") {
                  employee.Selected = false;
                } else if (this.data.employeeGroupNames == "some") {
                  employee.Selected = this.data.includedEmployees?.split(",").includes(employee.Id);
                  if (employee.Selected) {
                    this.selectedEmployeeIds.add(employee.Id);
                  }
                } else {
                  this.groupSelectorValue.length = 0;
                  const groupNames = this.data.employeeGroupNames.split(";");
                  groupNames.forEach((item) => this.groupSelectorValue.push(item));
                  employee.Selected = employee.EmployeeGroups.some((x) =>
                    this.groupSelectorValue.some((g) => g == x.Name),
                  );
                  if (employee.Selected) {
                    this.selectedEmployeeIds.add(employee.Id);
                  }
                }
              });

              this.employeeGroupNames = this.data.employeeGroupNames;
            } else {
              this.employeeGroupNames = "all";
            }
          }

          if (!result.Data.length) {
            this.end = true;
          }

          this.initialLoad = false;
          resolve(result);
        }, reject);
    });
  }

  updateSelectedCount(): void {
    this.employeeGroupService
      .getEmployeeCountForGroup(this.selectedGroupIds)
      .pipe(untilDestroyed(this))
      .subscribe((count) => {
        this.selectedCount = count;
      });
  }

  allSelected() {
    return this.employees != null && !this.employees.some((e) => !e.Selected && this.userHasPermissionForEmployee(e));
  }

  selectAll() {
    if (this.employeeGroupNames !== "some") {
      this.snackbar.open("Select all is not possible, when quick selection is active.", null, {duration: 3000});
      return;
    }
    this.employeeGroupNames = "some";
    this.employees?.forEach((employee) => {
      if (employee && this.userHasPermissionForEmployee(employee)) {
        this.selectedEmployeeIds.add(employee.Id);
        if (!this.selectedEmployees.includes(employee)) {
          this.selectedEmployees.push(employee);
        }
        employee.Selected = true;
      }
    });
    this.groupSelectorValue = [];
    this.updateSelectedCount();
  }

  deselectAll() {
    this.selectedEmployeeIds.clear();
    this.selectedCount = 0;
    this.selectedGroupNames = [];
    this.selectedGroupIds = [];
    this.employeeGroupNames = "some";
    this.employees.forEach((employee) => (employee.Selected = false));
    this.updateSelectedCount();
  }

  onGroupSelectionChange(groupNames: string[]) {
    this.selectedEmployeeIds.clear();
    this.employeeGroupNames = groupNames.join(";");
    const selectedGroups = this.groups.filter((group) => groupNames.includes(group.Name));
    this.selectedGroupIds = selectedGroups.map((group) => group.Id);

    this.employees.forEach((e) => {
      const wasSelected = e.Selected;
      e.Selected = e.EmployeeGroups.some((x) => this.selectedGroupIds.includes(x.Id));
      if (e.Selected) {
        this.selectedEmployeeIds.add(e.Id);
      } else if (wasSelected) {
        this.selectedEmployeeIds.delete(e.Id);
      }
    });

    if (groupNames.length == 0) this.employeeGroupNames = "some";

    this.updateSelectedCount();
  }

  closeAndSave() {
    let selectedEmployees = [];

    if (this.employeeGroupNames === "some") {
      selectedEmployees = Array.from(this.selectedEmployeeIds).map((Id) =>
        this.selectedEmployees.find((employee) => employee.Id === Id),
      );
    }

    const obj: ShareLinkedInOverlayData = {
      like: this.like,
      employees: selectedEmployees,
      shareText: this.shareText,
      sharing: this.sharing,
      // employeeGroupNames: this.employeeGroupNames,
      employeeGroupsConfig: this.getEmployeeGroupConfig(),
      scheduleEnabled: this.isScheduled,
      schedule: this.isScheduled ? this.schedule : null,
      editingSchedule: true,
    };

    if (this.isScheduled) {
      const result = this.validationService.ValidateScheduleForShareDialog(this.schedule);

      this.scheduleInThePast = result.PostInThePast;
      this.timezoneEmpty = result.TimezoneEmpty;
      this.scheduleIncomplete = result.IncompleteScheduleDate;

      if (this.scheduleInThePast || this.timezoneEmpty || this.scheduleIncomplete) {
        this.scheduleError();
        return;
      }
    }

    this.panelOverlayRef.close(obj);
  }
  getEmployeeGroupConfig(): EmployeeGroupConfig {
    let employeeGroupSelectionType: EmployeeGroupSelectionTypeEnum;

    switch (this.employeeGroupNames) {
      case "all":
        employeeGroupSelectionType = EmployeeGroupSelectionTypeEnum.Everyone;
        break;
      case "none":
        employeeGroupSelectionType = EmployeeGroupSelectionTypeEnum.None;
        break;
      case "some":
        employeeGroupSelectionType = EmployeeGroupSelectionTypeEnum.None;
        break;
    }

    if (this.selectedGroupIds.length > 0) employeeGroupSelectionType = EmployeeGroupSelectionTypeEnum.Some;

    return {
      SelectionType: employeeGroupSelectionType,
      EmployeeGroups: this.selectedGroupIds.map((x) => {
        return {Id: x, Name: this.groups.find((t) => t.Id == x).Name};
      }),
    };
  }

  close() {
    this.panelOverlayRef.close();
  }

  GetLinkedInInviteUrl(): Observable<string> {
    return this.authenticationService.currentUser.pipe(untilDestroyed(this)).pipe(
      map((user) => {
        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        return `${baseUrl}/auth/employee/${user.AccountId}/connect`;
      }),
    );
  }

  copyToaster() {
    this.snackbar.open("Invite Link Copied.");
  }

  NameEx(emp: EmployeeInList) {
    return emp.FirstName + " " + emp.LastName;
  }

  OnTimezoneSelected(timezoneInfo: TimezoneInfo) {
    this.schedule.timezoneId = timezoneInfo;
  }

  onDatepickerChange() {}

  dateConverter() {
    const date = moment();
    return momentToDate(date);
  }

  scheduleError() {
    let scheduleError = document.getElementById("schedule-error");
    if (scheduleError !== null) {
      scheduleError.scrollIntoView();
      scheduleError = null;
    }
  }

  userHasPermissionForEmployee(employee: EmployeeInList) {
    const user = this.userService.userObservable.value;

    if (user.GlobalPermissions.includes(GlobalPermissionEnum.ManageEmployees)) return true;

    const groupsUserHasPermission = user.EmployeeGroupRoles.filter((egr) =>
      egr.Permissions.includes(EmployeeGroupPermissionsEnum.ShareContent),
    ).map((x) => x.EmployeeGroupId);

    return groupsUserHasPermission.some((item) => employee.EmployeeGroups.map((eg) => eg.Id).includes(item));
  }

  isSelected(employee: EmployeeInList): boolean {
    return (
      this.selectedEmployeeIds.has(employee.Id) ||
      this.selectedGroupIds.some((id) => employee.EmployeeGroups?.some((group) => group.Id === id))
    );
  }

  selectionCount(): number {
    return this.selectedCount > 0 ? this.selectedCount : this.selectedEmployeeIds.size;
  }

  toggleSelection(event: MatSlideToggleChange, employee: EmployeeInList): void {
    this.employeeGroupNames = "some";
    if (event.checked) {
      this.selectedEmployeeIds.add(employee.Id);
      if (!this.selectedEmployees.includes(employee)) {
        this.selectedEmployees.push(employee);
      }
      employee.Selected = true;
    } else {
      this.selectedEmployeeIds.delete(employee.Id);
      const employeeIndex = this.selectedEmployees.findIndex((e) => e.Id === employee.Id);
      if (employeeIndex !== -1) {
        this.selectedEmployees.splice(employeeIndex, 1);
      }
      const employeeGroupIds = employee.EmployeeGroups?.map((group) => group.Id);
      this.selectedGroupIds = this.selectedGroupIds.filter((id) => !employeeGroupIds.includes(id));
      this.updateSelectedGroupNames();
      employee.Selected = false;
    }
  }

  updateSelectedGroupNames(): void {
    this.selectedGroupNames = this.groups
      .filter((group) => this.selectedGroupIds.includes(group.Id))
      .map((group) => group.Name);
  }

  goToTimeZoneSettings() {
    this.close();
    this.router.navigate(["/user/timezone"]);
  }
}
