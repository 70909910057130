import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-alert-info",
  templateUrl: "./alert-info.component.html",
  styleUrls: ["./alert-info.component.scss"],
})
export class AlertInfoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
