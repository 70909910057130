import {BaseMediaValidation} from "./BaseMediaValidation";

export class TwitterMediaValidation extends BaseMediaValidation {
  PhotoSizeError: boolean;
  VideoSizeError: boolean;
  GifSizeError: boolean;

  VideoDurationError: boolean;

  constructor() {
    super();
  }

  IsValid(): boolean {
    return !this.PhotoSizeError && !this.VideoSizeError && !this.GifSizeError && !this.VideoDurationError;
  }
}
