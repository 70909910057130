import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {ITokenBehaviour, TokenPreviewData} from "./ITokenBehaviour";

export class MentionTokenBehaviour implements ITokenBehaviour {
  originalValue: string;
  profileType: ProfileTypes;

  constructor(
    public id: string,
    public displayName: string,
    public sourceType: string,
  ) {
    this.profileType = parseInt(sourceType);
  }

  getEditorLength(_previewData: TokenPreviewData, sourceType: string): number {
    const profileType = parseInt(sourceType);

    if (profileType == ProfileTypes.TwitterAccount) return this.displayName.length + 1;

    return this.displayName.length;
  }

  getPreviewLength(_previewData: TokenPreviewData, sourceType: string): number {
    const profileType = parseInt(sourceType);
    if (profileType == ProfileTypes.TwitterAccount) return this.displayName.length + 1;

    return this.displayName.length;
  }

  getEditorValue() {
    if (this.profileType == ProfileTypes.TwitterAccount) {
      return `@${this.displayName}`;
    }

    return this.displayName;
  }

  getPreviewTextValue(_previewData: TokenPreviewData, sourceType: string): string {
    const displayingProfileType = parseInt(sourceType);

    if (this.profileType != displayingProfileType) return this.displayName;

    let mentionDisplayName = this.displayName;
    if (this.profileType == ProfileTypes.TwitterAccount) mentionDisplayName = "@" + mentionDisplayName;
    return mentionDisplayName;
  }

  getPreviewHtmlValue(previewData: TokenPreviewData, sourceType: string) {
    const displayingProfileType = ProfileTypes[sourceType];

    if (this.profileType != displayingProfileType) return this.displayName;

    let mentionDisplayName = this.displayName;
    if (this.profileType == ProfileTypes.TwitterAccount) mentionDisplayName = "@" + mentionDisplayName;

    const html = this.getHtmlAnchor(
      this.getHrefDependingOnSourceType(sourceType, this.id, this.displayName),
      mentionDisplayName,
    );
    return html;
  }

  private getHtmlAnchor(href: string, label: string): string {
    return '<a href="' + href + '" target="_blank" data-test="shared-activitymention-a">' + label + "</a>";
  }

  private getHrefDependingOnSourceType(sourceType: ProfileTypes | string, externalId: string, displayName: string) {
    if (!externalId) return "";

    if (sourceType == ProfileTypes.Facebook || sourceType == "Facebook") {
      return "https://www.facebook.com/" + externalId;
    } else if (sourceType == ProfileTypes.TwitterAccount || sourceType == "TwitterAccount") {
      return "https://twitter.com/" + displayName;
    } else if (sourceType == ProfileTypes.LinkedIn || sourceType == "LinkedIn") {
      return "https://www.linkedin.com/company/" + externalId;
    }
  }
}
