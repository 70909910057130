import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Channel} from "@shared/channel/profile.interface";
import {PublishSchedule} from "@shared/publisher/publish-schedule.interface";

export interface PromptDialogOptions {
  title: string;
  subtitle: string;
  buttons: {
    cancel: string;
    confirm: string;
  };
  channel?: Channel;
  schedule?: PublishSchedule;
  warning?: boolean;
  warningMessage?: string;
}

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-prompt-dialog",
  templateUrl: "./prompt-dialog.component.html",
  styleUrls: ["./prompt-dialog.component.scss"],
})
export class PromptDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public options: PromptDialogOptions) {}

  ngOnInit() {}
}
