import {Component, Input, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges} from "@angular/core";
import {CommentService} from "../comment.service";
import {Comment} from "../comment.model";
import {Activity} from "../../activity.model";
import {CommentResponse} from "../comment-response.interface";
import {IProfileResponse} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-facebook-comments",
  templateUrl: "./facebook-comments.component.html",
  styleUrls: ["./facebook-comments.component.scss"],
})
export class FacebookCommentsComponent implements OnInit, OnChanges {
  @Input() comments: Comment[];
  @Input() activity: Activity;
  commentsPageSize = 10;
  defaultProfiles: IProfileResponse;
  hideLoadMore: boolean;

  constructor(
    private profileService: ProfileService,
    private commentService: CommentService,
    private cdr: ChangeDetectorRef,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments && changes.comments.currentValue) {
      this.comments = [...changes.comments.currentValue];
      this.cdr.markForCheck();
    }
  }

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });
  }

  loadMoreFacebookComments() {
    const count = this.activity.CommentsCount;
    const skip = this.activity.Comments.length;
    const pageSize = this.commentsPageSize;
    let take = count >= skip + pageSize ? pageSize : count - skip;
    if (take <= 0) {
      take = 1;
    }
    const profileId = this.defaultProfiles?.facebook?.ExternalId;
    if (!profileId) return;
    this.commentService
      .forActivity("facebook", this.activity.Id, profileId, skip, take)
      .pipe(untilDestroyed(this))
      .subscribe((data: CommentResponse) => {
        const newComments = data.ActivityComments;
        if (newComments.length > 0) {
          this.activity.appendMoreComments(newComments);
        } else {
          this.hideLoadMore = true;
        }
        this.cdr.markForCheck();
      });
  }
}
