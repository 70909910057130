import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "hashtag"})
export class HashtagPipe implements PipeTransform {
  constructor() {}

  transform(text: string, makeLink: boolean = false, baseUrl: string = ""): string {
    if (!text) {
      return text;
    }

    let transformedText = text;

    // Match hashtags (words starting with #)
    const hashtagRegex = /#(\w+)/g;
    transformedText = text.replace(hashtagRegex, (match, hashtag) => {
      // Convert hashtag to lowercase for the URL
      const lowerCaseHashtag = hashtag.toLowerCase();

      if (makeLink) {
        const url = `${baseUrl}/${encodeURIComponent(lowerCaseHashtag)}`;
        return `<a href="${url}" target="_blank" class="hashtag-link">${match}</a>`;
      }

      return `<span class="text-light-brand-100 dark:text-dark-brand-100">${match}</span>`;
    });

    return transformedText;
  }
}
