import {Component, Input, OnChanges, SimpleChanges, Output, EventEmitter} from "@angular/core";
import {Activity} from "@activity/activity.model";
import {FacebookPost} from "@activity/facebook-activity/facebook-post.model";
import {ActivitySource, UrlMetadata} from "@activity/activity.interface";
import {ISelectedProfile} from "../composer.component";
import {FbScrapeResponse} from "@utils/url/url-metadata.interface";
import moment from "moment-timezone";
import {EmployeeInList, IProfile, IProfileSelectionModel} from "@channel/profile.interface";
import {ProfileTypes} from "@channel/profile-types.enum";
import {Tweet} from "@activity/twitter-activity/tweet.model";
import {ContentMention, MediaFileUploaded} from "@publisher/content.interface";
import {InstagramMedia} from "@activity/instagram-activity/instagram-media.model";
import {ThemeService} from "@theme/theme.service";
import {ComposerService} from "../composer.service";
import {LinkedInPost} from "@shared/activity/linkedin-activity/linkedin-post.model";
import {LinkedInMedia} from "@shared/activity/linkedin-activity/linkedin-post.model";
import {facebookUrl, instagramUrl, twitterUrl} from "@shared/utils/sourcesLink.function";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getMentionsFromQuillText} from "@shared/utils/getMentionsFromQuillText.function";
import {MAT_SELECT_CONFIG} from "@angular/material/select";
import {IComposerToken} from "../composer-editor/token/token.interface";

@UntilDestroy()
@Component({
  selector: "app-composer-preview",
  templateUrl: "./composer-preview.component.html",
  styleUrls: ["./composer-preview.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: "composer-preview-channel-selector"},
    },
  ],
})
export class ComposerPreviewComponent implements OnChanges {
  @Input() facebookText: string;
  @Input() twitterText: string;
  @Input() instagramText: string;
  @Input() linkedInText: string;
  @Input() files: MediaFileUploaded[];
  @Input() selectedProfiles: ISelectedProfile[];
  @Input() sharing = false;
  @Input() sharingText: string = null;
  @Input() sharingSource: ActivitySource;
  @Input() loading: boolean;
  @Input() providerBeingEdited: number;
  @Input() employees: EmployeeInList[] = [];

  @Output() facebookScrapeDataEvent = new EventEmitter<FbScrapeResponse>();

  activityPreview: Activity;
  currentProfile: IProfileSelectionModel = {
    ParentProfile: null,
    Profile: null,
  };
  currentPublishProfile: IProfile;
  mobile: boolean;
  unknownGroupPhoto: string;

  iconMap: {[key: number]: string} = {
    1: "facebook-badge",
    2: "twitter-badge",
    6: "instagram-badge",
    8: "linkedin-badge",
  };

  constructor(
    public themeService: ThemeService,
    public composerService: ComposerService,
  ) {
    composerService.onUpdateComposerUi.pipe(untilDestroyed(this)).subscribe((mediaFiles) => {
      this.files = mediaFiles;
      this.initActivity();
    });

    composerService.onLinksMetadataModelChange.subscribe(() => {
      this.initActivity();
    });
    this.initActivity();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.employees && changes.employees.currentValue) {
      this.employees = changes.employees.currentValue;
    }

    if (changes.selectedProfiles && changes.selectedProfiles.currentValue) {
      if (changes.selectedProfiles.currentValue.length) {
        const currentProfileExists =
          this.currentProfile?.Profile &&
          this.selectedProfiles.find((profile) => profile.profile.Id === this.currentProfile?.Profile?.Id);
        if (!this.currentProfile?.Profile || !currentProfileExists) {
          this.selectedProfiles = changes.selectedProfiles.currentValue;

          this.setCurrentProfile(this.selectedProfiles[0].profile);

          this.currentPublishProfile = this.selectedProfiles[0].publishAsProfileForFbGroups;
          this.sharing = false;
          this.updateActivity();
        }
      } else {
        this.setCurrentProfile(null);
      }
    }

    if (changes.unknownGroupPhoto?.currentValue) {
      this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
        if (isDarkTheme) {
          this.unknownGroupPhoto = this.unknownGroupPhoto
            ? this.unknownGroupPhoto
            : "/assets/icons/unknown-user-dark.svg";
        } else {
          this.unknownGroupPhoto = this.unknownGroupPhoto
            ? this.unknownGroupPhoto
            : "/assets/icons/unknown-user-light.svg";
        }
      });
    }

    if (changes.providerBeingEdited && changes.providerBeingEdited.currentValue >= 0) {
      this.currentProfile = {
        Profile:
          this.selectedProfiles.filter((x) => x.profile.Type === changes.providerBeingEdited.currentValue)[0]
            ?.profile ?? this.selectedProfiles[0]?.profile,
      };

      this.providerBeingEdited = changes.providerBeingEdited.currentValue;
      if (!this.currentProfile?.Profile) {
        this.sharing = false;
      }

      this.initActivity();
    }

    if (changes.facebookText || changes.twitterText || changes.instagramText || changes.linkedInText) {
      this.updateActivity();
    }

    if (
      changes.sharingText &&
      changes.sharingText.currentValue &&
      changes.sharingText.currentValue !== changes.sharingText.previousValue
    ) {
      this.sharingText = changes.sharingText.currentValue;
    }

    if (
      changes.sharingSource &&
      changes.sharingSource.currentValue &&
      changes.sharingSource.currentValue !== changes.sharingSource.previousValue
    ) {
      this.sharingSource = changes.sharingSource.currentValue;
    }
  }

  setCurrentProfile(profile) {
    this.currentProfile = {...this.currentProfile, Profile: profile};
  }

  onProfileChange() {
    // console.log("composer preview onProfileChange is causing initActivity");
    this.initActivity();
  }

  private updateActivity() {
    const profile = this.getActivitySource();

    this.activityPreview.Text = this.getText(profile.Type);

    this.activityPreview.Mentions = this.getMentions(profile.Type);

    this.activityPreview.TextLanguage = this.composerService.getEditorLanguage(profile.Type);

    const editor = this.composerService.getEditorToModify(profile.Type);

    const metadata: UrlMetadata[] = [];
    let facebookMetadata: FbScrapeResponse;
    const links = editor.links;
    if (links && links.length > 0) {
      for (const link of links) {
        if (link.Metadata && link.Metadata.length > 0) {
          metadata.push(link.Metadata[0]);
        }
      }

      facebookMetadata = links[0].FacebookMetadata;
    }

    if (facebookMetadata != null) {
      this.activityPreview?.FacebookPost?.setLink(facebookMetadata);
    }

    // console.log("composer preview is updating an Activity", metadata);
    this.activityPreview.UrlMetadata = metadata;

    this.activityPreview.CreatedTime = moment().toISOString();

    this.activityPreview.Source = profile;

    this.activityPreview.SourceType = this.provider;

    const tokens: IComposerToken[] = this.getTokensToUseForPreview(profile);

    this.activityPreview.tokens = tokens;
  }

  private initActivity() {
    const profile = this.getActivitySource();

    const text = this.getText(profile.Type);

    const language = this.composerService.getEditorLanguage(profile.Type);

    const metadata: UrlMetadata[] = [];

    const editor = this.composerService.getEditorToModify(profile.Type);

    const links = editor.links;
    if (links && links.length > 0) {
      for (const link of links) {
        if (link.Metadata && link.Metadata.length > 0) {
          metadata.push(link.Metadata[0]);
        }
      }
    }

    const tokens: IComposerToken[] = this.getTokensToUseForPreview(profile);

    const mentions = this.getMentions(profile.Type);

    // console.log("composer preview is making an Activity",metadata, links);
    this.activityPreview = new Activity(
      this.buildTweet(profile.Type),
      null,
      null,
      this.buildFacebookPost(profile.Type),
      this.buildInstargamMedia(profile.Type),
      null,
      null,
      null,
      null,
      null,
      moment().toISOString(),
      null,
      null,
      this.provider,
      null,
      text,
      null,
      null,
      null,
      profile,
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      metadata,
      null,
      null,
      null,
      null,
      this.buildLinkedInPost(profile.Type),
      mentions,
      language,
      links,
      tokens,
    );
  }

  private getTokensToUseForPreview(profile: ActivitySource) {
    let tokensToReturn: IComposerToken[] = [];

    if (this.composerService.customizeMode) {
      const editor = this.composerService.getEditorToModify(profile.Type);

      tokensToReturn = editor.tokens;
    } else {
      const allEditor = this.composerService.getEditorToModify(ProfileTypes.Unknown);
      tokensToReturn = allEditor.tokens;
    }

    return tokensToReturn;
  }

  getActivitySource() {
    const fbMetadata = this.getFirstLinkFbMetadata(ProfileTypes.Facebook);

    if (
      this.currentProfile?.Profile?.Type == ProfileTypes.LinkedIn &&
      this.currentProfile?.Profile?.IsALinkedInEmployee
    ) {
      this.sharing = true;
      this.sharingSource = this.currentProfile?.ParentProfile as ActivitySource;
      this.sharingSource.Type = 8;
    } else if (fbMetadata != null) {
      this.sharing = false;
      this.sharingSource = null;
    }

    let profile = (
      this.sharing
        ? this.sharingSource
        : this.currentPublishProfile
          ? this.currentPublishProfile
          : this.currentProfile?.Profile
    ) as ActivitySource;

    if (!profile) {
      profile = {
        Id: "",
        Name: "Select a Channel",
        ScreenName: "",
        Picture: "/assets/icons/unknown-user-light.svg",
        Url: "",
        Type: ProfileTypes.Facebook,
        Subtype: "",
      };
    }
    switch (profile.Type) {
      case ProfileTypes.Facebook:
        profile.Url = profile.Url || facebookUrl(this.currentProfile.Profile?.ExternalId);
        break;
      case ProfileTypes.InstagramAccount:
        profile.Url = profile.Url || instagramUrl(profile.ScreenName);
        break;
      case ProfileTypes.TwitterAccount:
        profile.Url = profile.Url || twitterUrl(profile.ScreenName);
        break;
      case ProfileTypes.LinkedIn:
        profile.Url = profile.Url;
        break;
    }
    return profile;
  }

  get provider(): "Facebook" | "TwitterAccount" | "InstagramAccount" | "LinkedIn" {
    if (this.currentProfile?.Profile) {
      switch (this.currentProfile?.Profile?.Type) {
        case ProfileTypes.Facebook:
          return "Facebook";
        case ProfileTypes.TwitterAccount:
          return "TwitterAccount";
        case ProfileTypes.InstagramAccount:
          return "InstagramAccount";
        case ProfileTypes.LinkedIn:
          return "LinkedIn";
      }
    }
    return "Facebook";
  }

  private getText(type: number): string {
    let text = "";

    // console.log("Getting text for type: "+ type);
    switch (type) {
      case ProfileTypes.Facebook:
        text = getMentionsFromQuillText(this.facebookText, type).text;
        break;
      case ProfileTypes.TwitterAccount:
        text = getMentionsFromQuillText(this.twitterText, type).text;
        break;
      case ProfileTypes.InstagramAccount:
        text = getMentionsFromQuillText(this.instagramText, type).text;
        break;
      case ProfileTypes.LinkedIn:
        // console.log(this.linkedInText);
        text = getMentionsFromQuillText(this.linkedInText, type).text;
        // console.log({text});
        break;
    }
    return text ?? "";
  }

  private buildFacebookPost(type: number): FacebookPost {
    if (type !== ProfileTypes.Facebook) {
      return;
    }
    const facebookPost = new FacebookPost();
    const filesFacebook = this.files?.filter((x) => x.EnabledForFacebook);

    if (filesFacebook && filesFacebook.length) {
      const video = this.getVideo();
      if (video && video.EnabledForFacebook) {
        facebookPost.setVideoAttachment(video);
      } else {
        facebookPost.setPhotosAttachment(filesFacebook);
      }
    } else {
      facebookPost.Attachments = [];

      const facebookLinks = this.composerService.getEditorToModify(ProfileTypes.Facebook).links;
      if (facebookLinks && facebookLinks.length > 0) {
        facebookPost.setLink(facebookLinks[0].FacebookMetadata);
      }
    }
    return facebookPost;
  }

  private buildInstargamMedia(type: number): InstagramMedia {
    if (type !== ProfileTypes.InstagramAccount) {
      return;
    }

    const filesInstagram = this.files?.filter((x) => x.EnabledForInstagram);

    const instagramMedia = new InstagramMedia();
    if (filesInstagram && filesInstagram.length) {
      if (filesInstagram.length === 1) {
        const video = this.getVideo();
        if (video && video.EnabledForInstagram) {
          instagramMedia.setVideoAttachment(video);
        } else {
          instagramMedia.setPhotosAttachment(filesInstagram);
        }
      } else {
        instagramMedia.setCarouselItems(filesInstagram);
      }
    }
    return instagramMedia;
  }

  private buildLinkedInPost(type: number): LinkedInPost {
    if (type !== ProfileTypes.LinkedIn) {
      return;
    }

    const linkedInPost = new LinkedInPost();
    let linkedInFiles = [];
    if (this.files) {
      linkedInFiles = this.files?.filter((x) => x.EnabledForLinkedIn);
      linkedInPost.Media = linkedInFiles.map((f) => {
        const m = {
          OriginalUrl: f.BlobUrl,
          Thumbnails: {},
        } as LinkedInMedia;
        return m;
      });
    } else {
      linkedInPost.Media = [];
    }

    let metadata: UrlMetadata[] = [];

    const linkedInLinks = this.composerService.getEditorToModify(ProfileTypes.LinkedIn).links;

    if (linkedInLinks && linkedInLinks.length > 0) {
      metadata = linkedInLinks[0].Metadata;
    }

    if (linkedInFiles?.some((x) => x.MediaType.startsWith("video"))) {
      linkedInPost.ShareMediaCategory = "VIDEO";
    } else if (linkedInFiles?.some((x) => x.MediaType.startsWith("image"))) {
      linkedInPost.ShareMediaCategory = "IMAGE";
    } else if (metadata?.some((x) => x.MetaTags != null)) {
      linkedInPost.ShareMediaCategory = "ARTICLE";
      const attachLink = linkedInLinks[0];
      linkedInPost.Link = {
        Domain: attachLink.domain,
        Url: attachLink.BaseUrl,
        Caption: attachLink.title,
        Name: attachLink.title,
        Picture: attachLink.imageUrl,
        Description: attachLink.description,
        LowResPicture: attachLink.imageUrl,
      };
    } else {
      linkedInPost.ShareMediaCategory = null;
    }

    return linkedInPost;
  }

  private buildTweet(type: number): Tweet {
    if (type !== ProfileTypes.TwitterAccount) {
      return;
    }
    const filesTwitter = this.files?.filter((x) => x.EnabledForTwitter);
    const tweet = new Tweet();
    if (filesTwitter && filesTwitter.length) {
      const video = this.getVideo();
      if (video && video.EnabledForTwitter) {
        tweet.setVideoAttachment(video);
      } else {
        tweet.setPhotosAttachment(filesTwitter);
      }
    }
    let metadata: UrlMetadata[] = [];

    const twitterLinks = this.composerService.getEditorToModify(ProfileTypes.TwitterAccount).links;

    if (twitterLinks && twitterLinks.length > 0) {
      metadata = twitterLinks[0].Metadata;
    }

    if (metadata && metadata[0] && metadata[0].Tweet) {
      tweet.QuotedStatusId = metadata[0].Tweet.Tweet.Id;
      tweet.Original = metadata[0].Tweet;
    }
    return tweet;
  }

  private getVideo(): MediaFileUploaded {
    return this.files.find((file) => file.MediaType.startsWith("video"));
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.Profile?.Id === o2?.Profile?.Id;
  }

  getFirstLinkMetadata(type: number | ProfileTypes) {
    const editor = this.composerService.getEditorToModify(type);

    if (editor.links == null || editor.links.length == 0) return [];

    return editor.links[0].Metadata;
  }

  getFirstLinkFbMetadata(type: number | ProfileTypes) {
    const editor = this.composerService.getEditorToModify(type);

    if (editor.links == null || editor.links.length == 0) return null;

    return editor.links[0].FacebookMetadata;
  }

  private getMentions(sourceType: ProfileTypes): ContentMention[] {
    const mentions = this.composerService.getEditorToModify(sourceType).Mentions;
    if (mentions == null || mentions == undefined) return [];

    return mentions.filter((x) => x.SourceType == sourceType);
  }
}
