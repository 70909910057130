import {Link} from "@publisher/content.interface";
import {UrlMetadata} from "@activity/activity.interface";
import {FbScrapeResponse} from "@utils/url/url-metadata.interface";

import {injectorInstance} from "@shared/shared.module";
import {ComposerService} from "@shared/composer/composer.service";
import {ProfileLabelsFromId} from "@shared/channel/profile-types.enum";

export function FromContentLink(link: Link): Link {
  const localLink = new Link(link.BaseUrl);
  localLink.ProfileType = link.ProfileType;

  localLink.IsTracking = link.IsTracking;
  localLink.TrackingUrl = link.TrackingUrl;

  localLink.UtmCampaign = link.UtmCampaign;
  localLink.UtmContent = link.UtmContent;
  localLink.UtmMedium = link.UtmMedium;
  localLink.UtmSource = link.UtmSource;
  localLink.UtmTerm = link.UtmTerm;

  localLink.IsShorten = link.IsShorten;
  localLink.ShortenUrl = link.ShortenUrl;
  localLink.ShortenDomain = link.ShortenDomain;
  localLink.ShortenGroupId = link.ShortenGroupId;

  localLink.IsLinkOnText = link.IsLinkOnText;
  localLink.IsMetadataLoading = link.IsMetadataLoading;

  setLinkFacebookMetadata(localLink, link.FacebookMetadata);
  setLinkMetadata(localLink, link.Metadata);

  localLink.startPosition = link.startPosition;

  localLink.lastTrackingUrlState = link.lastTrackingUrlState;

  return localLink;
}

export function setLinkMetadata(link: Link, urlMetadata: UrlMetadata[]) {
  link.Metadata = urlMetadata;

  if (!urlMetadata) return;

  link.title = getMetaTagValue("title", urlMetadata);
  link.description = getMetaTagValue("description", urlMetadata);
  link.domain = getDomain(getMetaTagValue("url", urlMetadata));
  link.imageUrl = getMetaTagValue("image", urlMetadata);
  link.IsMetadataLoading = false;
}

export function setLinkFacebookMetadata(link: Link, linkMetadata: FbScrapeResponse) {
  link.FacebookMetadata = linkMetadata;

  if (!link.FacebookMetadata) return;

  link.title = link.FacebookMetadata.title;
  link.description = link.FacebookMetadata.description;
  link.domain = getDomain(link.FacebookMetadata.url);
  link.IsMetadataLoading = false;
  if (link.FacebookMetadata.image && link.FacebookMetadata.image.length > 0)
    link.imageUrl = link.FacebookMetadata.image[0].url;
}

export function getMetaTagValue(name: string, metadata: UrlMetadata[]): string {
  if (metadata && metadata[0]) {
    const metaTags = metadata[0].MetaTags;
    const metaTag = metaTags
      ? metaTags.find((meta) => meta.Key === "twitter:" + name || meta.Key === "og:" + name)
      : null;
    return metaTag ? metaTag.Value : "";
  }
}

export function getTrackingUrl(link: Link): string {
  let result = link.BaseUrl;

  const spaceRegex = new RegExp(" ", "g");
  const tracking = [];

  if (!link.originalOrderTrackingParams) link.originalOrderTrackingParams = [];

  link.originalOrderTrackingParams.forEach((trackingParam) => {
    if (link.UtmCampaign && trackingParam == "utm_campaign") {
      tracking.push("utm_campaign=" + resolveDynParameter(link.UtmCampaign.replace(spaceRegex, "+")));
    }
    if (link.UtmSource && trackingParam == "utm_source") {
      tracking.push("utm_source=" + resolveDynParameter(link.UtmSource.replace(spaceRegex, "+")));
    }
    if (link.UtmMedium && trackingParam == "utm_medium") {
      tracking.push("utm_medium=" + resolveDynParameter(link.UtmMedium.replace(spaceRegex, "+")));
    }
    if (link.UtmTerm && trackingParam == "utm_term") {
      tracking.push("utm_term=" + resolveDynParameter(link.UtmTerm.replace(spaceRegex, "+")));
    }
    if (link.UtmContent && trackingParam == "utm_content") {
      tracking.push("utm_content=" + resolveDynParameter(link.UtmContent.replace(spaceRegex, "+")));
    }
  });

  const missingUtmParameters = ["utm_campaign", "utm_source", "utm_medium", "utm_term", "utm_content"].filter(
    (key) => !link.originalOrderTrackingParams.some((original) => original == key),
  );

  missingUtmParameters.forEach((trackingParam) => {
    if (link.UtmCampaign && trackingParam == "utm_campaign") {
      tracking.push("utm_campaign=" + resolveDynParameter(link.UtmCampaign.replace(spaceRegex, "+")));
    }
    if (link.UtmSource && trackingParam == "utm_source") {
      tracking.push("utm_source=" + resolveDynParameter(link.UtmSource.replace(spaceRegex, "+")));
    }
    if (link.UtmMedium && trackingParam == "utm_medium") {
      tracking.push("utm_medium=" + resolveDynParameter(link.UtmMedium.replace(spaceRegex, "+")));
    }
    if (link.UtmTerm && trackingParam == "utm_term") {
      tracking.push("utm_term=" + resolveDynParameter(link.UtmTerm.replace(spaceRegex, "+")));
    }
    if (link.UtmContent && trackingParam == "utm_content") {
      tracking.push("utm_content=" + resolveDynParameter(link.UtmContent.replace(spaceRegex, "+")));
    }
  });

  let searchParams = "";
  if (link.BaseUrl.length > 0) {
    const linkUrlData = new URL(result);
    searchParams = linkUrlData.search.replace("?", "");
  }

  if (tracking.length) {
    if (searchParams.length > 0) {
      result += "&" + tracking.join("&");
    } else {
      result += "?" + tracking.join("&");
    }
  }

  return result;
}

export function resolveDynParameter(parameterValue: string) {
  const composerService = injectorInstance?.get<ComposerService>(ComposerService, null, {optional: true});

  if (composerService && parameterValue == "{channelType}" && composerService.selectedProfiles.length > 0) {
    return ProfileLabelsFromId[composerService.selectedProfiles[0].profile.Type];
  }
  return parameterValue;
}

export function getDisplayUrl(link: Link): string {
  if (link.IsShorten) return link.ShortenUrl;
  return getTrackingUrl(link);
}

export function htmlEncode(text: string) {
  const regex = new RegExp("&", "gi");

  text = text.replace(regex, "&amp;");
  return text;
}

export function getDomain(url: string): string {
  if (!url) return "";
  const regex = /:\/\/(.[^/:]+)/;
  if (url.match(regex)) {
    return url.match(regex)[1].replace("www.", "");
  }
}
export function hasDynamicParameters(link: Link) {
  return true;
}

//This function only encodes spaces
export function encodeUrl(url: string): string {
  const regex = new RegExp(" ", "gi");
  return url.replace(regex, "%20");
}
