import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {Observable, concat} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {guid} from "../guid.function";

type TUploadStatus = "selected" | "uploading" | "uploaded" | "removed" | "canceled" | "error";

export interface UploadEvent {
  status: TUploadStatus;
  files?: UploadFile[];
  response?: any;
  progress?: number;
  error?: string;
  index?: number;
}

export interface UploadFile {
  file: File;
  preview?: SafeResourceUrl;
  progress?: boolean;
  url?: string;
  id?: string;
}

@Directive({
  selector: "[appFileUpload]",
})
export class FileUploadDirective implements OnChanges {
  @Output() public events: EventEmitter<UploadEvent> = new EventEmitter();
  @Input() public files: UploadFile[];
  @Input() public autoUpload = true;
  @Input() uploadHandler: (file: UploadFile) => Observable<any>;

  public status: TUploadStatus;

  constructor(
    private sanitizer: DomSanitizer,
    private elRef: ElementRef,
  ) {}

  @HostListener("change", ["$event.target.files"])
  public onSelect(files: FileList): void {
    this.files = Array.from(files).map((file: File) => {
      return {
        file,
        preview: file.type.startsWith("image")
          ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
          : null,
        id: guid(),
      };
    });
    this.elRef.nativeElement.value = "";
    this.onFileSelect();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.files && changes.files.currentValue) {
      this.onFileSelect();
    }
  }

  private onFileSelect(): void {
    this.status = "selected";
    this.events.emit({
      status: this.status,
      files: this.files,
    });
    if (this.autoUpload) {
      this.upload();
    }
  }

  public upload(): void {
    const observables = this.files.map((file) => {
      return this.uploadHandler(file);
    });
    this.setProgress();
    concat(...observables).subscribe(
      (response: any) => {
        this.onUploadCompleted(response);
      },
      (response) => this.onError(response),
    );
  }

  private setProgress(): void {
    this.status = "uploading";
    this.events.emit({
      status: this.status,
    });
  }

  private onUploadCompleted(response: any): void {
    this.status = "uploaded";
    this.events.emit({
      status: this.status,
      response,
    });
  }

  private onError(response: any): void {
    this.status = "error";
    this.events.emit({
      status: this.status,
      error: response.error,
    });
  }
}
