import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Content} from "@publisher/content.interface";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {ComposerComponent} from "@composer/composer.component";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {ProfileLabels} from "@channel/profile-types.enum";
import {LibraryService} from "@publisher/library.service";
import {GroupedPublishedContent} from "@shared/notifications/notification.interface";
import {IProfile} from "@shared/channel/profile.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ShareToEmployeeControllerService} from "@shared/composer/share-to-emplyee/share-to-employee-controller.service";
import {ShareLinkedInOverlayData} from "@shared/composer/share-to-emplyee/share-to-employee.component";
import {ReactionService} from "@shared/activity/reactions/reaction.service";
import {QueueService} from "@shared/publisher/queue.service";
import {forkJoin} from "rxjs";

export interface ActivityOverlayData {
  content: Content;
}

@UntilDestroy()
@Component({
  selector: "app-preview-dialog",
  templateUrl: "./preview-dialog.component.html",
  styleUrls: ["./preview-dialog.component.scss"],
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewDialogComponent extends PanelOverlay implements OnInit {
  content: Content;
  edited: boolean;
  deleting: boolean;
  publishEnabled = true;
  editingEnabled = true;
  rescheduleEnabled: boolean;
  isAPublishedEvent = false;
  activeTab: number;

  errors: {
    Facebook?: PreviewDialogError[];
    TwitterAccount?: PreviewDialogError[];
    InstagramAccount?: PreviewDialogError[];
    LinkedIn?: PreviewDialogError[];
  } = {};

  constructor(
    dialogRef: PanelOverlayRef<PreviewDialogComponent>,
    private composerOverlay: PanelOverlayService<ComposerComponent>,
    private libraryService: LibraryService,
    private cdr: ChangeDetectorRef,
    @Inject(PANEL_OVERLAY_DATA) data: ActivityOverlayData,
    private shareToEmployeeController: ShareToEmployeeControllerService,
    private reactionService: ReactionService,
    private queueService: QueueService,
  ) {
    super(dialogRef, data);
    if (this.data) {
      this.content = this.data.content;

      const groupContent = this.content as unknown as GroupedPublishedContent;

      if (this.content.Profiles.length < 1 && this.content.PublishAt) {
        this.content.ErrorMessage =
          "This content has no channel assigned. The assigned channel was removed after the content was scheduled for publishing.";
        this.publishEnabled = false;
      }

      if (groupContent.PublishEvents) {
        this.isAPublishedEvent = true;
        this.rescheduleEnabled = groupContent.PublishEvents.some((x) => x.ErrorMessage);
        this.errors = this.getErrorMessages();
      }

      if (this.content.IsDemo) {
        this.editingEnabled = false;
        this.publishEnabled = false;
        this.rescheduleEnabled = false;
      }

      if (this.content.IsAScheduledShare) {
        this.editingEnabled = true;
        this.publishEnabled = false;
        this.rescheduleEnabled = false;
      }
    }
  }

  ngOnInit() {
    this.activeTab = 0;
  }

  close() {
    this.dialogRef.close(this.edited);
  }

  editActivity() {
    if (this.content.IsAScheduledShare) {
      this.shareToEmployeeController
        .editScheduledShare(this.content, this.dialogRef as PanelOverlayRef<PreviewDialogComponent>)
        .subscribe((shareModel: ShareLinkedInOverlayData) => {
          this.edited = true;
          this.content = {
            ...this.content,
            Employees: shareModel.employees,
            // EmployeeGroupNames: shareModel.employeeGroupNames,
            EmployeeGroupsConfig: shareModel.employeeGroupsConfig,
            LikeOnBehalfOfEmployees: shareModel.like,
            ShareOnBehalfOfEmployees: shareModel.sharing,
            IncludedEmployees: shareModel.employees
              ? shareModel.employees
                  .filter((x) => x.Selected)
                  .map((x) => x.Id)
                  .reduce((a, b) => `${a},${b}`, "")
              : "",
            ScheduleModel: shareModel.scheduleModel,
          };
          this.cdr.markForCheck();
        });
    } else {
      this.composerOverlay
        .open(ComposerComponent, {
          position: "left",
          disableClose: true,
          parentOverlayRef: this.dialogRef,
          data: {
            content: this.content,
          },
        })
        .afterClosed.pipe(untilDestroyed(this))
        .subscribe((content: any) => {
          //The composer component can return sometimes a Content and sometimes a boolean or null.
          //boolean: When a publish now, schedule a one time or add to library is done, it will return a true;
          //null: when the preview was closed with cancel or close button
          //Content when content is edited successfully for the content library.
          if (content && content.MessageType) {
            this.content = content;
            this.edited = true;
            this.publishEnabled = this.content.Profiles && this.content.Profiles.length > 0 && !this.content.IsDemo;
          } else if (content === true) {
            this.close();
          }
          this.cdr.markForCheck();
        });
    }
  }

  publishActivity() {
    this.composerOverlay
      .open(ComposerComponent, {
        position: "left",
        disableClose: true,
        parentOverlayRef: this.dialogRef,
        data: {
          content: this.content,
          publishNow: true,
        },
      })
      .afterClosed.pipe(untilDestroyed(this))
      .subscribe((content: Content) => {
        if (content) {
          this.edited = true;
          this.close();
        }
      });
  }

  deleteActivity() {
    this.deleting = true;

    if (this.content.IsAScheduledShare && !this.isAPublishedEvent) {
      this.reactionService.deleteScheduleShareWithEmployees(this.content.Id).subscribe({
        next: () => {
          this.edited = true;
          this.close();
        },
        complete: () => (this.deleting = false),
      });
    } else if (this.content.IsAScheduledShare && this.isAPublishedEvent) {
      const groupContent = this.content as unknown as GroupedPublishedContent;

      const tasks = [];

      for (const publishEvent of groupContent.PublishEvents) {
        tasks.push(this.queueService.hidePublishEvent(publishEvent.Id));
      }

      forkJoin(tasks).subscribe({
        next: () => {
          this.edited = true;
          this.close();
        },
        complete: () => (this.deleting = false),
      });
    } else {
      this.libraryService
        .delete(this.content.Id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.edited = true;
            this.close();
          },
          complete: () => (this.deleting = false),
        });
    }
  }

  rescheduleActivity() {
    this.editActivity();
  }

  getProviderLabel(provider: string): string {
    return ProfileLabels[provider].label;
  }

  getErrorMessages(): any {
    const object: any = {};

    const groupedPublishedContent = this.content as unknown as GroupedPublishedContent;

    if (
      groupedPublishedContent &&
      groupedPublishedContent.PublishEvents &&
      groupedPublishedContent.PublishEvents.length > 0
    ) {
      const profileTypes = groupedPublishedContent.PublishEvents.filter((x) => x.ErrorMessage).map((x) => x.Type);

      for (const type of profileTypes) {
        object[type] = groupedPublishedContent.PublishEvents.filter((x) => x.ErrorMessage && x.Type == type).map(
          (x) => {
            return {profile: x.Profile, error: x.ErrorMessage};
          },
        );
      }
    }

    if (this.content.ErrorMessage) {
      //This error only happens if a post is scheduled for a certain date in the future and the user removes the profiles from the post.
      //Since the post is in the future its not a published event but we still mark as it has an error and show it as a if it was a published event.
      object["Unknown"] = this.content.ErrorMessage;
    }
    return object;
  }

  providerHasErrors(providerName: string): boolean {
    return this.errors[providerName]?.length > 0;
  }

  getErrorsByProvider(providerName: string): PreviewDialogError[] {
    return this.errors[providerName];
  }
}

export interface PreviewDialogError {
  profile: IProfile;
  error: string;
}
