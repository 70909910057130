<div *ngFor="let team of AccountTeams">
  <h4 class="team-title">{{ team.TeamName }}</h4>
  <div class="usersContainer">
    <div class="userContainer" *ngFor="let user of team.Users" (click)="UserClicked(user)">
      <img
        class="profile-picture"
        src="{{ user.Image ? user.Image : '/assets/icons/unknown-user-light.svg' }}"
        alt="" />
      <p class="userText">{{ user.FirstName }} {{ user.LastName }}</p>
    </div>
  </div>
</div>
