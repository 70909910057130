import {Component, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AuthenticationService} from "@auth/authentication.service";
import {AddChannelDialogComponent} from "@channel/add-channel-dialog/add-channel-dialog.component";
import {ProfileService} from "@channel/profile.service";
import {ComposerEmptyComponent} from "@composer/composer-empty/composer-empty.component";
import {ComposerComponent} from "@composer/composer.component";
import {environment} from "@environments/environment";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NotificationPanelComponent} from "@notifications/notification-panel/notification-panel.component";
import {NotificationService} from "@notifications/notification.service";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {SubscriptionService} from "@shared/subscription/subscription.service";
import {UpgradeSubscriptionService} from "@shared/subscription/upgrade-subscription/upgrade-subscription.service";
import {TeamService} from "@shared/user/team.service";
import {AnalyticsService} from "@shared/utils/analytics-service/analytics.service";
import {ThemeService} from "@theme/theme.service";
import {Theme, User} from "@user/user.interface";
import {UserService} from "@user/user.service";
import {Subject} from "rxjs";
import {exhaustMap} from "rxjs/operators";
import {IChatBox} from "@utils/chat/chatbox.interface";

@UntilDestroy()
@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  user: User;
  isDark = false;
  isProd = environment.production;
  isAdmin: boolean;

  addChannel$ = new Subject();

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    private panelOverlay: PanelOverlayService<NotificationPanelComponent>,
    private composerOverlay: PanelOverlayService<ComposerComponent>,
    private composerEmptyOverlay: PanelOverlayService<ComposerEmptyComponent>,
    public notificationService: NotificationService,
    private dialog: MatDialog,
    public themeService: ThemeService,
    private router: Router,
    private profileService: ProfileService,
    private subscriptionService: SubscriptionService,
    private upgradeDialogService: UpgradeSubscriptionService,
    private analyticsService: AnalyticsService,
    private teamService: TeamService,
    public chatbox: IChatBox,
  ) {
    userService
      .me()
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.isAdmin = user.IsAdmin;
      });

    this.teamService.getAllUsers().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnInit() {
    this.registerEvents();

    this.userService
      .me()
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
        this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDark) => {
          this.isDark = isDark;
        });
        this.toggleDarkTheme(user.Theme === Theme.Dark);
      });

    this.userService.userObservable.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
  }

  toggleTheme() {
    this.isDark = !this.isDark;
    this.toggleDarkTheme(this.isDark);
  }

  toggleDarkTheme(checked: boolean) {
    const theme = checked ? Theme.Dark : Theme.Light;

    this.themeService.setTheme(theme);

    this.userService
      .updateTheme(theme)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.authenticationService.updateTheme(theme);
      });
  }

  logout() {
    this.analyticsService.track("Logout");
    this.analyticsService.reset();
    this.authenticationService.logout();
  }

  openNotifications() {
    this.panelOverlay.open(NotificationPanelComponent, {
      position: "left",
    });
  }

  openComposer() {
    this.profileService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x.length > 0) {
          this.composerOverlay
            .open(ComposerComponent, {
              position: "left",
              disableClose: true,
            })
            .afterClosed.pipe(untilDestroyed(this))
            .subscribe();
        } else {
          this.composerEmptyOverlay.open(ComposerEmptyComponent, {
            position: "left",
            disableClose: true,
          });
        }
      });
  }

  get notificationsCount(): string {
    if (this.notificationService.count > 0) {
      if (this.notificationService.count > 9) {
        return "9+";
      }
      return this.notificationService.count.toString();
    }
  }

  public hasGroupRole(roleName: string) {
    return this.authenticationService.hasGroupRole(roleName);
  }

  registerEvents() {
    this.addChannel$.pipe(exhaustMap(() => this.subscriptionService.get())).subscribe((x) => {
      const channelCountLimit = x.getTotalQuantityByName("Channel");
      if (channelCountLimit > -1 && x.Usage.BilledChannelsCount >= channelCountLimit) {
        this.upgradeDialogService.showUpgradeSubscriptionDialog().pipe(untilDestroyed(this)).subscribe();
      } else {
        this.dialog.open(AddChannelDialogComponent, {
          maxWidth: "804px",
          maxHeight: "90vh",
          panelClass: ["app-dialog", "add-channel"],
          autoFocus: false,
        });
      }
    });
  }

  liveChat() {
    this.chatbox.requestWithText("");
  }
}
