<!-- Saved Card -->
<div *ngIf="!newCreditCard && !alwaysShowForm">
  <div class="flex items-center">
    <app-icon [icon]="subscription.CreditCardType.toLowerCase()" class="w-5 h-5 mr-1"></app-icon>
    {{ subscription.CreditCardType || "Card" }} ****
    {{ subscription.CreditCardLast4 }}
  </div>
</div>
<!-- New or Update Card Form -->
<div [hidden]="!newCreditCard && !alwaysShowForm" class="flex">
  <mat-form-field class="flex-1 flex fs-exclude card-input" appearance="outline">
    <mat-label>Credit Card</mat-label>
    <app-wm-stripe-card #stripeForm appMatStripe name="card" class="block w-full"></app-wm-stripe-card>
    <mat-error *ngIf="stripeForm.error" data-test="credit-card-error-message">{{ stripeForm.error.message }}</mat-error>
    <mat-hint align="end" *ngIf="newCreditCard && subscription.CreditCardLast4">
      <a *ngIf="enableBackOption" (click)="backToCards()">Back</a>
    </mat-hint>
  </mat-form-field>
</div>
