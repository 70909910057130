import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import {MatSelectChange} from "@angular/material/select";
import {EmployeeGroupService} from "@shared/employee-group/employee-group.service";
import {IEmployeeGroup} from "src/app/pages/auth/employee/employee";

@Component({
  selector: "app-employee-groups-filter",
  templateUrl: "./employee-groups-filter.component.html",
  styleUrls: ["./employee-groups-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeGroupsFilterComponent implements OnInit, OnChanges {
  @Input() employeeGroups: IEmployeeGroup[];

  @Input() allGroups: boolean;

  @Input() allEmployeesCount: number;

  @Input() showCounter: boolean;

  @Output() selectedEmployees = new EventEmitter<IEmployeeGroup[]>();

  selectedGroup: IEmployeeGroup;

  localGroups: IEmployeeGroup[] = [];

  allGroupDefault: IEmployeeGroup = {
    Id: null,
    Name: "All Employees",
    Selected: true,
    EmployeeCount: 0,
    Description: "All Employees",
    Thumbnail: null,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private employeeGroupService: EmployeeGroupService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employeeGroups && changes.employeeGroups.currentValue) {
      const selectedGroupId = this.localGroups.find((x) => x.Selected)?.Id;
      if (this.allGroups) {
        this.allGroupDefault.EmployeeCount = this.allEmployeesCount;

        this.localGroups = [
          this.allGroupDefault,
          ...changes.employeeGroups.currentValue.map((x) => Object.assign({}, x)),
        ];
      } else {
        this.localGroups = [...changes.employeeGroups.currentValue.map((x) => Object.assign({}, x))];
      }

      const selectedGroup = this.localGroups.find((x) => x.Id == selectedGroupId);

      if (selectedGroup) {
        this.localGroups.forEach((x) => (x.Selected = false));
        selectedGroup.Selected = true;
      } else if (this.localGroups.length > 0 && this.localGroups.every((x) => !x.Selected)) {
        this.localGroups[0].Selected = true;
        this.raiseSelectedGroups();
      }
      this.selectedGroup = this.localGroups.find((x) => x.Selected);
      this.cdr.markForCheck();
    }
  }

  ngOnInit(): void {
    if (this.employeeGroups == null) {
      this.employeeGroupService.getEmployeeGroups().subscribe((employeeGroups: IEmployeeGroup[]) => {
        if (this.allGroups) {
          this.localGroups = [this.allGroupDefault, ...employeeGroups];
        } else {
          this.localGroups = [...employeeGroups];
        }

        this.selectedGroup = this.localGroups.find((x) => x.Selected);
        this.cdr.markForCheck();
      });
    }
  }

  onSelectionChanged(event: MatSelectChange) {
    this.localGroups.forEach((group) => {
      group.Selected = group.Id == event?.value?.Id;
    });
    this.raiseSelectedGroups();
  }

  compareEmployeeGroupObjects(o1: IEmployeeGroup, o2: IEmployeeGroup) {
    return o1.Id == o2.Id;
  }

  raiseSelectedGroups() {
    this.selectedEmployees.emit(this.localGroups.filter((x) => x.Selected));
  }
}
