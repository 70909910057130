<span *ngIf="maxValue === -1" class="title">You have unlimited channels.</span>
<div *ngIf="maxValue !== -1" class="info">
  <span *ngIf="limit() >= 2" class="title">
    You can select <span class="limit">{{ limit() }}</span> more channels.
  </span>
  <span *ngIf="limit() === 1" class="title">
    You can select <span class="limit">{{ limit() }}</span> more channel.
  </span>
  <span *ngIf="limit() === 0" class="title">You can't select more channels.</span>
  <span *ngIf="limit() < 0" class="title">
    You're out of channels. Buy more on the <span class="limit">plans page</span>.
  </span>
</div>
<mat-progress-bar
  *ngIf="maxValue !== -1"
  mode="determinate"
  value="{{ (minValue / maxValue) * 100 }}"
  style="height: 6px; border-radius: 4px">
</mat-progress-bar>
