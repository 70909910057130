import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-alert-success",
  templateUrl: "./alert-success.component.html",
  styleUrls: ["./alert-success.component.scss"],
})
export class AlertSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
