import {Injectable, EventEmitter} from "@angular/core";
import {Observable, of, forkJoin, BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {
  IFacebookLinkedPageResult,
  ILinkedPagesForGroupModel,
  IProfile,
  IProfileResponse,
  EmployeeInList,
} from "./profile.interface";
import {map, share, tap} from "rxjs/operators";
import {ProfileTypes} from "./profile-types.enum";
import {AsyncMessageService} from "@shared/utils/async-message.service";
import {Employee, IEmployeeGroup} from "src/app/pages/auth/employee/employee";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private baseUrl = environment.api + "/profiles";

  private defaults: IProfileResponse;

  allProfilesObservable = new BehaviorSubject<IProfile[]>([]);

  public profileRemovedEvent = new EventEmitter<any>();

  public profileModifiedEvent = new EventEmitter<IProfile>();

  constructor(
    private http: HttpClient,
    private asyncMessageService: AsyncMessageService,
  ) {
    this.asyncMessageService.accountHub.OnProfileModified.subscribe((profileId: string) => {
      this.get(profileId).subscribe((profile) => {
        const profileToModifyIndex = this.allProfilesObservable.value.findIndex((x) => x.Id == profile.Id);
        const all = this.allProfilesObservable.value;
        all[profileToModifyIndex] = profile;
        this.allProfilesObservable.next(all);
        this.profileModifiedEvent.emit(profile);
      });
    });
  }

  getDefaults(cache = true): Observable<IProfileResponse> {
    if (this.defaults && cache) {
      return of(this.defaults);
    }
    return this.http.get<IProfileResponse>(this.baseUrl + "/default").pipe(
      tap((response) => {
        this.defaults = response;
      }),
    );
  }

  get(profileId: string): Observable<IProfile> {
    return this.http.get<IProfile>(this.baseUrl + `/${profileId}`).pipe(
      tap((profile) => {
        profile.IsWebhookEnabled = profile.IsWebHookSubscribed || profile.IsTwitterWebHookSubscribed;
      }),
    );
  }

  list(type?: string, cache = true): Observable<IProfile[]> {
    let observable: Observable<IProfile[]>;
    if (this.allProfilesObservable.value.length > 0 && cache) {
      observable = of(this.allProfilesObservable.value);
    } else {
      observable = this.http.get<IProfile[]>(this.baseUrl).pipe(
        tap((profiles) => {
          profiles.forEach((x) => {
            x.IsWebhookEnabled = x.IsWebHookSubscribed || x.IsTwitterWebHookSubscribed;
          });
          this.allProfilesObservable.next(profiles);
        }),
        share(),
      );
    }
    return observable.pipe(
      map((profiles) => {
        if (type) {
          return profiles.filter((profile: IProfile) => {
            switch (type) {
              case "facebook":
                return profile.Type === ProfileTypes.Facebook;
              case "twitter":
                return profile.Type === ProfileTypes.TwitterAccount;
              case "instagram":
                return profile.Type === ProfileTypes.InstagramAccount;
              case "linkedin":
                return profile.Type === ProfileTypes.LinkedIn;
            }
          });
        }
        return profiles;
      }),
    );
  }

  AnyFacebookProfileAdded() {
    return this.AnyProfileWithTypeAdded(1);
  }

  AnyTwitterProfileAdded() {
    return this.AnyProfileWithTypeAdded(2);
  }

  AnyInstagramProfileAdded() {
    return this.AnyProfileWithTypeAdded(6);
  }

  AnyLinkedInProfileAdded() {
    return this.AnyProfileWithTypeAdded(8);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.baseUrl + "/" + id).pipe(
      map(() => {
        const profileDeleted = this.allProfilesObservable.value.find((x) => x.Id === id);

        if (profileDeleted.IsDefault) {
          const defaultTask = this.getDefaults();
          const listProfilesTask = this.list(null, false);
          return forkJoin([defaultTask, listProfilesTask]);
        } else {
          const all = this.allProfilesObservable.value.filter((x) => x.Id !== id);
          return of(all);
        }
      }),
      tap(() => {
        this.profileRemovedEvent.emit({profileId: id});
      }),
    );
  }

  clearCache() {
    this.defaults = null;
    this.allProfilesObservable.next([]);
  }

  setProfileDefault(id: string): Observable<any> {
    return this.http.put(this.baseUrl + "/default/" + id, {}).pipe(
      tap(() => {
        const index = this.allProfilesObservable.value.findIndex((x) => x.Id === id);

        if (index < 0) {
          return;
        }

        const profile = this.allProfilesObservable.value[index];

        const oldPrimaryIndex = this.allProfilesObservable.value.findIndex(
          (x) => x.IsDefault && x.Type === profile.Type,
        );

        if (oldPrimaryIndex >= 0) {
          this.allProfilesObservable.value[oldPrimaryIndex].IsDefault = false;
        }
        this.allProfilesObservable.value[index].IsDefault = true;

        this.allProfilesObservable.next(this.allProfilesObservable.value);
        this.getDefaults().subscribe();
      }),
    );
  }

  setLinkedPagesToFacebookGroup(model: ILinkedPagesForGroupModel) {
    return this.http.post<IFacebookLinkedPageResult[]>(this.baseUrl + "/SetLinkedPagesForGroup", model);
  }

  getLinkedPagesToFacebookGroup(groupId: string) {
    return this.http.get<IProfile[]>(this.baseUrl + "/GetLinkedPagesForGroup/" + groupId);
  }

  removeEmployeeFromLinkedInOrganization(employeeId: string): Observable<any> {
    return this.http.delete<any>(environment.api + `/Employees/${employeeId}`);
  }

  requestLinkedInReauth(employeeId: string): Observable<void> {
    return this.http.post<void>(`${environment.api}/Employees/RequestLinkedInReauth/${employeeId}`, {});
  }

  private AnyProfileWithTypeAdded(type: number): boolean {
    return this.allProfilesObservable.value.some((profile) => profile.Type === type);
  }
}
