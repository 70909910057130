import {Reactable} from "../reactions/reactable.model";
import {Author, Reaction, UrlMetadata} from "../activity.interface";
import {Directive} from "@angular/core";

@Directive()
export class Comment extends Reactable {
  constructor(
    public ActivityCommentText: string,
    public Text: any,
    public ParentActivityId: string,
    public Author: Author,
    public ParentCommentId?: any,
    public IsPriority?: boolean,
    public AngryCount?: number,
    public WowCount?: number,
    public SadCount?: number,
    public HahaCount?: number,
    public LoveCount?: number,
    public ReactionsCount?: number,
    public JoinFieldParentId?: any,
    public Type?: any,
    public AccountId?: string,
    public BrandId?: string,
    public CreatedTime: string = new Date().toString(),
    public UpdatedTime?: any,
    public Engagement?: number,
    public SourceType?: string,
    CommentsCount = 0,
    public MD5Hash?: any,
    public Urls?: any,
    public Language?: any,
    public Source?: any,
    Comments?: Comment[],
    public StoredCommentsCount?: number,
    public Attachment?: any,
    public LikesCount: number = 0,
    public UrlMetadata?: UrlMetadata[],
    Reactions: Reaction[] = [],
    public Hidden?: boolean,
    public Anonymous?: boolean,
    public Id?: string,
  ) {
    super(Reactions, Comments, CommentsCount);
  }

  incrementLikeCount() {
    this.LikesCount++;
    this.ReactionsCount++;
  }

  decrementLikeCount() {
    if (this.LikesCount > 0) {
      this.LikesCount--;
    }
    if (this.ReactionsCount > 0) {
      this.ReactionsCount--;
    }
  }
}
