import {HttpClient} from "@angular/common/http";
import {EventEmitter, Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {Observable} from "rxjs";
import {map, shareReplay, tap} from "rxjs/operators";
import {
  DefaultGroupDomain,
  IBitlyGroup,
  IBitlyOrganization,
  IntegrationDataModel,
  IntegrationTypeEnum,
  SlackIntegrationModel,
  TeamsIntegrationModel,
} from "src/app/pages/settings/integrations/integrations.interface";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  baseUrl = `${environment.api}/integration`;
  integrationConfCache = [];

  private windowPoll: any;
  private bitlyGroupsObservable: Observable<IBitlyGroup[]>;
  private bitlyDefaultGroupDomainObservable: Observable<DefaultGroupDomain>;
  private integrationObservable: Observable<IntegrationTypeEnum[]>;

  public windowReference: Window;

  public integrationAuthenticationFinished = new EventEmitter<any>();

  constructor(private http: HttpClient) {
    this.updateObservables();
  }

  updateObservables() {
    this.bitlyGroupsObservable = this.http
      .get<IBitlyGroup[]>(`${this.baseUrl}/GetBitlyGroups`)
      .pipe(
        map((groups) => {
          groups.forEach((x) => {
            x.bsds.push("bit.ly");
          });

          return groups;
        }),
      )
      .pipe(shareReplay(1));

    this.bitlyDefaultGroupDomainObservable = this.http
      .get<DefaultGroupDomain>(`${this.baseUrl}/DefaultGroupDomain`)
      .pipe(shareReplay(1));

    this.integrationObservable = this.http
      .get<IntegrationTypeEnum[]>(this.baseUrl)
      .pipe(
        tap((integrationsConf) => {
          this.integrationConfCache = integrationsConf;
        }),
      )
      .pipe(shareReplay(1));
  }

  public startAuthentication(integrationType: IntegrationTypeEnum): Observable<any> {
    return this.http.get<string>(this.baseUrl + `/IntegrationStart/${integrationType}`).pipe(
      map((url) => {
        this.windowReference = window.open(url, "", "width=900,height=900");
        this.windowPoll = setInterval(() => {
          if (this.windowReference.closed === true) {
            clearInterval(this.windowPoll);
            this.integrationAuthenticationFinished.emit();
          }
        }, 500);

        return {
          windowPoll: this.windowPoll,
          windowReference: this.windowPoll,
          authenticationCancelled: this.integrationAuthenticationFinished,
        };
      }),
    );
  }

  getIntegrationsConfigured() {
    return this.integrationObservable;
  }

  logout(integrationType: IntegrationTypeEnum) {
    const intType = this.http.delete(`${this.baseUrl}/${integrationType}`);
    this.updateObservables();
    return intType;
  }

  getIntegrationStartUrl(integrationType: IntegrationTypeEnum) {
    return this.http.get<string>(this.baseUrl + `/IntegrationStart/${integrationType}`);
  }

  //Probably this doesnt belong on this service.
  shortenUrl(fullLink: string, domain: string, groupId: string) {
    return this.http.post<string>(`${this.baseUrl}/ShortenUrl`, {
      FullLink: fullLink,
      GroupId: groupId,
      Domain: domain,
    });
  }

  getBitlyGroups(): Observable<IBitlyGroup[]> {
    return this.bitlyGroupsObservable;
  }

  getBitlyOrganization() {
    return this.http.get<IBitlyOrganization[]>(`${this.baseUrl}/GetBitlyOrganizationData`);
  }

  setDefaultDomain(group: IBitlyGroup, domain: string) {
    const result = this.http.post<any>(`${this.baseUrl}/SetDefaultGroupDomain`, {
      GroupId: group?.guid,
      Domain: domain,
    });
    this.updateObservables();
    return result;
  }

  getDefaultDomain(): Observable<DefaultGroupDomain> {
    return this.bitlyDefaultGroupDomainObservable;
  }

  getSlackIntegrationData(): Observable<SlackIntegrationModel[]> {
    return this.http.get<IntegrationDataModel[]>(`${this.baseUrl}/IntegrationData/${IntegrationTypeEnum.Slack}`).pipe(
      map((x) => {
        return x.map((t) => {
          return {
            WorkspaceName: t.DisplayName,
            Domain: t.Domain,
            WorkspaceImage: t.ImageUrl,
          };
        });
      }),
    );
  }

  getTeamsIntegrationData(): Observable<TeamsIntegrationModel> {
    return this.http.get<IntegrationDataModel[]>(`${this.baseUrl}/IntegrationData/${IntegrationTypeEnum.Teams}`).pipe(
      map((x) => {
        return x.map((t) => {
          return {
            Domain: t.Domain,
          };
        })[0];
      }),
    );
  }
}
