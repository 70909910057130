<app-user-avatar [picture]="comment.Author?.Picture" size="small"></app-user-avatar>
<div class="parent-comment">
  <span
    class="first-level"
    [class.p0]="!(comment.ActivityCommentText || comment.Text)?.length"
    [class.no-text]="!(comment.ActivityCommentText || comment.Text)?.length">
    <a
      *ngIf="!comment.Anonymous"
      class="author"
      target="_blank"
      href="https://www.facebook.com/{{ comment.Author?.AccountIdentifier }}"
      >{{ comment.Author.FriendlyName }}</a
    >
    <span data-test="shared-commentText-span" [innerHTML]="text"></span>
  </span>
  <span class="fb-cmt-attachments" *ngIf="comment.Attachment">
    <img
      *ngIf="comment.Attachment.Type === 'sticker'"
      [src]="comment.Attachment.Media.Image.Src"
      width="80"
      height="80" />
    <img
      *ngIf="comment.Attachment.Type === 'photo'"
      [src]="comment.Attachment.Media.Image.Src"
      class="img-responsive" />
    <img
      *ngIf="comment.Attachment.Type === 'animated_image_share' && isAttachmentInViewPort"
      [src]="comment.Attachment.UnshimmedUrl || comment.Attachment.Media.Image.Src"
      class="img-responsive" />
    <video *ngIf="comment.Attachment.Type === 'video_inline'" class="vid-attch" controls>
      <source [src]="comment.Attachment.Media.Source" type="video/mp4" />
    </video>
  </span>
  <div class="comment-actions">
    <ng-container *ngIf="comment.Id">
      <span class="action" *ngIf="hasLike; else noReaction" (click)="unlike()">Unlike</span>
      <ng-template #noReaction>
        <span class="action" (click)="like()">Like</span>
      </ng-template>
      <span role="presentation" aria-hidden="true" class="cmt-dot"> &middot; </span>
      <span class="action" (click)="toggleComment()">Reply</span>
      <span role="presentation" aria-hidden="true" class="cmt-dot"> &middot; </span>
    </ng-container>
    <span class="source-created">{{ comment.CreatedTime | amTimeAgo }}</span>
    <span *ngIf="comment.ReactionsCount" role="presentation" aria-hidden="true" class="cmt-dot"> &middot; </span>
    <span class="reactions-wrapper">
      <app-facebook-reactions-list [post]="comment" size="small"></app-facebook-reactions-list>
      <span *ngIf="comment.ReactionsCount">
        <span class="source-created comment-reaction-counter">{{ comment.ReactionsCount }}</span>
      </span>
    </span>
  </div>
  <app-comment-input
    *ngIf="toggle"
    label="Write a reply"
    [provider]="activity.provider"
    [activity]="activity"
    [parentComment]="parentComment ? parentComment : comment"
    (commented)="toggleComment()"></app-comment-input>
  <div *ngIf="!isLast" class="clearfix"></div>
  <app-facebook-comment
    *ngFor="let subComment of comment.Comments; let last = last"
    [comment]="subComment"
    [level]="level + 1"
    [activity]="activity"
    [parentComment]="comment"
    [isLast]="last"
    class="second-level">
  </app-facebook-comment>
  <div
    *ngIf="!hideLoadMore && !!comment.CommentsCount && comment.Comments?.length < comment.CommentsCount"
    class="comment more-comments-link">
    <span>
      <a (click)="loadMoreFacebookComments()"
        >View <span>{{ comment.CommentsCount - comment.Comments?.length }}</span> more
        <span>{{ comment.CommentsCount - comment.Comments?.length === 1 ? "reply" : "replies" }}</span>
      </a>
    </span>
  </div>
</div>
