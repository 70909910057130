import {Component, EventEmitter, Input, Output} from "@angular/core";
import {IZapierProcessedProfile} from "@shared/composer/composer.component";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-progress-preview-zapier",
  templateUrl: "./progress-preview-zapier.component.html",
  styleUrls: ["./progress-preview-zapier.component.scss"],
})
export class ProgressPreviewZapierComponent {
  @Input() processedProfiles: IZapierProcessedProfile[];

  @Output() Next = new EventEmitter<any>();
  @Output() Finished = new EventEmitter<any>();

  OnNextClick() {
    this.Next.emit();
  }

  OnFinished() {
    this.Finished.emit();
  }
}
