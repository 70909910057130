import {Component, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Inject} from "@angular/core";
import {IBaseTableDataModel, LeaderboardUserModel} from "../../analytics.interface";
import {Observable} from "rxjs";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {BaseTableComponent} from "../base-table/base-table.component";
import {UserWidget} from "../../widget.interface";

@UntilDestroy()
@Component({
  selector: "app-user-leaderboard",
  templateUrl: "./user-leaderboard.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLeaderboardComponent extends BaseTableComponent<LeaderboardUserModel> implements AfterViewInit {
  fileName = "Sharing_Leaderboard";

  constructor(
    protected service: LeaderboardAnalyticsService,
    protected cdr: ChangeDetectorRef,
    protected timerangeListener: TimerangeListenerService,
    protected profilesListener: ProfileListenerService,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(service, timerangeListener, profilesListener, cdr, widgetConfig);
  }

  getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesIds: string[],
  ): Observable<IBaseTableDataModel<LeaderboardUserModel>> {
    return this.service.getUserLeaders(startDate, endDate, pageIndex, pageSize, groupsIds, profilesIds);
  }

  getCsvHeaders() {
    return {
      UserId: "Internal Id",
      UserFirstName: "First Name",
      UserLastName: "Last Name",
      UserImage: "Image",
      UserEmail: "Email",
      //ComposerShares: "New Content", TODO: reenable this option when the feature is implemented.
      StreamShares: "Existing Content",
      EmployeeEngagement: "Employee Engagement",
    };
  }

  FullUserName(u: LeaderboardUserModel) {
    return u.UserFirstName + " " + u.UserLastName;
  }
}
