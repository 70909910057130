<!-- Composing New Content -->
<div class="composer-actions mt-5" *ngIf="!content && !publishNow && !onlySchedule">
  <mat-tab-group
    (selectedTabChange)="scrollToBottom()"
    animationDuration="0ms"
    disablePagination="true"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    disableRipple
    [(selectedIndex)]="composerService.actionsActiveTab"
    [class.round]="composerService.actionsActiveTab > 0">
    <!-- Add to Library -->
    <mat-tab label="Add to Library" data-test="composer-add-to-library-tab">
      <div class="mb-4">
        <app-tags-input name="Tags" [(ngModel)]="selectedTags"></app-tags-input>
      </div>
      <div class="mb-4">
        <mat-slide-toggle [(ngModel)]="expires"> Expire post on date</mat-slide-toggle>
      </div>
      <div class="schedule-date-time mb-4" *ngIf="expires">
        <div class="schedule-date">
          <mat-form-field appearance="outline">
            <mat-label>Expiration Date</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatepicker"
              (dateChange)="expirationDatepickerChange()"
              [(ngModel)]="expiration.startAt"
              [min]="today" />
            <button type="button" (click)="expirationDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #expirationDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="schedule-time">
          <app-time-selector
            [(ngModel)]="expiration.time"
            (ngModelChange)="expirationDatepickerChange()"></app-time-selector>
        </div>
      </div>
      <div>
        <app-timezone-selector
          *ngIf="expires"
          (TimezoneSelected)="OnExpirationTimezoneSelected($event)"
          [InitialTimezoneSelected]="expiration.timezoneId"
          type="block"></app-timezone-selector>
      </div>
      <app-button
        (buttonClick)="saveContent()"
        [loading]="loading"
        data-test="composer-add-to-library-button"
        type="block"
        class="block mt-4"
        >Add to Library
      </app-button>
    </mat-tab>
    <!-- Schedule -->
    <mat-tab label="Schedule" data-test="composer-schedule-tab">
      <div class="schedule-date-time mb-4">
        <!-- Date -->
        <div class="schedule-date" data-test="composer-schedule-date-field">
          <mat-form-field appearance="outline" subscriptSizing="fixed">
            <mat-label>Schedule Date</mat-label>
            <!--suppress CheckEmptyScriptTag -->
            <input
              matInput
              [matDatepicker]="startDatepicker"
              (dateChange)="onDatepickerChange()"
              [(ngModel)]="schedule.startAt"
              [min]="today" />
            <button type="button" (click)="startDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Time -->
        <div class="schedule-time" data-test="composer-create-schedule-time-button">
          <app-time-selector [(ngModel)]="schedule.time" (ngModelChange)="onDatepickerChange()"></app-time-selector>
        </div>
      </div>
      <!-- Timezone -->
      <app-timezone-selector
        class="block mb-4"
        (TimezoneSelected)="OnTimezoneSelected($event); OnExpirationTimezoneSelected($event)"
        [InitialTimezoneSelected]="schedule.timezoneId"
        type="block"></app-timezone-selector>
      <div class="mb-4">
        <mat-slide-toggle
          [(ngModel)]="addToLibrary"
          data-test="composer-actions-schedule-saveToContentLibrary-toggle-button">
          Save to content library
        </mat-slide-toggle>
      </div>
      <div class="mb-4" *ngIf="addToLibrary">
        <app-tags-input name="Tags" [disabled]="!addToLibrary" [(ngModel)]="selectedTags"></app-tags-input>
      </div>
      <ng-container>
        <div class="mb-4">
          <mat-slide-toggle [(ngModel)]="expires" [disabled]="!addToLibrary"> Expire post on date</mat-slide-toggle>
        </div>
        <div class="schedule-date-time mb-4" *ngIf="expires && addToLibrary">
          <div class="schedule-date">
            <mat-form-field appearance="outline">
              <mat-label>Expiration Date</mat-label>
              <input
                matInput
                [matDatepicker]="expirationDatepicker"
                (dateChange)="expirationDatepickerChange()"
                [(ngModel)]="expiration.startAt"
                [min]="today" />
              <button type="button" (click)="expirationDatepicker.open()" mat-icon-button matSuffix>
                <app-icon
                  icon="calendar"
                  class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
              </button>
              <mat-datepicker #expirationDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="schedule-time">
            <app-time-selector
              [(ngModel)]="expiration.time"
              (ngModelChange)="expirationDatepickerChange()"></app-time-selector>
          </div>
        </div>
      </ng-container>
      <app-button
        (buttonClick)="createSchedule()"
        [loading]="loading"
        type="block"
        data-test="composer-create-schedule-button"
        >Schedule
      </app-button>
    </mat-tab>
    <!-- Publish Now -->
    <mat-tab label="Publish Now" data-test="composer-publish-now-tab">
      <div class="mb-4">
        <mat-slide-toggle
          [(ngModel)]="addToLibrary"
          data-test="composer-actions-publish-now-saveToContentLibrary-toggle-button">
          Save to content library
        </mat-slide-toggle>
      </div>
      <div class="mb-4" *ngIf="addToLibrary">
        <app-tags-input name="Tags" [disabled]="!addToLibrary" [(ngModel)]="selectedTags"></app-tags-input>
      </div>
      <div class="mb-4">
        <mat-slide-toggle [(ngModel)]="expires" [disabled]="!addToLibrary"> Expire post on date</mat-slide-toggle>
      </div>
      <div class="schedule-date-time mb-4" *ngIf="expires && addToLibrary">
        <div class="schedule-date">
          <mat-form-field appearance="outline">
            <mat-label>Expiration date</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatepicker"
              (dateChange)="expirationDatepickerChange()"
              [(ngModel)]="expiration.startAt"
              [min]="today" />
            <button type="button" (click)="expirationDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #expirationDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="schedule-time">
          <app-time-selector
            [(ngModel)]="expiration.time"
            (ngModelChange)="expirationDatepickerChange()"></app-time-selector>
        </div>
      </div>
      <app-timezone-selector
        *ngIf="expires && addToLibrary"
        (TimezoneSelected)="OnExpirationTimezoneSelected($event)"
        [InitialTimezoneSelected]="expiration.timezoneId"
        type="block"></app-timezone-selector>
      <app-button
        (buttonClick)="publish()"
        [loading]="loading"
        type="block"
        class="block mt-4"
        data-test="composer-publish-now-button"
        >Publish Now
      </app-button>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Editing Existing Content -->
<div class="composer-actions mt-5" *ngIf="content && !publishNow && !content.HasNoContent && !onlySchedule">
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    animationDuration="0ms"
    disablePagination="true"
    class="round no-tab-header"
    (selectedTabChange)="scrollToBottom()">
    <!-- Update tags -->
    <mat-tab label="Tags" data-test="composer-tags-tab">
      <div class="mb-4">
        <app-tags-input name="Tags" [(ngModel)]="selectedTags"></app-tags-input>
      </div>
      <div *ngIf="editSchedule">
        <div class="schedule-date-time mb-4">
          <!-- Date -->
          <div class="schedule-date" data-test="composer-schedule-date-field">
            <mat-form-field appearance="outline">
              <mat-label>Schedule Date</mat-label>
              <input
                matInput
                [matDatepicker]="startDatepicker"
                (dateChange)="onDatepickerChange()"
                [(ngModel)]="schedule.startAt"
                [min]="today" />
              <button type="button" (click)="startDatepicker.open()" mat-icon-button matSuffix>
                <app-icon
                  icon="calendar"
                  class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
              </button>
              <mat-datepicker #startDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- Time -->
          <div class="schedule-time" data-test="composer-create-schedule-time-button">
            <app-time-selector [(ngModel)]="schedule.time" (ngModelChange)="onDatepickerChange()"></app-time-selector>
          </div>
        </div>
      </div>
      <div *ngIf="!editSchedule">
        <div class="mb-4">
          <mat-slide-toggle [(ngModel)]="expires"> Expire post on date</mat-slide-toggle>
        </div>
        <div class="schedule-date-time mb-4" *ngIf="expires">
          <div class="schedule-date">
            <mat-form-field appearance="outline">
              <mat-label>Expiration Date</mat-label>
              <input
                matInput
                [matDatepicker]="expirationDatepicker"
                (dateChange)="expirationDatepickerChange()"
                [(ngModel)]="expiration.startAt"
                [min]="today" />
              <button type="button" (click)="expirationDatepicker.open()" mat-icon-button matSuffix>
                <app-icon
                  icon="calendar"
                  class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
              </button>
              <mat-datepicker #expirationDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="schedule-time">
            <app-time-selector
              [(ngModel)]="expiration.time"
              (ngModelChange)="expirationDatepickerChange()"></app-time-selector>
          </div>
        </div>
        <app-timezone-selector
          *ngIf="expires"
          (TimezoneSelected)="OnTimezoneSelected($event); OnExpirationTimezoneSelected($event)"
          [InitialTimezoneSelected]="schedule.timezoneId"
          type="block"></app-timezone-selector>
      </div>
      <app-button (buttonClick)="editContent()" [loading]="loading" type="block">Save</app-button>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- If Publishing Now from Library -->
<div class="composer-actions mt-5" *ngIf="publishNow && !onlySchedule">
  <mat-tab-group
    (selectedTabChange)="scrollToBottom()"
    animationDuration="0ms"
    disablePagination="true"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    disableRipple
    [(selectedIndex)]="composerService.actionsActiveTab"
    [class.round]="composerService.actionsActiveTab > 0">
    <!-- Publish Now -->
    <mat-tab label="Publish Now" data-test="composer-publish-now-tab">
      <app-button (buttonClick)="publish()" [loading]="loading" type="block" class="block mt-4">Publish Now</app-button>
    </mat-tab>
    <!-- Schedule -->
    <mat-tab label="Schedule" data-test="composer-schedule-tab">
      <div class="schedule-date-time mb-4">
        <!-- Date -->
        <div class="schedule-date" data-test="composer-schedule-date-field">
          <mat-form-field appearance="outline">
            <mat-label>Schedule Date</mat-label>
            <input
              matInput
              [matDatepicker]="startDatepicker"
              (dateChange)="onDatepickerChange()"
              [(ngModel)]="schedule.startAt"
              [min]="today" />
            <button type="button" (click)="startDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Time -->
        <div class="schedule-time" data-test="composer-create-schedule-time-button">
          <app-time-selector [(ngModel)]="schedule.time" (ngModelChange)="onDatepickerChange()"></app-time-selector>
        </div>
      </div>
      <!-- Timezone -->
      <app-timezone-selector
        (TimezoneSelected)="OnTimezoneSelected($event); OnExpirationTimezoneSelected($event)"
        [InitialTimezoneSelected]="schedule.timezoneId"
        type="block"></app-timezone-selector>
      <app-button
        (buttonClick)="createSchedule()"
        [loading]="loading"
        type="block"
        data-test="composer-create-schedule-button"
        >Schedule
      </app-button>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Saving a New Post from an Empty Timeslot -->
<div class="composer-actions mt-5" *ngIf="content && !publishNow && content.HasNoContent && !onlySchedule">
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    animationDuration="0ms"
    disablePagination="true"
    class="round no-tab-header"
    (selectedTabChange)="scrollToBottom()">
    <!-- Update tags -->
    <mat-tab label="Tags" data-test="composer-tags-tab">
      <div class="mb-4">
        <app-tags-input name="Tags" [(ngModel)]="selectedTags"></app-tags-input>
      </div>
      <div class="mb-4">
        <mat-slide-toggle [(ngModel)]="expires"> Expire post on date</mat-slide-toggle>
      </div>
      <div class="schedule-date-time mb-4" *ngIf="expires">
        <div class="schedule-date">
          <mat-form-field appearance="outline">
            <mat-label>Expiration Date</mat-label>
            <input
              matInput
              [matDatepicker]="expirationDatepicker"
              (dateChange)="expirationDatepickerChange()"
              [(ngModel)]="expiration.startAt"
              [min]="today" />
            <button type="button" (click)="expirationDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #expirationDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="schedule-time">
          <app-time-selector
            [(ngModel)]="expiration.time"
            (ngModelChange)="expirationDatepickerChange()"></app-time-selector>
        </div>
      </div>
      <app-timezone-selector
        *ngIf="expires"
        (TimezoneSelected)="OnExpirationTimezoneSelected($event)"
        [InitialTimezoneSelected]="expiration.timezoneId"
        type="block"></app-timezone-selector>
      <app-button (buttonClick)="saveContent()" [loading]="loading" type="block" class="block mt-4"
        >Add to Library</app-button
      >
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Rescheduling Failed Content -->
<div class="composer-actions mt-5" *ngIf="onlySchedule">
  <mat-tab-group
    animationDuration="0ms"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    disablePagination="true"
    (selectedTabChange)="scrollToBottom()"
    disableRipple
    [class.round]="composerService.actionsActiveTab > 0">
    <!-- Update Schedule -->
    <mat-tab label="Schedule" data-test="composer-schedule-tab">
      <div class="schedule-date-time mb-4">
        <div class="schedule-date" data-test="composer-schedule-date-field">
          <mat-form-field appearance="outline">
            <mat-label>Schedule Date</mat-label>
            <input
              matInput
              [matDatepicker]="startDatepicker"
              (dateChange)="onDatepickerChange()"
              [(ngModel)]="schedule.startAt"
              [min]="today" />
            <button type="button" (click)="startDatepicker.open()" mat-icon-button matSuffix>
              <app-icon
                icon="calendar"
                class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
            </button>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="schedule-time" data-test="composer-create-schedule-time-button">
          <app-time-selector [(ngModel)]="schedule.time"></app-time-selector>
        </div>
      </div>
      <!-- Timezone -->
      <app-timezone-selector
        (TimezoneSelected)="OnTimezoneSelected($event)"
        [InitialTimezoneSelected]="schedule.timezoneId"
        type="block"></app-timezone-selector>
      <app-button (buttonClick)="createSchedule()" [loading]="loading" type="block">Reschedule</app-button>
    </mat-tab>
    <!-- Publish Now -->
    <mat-tab label="Publish Now" data-test="composer-publish-now-tab">
      <app-button (buttonClick)="publish()" [loading]="loading" type="block" class="block mt-4">Publish Now</app-button>
    </mat-tab>
  </mat-tab-group>
</div>
