import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "@subscription/subscription";
import {StripeCardComponent} from "@shared/utils/stripe/stripe-card/stripe-card.component";
import {Stripe} from "@shared/utils/stripe/stripe-definitions";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-credit-card-form",
  templateUrl: "./credit-card-form.component.html",
  styleUrls: ["./credit-card-form.component.scss"],
})
export class CreditCardFormComponent implements OnInit {
  @ViewChild("stripeForm", {static: true}) stripeForm: StripeCardComponent;

  @Input() subscription: Subscription;
  @Input() alwaysShowForm: boolean;
  @Input() enableBackOption = false;

  brandIcon: string;
  cardErrors: string;
  expirationErrors: string;
  codeErrors: string;
  zipErrors: string;
  newCreditCard = false;

  constructor(private stripe2: Stripe) {}

  ngOnInit() {
    this.newCreditCard = this.subscription.CreditCardType === "Unset";
  }

  submit(): Promise<any> {
    this.newCreditCard = false;
    return this.stripe2.createToken(this.stripeForm.element);
  }

  changeCreditCard() {
    this.newCreditCard = true;
  }

  backToCards() {
    this.newCreditCard = false;
  }
}
