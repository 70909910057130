<button
  mat-button
  [disabled]="disabled"
  [type]="buttonType"
  [ngClass]="type ? '' + type : null"
  [class.icon]="icon"
  (click)="buttonClick.emit()"
  class="icon-button">
  <mat-icon
    *ngIf="icon"
    svgIcon="{{ icon }}"
    [ngStyle]="{
      'height.px': iconSize,
      'width.px': iconSize,
      'line-height.px': iconSize
    }"></mat-icon>
</button>
