import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {environment} from "@environments/environment";
import {Subscription} from "@subscription/subscription";
import {SubscriptionService} from "@subscription/subscription.service";

export interface IAddOnPlanInfo {
  name: string;
  includedCount: number;
  addOnCount: number;
}

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-addon-plan",
  templateUrl: "./addon-plan.component.html",
  styleUrls: ["./addon-plan.component.scss"],
})
export class AddOnPlanComponent implements OnInit {
  @Input() plan: IAddOnPlanInfo;
  @Input() subscription: Subscription;
  @Output() selectAddOnPlan: EventEmitter<IAddOnPlanInfo> = new EventEmitter();

  form: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService,
    private router: Router,
  ) {
    this.form = formBuilder.group({
      AddOnLicensedAdvocates: [0, Validators.required],
      AddOnLicensedUsers: [0, Validators.required],
      AddOnLicensedChannels: [0, Validators.required],
    });
  }

  ngOnInit() {}

  /// navigate to change-plans, which should show invoice with these addons
  changeAddOnPlans() {
    const srcForm = this.form.value;

    this.router.navigate(["/settings/change-plan"], {
      state: {
        annualChange: false,
        planName: this.subscription.PlanType,
        users: srcForm.AddOnLicensedUsers,
        channels: srcForm.AddOnLicensedChannels,
        advocates: srcForm.AddOnLicensedAdvocates,
      },
    });
  }

  showAddOnAdvocates() {
    return (
      !environment.production && this.subscription.getBaseQuantityByName(this.subscription.Addon_Advocate_Name) > 0
    );
  }
  showAddOnUsers() {
    return !environment.production;
  }
  showAddOnChannels() {
    return !environment.production;
  }
}
