import {Component, Inject, OnInit} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {IProfile} from "../profile.interface";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-selectable-channels",
  templateUrl: "./selectable-channels.component.html",
  styleUrls: ["./selectable-channels.component.scss"],
})
export class SelectableChannelsComponent implements OnInit {
  public profiles: IProfile[];

  public multipleSelectionEnabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogReference: MatDialogRef<SelectableChannelsComponent>,
  ) {
    this.profiles = data.profiles;

    this.multipleSelectionEnabled = data.multipleSelectionEnabled;
  }

  ngOnInit(): void {}

  selectChannel(profile: IProfile) {
    if (!this.multipleSelectionEnabled) {
      this.profiles.filter((x) => x.Id != profile.Id).forEach((x) => (x.Selected = false));
    }
    profile.Selected = !profile.Selected;
  }

  saveSelectedChannels() {
    this.dialogReference.close(this.profiles);
  }
}
