<div class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0" data-test="composer-share-container-div">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400">Share</div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-2.5"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Share to Facebook Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-44px)] min-w-[500px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div class="flex-1">
        <section class="relative inline-flex flex-col h-full w-full rounded-md p-5 box-border">
          <!-- Editor -->
          <app-composer-editor
            data-test="composer-share-editor-component"
            (editorChange)="onEditorChange($event)"
            [limitChars]="false"
            [dialogRef]="dialogRef"
            [showProviderLabel]="false"
            [activity]="activity"
            [addPhoto]="false"
            [provider]="profile.Type"
            placeholder="Write a comment.."
            class="mb-5 !mt-0">
            <div class="flex items-center mb-4" share>
              <app-profile-picture
                [picture]="profile.Picture"
                [provider]="profile.Type"
                pictureSize="40"
                [hasChannel]="true"
                [showBadge]="true"
                class="mr-2.5">
              </app-profile-picture>
              <strong>{{ profile.Name }}</strong>
            </div>
            <!-- Embedded Activity -->
            <div class="embedded-activity">
              <app-activity
                [activity]="activity"
                [showFooter]="false"
                [showReactions]="false"
                class="border border-solid border-light-base-300 dark:border-dark-base-300 rounded-md"></app-activity>
            </div>
          </app-composer-editor>
          <!-- Action -->
          <app-button (buttonClick)="share()" [loading]="loading" type="block">Share</app-button>
        </section>
      </div>
    </ng-scrollbar>
  </div>
</div>
