import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of, Subject} from "rxjs";
import {OauthDescriptor} from "./oauth.interface";
import {tap} from "rxjs/operators";
import {ProfileTypes} from "./profile-types.enum";

@Injectable({
  providedIn: "root",
})
export class OauthService {
  private baseUrl = environment.api + "/oauth";
  private created$: Subject<any> = new Subject();
  created: Observable<void> = this.created$.asObservable();

  constructor(private http: HttpClient) {}

  getUrl(provider: string, closeWindow: boolean): Observable<string> {
    return this.http.get<string>(this.baseUrl + `/start?provider=${provider}&closeWindow=${closeWindow}`);
  }

  getDescriptor(provider: string, descriptorId: string): Observable<OauthDescriptor> {
    return this.http.get<OauthDescriptor>(this.baseUrl + "/descriptor/" + provider + "/" + descriptorId);
  }

  saveByDescriptor(provider: string, descriptor: OauthDescriptor): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + "/descriptor-complete/" + provider, descriptor)
      .pipe(tap((_) => this.created$.next(null)));
  }

  reauthorize(provider: ProfileTypes, closeWindow: boolean): Observable<string> {
    if (provider == ProfileTypes.Facebook) return this.getReauthorizeUrl("facebook", closeWindow);
    else if (provider == ProfileTypes.InstagramAccount) return this.getReauthorizeUrl("instagram", closeWindow);
    else if (provider == ProfileTypes.TwitterAccount) return this.reauthorizeTwitter(closeWindow);
    else if (provider == ProfileTypes.LinkedIn) return this.getReauthorizeUrl("linkedin", closeWindow);

    return of(null);
  }

  private getReauthorizeUrl(providerName: string, closeWindow: boolean) {
    return this.http.get<string>(
      `${this.baseUrl}/start?provider=${providerName}&reauthenticate=true&closeWindow=${closeWindow}`,
    );
  }

  private reauthorizeTwitter(closeWindow: boolean) {
    return this.getUrl("twitter", closeWindow);
  }
}
