import {SyndicationLink} from "../activity.interface";

export class SyndicationItem {
  constructor(
    public ContentType: any,
    public Summary: string,
    public Links: SyndicationLink[],
    public Categories: any,
    public GUID: any,
    public ImageSrc: any,
    public ImageAlt: any,
  ) {}
}
