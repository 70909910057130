import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {BehaviorSubject, Observable, of, tap} from "rxjs";
import {Employee, IEmployeeGroup} from "src/app/pages/auth/employee/employee";

@Injectable({
  providedIn: "root",
})
export class EmployeeGroupService {
  private employeeGroupsCache: IEmployeeGroup[];

  private employeeGroupsWithPublishersCache: IEmployeeGroup[];

  private employeeGroupsSource = new BehaviorSubject<IEmployeeGroup[]>([]);
  groups$ = this.employeeGroupsSource.asObservable();

  constructor(private http: HttpClient) {}

  getEmployeeGroups(cache = true): Observable<IEmployeeGroup[]> {
    if (cache && this.employeeGroupsCache != null) {
      return of(this.employeeGroupsCache);
    }
    return this.http.get<IEmployeeGroup[]>(environment.api + "/EmployeeGroup");
  }

  getEmployeeGroupsWithPublishers(cache = true) {
    if (cache && this.employeeGroupsWithPublishersCache != null) {
      return of(this.employeeGroupsWithPublishersCache);
    }
    return this.http.get<IEmployeeGroup[]>(environment.api + "/EmployeeGroup/GetEmployeeGroupsWithPublishers");
  }

  getEmployeeGroupWithPublisher(id: string) {
    return this.http.get<IEmployeeGroup>(environment.api + `/EmployeeGroup/GetEmployeeGroupWithPublishers/${id}`);
  }

  getEmployeeCountForGroup(employeeGroupIds: string[]) {
    return this.http.post<number>(environment.api + "/EmployeeGroup/GetEmployeeCountForGroup", {
      EmployeeGroupIds: employeeGroupIds,
    });
  }

  createOrUpdateEmployeeGroup(model: IEmployeeGroup) {
    return this.http.post<IEmployeeGroup>(environment.api + "/EmployeeGroup", model).pipe(
      tap(() => {
        this.employeeGroupsCache = null;
      }),
    );
  }

  deleteEmployeeGroup(model: IEmployeeGroup) {
    return this.http.delete(environment.api + "/EmployeeGroup" + "/" + model.Id).pipe(
      tap(() => {
        this.employeeGroupsCache = null;
      }),
    );
  }

  updateEmployeeGroups(employee: Employee, groupNames: string[]) {
    return this.http
      .post(environment.api + "/EmployeeGroup" + "/employee/" + employee.Id + "/groups", groupNames ?? [])
      .pipe(
        tap(() => {
          this.employeeGroupsCache = null;
        }),
      );
  }

  updateGroups(groups: IEmployeeGroup[]) {
    this.employeeGroupsSource.next(groups);
  }

  addGroup(group: IEmployeeGroup) {
    const currentGroups = this.employeeGroupsSource.getValue();
    this.employeeGroupsSource.next([...currentGroups, group]);
  }
}
