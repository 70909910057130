import {EventEmitter, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {
  EmployeeGroupEngagementClientGraphModel,
  EmployeeGroupEngagementGraphModel,
  EmployeeGroupsAnalyticsModel,
  EmployeeGroupsGrowthGraphModel,
  ITopPostsModel,
  UserShareToEmployeesGraphModel,
  IBaseTableDataModel,
  AdvocacyOrganicDataModel,
  IEmployeeSocialActionsModel,
  LeaderboardEmployeeModel,
  LeaderboardUserModel,
  EmployeeInvitationResponseGraphModel,
  IEmployeeBeforeAndAfterSocialActionsModel,
  IAnalysisSettings,
  IFollowerCountsGraphModel,
  IEarnedMediaValueModel,
  EmployeeGroupEngagementRatioGraphModel,
} from "./analytics.interface";
import {map} from "rxjs/operators";
import {EngagementType} from "./analytics-types.enum";
import {UserWidget} from "src/app/pages/analytics/widget.interface";

@Injectable({
  providedIn: "root",
})
export class LeaderboardAnalyticsService {
  constructor(private http: HttpClient) {}

  editWidgetEvent = new EventEmitter<UserWidget>();

  removeWidgetEvent = new EventEmitter<UserWidget>();

  widgetCounter = 0;

  getTopPostsEx(startTime: Date, endTime: Date, pageNum: number): Observable<ITopPostsModel> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http.post<ITopPostsModel>(environment.api + "/Leaderboard/socialaction/counts", {
      StartTime: startTime.toISOString(),
      EndTime: endTime?.toISOString(),
      PageNum: pageNum,
      RecordsPerPage: 10,
    });
  }

  getAmplifiedPostsEx(
    startTime: Date,
    endDate: Date,
    pageNum: number,
    recordsPerPage: number,
    groupsIds: string[],
    profileIds: string[],
  ): Observable<IBaseTableDataModel<IEmployeeSocialActionsModel>> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http.post<IBaseTableDataModel<IEmployeeSocialActionsModel>>(
      environment.api + "/leaderboard/socialaction/employeecounts",
      {
        StartTime: startTime.toISOString(),
        EndTime: endDate?.toISOString(),
        PageNum: pageNum,
        RecordsPerPage: recordsPerPage,
        GroupIds: groupsIds,
        ProfileIds: profileIds,
      },
    );
  }

  getAmplifiedPostsBeforeAndAfter(
    startTime: Date,
    endDate: Date,
    pageNum: number,
    recordsPerPage: number,
    groupsIds: string[],
    profileIds: string[],
  ): Observable<IBaseTableDataModel<IEmployeeBeforeAndAfterSocialActionsModel>> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http.post<IBaseTableDataModel<IEmployeeBeforeAndAfterSocialActionsModel>>(
      environment.api + "/leaderboard/socialaction/employeecountsEx",
      {
        StartTime: startTime.toISOString(),
        EndTime: endDate?.toISOString(),
        PageNum: pageNum,
        RecordsPerPage: recordsPerPage,
        GroupIds: groupsIds,
        ProfileIds: profileIds,
      },
    );
  }

  getAdvocacyOrganicData(
    startTime: Date,
    endDate: Date,
    pageNum: number,
    recordsPerPage: number,
    groupsIds: string[],
    profileIds: string[],
  ): Observable<IBaseTableDataModel<AdvocacyOrganicDataModel>> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http.post<IBaseTableDataModel<AdvocacyOrganicDataModel>>(
      environment.api + "/leaderboard/advocacyOrganicData",
      {
        StartTime: startTime.toISOString(),
        EndTime: endDate?.toISOString(),
        PageNum: pageNum,
        RecordsPerPage: recordsPerPage,
        GroupIds: groupsIds,
        ProfileIds: profileIds,
      },
    );
  }

  getUserLeaders(
    startTime: Date,
    endDate: Date,
    pageNum: number,
    recordsPerPage: number,
    groupsIds: string[],
    profileIds: string[],
  ): Observable<IBaseTableDataModel<LeaderboardUserModel>> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http.post<IBaseTableDataModel<LeaderboardUserModel>>(environment.api + "/leaderboard/adminleaders", {
      StartTime: startTime.toISOString(),
      EndTime: endDate?.toISOString(),
      PageNum: pageNum,
      RecordsPerPage: recordsPerPage,
      GroupIds: groupsIds,
      ProfileIds: profileIds,
    });
  }

  getEmployeeLeaders(
    startTime: Date,
    endTime: Date,
    pageNum: number,
    recordsPerPage: number,
    groupsIds: string[],
    profileIds: string[],
  ): Observable<IBaseTableDataModel<LeaderboardEmployeeModel>> {
    if (!startTime) throw new RangeError("missing startTime");

    return this.http
      .post<IBaseTableDataModel<LeaderboardEmployeeModel>>(environment.api + "/Leaderboard/leaders", {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: pageNum,
        RecordsPerPage: recordsPerPage,
        GroupIds: groupsIds,
        ProfileIds: profileIds,
      })
      .pipe(
        map((leaderboard: IBaseTableDataModel<LeaderboardEmployeeModel>) => {
          leaderboard.Items.forEach((emp) => {
            emp.LikeCount = emp.Engagements.filter((eng) => eng.Type === EngagementType.Like).reduce<number>(
              (a, b) => a + b.Count,
              0,
            );

            emp.ShareCount = emp.Engagements.filter((eng) => eng.Type === EngagementType.Share).reduce<number>(
              (a, b) => a + b.Count,
              0,
            );

            emp.CommentCount = emp.Engagements.filter((eng) => eng.Type === EngagementType.Comment).reduce<number>(
              (a, b) => a + b.Count,
              0,
            );

            emp.TotalCount = emp.LikeCount + emp.ShareCount + emp.CommentCount;
          });

          return {
            ...leaderboard,
          };
        }),
      );
  }

  getEmployeesGroupsGraphData() {
    return this.http.get<EmployeeGroupsAnalyticsModel>(environment.api + "/Leaderboard/graphs/employeesXGroup");
  }

  getEmployeeGroupsGrowthData(startTime: Date, endTime: Date) {
    return this.http.post<EmployeeGroupsGrowthGraphModel[]>(
      environment.api + "/Leaderboard/graphs/employeegroupsgrowth",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
      },
    );
  }

  getEmployeeGroupsEngagementData(startTime: Date, endTime: Date, profileIds: string[]) {
    return this.http.post<EmployeeGroupEngagementGraphModel[]>(
      environment.api + "/Leaderboard/graphs/employeegroupsengagement",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
        ProfileIds: profileIds,
      },
    );
  }

  getEmployeeGroupsClientEngagementData(startTime: Date, endTime: Date, profileIds: string[]) {
    return this.http.post<EmployeeGroupEngagementClientGraphModel[]>(
      environment.api + "/Leaderboard/graphs/employeegroupsclientengagement",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
        ProfileIds: profileIds,
      },
    );
  }

  getEmployeeGroupsEngagementRatioData(startTime: Date, endTime: Date, profileIds: string[]) {
    return this.http.post<EmployeeGroupEngagementRatioGraphModel[]>(
      environment.api + "/Leaderboard/graphs/employeegroupsengagementratio",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
        ProfileIds: profileIds,
      },
    );
  }

  getUserShareToEmployeesData(startTime: Date, endTime: Date, profileIds: string[]) {
    return this.http.post<UserShareToEmployeesGraphModel[]>(
      environment.api + "/Leaderboard/graphs/usersharetoemployees",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
        ProfileIds: profileIds,
      },
    );
  }

  getEmployeeInvitationResponseData(startTime: Date, endTime: Date) {
    return this.http.post<EmployeeInvitationResponseGraphModel>(
      environment.api + "/Leaderboard/graphs/employeeInvitationResponse",
      {
        StartTime: startTime.toISOString(),
        EndTime: endTime?.toISOString(),
        PageNum: 0,
      },
    );
  }

  getAnalyticsSettings(): Observable<IAnalysisSettings> {
    return this.http.get<IAnalysisSettings>(environment.api + "/UserSettings/AnalyticsSettings");
  }

  putAnalyticsSettings(model: IAnalysisSettings) {
    return this.http.put(environment.api + "/UserSettings/AnalyticsSettings", model);
  }

  getEarnedMediaValueData(startDate: Date, endDate: Date, profileIds: string[]) {
    return this.http.post<IEarnedMediaValueModel>(environment.api + "/Leaderboard/graphs/earnedmediavalue", {
      StartTime: startDate.toISOString(),
      EndTime: endDate?.toISOString(),
      PageNum: 0,
      ProfileIds: profileIds,
    });
  }

  getFollowersCountData(startDate: Date, endDate: Date) {
    return this.http.post<IFollowerCountsGraphModel[]>(environment.api + "/Leaderboard/graphs/followersCount", {
      StartTime: startDate.toISOString(),
      EndTime: endDate?.toISOString(),
      PageNum: 0,
    });
  }
}
