import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-preview-footer",
  templateUrl: "./preview-footer.component.html",
  styleUrls: ["./preview-footer.component.scss"],
})
export class PreviewFooterComponent implements OnInit {
  @Input() provider: string;

  constructor() {}

  ngOnInit() {}
}
