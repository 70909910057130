import {Injectable, EventEmitter} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {NotificationGroup, NotificationsList, NotificationStatusResponse} from "./notification.interface";
import {tap} from "rxjs/operators";
import {AsyncMessageService} from "@utils/async-message.service";
import {FaviconService} from "@utils/favicon/favicon.service";
import {UserNotificationModel} from "@shared/utils/signalRHubs/hubs.interface";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private _count = 0;
  private baseUrl = environment.api + "/AccountNotification";

  private notifications$: EventEmitter<NotificationGroup> = new EventEmitter();

  private raiseNotification$: EventEmitter<NotificationGroup> = new EventEmitter();
  public notificationCountObservable: BehaviorSubject<number>;

  private isServiceInitialized: boolean;

  constructor(
    private http: HttpClient,
    private faviconService: FaviconService,
    private asyncMessageService: AsyncMessageService,
  ) {
    this.notificationCountObservable = new BehaviorSubject(this._count);
  }

  get count(): number {
    return this._count;
  }

  set count(value) {
    this._count = value;
    this.notificationCountObservable.next(this._count);
    if (value > 0) {
      this.faviconService.setAlertFavicon();
    } else {
      this.faviconService.setDefaultFavicon();
    }
  }

  init() {
    if (this.isServiceInitialized) return;

    this.asyncMessageService.accountHub.OnUserNotificationReceived.subscribe(
      (userNotificationModel: UserNotificationModel) => {
        const model = this.filterNotifications(userNotificationModel.NotificationGroup);

        this.count++;

        this.notifications$.emit(model);
      },
    );

    this.asyncMessageService.accountHub.OnUserNotificationRaise.subscribe(
      (userNotificationModel: UserNotificationModel) => {
        const model = this.filterNotifications(userNotificationModel.NotificationGroup);

        this.raiseNotification$.emit(model);
      },
    );

    this.getStatus().subscribe((response: NotificationStatusResponse) => {
      this.count = response.NotificationPendingCount;
    });

    this.isServiceInitialized = true;
  }

  private filterNotifications(notificationGroup: NotificationGroup): NotificationGroup {
    const isSameType = notificationGroup.Notifications.every((notification) => {
      return notification.NotificationType === notificationGroup.Notifications[0].NotificationType;
    });
    if (isSameType) {
      notificationGroup.Notifications = [notificationGroup.Notifications[0]];
    }
    return notificationGroup;
  }

  onNotification(): EventEmitter<NotificationGroup> {
    return this.notifications$;
  }

  onRaiseNotification(): EventEmitter<NotificationGroup> {
    return this.raiseNotification$;
  }

  list(page: number, size = 10): Observable<NotificationsList> {
    return this.http.post<NotificationsList>(this.baseUrl + "/GetNotifications", {
      PageSize: size,
      PageNumber: page,
    });
  }

  read(id: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/NotificationSeen", {
      IdNotification: id,
    });
  }

  clearAll(): Observable<any> {
    return this.http.delete<any>(this.baseUrl + "/ClearNotifications");
  }

  readAll(): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/SetAllNotificationAsSeen", {});
  }

  unread(id: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/NotificationUnSeen", {
      IdNotification: id,
    });
  }

  check(): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/NotificationChecked", {}).pipe(tap((_) => (this.count = 0)));
  }

  delete(id: string): Observable<any> {
    return this.http.request<any>("DELETE", this.baseUrl + "/RemoveNotification", {
      body: {IdNotification: id},
    });
  }

  getStatus(): Observable<NotificationStatusResponse> {
    return this.http.get<NotificationStatusResponse>(this.baseUrl + "/GetUserNotificationStatus");
  }
}
