import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: "app-autocompleteinput",
  templateUrl: "./autocompleteinput.component.html",
  styleUrls: ["./autocompleteinput.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteinputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteinputComponent implements OnInit, ControlValueAccessor {
  @Input() options: AutoCompleteInfo[];
  @Input() inputDisplayName: string;
  @Input() hasHint: boolean;
  @Input() hint: string;

  ///Its used on the html
  @Input() type: string;

  control = new FormControl();
  value: AutoCompleteInfo;

  private onChange: (_: AutoCompleteInfo) => void = () => {};
  private onTouched: () => void = () => {};
  disabled = false;

  constructor() {}

  writeValue(obj: AutoCompleteInfo): void {
    this.control.setValue(obj);
    this.value = obj;
    this.onChange(this.value);
  }
  registerOnChange(fn: (_: AutoCompleteInfo) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  getDisplayName(option?: AutoCompleteInfo): string {
    return option?.DisplayName ?? "";
  }

  onFocus() {
    if (!this.value?.Custom) this.control.setValue(null);
  }

  onBlur() {
    if (typeof this.control.value === "string") {
      this.writeValue({
        DisplayName: this.control.value,
        Value: this.control.value,
        Custom: true,
      });
    }

    if (this.value == null) return;

    setTimeout(() => {
      if (this.control.value?.Value != null) return;

      const optionExists = this.options.some((x) => x.DisplayName == this.control.value);

      if (!optionExists) {
        this.control.setValue(this.value);
      }
    }, 250);
  }

  onOptionChange() {
    this.writeValue(this.control.value);
  }
}

export interface AutoCompleteInfo {
  DisplayName: string;
  Value: string;

  Custom: boolean;
}
