<div
  *ngIf="activity.LinkedInPost?.FeedDistribution === 'NONE' && !activity.LinkedInPost?.IsSponsoredContent"
  class="flex items-center mx-3 h-10 text-light-base-400 dark:text-dark-base-400 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300">
  <app-icon icon="eye-off" class="w-5 h-5 leading-5"></app-icon>
  <p class="ml-2 truncate text-xs">This post is not shown on the page feed</p>
</div>

<div
  *ngIf="activity.LinkedInPost?.IsSponsoredContent && activity.LinkedInPost?.FeedDistribution !== 'NONE'"
  class="flex items-center mx-3 h-10 text-light-base-400 dark:text-dark-base-400 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300">
  <app-icon icon="ad" class="w-5 h-5 leading-5"></app-icon>
  <p class="ml-2 truncate text-xs">This is a promoted post</p>
</div>

<div
  *ngIf="activity.LinkedInPost?.IsSponsoredContent && activity.LinkedInPost?.FeedDistribution === 'NONE'"
  class="flex items-center mx-3 h-10 text-light-base-400 dark:text-dark-base-400 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300">
  <app-icon icon="ad" class="w-5 h-5 leading-5"></app-icon>
  <p class="ml-2 truncate text-xs">This is a promoted post not shown on the page feed</p>
</div>

<app-activity-header
  [ngClass]="{'linkedin-repost-header': activity.LinkedInPost?.Repost && !activity.Text}"
  [activity]="activity"
  [showBadge]="showBadge"
  [isRepost]="isRepost"
  [permalink]="'https://www.linkedin.com/feed/update/' + activity.Id"
  [showSource]="showSource">
</app-activity-header>

<!-- when activity has not been published yet, we only rely on UrlMetadata, no linkPreview object -->
<!-- TODO: review, might not be true anymore -->
<app-link-preview
  *ngIf="activity.UrlMetadata?.length && (!photos || photos.length === 0) && !video && !linkPreview"
  [activity]="activity"
  [mediaQuality]="mediaQuality"
  [mediaWidth]="mediaWidth"></app-link-preview>

<app-link-preview
  *ngIf="linkPreview && (!photos || photos.length === 0) && !video"
  [linkPreview]="linkPreview"
  [showDescription]="showDescription"
  [showDomain]="showDomain"></app-link-preview>

<app-image-attachment
  *ngIf="photos?.length && !video"
  [image]="photos"
  [activity]="activity"
  [imageQuality]="mediaQuality"
  [imageWidth]="mediaWidth"></app-image-attachment>

<app-video-attachment
  *ngIf="video"
  [video]="video"
  [activity]="activity"
  [showVolumeControl]="showVolumeControl"></app-video-attachment>

<!-- Poll -->
<div
  *ngIf="activity.LinkedInPost?.Poll"
  class="mx-3 p-3 border border-solid border-light-base-300 dark:border-dark-base-300 rounded text-light-base-400 dark:text-dark-base-400 poll-wrapper">
  <div class="font-medium text-light-base-600 dark:text-dark-base-400 poll-title">
    {{ activity.LinkedInPost.Poll.Question }}
  </div>
  <div [ngModel]="selectedOption" class="mt-2" ngDefaultControl>
    <div *ngFor="let option of activity.LinkedInPost?.Poll.Options" class="flex">
      <div class="relative flex w-full rounded mt-1">
        <div
          class="flex items-center justify-between h-8 text-xs font-medium text-light-base-600 dark:text-dark-base-400 px-2 py-0.5 leading-zero rounded bg-gray-100 dark:bg-gray-700"
          [ngStyle]="{'width.%': (option.VoteCount / getPollVoteCount(activity.LinkedInPost.Poll)) * 100}">
          <div class="absolute inline-flex line-clamp-1 font-medium pr-12">{{ option.Text }}</div>
          <div
            class="absolute right-2 inline-flex ml-auto font-bold"
            *ngIf="getPollVoteCount(activity.LinkedInPost.Poll)">
            {{ (option.VoteCount / getPollVoteCount(activity.LinkedInPost.Poll)) * 100 | number: "1.0-0" }}
            %
          </div>
          <div
            class="absolute right-2 inline-flex ml-auto font-medium"
            *ngIf="!getPollVoteCount(activity.LinkedInPost.Poll)">
            0%
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex space-x-2 mt-3 text-xs">
    <div class="font-medium">
      <span class="mr-1">{{ getPollVoteCount(activity.LinkedInPost.Poll) }}</span>
      <span *ngIf="getPollVoteCount(activity.LinkedInPost.Poll) === 1">Vote</span>
      <span *ngIf="getPollVoteCount(activity.LinkedInPost.Poll) !== 1">Votes</span>
    </div>
    <div>•</div>
    <div [innerHtml]="getMissingTime(activity.LinkedInPost.Poll)"></div>
  </div>
</div>
<!-- Carousel -->
<div *ngIf="activity.LinkedInPost?.Document">
  <app-carousel-attachment
    [items]="carousel"
    [activity]="activity"
    [showControls]="true"
    [showIcon]="false"
    [mediaQuality]="75"
    [mediaWidth]="600"
    [showVolumeControl]="false"
    [clickable]="true"
    [downloadUrl]="activity.LinkedInPost?.Document?.DownloadUrl"
    [documentTitle]="activity.LinkedInPost?.Document?.Title"></app-carousel-attachment>
</div>

<div *ngIf="activity.LinkedInPost.ShareMediaCategory === 'CAROUSEL'">
  <app-carousel-attachment
    [items]="carousel"
    [activity]="activity"
    [showControls]="true"
    [showIcon]="false"
    [mediaQuality]="75"
    [mediaWidth]="400"
    [showVolumeControl]="false"
    [clickable]="true"></app-carousel-attachment>
</div>

<!-- Repost -->
<app-linkedin-activity
  *ngIf="activity.LinkedInPost?.Repost"
  class="linkedin-repost"
  [ngClass]="{'without-commentary': activity.LinkedInPost?.Repost && !activity.Text}"
  [activity]="activity.LinkedInPost?.Repost"
  [showBadge]="true"
  [showDomain]="showDomain"
  [showDescription]="showDescription"
  [showSource]="true">
</app-linkedin-activity>
