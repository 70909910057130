<div class="create-schedule shadow-overlay-panel h-full bg-light-base-100 dark:bg-dark-base-0">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400">Notifications</div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        data-test="notifications-dialog-close-button"
        class="block -mr-2.5"></app-icon-button>
    </app-header-right>
  </app-header>
  <div class="flex flex-col min-h-[calc(100%-60px)] min-w-[520px]" data-test="notifications-dialog">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div
        *ngIf="notifications.length"
        class="flex items-center sticky top-0 border border-t-0 border-r-0 border-l-0 border-solid border-light-base-300 dark:border-dark-base-300 z-50 bg-light-base-100 dark:bg-dark-base-0"
        style="height: 50px">
        <ng-container *ngFor="let notificationGroup of notifications; let index = index">
          <div *ngIf="getDate(notificationGroup.NotificationDateTime, index) as date">
            <div *ngIf="index === 0">
              <div class="relative min-w-full">
                <div class="relative flex items-start">
                  <div>
                    <div class="relative px-1">
                      <div
                        class="h-8 w-8 bg-light-base-100 dark:bg-dark-base-0 rounded-full flex items-center justify-center">
                        <app-icon icon="calendar" class="h-4 w-4 text-gray-500"></app-icon>
                      </div>
                    </div>
                  </div>
                  <div class="min-w-0 flex-1 py-1.5">
                    <div class="text-sm">
                      <div class="font-medium text-light-base-400 dark:text-dark-base-400">
                        {{ date }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="flex-1 px-5 text-right">
          <a (click)="clearAll()">Clear All</a>
          <span class="font-medium text-light-base-400 dark:text-dark-base-400 px-2">•</span>
          <a (click)="readAll()">Mark All as Read</a>
        </div>
      </div>
      <div class="flex-1">
        <div class="flow-root overflow-hidden mt-3">
          <ul>
            <li *ngFor="let notificationGroup of notifications; let index = index; let last = last">
              <div class="relative pb-8">
                <span
                  class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-light-base-200 dark:bg-dark-base-200"
                  [class.hidden]="last"
                  aria-hidden="true"></span>
                <!-- Notification Wrapper -->
                <div class="relative flex items-start">
                  <div class="relative min-w-full">
                    <!-- Notifications -->
                    <div class="min-w-0 flex-1">
                      <!-- Scheduled Events -->
                      <app-notification-publishevent
                        (delete)="delete(index)"
                        [dialogRef]="dialogRef"
                        *ngIf="isNotificationGroupAPublishEvent(notificationGroup)"
                        [notificationGroup]="notificationGroup"
                        class="block"></app-notification-publishevent>
                      <!-- System Events -->
                      <app-notification-systemerror
                        (delete)="delete(index)"
                        [dialogRef]="dialogRef"
                        *ngIf="isNotificationGroupASystemError(notificationGroup)"
                        [notificationGroup]="notificationGroup"
                        class="block"></app-notification-systemerror>

                      <app-ic-newconversation-type
                        [dialogRef]="dialogRef"
                        *ngIf="isNotificationGroupANewInboxConversation(notificationGroup)"
                        [notificationGroup]="notificationGroup"
                        class="block">
                      </app-ic-newconversation-type>

                      <app-ic-assignedtome-type
                        [dialogRef]="dialogRef"
                        *ngIf="isNotificationGroupAInboxConversationAssignedToMe(notificationGroup)"
                        [notificationGroup]="notificationGroup"
                        class="block">
                      </app-ic-assignedtome-type>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- Loader -->
        <div class="loader p-10" *ngIf="loading">
          <app-loader></app-loader>
        </div>
        <!-- Placeholder -->
        <div class="empty-state-wrapper p-10" *ngIf="!loading && !notifications.length">
          <div class="empty-state-image notifications"></div>
          <div class="empty-state-text mt-5">No Notifications Right Now</div>
          <p class="text-center mt-2">
            When you start publishing content or collaborate with others, we’ll show the notifications here.
          </p>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>
