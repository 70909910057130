<!-- Active -->
<ng-container *ngIf="!requestNew && !isDisabled">
  <div
    class="relative inline-flex flex-col h-full w-full p-5 box-border bg-light-base-0 dark:bg-dark-base-50 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md">
    <div class="flex items-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-4" *ngIf="cardTitle">
      <app-icon *ngIf="facebookLogo" icon="facebook-logo" class="w-5 h-5 mr-2.5 leading-5" title="Facebook"></app-icon>
      <app-icon
        *ngIf="instagramLogo"
        icon="instagram-logo"
        class="w-5 h-5 mr-2.5 leading-5"
        title="Instagram"></app-icon>
      <app-icon *ngIf="twitterLogo" icon="twitter-logo" class="w-5 h-5 mr-2.5 leading-5" title="Twitter"></app-icon>
      <app-icon *ngIf="linkedinLogo" icon="linkedin-logo" class="w-5 h-5 mr-2.5 leading-5" title="Linkedin"></app-icon>
      {{ cardTitle }}
    </div>
    <div *ngIf="showSupportedChannels" class="mb-2.5">
      <app-icon *ngIf="supportsFacebook" icon="facebook-logo" class="w-5 h-5 leading-5" title="Facebook"></app-icon>
      <app-icon
        *ngIf="supportsInstagram"
        icon="instagram-logo"
        class="w-5 h-5 leading-5 ml-2.5"
        title="Instagram"></app-icon>
      <app-icon *ngIf="supportsTwitter" icon="twitter-logo" class="w-5 h-5 leading-5 ml-2.5" title="Twitter"></app-icon>
      <app-icon
        *ngIf="supportsLinkedin"
        icon="linkedin-logo"
        class="w-5 h-5 leading-5 ml-2.5"
        title="Linkedin"></app-icon>
    </div>
    <div *ngIf="description" class="description text-xs text-light-base-400 dark:text-dark-base-400 font-normal">
      {{ description }}
    </div>
    <div class="absolute bottom-2.5 right-2.5">
      <app-basic-button
        type="connect"
        (buttonClick)="connect.emit()"
        icon="arrow-right"
        data-test="shared-connect-btn"
        >{{ buttonLabel }}</app-basic-button
      >
    </div>
  </div>
</ng-container>
<!-- Disabled -->
<ng-container *ngIf="!requestNew && isDisabled">
  <div
    class="relative inline-flex flex-col h-full w-full bg-opacity-0 border-2 border-dashed border-light-base-300 dark:border-dark-base-300 rounded-md p-5 box-border">
    <div class="flex items-center text-sm text-light-base-600 dark:text-dark-base-400 font-bold mb-4" *ngIf="cardTitle">
      <app-icon *ngIf="facebookLogo" icon="facebook-logo" class="w-5 h-5 mr-2.5 leading-5" title="Facebook"></app-icon>
      <app-icon
        *ngIf="instagramLogo"
        icon="instagram-logo"
        class="w-5 h-5 mr-2.5 leading-5"
        title="Instagram"></app-icon>
      <app-icon *ngIf="twitterLogo" icon="twitter-logo" class="w-5 h-5 mr-2.5 leading-5" title="Twitter"></app-icon>
      <app-icon *ngIf="linkedinLogo" icon="linkedin-logo" class="w-5 h-5 mr-2.5 leading-5" title="Linkedin"></app-icon>
      {{ cardTitle }}
    </div>
    <div *ngIf="showSupportedChannels" class="mb-2.5">
      <app-icon *ngIf="supportsFacebook" icon="facebook-logo" class="w-5 h-5 leading-5" title="Facebook"></app-icon>
      <app-icon
        *ngIf="supportsInstagram"
        icon="instagram-logo"
        class="w-5 h-5 leading-5 ml-2.5"
        title="Instagram"></app-icon>
      <app-icon *ngIf="supportsTwitter" icon="twitter-logo" class="w-5 h-5 leading-5 ml-2.5" title="Twitter"></app-icon>
      <app-icon
        *ngIf="supportsLinkedin"
        icon="linkedin-logo"
        class="w-5 h-5 leading-5 ml-2.5"
        title="Linkedin"></app-icon>
    </div>
    <div *ngIf="description" class="description text-xs text-light-base-400 dark:text-dark-base-400 font-normal">
      {{ description }}
    </div>
    <div class="absolute bottom-2.5 right-2.5" *ngIf="!isDisabled">
      <app-basic-button
        type="connect"
        (buttonClick)="connect.emit()"
        icon="arrow-right"
        data-test="shared-connect-btn"
        >{{ buttonLabel }}</app-basic-button
      >
    </div>
  </div>
</ng-container>
<ng-container *ngIf="requestNew">
  <div
    class="relative inline-flex flex-col h-full w-full bg-opacity-0 border-2 border-dashed border-light-base-300 dark:border-dark-base-300 rounded-md p-5 box-border">
    <div class="flex items-center text-sm text-light-base-400 dark:text-dark-base-400 font-bold mb-4" *ngIf="cardTitle">
      <app-icon icon="plus-square" class="w-5 h-5 leading-5 mr-2.5"></app-icon>
      {{ cardTitle }}
    </div>
    <div *ngIf="description" class="description text-xs text-light-base-400 dark:text-dark-base-400 font-normal">
      {{ description }}
    </div>
    <div class="absolute bottom-2.5 right-2.5">
      <app-basic-button type="connect" (buttonClick)="connect.emit()">Request</app-basic-button>
    </div>
  </div>
</ng-container>
