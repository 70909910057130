import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-placeholder",
  templateUrl: "./placeholder.component.html",
  styleUrls: ["./placeholder.component.scss"],
})
export class PlaceholderComponent implements OnInit {
  @Input() clickable: boolean;

  constructor() {}

  ngOnInit(): void {}
}
