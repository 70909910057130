export enum EngagementType {
  Like = 0,
  Share,
  Comment,
}

export enum EngagementChannel {
  Slack = 0,
  Teams,
  Email,
  Automatic,
}
