<mat-menu #profilesMenu="matMenu" xPosition="before" class="channel-selector-menu selector-menu">
  <div class="px-2.5 pt-1 pb-2 mb-1 border-b border-light-base-300 dark:border-dark-base-300">
    <span *ngIf="profiles?.length > 1">Choose how to interact</span>
    <span *ngIf="profiles?.length === 1">Interacting as:</span>
  </div>
  <button mat-menu-item *ngFor="let profile of profiles" (click)="setDefaultProfile(profile)" class="!px-2">
    <app-profile-picture
      class="flex items-center"
      [pictureSize]="32"
      [picture]="profile.Picture"
      [hasChannel]="true"></app-profile-picture>
    {{ profile.Name }}
  </button>
</mat-menu>
<button *ngIf="defaultProfiles[provider]" mat-button [matMenuTriggerFor]="profilesMenu">
  <app-profile-picture
    [pictureSize]="24"
    [picture]="defaultProfiles[provider]?.Picture"
    [hasChannel]="true"></app-profile-picture>
</button>
