import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-sidenav-header",
  templateUrl: "./sidenav-header.component.html",
  styleUrls: ["./sidenav-header.component.scss"],
})
export class SidenavHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
