import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-channel-gauge",
  templateUrl: "./channel-gauge.component.html",
  styleUrls: ["./channel-gauge.component.scss"],
})
export class ChannelGaugeComponent implements OnInit {
  constructor() {}

  @Input() minValue: number;
  @Input() maxValue: number;

  ngOnInit() {}

  limit(): number {
    return this.maxValue - this.minValue;
  }
}
