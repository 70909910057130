PROGRESS DIALOG

<div *ngFor="let processedProfile of processedProfiles">
  <app-profile-picture
    [picture]="processedProfile.profile.Picture"
    [provider]="processedProfile.profile.Type"
    [hasChannel]="true"
    [showBadge]="false"
    [pictureSize]="60"
    class="mr-1.25"
    [icon]="processedProfile.confirmed ? 'check' : null"></app-profile-picture>

  {{ processedProfile.processed }}
</div>

<app-button (buttonClick)="OnNextClick()" minWidth="116">Next</app-button>
<app-button (buttonClick)="OnFinished()" minWidth="116">Finished</app-button>
