import {Component, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {switchMap, tap} from "rxjs/operators";
import {ProfileTypes} from "../profile-types.enum";
import {Channel, ILinkedPagesForGroupModel, IProfile} from "../profile.interface";
import {ProfileService} from "../profile.service";
import {SelectableChannelsComponent} from "../selectable-channels/selectable-channels.component";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-linked-channels",
  templateUrl: "./linked-channels.component.html",
  styleUrls: ["./linked-channels.component.scss"],
})
export class LinkedChannelsComponent implements OnInit {
  @Input() channel: Channel;
  linkedChannels: IProfile[];
  profilesWithError: IProfile[];

  constructor(
    private profileService: ProfileService,
    private matdialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.linkedChannels = this.channel?.profile?.LinkedProfiles.map((x) => x.ChildProfile) ?? [];
  }

  addLinkedPage() {
    this.profileService
      .list()
      .pipe(untilDestroyed(this))
      .subscribe((profiles) => {
        const filteredProfiles = profiles
          .filter((x) => x.Type == ProfileTypes.Facebook && x.Subtype == "FacebookPage")
          .map((p) => {
            return {
              ...p,
              Selected: this.linkedChannels.some((x) => x.Id == p.Id),
            };
          });

        this.matdialog
          .open(SelectableChannelsComponent, {
            width: "300px",
            data: {
              profiles: filteredProfiles,
              multipleSelectionEnabled: true,
            },
          })
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe((profiles) => {
            const saveModel: ILinkedPagesForGroupModel = {
              GroupProfileId: this.channel.profile.Id,
              PagesProfileIds: profiles.filter((x) => x.Selected).map((x) => x.Id),
            };

            this.profileService
              .setLinkedPagesToFacebookGroup(saveModel)
              .pipe(
                tap((linkedPagesResult) => {
                  this.profilesWithError = linkedPagesResult?.filter((x) => !x.Successful)?.map((x) => x.Profile) ?? [];
                }),
                switchMap((linkedPages) => {
                  return this.profileService.getLinkedPagesToFacebookGroup(this.channel.profile.Id);
                }),
              )
              .pipe(untilDestroyed(this))
              .subscribe((profilesResult: IProfile[]) => {
                this.profileService.list(null, false).pipe(untilDestroyed(this)).subscribe();
                this.linkedChannels = profilesResult;
              });
          });
      });
  }
}
