import {BaseMediaValidation} from "./BaseMediaValidation";

export class InstagramMediaValidation extends BaseMediaValidation {
  AspectRatioError: boolean;
  SizeError: boolean;
  PhotoDimensionError: boolean;
  VideoDimensionError: boolean;
  InvalidExtension: boolean;
  InvalidVideoDuration: boolean;
  VideoAspectRatioError: boolean;

  constructor() {
    super();
  }

  IsValid(): boolean {
    return (
      !this.AspectRatioError &&
      !this.SizeError &&
      !this.PhotoDimensionError &&
      !this.VideoDimensionError &&
      !this.InvalidExtension &&
      !this.InvalidVideoDuration &&
      !this.VideoAspectRatioError
    );
  }
}
