import {Component, Input} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";
import {IconableButton} from "@shared/layout/basic-button/IconableButton";

@UntilDestroy()
@Component({
  selector: "app-sidenav-item",
  templateUrl: "./sidenav-item.component.html",
  styleUrls: ["./sidenav-item.component.scss"],
})
export class SidenavItemComponent extends IconableButton {
  @Input() active: boolean;
  @Input() counter: number;

  constructor() {
    super();
  }
}
