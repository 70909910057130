import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {environment} from "@environments/environment";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-dialog-card",
  templateUrl: "./dialog-card.component.html",
  styleUrls: ["./dialog-card.component.scss"],
})
export class DialogCardComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() description: string;
  @Input() showSupportedChannels: boolean;
  @Input() supportsFacebook: boolean;
  @Input() supportsTwitter: boolean;
  @Input() supportsInstagram: boolean;
  @Input() supportsLinkedin: boolean;
  @Input() facebookLogo: boolean;
  @Input() instagramLogo: boolean;
  @Input() twitterLogo: boolean;
  @Input() linkedinLogo: boolean;
  @Input() rssLogo: boolean;
  @Input() requestNew: boolean;
  @Input() buttonLabel = "Connect";
  @Input() isDisabled: boolean;
  @Output() public connect: EventEmitter<any> = new EventEmitter();

  isProd = environment.production;

  constructor() {}

  ngOnInit() {}
}
