<div class="flex date-range">
  <div class="w-[200px]">
    <mat-selection-list [multiple]="false" [(ngModel)]="selectedPreset">
      <mat-list-option *ngFor="let preset of datePresets" [value]="preset" (click)="onPresetClicked(preset)">
        <div class="text-sm">{{ preset.DisplayName }}</div>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div
    class="w-[310px] border-l border-r-0 border-t-0 border-b-0 border-solid border-light-base-300 dark:border-dark-base-300">
    <div class="flex flex-col p-4">
      <div class="text-light-base-600 dark:text-dark-base-600">
        {{ timerangeListener.presetSelected$.value | toPresetName }}
      </div>
      <div class="flex mt-2">
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div class="px-2 flex items-center justify-center">-</div>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" />
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-calendar [selected]="selectedRangeValue" (selectedChange)="selectedChange($event)"></mat-calendar>

    <button (click)="applyChanges()">Apply</button>
    <button (click)="cancelChanges()">Cancel</button>
  </div>
</div>
