import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ImageAttachment} from "../image-attachment/image-attachment.interface";
import {ImagePreviewComponent} from "./image-preview.component";
import {IChatBox} from "@shared/utils/chat/chatbox.interface";

@Injectable({
  providedIn: "root",
})
export class ImagePreviewService {
  constructor(
    private dialog: MatDialog,
    public chatbox: IChatBox,
  ) {}

  open(photoAttachment: ImageAttachment[], selectedIndex: number, caption: string, downloadUrl = null) {
    this.chatbox.hideChatBox();
    const photoPreviewEvent: ImagePreviewOpenEvent = {
      imageAttachment: photoAttachment,
      index: selectedIndex,
      caption: caption,
    };

    this.dialog
      .open(ImagePreviewComponent, {
        panelClass: ["image-preview-dialog"],
        hasBackdrop: true,
        autoFocus: true,
        maxWidth: "100vw",
        maxHeight: "100vh",
        height: "100%",
        width: "100%",
        disableClose: true,
        data: {
          event: photoPreviewEvent,
          downloadUrl,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.chatbox.showChatBox();
      });
  }
}

export interface ImagePreviewOpenEvent {
  imageAttachment: ImageAttachment[];
  index: number;
  caption: string;
}
