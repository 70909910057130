import {Component, Inject, OnInit} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-countdown-snackbar",
  template: `
    {{ Message }}
    <span>{{ Seconds }} seconds.</span>
  `,
})
export class CountDownSnackbarComponent implements OnInit {
  Message = "";
  Seconds = 5;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {
    this.Message = data.Message;
    this.Seconds = data.Seconds;
  }

  ngOnInit(): void {
    this.startCountDown();
  }

  countDownFinished() {
    this.data.onCountDownFinished();
  }

  startCountDown() {
    setInterval(() => {
      if (this.Seconds < 1) {
        this.countDownFinished();
      } else {
        this.Seconds = this.Seconds - 1;
      }
    }, 1000);
  }
}
