import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "monthDate",
})
export class MonthDatePipe implements PipeTransform {
  transform(value: moment.Moment, args?: any): any {
    if (args == null || !args[0]) {
      return value.format("MMM YYYY");
    }

    const endDate = args[0].EndDate;

    if (value.format("MMMM") === endDate.format("MMMM")) {
      return value.format("MMM YYYY");
    }

    return value.format("MMM") + " - " + endDate.format("MMM YYYY");
  }
}
