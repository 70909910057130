import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-sidenav-label",
  templateUrl: "./sidenav-label.component.html",
  styleUrls: ["./sidenav-label.component.scss"],
})
export class SidenavLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
