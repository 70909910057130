import {Injectable, Injector} from "@angular/core";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, ComponentType, PortalInjector} from "@angular/cdk/portal";
import {PanelOverlayRef} from "./panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "./panel-overlay.tokens";
import {PanelOverlay} from "./panel-overlay";

interface PanelOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: any;
  position?: "left" | "right";
  disableClose?: boolean;
  parentOverlayRef?: PanelOverlayRef<any>;
}

const DEFAULT_CONFIG: PanelOverlayConfig = {
  hasBackdrop: true,
  position: "right",
};

@Injectable({
  providedIn: "root",
})
export class PanelOverlayService<T extends PanelOverlay> {
  constructor(
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  open(component: ComponentType<T>, config: PanelOverlayConfig = {}): PanelOverlayRef<T> {
    /*
     TODO: figure out how to get the url added as a URL
    */
    const dialogConfig = {...DEFAULT_CONFIG, ...config};
    const overlayRef = this.createOverlay(dialogConfig);
    const dialogRef = new PanelOverlayRef<T>(overlayRef, config.parentOverlayRef);
    dialogRef.componentInstance = this.attachDialogContainer(component, overlayRef, dialogConfig, dialogRef) as T;
    if (!config.disableClose) {
      overlayRef.backdropClick().subscribe((_) => dialogRef.close());
    }
    this.moveParentPanel(config.parentOverlayRef, dialogRef);
    return dialogRef;
  }

  private attachDialogContainer(
    component: ComponentType<T>,
    overlayRef: OverlayRef,
    config: PanelOverlayConfig,
    dialogRef: PanelOverlayRef<T>,
  ) {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(component, null, injector);
    return overlayRef.attach(containerPortal).instance;
  }

  private createOverlay(config: PanelOverlayConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: PanelOverlayConfig): OverlayConfig {
    let positionStrategy = this.overlay.position().global();
    if (config.position === "right") {
      positionStrategy = positionStrategy.right();
    } else {
      positionStrategy = positionStrategy.left();
    }
    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }

  private createInjector(config: PanelOverlayConfig, dialogRef: PanelOverlayRef<T>): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(PanelOverlayRef, dialogRef);
    injectionTokens.set(PANEL_OVERLAY_DATA, config.data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private moveParentPanel(parentPanelOverlayRef: PanelOverlayRef<T>, currentPanelOverlayRef: PanelOverlayRef<T>) {
    let parent = parentPanelOverlayRef;
    let current = currentPanelOverlayRef;
    while (parent) {
      parent.shiftPanel(current);
      current = parent;
      parent = parent.parentOverlayRef;
    }
  }
}
