module.exports = [{
  'name': 'Adlam',
  'astral': '\uD83A[\uDD00-\uDD4B\uDD50-\uDD59\uDD5E\uDD5F]'
}, {
  'name': 'Ahom',
  'astral': '\uD805[\uDF00-\uDF1A\uDF1D-\uDF2B\uDF30-\uDF46]'
}, {
  'name': 'Anatolian_Hieroglyphs',
  'astral': '\uD811[\uDC00-\uDE46]'
}, {
  'name': 'Arabic',
  'bmp': '\u0600-\u0604\u0606-\u060B\u060D-\u061A\u061C-\u061E\u0620-\u063F\u0641-\u064A\u0656-\u066F\u0671-\u06DC\u06DE-\u06FF\u0750-\u077F\u0870-\u088E\u0890\u0891\u0898-\u08E1\u08E3-\u08FF\uFB50-\uFBC2\uFBD3-\uFD3D\uFD40-\uFD8F\uFD92-\uFDC7\uFDCF\uFDF0-\uFDFF\uFE70-\uFE74\uFE76-\uFEFC',
  'astral': '\uD803[\uDE60-\uDE7E]|\uD83B[\uDE00-\uDE03\uDE05-\uDE1F\uDE21\uDE22\uDE24\uDE27\uDE29-\uDE32\uDE34-\uDE37\uDE39\uDE3B\uDE42\uDE47\uDE49\uDE4B\uDE4D-\uDE4F\uDE51\uDE52\uDE54\uDE57\uDE59\uDE5B\uDE5D\uDE5F\uDE61\uDE62\uDE64\uDE67-\uDE6A\uDE6C-\uDE72\uDE74-\uDE77\uDE79-\uDE7C\uDE7E\uDE80-\uDE89\uDE8B-\uDE9B\uDEA1-\uDEA3\uDEA5-\uDEA9\uDEAB-\uDEBB\uDEF0\uDEF1]'
}, {
  'name': 'Armenian',
  'bmp': '\u0531-\u0556\u0559-\u058A\u058D-\u058F\uFB13-\uFB17'
}, {
  'name': 'Avestan',
  'astral': '\uD802[\uDF00-\uDF35\uDF39-\uDF3F]'
}, {
  'name': 'Balinese',
  'bmp': '\u1B00-\u1B4C\u1B50-\u1B7E'
}, {
  'name': 'Bamum',
  'bmp': '\uA6A0-\uA6F7',
  'astral': '\uD81A[\uDC00-\uDE38]'
}, {
  'name': 'Bassa_Vah',
  'astral': '\uD81A[\uDED0-\uDEED\uDEF0-\uDEF5]'
}, {
  'name': 'Batak',
  'bmp': '\u1BC0-\u1BF3\u1BFC-\u1BFF'
}, {
  'name': 'Bengali',
  'bmp': '\u0980-\u0983\u0985-\u098C\u098F\u0990\u0993-\u09A8\u09AA-\u09B0\u09B2\u09B6-\u09B9\u09BC-\u09C4\u09C7\u09C8\u09CB-\u09CE\u09D7\u09DC\u09DD\u09DF-\u09E3\u09E6-\u09FE'
}, {
  'name': 'Bhaiksuki',
  'astral': '\uD807[\uDC00-\uDC08\uDC0A-\uDC36\uDC38-\uDC45\uDC50-\uDC6C]'
}, {
  'name': 'Bopomofo',
  'bmp': '\u02EA\u02EB\u3105-\u312F\u31A0-\u31BF'
}, {
  'name': 'Brahmi',
  'astral': '\uD804[\uDC00-\uDC4D\uDC52-\uDC75\uDC7F]'
}, {
  'name': 'Braille',
  'bmp': '\u2800-\u28FF'
}, {
  'name': 'Buginese',
  'bmp': '\u1A00-\u1A1B\u1A1E\u1A1F'
}, {
  'name': 'Buhid',
  'bmp': '\u1740-\u1753'
}, {
  'name': 'Canadian_Aboriginal',
  'bmp': '\u1400-\u167F\u18B0-\u18F5',
  'astral': '\uD806[\uDEB0-\uDEBF]'
}, {
  'name': 'Carian',
  'astral': '\uD800[\uDEA0-\uDED0]'
}, {
  'name': 'Caucasian_Albanian',
  'astral': '\uD801[\uDD30-\uDD63\uDD6F]'
}, {
  'name': 'Chakma',
  'astral': '\uD804[\uDD00-\uDD34\uDD36-\uDD47]'
}, {
  'name': 'Cham',
  'bmp': '\uAA00-\uAA36\uAA40-\uAA4D\uAA50-\uAA59\uAA5C-\uAA5F'
}, {
  'name': 'Cherokee',
  'bmp': '\u13A0-\u13F5\u13F8-\u13FD\uAB70-\uABBF'
}, {
  'name': 'Chorasmian',
  'astral': '\uD803[\uDFB0-\uDFCB]'
}, {
  'name': 'Common',
  'bmp': '\0-@\\[-`\\{-\xA9\xAB-\xB9\xBB-\xBF\xD7\xF7\u02B9-\u02DF\u02E5-\u02E9\u02EC-\u02FF\u0374\u037E\u0385\u0387\u0605\u060C\u061B\u061F\u0640\u06DD\u08E2\u0964\u0965\u0E3F\u0FD5-\u0FD8\u10FB\u16EB-\u16ED\u1735\u1736\u1802\u1803\u1805\u1CD3\u1CE1\u1CE9-\u1CEC\u1CEE-\u1CF3\u1CF5-\u1CF7\u1CFA\u2000-\u200B\u200E-\u2064\u2066-\u2070\u2074-\u207E\u2080-\u208E\u20A0-\u20C0\u2100-\u2125\u2127-\u2129\u212C-\u2131\u2133-\u214D\u214F-\u215F\u2189-\u218B\u2190-\u2426\u2440-\u244A\u2460-\u27FF\u2900-\u2B73\u2B76-\u2B95\u2B97-\u2BFF\u2E00-\u2E5D\u2FF0-\u2FFB\u3000-\u3004\u3006\u3008-\u3020\u3030-\u3037\u303C-\u303F\u309B\u309C\u30A0\u30FB\u30FC\u3190-\u319F\u31C0-\u31E3\u3220-\u325F\u327F-\u32CF\u32FF\u3358-\u33FF\u4DC0-\u4DFF\uA700-\uA721\uA788-\uA78A\uA830-\uA839\uA92E\uA9CF\uAB5B\uAB6A\uAB6B\uFD3E\uFD3F\uFE10-\uFE19\uFE30-\uFE52\uFE54-\uFE66\uFE68-\uFE6B\uFEFF\uFF01-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\uFF70\uFF9E\uFF9F\uFFE0-\uFFE6\uFFE8-\uFFEE\uFFF9-\uFFFD',
  'astral': '\uD800[\uDD00-\uDD02\uDD07-\uDD33\uDD37-\uDD3F\uDD90-\uDD9C\uDDD0-\uDDFC\uDEE1-\uDEFB]|\uD82F[\uDCA0-\uDCA3]|\uD833[\uDF50-\uDFC3]|\uD834[\uDC00-\uDCF5\uDD00-\uDD26\uDD29-\uDD66\uDD6A-\uDD7A\uDD83\uDD84\uDD8C-\uDDA9\uDDAE-\uDDEA\uDEE0-\uDEF3\uDF00-\uDF56\uDF60-\uDF78]|\uD835[\uDC00-\uDC54\uDC56-\uDC9C\uDC9E\uDC9F\uDCA2\uDCA5\uDCA6\uDCA9-\uDCAC\uDCAE-\uDCB9\uDCBB\uDCBD-\uDCC3\uDCC5-\uDD05\uDD07-\uDD0A\uDD0D-\uDD14\uDD16-\uDD1C\uDD1E-\uDD39\uDD3B-\uDD3E\uDD40-\uDD44\uDD46\uDD4A-\uDD50\uDD52-\uDEA5\uDEA8-\uDFCB\uDFCE-\uDFFF]|\uD83B[\uDC71-\uDCB4\uDD01-\uDD3D]|\uD83C[\uDC00-\uDC2B\uDC30-\uDC93\uDCA0-\uDCAE\uDCB1-\uDCBF\uDCC1-\uDCCF\uDCD1-\uDCF5\uDD00-\uDDAD\uDDE6-\uDDFF\uDE01\uDE02\uDE10-\uDE3B\uDE40-\uDE48\uDE50\uDE51\uDE60-\uDE65\uDF00-\uDFFF]|\uD83D[\uDC00-\uDED7\uDEDD-\uDEEC\uDEF0-\uDEFC\uDF00-\uDF73\uDF80-\uDFD8\uDFE0-\uDFEB\uDFF0]|\uD83E[\uDC00-\uDC0B\uDC10-\uDC47\uDC50-\uDC59\uDC60-\uDC87\uDC90-\uDCAD\uDCB0\uDCB1\uDD00-\uDE53\uDE60-\uDE6D\uDE70-\uDE74\uDE78-\uDE7C\uDE80-\uDE86\uDE90-\uDEAC\uDEB0-\uDEBA\uDEC0-\uDEC5\uDED0-\uDED9\uDEE0-\uDEE7\uDEF0-\uDEF6\uDF00-\uDF92\uDF94-\uDFCA\uDFF0-\uDFF9]|\uDB40[\uDC01\uDC20-\uDC7F]'
}, {
  'name': 'Coptic',
  'bmp': '\u03E2-\u03EF\u2C80-\u2CF3\u2CF9-\u2CFF'
}, {
  'name': 'Cuneiform',
  'astral': '\uD808[\uDC00-\uDF99]|\uD809[\uDC00-\uDC6E\uDC70-\uDC74\uDC80-\uDD43]'
}, {
  'name': 'Cypriot',
  'astral': '\uD802[\uDC00-\uDC05\uDC08\uDC0A-\uDC35\uDC37\uDC38\uDC3C\uDC3F]'
}, {
  'name': 'Cypro_Minoan',
  'astral': '\uD80B[\uDF90-\uDFF2]'
}, {
  'name': 'Cyrillic',
  'bmp': '\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F'
}, {
  'name': 'Deseret',
  'astral': '\uD801[\uDC00-\uDC4F]'
}, {
  'name': 'Devanagari',
  'bmp': '\u0900-\u0950\u0955-\u0963\u0966-\u097F\uA8E0-\uA8FF'
}, {
  'name': 'Dives_Akuru',
  'astral': '\uD806[\uDD00-\uDD06\uDD09\uDD0C-\uDD13\uDD15\uDD16\uDD18-\uDD35\uDD37\uDD38\uDD3B-\uDD46\uDD50-\uDD59]'
}, {
  'name': 'Dogra',
  'astral': '\uD806[\uDC00-\uDC3B]'
}, {
  'name': 'Duployan',
  'astral': '\uD82F[\uDC00-\uDC6A\uDC70-\uDC7C\uDC80-\uDC88\uDC90-\uDC99\uDC9C-\uDC9F]'
}, {
  'name': 'Egyptian_Hieroglyphs',
  'astral': '\uD80C[\uDC00-\uDFFF]|\uD80D[\uDC00-\uDC2E\uDC30-\uDC38]'
}, {
  'name': 'Elbasan',
  'astral': '\uD801[\uDD00-\uDD27]'
}, {
  'name': 'Elymaic',
  'astral': '\uD803[\uDFE0-\uDFF6]'
}, {
  'name': 'Ethiopic',
  'bmp': '\u1200-\u1248\u124A-\u124D\u1250-\u1256\u1258\u125A-\u125D\u1260-\u1288\u128A-\u128D\u1290-\u12B0\u12B2-\u12B5\u12B8-\u12BE\u12C0\u12C2-\u12C5\u12C8-\u12D6\u12D8-\u1310\u1312-\u1315\u1318-\u135A\u135D-\u137C\u1380-\u1399\u2D80-\u2D96\u2DA0-\u2DA6\u2DA8-\u2DAE\u2DB0-\u2DB6\u2DB8-\u2DBE\u2DC0-\u2DC6\u2DC8-\u2DCE\u2DD0-\u2DD6\u2DD8-\u2DDE\uAB01-\uAB06\uAB09-\uAB0E\uAB11-\uAB16\uAB20-\uAB26\uAB28-\uAB2E',
  'astral': '\uD839[\uDFE0-\uDFE6\uDFE8-\uDFEB\uDFED\uDFEE\uDFF0-\uDFFE]'
}, {
  'name': 'Georgian',
  'bmp': '\u10A0-\u10C5\u10C7\u10CD\u10D0-\u10FA\u10FC-\u10FF\u1C90-\u1CBA\u1CBD-\u1CBF\u2D00-\u2D25\u2D27\u2D2D'
}, {
  'name': 'Glagolitic',
  'bmp': '\u2C00-\u2C5F',
  'astral': '\uD838[\uDC00-\uDC06\uDC08-\uDC18\uDC1B-\uDC21\uDC23\uDC24\uDC26-\uDC2A]'
}, {
  'name': 'Gothic',
  'astral': '\uD800[\uDF30-\uDF4A]'
}, {
  'name': 'Grantha',
  'astral': '\uD804[\uDF00-\uDF03\uDF05-\uDF0C\uDF0F\uDF10\uDF13-\uDF28\uDF2A-\uDF30\uDF32\uDF33\uDF35-\uDF39\uDF3C-\uDF44\uDF47\uDF48\uDF4B-\uDF4D\uDF50\uDF57\uDF5D-\uDF63\uDF66-\uDF6C\uDF70-\uDF74]'
}, {
  'name': 'Greek',
  'bmp': '\u0370-\u0373\u0375-\u0377\u037A-\u037D\u037F\u0384\u0386\u0388-\u038A\u038C\u038E-\u03A1\u03A3-\u03E1\u03F0-\u03FF\u1D26-\u1D2A\u1D5D-\u1D61\u1D66-\u1D6A\u1DBF\u1F00-\u1F15\u1F18-\u1F1D\u1F20-\u1F45\u1F48-\u1F4D\u1F50-\u1F57\u1F59\u1F5B\u1F5D\u1F5F-\u1F7D\u1F80-\u1FB4\u1FB6-\u1FC4\u1FC6-\u1FD3\u1FD6-\u1FDB\u1FDD-\u1FEF\u1FF2-\u1FF4\u1FF6-\u1FFE\u2126\uAB65',
  'astral': '\uD800[\uDD40-\uDD8E\uDDA0]|\uD834[\uDE00-\uDE45]'
}, {
  'name': 'Gujarati',
  'bmp': '\u0A81-\u0A83\u0A85-\u0A8D\u0A8F-\u0A91\u0A93-\u0AA8\u0AAA-\u0AB0\u0AB2\u0AB3\u0AB5-\u0AB9\u0ABC-\u0AC5\u0AC7-\u0AC9\u0ACB-\u0ACD\u0AD0\u0AE0-\u0AE3\u0AE6-\u0AF1\u0AF9-\u0AFF'
}, {
  'name': 'Gunjala_Gondi',
  'astral': '\uD807[\uDD60-\uDD65\uDD67\uDD68\uDD6A-\uDD8E\uDD90\uDD91\uDD93-\uDD98\uDDA0-\uDDA9]'
}, {
  'name': 'Gurmukhi',
  'bmp': '\u0A01-\u0A03\u0A05-\u0A0A\u0A0F\u0A10\u0A13-\u0A28\u0A2A-\u0A30\u0A32\u0A33\u0A35\u0A36\u0A38\u0A39\u0A3C\u0A3E-\u0A42\u0A47\u0A48\u0A4B-\u0A4D\u0A51\u0A59-\u0A5C\u0A5E\u0A66-\u0A76'
}, {
  'name': 'Han',
  'bmp': '\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFA6D\uFA70-\uFAD9',
  'astral': '\uD81B[\uDFE2\uDFE3\uDFF0\uDFF1]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879\uD880-\uD883][\uDC00-\uDFFF]|\uD869[\uDC00-\uDEDF\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF38\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|\uD87E[\uDC00-\uDE1D]|\uD884[\uDC00-\uDF4A]'
}, {
  'name': 'Hangul',
  'bmp': '\u1100-\u11FF\u302E\u302F\u3131-\u318E\u3200-\u321E\u3260-\u327E\uA960-\uA97C\uAC00-\uD7A3\uD7B0-\uD7C6\uD7CB-\uD7FB\uFFA0-\uFFBE\uFFC2-\uFFC7\uFFCA-\uFFCF\uFFD2-\uFFD7\uFFDA-\uFFDC'
}, {
  'name': 'Hanifi_Rohingya',
  'astral': '\uD803[\uDD00-\uDD27\uDD30-\uDD39]'
}, {
  'name': 'Hanunoo',
  'bmp': '\u1720-\u1734'
}, {
  'name': 'Hatran',
  'astral': '\uD802[\uDCE0-\uDCF2\uDCF4\uDCF5\uDCFB-\uDCFF]'
}, {
  'name': 'Hebrew',
  'bmp': '\u0591-\u05C7\u05D0-\u05EA\u05EF-\u05F4\uFB1D-\uFB36\uFB38-\uFB3C\uFB3E\uFB40\uFB41\uFB43\uFB44\uFB46-\uFB4F'
}, {
  'name': 'Hiragana',
  'bmp': '\u3041-\u3096\u309D-\u309F',
  'astral': '\uD82C[\uDC01-\uDD1F\uDD50-\uDD52]|\uD83C\uDE00'
}, {
  'name': 'Imperial_Aramaic',
  'astral': '\uD802[\uDC40-\uDC55\uDC57-\uDC5F]'
}, {
  'name': 'Inherited',
  'bmp': '\u0300-\u036F\u0485\u0486\u064B-\u0655\u0670\u0951-\u0954\u1AB0-\u1ACE\u1CD0-\u1CD2\u1CD4-\u1CE0\u1CE2-\u1CE8\u1CED\u1CF4\u1CF8\u1CF9\u1DC0-\u1DFF\u200C\u200D\u20D0-\u20F0\u302A-\u302D\u3099\u309A\uFE00-\uFE0F\uFE20-\uFE2D',
  'astral': '\uD800[\uDDFD\uDEE0]|\uD804\uDF3B|\uD833[\uDF00-\uDF2D\uDF30-\uDF46]|\uD834[\uDD67-\uDD69\uDD7B-\uDD82\uDD85-\uDD8B\uDDAA-\uDDAD]|\uDB40[\uDD00-\uDDEF]'
}, {
  'name': 'Inscriptional_Pahlavi',
  'astral': '\uD802[\uDF60-\uDF72\uDF78-\uDF7F]'
}, {
  'name': 'Inscriptional_Parthian',
  'astral': '\uD802[\uDF40-\uDF55\uDF58-\uDF5F]'
}, {
  'name': 'Javanese',
  'bmp': '\uA980-\uA9CD\uA9D0-\uA9D9\uA9DE\uA9DF'
}, {
  'name': 'Kaithi',
  'astral': '\uD804[\uDC80-\uDCC2\uDCCD]'
}, {
  'name': 'Kannada',
  'bmp': '\u0C80-\u0C8C\u0C8E-\u0C90\u0C92-\u0CA8\u0CAA-\u0CB3\u0CB5-\u0CB9\u0CBC-\u0CC4\u0CC6-\u0CC8\u0CCA-\u0CCD\u0CD5\u0CD6\u0CDD\u0CDE\u0CE0-\u0CE3\u0CE6-\u0CEF\u0CF1\u0CF2'
}, {
  'name': 'Katakana',
  'bmp': '\u30A1-\u30FA\u30FD-\u30FF\u31F0-\u31FF\u32D0-\u32FE\u3300-\u3357\uFF66-\uFF6F\uFF71-\uFF9D',
  'astral': '\uD82B[\uDFF0-\uDFF3\uDFF5-\uDFFB\uDFFD\uDFFE]|\uD82C[\uDC00\uDD20-\uDD22\uDD64-\uDD67]'
}, {
  'name': 'Kayah_Li',
  'bmp': '\uA900-\uA92D\uA92F'
}, {
  'name': 'Kharoshthi',
  'astral': '\uD802[\uDE00-\uDE03\uDE05\uDE06\uDE0C-\uDE13\uDE15-\uDE17\uDE19-\uDE35\uDE38-\uDE3A\uDE3F-\uDE48\uDE50-\uDE58]'
}, {
  'name': 'Khitan_Small_Script',
  'astral': '\uD81B\uDFE4|\uD822[\uDF00-\uDFFF]|\uD823[\uDC00-\uDCD5]'
}, {
  'name': 'Khmer',
  'bmp': '\u1780-\u17DD\u17E0-\u17E9\u17F0-\u17F9\u19E0-\u19FF'
}, {
  'name': 'Khojki',
  'astral': '\uD804[\uDE00-\uDE11\uDE13-\uDE3E]'
}, {
  'name': 'Khudawadi',
  'astral': '\uD804[\uDEB0-\uDEEA\uDEF0-\uDEF9]'
}, {
  'name': 'Lao',
  'bmp': '\u0E81\u0E82\u0E84\u0E86-\u0E8A\u0E8C-\u0EA3\u0EA5\u0EA7-\u0EBD\u0EC0-\u0EC4\u0EC6\u0EC8-\u0ECD\u0ED0-\u0ED9\u0EDC-\u0EDF'
}, {
  'name': 'Latin',
  'bmp': 'A-Za-z\xAA\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u02E0-\u02E4\u1D00-\u1D25\u1D2C-\u1D5C\u1D62-\u1D65\u1D6B-\u1D77\u1D79-\u1DBE\u1E00-\u1EFF\u2071\u207F\u2090-\u209C\u212A\u212B\u2132\u214E\u2160-\u2188\u2C60-\u2C7F\uA722-\uA787\uA78B-\uA7CA\uA7D0\uA7D1\uA7D3\uA7D5-\uA7D9\uA7F2-\uA7FF\uAB30-\uAB5A\uAB5C-\uAB64\uAB66-\uAB69\uFB00-\uFB06\uFF21-\uFF3A\uFF41-\uFF5A',
  'astral': '\uD801[\uDF80-\uDF85\uDF87-\uDFB0\uDFB2-\uDFBA]|\uD837[\uDF00-\uDF1E]'
}, {
  'name': 'Lepcha',
  'bmp': '\u1C00-\u1C37\u1C3B-\u1C49\u1C4D-\u1C4F'
}, {
  'name': 'Limbu',
  'bmp': '\u1900-\u191E\u1920-\u192B\u1930-\u193B\u1940\u1944-\u194F'
}, {
  'name': 'Linear_A',
  'astral': '\uD801[\uDE00-\uDF36\uDF40-\uDF55\uDF60-\uDF67]'
}, {
  'name': 'Linear_B',
  'astral': '\uD800[\uDC00-\uDC0B\uDC0D-\uDC26\uDC28-\uDC3A\uDC3C\uDC3D\uDC3F-\uDC4D\uDC50-\uDC5D\uDC80-\uDCFA]'
}, {
  'name': 'Lisu',
  'bmp': '\uA4D0-\uA4FF',
  'astral': '\uD807\uDFB0'
}, {
  'name': 'Lycian',
  'astral': '\uD800[\uDE80-\uDE9C]'
}, {
  'name': 'Lydian',
  'astral': '\uD802[\uDD20-\uDD39\uDD3F]'
}, {
  'name': 'Mahajani',
  'astral': '\uD804[\uDD50-\uDD76]'
}, {
  'name': 'Makasar',
  'astral': '\uD807[\uDEE0-\uDEF8]'
}, {
  'name': 'Malayalam',
  'bmp': '\u0D00-\u0D0C\u0D0E-\u0D10\u0D12-\u0D44\u0D46-\u0D48\u0D4A-\u0D4F\u0D54-\u0D63\u0D66-\u0D7F'
}, {
  'name': 'Mandaic',
  'bmp': '\u0840-\u085B\u085E'
}, {
  'name': 'Manichaean',
  'astral': '\uD802[\uDEC0-\uDEE6\uDEEB-\uDEF6]'
}, {
  'name': 'Marchen',
  'astral': '\uD807[\uDC70-\uDC8F\uDC92-\uDCA7\uDCA9-\uDCB6]'
}, {
  'name': 'Masaram_Gondi',
  'astral': '\uD807[\uDD00-\uDD06\uDD08\uDD09\uDD0B-\uDD36\uDD3A\uDD3C\uDD3D\uDD3F-\uDD47\uDD50-\uDD59]'
}, {
  'name': 'Medefaidrin',
  'astral': '\uD81B[\uDE40-\uDE9A]'
}, {
  'name': 'Meetei_Mayek',
  'bmp': '\uAAE0-\uAAF6\uABC0-\uABED\uABF0-\uABF9'
}, {
  'name': 'Mende_Kikakui',
  'astral': '\uD83A[\uDC00-\uDCC4\uDCC7-\uDCD6]'
}, {
  'name': 'Meroitic_Cursive',
  'astral': '\uD802[\uDDA0-\uDDB7\uDDBC-\uDDCF\uDDD2-\uDDFF]'
}, {
  'name': 'Meroitic_Hieroglyphs',
  'astral': '\uD802[\uDD80-\uDD9F]'
}, {
  'name': 'Miao',
  'astral': '\uD81B[\uDF00-\uDF4A\uDF4F-\uDF87\uDF8F-\uDF9F]'
}, {
  'name': 'Modi',
  'astral': '\uD805[\uDE00-\uDE44\uDE50-\uDE59]'
}, {
  'name': 'Mongolian',
  'bmp': '\u1800\u1801\u1804\u1806-\u1819\u1820-\u1878\u1880-\u18AA',
  'astral': '\uD805[\uDE60-\uDE6C]'
}, {
  'name': 'Mro',
  'astral': '\uD81A[\uDE40-\uDE5E\uDE60-\uDE69\uDE6E\uDE6F]'
}, {
  'name': 'Multani',
  'astral': '\uD804[\uDE80-\uDE86\uDE88\uDE8A-\uDE8D\uDE8F-\uDE9D\uDE9F-\uDEA9]'
}, {
  'name': 'Myanmar',
  'bmp': '\u1000-\u109F\uA9E0-\uA9FE\uAA60-\uAA7F'
}, {
  'name': 'Nabataean',
  'astral': '\uD802[\uDC80-\uDC9E\uDCA7-\uDCAF]'
}, {
  'name': 'Nandinagari',
  'astral': '\uD806[\uDDA0-\uDDA7\uDDAA-\uDDD7\uDDDA-\uDDE4]'
}, {
  'name': 'New_Tai_Lue',
  'bmp': '\u1980-\u19AB\u19B0-\u19C9\u19D0-\u19DA\u19DE\u19DF'
}, {
  'name': 'Newa',
  'astral': '\uD805[\uDC00-\uDC5B\uDC5D-\uDC61]'
}, {
  'name': 'Nko',
  'bmp': '\u07C0-\u07FA\u07FD-\u07FF'
}, {
  'name': 'Nushu',
  'astral': '\uD81B\uDFE1|\uD82C[\uDD70-\uDEFB]'
}, {
  'name': 'Nyiakeng_Puachue_Hmong',
  'astral': '\uD838[\uDD00-\uDD2C\uDD30-\uDD3D\uDD40-\uDD49\uDD4E\uDD4F]'
}, {
  'name': 'Ogham',
  'bmp': '\u1680-\u169C'
}, {
  'name': 'Ol_Chiki',
  'bmp': '\u1C50-\u1C7F'
}, {
  'name': 'Old_Hungarian',
  'astral': '\uD803[\uDC80-\uDCB2\uDCC0-\uDCF2\uDCFA-\uDCFF]'
}, {
  'name': 'Old_Italic',
  'astral': '\uD800[\uDF00-\uDF23\uDF2D-\uDF2F]'
}, {
  'name': 'Old_North_Arabian',
  'astral': '\uD802[\uDE80-\uDE9F]'
}, {
  'name': 'Old_Permic',
  'astral': '\uD800[\uDF50-\uDF7A]'
}, {
  'name': 'Old_Persian',
  'astral': '\uD800[\uDFA0-\uDFC3\uDFC8-\uDFD5]'
}, {
  'name': 'Old_Sogdian',
  'astral': '\uD803[\uDF00-\uDF27]'
}, {
  'name': 'Old_South_Arabian',
  'astral': '\uD802[\uDE60-\uDE7F]'
}, {
  'name': 'Old_Turkic',
  'astral': '\uD803[\uDC00-\uDC48]'
}, {
  'name': 'Old_Uyghur',
  'astral': '\uD803[\uDF70-\uDF89]'
}, {
  'name': 'Oriya',
  'bmp': '\u0B01-\u0B03\u0B05-\u0B0C\u0B0F\u0B10\u0B13-\u0B28\u0B2A-\u0B30\u0B32\u0B33\u0B35-\u0B39\u0B3C-\u0B44\u0B47\u0B48\u0B4B-\u0B4D\u0B55-\u0B57\u0B5C\u0B5D\u0B5F-\u0B63\u0B66-\u0B77'
}, {
  'name': 'Osage',
  'astral': '\uD801[\uDCB0-\uDCD3\uDCD8-\uDCFB]'
}, {
  'name': 'Osmanya',
  'astral': '\uD801[\uDC80-\uDC9D\uDCA0-\uDCA9]'
}, {
  'name': 'Pahawh_Hmong',
  'astral': '\uD81A[\uDF00-\uDF45\uDF50-\uDF59\uDF5B-\uDF61\uDF63-\uDF77\uDF7D-\uDF8F]'
}, {
  'name': 'Palmyrene',
  'astral': '\uD802[\uDC60-\uDC7F]'
}, {
  'name': 'Pau_Cin_Hau',
  'astral': '\uD806[\uDEC0-\uDEF8]'
}, {
  'name': 'Phags_Pa',
  'bmp': '\uA840-\uA877'
}, {
  'name': 'Phoenician',
  'astral': '\uD802[\uDD00-\uDD1B\uDD1F]'
}, {
  'name': 'Psalter_Pahlavi',
  'astral': '\uD802[\uDF80-\uDF91\uDF99-\uDF9C\uDFA9-\uDFAF]'
}, {
  'name': 'Rejang',
  'bmp': '\uA930-\uA953\uA95F'
}, {
  'name': 'Runic',
  'bmp': '\u16A0-\u16EA\u16EE-\u16F8'
}, {
  'name': 'Samaritan',
  'bmp': '\u0800-\u082D\u0830-\u083E'
}, {
  'name': 'Saurashtra',
  'bmp': '\uA880-\uA8C5\uA8CE-\uA8D9'
}, {
  'name': 'Sharada',
  'astral': '\uD804[\uDD80-\uDDDF]'
}, {
  'name': 'Shavian',
  'astral': '\uD801[\uDC50-\uDC7F]'
}, {
  'name': 'Siddham',
  'astral': '\uD805[\uDD80-\uDDB5\uDDB8-\uDDDD]'
}, {
  'name': 'SignWriting',
  'astral': '\uD836[\uDC00-\uDE8B\uDE9B-\uDE9F\uDEA1-\uDEAF]'
}, {
  'name': 'Sinhala',
  'bmp': '\u0D81-\u0D83\u0D85-\u0D96\u0D9A-\u0DB1\u0DB3-\u0DBB\u0DBD\u0DC0-\u0DC6\u0DCA\u0DCF-\u0DD4\u0DD6\u0DD8-\u0DDF\u0DE6-\u0DEF\u0DF2-\u0DF4',
  'astral': '\uD804[\uDDE1-\uDDF4]'
}, {
  'name': 'Sogdian',
  'astral': '\uD803[\uDF30-\uDF59]'
}, {
  'name': 'Sora_Sompeng',
  'astral': '\uD804[\uDCD0-\uDCE8\uDCF0-\uDCF9]'
}, {
  'name': 'Soyombo',
  'astral': '\uD806[\uDE50-\uDEA2]'
}, {
  'name': 'Sundanese',
  'bmp': '\u1B80-\u1BBF\u1CC0-\u1CC7'
}, {
  'name': 'Syloti_Nagri',
  'bmp': '\uA800-\uA82C'
}, {
  'name': 'Syriac',
  'bmp': '\u0700-\u070D\u070F-\u074A\u074D-\u074F\u0860-\u086A'
}, {
  'name': 'Tagalog',
  'bmp': '\u1700-\u1715\u171F'
}, {
  'name': 'Tagbanwa',
  'bmp': '\u1760-\u176C\u176E-\u1770\u1772\u1773'
}, {
  'name': 'Tai_Le',
  'bmp': '\u1950-\u196D\u1970-\u1974'
}, {
  'name': 'Tai_Tham',
  'bmp': '\u1A20-\u1A5E\u1A60-\u1A7C\u1A7F-\u1A89\u1A90-\u1A99\u1AA0-\u1AAD'
}, {
  'name': 'Tai_Viet',
  'bmp': '\uAA80-\uAAC2\uAADB-\uAADF'
}, {
  'name': 'Takri',
  'astral': '\uD805[\uDE80-\uDEB9\uDEC0-\uDEC9]'
}, {
  'name': 'Tamil',
  'bmp': '\u0B82\u0B83\u0B85-\u0B8A\u0B8E-\u0B90\u0B92-\u0B95\u0B99\u0B9A\u0B9C\u0B9E\u0B9F\u0BA3\u0BA4\u0BA8-\u0BAA\u0BAE-\u0BB9\u0BBE-\u0BC2\u0BC6-\u0BC8\u0BCA-\u0BCD\u0BD0\u0BD7\u0BE6-\u0BFA',
  'astral': '\uD807[\uDFC0-\uDFF1\uDFFF]'
}, {
  'name': 'Tangsa',
  'astral': '\uD81A[\uDE70-\uDEBE\uDEC0-\uDEC9]'
}, {
  'name': 'Tangut',
  'astral': '\uD81B\uDFE0|[\uD81C-\uD820][\uDC00-\uDFFF]|\uD821[\uDC00-\uDFF7]|\uD822[\uDC00-\uDEFF]|\uD823[\uDD00-\uDD08]'
}, {
  'name': 'Telugu',
  'bmp': '\u0C00-\u0C0C\u0C0E-\u0C10\u0C12-\u0C28\u0C2A-\u0C39\u0C3C-\u0C44\u0C46-\u0C48\u0C4A-\u0C4D\u0C55\u0C56\u0C58-\u0C5A\u0C5D\u0C60-\u0C63\u0C66-\u0C6F\u0C77-\u0C7F'
}, {
  'name': 'Thaana',
  'bmp': '\u0780-\u07B1'
}, {
  'name': 'Thai',
  'bmp': '\u0E01-\u0E3A\u0E40-\u0E5B'
}, {
  'name': 'Tibetan',
  'bmp': '\u0F00-\u0F47\u0F49-\u0F6C\u0F71-\u0F97\u0F99-\u0FBC\u0FBE-\u0FCC\u0FCE-\u0FD4\u0FD9\u0FDA'
}, {
  'name': 'Tifinagh',
  'bmp': '\u2D30-\u2D67\u2D6F\u2D70\u2D7F'
}, {
  'name': 'Tirhuta',
  'astral': '\uD805[\uDC80-\uDCC7\uDCD0-\uDCD9]'
}, {
  'name': 'Toto',
  'astral': '\uD838[\uDE90-\uDEAE]'
}, {
  'name': 'Ugaritic',
  'astral': '\uD800[\uDF80-\uDF9D\uDF9F]'
}, {
  'name': 'Vai',
  'bmp': '\uA500-\uA62B'
}, {
  'name': 'Vithkuqi',
  'astral': '\uD801[\uDD70-\uDD7A\uDD7C-\uDD8A\uDD8C-\uDD92\uDD94\uDD95\uDD97-\uDDA1\uDDA3-\uDDB1\uDDB3-\uDDB9\uDDBB\uDDBC]'
}, {
  'name': 'Wancho',
  'astral': '\uD838[\uDEC0-\uDEF9\uDEFF]'
}, {
  'name': 'Warang_Citi',
  'astral': '\uD806[\uDCA0-\uDCF2\uDCFF]'
}, {
  'name': 'Yezidi',
  'astral': '\uD803[\uDE80-\uDEA9\uDEAB-\uDEAD\uDEB0\uDEB1]'
}, {
  'name': 'Yi',
  'bmp': '\uA000-\uA48C\uA490-\uA4C6'
}, {
  'name': 'Zanabazar_Square',
  'astral': '\uD806[\uDE00-\uDE47]'
}];