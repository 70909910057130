import {Component, EventEmitter, HostListener, Input, OnInit, Output} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {IZapierProcessedProfile} from "@shared/composer/composer.component";
import {ZapierService} from "@shared/utils/zapier/zapier.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-configure-zapier",
  templateUrl: "./configure-zapier.component.html",
  styleUrls: ["./configure-zapier.component.scss"],
})
export class ConfigureZapierComponent implements OnInit {
  @Input() processedProfile: IZapierProcessedProfile;

  @Output() Next = new EventEmitter<any>();

  @Output() Skip = new EventEmitter<any>();

  embeddedZapierUrl: SafeResourceUrl;

  zapierInstagramTemplateEmbedUrl = "https://zapier.com/partner/embed/socialweaver/create/373852";

  constructor(
    private zapierService: ZapierService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    const zapierTemplateEmbedUrlWithParams = `${this.zapierInstagramTemplateEmbedUrl}?
                                steps__0__params__profile_id=${this.processedProfile.profile.Id}&
                                steps__1__params__instagramPageId=${this.processedProfile.profile.ExternalId}`;

    this.embeddedZapierUrl = this.sanitizer.bypassSecurityTrustResourceUrl(zapierTemplateEmbedUrlWithParams);
  }

  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e.origin != "https://zapier.com") return;

    const isZapAdded = e.data.event == "zap:unpause";

    if (isZapAdded) {
      console.log("[onMessage] Zap was created");

      this.processedProfile.processed = true;

      this.processedProfile.configured = true;

      this.Next.emit();
    }
  }

  OnSkipClick() {
    this.Skip.emit();
  }
}
