import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {TagService} from "@shared/tags/tag.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Tag} from "@shared/publisher/content.interface";

@UntilDestroy()
@Component({
  selector: "app-tags-filter",
  templateUrl: "./tags-filter.component.html",
  styleUrls: ["./tags-filter.component.scss"],
})
export class TagsFilterComponent implements OnInit, OnChanges {
  @Input() tags: Tag[];
  @Input() selectedTag: Tag;
  @Input() showCounter: boolean;
  @Output() selectedTagChange: EventEmitter<Tag> = new EventEmitter();
  @Input() loading = true;

  constructor(tagService: TagService, changeRef: ChangeDetectorRef) {
    tagService
      .onTagCreatedEvent()
      .pipe(untilDestroyed(this))
      .subscribe((tag) => {
        this.tags.push(tag);
        changeRef.markForCheck();
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags) {
      this.tags = changes.tags.currentValue;
    }
  }

  ngOnInit() {}

  select(tag: Tag) {
    this.selectedTagChange.emit(tag);
  }

  notSelected() {
    return this.tags.some((x) => x.Selected);
  }

  clearFilterWithoutRaisingEvent() {
    this.tags?.forEach((x) => (x.Selected = false));
  }
}
