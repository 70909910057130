<mat-form-field appearance="outline">
  <mat-label>Time</mat-label>
  <input
    matInput
    maxlength="2"
    inputmode="numeric"
    placeholder="HH"
    autocomplete="off"
    [value]="formatHour(model?.hour)"
    (change)="updateHour($any($event).target.value)"
    [readOnly]="readonlyInputs"
    [disabled]="disabled"
    aria-label="Hours"
    (input)="formatInput($any($event).target)"
    (keydown.ArrowUp)="changeHour(hourStep); $event.preventDefault()"
    (keydown.ArrowDown)="changeHour(-hourStep); $event.preventDefault()"
    (click)="onTimeInputClick($event)" />
  <ng-container><span class="text-light-base-600 dark:text-dark-base-600">:</span></ng-container>
  <input
    matInput
    maxlength="2"
    inputmode="numeric"
    placeholder="MM"
    autocomplete="off"
    [value]="formatMinSec(model?.minute)"
    (change)="updateMinute($any($event).target.value)"
    [readOnly]="readonlyInputs"
    [disabled]="disabled"
    aria-label="Minutes"
    (input)="formatInput($any($event).target)"
    (keydown.ArrowUp)="changeMinute(minuteStep); $event.preventDefault()"
    (keydown.ArrowDown)="changeMinute(-minuteStep); $event.preventDefault()"
    (click)="onTimeInputClick($event)" />
  <button
    type="button"
    mat-icon-button
    matSuffix
    [disabled]="disabled"
    [class.disabled]="disabled"
    (click)="toggleMeridian()"
    class="ml-4">
    <span class="block text-sm font-sans font-normal text-light-base-600 dark:text-dark-base-400 ampm"
      ><ng-container *ngIf="model && model.hour >= 12; else am">PM</ng-container>
      <ng-template #am>AM</ng-template></span
    >
  </button>
</mat-form-field>
