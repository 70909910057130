import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SidenavService {
  isDrawerOpen = false;
  isSidenavCollapsed = false;
  inDrawerMode = false;

  toggleSidenavDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  toggleCollapseSidenav() {
    this.isSidenavCollapsed = !this.isSidenavCollapsed;
  }
}
