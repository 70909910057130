import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Content, ContentModel} from "./content.interface";
import {ActivityService} from "@activity/activity.service";
import {map, tap} from "rxjs/operators";
import {TagService} from "@tags/tag.service";
import {LibraryMessageFilterModel} from "@shared/notifications/notification.interface";

@Injectable({
  providedIn: "root",
})
export class LibraryService {
  private created$: Subject<any> = new Subject();
  created: Observable<void> = this.created$.asObservable();
  private baseUrl = environment.api + "/library";

  constructor(
    private httpClient: HttpClient,
    private activityService: ActivityService,
    private tagService: TagService,
  ) {}

  create(content: ContentModel): Observable<Content> {
    content.SkipPushNotification = true;
    return this.httpClient.post<Content>(this.baseUrl, content).pipe(tap(() => this.created$.next(null)));
  }

  publish(content: ContentModel): Observable<Content> {
    content.SkipPushNotification = true;
    return this.httpClient
      .post<Content>(environment.api + "/Publish", content)
      .pipe(tap(() => this.created$.next(null)));
  }

  edit(content: Content): Observable<Content> {
    return this.httpClient
      .post<Content>(this.baseUrl, content)
      .pipe(map((content: Content) => this.activityService.mapActivitiesInContents([content])[0]));
  }

  delete(libraryId: string): Observable<void> {
    return this.httpClient.delete<void>(this.baseUrl + "/" + libraryId);
  }

  getContents(filter: LibraryMessageFilterModel, page = 0, size = 25): Observable<Content[]> {
    return this.httpClient.post<Content[]>(this.baseUrl + "/" + page + "/" + size, filter).pipe(
      map((contents: Content[]) => this.activityService.mapActivitiesInContents(contents)),
      map((contents: Content[]) => this.tagService.mapTagsInContent(contents)),
    );
  }

  getContent(contentId: string): Observable<Content> {
    return this.httpClient.get<Content>(`${this.baseUrl}/${contentId}`);
  }
}
