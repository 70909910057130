<mat-form-field appearance="outline" floatLabel="always" class="search p-0 library-search" style="display: block">
  <mat-label>Search</mat-label>
  <input
    matInput
    type="text"
    placeholder="Search library.."
    autocomplete="off"
    [(ngModel)]="text"
    (change)="search()" />
  <button
    type="button"
    *ngIf="(!text?.length && !submitted) || !submitted"
    (click)="search()"
    class="auth-show-pass"
    mat-icon-button
    matSuffix>
    <mat-icon svgIcon="search" title="Search"></mat-icon>
  </button>
  <button
    type="button"
    *ngIf="text?.length && submitted"
    (click)="clear()"
    class="auth-show-pass"
    mat-icon-button
    matSuffix>
    <mat-icon svgIcon="x" title="Clear"></mat-icon>
  </button>
</mat-form-field>
