<div class="preview-wrapper" data-test="composer-click-outside-to-close-open-menu">
  <div data-test="composer-content-preview-options" *ngIf="currentProfile?.Profile">
    <!-- Preview Options -->
    <div class="preview-options">
      <mat-form-field floatlLabel="never" subscriptSizing="dynamic" appearance="outline">
        <!-- Preview Channel -->
        <mat-select
          *ngIf="currentProfile?.Profile"
          name="profile"
          [(ngModel)]="currentProfile"
          (selectionChange)="onProfileChange()"
          placeholder="Preview As"
          panelClass="channel-preview-selector-list"
          class="preview-channels"
          [compareWith]="compareObjects">
          <mat-select-trigger>
            <app-icon [icon]="iconMap[currentProfile?.Profile?.Type]" class="w-5 h-5"></app-icon>
            <span class="option-title ml-1.5 font-medium">{{ currentProfile?.Profile?.Name }}</span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let channel of selectedProfiles"
            [value]="{Profile: channel.profile, ParentProfile: null}">
            <app-profile-picture
              [picture]="channel.profile.Picture"
              [provider]="channel.profile.Type"
              [hasChannel]="true"
              [showBadge]="true"
              [badgeSize]="12"
              [pictureSize]="32">
            </app-profile-picture>
            <div class="ml-2 font-medium text-light-base-600 dark:text-dark-base-400 text-sm">
              {{ channel.profile.Name }}
            </div>
            <div
              *ngIf="channel.profile.ScreenName"
              class="text-light-base-400 dark:text-dark-base-400 text-[12px] ml-2 truncate">
              &#64;{{ channel.profile.ScreenName }}
            </div>
          </mat-option>
        </mat-select>
        <app-icon
          matSuffix
          icon="selector"
          class="text-light-base-400 dark:text-dark-base-400 w-5 h-5 leading-5"></app-icon>
      </mat-form-field>
      <!-- Preview Channel Placeholder -->
      <div *ngIf="!currentProfile?.Profile" class="preview-channels"></div>
    </div>
  </div>
  <!-- Preview Card -->
  <div class="preview-card" data-test="composer-content-preview-card">
    <div data-test="composer-content-preview-container">
      <div class="relative flex flex-col items-center justify-center w-full" *ngIf="!currentProfile?.Profile">
        <div
          class="flex-1 h-[42px] min-h-[42px] border-dashed border-2 rounded-[4px] w-full border-light-base-400/70 dark:border-dark-base-400/40"></div>
        <div
          class="mt-5 py-10 flex flex-col items-center justify-center text-light-base-400 dark:text-dark-base-400 border-light-base-400/70 dark:border-dark-base-400/40 border-dashed border-2 rounded-[4px] w-full">
          <app-icon icon="eye-off" class="w-8 h-8 text-light-base-400 dark:text-dark-base-400"></app-icon>
          <div class="mt-4 font-medium text-light-base-600 dark:text-dark-base-400">Preview not available</div>
          <div class="mt-2 max-w-xs text-center">Select a channel and add media or text to preview your post.</div>
        </div>
      </div>
      <div class="relative preview mt-5" *ngIf="currentProfile?.Profile">
        <div [class.sharing]="sharing">
          <app-activity
            *ngIf="activityPreview"
            [activity]="activityPreview"
            [showFooter]="false"
            [showBadge]="false"
            [showDescription]="false"
            [showDomain]="true"
            [showCarouselControls]="true"
            [showVolumeControl]="true"
            [isRepost]="false"
            [loading]="loading"
            data-test="composer-post-preview"
            class="activity-preview">
            <app-preview-footer [provider]="provider"></app-preview-footer>
          </app-activity>
        </div>
      </div>
    </div>
  </div>
  <!-- Preview Statement -->
  <div
    *ngIf="currentProfile?.Profile"
    class="select-none flex items-start mt-5 text-light-base-500/90 dark:text-dark-base-500/90 border border-solid border-light-base-400/30 dark:border-dark-base-400/30 rounded p-2 text-xs">
    <app-icon icon="info" class="flex-shrink-0 w-4 h-4"></app-icon>
    <p class="ml-2">The preview above approximates how your content will appear when published.</p>
  </div>
</div>
