import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, Output} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageAttachment} from "@activity/attachments/image-attachment/image-attachment.interface";

@UntilDestroy()
@Component({
  selector: "app-image-preview",
  templateUrl: "./image-preview.component.html",
  styleUrls: ["./image-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
  @Input() images: {src: string; title: string}[] = [];
  @Input() selectedIndex: number = 0;
  @Output() closeGallery = new EventEmitter<void>();

  zoomLevel: number = 1;
  showCaption: boolean = true;

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close();
    } else if (event.key === "ArrowLeft" && this.selectedIndex > 0) {
      this.prevImage();
    } else if (event.key === "ArrowRight" && this.selectedIndex < this.images.length - 1) {
      this.nextImage();
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.event) {
      const caption = this.data.event.caption;
      this.images = this.data.event.imageAttachment.map((media: ImageAttachment) => ({
        src: media.src,
        title: caption,
      }));
      this.selectedIndex = this.data.event.index;
    }
  }

  get currentImage() {
    return this.images[this.selectedIndex];
  }

  nextImage() {
    if (this.selectedIndex < this.images.length - 1) {
      this.selectedIndex++;
    }
  }

  prevImage() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }
  close() {
    this.dialogRef.close();
  }

  toggleZoom(event: MouseEvent) {
    if (this.zoomLevel === 1) {
      const rect = (event.target as HTMLImageElement).getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      const x = (offsetX / rect.width) * 100;
      const y = (offsetY / rect.height) * 100;

      this.zoomLevel = 2;
      (event.target as HTMLImageElement).style.transformOrigin = `${x}% ${y}%`;
    } else {
      this.zoomLevel = 1;
    }
  }

  toggleCaption() {
    this.showCaption = !this.showCaption;
  }
}
