import {Injectable} from "@angular/core";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import moment from "moment/moment";
import {DateRangePreset, TimeFrameRange} from "@shared/utils/time-selector/time-picker/time-struct.interface";
import {StateService} from "@shared/user/state.service";
import {IProfile} from "@shared/channel/profile.interface";
import {ProfileService} from "@shared/channel/profile.service";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {distinctUntilChanged, map, shareReplay} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TimerangeListenerService {
  startDate$: BehaviorSubject<Date>;
  endDate$: BehaviorSubject<Date>;
  presetSelected$: BehaviorSubject<DateRangePreset>;

  constructor(private stateService: StateService) {
    const timeFrameRangeJson = this.stateService.get(this.stateService.dateRangeKey);

    if (timeFrameRangeJson == null) {
      this.startDate$ = new BehaviorSubject<Date>(moment().subtract(7, "days").toDate());
      this.endDate$ = new BehaviorSubject<Date>(moment().toDate());
      this.presetSelected$ = new BehaviorSubject<DateRangePreset>(DateRangePreset.Last7Days);
    } else {
      const timeFrameRange: TimeFrameRange = {
        StartDate: new Date(timeFrameRangeJson.StartDate),
        EndDate: new Date(timeFrameRangeJson.EndDate),
        SelectedDatePreset: timeFrameRangeJson.SelectedDatePreset,
      };

      this.startDate$ = new BehaviorSubject<Date>(timeFrameRange.StartDate);
      this.endDate$ = new BehaviorSubject<Date>(timeFrameRange.EndDate);
      this.presetSelected$ = new BehaviorSubject<DateRangePreset>(timeFrameRange.SelectedDatePreset);
    }
  }

  setTimeFrameRange(timeRange: TimeFrameRange) {
    this.stateService.set(this.stateService.dateRangeKey, timeRange);
    this.startDate$.next(timeRange.StartDate);
    this.endDate$.next(moment(timeRange.EndDate).endOf("D").toDate());
    this.presetSelected$.next(timeRange.SelectedDatePreset);
  }
}

@Injectable({
  providedIn: "root",
})
export class ProfileListenerService {
  profiles$ = new BehaviorSubject<IProfile[]>([]);

  selectedProfiles$: BehaviorSubject<IProfile[]>;

  allProfilesSelected$: Observable<boolean>;

  constructor(
    private stateService: StateService,
    private profileService: ProfileService,
  ) {
    this.profileService.list(null, false).subscribe();

    this.profileService.allProfilesObservable.subscribe((profiles: IProfile[]) => {
      const linkedinPages = profiles.filter((x) => x.Type == ProfileTypes.LinkedIn && x.Subtype == "LinkedInPage");
      this.profiles$.next(linkedinPages);
      const profilesSelectedJson = this.stateService.get(this.stateService.profileFilterKey);

      if (profilesSelectedJson == null) {
        this.selectedProfiles$ = new BehaviorSubject<IProfile[]>(linkedinPages);
      } else {
        this.selectedProfiles$ = new BehaviorSubject<IProfile[]>(profilesSelectedJson);
      }
      this.allProfilesSelected$ = combineLatest([this.profiles$, this.selectedProfiles$]).pipe(
        map(([profiles, selectedProfiles]) =>
          profiles.every((profile) => selectedProfiles.some((sp) => sp.Id == profile.Id)),
        ),
        distinctUntilChanged(),
        shareReplay(1),
      );
    });
  }

  setProfiles(selectedProfiles: IProfile[]) {
    this.stateService.set(this.stateService.profileFilterKey, selectedProfiles);
    this.selectedProfiles$.next(selectedProfiles);
  }
}
