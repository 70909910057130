<div
  class="tag"
  [style.backgroundColor]="tagColor"
  [style.color]="tagFontColor"
  [ngClass]="{
    small: tagSize === 'small',
    medium: tagSize === 'medium',
    large: tagSize === 'large',
    active: active
  }"
  matRipple>
  <span class="name">{{ tagName }}</span>
  <span
    *ngIf="showCounter"
    class="count"
    [ngClass]="{
      single: tagCounter <= 9,
      double: tagCounter > 9 && tagCounter < 99,
      triple: tagCounter > 99,
      quad: tagCounter > 999
    }"
    >{{ tagCounter }}</span
  >
</div>
