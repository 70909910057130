export enum FilterModeEnum {
  NoneSelected,
  Selection,
}

export class SwFieldFilter<T> {
  FilterMode: FilterModeEnum;

  SelectionValues?: T[];
}

export enum EmployeeStatusFilterEnum {
  Active,
  Incomplete,
  Expired,
}

export enum SortingEnum {
  NoneSelected,
  Ascending,
  Descending,
}

export class SwFieldSorting {
  SortingField: string;

  Sorting: SortingEnum;
}
