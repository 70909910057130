<app-activity-header
  [activity]="activity"
  [showBadge]="showBadge"
  [permalink]="activity.InstagramMedia.Url"
  [showSource]="showSource"></app-activity-header>

<app-image-attachment
  *ngIf="photos"
  [image]="photos"
  [activity]="activity"
  [imageQuality]="mediaQuality"
  [imageWidth]="mediaWidth"></app-image-attachment>

<app-video-attachment
  *ngIf="video"
  [video]="video"
  [activity]="activity"
  [showVolumeControl]="showVolumeControl"></app-video-attachment>

<app-carousel-attachment
  *ngIf="carousel"
  [items]="carousel"
  [activity]="activity"
  [showControls]="showCarouselControls"
  [showIcon]="showCarouselIcon"
  [mediaQuality]="mediaQuality"
  [mediaWidth]="mediaWidth"
  [showVolumeControl]="showVolumeControl"></app-carousel-attachment>
