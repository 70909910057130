import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-button-toggle",
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
})
export class ButtonToggleComponent implements OnInit {
  @Input() active: boolean;
  @Input() height: number;

  constructor() {}

  ngOnInit(): void {}
}
