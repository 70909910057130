Profiles
<div *ngFor="let processedProfile of processedProfiles">
  <app-profile-picture
    [picture]="processedProfile.profile.Picture"
    [provider]="processedProfile.profile.Type"
    [hasChannel]="true"
    [showBadge]="true"
    [badgeSize]="8"
    [pictureSize]="60"
    class="mr-1.25"></app-profile-picture>

  Processed: {{ processedProfile.processed }} Configured: {{ processedProfile.configured }} Confirmed:
  {{ processedProfile.confirmed }}
</div>

<p>
  Processed means that the profile was evaluated by the user on the iframe, he either configured the zap or just skipped
  it.
</p>

<p>Configured means the user was able to create a zap on the iframe.</p>

<p>
  Confirmed means the zapier sent us a request to subscribe to our webhooks, this means the zap is enabled successfully.
  This flag should only be set through signal R when we receive a reqwuest from zapier.
</p>

<app-button (buttonClick)="OnFinishedClicked()" minWidth="116">Finished</app-button>
