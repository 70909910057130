import {Inject, Injectable, OnInit} from "@angular/core";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import * as Highcharts from "highcharts";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import {AccessibilityOptions} from "highcharts";
import moment from "moment";
import {combineLatest, Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {ExportMenuItemButton, UserWidget} from "../../widget.interface";

exporting(Highcharts);
exportData(Highcharts);

@UntilDestroy()
@Injectable()
export abstract class BaseGraphComponent implements OnInit {
  abstract elementId: string;
  loading = true;

  abstract options: Highcharts.Options;

  chart: Highcharts.Chart;

  protected constructor(
    protected analyticsService: LeaderboardAnalyticsService,
    protected timeRangeListener: TimerangeListenerService,
    protected profilesListener: ProfileListenerService,
    @Inject("widgetConfig") protected widgetConfig: UserWidget,
  ) {}

  ngOnInit(): void {
    this.options.title.text = this.widgetConfig.displayName;
    this.options.title.x = 20;
    this.options.title.y = 13;
    (this.options.exporting.buttons.contextButton.menuItems as unknown as ExportMenuItemButton[]).push({
      text: "Widget Settings",
      onclick: () => this.onEditChart(this.analyticsService, this.widgetConfig),
    });

    (this.options.exporting.buttons.contextButton.menuItems as unknown as ExportMenuItemButton[]).push({
      text: "Remove Widget",
      onclick: () => this.onRemoveGraph(this.analyticsService, this.widgetConfig),
    });

    this.options.accessibility = this.options.accessibility || {};
    (this.options.accessibility as AccessibilityOptions).enabled = false;

    combineLatest([
      this.timeRangeListener.startDate$,
      this.timeRangeListener.endDate$,
      this.profilesListener.selectedProfiles$,
    ])
      .pipe(
        untilDestroyed(this),
        debounceTime(1500),
        distinctUntilChanged(),
        map(([startDate, endDate, selectedProfiles]) => {
          return {
            startDate: moment(startDate).startOf("day").toDate(),
            endDate: moment(endDate).endOf("day").toDate(),
            profileIds: selectedProfiles?.length > 0 ? selectedProfiles.map((x) => x.ExternalId) : null,
          };
        }),
        switchMap((obj) => this.updateChartData(obj.startDate, obj.endDate, obj.profileIds)),
      )
      .subscribe(() => {
        this.chart = Highcharts.chart(this.elementId, this.options);
      });
  }

  onEditChart(analyticsService: LeaderboardAnalyticsService, userWidget: UserWidget) {
    analyticsService.editWidgetEvent.emit(userWidget);
  }

  onRemoveGraph(analyticsService: LeaderboardAnalyticsService, userWidget: UserWidget) {
    analyticsService.removeWidgetEvent.emit(userWidget);
  }

  setElementId() {
    this.elementId = this.elementId + "-" + this.analyticsService.widgetCounter;

    this.analyticsService.widgetCounter = this.analyticsService.widgetCounter + 1;
  }

  setLoadingState(loading: boolean) {
    this.loading = loading;
  }

  abstract updateChartData(startDate: Date, endDate: Date, profileIds: string[]): Observable<void>;
}
