import {Component, OnInit} from "@angular/core";
import {SidenavService} from "@layout/sidenav/sidenav.service";
import {BreakpointObserver} from "@angular/cdk/layout";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  constructor(
    public sidenavService: SidenavService,
    public breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(["(max-width: 1024px)"])
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result.matches) {
          this.sidenavService.inDrawerMode = true;
        } else {
          this.sidenavService.inDrawerMode = false;
          this.sidenavService.isDrawerOpen = false;
        }
      });
  }
}
