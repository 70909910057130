import {Component, HostBinding, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-tag-circle",
  template: "",
  styleUrls: ["./tag-circle.component.scss"],
})
export class TagCircleComponent implements OnInit {
  @HostBinding("style.backgroundColor")
  @Input()
  color: string;

  constructor() {}

  ngOnInit() {}
}
