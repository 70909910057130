<mat-form-field
  appearance="outline"
  subscriptSizing="fixed"
  [ngClass]="{
    'ng-invalid mat-form-field-invalid mat-form-field-hide-placeholder ng-touched mb-2.5': hasError
  }">
  <mat-label *ngIf="inputLabel && selectedTags?.length <= 0">{{ inputLabel }}</mat-label>
  <mat-label *ngIf="!selectedTags?.length && !inputLabel">Select one or more tags</mat-label>
  <mat-label *ngIf="selectedTags?.length === 1">Selected tag</mat-label>
  <mat-label *ngIf="selectedTags?.length > 1">Selected tags</mat-label>
  <mat-chip-grid data-test="tag-input-chiplist" #chipListTags [disabled]="disabled">
    <mat-chip-row
      *ngIf="showAllContentTag && selectedTags.length === 0 && tagService.allcontentTag.Selected"
      [style.background-color]="tagService.allcontentTag.ColorHex"
      [style.color]="tagService.allcontentTag.FgColorHex"
      [removable]="true"
      (removed)="remove(tagService.allcontentTag)">
      {{ tagService.allcontentTag.Name | lowercase }}
      <mat-icon
        matChipRemove
        svgIcon="x"
        [ngStyle]="{color: tagService.allcontentTag.FgColorHex}"
        title="Remove"></mat-icon>
    </mat-chip-row>
    <mat-chip-row
      *ngFor="let tag of selectedTags"
      [style.background-color]="tag.ColorHex"
      [style.color]="tag.FgColorHex"
      [removable]="true"
      (removed)="remove(tag)">
      {{ tag.Name | lowercase }}
      <mat-icon matChipRemove svgIcon="x" [ngStyle]="{color: tag.FgColorHex}" title="Remove"></mat-icon>
    </mat-chip-row>
    <input
      data-test="tag-input-new-tag-name-input"
      matInput
      maxlength="30"
      (keydown)="onTagNameInput($event)"
      #tagInput
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipListTags"
      #triggerTags="matAutocompleteTrigger"
      (click)="triggerTags.openPanel()"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)" />
  </mat-chip-grid>
  <mat-hint *ngIf="hasError">
    <!-- Error -->
    <div class="mat-error">
      <span>A content tag is required.</span>
    </div>
  </mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionActivated)="optionActivated()"
    (optionSelected)="selected($event)"
    class="tag-selector selectors">
    <ng-container *ngIf="filteredTags | async as tags; else noMatches">
      <mat-option
        *ngIf="showAllContentTag && !tagService.allcontentTag.Selected"
        [value]="tagService.allcontentTag"
        (click)="optionClicked()">
        <div class="flex items-center">
          <div
            class="h-5 w-5 rounded-full"
            [class.new]="!tagService.allcontentTag.Id && enableCreationOfTags"
            [ngStyle]="{background: tagService.allcontentTag.ColorHex}"></div>
          <div class="ml-2 text-light-base-600 dark:text-dark-base-600">
            {{ tagService.allcontentTag.Name | lowercase }}
          </div>
        </div>
      </mat-option>
      <mat-option *ngFor="let tag of tags" [value]="tag" (click)="optionClicked()">
        <div class="flex items-center">
          <div
            class="h-5 w-5 rounded-full"
            [class.new]="!tag.Id && enableCreationOfTags"
            [ngStyle]="{background: tag.ColorHex}"></div>
          <div class="ml-2 text-light-base-600 dark:text-dark-base-600">{{ tag.Name | lowercase }}</div>
          <span style="font-family: monospace; margin-left: 6px" *ngIf="!tag.Id && enableCreationOfTags"
            >(New Tag)</span
          >
        </div>
      </mat-option>
    </ng-container>
    <ng-template #noMatches>
      <mat-option [disabled]="true">No matches found</mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
