import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MediaFile} from "@shared/publisher/content.interface";
import {guid} from "@shared/utils/guid.function";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface PhotoEditorOverlayData {
  src: string;
}

export interface PhotoEditorExportModel {
  File: File;
  MediaFile: MediaFile;
  data: any;
}

export interface PhotoEditorImportModel {
  Media: MediaFile;
  data: any;
}

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-photo-editor",
  templateUrl: "./photo-editor.component.html",
})
export class PhotoEditorComponent implements OnInit {
  @Output() imageExported: EventEmitter<PhotoEditorExportModel> = new EventEmitter<PhotoEditorExportModel>();
  @Output() editorClosed: EventEmitter<any> = new EventEmitter<any>();

  importModel: PhotoEditorImportModel;

  constructor(
    public dialogRef: MatDialogRef<PhotoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {}

  ngOnInit() {
    this.importModel = this.data;
  }

  handleProcess(processEvent) {
    const id = guid();
    const extension = this.importModel.Media.FileName.split(".").pop();
    const fileName = `${id}.${extension}`;
    const file = new File([processEvent.dest], fileName, {
      type: this.importModel.Media.MediaType,
    });

    const returnModel = {
      File: file,
      MediaFile: this.importModel.Media,
      data: this.importModel.data,
    };

    this.dialogRef.close(returnModel);
  }
}
