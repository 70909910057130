import {MediaFileUploaded} from "@shared/publisher/content.interface";

export function setMediaFileMetadata(media: MediaFileUploaded): Promise<MediaFileUploaded> {
  return new Promise<MediaFileUploaded>(function (resolve) {
    const containsImage = media.MediaType?.search("image") > -1;
    const containsVideo = media.MediaType?.search("video") > -1;

    if (!containsImage && !containsVideo) {
      resolve(media);
    }

    if (containsImage) {
      const image = new Image();

      image.src = media.BlobUrl;

      image.onload = function () {
        media.Height = image.height;

        media.Width = image.width;

        resolve(media);
      };
    }

    if (containsVideo) {
      const video = document.createElement("video");

      video.src = media.BlobUrl;

      video.onloadedmetadata = function () {
        media.Height = video.videoHeight;

        media.Width = video.videoWidth;

        media.videoDuration = video.duration;

        resolve(media);
      };
    }
  });
}
