import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {ContentModel, MediaFileUploaded} from "@shared/publisher/content.interface";
import {ISelectedProfile} from "../composer.component";
import {ComposerValidation} from "./ComposerValidation";
import {FacebookMediaValidation} from "./media-validation/FacebookMediaValidation";

export class FacebookComposerValidation extends ComposerValidation {
  public NoContent: boolean;
  public invalidPhotoSizeError: boolean;
  public invalidVideoSizeError: boolean;
  public onlyOneGifAllowedError: boolean;

  constructor() {
    super();
    this.Reset();
  }

  IsValid(contentModel: ContentModel, selectedProfiles: ISelectedProfile[]): boolean {
    let facebookMedia: MediaFileUploaded[] = contentModel.MediaFiles?.filter((file) => file.EnabledForFacebook);

    const anyLinkAttached = contentModel.Links?.some((x) => x.ProfileType == ProfileTypes.Facebook);

    this.NoContent = !contentModel.FacebookText && !facebookMedia.length && !anyLinkAttached;
    this.onlyOneGifAllowedError = facebookMedia.filter((x) => x.MediaType.indexOf("gif") >= 0).length > 1;

    let isValid = !this.NoContent && !this.onlyOneGifAllowedError;

    facebookMedia = contentModel.MediaFiles.filter((file) => file.EnabledForFacebook);

    contentModel.MediaFiles.forEach((element) => {
      if (element.EnabledForFacebook) {
        const facebookValidation = new FacebookMediaValidation();

        if (element.MediaType.startsWith("image")) {
          facebookValidation.PhotoSizeError = element.SizeBytes > this.MbToBytes(4);
        } else if (element.MediaType.startsWith("video")) {
          facebookValidation.VideoSizeError = element.SizeBytes > this.MbToBytes(1000);
        }

        if (isValid) {
          isValid = facebookValidation.IsValid();
        }

        if (!this.invalidPhotoSizeError) {
          this.invalidPhotoSizeError = facebookValidation.PhotoSizeError;
        }

        if (!this.invalidVideoSizeError) {
          this.invalidVideoSizeError = facebookValidation.VideoSizeError;
        }

        element.facebookValidation = facebookValidation;
      }
    });
    return isValid;
  }

  LocalReset() {
    this.NoContent = false;
    this.invalidPhotoSizeError = false;
    this.invalidVideoSizeError = false;
    this.onlyOneGifAllowedError = false;
  }
}
