import {Component, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-button-toggle-group",
  templateUrl: "./button-toggle-group.component.html",
  styleUrls: ["./button-toggle-group.component.scss"],
})
export class ButtonToggleGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
