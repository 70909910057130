import {ComponentType} from "@angular/cdk/portal";
import {ContentAdvocacyOrganicEngagementComponent} from "./linkedin/content-advocacy-organic-engagement/content-advocacy-organic-engagement.component";
import {EmployeeGroupClientEngagementGraphComponent} from "./linkedin/employee-group-client-engagement-graph/employee-group-client-engagement-graph.component";
import {EmployeeGroupEngagementGraphComponent} from "./linkedin/employee-group-engagement-graph/employee-group-engagement-graph.component";
import {EmployeeGroupGraphComponent} from "./linkedin/employee-group-graph/employee-group-graph.component";
import {EmployeeGroupGrowthGraphComponent} from "./linkedin/employee-group-growth-graph/employee-group-growth-graph.component";
import {EmployeeInvitationResponseGraphComponent} from "./linkedin/employee-invitation-response-graph/employee-invitation-response-graph.component";
import {EmployeeLeaderboardComponent} from "./linkedin/employee-leaderboard/employee-leaderboard.component";
import {TopContentCardsComponent} from "./linkedin/top-content-cards/top-content-cards.component";
import {TopContentByEmployeeEngagementComponent} from "./linkedin/top-content/top-content-by-employee-engagement.component";
import {UserLeaderboardComponent} from "./linkedin/user-leaderboard/user-leaderboard.component";
import {UserShareToEmployeesGraphComponent} from "./linkedin/user-share-employee-graph/user-share-employee-graph";
import {EarnedMediaValueGraphComponent} from "./linkedin/earned-media-value-graph/earned-media-value-graph.component";
import {FollowersCountGraphComponent} from "./linkedin/followers-count-graph/followers-count-graph.component";
import {EmployeeGroupEngagementRatioGraphComponent} from "./linkedin/employee-group-engagement-ratio-graph/employee-group-engagement-ratio-graph.component";

//type: used to create the component in the view
//typeIdentifier: An identifier of the type of widget, since type.name is not reliable when minifying.
//imageUrl: Its an image shown on the add widgetdialog.
//name: The default display name shown in the widget.

export const AnalyticsWidgets: AnalyticWidget[] = [
  {
    type: EmployeeLeaderboardComponent,
    typeIdentifier: "0",
    imageUrl: "../../../assets/images/widgets/widget0.png",
    name: "Engagement Leaderboard",
    description: "Table of the top Employees based on their level of engagement.",
    noGroupSetting: false,
  },
  {
    type: UserLeaderboardComponent,
    typeIdentifier: "1",
    imageUrl: "../../../assets/images/widgets/widget1.png",
    name: "Sharing Leaderboard",
    description: "Table of the top SocialWeaver Users based on their level of sharing.",
    noGroupSetting: false,
  },
  {
    type: TopContentCardsComponent,
    typeIdentifier: "2",
    imageUrl: "../../../assets/images/widgets/widget2.png",
    name: "Top Content",
    description: "The most popular Posts shown as a set of cards.",
    noGroupSetting: false,
  },
  {
    type: EmployeeGroupGraphComponent,
    typeIdentifier: "3",
    imageUrl: "../../../assets/images/widgets/widget3.png",
    name: "Employee Groups",
    description: "Donut chart of the number of Employees by Employee Group.",
    noGroupSetting: true,
  },
  {
    type: UserShareToEmployeesGraphComponent,
    typeIdentifier: "4",
    imageUrl: "../../../assets/images/widgets/widget4.png",
    name: "User Shares to Employees",
    description: "Bar chart of counts of content shares by Employee Group. Shows new and existing content stacked.",
    noGroupSetting: false,
  },
  {
    type: EmployeeGroupEngagementGraphComponent,
    typeIdentifier: "5",
    imageUrl: "../../../assets/images/widgets/widget5.png",
    name: "Employee Engagement by Group",
    description: "Bar chart of Employee engagement by Employee Group. Shows like, share, and comment counts stacked.",
    noGroupSetting: true,
  },
  {
    type: EmployeeGroupGrowthGraphComponent,
    typeIdentifier: "6",
    imageUrl: "../../../assets/images/widgets/widget6.png",
    name: "Employee Growth by Group",
    description: "Line chart of the number of Employees per Employee Group over time.",
    noGroupSetting: false,
  },
  {
    type: EmployeeGroupClientEngagementGraphComponent,
    typeIdentifier: "7",
    imageUrl: "../../../assets/images/widgets/widget7.png",
    name: "Engagement by Client",
    description: "Bar chart of ‘clients’ Employees use to generate engagement - Slack, MS Teams, email or automatic.",
    noGroupSetting: false,
  },
  {
    type: ContentAdvocacyOrganicEngagementComponent,
    typeIdentifier: "8",
    imageUrl: "../../../assets/images/widgets/widget8.png",
    name: "Advocacy Generated Engagement",
    description: "Table of the most popular posts by the percentage of engagement that is employee generated.",
    noGroupSetting: false,
  },
  {
    type: EmployeeInvitationResponseGraphComponent,
    typeIdentifier: "9",
    imageUrl: "../../../assets/images/widgets/widget9.png",
    name: "Employee Invitations",
    description: "Donut chart of the Employee responses to invitations.",
    noGroupSetting: false,
  },
  {
    type: TopContentByEmployeeEngagementComponent,
    typeIdentifier: "10",
    imageUrl: "../../../assets/images/widgets/widget10.png",
    name: "Top Content List",
    description: "Table of the top content by the amount of Employee engagement.",
    noGroupSetting: false,
  },
  {
    type: EarnedMediaValueGraphComponent,
    typeIdentifier: "11",
    imageUrl: "../../../assets/images/widgets/widget11.png", // TODO - replace with an appropriate PNG
    name: "Earned Media Value",
    description: "Graph of the earned media value of clicks and impressions of Employee engagement over time.",
    noGroupSetting: false,
  },
  {
    type: FollowersCountGraphComponent,
    typeIdentifier: "12",
    imageUrl: "../../../assets/images/widgets/widget12.png", // TODO - replace with an appropriate PNG
    name: "Followers Count",
    description: "Graph of the number of organic and paid followers over time.",
    noGroupSetting: false,
  },
  {
    type: EmployeeGroupEngagementRatioGraphComponent,
    typeIdentifier: "13",
    imageUrl: "../../../assets/images/widgets/widget13.png",
    name: "Engagement Percentage by Group",
    description: "Bar chart of employee groups and the percentage of engaged employees versus employee count.",
    noGroupSetting: true,
  },
];

export interface UserWidget {
  id: string;
  typeIdentifier: string;
  displayName: string;
  order: number;
  showNoGroup: boolean;
}

export interface AnalyticWidget {
  type: ComponentType<any>;
  typeIdentifier: string;
  imageUrl: string;
  name: string;
  description: string;
  noGroupSetting: boolean;
}

export interface EditWidgetConfig {
  analyticWidget: AnalyticWidget;

  edit: boolean;

  editingWidget: UserWidget;
}

export interface ExportMenuItemButton {
  text: string;
  onclick: () => void;
}

export const defaultNoGroupSettingForWidget: boolean = true;
