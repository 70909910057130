import {Component, Input, OnChanges} from "@angular/core";
import {LinkPreview} from "../attachments/link-preview/link-preview.component";
import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {Activity} from "../activity.model";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-facebook-activity",
  templateUrl: "./facebook-activity.component.html",
  styleUrls: ["./facebook-activity.component.scss"],
})
export class FacebookActivityComponent implements OnChanges {
  @Input() activity: Activity;
  @Input() showSource = true;
  @Input() showReactions: boolean;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showVolumeControl: boolean;
  @Input() sharing: boolean;
  @Input() showBadge = true;
  @Input() showDescription: boolean;
  @Input() showDomain: boolean;

  linkPreview: LinkPreview;
  photos: ImageAttachment[];
  video: VideoAttachment;

  constructor() {}

  ngOnChanges() {
    const anyLink =
      this.activity.FacebookPost?.Attachments?.some((x) => x.Type == "share") || this.activity.FacebookPost?.Link;
    const anyPhoto = this.activity.FacebookPost?.Attachments?.some((x) => x.Type == "photo" || "album");
    const anyVideo = this.activity.FacebookPost?.Attachments?.some(
      (x) => x.Type == "video_inline" || x.Type == "video" || x.Type == "animated_image_video",
    );

    if (anyLink) {
      this.linkPreview = this.activity.FacebookPost.linkPreview;
    } else {
      this.linkPreview = null;
    }
    if (anyPhoto) {
      this.photos = this.activity.FacebookPost.photosAttachment;
    } else {
      this.photos = null;
    }
    if (anyVideo) {
      this.video = this.activity.FacebookPost.videoAttachment;
    } else {
      this.video = null;
    }

    if (this.activity?.FacebookPost?.IsSharing) {
      this.activity.FacebookPost.Original = Object.assign({}, this.activity);

      if (this.activity.FacebookPost.Attachments && this.activity.FacebookPost.Attachments.length) {
        this.activity.FacebookPost.Original.Text = this.activity?.FacebookPost.Attachments[0].Description;

        this.activity.FacebookPost.Original.Comments = [];
      }
    }
  }
}
