import {Component, Input, OnChanges} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-chars-counter",
  templateUrl: "./chars-counter.component.svg",
  styleUrls: ["./chars-counter.component.scss"],
})
export class CharsCounterComponent implements OnChanges {
  @Input() max: number;
  @Input() value: number;

  radius = 9;
  changeOffset = 20;

  constructor() {}

  get dasharray() {
    if (this.value === 0) {
      return;
    }
    const circumference = 2 * Math.PI * this.radius;
    return 100 + (circumference * this.value) / this.max + ", " + circumference;
  }

  get charsLeft(): number {
    return this.max - this.value;
  }

  ngOnChanges() {
    this.radius = this.charsLeft <= this.changeOffset ? 12 : 9;
  }
}
