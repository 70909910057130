import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";
import {Content} from "@publisher/content.interface";
import {PreviewDialogComponent} from "@shared/preview-dialog/preview-dialog.component";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {CalendarSettingsService} from "../../pages/publisher/calendar/calendar-widget/calendar-settings/calendar-settings.service";
import {Activity} from "@activity/activity.model";
import {GroupedPublishedContent, PublishedContent} from "@notifications/notification.interface";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EmployeeInList} from "@shared/channel/profile.interface";

@UntilDestroy()
@Component({
  selector: "app-publisher-activity",
  templateUrl: "./publisher-activity.component.html",
  styleUrls: ["./publisher-activity.component.scss"],
})
export class PublisherActivityComponent implements OnChanges, OnInit {
  @Input() content: Content | PublishedContent;
  @Input() provider: string;
  @Input() openDetail = true;
  @Input() clickable: boolean;
  @Output() closePublisherActivityComponent: EventEmitter<boolean> = new EventEmitter();
  @Input() showTime: boolean;
  @Input() showTags: boolean;
  @Input() showFooter: boolean;
  @Input() showStatus: boolean;
  @Input() showChannels: boolean;
  @Input() showBadge: boolean;
  @Input() showStack: boolean;
  @Input() tagSize: string;
  @Input() pictureSize: number;
  @Input() showCarouselControls: boolean;
  @Input() showCarouselIcon: boolean;
  @Input() showVolumeControl: boolean;
  @Input() profileQuality: number;
  @Input() profileWidth: number;
  @Input() showDomain: boolean;
  @Input() showDescription: boolean;
  @Input() showAdvocates = false;

  activity: Activity;
  status: string;

  employees: EmployeeInList[];

  likeToEmployees: boolean;

  shareToEmployees: boolean;

  employeeGroups: string[];

  constructor(
    private panelOverlay: PanelOverlayService<PreviewDialogComponent>,
    private calendarSettingsService: CalendarSettingsService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    const defaultProvider = this.calendarSettingsService.get().defaultProvider;
    const provider = this.provider || defaultProvider;
    const firstProvider = Object.keys(this.content.Activities)[0];
    this.activity = this.content.Activities[provider]
      ? this.content.Activities[provider]
      : this.content.Activities[firstProvider];

    const scheduledContent = this.content as unknown as GroupedPublishedContent;

    if (scheduledContent.PublishEvents && scheduledContent.PublishEvents.some((x) => x.PublishedAt)) {
      if (scheduledContent.PublishEvents && scheduledContent.PublishEvents.some((x) => x.ErrorMessage)) {
        this.status = "error";
        const profilesWithError = scheduledContent.PublishEvents.filter((x) => x.ErrorMessage).map((x) => x.Profile);
        for (const profileWithError of profilesWithError) {
          const profile = this.content.Profiles.find((x) => x.Id == profileWithError.Id);
          profile.hasError = true;
        }
      } else {
        this.status = "published";
        if (this.content.IsAScheduledShare) {
          this.status = "shared";
        }
      }
    } else if (this.content.Profiles.length < 1) {
      this.status = "error";
    } else if (this.content.IsAScheduledShare) {
      this.status = "scheduledShare";
    } else {
      this.status = "scheduled";
    }
    this.setEmployeeSharingSettings();
  }
  setEmployeeSharingSettings() {
    this.employees = this.content["Employees"];
    this.likeToEmployees = this.content["LikeOnBehalfOfEmployees"];
    this.shareToEmployees = this.content["ShareOnBehalfOfEmployees"];

    if (this.content["EmployeeGroupNames"]) {
      const groupNamesString = this.content["EmployeeGroupNames"];

      const publishedStatus = ["published", "shared", "error"];

      if (publishedStatus.some((x) => x == this.status)) {
        this.employeeGroups = groupNamesString;
      } else if (groupNamesString != "some" && groupNamesString != "all") {
        this.employeeGroups = groupNamesString.split(";");
      } else {
        this.employeeGroups = [];
      }
    } else {
      this.employeeGroups = [];
    }
  }

  openPreviewDialog() {
    if (this.openDetail) {
      this.panelOverlay
        .open(PreviewDialogComponent, {
          position: "left",
          disableClose: true,
          data: {
            content: this.content,
          },
        })
        .afterClosed.pipe(untilDestroyed(this))
        .subscribe((edited: boolean) => {
          this.closePublisherActivityComponent.emit(edited);
        });
    }
  }
}
