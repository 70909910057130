<div class="comments" *ngIf="comments && comments.length">
  <app-instagram-comment
    *ngFor="let comment of comments; let index = index"
    [comment]="comment"
    [activity]="activity"
    (delete)="deleteInstagramComment(index, comment)"></app-instagram-comment>

  <div class="comment more-comments-link" *ngIf="!hideLoadMore">
    <a (click)="loadMoreInstagramComments()">Load more comments</a>
  </div>
</div>
