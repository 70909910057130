import {Injectable, EventEmitter} from "@angular/core";
import {environment} from "@environments/environment";
import {UserService} from "@shared/user/user.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ZapierObject, ZapierTemplate, ZapierZap} from "./zapier.interface";
import {INTERCEPTOR_SKIP_AUTH} from "@shared/auth/auth.interceptor";

@Injectable({
  providedIn: "root",
})
export class ZapierService {
  private zapierAuthenticationUrl: string;

  private zapierBaseApiUrl = "https://api.zapier.com/v1/";

  public zapierAuthenticationCanceled = new EventEmitter<any>();

  public windowReference: Window;

  constructor(
    private userService: UserService,
    private http: HttpClient,
  ) {}

  IsAuthenticated(): Observable<boolean> {
    return this.userService.me().pipe(
      map((user) => {
        return user.ZapierUserToken != null && user.ZapierUserToken.length > 0;
      }),
    );
  }

  getAuthenticationStartUrl(): Observable<string> {
    return this.userService.me().pipe(
      map((user) => {
        const parsedUrl = new URL(window.location.href);
        const baseUrl = parsedUrl.origin;
        const redirectUrl = `${baseUrl}/oauth/zapier-complete?userId=${user.Id}`;

        this.zapierAuthenticationUrl = `https://zapier.com/oauth/authorize?client_id=${environment.zapierClientId}&redirect_uri=${redirectUrl}&response_type=token&scope=zap`;

        return this.zapierAuthenticationUrl;
      }),
    );
  }

  zapierAuthenticationReceived(userId: string, token: string, error: string): Observable<any> {
    const url = environment.api + "/oauth/ProcessZapierAuthentication";

    return this.http.post<any>(url, {
      Token: token,
      Error: error,
      UserId: userId,
    });
  }

  refreshAuthentication(): Observable<any> {
    return this.userService.me(false);
  }

  getZapierAvailableTemplates(): Observable<ZapierTemplate[]> {
    return this.http.get<ZapierTemplate[]>(
      `${this.zapierBaseApiUrl}zap-templates?client_id=${environment.zapierClientId}&limit=25`,
      {
        headers: {
          [INTERCEPTOR_SKIP_AUTH]: "true",
        },
      },
    );
  }

  async getUserZaps(): Promise<ZapierZap[]> {
    const user = await this.userService.me().toPromise();

    return this.http
      .get<ZapierObject>(`${this.zapierBaseApiUrl}zaps`, {
        headers: {
          [INTERCEPTOR_SKIP_AUTH]: "true",
          Authorization: `Bearer ${user.ZapierUserToken}`,
        },
      })
      .pipe(
        map((zapierObjects) => {
          return zapierObjects.objects.filter((x) => x.state != "draft");
        }),
      )
      .toPromise();
  }

  logoutFromZapier() {
    const url = environment.api + "/UserData/LogoutFromZapier";

    return this.http.delete<any>(url);
  }

  closeAuthenticationWindow() {
    this.windowReference.close();
  }
}
