import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent implements OnInit {
  @Input() tagName: string;
  @Input() tagColor: string;
  @Input() tagFontColor: string;
  @Input() tagCounter: number;
  @Input() showCounter = false;
  @Input() tagSize: string;
  @Input() active: boolean;

  constructor() {}

  ngOnInit() {}
}
