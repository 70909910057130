<div
  class="flex text-sm p-4 rounded-md border border-solid border-light-success-100 dark:border-dark-success-100 bg-light-success-100/5 dark:bg-dark-success-100/5">
  <div class="flex-shrink-0 leading-zero">
    <app-icon
      icon="check-circle"
      class="w-5 h-5 leading-zero text-light-success-100 dark:text-dark-success-100"></app-icon>
  </div>
  <div class="ml-3">
    <div class="text-light-success-100 dark:text-dark-success-100 -mt-px">
      <ng-content></ng-content>
    </div>
  </div>
</div>
