import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Activity} from "@activity/activity.model";
import {LanguageService} from "@utils/language-service/language.service";

export interface LinkPreview {
  url?: string;
  description?: string;
  title?: string;
  image?: string;
  domain?: string;
  textLanguage?: string;
}

import {UntilDestroy} from "@ngneat/until-destroy";
import {getDomain} from "@utils/link.function";

@UntilDestroy()
@Component({
  selector: "app-link-preview",
  templateUrl: "./link-preview.component.html",
  styleUrls: ["./link-preview.component.scss"],
})
export class LinkPreviewComponent implements OnChanges {
  @Input() linkPreview: LinkPreview = {};
  @Input() activity: Activity;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showDescription: boolean;
  @Input() showDomain: boolean;
  @Input() isUpdatingImage = false;
  @Input() isUpdatingTitle = false;

  videoUrl: string;
  showVideo: boolean;
  videoRatio: number;

  constructor(private languageService: LanguageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("linkPreview", this.linkPreview);
    if (changes.activity && changes.activity.currentValue) {
      if (changes.activity.currentValue.SourceType == "Facebook") {
        const theLink = changes.activity.currentValue.FacebookPost.Link;
        this.linkPreview.url = theLink.Url;
        this.linkPreview.title = theLink.Caption;
        this.linkPreview.description = theLink.Description;
        this.linkPreview.image = theLink.Picture;
      } else {
        this.SetMetaValuesToComponent(changes.activity.currentValue);
      }
    }
  }

  SetMetaValuesToComponent(activity: Activity) {
    this.linkPreview.url = activity.getMetaTagValue("url");

    this.linkPreview.title = activity.getMetaTagValue("title");

    this.linkPreview.description = activity.getMetaTagValue("description");

    this.linkPreview.image = activity.getMetaTagValue("image");

    this.linkPreview.domain = getDomain(this.linkPreview.url);

    this.linkPreview.textLanguage = this.languageService.detectLanguageOnText(this.linkPreview.title);

    this.videoUrl = activity.getMetaTagValue("video:url");
    if (this.videoUrl) {
      this.videoUrl += "?autoplay=1";
      const height = +activity.getMetaTagValue("video:height");
      const width = +activity.getMetaTagValue("video:width");
      if (height && width) {
        this.videoRatio = (height * 100) / width;
      }
    }
  }

  onClick(event: Event) {
    if (this.videoUrl) {
      event.preventDefault();
      this.showVideo = true;
    }
  }
}
