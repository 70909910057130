import {Component, OnInit, Input, Inject, HostListener} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-zapier-embed",
  templateUrl: "./zapier-embed.component.html",
  styleUrls: ["./zapier-embed.component.scss"],
})
export class ZapierEmbedComponent implements OnInit {
  @Input() zapId: number;
  @Input() isNew = true;
  @Input() loading = true;
  embeddedZapierUrl: SafeResourceUrl;
  createZapEmbedUrl = "https://zapier.com/partner/embed/socialweaver/create/";
  editZapEmbedUrl = "https://zapier.com/app/editor/";

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogReference: MatDialogRef<ZapierEmbedComponent>,
  ) {
    if (!this.zapId) {
      this.zapId = data.zapId;
    }
    this.isNew = data.isNew;
  }

  ngOnInit(): void {
    const baseUrl = this.isNew ? this.createZapEmbedUrl : this.editZapEmbedUrl;
    this.embeddedZapierUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${this.zapId}`);

    setTimeout(() => {
      const iframe = document.getElementById("zapierIframe") as HTMLIFrameElement;
      if (iframe) {
        iframe.onload = () => {
          this.loading = false;
        };
      }
    }, 0);
  }

  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e.origin != "https://zapier.com") return;
    if (e.data.event === "zap:unpause:done") {
      this.dialogReference.close({
        isEnabled: true,
      });
    } else if (e.data.event === "zap:pause") {
      this.dialogReference.close({
        isEnabled: false,
      });
    }
  }
}
