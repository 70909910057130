import {Component, Input, OnChanges} from "@angular/core";
import {Album, ImageAttachment} from "./image-attachment.interface";
import {Activity} from "@activity/activity.model";
import {ImagePreviewService} from "../image-preview/image-preview.service";
import {stripHtml} from "@shared/utils/strip-html.function";
import {UserMediaService} from "@shared/user/user-media.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-image-attachment",
  templateUrl: "./image-attachment.component.html",
  styleUrls: ["./image-attachment.component.scss"],
})
export class ImageAttachmentComponent implements OnChanges {
  @Input() image: ImageAttachment[] = [];
  @Input() album: Album;
  @Input() activity: Activity;
  @Input() imageWidth: number;
  @Input() imageQuality: number;

  public gallery: ImageAttachment[] = [];

  constructor(
    private imagePreviewService: ImagePreviewService,
    private userMediaService: UserMediaService,
  ) {}

  ngOnChanges() {
    this.gallery = this.image.slice(0, 5);
  }

  open(index: number): void {
    if (this.image.some((x) => !(x.height > 0) || !(x.width > 0))) {
      const promises = [];

      for (const image of this.image) {
        promises.push(this.userMediaService.getMediaDimensions(image.src));
      }

      Promise.all(promises).then((x) => {
        for (const image of this.image) {
          const result = x.find((x) => x.url == image.src);

          image.height = result.height;

          image.width = result.width;
        }

        this.imagePreviewService.open(this.image, index, stripHtml(this.activity.Text));
      });
    } else {
      this.imagePreviewService.open(this.image, index, stripHtml(this.activity.Text));
    }
  }
}
