<div
  class="share-to-employee h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0"
  data-test="composer-share-to-employee-container-div">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 ml-2">
        Share Content to Employee
      </div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <div class="flex flex-col min-h-[calc(100%-50px)] min-w-[510px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div
        *ngIf="initialLoad"
        class="flex flex-col items-center justify-center border-2 border-dashed border-light-base-300 dark:border-dark-base-300 text-light-base-400 dark:text-dark-base-400 bg-transparent dark:bg-transparent rounded mt-5 px-8 py-12 m-5">
        <app-loader></app-loader>
      </div>
      <!-- No Employees -->
      <div class="flex-1 p-5" *ngIf="!employees?.length && !initialLoad && !loadingEmployees">
        <!-- Invite Link -->
        <div class="flex flex-col border border-solid border-light-base-300 dark:border-dark-base-300 rounded">
          <div
            class="px-4 py-4 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300 bg-light-base-200 dark:bg-dark-base-200">
            <div class="font-medium text-light-base-600 dark:text-dark-base-400">Invite Link</div>
          </div>
          <div class="flex items-start px-4 py-5">
            <pre class="whitespace-pre-wrap break-all">{{ inviteUrl }}</pre>
            <div class="flex-shrink-0 leading-zero ml-5">
              <app-icon-button
                icon="clipboard"
                title="copy"
                [cdkCopyToClipboard]="inviteUrl"
                (buttonClick)="copyToaster()"></app-icon-button>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center border-2 border-dashed border-light-base-300 dark:border-dark-base-300 text-light-base-400 dark:text-dark-base-400 bg-transparent dark:bg-transparent rounded mt-5 px-8 py-12">
          <app-icon icon="users-3" class="h-8 w-8"></app-icon>
          <div class="mt-4 font-medium text-light-base-600 dark:text-dark-base-400 text-center">
            Increase your reach by leveraging your employees' networks.
          </div>
          <div class="mt-4 text-center">
            Share your content to your employees' LinkedIn timeline directly from the composer. Invite your employees
            using the link above to get started.
          </div>
        </div>
      </div>
      <!-- Employees -->
      <div class="flex-1 p-5" *ngIf="employees?.length && !initialLoad">
        <div
          class="relative inline-flex flex-col h-full w-full p-4 bg-light-base-0 dark:bg-dark-base-0 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md box-border">
          <!-- Options -->
          <div class="flex-1 flex items-center">
            <mat-slide-toggle [checked]="sharing" [(ngModel)]="sharing"> Share</mat-slide-toggle>
            <mat-slide-toggle class="ml-4" [checked]="like" [(ngModel)]="like"> Like</mat-slide-toggle>
          </div>
          <!-- Share Caption -->
          <div class="flex mt-4">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Share Caption</mat-label>
              <textarea matInput [(ngModel)]="shareText" rows="3" [disabled]="!sharing"> </textarea>
            </mat-form-field>
          </div>
        </div>
        <!-- Scroll up to error -->
        <div id="schedule-error"></div>
        <!-- Schedule Sharing -->
        <div
          *ngIf="scheduleEnabled"
          class="schedule-sharing p-4 relative flex flex-col h-full w-full box-border mt-4 bg-light-base-0 dark:bg-dark-base-0 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md"
          [ngClass]="{'opacity-60': !sharing && !like}">
          <mat-slide-toggle
            [disabled]="editingSchedule || (!sharing && !like)"
            [checked]="isScheduled"
            [(ngModel)]="isScheduled">
            Schedule Sharing to Employees
          </mat-slide-toggle>
          <div class="overflow-hidden" [ngClass]="{'h-0': !isScheduled}">
            <!-- Options -->
            <div class="grid grid-cols-2 mt-4">
              <!-- Date -->
              <div
                class="schedule-sharing-date col-span-1 mr-4"
                [ngClass]="{'time-error': scheduleInThePast || scheduleIncomplete}">
                <mat-form-field appearance="outline">
                  <mat-label>Schedule Date</mat-label>
                  <!--suppress CheckEmptyScriptTag -->
                  <input
                    matInput
                    [matDatepicker]="startDatepicker"
                    (dateChange)="onDatepickerChange()"
                    [(ngModel)]="schedule.startAt"
                    [min]="today" />
                  <button type="button" (click)="startDatepicker.open()" mat-icon-button matSuffix>
                    <app-icon
                      icon="calendar"
                      class="w-5 h-5 leading-5 text-light-base-400 dark:text-dark-base-400"></app-icon>
                  </button>
                  <mat-datepicker panelClass="app-datepicker" #startDatepicker></mat-datepicker>
                </mat-form-field>
              </div>
              <!-- Time -->
              <div
                class="schedule-sharing-time col-span-1"
                [ngClass]="{'time-error': scheduleInThePast || scheduleIncomplete}"
                data-test="composer-create-schedule-time-button">
                <app-time-selector
                  [(ngModel)]="schedule.time"
                  (ngModelChange)="onDatepickerChange()"></app-time-selector>
              </div>
              <app-message class="block col-span-2 mt-1" *ngIf="isScheduled && scheduleInThePast"
                >Cannot schedule a share in the past.
              </app-message>
              <app-message class="block col-span-2 mt-1" *ngIf="isScheduled && scheduleIncomplete"
                >Please complete the schedule by filling in both the time and date fields.
              </app-message>
            </div>
            <!-- Timezone -->
            <div class="col-span-2 mt-4" [ngClass]="{'time-error': timezoneEmpty}">
              <app-timezone-selector
                (TimezoneSelected)="OnTimezoneSelected($event)"
                [InitialTimezoneSelected]="schedule.timezoneId"
                type="block"></app-timezone-selector>
              <app-message class="block mt-1" *ngIf="isScheduled && timezoneEmpty"
                >Timezone cannot be empty.
              </app-message>
              <div class="text-right mt-1 ml-0.5 text-[10px] text-light-base-400 dark:text-dark-base-400">
                Update your default
                <a (click)="goToTimeZoneSettings()">timezone settings <span class="mt-0.5">&rarr;</span></a>
              </div>
            </div>
          </div>
        </div>
        <!-- Employee List -->
        <div
          [ngClass]="{'opacity-60': !sharing && !like}"
          class="relative flex flex-col h-full w-full bg-light-base-0 dark:bg-dark-base-0 border border-solid border-b-2 border-light-base-300/90 dark:border-dark-base-300/90 rounded-md box-border mt-4">
          <div
            class="flex items-center justify-between px-4 h-12 max-h-12 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300 rounded-t-md bg-white dark:bg-dark-base-100 sticky top-0 z-50">
            <!-- Loading -->
            <div
              *ngIf="loadingEmployees"
              class="h-1 absolute top-0 left-0 right-0 overflow-hidden bg-light-brand-100/25 dark:bg-dark-brand-100/25">
              <div
                class="employee-table-loading bg-light-brand-100 dark:bg-dark-brand-100 rounded-md absolute bottom-0 top-0 w-1/2"></div>
            </div>
            <div *ngIf="!selectionCount()" class="font-medium text-light-base-600 dark:text-dark-base-400">
              <span>Employees</span>
            </div>
            <div
              *ngIf="selectionCount()"
              class="flex items-center font-medium text-light-base-400 dark:text-dark-base-400">
              <app-icon-button
                icon="x"
                iconSize="20"
                class="-ml-3"
                (buttonClick)="deselectAll(); paginator.firstPage()"></app-icon-button>
              <div class="ml-1">{{ selectionCount() }} of {{ totalEmployees }} selected</div>
            </div>
            <div>
              <div
                *ngIf="allSelected()"
                (click)="deselectAll()"
                class="float-right font-medium text-light-brand-100 dark:text-dark-brand-100 hover:text-light-brand-200 dark:hover:text-dark-brand-200 cursor-pointer select-none"
                [ngClass]="{
                  'pointer-events-none !cursor-not-allowed': !sharing && !like
                }">
                Select None
              </div>
              <div
                *ngIf="!allSelected()"
                (click)="selectAll()"
                class="float-right font-medium cursor-pointer select-none"
                [ngClass]="
                  (!sharing && !like) || this.employeeGroupNames !== 'some'
                    ? '!cursor-not-allowed text-light-base-400 dark:text-dark-base-400'
                    : 'text-light-brand-100 dark:text-dark-brand-100 hover:text-light-brand-200 dark:hover:text-dark-brand-200'
                ">
                Select All
              </div>
            </div>
          </div>
          <div
            *ngIf="groups?.length"
            class="flex px-4 py-3 border-solid border-0 border-b border-light-base-300 dark:border-dark-base-300">
            <div class="flex-1 flex">
              <mat-form-field appearance="outline" class="group-quick-selection">
                <mat-label>Quick Group Selection</mat-label>
                <mat-select
                  panelClass="group-quick-selection-menu"
                  multiple
                  (ngModelChange)="onGroupSelectionChange($event)"
                  [disabled]="!sharing && !like"
                  [(ngModel)]="selectedGroupNames">
                  <mat-option
                    *ngFor="let group of groups"
                    [value]="group.Name"
                    appEmployeeGroupRoleValidator
                    class="permission"
                    [disabled]="!group.UserHasPermissionToShare"
                    [showLock]="!group.UserHasPermissionToShare"
                    [employeeGroupId]="group.Id">
                    {{ group.Name | lowercase }}
                  </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button class="!flex !items-center !justify-center">
                  <app-icon
                    icon="selector"
                    class="text-light-base-400 dark:text-dark-base-400 w-5 h-5 leading-5"></app-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <mat-table [dataSource]="dataSource" [trackBy]="trackById" class="share-to-employee-table">
            <!-- Thumbnail Column -->
            <ng-container matColumnDef="thumbnail">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let employee">
                <app-profile-picture
                  [picture]="employee.Picture"
                  [hasChannel]="true"
                  [mediaWidth]="64"
                  [mediaQuality]="50"
                  pictureSize="32"></app-profile-picture>
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
              <mat-cell *matCellDef="let employee">
                <div class="flex items-center">
                  <div class="flex-1 flex flex-col">
                    <!-- Name -->
                    <div>{{ NameEx(employee) }}</div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>
            <!-- Group Column -->
            <ng-container matColumnDef="group">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let employee">
                <div class="flex items-center">
                  <!-- Groups -->
                  <div class="flex flex-1 items-center flex-wrap -ml-0.5">
                    <div
                      class="text-[10px] leading-[10px] py-1 px-1.5 m-0.5 rounded-3xl text-light-base-400 dark:text-dark-base-400 bg-light-base-200 dark:bg-dark-base-200 border border-solid border-light-base-300 dark:border-light-base-400/20"
                      *ngFor="let group of employee.EmployeeGroups">
                      {{ group.Name }}
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>
            <!-- Toggle Column -->
            <ng-container matColumnDef="options">
              <mat-header-cell *matHeaderCellDef> Toggle</mat-header-cell>
              <mat-cell *matCellDef="let employee">
                <mat-slide-toggle
                  [checked]="isSelected(employee)"
                  (change)="toggleSelection($event, employee)"
                  [disabled]="
                    !userHasPermissionForEmployee(employee) || (!sharing && !like) || employeeGroupNames !== 'some'
                  "
                  [class.locked]="!userHasPermissionForEmployee(employee)">
                </mat-slide-toggle>
              </mat-cell>
            </ng-container>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{
                'employee-selected': isSelected(row),
                'employee-disabled':
                  !userHasPermissionForEmployee(row) || employeeGroupNames !== 'some' || (!sharing && !like),
                'employee-not-selected': !isSelected(row)
              }"></mat-row>
          </mat-table>
          <!-- Table Pagination -->
          <mat-paginator
            #paginator
            class="employee-table-paginator"
            [pageSize]="pageSize"
            [length]="totalEmployees"
            [pageSizeOptions]="[25, 50, 100]"
            [disabled]="!sharing && !like"
            (page)="handlePageEvent($event)"></mat-paginator>
        </div>
      </div>
    </ng-scrollbar>
    <app-footer class="h-[70px] bg-light-base-100 dark:bg-dark-base-0 z-50" *ngIf="employees?.length && !initialLoad">
      <div class="flex-1">
        <app-button (buttonClick)="closeAndSave()" type="block" [disabled]="(!like && !sharing) || !selectionCount()">
          <ng-container *ngIf="shareButton && !isScheduled">Share to employees</ng-container>
          <ng-container *ngIf="!shareButton && !isScheduled">Save</ng-container>
          <ng-container *ngIf="isScheduled">Schedule</ng-container>
        </app-button>
      </div>
    </app-footer>
  </div>
</div>
