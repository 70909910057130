import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {ProfileTypes} from "../profile-types.enum";
import {ThemeService} from "@theme/theme.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-profile-picture",
  templateUrl: "./profile-picture.component.html",
  styleUrls: ["./profile-picture.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent implements OnInit {
  @Input() picture: string;
  @Input() provider: number | string;
  @Input() showBadge: boolean;
  @Input() badgeSize = 16;
  @Input() hasChannel: boolean;
  @Input() pictureSize = 40;
  @Input() hasError: boolean;
  @Input() selected: boolean;
  @Input() selectable: boolean;
  @Input() activeInComposer: boolean;
  @Input() mediaWidth = 80;
  @Input() mediaQuality = 80;
  @Input() icon: string;

  @Input() removable: boolean;

  @Output() removeClicked = new EventEmitter<any>();

  private badgesMap = {
    Facebook: "facebook-badge",
    InstagramAccount: "instagram-badge",
    TwitterAccount: "twitter-badge",
    LinkedIn: "linkedin-badge",
    SyndicationFeed: "rss-badge",
  };

  constructor(
    public themeService: ThemeService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
      if (isDarkTheme) {
        this.picture = this.picture ? this.picture : "/assets/icons/unknown-user-dark.svg";
      } else {
        this.picture = this.picture ? this.picture : "/assets/icons/unknown-user-light.svg";
      }
    });
  }

  get badge(): string {
    let provider = this.provider;
    if (typeof this.provider === "number") {
      switch (this.provider) {
        case ProfileTypes.Facebook:
          provider = "Facebook";
          break;
        case ProfileTypes.TwitterAccount:
          provider = "TwitterAccount";
          break;
        case ProfileTypes.InstagramAccount:
          provider = "InstagramAccount";
          break;
        case ProfileTypes.SyndicationFeed:
          provider = "SyndicationFeed";
          break;
        case ProfileTypes.LinkedIn:
          provider = "LinkedIn";
      }
    }
    return this.badgesMap[provider];
  }

  onRemoveClicked() {
    this.removeClicked.emit();
  }
}
