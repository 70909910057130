import {Component, OnInit} from "@angular/core";
import {BaseNotificationTypeComponent} from "../base-notification-type/base-notification-type.component";
import {Notification} from "@shared/notifications/notification.interface";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-ic-assignedtome-type",
  templateUrl: "./ic-assignedtome-type.component.html",
  styleUrls: ["./ic-assignedtome-type.component.scss"],
})
export class IcAssignedtomeTypeComponent extends BaseNotificationTypeComponent implements OnInit {
  notification: Notification;

  ngOnInit(): void {
    if (this.notificationGroup.Notifications && this.notificationGroup.Notifications.length > 0) {
      this.notification = this.notificationGroup.Notifications[0];
    }
  }
}
