<div class="wrapper {{ size }}">
  <span class="reaction like" *ngIf="post.LikesCount" matTooltip="{{ post.LikesCount }} Like">
    <mat-icon svgIcon="like-solid"></mat-icon>
  </span>
  <span class="reaction love" *ngIf="post.LoveCount" matTooltip="{{ post.LoveCount }} Love">
    <mat-icon svgIcon="love"></mat-icon>
  </span>
  <span class="reaction haha" *ngIf="post.HahaCount" matTooltip="{{ post.HahaCount }} Haha">
    <mat-icon svgIcon="haha"></mat-icon>
  </span>
  <span class="reaction wow" *ngIf="post.WowCount" matTooltip="{{ post.WowCount }} Wow">
    <mat-icon svgIcon="wow"></mat-icon>
  </span>
  <span class="reaction sad" *ngIf="post.SadCount" matTooltip="{{ post.SadCount }} Sad">
    <mat-icon svgIcon="sad"></mat-icon>
  </span>
  <span class="reaction angry" *ngIf="post.AngryCount" matTooltip="{{ post.AngryCount }} Angry">
    <mat-icon svgIcon="angry"></mat-icon>
  </span>
</div>
