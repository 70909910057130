import {Component, Inject, forwardRef, Input, ElementRef} from "@angular/core";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {Elements, IconStyle, CardElementOptions} from "../stripe-definitions/element";
import {StripeConfig, StripeConfigToken} from "../stripe-factory";
import {StripeElement} from "../stripe-element";
import {ThemeService} from "@shared/theme/theme.service";

/** Stripe Card Element for Angular */
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-wm-stripe-card",
  template: "",
  providers: [
    {
      provide: StripeElement,
      useExisting: forwardRef(() => StripeCardComponent),
    },
  ],
})
export class StripeCardComponent extends StripeElement<"card"> {
  constructor(
    elements: Elements,
    @Inject(StripeConfigToken) config: StripeConfig,
    ref: ElementRef<HTMLElement>,
    themeService: ThemeService,
  ) {
    super("card", elements, config, ref);

    themeService.isDarkTheme.pipe(untilDestroyed(this)).subscribe((isDarkTheme) => {
      this.style = this.getStyleDependingOnTheme(isDarkTheme);

      this.update(this.options);
    });
  }

  /** Card specific options */
  protected get options(): CardElementOptions {
    return {
      disabled: this.disabled,
      hidePostalCode: this.hidePostalCode,
      hideIcon: this.hideIcon,
      iconStyle: this.iconStyle,
      style: this.style,
    };
  }

  /** The brand of the Card */
  get brand(): string {
    return (this.value && this.value.brand) || "";
  }

  /** Disables the Card control */
  @Input("disabled") set disableSetter(value: boolean) {
    this.disabled = coerceBooleanProperty(value);
  }

  /** Hides the card icon */
  @Input("hideIcon") set hideIconSetter(value: boolean) {
    this.hideIcon = coerceBooleanProperty(value);
  }

  /** Hides the postal code */
  @Input("hidePostalCode") set hidePostalCodeSetter(value: boolean) {
    this.hidePostalCode = coerceBooleanProperty(value);
  }

  private style: any;

  public disabled = false;

  public hideIcon: boolean;

  public hidePostalCode = true;

  /** Selects the icon style */
  @Input() iconStyle: IconStyle;

  getStyleDependingOnTheme(isDarkTheme) {
    if (isDarkTheme) {
      return {
        base: {
          color: "#d9d9d9",
        },
        complete: {},
        empty: {},
        invalid: {
          color: "#cf6679",
        },
      };
    }

    return {
      base: {
        color: "#404040",
      },
      complete: {},
      empty: {},
      invalid: {
        color: "#b00020",
      },
    };
  }
}
