<div *ngIf="!profiles?.length && loading" style="width: 100%">
  <app-loader class="block mx-auto my-5"></app-loader>
</div>
<ng-container *ngIf="profiles">
  <app-profile-picture
    *ngFor="let p of profiles"
    class="cursor-pointer transition-filter ease-in-out"
    [ngClass]="{
      'opacity-40 filter-grayscale': notSelected(),
      '!opacity-100 !filter-none': p.Selected
    }"
    [picture]="p.Picture"
    (click)="toggleSelectProfile(p)"
    [matTooltip]="p.Name + (p.ScreenName ? ' (@' + p.ScreenName + ')' : '')"
    matTooltipPosition="above"
    [provider]="p.Type"
    [showBadge]="true"
    [hasChannel]="true"
    style="margin: 4px">
  </app-profile-picture>
</ng-container>
<ng-container *ngIf="!profiles?.length && !loading">
  <div class="text-light-base-400 dark:text-dark-base-400 text-xs py-2">No Channels Connected.</div>
</ng-container>
