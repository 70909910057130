import {Injectable} from "@angular/core";
import {forkJoin, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {map, switchMap} from "rxjs/operators";
import {guid} from "@utils/guid.function";
import {INTERCEPTOR_SKIP_AUTH} from "@auth/auth.interceptor";
import {MediaFile} from "@publisher/content.interface";
import {MediaUploadModel} from "@user/user.interface";

@Injectable({
  providedIn: "root",
})
export class UserMediaService {
  constructor(private httpClient: HttpClient) {}

  uploadImage(file: File): Observable<string> {
    return this.httpClient
      .get<MediaUploadModel>(environment.api + "/user-media/upload-token", {
        params: {
          fileId: `${file.name}`,
        },
      })
      .pipe(
        switchMap((response: MediaUploadModel) => {
          return this.httpClient
            .put(response.PutUrl, file, {
              headers: {
                "Content-Type": file.type,
                [INTERCEPTOR_SKIP_AUTH]: "true",
              },
            })
            .pipe(map(() => response.GetUrl));
        }),
      );
  }

  uploadMediaFile(file: File, thumb?: Blob, id?: string): Observable<MediaFile> {
    const extensions = file.name.split(".");
    const extension = extensions && extensions.length ? extensions.pop() : "";
    return this.httpClient
      .get<MediaUploadModel>(environment.api + "/user-media/upload-token", {
        params: {
          fileId: `${id}.${extension}`,
        },
      })
      .pipe(
        switchMap((fileUrl: MediaUploadModel) => {
          const fileId = id || guid();
          let observable: Observable<any>;
          const fileObservable = this.httpClient
            .put(fileUrl.PutUrl, file, {
              headers: {
                "Content-Type": file.type,
                [INTERCEPTOR_SKIP_AUTH]: "true",
              },
            })
            .pipe(map(() => fileUrl.GetUrl));
          if (thumb) {
            const thumbObservable = this.httpClient
              .get<MediaUploadModel>(environment.api + "/user-media" + "/upload-token")
              .pipe(
                switchMap((thumbUrl: MediaUploadModel) =>
                  this.httpClient
                    .put(thumbUrl.PutUrl, thumb, {
                      headers: {
                        "Content-Type": thumbUrl.ContentType,
                        [INTERCEPTOR_SKIP_AUTH]: "true",
                      },
                    })
                    .pipe(map(() => thumbUrl.GetUrl)),
                ),
              );

            observable = forkJoin([fileObservable, thumbObservable]);
          } else {
            observable = fileObservable;
          }
          return observable.pipe(
            map((f) => {
              const blobUrl = (Array.isArray(f) ? f[0] : f).split("?")[0];
              const previewUrl = (Array.isArray(f) ? f[1] : f).split("?")[0];

              return {
                Id: fileId,
                FileName: file.name,
                BlobUrl: blobUrl,
                PreviewUrl: previewUrl,
                MediaType: file.type,
                SizeBytes: file.size,
              };
            }),
          );
        }),
      );
  }

  getVideoThumb(videoFile: File): Observable<Blob> {
    return Observable.create((observer) => {
      const url = URL.createObjectURL(videoFile);
      const video = document.createElement("video");
      const snapImage = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const circle = new Path2D();
        const radius = 48;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Circle
        circle.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        context.fillStyle = "rgba(0, 0, 0, 0.75)";
        context.fill(circle);
        context.lineWidth = 2;
        context.strokeStyle = "#bbbbbb";
        context.stroke(circle);
        context.fillStyle = "#ffffff";
        // Triangle
        context.beginPath();
        context.moveTo(centerX - 12, centerY - 24);
        context.lineTo(centerX - 12, centerY + 24);
        context.lineTo(centerX + 24, centerY);
        context.fill();
        context.save();
        canvas.toBlob((blob) => {
          if (blob && blob.size > 1024) {
            observer.next(blob);
            observer.complete();
          }
        });
      };
      const timeupdate = () => {
        snapImage();
        video.pause();
        video.removeEventListener("timeupdate", timeupdate);
      };
      video.addEventListener("timeupdate", timeupdate);
      video.preload = "metadata";
      video.src = url;
      video.muted = true;
      video["playsInline"] = true;
      video.play();
    });
  }

  addPlayButtonToThumbnail(imageFile: File) {
    return Observable.create((observer) => {
      const url = URL.createObjectURL(imageFile);
      const imageHtmlElement = document.createElement("img");
      const snapImage = () => {
        const canvas = document.createElement("canvas");
        canvas.width = imageHtmlElement.width;
        canvas.height = imageHtmlElement.height;
        const context = canvas.getContext("2d");
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const circle = new Path2D();
        const radius = 48;
        context.drawImage(imageHtmlElement, 0, 0, canvas.width, canvas.height);
        // Circle
        circle.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        context.fillStyle = "rgba(0, 0, 0, 0.75)";
        context.fill(circle);
        context.lineWidth = 2;
        context.strokeStyle = "#bbbbbb";
        context.stroke(circle);
        context.fillStyle = "#ffffff";
        // Triangle
        context.beginPath();
        context.moveTo(centerX - 12, centerY - 24);
        context.lineTo(centerX - 12, centerY + 24);
        context.lineTo(centerX + 24, centerY);
        context.fill();
        context.save();
        canvas.toBlob((blob) => {
          if (blob && blob.size > 1024) {
            observer.next(blob);
            observer.complete();
          } else observer.error("Something went wrong");
        });
      };
      const timeupdate = () => {
        snapImage();
      };
      imageHtmlElement.onload = timeupdate;
      imageHtmlElement.src = url;
    });
  }

  getMediaDimensions(url: string): Promise<any> {
    return new Promise<any>(function (resolve) {
      const image = new Image();

      image.src = url;

      image.onload = function () {
        resolve({
          url: url,
          height: image.height,
          width: image.width,
        });
      };
    });
  }
}
