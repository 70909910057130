<div class="activity-footer-wrapper">
  <!-- Facebook -->
  <span *ngIf="activity.FacebookPost">
    <button
      mat-button
      data-test="shared-likebutton-button"
      class="action facebook like"
      [class.active]="hasReactions?.facebook?.like"
      (click)="likeToggle('facebook')"
      [disabled]="!defaultProfiles['facebook']"
      matTooltip="Connect a private channel to enable"
      [matTooltipDisabled]="defaultProfiles['facebook'] !== null"
      matTooltipPosition="above">
      <mat-icon *ngIf="!hasReactions?.facebook?.like" svgIcon="like"></mat-icon>
      <mat-icon *ngIf="hasReactions?.facebook?.like" svgIcon="like-solid"></mat-icon>
      <span [class.active]="hasReactions?.facebook?.like">Like</span>
    </button>
    <button
      mat-button
      class="action facebook comment"
      (click)="toggleComments()"
      [disabled]="!defaultProfiles['facebook']"
      matTooltip="Connect a private channel to enable"
      [matTooltipDisabled]="defaultProfiles['facebook'] !== null"
      matTooltipPosition="above">
      <mat-icon svgIcon="message-square"></mat-icon> <span>Comment</span>
    </button>
    <button
      mat-button
      class="action facebook share"
      (click)="share('facebook')"
      [disabled]="!defaultProfiles['facebook']"
      matTooltip="Connect a private channel to enable"
      [matTooltipDisabled]="defaultProfiles['facebook'] !== null"
      matTooltipPosition="above">
      <mat-icon svgIcon="share-arrow"></mat-icon> <span>Share</span>
    </button>
  </span>
  <!-- Twitter -->
  <span *ngIf="activity.Tweet">
    <button
      mat-button
      class="action twitter reply"
      [matTooltip]="!defaultProfiles['twitter'] ? 'Connect a private channel to enable' : 'Reply'"
      matTooltipPosition="above"
      (click)="replyOrRetweet('reply')"
      [disabled]="!defaultProfiles['twitter']">
      <mat-icon svgIcon="message-circle"></mat-icon>
    </button>
    <button
      mat-button
      class="action twitter retweet"
      [class.active]="hasReactions?.twitter?.share"
      [matTooltip]="!defaultProfiles['twitter'] ? 'Connect a private channel to enable' : 'Retweet'"
      matTooltipPosition="above"
      (click)="replyOrRetweet('retweet')"
      [disabled]="!defaultProfiles['twitter']">
      <mat-icon svgIcon="repeat"></mat-icon>
      <span *ngIf="activity.Tweet.RetweetsCount" class="counter" [class.active]="hasReactions?.twitter?.share">{{
        activity.Tweet.RetweetsCount | shortNumber
      }}</span>
      <span class="filler"></span>
    </button>
    <button
      mat-button
      class="action twitter like"
      [class.active]="hasReactions?.twitter?.like"
      (click)="likeToggle('twitter')"
      [matTooltip]="!defaultProfiles['twitter'] ? 'Connect a private channel to enable' : 'Like'"
      [disabled]="!defaultProfiles['twitter']"
      matTooltipPosition="above">
      <mat-icon *ngIf="!hasReactions?.twitter?.like" svgIcon="heart"></mat-icon>
      <mat-icon *ngIf="hasReactions?.twitter?.like" svgIcon="heart-solid"></mat-icon>
      <span
        *ngIf="activity.Tweet.LikesCount || activity.Tweet.Original?.Tweet.LikesCount"
        class="counter"
        [class.active]="hasReactions?.twitter?.like"
        >{{ activity.Tweet.LikesCount || activity.Tweet.Original?.Tweet.LikesCount | shortNumber }}</span
      >
      <span class="filler"></span>
    </button>
  </span>
  <!-- RSS -->
  <span *ngIf="activity.SyndicationItem">
    <button mat-button class="action rss share" (click)="share('syndication')">
      <mat-icon svgIcon="share"></mat-icon> <span>Share</span>
    </button>
  </span>
  <!-- Instagram -->
  <span *ngIf="activity.InstagramMedia">
    <button
      mat-button
      class="action instagram comment"
      (click)="toggleComments()"
      [disabled]="!defaultProfiles['instagram']"
      matTooltip="Connect a private channel to enable"
      [matTooltipDisabled]="defaultProfiles['instagram'] !== null"
      matTooltipPosition="above">
      <mat-icon svgIcon="message-circle"></mat-icon>
      <span>Comment</span>
    </button>
    <!-- Like Counter -->
    <button
      *ngIf="activity.InstagramMedia.LikesCount > 0"
      mat-button
      disabled
      class="action instagram like disabled"
      [disabled]="true">
      <mat-icon *ngIf="!hasReactions?.instagram?.like" svgIcon="heart"></mat-icon>
      <!--<mat-icon *ngIf="hasReactions?.instagram?.like" svgIcon="heart-solid"></mat-icon>-->
      <span *ngIf="activity.InstagramMedia.LikesCount">{{ activity.InstagramMedia.LikesCount | shortNumber }}</span>
    </button>
  </span>
  <!-- LinkedIn -->
  <button
    *ngIf="activity.LinkedInPost"
    mat-button
    (click)="shareToEmployees()"
    class="send-to-employees-button -ml-2"
    [disabled]="!defaultProfiles['linkedin']">
    <div class="flex items-center justify-center text-light-base-500 dark:text-dark-base-500 h-10 min-h-[40px]">
      <app-icon icon="users" class="w-[18px] h-[18px]"></app-icon>
      <div class="ml-2 text-[13px]">Send to Employees</div>
    </div>
  </button>
  <!-- Channel Selector -->
  <app-profile-selector
    *ngIf="!activity.LinkedInPost"
    [provider]="activity.provider"
    (selectProfile)="onProfileSelected($event)"></app-profile-selector>
</div>
<div class="activity-footer-comment">
  <app-comment-input
    *ngIf="commentsOpen"
    label="Write a comment"
    [provider]="activity.provider"
    [activity]="activity"
    (commented)="toggleComments()">
  </app-comment-input>
</div>
