<div class="h-screen flex flex-row">
  <!-- Sidenav Drawer (Mobile Menu) -->
  <div
    class="fixed lg:hidden inset-0 flex pointer-events-none"
    [ngClass]="{'pointer-events-auto z-40': sidenavService.isDrawerOpen}"
    role="dialog"
    aria-modal="true">
    <!-- Overlay -->
    <div
      class="fixed inset-0 bg-dark-base-0/10 dark:bg-light-base-0/10 transition-opacity ease-in-out opacity-0"
      [ngClass]="{'opacity-100': sidenavService.isDrawerOpen}"
      aria-hidden="true"></div>
    <div
      class="relative pointer-events-auto flex flex-col w-80 drawer-shadow overflow-auto bg-white dark:bg-dark-base-100 transition transform drawer-animation"
      [ngClass]="{
        'translate-x-0': sidenavService.isDrawerOpen,
        '-translate-x-full': !sidenavService.isDrawerOpen
      }">
      <!-- Close -->
      <div class="absolute top-0 right-0 h-16 w-16 flex items-center justify-center">
        <app-icon-button (click)="sidenavService.toggleSidenavDrawer()" icon="x">
          <span class="sr-only">Close</span>
        </app-icon-button>
      </div>
      <div class="h-screen px-3">
        <!-- Loading Content Template for Mobile -->
        <ng-container *ngIf="sidenavService.inDrawerMode" [ngTemplateOutlet]="content"></ng-container>
      </div>
    </div>
  </div>
  <!-- Static Desktop Menu -->
  <div
    class="hidden lg:flex relative order-first justify-between flex-shrink-0 border-0 border-r border-solid border-light-base-300 dark:border-dark-base-300"
    [ngClass]="{'!border-0': sidenavService.isSidenavCollapsed}">
    <div class="flex flex-col w-[256px] overflow-auto" [ngClass]="{hidden: sidenavService.isSidenavCollapsed}">
      <div class="block h-screen p-0">
        <ng-scrollbar class="app-scrollbar h-screen p-0">
          <div class="h-screen px-3">
            <!-- Loading Content Template for Desktop -->
            <ng-container *ngIf="!sidenavService.inDrawerMode" [ngTemplateOutlet]="content"></ng-container>
          </div>
        </ng-scrollbar>
      </div>
    </div>
  </div>
</div>
<!-- Sidenav Content Template -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
