<app-facebook-comment
  *ngFor="let comment of comments; let last = last"
  [comment]="comment"
  [activity]="activity"
  [isLast]="last"></app-facebook-comment>

<div
  *ngIf="!hideLoadMore && !!activity.CommentsCount && comments.length < activity.CommentsCount"
  class="comment more-comments-link">
  <a (click)="loadMoreFacebookComments()"
    >View <span>{{ activity.CommentsCount - comments.length }}</span> more
    <span>{{ activity.CommentsCount - comments?.length === 1 ? "comment" : "comments" }}</span>
  </a>
</div>
