import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() showMenuIcon: boolean;
  @Input() toolbar: boolean;
  @Output() menuClick: EventEmitter<void> = new EventEmitter();
  @Input() menuClickTitle: string;

  constructor() {}

  ngOnInit() {}
}
