import {Component, Input, OnInit} from "@angular/core";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"],
})
export class ContainerComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() padding = 8;

  constructor() {}

  ngOnInit() {}
}
