import {Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges} from "@angular/core";
import {IProfile} from "@shared/channel/profile.interface";
import {ProfileService} from "@shared/channel/profile.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ProfileTypes} from "@shared/channel/profile-types.enum";
import {map} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-profile-filter",
  templateUrl: "./profile-filter.component.html",
  styleUrls: ["./profile-filter.component.scss"],
})
export class ProfileFilterComponent implements OnInit, OnChanges {
  loading = true;

  @Input() Profiles: IProfile[];

  @Input() profileTypes: ProfileTypes[] = null;

  @Output() OnProfileSelectionChanged = new EventEmitter<IProfile[]>();

  profiles: IProfile[] = [];

  constructor(private profileService: ProfileService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Profiles && changes.Profiles.currentValue) {
      this.profiles = changes.Profiles.currentValue;
      this.loading = false;
    }
  }

  ngOnInit(): void {
    if (!this.Profiles) {
      this.profileService
        .list(null, false)
        .pipe(
          untilDestroyed(this),
          map((profiles: IProfile[]) => {
            if (this.profileTypes == null) return profiles;

            return profiles.filter((x) => this.profileTypes.includes(x.Type));
          }),
        )
        .subscribe((profiles) => {
          this.loading = false;
          this.profiles = profiles;
        });
    }
  }

  toggleSelectProfile(profile: IProfile) {
    profile.Selected = !profile.Selected;
    this.OnProfileSelectionChanged.emit(this.profiles);
  }

  notSelected() {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.some((x) => x.Selected === true);
  }

  clearFilterWithoutRaisingEvent() {
    this.profiles.forEach((x) => (x.Selected = false));
  }
}
