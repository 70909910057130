import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TimezoneInfo} from "./timezone.interface";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TimezoneService {
  constructor(private http: HttpClient) {}

  list(): Observable<TimezoneInfo[]> {
    return this.http.get<TimezoneInfo[]>("/assets/json/tz.min.json");
  }

  getTimezoneByName(timezoneName: string): Observable<TimezoneInfo> {
    return this.list().pipe(
      map((timezones: TimezoneInfo[]) => {
        const timezone = timezones.find((x) => x.LongName == timezoneName);
        return timezone;
      }),
    );
  }

  getTimezoneByIanaId(ianaid: string): Observable<TimezoneInfo> {
    return this.list().pipe(
      map((timezones: TimezoneInfo[]) => {
        const timezone = timezones.find((x) => x.IanaId == ianaid);
        return timezone;
      }),
    );
  }
}
