import {Component, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit, Inject} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {ProfileListenerService, TimerangeListenerService} from "../../timerange-listener.service";
import {
  IBaseTableDataModel,
  IEmployeeBeforeAndAfterSocialActionsModel,
  IEmployeeSocialActionsModel,
} from "../../analytics.interface";
import {LeaderboardAnalyticsService} from "../../analytics.service";
import {BaseTableComponent} from "../base-table/base-table.component";
import {Observable} from "rxjs";
import {UserWidget} from "../../widget.interface";
import {environment} from "@environments/environment";

@UntilDestroy()
@Component({
  selector: "app-top-amplification-posts",
  templateUrl: "./top-content-by-employee-engagement.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopContentByEmployeeEngagementComponent
  extends BaseTableComponent<IEmployeeSocialActionsModel | IEmployeeBeforeAndAfterSocialActionsModel>
  implements AfterViewInit
{
  fileName = "Top_Amplified_Content_List";
  isProd = environment.production;

  constructor(
    protected timerangeListener: TimerangeListenerService,
    protected leaderboardAnalyticsService: LeaderboardAnalyticsService,
    protected cdr: ChangeDetectorRef,
    protected profilesListener: ProfileListenerService,
    @Inject("widgetConfig") widgetConfig: UserWidget,
  ) {
    super(leaderboardAnalyticsService, timerangeListener, profilesListener, cdr, widgetConfig);
  }

  getTableData(
    startDate: Date,
    endDate: Date,
    pageIndex: number,
    pageSize: number,
    groupsIds: string[],
    profilesIds: string[],
  ): Observable<IBaseTableDataModel<IEmployeeSocialActionsModel | IEmployeeBeforeAndAfterSocialActionsModel>> {
    if (this.isProd) {
      return this.leaderboardAnalyticsService.getAmplifiedPostsEx(
        startDate,
        endDate,
        pageIndex,
        pageSize,
        groupsIds,
        profilesIds,
      );
    }

    const newPosts = this.leaderboardAnalyticsService.getAmplifiedPostsBeforeAndAfter(
      startDate,
      endDate,
      pageIndex,
      pageSize,
      groupsIds,
      profilesIds,
    );

    // for comparison in the network tab
    this.leaderboardAnalyticsService.getAmplifiedPostsEx(
      startDate,
      endDate,
      pageIndex,
      pageSize,
      groupsIds,
      profilesIds,
    );

    return newPosts;
  }

  getCsvHeaders() {
    if (this.isProd) {
      return {
        PostText: "PostText",
        MediaTitle: "MediaTitle",
        ShareMediaCategory: "ShareMediaCategory",
        ActivityPermalink: "ActivityPermalink",
        ActivityId: "ActivityInternalId",
        CommentCount: "CommentCount",
        LikeCount: "LikeCount",
        ShareCount: "ShareCount",
        TotalCount: "TotalCount",
      };
    }

    return {
      PostText: "Post Text",
      MediaTitle: "Media Title",
      ShareMediaCategory: "Share Media Category",
      ActivityPermalink: "Activity Permalink",
      ActivityId: "Activity Internal Id",

      EmployeeCommentCount: "Employee Comments",
      EmployeeLikeCount: "Employee Likes",
      EmployeeShareCount: "Employee Shares",
      EmployeeTotalCount: "Employee Total Engagement",

      BeforeCommentCount: "Global Before Share Comments",
      BeforeLikeCount: "Global Before Share Likes",
      BeforeShareCount: "Global Before Share Shares",
      BeforeTotalCount: "Global Before Share Total Engagement",
      BeforeClickCount: "Global Before Share Clicks",
      BeforeImpressionCount: "Global Before Share Impressions",
      BeforeUniqueImpressionCount: "Global Before Share Unique Impressions",

      TotalCommentCount: "Total Global Comments",
      TotalLikeCount: "Total Global Likes",
      TotalShareCount: "Total Global Shares",
      TotalTotalCount: "Total Global Engagement",
      TotalClickCount: "Total Global Clicks",
      TotalImpressionCount: "Total Global Impressions",
      TotalUniqueImpressionCount: "Total Global Unique Impressions",
    };
  }
}
