<a class="relative !aspect-ratio flex flex-col cursor-pointer w-full leading-zero bg-black" *ngIf="openInDialog">
  <img class="absolute w-full top-0 left-0 bottom-0 right-0 bg-black" [src]="video.preview" (click)="openDialog()" />
  <div class="play-image"></div>
</a>
<div class="relative !aspect-video flex flex-grow flex-col cursor-pointer" *ngIf="!openInDialog">
  <video
    #videoPlayer
    class="w-full min-w-full leading-zero relative bg-black z-40 max-h-[400px]"
    (click)="playVideo()"
    [attr.autoplay]="autoPlay"
    [muted]="muted"
    controls>
    <source [src]="video?.source" type="video/mp4" />
  </video>
</div>
