import {Component, OnInit} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-header-left",
  templateUrl: "./header-left.component.html",
  styleUrls: ["./header-left.component.scss"],
})
export class HeaderLeftComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
