<div class="relative min-w-full">
  <div class="relative flex items-start">
    <div>
      <div class="relative px-1">
        <div class="h-8 w-8 bg-light-base-100 dark:bg-dark-base-0 rounded-full flex items-center justify-center">
          <app-icon icon="exclamation-circle-solid" class="h-4 w-4 text-red dark:text-red-300"></app-icon>
        </div>
      </div>
    </div>
    <div class="min-w-0 flex-1 flex justify-between py-1.5 mr-5">
      <div class="font-medium text-light-base-400 dark:text-dark-base-400">
        There's a problem that requires your attention
      </div>
      <div class="text-light-base-400 dark:text-dark-base-400">
        <app-icon
          class="read w-4 h-4 leading-4 cursor-pointer mr-2"
          icon="check"
          *ngIf="notificationGroup.Pending"
          (click)="toggleRead()"
          matTooltip="Mark as Read"
          matTooltipPosition="above"></app-icon>
        <app-icon
          class="delete w-4 h-4 leading-4 cursor-pointer"
          icon="trash"
          (click)="deleteNotification()"
          matTooltip="Delete Notification"
          matTooltipPosition="above"></app-icon>
      </div>
    </div>
  </div>
  <div class="relative flex items-start">
    <div class="flex-1 ml-10 mr-5">
      <div class="relative">
        <div
          class="block bg-white dark:bg-dark-base-100 border-2 border-solid border-light-danger-100 dark:border-dark-danger-100 rounded p-4 mt-2.5 first:mt-0">
          <!-- Notification Channels -->
          <div class="flex flex-wrap text-none leading-zero"></div>
          <!-- Notification Text -->
          <div class="min-w-full text-xs text-light-base-600 dark:text-dark-base-400">
            Your
            <span class="font-medium">{{ notification?.Profile?.Name || notification?.Profile?.ScreenName }}</span>
            channel returned the following error
            <span class="text-red dark:text-red-300">"{{ notification.Text }}"</span>
            when we tried to publish to it. To correct this problem, try reauthorizing your channel via the channels
            page under settings.
          </div>
        </div>
        <!-- Notification Date -->
        <div class="mt-2 text-xs font-normal text-light-base-400 dark:text-dark-base-400">
          Occured on {{ notification.NotificationDateTime | date: "medium" }}
        </div>
      </div>
    </div>
  </div>
</div>
