<!-- Header -->
<app-header [showMenuToggle]="false" [showCollapseToggle]="false" data-test="composer-header">
  <app-header-left>
    <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 ml-2">Composer</div>
  </app-header-left>
  <app-header-right>
    <!-- Close -->
    <app-icon-button
      icon="x"
      iconSize="20"
      (buttonClick)="close()"
      data-test="composer-close-button"
      class="block -mr-1"></app-icon-button>
  </app-header-right>
</app-header>
<!-- Loader -->
<div class="loader p-10" *ngIf="bootingComposer" style="width: 910px; height: 200px; box-sizing: border-box">
  <app-loader></app-loader>
</div>
<div class="bg-light-base-100 dark:bg-dark-base-0 pane-body" #composerBody data-test="composer-body">
  <!-- Editor -->
  <div class="composer-editor">
    <ng-scrollbar class="app-scrollbar" [ngStyle]="{'height.px': composerBody.clientHeight}" #scrollable>
      <div class="composer-wrapper p-5">
        <!-- Errors -->
        <app-alert *ngIf="errorMessage" class="block mb-5">
          <app-alert-error>{{ errorMessage }}</app-alert-error>
        </app-alert>
        <div class="relative">
          <app-composer-channel-selector
            [hasError]="composerValidation.GeneralValidation.NoProfileSelected && !customize">
          </app-composer-channel-selector>
        </div>
        <ng-container *ngFor="let provider of activeEditorsProfileTypes; let index = index">
          <app-composer-editor
            data-test="composer-editor-component"
            [attr.test-composer-editor-type]="provider"
            (editorChange)="onEditorChange($event, provider)"
            (shareEmployeesChange)="onShareEmployeesChange($event, provider)"
            (commentEditorChange)="onCommentEditorChange($event, provider)"
            [limitChars]="limitChars(provider)"
            [active]="activeEditors[index]"
            [dialogRef]="dialogRef"
            [provider]="getProviderToSendToEditor(provider)"
            [enableMentions]="enableMentions"
            [selectedProfiles]="composerService.selectedProfiles"
            (clicked)="setActive(index, provider)"
            (loading)="loading = $event"
            [editorModel]="editorModel"
            [showProviderLabel]="customize"
            (mediaClicked)="onMediaClicked($event, provider)"
            (videoClicked)="onVideoClicked($event, provider)"
            [class.customization-mode]="customize"
            [class.has-errors]="
              (composerValidation.GeneralValidation.ComposerHasErrors && !composerService.customizeMode) ||
              composerValidation.GeneralValidation.PostInThePast ||
              composerValidation.GeneralValidation.TimezoneEmpty ||
              composerValidation.GeneralValidation.ExpirationInThePast ||
              composerValidation.GeneralValidation.IncompleteScheduleDate ||
              composerValidation.GeneralValidation.IncompleteExpirationDate
            "
            [class.error-override]="composerValidation.GeneralValidation.NoProfileSelected && !customize"
            [class.twitter-has-errors]="composerValidation.TwitterValidation.hasErrors && provider === 2 && customize"
            [class.facebook-has-errors]="composerValidation.FacebookValidation.hasErrors && provider === 1 && customize"
            [class.instagram-has-errors]="
              composerValidation.InstagramValidation.hasErrors && provider === 6 && customize
            "
            [class.linkedin-has-errors]="composerValidation.LinkedInValidation.hasErrors && provider === 8 && customize"
            [initialText]="getInitialText(provider)"
            [includeComment]="includeComment"
            [initialMentions]="initialMentions">
          </app-composer-editor>
          <!-- Regular Mode Validation -->
          <app-validation-message
            [regularMode]="activeEditorsProfileTypes?.length === 1"
            [GeneralValidation]="composerValidation.GeneralValidation"
            [FacebookValidation]="composerValidation.FacebookValidation"
            [TwitterValidation]="composerValidation.TwitterValidation"
            [InstagramValidation]="composerValidation.InstagramValidation"
            [LinkedInValidation]="composerValidation.LinkedInValidation">
          </app-validation-message>

          <ng-container *ngFor="let p of composerService.selectedProfiles">
            <ng-container *ngIf="activeEditorsProfileTypes?.length === 1 || p.profile.Type === provider">
              <ng-container *ngIf="p.profile.Credential.RequiresAttention">
                <app-message class="mt-1"
                  ><span class="font-medium">{{ profileLabels[p.profile.Type] }}: </span>The
                  {{ p.profile.Name }} channel token has expired. Please
                  <a [routerLink]="" (click)="ReauthorizeProfile(p.profile)">reauthorize</a> your channel before
                  publishing.
                  <!-- You already authorized your profile? <a [routerLink]="" (click)="updateReauthProfileStatus()">Check</a>  -->
                </app-message>
              </ng-container>

              <ng-container *ngIf="!hasPermission(p)">
                <app-message class="mt-1"
                  ><span class="font-medium">{{ profileLabels[p.profile.Type] }}: </span>You dont have permission to
                  perform this action for {{ p.profile.Name }}
                  <!-- You already authorized your profile? <a [routerLink]="" (click)="updateReauthProfileStatus()">Check</a>  -->
                </app-message>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Customization Mode Validation -->
          <app-validation-message
            [customizationMode]="activeEditorsProfileTypes?.length > 1"
            [provider]="provider"
            [GeneralValidation]="composerValidation.GeneralValidation"
            [FacebookValidation]="composerValidation.FacebookValidation"
            [TwitterValidation]="composerValidation.TwitterValidation"
            [InstagramValidation]="composerValidation.InstagramValidation"
            [LinkedInValidation]="composerValidation.LinkedInValidation">
          </app-validation-message>
        </ng-container>
        <!-- Customize Post Button -->
        <app-button-stroked
          type="block"
          buttonType="button"
          class="customize-button flex mt-5"
          (buttonClick)="toggleCustomization()"
          *ngIf="profilesTypes.length > 1 && !editMode">
          <div class="flex-1 items-center justify-center text-light-base-600 dark:text-dark-base-600">
            <app-icon *ngIf="!customize" icon="scissors" class="icon w-5 h-5 leading-5 absolute left-0"></app-icon>
            <app-icon *ngIf="customize" icon="backspace" class="icon w-5 h-5 leading-5 absolute right-0"></app-icon>
            <div *ngIf="!customize">Customize for Each Network</div>
            <div *ngIf="customize">Discard Customization</div>
          </div>
        </app-button-stroked>
        <app-composer-actions
          #composerAction
          [model]="model"
          [content]="data?.content"
          [publishNow]="data?.publishNow"
          (closeComposerEvent)="close($event)"
          [onlySchedule]="onlySchedule"
          (submitFailed)="onComposerSubmitFailed()"
          (errorMessage)="onErrorMessage($event)"
          (handleScrollbar)="scrollable.scrollTo({bottom: 0, duration: 100})"
          [editSchedule]="editSchedule">
        </app-composer-actions>
      </div>
    </ng-scrollbar>
  </div>
  <!-- Post Preview -->
  <div
    class="composer-preview border-l border-0 border-l-light-base-300 dark:border-l-dark-base-300 border-solid bg-light-base-200 dark:bg-dark-base-0">
    <ng-scrollbar class="app-scrollbar" [ngStyle]="{'height.px': composerBody.clientHeight}">
      <div class="composer-wrapper flex flex-col p-5">
        <app-composer-preview
          data-test="composer-preview-component"
          (facebookScrapeDataEvent)="onFacebookMetadataChange($event)"
          [facebookText]="model?.FacebookText"
          [twitterText]="model?.TwitterText"
          [instagramText]="model?.InstagramText"
          [linkedInText]="model?.LinkedInText"
          [files]="mediaFiles"
          [loading]="loading"
          [selectedProfiles]="composerService.selectedProfiles"
          [providerBeingEdited]="providerBeingEdited"
          [employees]="model.Employees"
          [sharingText]="model.LinkedInShareCommentary">
        </app-composer-preview>
      </div>
    </ng-scrollbar>
  </div>
</div>
<div>
  <div #container></div>
</div>
