<div
  class="h-full shadow-overlay-panel bg-light-base-100 dark:bg-dark-base-0"
  data-test="composer-link-tracking-container-div">
  <!-- Header -->
  <app-header [showMenuToggle]="false" [showCollapseToggle]="false">
    <app-header-left>
      <div class="text-sm font-semibold text-light-base-600 dark:text-dark-base-400 ml-2">
        {{ title }}
      </div>
    </app-header-left>
    <app-header-right>
      <!-- Close -->
      <app-icon-button
        icon="x"
        iconSize="20"
        (buttonClick)="close()"
        buttonType="button"
        class="block -mr-1"></app-icon-button>
    </app-header-right>
  </app-header>
  <!-- Attach Link Wrapper -->
  <div class="flex flex-col min-h-[calc(100%-44px)] min-w-[500px]">
    <ng-scrollbar class="app-scrollbar flex-1 flex">
      <div class="flex-1 p-5">
        <section
          class="relative inline-flex flex-col h-full w-full rounded-md p-5 bg-light-base-0 dark:bg-dark-base-100 box-border border border-solid border-light-base-300/90 dark:border-dark-base-300/90">
          <!-- Link Tracking Form -->
          <ng-container [formGroup]="form">
            <div class="mb-5 flex">
              <!-- Source -->
              <app-autocompleteinput
                class="flex-1"
                formControlName="utmSource"
                [options]="utmPresetService.options.Source"
                inputDisplayName="Source"
                hasHint="true"
                hint="The referrer, for example: google, newsletter">
              </app-autocompleteinput>
            </div>
            <!-- Medium -->
            <div class="mb-5 flex">
              <app-autocompleteinput
                class="flex-1"
                formControlName="utmMedium"
                [options]="utmPresetService.options.Medium"
                inputDisplayName="Medium"
                hasHint="true"
                hint="Marketing medium: (eg: cpc, banner, email)">
              </app-autocompleteinput>
            </div>
            <!-- Campaign -->
            <div class="mb-5 flex">
              <app-autocompleteinput
                class="flex-1"
                formControlName="utmCampaign"
                [options]="utmPresetService.options.Campaign"
                inputDisplayName="Campaign"
                hasHint="true"
                hint="Product, promo code, or slogan (eg: spring_sale)">
              </app-autocompleteinput>
            </div>
            <!-- Term -->
            <div class="mb-5 flex">
              <app-autocompleteinput
                class="flex-1"
                formControlName="utmTerm"
                [options]="utmPresetService.options.Term"
                inputDisplayName="Term"
                hasHint="true"
                hint="Identify the paid keywords: (eg: running+shoes)">
              </app-autocompleteinput>
            </div>
            <!-- Content -->
            <div class="mb-5 flex">
              <app-autocompleteinput
                class="flex-1"
                formControlName="utmContent"
                [options]="utmPresetService.options.Content"
                inputDisplayName="Content"
                hasHint="true"
                hint="Use to differentiate ads: (eg: logolink, textlink)">
              </app-autocompleteinput>
            </div>
          </ng-container>

          <div class="flex">
            <mat-form-field appearance="outline" data-test="composer-link-preview" class="flex-1">
              <mat-label>Link Preview</mat-label>
              <textarea [ngModel]="getTrackingUrl()" matInput disabled rows="4"></textarea>
            </mat-form-field>
          </div>

          <app-button
            [disabled]="!link.BaseUrl"
            (buttonClick)="saveTracking()"
            [loading]="loading"
            type="block"
            class="block mt-5"
            >{{ title }}</app-button
          >
        </section>
      </div>
    </ng-scrollbar>
  </div>
</div>
