import {Component, EventEmitter, Inject, OnInit, Optional, Output} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PanelOverlay} from "@overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@overlay-panel/panel-overlay.tokens";
import {IComposerData} from "@composer/composer.component";

@Component({
  selector: "app-composer-teams-share",
  templateUrl: "./composer-teams-share.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
})
export class ComposerTeamsShareComponent extends PanelOverlay implements OnInit {
  @Output() shareTextUpdated = new EventEmitter<string>();
  constructor(
    dialogRef: PanelOverlayRef<ComposerTeamsShareComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: IComposerData,
  ) {
    super(dialogRef, data);
  }

  close() {
    this.dialogRef.close();
  }
  ngOnInit(): void {}

  share() {
    this.shareTextUpdated.emit(this.data);
    this.close();
  }
}
