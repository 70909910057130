import {Injectable} from "@angular/core";
import {AuthenticationService} from "@auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class StateService {
  constructor(private authService: AuthenticationService) {}

  public selectedProfilesKey = "selectedProfilesIds";
  public dateRangeKey = "dateRangeSelected";
  public profileFilterKey = "profileFilterIds";

  get(key: string): any {
    const value = localStorage.getItem(this.prefix + key);
    try {
      return JSON.parse(value);
    } catch (e) {
      console.error(e);
      return value;
    }
  }

  getWithType<T>(key: string): T {
    const value = localStorage.getItem(this.prefix + key);
    try {
      return JSON.parse(value) as T;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  set(key: string, value: any) {
    localStorage.setItem(this.prefix + key, JSON.stringify(value));
  }

  public get prefix(): string {
    return this.authService.currentUserValue?.Id + "_";
  }
}
