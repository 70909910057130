import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UntilDestroy} from "@ngneat/until-destroy";
import {PanelOverlay} from "@shared/overlay-panel/panel-overlay";
import {PanelOverlayRef} from "@shared/overlay-panel/panel-overlay-ref";
import {PANEL_OVERLAY_DATA} from "@shared/overlay-panel/panel-overlay.tokens";
import {MediaFile, MediaFileUploaded} from "@shared/publisher/content.interface";
import {UserMediaService} from "@shared/user/user-media.service";
import {UploadEvent, UploadFile} from "@shared/utils/file-upload/file-upload.directive";
import {guid} from "@shared/utils/guid.function";
import {Observable, switchMap} from "rxjs";

export interface EditVideoData {
  media: MediaFileUploaded;
}

@UntilDestroy()
@Component({
  selector: "app-edit-video",
  templateUrl: "./edit-video.component.html",
  animations: [
    trigger("slideContent", [
      state("void", style({transform: "translateX(-500px)"})),
      state("enter", style({transform: "none"})),
      state("leave", style({transform: "translateX(-500px)"})),
      transition("void => enter", animate("450ms cubic-bezier(.15,1,.3,1)")),
      transition("enter => leave", animate("450ms cubic-bezier(.15,1,.3,1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditVideoComponent extends PanelOverlay implements OnInit {
  form: FormGroup;

  loading = false;
  submitted = false;
  isUpdatingImage = false;

  public uploadHandler = this.upload.bind(this);

  constructor(
    dialogRef: PanelOverlayRef<EditVideoComponent>,
    @Optional() @Inject(PANEL_OVERLAY_DATA) data: EditVideoData,
    formBuilder: FormBuilder,
    private userMediaService: UserMediaService,
  ) {
    super(dialogRef, data);

    this.form = formBuilder.group({
      thumbnailUrl: data.media.PreviewUrl,
    });
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      this.dialogRef.close({
        thumbnailUrl: this.form.value.thumbnailUrl,
      });
    }
  }

  public onUploadEvent(event: UploadEvent): void {
    console.log({event});
    switch (event.status) {
      case "selected":
        break;
      case "uploading":
        this.isUpdatingImage = true;
        break;
      case "uploaded":
        this.form.patchValue({
          thumbnailUrl: event.response,
        });
        this.isUpdatingImage = false;
        break;
      case "error":
        console.error(event);
        this.isUpdatingImage = false;
        break;
    }
  }

  public upload(file: UploadFile): Observable<string> {
    if (file.file.type.startsWith("image")) {
      return this.userMediaService.addPlayButtonToThumbnail(file.file).pipe(
        switchMap((thumb: Blob) => {
          const id = guid();
          const extension = file.file.name.split(".").pop();
          const fileName = `${id}.${extension}`;
          const myFile = new File([thumb], fileName, {
            type: file.file.type,
          });
          return this.userMediaService.uploadImage(myFile);
        }),
      );
    }
  }
}
