import {Component, Input, OnChanges} from "@angular/core";
import {VideoAttachment} from "../attachments/video-attachment/video-attachment.component";
import {Activity} from "../activity.model";
import {CarouselItem} from "../attachments/carousel-attachment/carousel-attachment.component";
import {ImageAttachment} from "../attachments/image-attachment/image-attachment.interface";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-instagram-activity",
  templateUrl: "./instagram-activity.component.html",
  styleUrls: ["./instagram-activity.component.scss"],
})
export class InstagramActivityComponent implements OnChanges {
  @Input() activity: Activity;
  @Input() showSource = true;
  @Input() showCarouselControls: boolean;
  @Input() showCarouselIcon: boolean;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;
  @Input() showVolumeControl: boolean;
  @Input() showBadge = true;

  photos: ImageAttachment[];
  video: VideoAttachment;
  carousel: CarouselItem[];

  constructor() {}

  ngOnChanges() {
    this.photos = this.activity.InstagramMedia.photosAttachment;
    this.video = this.activity.InstagramMedia.videoAttachment;
    this.carousel = this.activity.InstagramMedia.carouselItems;
  }
}
