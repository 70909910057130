import {Component, Input, OnInit, ChangeDetectorRef} from "@angular/core";
import {Comment} from "../comment.model";
import {Activity} from "@activity/activity.model";
import {CommentService} from "../comment.service";
import {CommentResponse} from "../comment-response.interface";
import {IProfileResponse} from "@channel/profile.interface";
import {ProfileService} from "@channel/profile.service";

import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-instagram-comments",
  templateUrl: "./instagram-comments.component.html",
  styleUrls: ["./instagram-comments.component.scss"],
})
export class InstagramCommentsComponent implements OnInit {
  @Input() comments: Comment[];
  @Input() activity: Activity;
  hideLoadMore: boolean;
  commentsPageSize = 10;
  defaultProfiles: IProfileResponse;
  nextPageCursorId: string;

  constructor(
    private profileService: ProfileService,
    private commentService: CommentService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.profileService
      .getDefaults()
      .pipe(untilDestroyed(this))
      .subscribe((profileResponse) => {
        this.defaultProfiles = profileResponse;
      });
    this.hideLoadMore = !this.activity.InstagramMedia.MoreCommentsAvailable;
  }

  loadMoreInstagramComments() {
    let take = this.commentsPageSize;
    if (take <= 0) {
      take = 1;
    }
    const profileId = this.defaultProfiles?.instagram?.ExternalId;
    if (!profileId) return;
    this.commentService
      .forActivity("instagram", this.activity.Id, profileId, null, take, this.nextPageCursorId)
      .pipe(untilDestroyed(this))
      .subscribe((data: CommentResponse) => {
        const newComments = data.ActivityComments;
        this.nextPageCursorId = data.NextPageCursorId;

        if (newComments.length > 0) {
          this.activity.appendMoreComments(newComments);
        }

        if (!this.nextPageCursorId) {
          this.hideLoadMore = true;
        }
        this.cdr.markForCheck();
      });
  }

  deleteInstagramComment(commentIndex: number, comment: Comment) {
    if (!this.defaultProfiles?.instagram?.ExternalId) return;

    this.activity.removeCommentByIndex(commentIndex);
    this.commentService
      .delete(this.activity.Id, this.defaultProfiles.instagram.ExternalId, comment.Id)
      .pipe(untilDestroyed(this))
      .subscribe({
        error: (error) => {
          this.activity.addCommentAtIndex(comment, commentIndex);
          // TODO: add error message
        },
      });
  }
}
