import {IUsage, IAddOnSubscription} from "./subscription.interface";
import moment from "moment-timezone";

export class Subscription {
  Addon_User_Name = "User";
  Addon_Advocate_Name = "Employee";
  Addon_Channel_Name = "Channel";

  MaxLicensedChannels: number;
  MaxLicensedUsers: number;
  MaxLicensedAdvocates: number;
  BaseLicensedChannels: number;
  BaseLicensedUsers: number;
  BaseLicensedAdvocates: number;

  constructor(
    public AmountDue: number,
    public Currency: string,
    public PeriodEnd: string,
    public PeriodStart: string,
    public SubscriptionId: string,
    public PlanType: string,
    public State: string,
    public HasTrialed: boolean,
    public CreditCardLast4: any,
    public CreditCardType: string,
    public ExpirationMonth: any,
    public ExpirationYear: any,
    public CreditCardId: any,
    public Usage: IUsage,
    public AddOnSubscriptions: IAddOnSubscription[],
    public CancelAtPeriodEnd: boolean,
  ) {
    // set the Quantities in AddOnSubscriptions[] based on specific add-on limits
    if (this.Usage) {
      this.setAddOnQuantityByName(this.Addon_User_Name, this.Usage.AddOnLicensedUsers);
      this.setAddOnQuantityByName(this.Addon_Channel_Name, this.Usage.AddOnLicensedChannels);
      this.setAddOnQuantityByName(this.Addon_Advocate_Name, this.Usage.AddOnLicensedAdvocates);
    }
  }

  formattedPeriodEnd() {
    if (!this.PeriodEnd) {
      return "";
    }
    return moment(this.PeriodEnd).format("MM/DD/YYYY");
  }

  formattedAmountDue() {
    if (!this.AmountDue) {
      return (0.0).toFixed(2);
    }
    let totalAmountDue = this.AmountDue;

    this.AddOnSubscriptions.forEach((subscription) => {
      if (subscription.Quantity > 0) totalAmountDue += subscription.AmountDue * subscription.Quantity;
    });

    return (totalAmountDue / 100).toFixed(2);
  }

  canAddChannels(count) {
    if (!count && count !== 0) {
      count = 1;
    }
    const limit = this.getTotalQuantityByName(this.Addon_Channel_Name);
    return this.Usage && limit > -1 && limit >= this.Usage.BilledChannelsCount + count;
  }

  canFilterStreams() {
    return this.Usage && this.Usage.CanFilterStreams;
  }

  canMonitorTwitter() {
    return this.Usage && this.Usage.CanMonitorKeywords;
  }

  getBaseQuantityByName(planName: string): number {
    const subscription = this.AddOnSubscriptions.find((si) => si.Name === planName);
    if (subscription.PlanType === "NotApplicable") {
      return -1;
    }
    return subscription.BaseQuantity;
  }

  getAddOnQuantityByName(addOn: string): number {
    return this.AddOnSubscriptions.find((si) => si.Name === addOn).Quantity;
  }

  setAddOnQuantityByName(addOn: string, newVal: number): boolean {
    const addon = this.AddOnSubscriptions.find((si) => si.Name === addOn);

    if (addon) {
      addon.Quantity = newVal;
      return true;
    }

    return false;
  }

  getTotalQuantityByName(addOn: string): number {
    const si = this.AddOnSubscriptions.find((si) => si.Name === addOn);
    if (si.PlanType === "NotApplicable") {
      return -1;
    }
    return si.Quantity + si.BaseQuantity;
  }

  hasChangedAddOnQuantity(): boolean {
    if (this.Usage === undefined || this.Usage === null) return false;

    if (this.Usage.AddOnLicensedUsers !== this.getAddOnQuantityByName(this.Addon_User_Name)) return true;

    if (this.Usage.AddOnLicensedChannels !== this.getAddOnQuantityByName(this.Addon_Channel_Name)) return true;

    return this.Usage.AddOnLicensedAdvocates !== this.getAddOnQuantityByName(this.Addon_Advocate_Name);
  }
}
