import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Activity} from "@activity/activity.model";
import {IProfile} from "@channel/profile.interface";
import {ProfileLabels} from "@channel/profile-types.enum";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-publisher-activity-header",
  templateUrl: "./publisher-activity-header.component.html",
  styleUrls: ["./publisher-activity-header.component.scss"],
})
export class PublisherActivityHeaderComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() profiles: IProfile[];
  @Input() provider: string;
  @Input() showTime: boolean;
  @Input() showChannels: boolean;
  @Input() showStatus: boolean;
  @Input() time: string;
  @Input() status: string;
  @Input() showBadge: boolean;
  @Input() pictureSize: number;
  @Input() hasError: boolean;
  @Input() profileQuality: number;
  @Input() profileWidth: number;

  public filteredProfiles: IProfile[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.profiles !== undefined && changes.profiles.currentValue) ||
      (changes.provider !== undefined && changes.provider.currentValue)
    ) {
      this.filteredProfiles = this.getProfilesToShow();
    }
  }

  ngOnInit() {}

  getProfilesToShow(): IProfile[] {
    if (!this.profiles) return [];

    if (!this.provider) {
      return this.profiles;
    }
    return this.profiles.filter((profile) => {
      return profile.Type === ProfileLabels[this.provider].id;
    });
  }
}
