import {ProfileTypes} from "@shared/channel/profile-types.enum";
import Autolinker from "autolinker";
import {ITokenBehaviour, TokenPreviewData} from "./ITokenBehaviour";

export class HashtagTokenBehaviour implements ITokenBehaviour {
  originalValue: string;

  constructor(hashtag: string) {
    this.originalValue = hashtag;
  }
  getEditorLength(): number {
    return this.originalValue.length;
  }

  getPreviewLength(): number {
    return this.originalValue.length;
  }

  getEditorValue() {
    return this.originalValue;
  }

  getPreviewTextValue(): string {
    return this.originalValue;
  }

  getPreviewHtmlValue(previewData: TokenPreviewData, sourceType: string): string {
    const hashtag = this.originalValue.replace("#", "");
    const htmlTag = new Autolinker.HtmlTag({
      tagName: "a",
      attrs: {
        href: this.getHrefForHashtagDependingOnSourceType(sourceType, hashtag),
        target: "_blank",
        class: "shared-activityhashtag-a",
      },
      innerHtml: this.originalValue,
    });

    return htmlTag.toAnchorString();
  }

  private getHrefForHashtagDependingOnSourceType(sourceType: ProfileTypes | string, hashtag: string) {
    if (sourceType == ProfileTypes.Facebook || sourceType == "Facebook") {
      return "https://www.facebook.com/hashtag/" + hashtag;
    } else if (sourceType == ProfileTypes.TwitterAccount || sourceType == "TwitterAccount") {
      return "https://twitter.com/hashtag/" + hashtag;
    } else if (sourceType == ProfileTypes.InstagramAccount || sourceType == "InstagramAccount") {
      return "https://instagram.com/explore/tags/" + hashtag;
    } else if (sourceType == ProfileTypes.LinkedIn || sourceType == "LinkedIn") {
      return "https://www.linkedin.com/feed/hashtag/" + hashtag + "/";
    }

    return "#";
  }
}
