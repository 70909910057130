import {Component, Input, OnInit} from "@angular/core";
import {Activity} from "../activity.model";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-syndication-activity",
  templateUrl: "./syndication-activity.component.html",
  styleUrls: ["./syndication-activity.component.scss"],
})
export class SyndicationActivityComponent implements OnInit {
  @Input() activity: Activity;
  @Input() mediaWidth: number;
  @Input() mediaQuality: number;

  constructor() {}

  ngOnInit() {}
}
