<div class="flex">
  <mat-form-field (click)="onFocus()" appearance="outline" class="flex-1">
    <mat-label>{{ inputDisplayName }}</mat-label>
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onBlur()" />

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="getDisplayName"
      (optionSelected)="onOptionChange()"
      (blur)="onBlur()"
      (click)="onFocus()"
      class="selectors">
      <mat-option *ngFor="let option of options" [value]="option" class="text-sm" title="{{ option.DisplayName }}">
        {{ option.DisplayName }}
      </mat-option>
    </mat-autocomplete>
    <app-icon
      *ngIf="options.length > 0"
      icon="dropdown-arrow"
      class="h-6 w-6 leading-zero text-light-base-400 dark:text-dark-base-400"
      style="position: absolute; right: -2px; top: 6px"></app-icon>

    <!--    <app-icon-->
    <!--      *ngIf="!value.Custom"-->
    <!--      icon="x"-->
    <!--      class="h-6 w-6 leading-zero text-light-base-400 dark:text-dark-base-400"-->
    <!--      style="position: absolute; right: 20px; top: 6px"-->
    <!--      (click)="removeSelectedOption()"></app-icon>-->

    <mat-hint *ngIf="hasHint" class="text-light-base-400 dark:text-dark-base-400">{{ hint }}</mat-hint>
  </mat-form-field>
</div>
