export class GeneralComposerValidation {
  ComposerHasErrors: boolean;
  NoChannel: boolean;
  NoProfileSelected: boolean;
  PostInThePast: boolean;
  TimezoneEmpty: boolean;
  MediaFilesUploading: boolean;
  ProfileRequiresAttention: boolean;
  ExpirationInThePast: boolean;
  ProfileRequiresPermission: boolean;
  IncompleteScheduleDate: boolean;
  IncompleteExpirationDate: boolean;

  constructor() {}

  public Reset() {
    this.ComposerHasErrors = false;
    this.NoChannel = false;
    this.NoProfileSelected = false;
    this.TimezoneEmpty = false;
    this.PostInThePast = false;
    this.MediaFilesUploading = false;
    this.ProfileRequiresAttention = false;
    this.ExpirationInThePast = false;
    this.ProfileRequiresPermission = false;
    this.IncompleteScheduleDate = false;
    this.IncompleteExpirationDate = false;
  }
}
