import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {Activity} from "./activity.model";

import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
})
export class ActivityComponent implements OnInit, OnChanges {
  @Input() activity: Activity;
  @Input() showFooter = true;
  @Input() showSource = true;
  @Input() showReactions = true;
  @Input() showCarouselControls: boolean;
  @Input() showCarouselIcon: boolean;
  @Input() showVolumeControl: boolean;
  @Input() mediaWidth = 500;
  @Input() mediaQuality = 80;
  @Input() showBadge = true;
  @Input() showDescription: boolean;
  @Input() showDomain: boolean;
  @Input() isRepost: boolean;
  @Input() loading: boolean;

  @Output() retweetShare = new EventEmitter<any>();
  @Output() openFacebookShareComposer = new EventEmitter<any>();
  @Output() openSyndicationShareComposer = new EventEmitter<any>();

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activity != null && changes.activity.currentValue) {
      this.activity = changes.activity.currentValue;
    }
  }

  onRetweetShare(data) {
    this.retweetShare.emit(data);
  }

  onOpenFacebookShareComposer(data) {
    this.openFacebookShareComposer.emit(data);
  }

  OnOpenSyndicationShareComposer(data) {
    this.openSyndicationShareComposer.emit(data);
  }
}
